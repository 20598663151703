import {AfterViewInit, Component, Inject, OnInit, QueryList, ViewChildren} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatCheckbox} from "@angular/material/checkbox";
import {IAttestation} from "../../models";

@Component({
  selector: 'app-attestation-modal',
  templateUrl: './attestation-modal.component.html',
  styleUrls: ['./attestation-modal.component.scss']
})
export class AttestationModalComponent implements OnInit, AfterViewInit {

  @ViewChildren(MatCheckbox) viewChildren!: QueryList<MatCheckbox>;

  constructor(
    public dialogRef: MatDialogRef<AttestationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IAttestation
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    // viewChildren is set
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
   * Determines if all of the attestations have been checked
   */
  canAction() {
    let allChecked = true;
    //if even one is false break
    if(!!this.viewChildren){
      for (let i = 0; i <= this.viewChildren.length - 1; i++) {
        if (!!this.viewChildren.get(i) && !this.viewChildren.get(i)?.checked) {
          allChecked = false;
          break;
        }
      }
    } else {
      return false;
    }
    return allChecked;
  }

  /**
   * Allows the modal action if all attestations have been checked
   * @param data
   */
  action(data: IAttestation): void {
    if (this.canAction()) {
      this.dialogRef.close(data);
    }
  }

}
