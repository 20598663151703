import {createAction, props} from '@ngrx/store';
import {Update, EntityMap, EntityMapOne, Predicate} from '@ngrx/entity';
import {IBusinessUnit} from "../../../business-units/models";

export const loadBusinessUnits = createAction('[IBusinessUnit/API] Load IBusinessUnits', props<{ businessUnits: IBusinessUnit[] }>());
export const setBusinessUnits = createAction('[IBusinessUnit/API] Set IBusinessUnits', props<{ businessUnits: IBusinessUnit[] }>());
export const addBusinessUnit = createAction('[IBusinessUnit/API] Add IBusinessUnit', props<{ businessUnit: IBusinessUnit }>());
export const setBusinessUnit = createAction('[IBusinessUnit/API] Set IBusinessUnit', props<{ businessUnit: IBusinessUnit }>());
export const upsertBusinessUnit = createAction('[IBusinessUnit/API] Upsert IBusinessUnit', props<{ businessUnit: IBusinessUnit }>());
export const addBusinessUnits = createAction('[IBusinessUnit/API] Add IBusinessUnits', props<{ businessUnits: IBusinessUnit[] }>());
export const upsertBusinessUnits = createAction('[IBusinessUnit/API] Upsert IBusinessUnits', props<{ businessUnits: IBusinessUnit[] }>());
export const updateBusinessUnit = createAction('[IBusinessUnit/API] Update IBusinessUnit', props<{ update: Update<IBusinessUnit> }>());
export const updateBusinessUnits = createAction('[IBusinessUnit/API] Update IBusinessUnits', props<{ updates: Update<IBusinessUnit>[] }>());
export const mapBusinessUnit = createAction('[IBusinessUnit/API] Map IBusinessUnit', props<{ entityMap: EntityMapOne<IBusinessUnit> }>());
export const mapBusinessUnits = createAction('[IBusinessUnit/API] Map IBusinessUnits', props<{ entityMap: EntityMap<IBusinessUnit> }>());
export const deleteBusinessUnit = createAction('[IBusinessUnit/API] Delete IBusinessUnit', props<{ id: string }>());
export const deleteBusinessUnits = createAction('[IBusinessUnit/API] Delete IBusinessUnits', props<{ ids: string[] }>());
export const deleteBusinessUnitsByPredicate = createAction('[IBusinessUnit/API] Delete IBusinessUnits By Predicate', props<{ predicate: Predicate<IBusinessUnit> }>());
export const clearBusinessUnits = createAction('[IBusinessUnit/API] Clear IBusinessUnits');
