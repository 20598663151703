import { Injectable } from '@angular/core';
import {Config, getConfig, initialConfigState} from "../../../redux";
import {environment} from "../../../../../environments/environment";
import {Store} from "@ngrx/store";
import {RondeivuHttpClient} from "../../../../handlers";
import {IDealRequest} from "../../../deals/models";
import {HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BusinessUnitDealsService {

  private config:Config = initialConfigState;
  private readonly BASE_URL = environment.rondeivu_api;
  private readonly FRAGMENT = '/deals';

  constructor(private store: Store, private http: RondeivuHttpClient) {
    this.store.select(getConfig).subscribe((config:Config)=>{
      this.config = config;
    });
  }

  addDeal(deal: IDealRequest): Observable<any> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    // const req = JSON.stringify(deal);
    // console.log(req);
    return this.http.post(this.BASE_URL + this.FRAGMENT, deal, {headers: httpHeaders});
  }

  getDeals():Observable<any>{
    return this.http.get(this.BASE_URL + this.FRAGMENT);
  }

  getDeal(id:string):Observable<any>{
    return this.http.get(this.BASE_URL + this.FRAGMENT + '/' + id);
  }
}
