import {createFeatureSelector, createSelector} from "@ngrx/store";
import {AppState} from "../../index";

export const getAppState = createFeatureSelector<AppState>('app');
//lookups
export const getLookups = createSelector(
  getAppState,
  (state: AppState) => state.lookups
);
//lookups
export const getDealTypes = createSelector(
  getAppState,
  (state: AppState) => state.lookups.dealTypes
);
//lookups
export const getAssetTypes = createSelector(
  getAppState,
  (state: AppState) => state.lookups.assetTypes
);
