import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {
  UserActivityComponent,
  UserBusinessUnitsComponent, UserEmailNotificationsComponent,
  UserInformationComponent,
  UserListComponent,
  UserNavigationComponent
} from "./components";
import {PageNotFoundComponent} from "../../components";
import {UserResolver} from "../../resolvers/user.resolver";

const routes: Routes = [
  { path: '', component: UserListComponent },
  {
    path: ':id', component: UserNavigationComponent,
    resolve: {
      UserResolver
    },
    children: [
      {path: 'information', component: UserInformationComponent},
      {path: 'business-units', component: UserBusinessUnitsComponent},
      {path: 'activity', component: UserActivityComponent},
      {path: 'email-notifications', component: UserEmailNotificationsComponent},
      {path: '', redirectTo: 'information', pathMatch: 'full'},
      {path: '**', redirectTo: 'information'},
    ]
  },
  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsersRoutingModule {}
