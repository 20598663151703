import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ThemePalette} from "@angular/material/core";
import {
  Config, fetchTasks,
  getConfig,
  getSelectedBusinessUnit,
  getSelectedKyc,
  getThemeState,
  initialConfigState,
  initialThemeState,
  Theme,
  toggleLoading, updateKyc, updateKycId
} from "../../../redux";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import {IBusinessUnit} from "../../../business-units/models";
import {KycService, UtilService} from "../../../shared/services";
import {ToastrService} from "ngx-toastr";
import {toggleNavigating} from "../../../redux/stores/config/config.actions";
import {AppFade} from "../../../../animations/animations";

@Component({
  selector: 'app-kyc-navigation',
  templateUrl: './kyc-navigation.component.html',
  styleUrls: ['./kyc-navigation.component.scss'],
  animations: [AppFade()],
})
export class KycNavigationComponent implements OnInit, OnDestroy, AfterViewInit {
  loading = false;
  animationState = false;

  links = [
    {title: 'Information', link: '/information', enabled: true, hidden: false},
    {title: 'Upload Documents', link: '/documents', enabled: true, hidden: false}
  ];
  activeLink = {};
  background: ThemePalette = undefined;

  kycId: string | null = '';
  selectedKyc: any = {};

  config$: Subscription = new Subscription();
  theme$: Subscription = new Subscription();
  route$: Subscription = new Subscription();

  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;
  selectedBusinessUnit$: Subscription = new Subscription();
  selectedKyc$: Subscription = new Subscription();
  config: Config = initialConfigState;
  theme: Theme = initialThemeState;

  constructor(
    private kycService: KycService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private toastrService: ToastrService,
    public util: UtilService
  ) {
    this.route$ = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.setActiveLink();
        this.store.dispatch(toggleNavigating({navigating: true}));
        this.loading = true;
        setTimeout(() => {
          this.store.dispatch(toggleNavigating({navigating: false}));
          this.loading = false;
        }, 1000);
      }
    });

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.theme$ = this.store.select(getThemeState).subscribe((theme: any) => {
      this.theme = theme;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      if (!!bu && !!bu.businessUnitId && this.selectedBusinessUnit.businessUnitId != bu.businessUnitId) {
        this.selectedBusinessUnit = bu;
        this.subscribeToDatasource();
      }
    });

    this.selectedKyc$ = this.store.select(getSelectedKyc).subscribe((kyc: any) => {
      this.selectedKyc = kyc;
    });

  }

  ngOnInit(): void {
    this.animationState = true;
  }

  ngOnDestroy() {
    this.store.dispatch(updateKyc({kyc: {}}));
    this.config$.unsubscribe();
    this.theme$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedKyc$.unsubscribe();
    this.route$.unsubscribe();
  }

  subscribeToDatasource() {
    this.getKycIdFromRoute();
    this.getKyc();
    this.setActiveLink();
  }

  ngAfterViewInit() {

  }

  getKyc() {
    this.loading = true;
    setTimeout(() => {
      this.fetchKycAndDispatch();
      this.loading = false;
    }, 1000);
  }

  /**
   * Get the kyc and update the store
   */
  private fetchKycAndDispatch() {
    if (!!this.kycId) {
      this.kycService.getKycById(this.kycId).subscribe({
        next: (kyc: any) => {
          this.selectedKyc = kyc;
          this.toastrService.success("KYC Loaded!", $localize`:@@companyName:Rondeivu`);
          this.store.dispatch(updateKyc({kyc: kyc}));
          this.loading = false;
        }, error: (error: any) => {
          this.loading = false;
          this.toastrService.error("Unable to get the KYC!");
        }
      });
    }
  }

  getKycIdFromRoute() {
    this.kycId = this.route.snapshot.paramMap.get('id');
    if (!!this.kycId) {
      this.store.dispatch(updateKycId({kycId: this.kycId}));
    }
  }

  setActiveLink() {
    const fragments = this.router.url.split('/');
    let link = '/' + fragments[fragments.length - 1];
    this.links.forEach(l => {
      if (l.link == link) {
        this.activeLink = l;
      }
    });
  }

  review() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.kycService.submitKyc(this.selectedKyc).subscribe({
      next: (kyc: any) => {
        this.subscribeToDatasource();
        this.store.dispatch({type: fetchTasks});
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.success("KYC Submitted!", $localize`:@@companyName:Rondeivu`);
      }, error: error => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.error("Something went wrong!");
      }
    });
  }

  navigate(obj: { title: string; link: string; enabled: boolean }) {
    this.store.dispatch(toggleNavigating({navigating: true}));
    this.animationState = false;
    setTimeout(() => {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/kyc/' + this.kycId + obj.link]).then(() => {
        this.animationState = true;
        this.store.dispatch(toggleNavigating({navigating: false}));
      });
    }, 300);
  }

  goBack() {
    this.store.dispatch(toggleNavigating({navigating: true}));
    this.animationState = false;
    setTimeout(() => {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/kyc']).then(() => {
        this.animationState = true;
        this.store.dispatch(toggleNavigating({navigating: false}));
      });
    }, 300);
  }

}
