import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {IUserEmailNotification} from "../../models";
import {
  Config,
  getConfig,
  getSelectedBusinessUnit,
  initialConfigState,
  initialThemeState,
  Theme,
  toggleLoading
} from "../../../redux";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {IBusinessUnit} from "../../../business-units/models";
import {CloudwatchService} from "../../services";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-user-email-notifications',
  templateUrl: './user-email-notifications.component.html',
  styleUrls: ['./user-email-notifications.component.scss']
})
export class UserEmailNotificationsComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();

  dataSource: MatTableDataSource<IUserEmailNotification> = new MatTableDataSource();
  config: Config = initialConfigState;
  theme: Theme = initialThemeState;
  columnsToDisplay = ['appUser', 'emailTo', 'emailFrom', 'subject', 'platform', 'type', 'emailStatus', 'statusChangedTime'];

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;

  dataList: IUserEmailNotification[] = [];
  sortedDataList: IUserEmailNotification[] = [];

  constructor(private cloudwatch: CloudwatchService,
              private toastr: ToastrService,
              private router: Router,
              private store: Store,
              private dialog: MatDialog) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.selectedBusinessUnit = Object.assign({}, bu);
      if (!!this.selectedBusinessUnit && !!this.selectedBusinessUnit.businessUnitId) {
        this.subscribeToDatasource();
      }
    });

  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
  }

  subscribeToDatasource(): void {
    console.log("Fetching User Email Notifications...");
    this.getUserEmailNotifications();
  }

  getUserEmailNotifications() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.cloudwatch.getUserEmailNotifications().subscribe({
      next: (res: IUserEmailNotification[]) => {
        this.dataList = res;
        this.dataSource = new MatTableDataSource(res);
        this.initDataSource();
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Something went wrong!");
      }
    });
  }

  initDataSource() {
    this.dataSource.filterPredicate = (data: IUserEmailNotification, filter: string) => {
      const lc_filter = filter.toLowerCase();
      const name = data.appUser?.firstName + ' ' + data.appUser?.lastName;
      const nameMatch = name.toLowerCase().includes(lc_filter);
      const emailTo = data.emailTo.toLowerCase().includes(lc_filter);
      const emailFrom = data.emailFrom.toLowerCase().includes(lc_filter);
      const subject = data.subject.toLowerCase().includes(lc_filter);
      const platform = data.platform.toLowerCase().includes(lc_filter);
      const type = data.type.toLowerCase().includes(lc_filter);
      const emailStatus = data.emailStatus.toLowerCase().includes(lc_filter);

      return nameMatch || emailTo || emailFrom || subject || platform || type || emailStatus;
    };
    if (!!this.paginator) {
      this.paginator.pageSize = 100;
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //sort
  sortData(sort: Sort) {
    const data = this.dataList.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedDataList = data;
      return;
    }

    this.sortedDataList = data.sort((a: IUserEmailNotification, b: IUserEmailNotification) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'appUser':
          const user1 = a.appUser?.firstName + ' ' + a.appUser?.lastName;
          const user2 = b.appUser?.firstName + ' ' + b.appUser?.lastName;
          return compare(user1 || '', user2 || '', isAsc);
        case 'emailTo':
          return compare(a.emailTo || '', b.emailTo || '', isAsc);
        case 'emailFrom':
          return compare(a.emailFrom || '', b.emailFrom || '', isAsc);
        case 'subject':
          return compare(a.subject || '', b.subject || '', isAsc);
        case 'platform':
          return compare(a.platform || '', b.platform || '', isAsc);
        case 'type':
          return compare(a.type || '', b.type || '', isAsc);
        case 'emailStatus':
          return compare(a.emailStatus || '', b.emailStatus || '', isAsc);
        case 'statusChangedTime':
          return compare(a.statusChangedTime || '', b.statusChangedTime || '', isAsc);
        default:
          return 0;
      }
    });

    this.dataSource = new MatTableDataSource(this.sortedDataList);
    this.initDataSource();
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
