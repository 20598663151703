import {Injectable} from '@angular/core';
import {Store} from "@ngrx/store";
import {RondeivuHttpClient} from "../../../../handlers";
import {IDocument} from "../../../deals/models";
import {Observable} from "rxjs";
import {DocumentBaseService} from "./document-base.service";


@Injectable({
  providedIn: 'root'
})
export class DocumentsService extends DocumentBaseService {
  constructor(store: Store, http: RondeivuHttpClient) {
    super(store, http);
  }

  override getDataRoomsByDealId(objectId: string, types: string[]): Observable<any> {
    return this.http.get(this.BASE_URL + this.DOCUMENTS_FRAGMENT + '?id=' + objectId + '&types=' + types.join(","));
  }

  override getDocumentsByDataRoomId(dataRoomId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.DOCUMENTS_FRAGMENT + '/' + dataRoomId);
  }

  override getDataroomConfigDataRoomId(dataRoomId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.DOCUMENTS_FRAGMENT + this.CONFIG + '/' + dataRoomId);
  }

  override downloadDocument(id: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.DOCUMENTS_FRAGMENT + this.DOWNLOAD + '/' + id, {responseType: 'blob'});
  }

  override downloadAllDocuments(dataRoomId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.DOCUMENTS_FRAGMENT + this.DOWNLOAD_ALL + '/' + dataRoomId, {responseType: 'blob'});
  }

  override createFolder(dataRoomId: string, parentId: string, name: string): Observable<any> {
    return this.http.post(this.BASE_URL + this.DOCUMENTS_FRAGMENT + this.CREATE_FOLDER + '/' + dataRoomId, {
      parentId: parentId,
      name: name
    });
  }

  override moveDocument(data: IDocument, parentId: string): Observable<any> {
    return this.http.patch(this.BASE_URL + this.DOCUMENTS_FRAGMENT + this.MOVE + '/' + data.id, {parentId: parentId});
  }

  override updateDocument(data: IDocument): Observable<any> {
    return this.http.patch(this.BASE_URL + this.DOCUMENTS_FRAGMENT + '/' + data.id, data);
  }

  override uploadDocument(parentId: string, file: any): Observable<any> {
    let formData = new FormData();
    formData.append("formFile", file);
    return this.http.post(this.BASE_URL + this.DOCUMENTS_FRAGMENT + this.UPLOAD + '/' + parentId, formData);
  }

  override deleteDocument(id: string): Observable<any> {
    return this.http.delete(this.BASE_URL + this.DOCUMENTS_FRAGMENT + '/' + id);
  }

  override reorder(parentId: string, datarooms?: IDocument): Observable<any> {
    return this.http.put(this.BASE_URL + this.DOCUMENTS_FRAGMENT + this.REORDER + '/' + parentId, datarooms);
  }

}
