import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {
  Config,
  getConfig,
  getSelectedBusinessUnit,
  getSelectedUserId,
  initialConfigState,
  initialThemeState,
  Theme,
  toggleLoading
} from "../../../redux";
import {IBusinessUnit} from "../../../business-units/models";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {UsersService} from "../../services/users/users.service";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {MatDialog} from "@angular/material/dialog";
import {IUserEmailResponse} from "../../models/i-users";

@Component({
  selector: 'app-user-email-notifications',
  templateUrl: './user-email-notifications.component.html',
  styleUrls: ['./user-email-notifications.component.scss']
})
export class UserEmailNotificationsComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  selectedUserId$: Subscription = new Subscription();
  selectedUserId = '';

  dataSource: MatTableDataSource<IUserEmailResponse> = new MatTableDataSource();
  userEmailNotifications: IUserEmailResponse[] = [];
  sortedNotifications: IUserEmailResponse[] = [];
  config: Config = initialConfigState;
  theme: Theme = initialThemeState;
  columnsToDisplay = ['status', 'platform', 'subject', 'type', 'date'];

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;

  constructor(private usersService: UsersService,
              private toastr: ToastrService,
              private router: Router,
              private store: Store,
              private dialog: MatDialog) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.selectedBusinessUnit = Object.assign({}, bu);
    });

    this.selectedUserId$ = this.store.select(getSelectedUserId).subscribe((id: string) => {
      this.selectedUserId = id;
      if (!!this.selectedBusinessUnit && !!this.selectedBusinessUnit.businessUnitId && !!this.selectedUserId) {
        this.subscribeToDatasource();
      }
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedUserId$.unsubscribe();
  }

  subscribeToDatasource(): void {
    console.log("Fetching User Email Notifications...");
    this.getUserEmailNotifications();
  }

  getUserEmailNotifications() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.usersService.getUserEmailNotifications(this.selectedUserId).subscribe({
      next: (res: IUserEmailResponse[]) => {
        this.userEmailNotifications = res;
        this.dataSource = new MatTableDataSource(this.userEmailNotifications);
        this.initDataSource();
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: (err: any) => {
        this.toastr.error("Something went wrong!");
        this.store.dispatch(toggleLoading({loading: false}));
      }
    });
  }

  initDataSource() {
    this.dataSource.filterPredicate = (data: IUserEmailResponse, filter: string) => {
      const SUBJECT = data.subject?.toLowerCase()?.includes(filter.toLowerCase());
      return SUBJECT;
    };
    if (!!this.paginator) {
      this.paginator.pageSize = 10;
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //sort
  sortData(sort: Sort) {
    const data = this.userEmailNotifications.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedNotifications = data;
      return;
    }

    this.sortedNotifications = data.sort((a: IUserEmailResponse, b: IUserEmailResponse) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'status':
          return compare(a.emailStatus, b.emailStatus, isAsc);
        case 'platform':
          return compare(a.platform, b.platform, isAsc);
        case 'subject':
          return compare(a.subject, b.subject, isAsc);
        case 'date':
          return compare(a.statusChangedTime, b.statusChangedTime, isAsc);
        case 'type':
          return compare(a.type, b.type, isAsc);
        default:
          return 0;
      }
    });

    this.dataSource = new MatTableDataSource(this.sortedNotifications);
    this.initDataSource();
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
