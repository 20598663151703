import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {IDealTeamMemberRequest} from "../../models";
import {
  Config,
  getConfig,
  getLookups,
  initialConfigState,
  Lookups, toggleLoading
} from "../../../redux";
import {LoadingService} from "../../../../services";
import {Store} from "@ngrx/store";
import {DealTeamMembersService} from "../../services";
import {ToastrService} from "ngx-toastr";
import {Subscription} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {IMiniAppUser} from "../../../../models";

@Component({
  selector: 'app-deal-team-member-modal',
  templateUrl: './deal-team-member-modal.component.html',
  styleUrls: ['./deal-team-member-modal.component.scss']
})
export class DealTeamMemberModalComponent implements OnInit, OnDestroy {
  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  curPage = 0;
  lookups: Lookups = {} as Lookups;
  config: Config = initialConfigState;
  loading = false;

  userForm = new FormGroup({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email]),
    role: new FormControl('')
  });

  employees: IMiniAppUser[] = [];
  selectedEmployeeIds: string[] = [];
  selectedEmployees: IMiniAppUser[] = [];

  constructor(private loader: LoadingService, private store: Store,
              private dtm: DealTeamMembersService, private toastr: ToastrService,
              public dialogRef: MatDialogRef<DealTeamMemberModalComponent>, @Inject(MAT_DIALOG_DATA) public data: IDealTeamMemberRequest) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });
  }

  ngOnInit(): void {
    this.getEmployees();
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
  }

  getEmployees() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.dtm.getEmployees(this.config.selected_deal_team_id).subscribe({
      next: (res: IMiniAppUser[]) => {
        this.employees = res;
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: error => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to get employees!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  toggleEmployee(user: IMiniAppUser) {
    if (!!user) {
      if (this.selectedEmployeeIds.includes(user.id)) {
        this.selectedEmployees.splice(this.selectedEmployees.indexOf(user), 1);
        this.selectedEmployeeIds.splice(this.selectedEmployeeIds.indexOf(user.id), 1);
      } else {
        this.selectedEmployees.push(user);
        this.selectedEmployeeIds.push(user.id);
      }
    }
  }

  addEmployees() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.dtm.addEmployees(this.config.selected_deal_team_id, this.selectedEmployeeIds).subscribe({
      next: (res: any) => {
        this.dialogRef.close(this.selectedEmployees);
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Deal Team Member(s) Added!", $localize`:@@companyName:Rondeivu`);
      }, error: error => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to add members!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  addExternalEmployee() {
    const emp = {
      firstName: this.userForm.get('firstName')?.value || '',
      lastName: this.userForm.get('lastName')?.value || '',
      email: this.userForm.get('email')?.value || ''
    }
    this.store.dispatch(toggleLoading({loading: true}));
    this.dtm.addExternal(this.config.selected_deal_team_id, emp).subscribe({
      next: (res: any) => {
        this.dialogRef.close(this.selectedEmployees);
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("External Deal Team Member Added!", $localize`:@@companyName:Rondeivu`);
      }, error: error => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to add external member!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

}
