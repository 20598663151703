<div style="height:calc(100vh - 112px); overflow-y: auto;">
  <div class="container-fluid">
    <br/><br/>
    <div class="row justify-content-center">
      <div class="col-6 col-md-5">
        <div style="display: flex;">
          <mat-icon color="primary" class="rm-header-icon">rule</mat-icon>&nbsp;
          <span class="rm-title rm-title-3">{{ approvalType.charAt(0).toUpperCase() + approvalType.slice(1) }}
            Approvals</span>
        </div>
      </div>
      <div class="col-6 text-end">
        &nbsp;
      </div>
      <div class="col-12 col-md-11">
        <span class="rm-title rm-title-6 rm-lighter">These are all of your {{ approvalType }} approvals:</span>
      </div>
    </div>
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-12 col-md-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <table mat-table [dataSource]="dataSource" [multiTemplateDataRows]="true" matSort>
                <!-- Search Header -->
                <ng-container matColumnDef="table-name">
                  <th colspan="2" mat-header-cell *matHeaderCellDef>
                  <span style="font-size: large;">{{ approvalType.charAt(0).toUpperCase() + approvalType.slice(1) }}
                    Approvals</span>
                  </th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="search-header">
                  <th colspan="4" mat-header-cell *matHeaderCellDef>
                    <form>
                      <mat-form-field style="width: 100%;">
                        <mat-label>Search</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" #input/>
                        <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                    </form>
                  </th>
                </ng-container>
                <!-- USER Column -->
                <ng-container matColumnDef="User">
                  <th mat-header-cell *matHeaderCellDef> Created By</th>
                  <td mat-cell *matCellDef="let element" style="padding: 5px;">
                    <!--        user menu button-->
                    <app-user-card [firstName]="element.submittedBy?.['firstName'] "
                                   [lastName]="element.submittedBy?.['lastName']"
                                   [displayName]="element.submittedBy?.['email']"
                                   [borderColor]="'transparent'"
                                   [background]="'#F1EDE4'"
                                   [sideColor]="'#002150'"
                                   [image]="element.submittedBy?.['imageUrl']"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- COMPANY Column -->
                <ng-container matColumnDef="Company">
                  <th mat-header-cell *matHeaderCellDef> Business Unit</th>
                  <td mat-cell *matCellDef="let element" style="padding: 5px;">
                    <app-user-card [firstName]="element.businessUnit?.['displayName']"
                                   [displayName]="element.businessUnit?.['slug']"
                                   [borderColor]="'transparent'"
                                   [sideColor]="'#ff0000'"
                                   [image]="element.businessUnit?.['imageUrl']"
                                   (click)="util.isType('admin')? util.viewBusinessUnit(element.businessUnit?.id) : null"
                                   [ngStyle]="{'cursor': util.isType('admin')? 'pointer':''}"
                                   [highlightOnHover]="util.isType('admin')"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- IDENTIFIERS Column -->
                <ng-container matColumnDef="Identifiers">
                  <th mat-header-cell *matHeaderCellDef> Subtype</th>
                  <td mat-cell *matCellDef="let element">
                    <span style="font-weight: lighter;">{{ element.businessUnit?.subType }} </span></td>
                </ng-container>
                <!-- CREATED Column -->
                <ng-container matColumnDef="Created">
                  <th mat-header-cell *matHeaderCellDef> Created On</th>
                  <td mat-cell *matCellDef="let element"> {{ element.businessUnit?.created | date }}</td>
                </ng-container>
                <!-- STATUS Column -->
                <ng-container matColumnDef="Status">
                  <th mat-header-cell *matHeaderCellDef> Actions</th>
                  <td mat-cell *matCellDef="let element" style="width: 200px;">
                    <div *ngIf="element.businessUnit?.status === 'PENDING'" style="display: flex;">
                      <button
                        mat-raised-button
                        color="primary"
                        (click)="approveBusinessUnit(element.businessUnit)"
                        [disabled]="approvalType == ApprovalType.ISSUER ?
                        !util.hasPermission(util.Permission.CAN_EDIT_BU_APPROVALS_ISSUER) :
                        !util.hasPermission(util.Permission.CAN_EDIT_BU_APPROVALS_INVESTOR)"
                        [ngStyle]="{'cursor': (!util.hasPermission(util.Permission.CAN_EDIT_BU_APPROVALS_ISSUER) ||
                         !util.hasPermission(util.Permission.CAN_EDIT_BU_APPROVALS_INVESTOR)) ? util.NoAccessCursor: ''}"
                        [matTooltip]="(!util.hasPermission(util.Permission.CAN_EDIT_BU_APPROVALS_ISSUER) ||
                         !util.hasPermission(util.Permission.CAN_EDIT_BU_APPROVALS_INVESTOR))  ? util.NoAccessMsg : ''"
                        matTooltipPosition="left"
                      >Approve
                      </button>&nbsp;
                      <button
                        mat-raised-button
                        color="warn"
                        (click)="declineBusinessUnit(element.businessUnit)"
                        [disabled]="approvalType == ApprovalType.ISSUER ?
                        !util.hasPermission(util.Permission.CAN_EDIT_BU_APPROVALS_ISSUER) :
                        !util.hasPermission(util.Permission.CAN_EDIT_BU_APPROVALS_INVESTOR)"
                        [ngStyle]="{'cursor': (!util.hasPermission(util.Permission.CAN_EDIT_BU_APPROVALS_ISSUER) ||
                         !util.hasPermission(util.Permission.CAN_EDIT_BU_APPROVALS_INVESTOR)) ? util.NoAccessCursor: ''}"
                        [matTooltip]="(!util.hasPermission(util.Permission.CAN_EDIT_BU_APPROVALS_ISSUER) ||
                         !util.hasPermission(util.Permission.CAN_EDIT_BU_APPROVALS_INVESTOR))  ? util.NoAccessMsg : ''"
                        matTooltipPosition="left"
                      >Decline
                      </button>
                    </div>
                    <div *ngIf="element.businessUnit?.status != 'PENDING'" style="text-align: center;">
                      <span [appStatus]="element.businessUnit?.status">{{ element.businessUnit?.status }}</span>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="expand">
                  <th mat-header-cell *matHeaderCellDef aria-label="row actions" style="width: 50px;">&nbsp;</th>
                  <td mat-cell *matCellDef="let element" style="width: 50px;">
                    <!--            mandates-->
                    <div *ngIf="!!element.mandates && element.mandates.length > 0">
                      <button mat-icon-button aria-label="expand row"
                              (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                        <mat-icon *ngIf="expandedElement !== element" [matBadge]="element.mandates?.length"
                                  matBadgeColor="accent">keyboard_arrow_down
                        </mat-icon>
                        <mat-icon *ngIf="expandedElement === element" [matBadge]="element.mandates?.length"
                                  matBadgeColor="accent">keyboard_arrow_up
                        </mat-icon>
                      </button>
                    </div>
                    <!--            deals-->
                    <div *ngIf="!!element.deals && element.deals.length > 0">
                      <button mat-icon-button aria-label="expand row"
                              (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                        <mat-icon *ngIf="expandedElement !== element" [matBadge]="element.deals?.length"
                                  matBadgeColor="accent">keyboard_arrow_down
                        </mat-icon>
                        <mat-icon *ngIf="expandedElement === element" [matBadge]="element.deals?.length"
                                  matBadgeColor="accent">keyboard_arrow_up
                        </mat-icon>
                      </button>
                    </div>
                  </td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                  <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length"
                      style="padding:0;">
                    <div class="example-element-detail"
                         [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                      <div class="approval-row">
                        <span style="font-size: large; color: white"
                              *ngIf="!!element.deals && element.deals.length > 0">Deals</span>
                        <!--DEAL TABLE START-->
                        <table *ngIf="!!element.deals && element.deals.length > 0" mat-table
                               [dataSource]="element.deals"
                               class="inner-table">
                          <!--                    header-->
                          <ng-container matColumnDef="dealTitle">
                            <th colspan="1" mat-header-cell *matHeaderCellDef class="inner-header">Project Name</th>
                            <td mat-cell *matCellDef="let element"> {{ element.projectName }}</td>
                          </ng-container>
                          <!--                    header-->
                          <ng-container matColumnDef="fundraiseSize">
                            <th colspan="1" mat-header-cell *matHeaderCellDef class="inner-header">Total Fundraise
                              Size
                            </th>
                            <td mat-cell
                                *matCellDef="let element"> {{ '$' + element.fundraiseSize + ' million USD' }}
                            </td>
                          </ng-container>
                          <!--                    header-->
                          <ng-container matColumnDef="capacityToRaise">
                            <th colspan="1" mat-header-cell *matHeaderCellDef class="inner-header">Capacity to Raise
                            </th>
                            <td mat-cell
                                *matCellDef="let element"> {{ '$' + element.rondeivuFundraiseSize + ' million USD' }}
                            </td>
                          </ng-container>
                          <!--                    header-->
                          <ng-container matColumnDef="timeline">
                            <th colspan="1" mat-header-cell *matHeaderCellDef class="inner-header">Timeline to Close
                            </th>
                            <td mat-cell *matCellDef="let element"> {{ getTimelineText(element.timeLineTypeId) }}</td>
                          </ng-container>
                          <!--                    header-->
                          <ng-container matColumnDef="created">
                            <th mat-header-cell *matHeaderCellDef class="inner-header"> Created</th>
                            <td mat-cell *matCellDef="let element"> {{ element.created | date }}</td>
                          </ng-container>
                          <!--                    header-->
                          <ng-container matColumnDef="view">
                            <th mat-header-cell *matHeaderCellDef class="inner-header"> View</th>
                            <td mat-cell *matCellDef="let element">
                              <button mat-icon-button
                                      (click)="navToDeal(element.id)"
                                      [disabled]="!util.hasPermission(util.Permission.CAN_ACCESS_APPROVALS_ISSUER)"
                                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ACCESS_APPROVALS_ISSUER) ? util.NoAccessCursor: ''}"
                                      [matTooltip]="!util.hasPermission(util.Permission.CAN_ACCESS_APPROVALS_ISSUER) ? util.NoAccessMsg : 'View Deal'"
                                      matTooltipPosition="left">
                                <mat-icon>east</mat-icon>
                              </button>
                            </td>
                          </ng-container>
                          <!--                    header-->
                          <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef class="inner-header"> Deal Actions</th>
                            <td mat-cell *matCellDef="let element">
                              <div *ngIf="element['dealWorkflow'] === 'PENDING'" style="display: flex;">
                                <button mat-raised-button
                                        (click)="approveDeal(element)"
                                        [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_APPROVALS_ISSUER)"
                                        [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_APPROVALS_ISSUER) ? util.NoAccessCursor: ''}"
                                        [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_APPROVALS_ISSUER) ? util.NoAccessMsg : ''"
                                        matTooltipPosition="left"
                                        color="primary">Approve
                                </button>&nbsp;
                                <button mat-raised-button
                                        (click)="declineDeal(element)"
                                        [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_APPROVALS_ISSUER)"
                                        [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_APPROVALS_ISSUER) ? util.NoAccessCursor: ''}"
                                        [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_APPROVALS_ISSUER) ? util.NoAccessMsg : ''"
                                        matTooltipPosition="left"
                                        color="warn">Decline
                                </button>
                              </div>
                              <div *ngIf="element['dealWorkflow'] != 'PENDING'" style="text-align: center;">
                                <span [appStatus]="element['dealWorkflow']">{{ element['dealWorkflow'] }}</span>
                              </div>
                            </td>
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="dealColumns; sticky:true;"></tr>
                          <tr mat-row *matRowDef="let element; columns: dealColumns;">
                          </tr>
                        </table>
                        <!--              DEAL TABLE END-->
                      </div>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="['table-name','search-header']"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
                    class="example-element-row"
                    [class.example-expanded-row]="expandedElement === element"
                    [ngStyle]="{'cursor': canExpand(element) ? 'pointer' : ''}"
                    (click)="expandRow(element)">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter.</td>
                </tr>
              </table>
            </div>
          </div>
          <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[100, 200, 500]"
                         [pageSize]="100"
                         aria-label="Select page of approvals"></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
