import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IDealCardEvent, IDealTimeline} from "../../models/i-deal-card";

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit, OnChanges {

  readonly PADDING_LEFT = 1.5;

  @Input() desc: string = 'This is where the deal is';
  @Input() percent: number = 75;
  @Input() dealTimeline: IDealTimeline = {} as unknown as IDealTimeline;
  @Input() showTitles: boolean = false;

  circleHover: boolean[] = [];
  markerHover: boolean = false;

  constructor() {

  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes && changes['dealTimeline']) {
      this.dealTimeline = changes['dealTimeline'].currentValue;
      // console.log("DEAL TIMELINE: " + this.dealTimeline);
    }
  }

  getMarkerStyles(t: IDealCardEvent) {
    return {
      'border-color': '#AFAFAF',
      'background-color': this.getMarkerColor(t),
      'left': this.getPercent(t.xPos)
    }
  }

  getMarkerColor(t: IDealCardEvent) {
    switch (t.status) {
      case 'CURRENT':
        return '#0044c1';
      case 'FUTURE':
        return '#E8E8E8';
      case 'PAST':
        return '#0044c1';
      default:
        return '#444444';
    }
  }

  getCurrentColor(t: IDealCardEvent) {
    switch (t.status) {
      case 'CURRENT':
        return '#000';
      default:
        return '#000';
    }
  }

  getPercent(num: number | undefined, pad?: number): string {
    let n = !!num? num : 0;
    let p = !!pad? pad : 0;
    return (n + p).toString() + '%';
  }

  getPx(num: number): string {
    return num.toString() + 'px';
  }

  hasTitles(dealEvents: IDealCardEvent[], titlePos: string) {
    let hasTitles = false;
    if (!!dealEvents) {
      for (let i = 0; i <= dealEvents.length - 1; i++) {
        if (!!dealEvents[i].title && dealEvents[i].titlePos == titlePos) {
          hasTitles = true;
          break;
        }
      }
    }
    return hasTitles;
  }

  getHeight() {
    let min = 25;
    min += this.hasTitles(this.dealTimeline.markers, 'top') ? 25 : 0;
    min += this.hasTitles(this.dealTimeline.markers, 'bottom') ? 25 : 0;
    return min.toString() + 'px';
  }
}
