import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-entity-inner-navigation',
  templateUrl: './entity-inner-navigation.component.html',
  styleUrls: ['./entity-inner-navigation.component.scss']
})
export class EntityInnerNavigationComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
