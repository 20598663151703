<div class="rm-header">
  <span>Non-Disclosure Agreement</span>
</div>
<div>
  <div class="row justify-content-center" style="padding-bottom: 50px;padding-top: 25px;">
    <div class="col-11 nda">
      <div style="display: flex;">
        <mat-icon class="nda-icon">warning</mat-icon>&nbsp;
        <span class="nda-text">Negotiate {{data.ndaType}} NDA</span>
      </div>
      <br/><br/>
      <span>You will be sent an email with a link to the {{data.ndaType}} NDA on our negotiation platform. You
        will be able to review and make any mark-ups to the NDA on the platform.</span>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <button mat-raised-button (click)="dialogRef.close()">CANCEL
      </button>
    </div>
    <div class="col-6 text-end">
      <button mat-raised-button color="primary" (click)="dialogRef.close(true)">PROCEED
      </button>
    </div>
  </div>
</div>
