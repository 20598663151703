import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {CognitoUser, Config, getCognitoUser, getConfig, initialCognitoUserState, initialConfigState} from "../../redux";
import {RondeivuHttpClient} from "../../../handlers";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class EntitiesService {

  readonly BASE_URL = environment.rondeivu_api;
  readonly FRAGMENT = '/businessunitentity';
  readonly AUDIT = '/audit';
  private config: Config = initialConfigState;
  private cognito: CognitoUser = initialCognitoUserState;

  constructor(private http: RondeivuHttpClient, private store: Store) {
    this.store.select(getConfig).subscribe(c => {
      this.config = c;
    });
    this.store.select(getCognitoUser).subscribe(cu => {
      this.cognito = cu;
    });
  }

  getBusinessUnitEntities(): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT);
  }

  addBusinessUnitEntity(businessUnitEntity: any): Observable<any> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.BASE_URL + this.FRAGMENT, businessUnitEntity, {headers: httpHeaders});
  }

  getBusinessUnitEntityById(id: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + '/' + id);
  }

  updateBusinessUnitEntity(businessUnitEntity: any): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + '/' + businessUnitEntity.id, businessUnitEntity);
  }

  getBusinessUnitEntityAudit(): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.AUDIT + '/' + this.config.selected_business_unit_entity.id);
  }

}
