import {Injectable, OnDestroy} from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {from, Observable, Subscription} from 'rxjs';
import {ToastrService} from "ngx-toastr";
import {BusinessUnitService} from "../modules/business-units/services";
import {Store} from "@ngrx/store";
import {
  Config, getConfig,
  initialConfigState,
  updateEditingBusinessUnitEmployee
} from "../modules/redux";


@Injectable({
  providedIn: 'root'
})
export class BusinessUnitEmployeeResolver implements Resolve<boolean>, OnDestroy {

  private readonly LOGIN_PATH = '/auth/login';
  config$: Subscription = new Subscription();
  config: Config = initialConfigState;

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private businessUnitService: BusinessUnitService,
    private store: Store) {
    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return from(this.fetchAndDispatchBusinessUnitEmployee(route, state));
  }

  private fetchAndDispatchBusinessUnitEmployee(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let employeeId = route.paramMap.get('id') || '';
      this.businessUnitService.getEmployee(employeeId).subscribe({
        next: (employee: any) => {
          this.store.dispatch(updateEditingBusinessUnitEmployee({businessUnitEmployee: employee}));
          resolve(true);
        }, error: err => {
          reject(false);
          this.router.navigate(['/' + this.config.selected_business_unit.slug + '/business-units/' + this.config.editing_business_unit.businessUnitId + '/employees']).then(() => {
            this.toastr.error("Access denied!", $localize`:@@companyName:Rondeivu`);
          });
        }
      });
    });
  }
}
