import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {IBusinessUnit} from "../../../business-units/models";
import {MatTableDataSource} from "@angular/material/table";
import {
  Config,
  getConfig,
  getSelectedBusinessUnit, getSelectedUserId,
  initialConfigState,
  initialThemeState,
  Theme,
  toggleLoading
} from "../../../redux";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {ToastrService} from "ngx-toastr";
import {Store} from "@ngrx/store";
import {MatDialog} from "@angular/material/dialog";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {UsersService} from "../../services/users/users.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-user-business-units',
  templateUrl: './user-business-units.component.html',
  styleUrls: ['./user-business-units.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class UserBusinessUnitsComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  selectedUserId$: Subscription = new Subscription();
  selectedUserId = '';

  buRequest: IBusinessUnit = {} as unknown as IBusinessUnit;

  dataSource: MatTableDataSource<IBusinessUnit> = new MatTableDataSource();
  businessUnits: IBusinessUnit[] = [];
  config: Config = initialConfigState;
  theme: Theme = initialThemeState;
  columnsToDisplay = ['name', 'profile', 'type', 'accessType', 'view'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: IBusinessUnit | null = {} as IBusinessUnit;
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;

  constructor(private usersService: UsersService,
              private toastr: ToastrService,
              private router: Router,
              private store: Store,
              private dialog: MatDialog) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.selectedBusinessUnit = Object.assign({}, bu);
    });

    this.selectedUserId$ = this.store.select(getSelectedUserId).subscribe((id: string) => {
      this.selectedUserId = id;
      if (!!this.selectedBusinessUnit && !!this.selectedBusinessUnit.businessUnitId && !!this.selectedUserId) {
        this.subscribeToDatasource();
      }
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedUserId$.unsubscribe();
  }

  subscribeToDatasource(): void {
    console.log("Fetching User Business Units...");
    this.getBusinessUnits();
  }

  /**
   * Fetch the business units
   */
  getBusinessUnits() {
    this.usersService.getBusinessUnitsForUser(this.selectedUserId).subscribe((businessUnits: any) => {
      this.businessUnits = businessUnits;
      this.dataSource = new MatTableDataSource(this.businessUnits);
      this.initDataSource();
    }, (err: any) => {
      this.toastr.error("Something went wrong!");
    });
  }

  initDataSource() {
    this.dataSource.filterPredicate = (data: IBusinessUnit, filter: string) => {
      return data.slug.includes(filter) ||
        data.displayName.includes(filter) ||
        data.subType.includes(filter);
    };
    if (!!this.paginator) {
      this.paginator.pageSize = 10;
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  viewBusinessUnit(element:any){
    this.store.dispatch(toggleLoading({loading: true}));
    setTimeout(() => {
      this.store.dispatch(toggleLoading({loading: false}));
      this.router.navigate(['/' + this.config.selected_business_unit.slug +'/business-units/' + element.id]);
    }, 1000);
  }
}
