<div class="employee-header">
  <button type="button" mat-icon-button (click)="back()">
    <mat-icon>west</mat-icon>
  </button>
  <app-user-card
    [firstName]="selectedEmployee?.user?.firstName"
    [lastName]="selectedEmployee?.user?.lastName"
    [displayName]="selectedEmployee?.user?.email"
    [image]="selectedEmployee?.user?.imageUrl"
  >
  </app-user-card>
</div>
<router-outlet></router-outlet>
