import {Component, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {Subscription} from "rxjs";
import {IBusinessUnit, IBusinessUnitEmployee} from "../../models";
import {MatTableDataSource} from "@angular/material/table";
import {
  Config,
  getConfig,
  getEditingBusinessUnit,
  getLookups,
  getSelectedBusinessUnit,
  initialConfigState,
  initialLookupsState,
  initialThemeState,
  Lookups,
  Theme, toggleLoading
} from "../../../redux";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {ToastrService} from "ngx-toastr";
import {Store} from "@ngrx/store";
import {MatDialog} from "@angular/material/dialog";
import {
  DeleteConfirmationModalComponent, KycModalComponent, UserCardSize
} from "../../../shared/components";
import {Router} from "@angular/router";
import {KycService, UtilService} from "../../../shared/services";
import {HttpErrorResponse} from "@angular/common/http";
import {ModalSize} from "../../../shared/services/util/util.service";

@Component({
  selector: 'app-business-unit-kyc',
  templateUrl: './business-unit-kyc.component.html',
  styleUrls: ['./business-unit-kyc.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BusinessUnitKycComponent implements OnInit {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;
  selectedBusinessUnit$: Subscription = new Subscription();
  editingBusinessUnit$: Subscription = new Subscription();

  buRequest: IBusinessUnit = {} as unknown as IBusinessUnit;

  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  kycList: any[] = [];
  theme: Theme = initialThemeState;
  columnsToDisplay = ['User', 'profile', 'status', 'hasPendingKycProcesses', 'hasDeclinedKycProcesses', 'Date'];
  kycProcessCols = ['name', 'status', 'created', 'view'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: any | null = {};
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  adminBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;
  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;

  userCardSize = UserCardSize;

  constructor(private kycService: KycService,
              private toastr: ToastrService,
              private store: Store,
              private dialog: MatDialog,
              private router: Router,
              public util: UtilService
  ) {
    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
      this.buRequest = Object.assign({}, config.editing_business_unit);
    });
    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.adminBusinessUnit = Object.assign({}, bu);
    });

    this.editingBusinessUnit$ = this.store.select(getEditingBusinessUnit).subscribe((sbu: IBusinessUnit) => {
      if (!!sbu && this.selectedBusinessUnit.businessUnitId != sbu.businessUnitId) {
        this.selectedBusinessUnit = Object.assign({}, sbu);
        if (!!this.adminBusinessUnit && !!this.adminBusinessUnit.businessUnitId &&
          !!this.selectedBusinessUnit && !!this.selectedBusinessUnit.businessUnitId &&
          this.adminBusinessUnit.businessUnitType == 'ADMIN') {
          this.subscribeToDatasource();
        }
      }
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.editingBusinessUnit$.unsubscribe();
  }

  subscribeToDatasource(): void {
    console.log("Fetching Business Unit Employees...");
    this.getKycs();
  }

  /**
   * Main data fetch
   */
  getKycs() {
    this.kycService.getKycs().subscribe({
      next: (kyc: any[]) => {
        this.kycList = kyc;
        if (this.kycList.length > 1) {
          this.toastr.warning("More than one business unit returned!", $localize`:@@companyName:Rondeivu`);
        }
        this.dataSource = new MatTableDataSource(this.kycList);
        this.initDataSource();
      }, error: (err: HttpErrorResponse) => {
        this.toastr.error("Unable to get KYC!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  initDataSource() {
    this.dataSource.filterPredicate = (data: IBusinessUnitEmployee, filter: string) => {
      return data.firstName.includes(filter) ||
        data.lastName.includes(filter) ||
        data.title.includes(filter);
    };
    if (!!this.paginator) {
      this.paginator.pageSize = 10;
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * Adds a kyc process to this business unit
   * @param kycObject
   */
  addKyc(kycObject?: any) {
    // if this is the first kyc we take the profile from the editing business unit
    let profile = this.config.editing_business_unit.businessUnitType;
    if (!!kycObject) {
      profile = kycObject.businessUnit?.profile;
    }

    const delRef = this.dialog.open(KycModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      disableClose: true,
      data: {
        isEditing: false,
        kyc: {},
        profile: profile
      }
    });

    delRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.store.dispatch(toggleLoading({loading: true}));
        this.kycService.addKyc(result).subscribe({
          next: (res: any) => {
            this.subscribeToDatasource();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("KYC Initiated!", $localize`:@@companyName:Rondeivu`);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to add kyc!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  /**
   * Deletes a KYC process from this business unit
   * @param kycProcess
   */
  delete(kycProcess: any) {
    const delRef = this.dialog.open(DeleteConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      data: kycProcess
    });

    delRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.store.dispatch(toggleLoading({loading: true}));
        this.kycService.deleteKyc(kycProcess).subscribe(res => {
          this.subscribeToDatasource();
          this.store.dispatch(toggleLoading({loading: false}));
          this.toastr.success("KYC Removed!", $localize`:@@companyName:Rondeivu`);
        }, err => {
          this.store.dispatch(toggleLoading({loading: false}));
          this.toastr.error("Unable to remove kyc!", $localize`:@@companyName:Rondeivu`);
        });
      }
    });
  }

  /**
   * View the individual kyc process
   * @param process
   */
  viewKycProcess(process: any) {
    this.store.dispatch(toggleLoading({loading: true}));
    setTimeout(() => {
      this.store.dispatch(toggleLoading({loading: false}));
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/kyc/' + process?.id]);
    }, 1000);
  }

  /**
   * Updates the business unit kyc status
   * @param event
   */
  updateStatus(event: any) {
    let payload = {
      status: event.value
    };
    this.store.dispatch(toggleLoading({loading: true}));
    this.kycService.updateBusinessUnitKycStatus(payload).subscribe((res: any) => {
      this.store.dispatch(toggleLoading({loading: false}));
      this.toastr.success("KYC Status Updated!", $localize`:@@companyName:Rondeivu`);
    }, (err: any) => {
      this.store.dispatch(toggleLoading({loading: false}));
      this.toastr.error("Unable to update kyc status!", $localize`:@@companyName:Rondeivu`);
    });
  }

}
