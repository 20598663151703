<div style="height:calc(100vh - 176px); overflow-y: auto;">
  <div class="container-fluid">
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-12 col-md-11">
        <span class="rm-title rm-title-3">Business Units</span><br/>
        <span class="rm-title rm-title-6 rm-lighter">These are all of the user's business units:</span>
      </div>
    </div>
    <!--    table start-->
    <div class="row justify-content-center d-none d-md-flex" style="padding-bottom: 150px; padding-top: 15px;">
      <div class="col-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <table mat-table [dataSource]="dataSource" [multiTemplateDataRows]="true" matSort>
                <!-- Search Header -->
                <ng-container matColumnDef="table-name">
                  <th colspan="2" mat-header-cell *matHeaderCellDef>
                    <span style="font-size: large;">User Business Units</span>
                  </th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="search-header">
                  <th colspan="3" mat-header-cell *matHeaderCellDef>
                    <form>
                      <mat-form-field style="width: 100%;">
                        <mat-label>Search</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" #input>
                        <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                    </form>
                  </th>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Business Unit</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    <app-user-card [firstName]="element['displayName']"
                                   [displayName]="element['slug']"
                                   [borderColor]="'transparent'"
                                   [sideColor]="'#ff0000'"
                                   [image]="element['imageUrl']"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="slug">
                  <th mat-header-cell *matHeaderCellDef> Business Unit Slug</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{element?.slug}}
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="profile">
                  <th mat-header-cell *matHeaderCellDef> Profile Type</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{element?.profile}}
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef> Sub Type</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{element?.subType}}
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="accessType">
                  <th mat-header-cell *matHeaderCellDef> Access Type</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{element?.accessType}}
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="view">
                  <th mat-header-cell *matHeaderCellDef> View</th>
                  <td mat-cell *matCellDef="let element">
                      <button mat-icon-button (click)="viewBusinessUnit(element)" matTooltip="View Business Unit" matTooltipPosition="left">
                        <mat-icon>east</mat-icon>
                      </button>
                  </td>
                </ng-container>

                <!-- EXPAND -->
                <ng-container matColumnDef="expand">
                  <th mat-header-cell *matHeaderCellDef aria-label="row actions" style="width: 50px;">&nbsp;</th>
                  <td mat-cell *matCellDef="let approval" style="width: 50px;">
                    <button mat-icon-button aria-label="expand row"
                            (click)="(expandedElement = expandedElement === approval ? null : approval); $event.stopPropagation()">
                      <mat-icon *ngIf="expandedElement !== approval">keyboard_arrow_down</mat-icon>
                      <mat-icon *ngIf="expandedElement === approval">keyboard_arrow_up</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                  <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                    <div class="example-element-detail"
                         [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                      <div class="approval-row detail-row">
                      </div>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="['table-name','search-header']"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                    class="example-element-row"
                    [class.example-expanded-row]="expandedElement === element">
                  <!--              (click)="expandedElement = expandedElement === element ? null : element">-->
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter.</td>
                </tr>
              </table>
            </div>
          </div>
          <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[5, 10, 25, 100]"
                         aria-label="Select page of users"></mat-paginator>
        </div>
      </div>
    </div>
    <!--    list start-->
    <div class="row justify-content-center d-flex d-md-none" style="padding-bottom: 150px; padding-top: 15px;">
      <div class="col-12">
        <ul style="list-style-type: none; padding: 25px 0 0 0;">
          <li *ngFor="let element of dataSource.data"
              style="margin-bottom: 5px; display: flex; justify-content: space-between;">
            <app-user-card [firstName]="element['displayName']"
                           [displayName]="element['slug']"
                           [borderColor]="'transparent'"
                           [sideColor]="'#ff0000'"
                           [image]="element['imageUrl']"
            ></app-user-card>
            <div style="display: flex;">
              <button mat-icon-button (click)="viewBusinessUnit(element)" matTooltip="View Business Unit"
                      matTooltipPosition="left">
                <mat-icon>east</mat-icon>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

