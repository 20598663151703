import {Component, OnInit} from '@angular/core';
import {BusinessUnitService} from "../../../business-units/services";
import {ToastrService} from "ngx-toastr";
import {Subscription} from "rxjs";
import {
  Config,
  getConfig,
  getSelectedBusinessUnit,
  initialConfigState,
  initialThemeState,
  Theme,
  toggleLoading
} from "../../../redux";
import {IBusinessUnit} from "../../../business-units/models";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {MatDialog} from "@angular/material/dialog";

interface IPermResponse {
  bu: { name: string, allPossiblePermissions: string[] }[];
  gdt: { name: string, allPossiblePermissions: string[] }[];
}

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  config: Config = initialConfigState;
  theme: Theme = initialThemeState;

  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;
  permissions: IPermResponse = {} as unknown as IPermResponse;

  constructor(private businessUnitService: BusinessUnitService,
              private toastr: ToastrService,
              private router: Router,
              private store: Store,
              private dialog: MatDialog) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.selectedBusinessUnit = Object.assign({}, bu);
      if (!!this.selectedBusinessUnit && !!this.selectedBusinessUnit.businessUnitId) {
        this.subscribeToDatasource();
      }
    });

  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
  }

  subscribeToDatasource(): void {
    this.getAll();
  }

  getAll() {
    this.businessUnitService.getAllPermissions().subscribe({
      next: (permissions: IPermResponse) => {
        this.permissions = permissions;
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to get permissions!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

}
