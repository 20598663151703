<div class="main-dash">
  <div class="row">
    <div id="deal-body">
      <!--add button-->
      <div class="row justify-content-end" style="padding-bottom: 50px;" *ngIf="deals.length > 0">
        <div class="col-12 text-end">
          <button id="add-btn" mat-fab color="warn" (click)="openModal()">
            <mat-icon>library_add</mat-icon>
          </button>
        </div>
      </div>
      <!--      deal cards-->
      <div class="row" *ngIf="deals.length > 0">
        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4 deal-row" *ngFor="let d of deals;index as i;">
          <app-deal-card class="deal-card" [deal]="deals[i]"></app-deal-card>
        </div>
      </div>
      <!--deal cards end-->
      <!--  no deals start-->
      <div style="padding-top:250px;" *ngIf="type == 'pending' && deals.length == 0">
        <div class="row justify-content-center" *ngIf="showMandateNotification">
          <div class="col-6 notification text-center" (click)="openMandateModal(); showMandateNotification=false;">
            <span class="welcome-msg tracking-in-expand-fwd">Welcome <b>{{getName()}}</b>, please click here to <u>add a <i>Mandate</i></u></span>&nbsp;
          </div>
        </div>
        <div class="row justify-content-center" *ngIf="showDealNotification">
          <div class="col-6 notification text-center" (click)="openDealModal(); showDealNotification=false;">
            <span class="welcome-msg tracking-in-expand-fwd">Welcome <b>{{getName()}}</b>, please click here to <u>add a <i>Deal</i></u></span>&nbsp;&nbsp;
          </div>
        </div>
      </div>
      <!--  no closed deals start-->
      <div class="row justify-content-center" style="padding-top:250px;"
           *ngIf="type == 'closed' || type == 'live' && deals.length==0">
        <div class="col-4" style="border: 1px dashed dimgray; padding: 25px 15px 25px 15px; border-radius: 12px;">
          <div class="row justify-content-center">
            <div class="col-8">
              <h2 style="color: dimgray"> You have no {{type}} deals</h2>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

