import {Component, OnDestroy, OnInit} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {Store} from "@ngrx/store";
import {
  Config,
  getConfig,
  getEditingBusinessUnit,
  getLookups,
  getSelectedBusinessUnit,
  initialConfigState,
  initialLookupsState,
  Lookups,
  toggleLoading
} from "../../../redux";
import {BusinessUnitService} from "../../services";
import {IBusinessUnit, IBusinessUnitAdminSettings} from "../../models";
import {FormControl, FormGroup} from "@angular/forms";
import {HttpErrorResponse} from "@angular/common/http";
import {MAT_DATE_FORMATS} from "@angular/material/core";
import {MY_DATE_FORMATS} from "../../../ng-material/ng-material.module";
import {Subscription} from "rxjs";
import {ConfirmationModalComponent, UserCardModalComponent, UserCardSize} from "../../../shared/components";
import {ModalSize, UtilService} from "../../../shared/services/util/util.service";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-business-unit-info',
  templateUrl: './business-unit-info.component.html',
  styleUrls: ['./business-unit-info.component.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS}
  ]
})
export class BusinessUnitInfoComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  editingBusinessUnit$: Subscription = new Subscription();

  lookups: Lookups = initialLookupsState;
  config: Config = initialConfigState;
  employees: any[] = [];
  buCache: IBusinessUnit = {} as unknown as IBusinessUnit;
  adminCache: IBusinessUnitAdminSettings = {} as unknown as IBusinessUnitAdminSettings;

  userCardSize = UserCardSize;

  userForm = new FormGroup({
    slug: new FormControl('', []),
    displayName: new FormControl('', []),
    internalName: new FormControl('', []),
    businessUnitId: new FormControl('', []),
    businessUnitType: new FormControl({value: '', disabled: true}, []),
    kycStatus: new FormControl('', []),
    domicile: new FormControl('', []),
    subType: new FormControl('', []),
    status: new FormControl('', []),
    intakeDescription: new FormControl('', []),
    legalEntityDomicile: new FormControl('', []),
    representative: new FormControl(),
    profileLink: new FormControl(),
    imageUrl: new FormControl()
  });

  adminForm = new FormGroup({
    businessUnitId: new FormControl(),
    relationshipStartDate: new FormControl(new Date(), []),
    accreditationPassed: new FormControl(false, []),
    globalNdaSigned: new FormControl('', []),
    workflowStatus: new FormControl('', []),
    attestationSignedComments: new FormControl('', [])
  });

  adminBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;
  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;

  constructor(
    private businessUnitService: BusinessUnitService,
    private toastr: ToastrService,
    private store: Store,
    public util: UtilService,
    private dialog: MatDialog
  ) {
    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.adminBusinessUnit = Object.assign({}, bu);
    });

    this.editingBusinessUnit$ = this.store.select(getEditingBusinessUnit).subscribe((sbu: IBusinessUnit) => {
      if (!!sbu && this.selectedBusinessUnit.businessUnitId != sbu.businessUnitId) {
        this.selectedBusinessUnit = Object.assign({}, sbu);
        if (!!this.adminBusinessUnit && !!this.adminBusinessUnit.businessUnitId &&
          !!this.selectedBusinessUnit && !!this.selectedBusinessUnit.businessUnitId &&
          this.adminBusinessUnit.businessUnitType == 'ADMIN') {
          this.subscribeToDatasource();
        }
      }
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.editingBusinessUnit$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
  }

  subscribeToDatasource(): void {
    console.log("Fetching Business Unit Information...");
    this.fetchRondeivuEmployeesUsers();
    this.fetchBusinessUnitInfo();
    this.fetchBusinessUnitAdminSettings();
  }

  fetchBusinessUnitInfo() {
    this.businessUnitService.getBusinessUnit(this.config.editing_business_unit.businessUnitId).subscribe(bu => {
      this.buCache = Object.assign({}, bu);
      this.userForm.patchValue(this.buCache);
      // The whole representative is returned on the response but only id is required
      if (!!this.buCache && !!this.buCache.representative) {
        this.userForm.get('representative')?.setValue(this.buCache.representative.id);
      }
    }, (error: HttpErrorResponse) => {
      this.toastr.error("Unable to get business unit information!", $localize`:@@companyName:Rondeivu`);
    });
  }

  fetchBusinessUnitAdminSettings() {
    this.businessUnitService.getBusinessUnitAdminSettings().subscribe(as => {
      this.adminCache = Object.assign({}, as);
      this.adminForm.patchValue(this.adminCache);
    }, (error: HttpErrorResponse) => {
      this.toastr.error("Unable to get business unit settings!", $localize`:@@companyName:Rondeivu`);
    });
  }

  fetchRondeivuEmployeesUsers() {
    this.businessUnitService.getRondeivuEmployees().subscribe(reu => {
      this.employees = Object.assign([], reu);
    }, (error: HttpErrorResponse) => {
      this.toastr.error("Unable to get Rondeivu employees!", $localize`:@@companyName:Rondeivu`);
    });
  }

  save() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.businessUnitService.editBusinessUnit(this.userForm.getRawValue() as IBusinessUnit).subscribe({
      next: (emp: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Business Unit Information Saved!", $localize`:@@companyName:Rondeivu`);
      }, error: (error: HttpErrorResponse) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to save business unit information!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  adminSave() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.businessUnitService.editBusinessUnitAdminSettings(this.adminForm.getRawValue()).subscribe(emp => {
      this.store.dispatch(toggleLoading({loading: false}));
      this.toastr.success("Admin Settings Saved!", $localize`:@@companyName:Rondeivu`);
    }, (error: HttpErrorResponse) => {
      this.store.dispatch(toggleLoading({loading: false}));
      this.toastr.error("Unable to save admin settings!", $localize`:@@companyName:Rondeivu`);
    });
  }

  openImageUploadModal() {
    const dialogRef = this.dialog.open(UserCardModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog closed!");
      if (!!result) {
        const id = this.userForm.get('businessUnitId')?.value || '';
        const file = new File([result], 'user.png');
        this.businessUnitService.uploadBusinessUnitImage(file, id).subscribe({
          next: (res: any) => {
            let url = res.url || ''
            this.userForm.get('imageUrl')?.setValue(url);
            this.toastr.success("Image Uploaded! ", $localize`:@@companyName:Rondeivu`);
          }, error: (err: any) => {
            this.toastr.error("Something went wrong! " + err.message);
          }
        });
      }
    });
  }

  clearImage() {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog closed!");
      if (!!result) {
        const id = this.userForm.get('businessUnitId')?.value || '';
        this.businessUnitService.clearBusinessUnitImage(id).subscribe({
          next: (res: any) => {
            let url = res.url || ''
            this.userForm.get('imageUrl')?.setValue(url);
            this.toastr.success("Image Cleared! ", $localize`:@@companyName:Rondeivu`);
          }, error: (err: any) => {
            this.toastr.error("Something went wrong! " + err.message);
          }
        });
      }
    });
  }

}
