import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {
  CognitoUser, Config,
  getCognitoUser, getConfig,
  initialCognitoUserState,
  initialConfigState,
} from "../../../redux";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit,OnDestroy {
  config$:Subscription = new Subscription();
  config: Config = initialConfigState;

  constructor(private store:Store) { }

  ngOnInit(): void {
    //sub the config
    this.config$ = this.store.select(getConfig).subscribe((config:Config)=>{
      this.config = config;
    });
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
  }
}
