<div class="rm-header">
  <span>Subscribe</span>
</div>
<div class="row justify-content-center" style="padding-bottom: 10px; padding-top: 25px;">
  <div class="col-11">
    <span style="font-size: medium;">
      <p>Please complete the information below to subscribe to this deal and allow Rondeivu to prepare your documents.</p>
      <p>
        <i>Note: By clicking Subscribe you are not making a binding commitment to invest. Completion of this form does not guarantee that your subscription will be accepted by the issuer.</i>
      </p>
    </span>
  </div>
</div>
<br/>
<div class="row justify-content-center" style="padding-bottom: 50px;">
  <div class="col-11 text-center">
    <form>
      <mat-form-field class="form-field">
        <mat-label>Subscription Amount</mat-label>
        <input id="amt" name="amt" type="number" [min]="0" [(ngModel)]="data.subscriptionAmount" matInput
               placeholder="Enter amount in millions USD" [required]="true">
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>Notes</mat-label>
        <textarea id="notes" name="notes" [(ngModel)]="data.notes" matInput placeholder="Please provide any additional notes regarding your subscription, such as contingencies or ongoing due diligence processes. These notes will be visible to Rondeivu and the Issuer."></textarea>
      </mat-form-field>
    </form>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-6">
    <button mat-raised-button (click)="dialogRef.close()">CANCEL</button>
  </div>
  <div class="col-6 text-end">
    <button mat-raised-button color="primary" (click)="dialogRef.close(data)" [disabled]="!data.subscriptionAmount || data.subscriptionAmount < 0">SUBMIT</button>
  </div>
</div>
