import {Injectable, OnDestroy} from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {from, Observable, Subscription} from 'rxjs';
import {ToastrService} from "ngx-toastr";
import {Store} from "@ngrx/store";

import {
  Config,
  getConfig, initialConfigState,
  updateDealTeamMember,
} from "../modules/redux";
import {DealTeamMembersService} from "../modules/deals/services";

@Injectable({
  providedIn: 'root'
})
export class DealTeamMemberResolver implements Resolve<boolean>, OnDestroy {

  config$: Subscription = new Subscription();
  config: Config = initialConfigState;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private dealTeamMemberService: DealTeamMembersService,
    private store: Store
  ) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return from(this.fetchAndDispatchDealTeamMember(route, state));
  }

  private fetchAndDispatchDealTeamMember(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let dealTeamMemberId = route.paramMap.get('id') || '';
      this.dealTeamMemberService.getDealTeamMemberById(dealTeamMemberId).subscribe({
        next: (member: any) => {
          this.store.dispatch(updateDealTeamMember({dealTeamMember: member}));
          resolve(true);
        }, error: (error: any) => {
          reject(false);
          this.router.navigate(['/' + this.config.selected_business_unit.slug + '/deals/' + this.config.selected_deal.id + '/deal-team']).then(() => {
            this.toastr.error("Access denied!", $localize`:@@companyName:Rondeivu`);
          });
        }
      });
    });
  }
}
