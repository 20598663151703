import {UserSettings} from "./user.model";

export const initialUserState: UserSettings = new UserSettings({
  emailVerified: false,
  phoneNumberVerified: false,
  id: '',
  subId: '',
  firstName: '',
  lastName: '',
  email: '',
  countryCode: '',
  mobile: '',
  created: '',
  profileLink: '',
  imageUrl: ''
});
