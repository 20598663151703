export {AdminConfigComponent} from "./admin-config/admin-config.component";
export {AdminConfigModalComponent} from "./admin-config-modal/admin-config-modal.component";
export {AdminNavigationComponent} from "./admin-navigation/admin-navigation.component";
export {AdminPanelComponent} from "./admin-panel/admin-panel.component";
export {RondeivuEmployeesComponent} from "./rondeivu-employees/rondeivu-employees.component";
export {
  RondeivuEmployeeModalComponent
} from "./rondeivu-employees/rondeivu-employee-modal/rondeivu-employee-modal.component";
export {UserEmailNotificationsComponent} from "./user-email-notifications/user-email-notifications.component";


//cloudwatch
export {CloudwatchSnsBounceLogComponent} from './cloudwatch-sns-bounce-log/cloudwatch-sns-bounce-log.component';
export {
  CloudwatchSnsComplaintLogComponent
} from './cloudwatch-sns-complaint-log/cloudwatch-sns-complaint-log.component';
export {CloudwatchSnsDeliveryLogComponent} from './cloudwatch-sns-delivery-log/cloudwatch-sns-delivery-log.component';
export {CloudwatchSnsLogComponent} from './cloudwatch-sns-log/cloudwatch-sns-log.component';

export {PermissionsComponent} from './permissions/permissions.component';
