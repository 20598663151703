import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class MockDealCardService {
  env = environment;
  fragment = '/deal-cards';

  constructor(private http: HttpClient) {}

  getDealCards():Observable<any> {
    return this.http.get(this.env.rondeivu_api_mock + this.fragment);
  }

  getDealCard(id:string):Observable<any> {
    return this.http.get(this.env.rondeivu_api_mock + this.fragment + '/' + id);
  }
}
