import {Injectable} from '@angular/core';
import {Config, getConfig, initialConfigState} from "../../../redux";
import {environment} from "../../../../../environments/environment";
import {Store} from "@ngrx/store";
import {RondeivuHttpClient} from "../../../../handlers";
import {UuidService} from "../../../../services";
import {Observable} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class DealNdaService {
  private config: Config = initialConfigState;
  private readonly BASE_URL = environment.rondeivu_api;
  private readonly FRAGMENT = '/nda';
  private readonly DEAL_FRAGMENT = '/deal';

  private readonly STATUS_FRAGMENT = '/nda-status';
  private readonly GENERATE_NDA_FRAGMENT = '/generate-nda';
  private readonly NDA_DOC_INFO_FRAGMENT = '/nda-document-info';
  private readonly NDA_DOC_DOWNLOAD_FRAGMENT = '/nda-document-download';
  private readonly GENERATE_SENDER_FRAGMENT = '/generate-nda-sender-embedded-signing';
  private readonly GENERATE_RECIPIENT_FRAGMENT = '/generate-nda-recipient-embedded-signing';
  private readonly NEGOTIATE_FRAGMENT = '/negotiate-nda';


  constructor(private store: Store, private http: RondeivuHttpClient, private uuid: UuidService) {
    this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
  }

  /**
   * Get all NDA
   */
  getNda(): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT);
  }

  /**
   * Get NDA by deal id
   */
  getNdaByDealId(dealId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.DEAL_FRAGMENT + '/' + dealId);
  }

  /**
   * Get NDA status by envelope id
   */
  getNdaStatusByEnvelopeId(envelopeId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.STATUS_FRAGMENT + '/' + envelopeId);
  }

  /**
   * Get NDA status by envelope id
   */
  downloadNdaByEnvelopeId(envelopeId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.NDA_DOC_DOWNLOAD_FRAGMENT + '/' + envelopeId, {responseType: 'blob'});
  }

  /**
   * Generate embedded link for NDA based on type of NDA
   * @param payload
   */
  createNda(payload: { ndaType: string, dealId: string }) {
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.GENERATE_NDA_FRAGMENT, payload);
  }

  /**
   * Get envelope info from docusign
   */
  getNdaDocumentInfo(envelopeId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.NDA_DOC_INFO_FRAGMENT + '/' + envelopeId);
  }

  /**
   * Download signed doc from docusign
   */
  downloadNdaDocument(envelopeId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.NDA_DOC_DOWNLOAD_FRAGMENT + '/' + envelopeId);
  }

  /**
   * generate the NDA sender signing url
   */
  generateNdaSenderUrl(envelopeId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.GENERATE_SENDER_FRAGMENT + '/' + envelopeId);
  }

  /**
   * generate the NDA recipient signing url
   */
  generateNdaRecipientUrl(envelopeId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.GENERATE_RECIPIENT_FRAGMENT + '/' + envelopeId);
  }

  /**
   * negotiate the NDA
   */
  negotiateNda(type: string, dealId: string) {
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.NEGOTIATE_FRAGMENT, {ndaType: type, dealId: dealId});
  }


}
