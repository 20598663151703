import { Component, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { UuidService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-test-page',
  templateUrl: './test-page.component.html',
  styleUrls: ['./test-page.component.scss']
})
export class TestPageComponent implements OnInit {

  links = [
    { title: 'Live Deals', link: '/live-deals' },
    { title: 'Saved Deals', link: '/saved-deals' },
    { title: 'Closed Deals', link: '/closed-deals' },
    { title: 'Pending Approval', link: '/pending-approval' }
  ];
  activeLink = this.links[0];
  background: ThemePalette = undefined;
  gfg = [
    {
      status: "Time 1",
    },
    {
      status: "Time 2",
    },
    {
      status: "Time 3",
    },
    {
      status: "Time 4",
    },
  ];


  constructor(public uuidService: UuidService,) { }

  ngOnInit(): void {
  }

  toggleBackground() {
    this.background = this.background ? undefined : 'primary';
  }

  addLink() {
    let l = `Link ${this.links.length + 1}`;
    this.links.push({title:l,link:l});
  }

  getEnv() {
    return JSON.stringify(environment);
  }

}
