import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {createReducer, on} from "@ngrx/store";
import * as DealActions from './deal.actions';
import {IDealCard} from "../../../shared/models";

export interface DealState extends EntityState<IDealCard> {
  selectedDealId: string | null;
}

export const adapter: EntityAdapter<IDealCard> = createEntityAdapter<IDealCard>({
  selectId: selectDealId,
  sortComparer: sortByName,
});

export const initialDealState: DealState = adapter.getInitialState({
  // additional entity state properties
  selectedDealId: null,
});

export const dealReducer = createReducer(initialDealState,
  on(DealActions.addDeal, (state, {deal}) => {
    return adapter.addOne(deal, state)
  }),
  on(DealActions.setDeal, (state, {deal}) => {
    return adapter.setOne(deal, state)
  }),
  on(DealActions.upsertDeal, (state, {deal}) => {
    return adapter.upsertOne(deal, state);
  }),
  on(DealActions.addDeals, (state, {deals}) => {
    return adapter.addMany(deals, state);
  }),
  on(DealActions.upsertDeals, (state, {deals}) => {
    return adapter.upsertMany(deals, state);
  }),
  on(DealActions.updateDeal, (state, {update}) => {
    return adapter.updateOne(update, state);
  }),
  on(DealActions.updateDeals, (state, {updates}) => {
    return adapter.updateMany(updates, state);
  }),
  on(DealActions.mapDeal, (state, {entityMap}) => {
    return adapter.mapOne(entityMap, state);
  }),
  on(DealActions.mapDeals, (state, {entityMap}) => {
    return adapter.map(entityMap, state);
  }),
  on(DealActions.deleteDeal, (state, {id}) => {
    return adapter.removeOne(id, state);
  }),
  on(DealActions.deleteDeals, (state, {ids}) => {
    return adapter.removeMany(ids, state);
  }),
  on(DealActions.deleteDealsByPredicate, (state, {predicate}) => {
    return adapter.removeMany(predicate, state);
  }),
  on(DealActions.loadDeals, (state, {deals}) => {
    return adapter.setAll(deals, state);
  }),
  on(DealActions.setDeals, (state, {deals}) => {
    return adapter.setMany(deals, state);
  }),
  on(DealActions.clearDeals, state => {
    return adapter.removeAll({...state, selectedDealId: null});
  })
);

export const getSelectedDealId = (state: DealState) => state.selectedDealId;

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of deal ids
export const selectDealIds = selectIds;

// select the dictionary of deal entities
export const selectDealEntities = selectEntities;

// select the array of deals
export const selectAllDeals = selectAll;

// select the total deal count
export const selectDealTotal = selectTotal;

export function selectDealId(a: IDealCard): string {
  //In this case this would be optional since primary key is id
  return a.id;
}

export function sortByName(a: IDealCard, b: IDealCard): number {
  if (!a.assetName || !b.assetName) {
    return 0;
  }
  return a.assetName.localeCompare(b.assetName);
}


