import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from "../shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxChartsModule} from "@swimlane/ngx-charts";
import {HttpClientModule} from "@angular/common/http";
import {NgMaterialModule} from "../ng-material/ng-material.module";
import {
  EntityInformationComponent,
  EntityInnerNavigationComponent,
  EntityKycListComponent,
  EntityKycModalComponent,
  EntityKycNavigationComponent,
  EntityListComponent,
  EntityModalComponent,
  EntityNavigationComponent,
  KycBusinessModalComponent,
  KycBusinessSelectModalComponent,
  KycImportModalComponent,
  KycInputComponent,
  KycInputSectionQuestionsComponent,
  KycInputSectionTextComponent,
  KycPersonModalComponent,
  KycPersonSelectModalComponent
} from "./components";
import {EntitiesRoutingModule} from "./entities-routing.module";
import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";


@NgModule({
  declarations: [
    EntityInformationComponent,
    EntityNavigationComponent,
    EntityListComponent,
    EntityModalComponent,
    KycPersonModalComponent,
    KycPersonSelectModalComponent,
    KycBusinessSelectModalComponent,
    KycBusinessModalComponent,
    KycInputComponent,
    EntityKycListComponent,
    EntityKycNavigationComponent,
    EntityInnerNavigationComponent,
    EntityKycModalComponent,
    KycInputSectionTextComponent,
    KycInputSectionQuestionsComponent,
    KycImportModalComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EntitiesRoutingModule,
    NgxChartsModule,
    HttpClientModule,
    NgMaterialModule,
    NgxExtendedPdfViewerModule
  ],
  providers: [],
  exports: []
})
export class EntitiesModule {
}
