import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {
  Config,
  getConfig,
  getLookups,
  initialConfigState,
  initialLookupsState,
  Lookups,
  toggleLoading
} from "../../../redux";
import {FormControl, FormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {UtilService} from "../../../shared/services";
import {UuidService} from "../../../../services";
import {IKycPerson} from "../../models/i-kyc-person";
import {EntityKycPersonService} from "../../services/entity-kyc-person.service";
import {ComplyAdvantageService} from "../../services/comply-advantage.service";
import {Level} from "../../models/kyc";

@Component({
  selector: 'app-kyc-person-modal',
  templateUrl: './kyc-person-modal.component.html',
  styleUrls: ['./kyc-person-modal.component.scss']
})
export class KycPersonModalComponent implements OnInit {

  @ViewChild('fileUpload') private fileInput: ElementRef | undefined;
  protected readonly Level = Level;

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;

  form = new FormGroup({
    id: new FormControl(''),
    rondeivuStatus: new FormControl({value: '', disabled: this.data.disabled}),
    businessUnitId: new FormControl(''),
    businessUnitEntityKycId: new FormControl(''),

    firstName: new FormControl({value: '', disabled: this.data.disabled}),
    lastName: new FormControl({value: '', disabled: this.data.disabled}),
    middleName: new FormControl({value: '', disabled: this.data.disabled}),
    suffix: new FormControl({value: '', disabled: this.data.disabled}),
    jobTitle: new FormControl({value: '', disabled: this.data.disabled}),
    idType: new FormControl({value: '', disabled: this.data.disabled}),
    idNumber: new FormControl({value: '', disabled: this.data.disabled}),
    streetAddress: new FormControl({value: '', disabled: this.data.disabled}),
    city: new FormControl({value: '', disabled: this.data.disabled}),
    countryId: new FormControl({value: '', disabled: this.data.disabled}),
    stateOrProvince: new FormControl({value: '', disabled: this.data.disabled}),
    zipPostalCode: new FormControl({value: '', disabled: this.data.disabled}),
    kycPersonType: new FormControl({value: 'BASIC', disabled: this.data.disabled}),
    principalOccupation: new FormControl({value: '', disabled: this.data.disabled}),
    yearofBirth: new FormControl({value: 1999, disabled: this.data.disabled}),

    //government issued id
    personGovernmentIssuedIdNumber: new FormControl({value: '', disabled: this.data.disabled}),
    personGovernmentIssuedIdType: new FormControl(),
    personGovernmentIssuedIdExpiryDate: new FormControl(),
    //proof of address
    personProofofAddressDocuments: new FormControl(),
    personProofofAddressDocumentType: new FormControl(),
    personProofofAddressDocumentOtherName: new FormControl({value: '', disabled: this.data.disabled}),
    personProofofAddressExpiryDate: new FormControl(),
    personProofofAddressIssuedDate: new FormControl(),
    //proof of id
    personProofofIdentificationDocuments: new FormControl(),
    personProofofIdentificationIdType: new FormControl(),
    personProofofIdentificationOtherName: new FormControl({value: '', disabled: this.data.disabled}),
    personProofofIdentificationIdExpiryDate: new FormControl()

  });

  constructor(public dialogRef: MatDialogRef<KycPersonModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { person: IKycPerson, level: string, disabled: boolean },
              private store: Store,
              private toastr: ToastrService,
              private personService: EntityKycPersonService,
              private complyAdvantageService: ComplyAdvantageService,
              public util: UtilService, private uuid: UuidService) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });
  }

  ngOnInit(): void {
    if (!!this.data.person) {
      this.form.patchValue(this.data.person);
    } else {
      this.form.get('businessUnitId')?.setValue(this.config.selected_business_unit.businessUnitId);
    }

    if (!!this.data.level) {
      this.form.get('kycPersonType')?.setValue(this.data.level);
    }
  }

  submit(): void {
    this.dialogRef.close(this.form.getRawValue());
  }

  isKyc(type: string) {
    return this.form.get('kycPersonType')?.value == type;
  }

  changeStatus() {
    this.store.dispatch(toggleLoading({loading: true}));
    let id = this.form.get('id')?.value || '';
    let status = this.form.get('rondeivuStatus')?.value || '';
    this.personService.updatePersonStatus(id, {kycPersonId: id, kycPersonRondeivuStatus: status}).subscribe({
      next: (res: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Status Updated!", $localize`:@@companyName:Rondeivu`);
      },
      error: (err) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Could not update status!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  getSearches() {
    this.store.dispatch(toggleLoading({loading: true}));
    let id = this.data.person.searchId;
    this.complyAdvantageService.getSearchedById(id.toString()).subscribe({
      next: (res: any) => {
        console.log(res);
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Search Logged!", $localize`:@@companyName:Rondeivu`);
      },
      error: (err) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Could not get comments!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  getComments() {
    this.store.dispatch(toggleLoading({loading: true}));
    let id = this.data.person.searchId;
    this.complyAdvantageService.getCommentsBySearchId(id.toString()).subscribe({
      next: (res: any) => {
        console.log(res);
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Comments Logged!", $localize`:@@companyName:Rondeivu`);
      },
      error: (err) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Could not get comments!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  getDetails() {
    this.store.dispatch(toggleLoading({loading: true}));
    let id = this.data.person.searchId;
    this.complyAdvantageService.getDetailsBySearchId(id.toString()).subscribe({
      next: (res: any) => {
        console.log(res);
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Details Logged!", $localize`:@@companyName:Rondeivu`);
      },
      error: (err) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Could not get comments!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  getMonitors() {
    this.store.dispatch(toggleLoading({loading: true}));
    let id = this.data.person.searchId;
    this.complyAdvantageService.getMonitorsBySearchId(id.toString()).subscribe({
      next: (res: any) => {
        console.log(res);
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Monitors Logged!", $localize`:@@companyName:Rondeivu`);
      },
      error: (err) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Could not get comments!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  /**
   * Add document(s) to the person
   * @param event
   * @param property
   */
  addDocuments(event: any, property: string) {
    console.log(event);
    if (!!event && !!event.target && !!event.target.files) {
      if (!!this.data.person) {
        // person edit - add files
        this.saveFilesToServer(event.target.files, property);
      } else {
        // person add - add files
        let docs = this.form.get(property)?.value;
        if (!docs) {
          docs = [];
        }
        for (let i = 0; i <= event.target.files.length - 1; i++) {
          docs.push(event.target.files[i]);
        }
        this.form.get(property)?.setValue(docs);
      }
    }
  }

  /**
   * Save the files to server individually when modal is editing a person
   */
  private saveFilesToServer(files: any[], property: string) {
    let id = this.form.get('id')?.value || '';
    let formData = new FormData();
    // get the field cache and init if null
    let cache: any[] = this.form.get(property)?.value;
    if (!cache) {
      cache = [];
    }
    if (!!files) {
      // append files to the PUT form
      formData.append('kycPersonId', id);
      for (let i = 0; i <= files.length - 1; i++) {
        formData.append(property, files[i]);
      }
      // note - different endpoints depending on the document type
      if (property == 'personProofofAddressDocuments') {
        // proof of address docs
        this.store.dispatch(toggleLoading({loading: true}));
        this.personService.uploadProofOfAddress(id, formData).subscribe({
          next: (res: any) => {
            // add the response to the collection
            cache.push(res);
            this.form.get(property)?.setValue(cache);
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("Document Added!", $localize`:@@companyName:Rondeivu`);
          },
          error: (err) => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Could not add file!", $localize`:@@companyName:Rondeivu`);
          }
        });
      } else if (property == 'personProofofIdentificationDocuments') {
        // proof of identification docs
        this.store.dispatch(toggleLoading({loading: true}));
        this.personService.uploadProofOfIdentification(id, formData).subscribe({
          next: (res: any) => {
            // add the response to the collection
            cache.push(res);
            this.form.get(property)?.setValue(cache);
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("Document Added!", $localize`:@@companyName:Rondeivu`);
          },
          error: (err) => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Could not add file!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    }
  }

  /**
   * Download a proof of address file
   * @param docId
   * @param fileName
   */
  downloadProofOfAddress(docId: string, fileName: string) {
    // download
    this.store.dispatch(toggleLoading({loading: true}));
    this.personService.getProofOfAddress(docId).subscribe({
      next: (res: any) => {
        let filename = fileName;
        let blob: Blob = res as Blob;
        let a = document.createElement('a');
        a.download = filename;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Document Downloaded!", $localize`:@@companyName:Rondeivu`);
      },
      error: (err) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Could not download file!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  /**
   * Delete a proof of address file
   * @param docId
   * @param idx
   */
  deleteProofOfAddress(docId: string, idx: number) {
    // download
    this.store.dispatch(toggleLoading({loading: true}));
    this.personService.deleteProofOfAddress(docId).subscribe({
      next: (res: any) => {
        let docs: any[] = this.form.get('personProofofAddressDocuments')?.value;
        docs.splice(idx, 1);
        this.form.get('personProofofAddressDocuments')?.setValue(docs);
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Document Removed!", $localize`:@@companyName:Rondeivu`);
      },
      error: (err) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Could not download file!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  /**
   * Download a proof of identification file
   * @param docId
   * @param fileName
   */
  downloadProofOfIdentification(docId: string, fileName: string) {
    // download
    this.store.dispatch(toggleLoading({loading: true}));
    this.personService.getProofOfIdentification(docId).subscribe({
      next: (res: any) => {
        let filename = fileName;
        let blob: Blob = res as Blob;
        let a = document.createElement('a');
        a.download = filename;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Document Downloaded!", $localize`:@@companyName:Rondeivu`);
      },
      error: (err) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Could not download file!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  /**
   * Delete a proof of identification file
   * @param docId
   * @param idx
   */
  deleteProofOfIdentification(docId: string, idx: number) {
    // download
    this.store.dispatch(toggleLoading({loading: true}));
    this.personService.deleteProofOfIdentification(docId).subscribe({
      next: (res: any) => {
        let docs: any[] = this.form.get('personProofofIdentificationDocuments')?.value;
        docs.splice(idx, 1);
        this.form.get('personProofofIdentificationDocuments')?.setValue(docs);
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Document Removed!", $localize`:@@companyName:Rondeivu`);
      },
      error: (err) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Could not download file!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

}
