<!--{{permissions|json}}-->
<div style="overflow-y: auto; height: calc(100vh - 242px);">
  <div class="container-fluid">
    <div style="overflow-x: hidden;">
      <div class="row justify-content-center" style="padding-top: 25px;">
        <div class="col-11">
          <span class="rm-title rm-title-3">Employee Permissions</span><br/>
        </div>
      </div>
      <br/>
      <!--    WELL START-->
      <div class="row justify-content-center" style="padding-bottom: 25px;">
        <div class="col-11">
          <!--          <app-user-card [firstName]="selectedEmployee.user.firstName"-->
          <!--                         [lastName]="selectedEmployee.user.lastName"-->
          <!--                         [displayName]="selectedEmployee.user.email"-->
          <!--                         [borderColor]="'transparent'"-->
          <!--                         [sideColor]="'#002150'"-->
          <!--                         [image]="selectedEmployee.user.imageUrl"-->
          <!--          ></app-user-card>-->
          <form [formGroup]="userForm">
            <div class="row justify-content-center">
              <div class="col-12 col-md-6">
                <div *ngIf="!!allRoles && (allRoles.length > 0)">
                  <mat-form-field appearance="outline" class="form-field">
                    <mat-label>Select Role</mat-label>
                    <mat-select (selectionChange)="updateForm($event)" formControlName="role">
                      <mat-option *ngFor="let role of allRoles" [value]="role">
                        <div matTooltipPosition="right" [matTooltip]="!!role? role.description : ''">
                          {{!!role ? role.name : ''}}
                        </div>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-12 col-md-6 text-end">
                <button type="button" mat-raised-button color="primary" (click)="savePermissions()"
                        [disabled]="!selectedRole">SAVE
                </button>
              </div>
            </div>
          </form>
          <br/>

          <form [formGroup]="form" (ngSubmit)="savePermissions()">
            <div class="row">
              <mat-accordion multi="true">
                <!--              business unit permissions-->
                <mat-expansion-panel expanded="true">
                  <mat-expansion-panel-header>
                    <mat-panel-title style="font-size: 1.1rem;">
                      BUSINESS UNIT PERMISSIONS
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div *ngFor="let pg of structure.businessUnitPermissionGroups"
                       class="row">
                    <div class="col-12 col-md-6" style="display: flex;">
                      <div>
                        <mat-icon color="primary">{{pg.icon}}</mat-icon>
                      </div>
                      &nbsp;
                      <div>
                        <mat-label style="font-weight: bold;">{{ pg.name }}</mat-label>
                        <br/>
                        <mat-label style="font-weight: lighter;">{{pg.description}}</mat-label>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <mat-form-field appearance="outline" class="form-field">
                        <mat-label>Permission</mat-label>
                        <mat-select [formControlName]="pg.id" [disabled]="!selectedRole"
                                    (selectionChange)="onChange(pg.id, $event)">
                          <mat-select-trigger>
                            <div style="display: flex;">
                              <mat-icon
                                [ngStyle]="{'color': selectedOptions.get(pg.id)?.color}">{{selectedOptions.get(pg.id)?.icon}}
                              </mat-icon>&nbsp;
                              <span style="line-height: 24px;">{{selectedOptions.get(pg.id)?.name}}</span>
                            </div>
                          </mat-select-trigger>
                          <mat-option *ngFor="let option of pg.permissionOptions"
                                      [value]="option"
                                      class="perm-opt">
                            <mat-icon [ngStyle]="{'color': option.color}">{{option.icon}}</mat-icon>
                            <span class="opt-title">{{option.name}}</span><br/>
                            <span class="opt-desc">{{option.description}}</span>
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </mat-expansion-panel>
                <!--  deal team permissions-->
                <mat-expansion-panel expanded="true">
                  <mat-expansion-panel-header>
                    <mat-panel-title style="font-size: 1.1rem;">
                      GLOBAL DEAL TEAM PERMISSIONS
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                  </mat-expansion-panel-header>

                  <div *ngFor="let pg of structure.globalDealTeamPermissionGroups"
                       class="row">
                    <div class="col-12 col-md-6" style="display: flex;">
                      <div>
                        <mat-icon color="primary">{{pg.icon}}</mat-icon>
                      </div>
                      &nbsp;
                      <div>
                        <mat-label style="font-weight: bold;">{{ pg.name }}</mat-label>
                        <br/>
                        <mat-label style="font-weight: lighter;">{{pg.description}}</mat-label>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <mat-form-field appearance="outline" class="form-field">
                        <mat-label>Permission</mat-label>
                        <mat-select [formControlName]="pg.id" [disabled]="!selectedRole"
                                    (selectionChange)="onChange(pg.id, $event)">
                          <mat-select-trigger>
                            <div style="display: flex;">
                              <mat-icon
                                [ngStyle]="{'color': selectedOptions.get(pg.id)?.color}">{{selectedOptions.get(pg.id)?.icon}}
                              </mat-icon>&nbsp;
                              <span style="line-height: 24px;">{{selectedOptions.get(pg.id)?.name}}</span>
                            </div>
                          </mat-select-trigger>
                          <mat-option *ngFor="let option of pg.permissionOptions"
                                      [value]="option"
                                      class="perm-opt">
                            <mat-icon [ngStyle]="{'color': option.color}">{{option.icon}}</mat-icon>
                            <span class="opt-title">{{option.name}}</span><br/>
                            <span class="opt-desc">{{option.description}}</span>
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </mat-expansion-panel>
                <!--              deal team permission end-->
              </mat-accordion>
            </div>
            <!--          row start-->
            <div class="row" style="padding-top: 20px; padding-bottom: 150px;">
              <div class="col-12 text-end">
                <!--              <button type="button" mat-raised-button (click)="back()">BACK</button> &nbsp;-->
                <button type="submit" mat-raised-button color="primary" [disabled]="!selectedRole">SAVE</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

