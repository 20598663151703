<div class="container">
  <div class="row">
    <ngx-charts-pie-grid
      [results]="saleData"
      [customColors]="[
        {
          name: 'Approvals',
          value: theme['color1']
        },
        {
          name: 'Deals',
          value: theme['color2']
        },
        {
          name: 'Mandates',
          value: theme['color3']
        },
        {
          name: 'Users',
          value: theme['color4']
        },
        {
          name: 'Example',
          value: theme['color5']
        }
      ]"
    >
    </ngx-charts-pie-grid>
  </div>
  <br />
  <div class="row">
    <hr />
  </div>
  <br />
  <br />
  <br />
  <br />
  <br />
  <div class="row">
    <div class="col-12">
      <ngx-charts-bar-horizontal
        [view]="[1000, 400]"
        [results]="saleData"
        [xAxisLabel]="'Portfolio Value'"
        [legend]="true"
        [showXAxisLabel]="true"
        [showYAxisLabel]="true"
        [xAxis]="true"
        [yAxis]="true"
        [gradient]="true"
        [customColors]="[
          {
            name: 'Approvals',
            value: theme['color1']
          },
          {
            name: 'Deals',
            value: theme['color2']
          },
          {
            name: 'Mandates',
            value: theme['color3']
          },
          {
            name: 'Users',
            value: theme['color4']
          },
          {
            name: 'Example',
            value: theme['color5']
          }
        ]"
        [title]="'Rondeivu Overview'"
        [gradient]="false"
      >
      </ngx-charts-bar-horizontal>
    </div>
  </div>
</div>
