import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {IBusinessUnit, IBusinessUnitEmployee} from "../../../business-units/models";
import {MatTableDataSource} from "@angular/material/table";
import {
  Config,
  getConfig,
  getSelectedBusinessUnit,
  initialConfigState,
  initialThemeState,
  Theme,
  toggleLoading,
  updateBusinessUnits,
  updateSelectedBusinessUnit
} from "../../../redux";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {BusinessUnitService} from "../../../business-units/services";
import {ToastrService} from "ngx-toastr";
import {Store} from "@ngrx/store";
import {MatDialog} from "@angular/material/dialog";
import {CompanyEmployeeModalComponent} from "../company-employee-modal/company-employee-modal.component";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {
  ConfirmationModalComponent,
  DeleteConfirmationModalComponent, UserCardModalComponent, UserCardSize
} from "../../../shared/components";
import {UtilService} from "../../../shared/services";
import {ModalSize} from "../../../shared/services/util/util.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-company-employees',
  templateUrl: './company-employees.component.html',
  styleUrls: ['./company-employees.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CompanyEmployeesComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();

  buRequest: IBusinessUnit = {} as unknown as IBusinessUnit;

  dataSource: MatTableDataSource<IBusinessUnitEmployee> = new MatTableDataSource();
  employees: IBusinessUnitEmployee[] = [];
  config: Config = initialConfigState;
  theme: Theme = initialThemeState;
  columnsToDisplay = ['employee', 'title', 'role', 'invite', 'Edit'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: IBusinessUnitEmployee | null = {} as IBusinessUnitEmployee;
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  userCardSize = UserCardSize;

  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;

  constructor(private businessUnitService: BusinessUnitService,
              private toastr: ToastrService,
              private store: Store,
              private dialog: MatDialog,
              public util: UtilService,
              private router: Router
  ) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.selectedBusinessUnit = Object.assign({}, bu);
      if (!!this.selectedBusinessUnit && !!this.selectedBusinessUnit.businessUnitId) {
        this.subscribeToDatasource();
      }
    });

  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
  }

  subscribeToDatasource(): void {
    console.log("Fetching Company Employees...");
    this.getEmployees();
  }

  getEmployees() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.businessUnitService.getBusinessUnitEmployees(this.selectedBusinessUnit.businessUnitId).subscribe((employees: IBusinessUnitEmployee[]) => {
      this.employees = employees;
      this.dataSource = new MatTableDataSource(this.employees);
      this.initDataSource();
      this.store.dispatch(toggleLoading({loading: false}));
    }, (err: any) => {
      this.store.dispatch(toggleLoading({loading: false}));
      this.toastr.error("Something went wrong!");
    });
  }

  initDataSource() {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const concatName = data.user.firstName + " " + data.user.lastName + " " + data.user.email;
      const NM = concatName.toLowerCase().includes(filter.toLowerCase());
      return NM;
    };
    if (!!this.paginator) {
      this.paginator.pageSize = 10;
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  delete(employee: IBusinessUnitEmployee) {
    const delRef = this.dialog.open(DeleteConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      data: employee
    });

    delRef.afterClosed().subscribe(result => {
      // console.log("Dialog output:", result);
      if (!!result) {
        this.store.dispatch(toggleLoading({loading: true}));
        this.businessUnitService.deleteBusinessUnitEmployee(employee.id).subscribe({
          next: res => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("Employee Removed!", $localize`:@@companyName:Rondeivu`);
            this.getEmployees();
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Something went wrong!");
          }
        });
      }
    });
  }

  viewInfo(user: any) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.router.navigate(['/' + this.config.selected_business_unit.slug + '/settings/employees/' + user.id + '/info']).then(() => {
      this.store.dispatch(toggleLoading({loading: false}));
    });
  }

  viewPermissions(user: any) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.router.navigate(['/' + this.config.selected_business_unit.slug + '/settings/employees/' + user.id + '/permissions']).then(() => {
      this.store.dispatch(toggleLoading({loading: false}));
    });
  }

  editEmployee(employee: IBusinessUnitEmployee) {
    let employeeRef = this.dialog.open(CompanyEmployeeModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      disableClose: true,
      data: {
        isEditing: true,
        employee: employee
      }
    });

    employeeRef.afterClosed().subscribe(result => {
      if (!!result) {
        // console.log("Dialog closed with payload!");
        this.store.dispatch(toggleLoading({loading: true}));
        this.businessUnitService.updateBusinessUnitEmployee(result).subscribe({
          next: (res: any) => {
            // update the employee properties
            for (let i = 0; i <= this.employees.length - 1; i++) {
              if (this.employees[i].id == result.id) {
                this.employees[i].title = result.title;
                this.employees[i].role = result.role;
              }
            }
            this.dataSource = new MatTableDataSource(this.employees);
            this.initDataSource();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("Business Unit Employee Updated!");
          }, error: error => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Something went wrong!");
          }
        });
      }
    });
  }

  showCreateModal() {
    this.addEmployee();
  }

  addEmployee() {
    let employeeRef = this.dialog.open(CompanyEmployeeModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      disableClose: true,
      data: {
        isEditing: false,
        employee: {}
      }
    });

    employeeRef.afterClosed().subscribe(result => {
      if (!!result) {
        // console.log("Dialog closed with payload!");
        this.store.dispatch(toggleLoading({loading: true}));
        this.businessUnitService.addBusinessUnitEmployee({
          firstName: result.firstName,
          lastName: result.lastName,
          email: result.email
        }).subscribe({
          next: (res: any) => {
            this.employees.push(res);
            this.dataSource = new MatTableDataSource(this.employees);
            this.initDataSource();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("Employee Added!", $localize`:@@companyName:Rondeivu`);
          }, error: error => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Something went wrong!");
          }
        });
      }
    });
  }

  openImageUploadModal() {
    const dialogRef = this.dialog.open(UserCardModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog closed!");
      if (!!result) {
        const id = this.selectedBusinessUnit.businessUnitId;
        const file = new File([result], 'user.png');
        this.businessUnitService.uploadBusinessUnitImageForEmployee(file, id).subscribe({
          next: (res: any) => {
            this.updateSelectedBusinessUnitImageUrl(res.url);
            this.toastr.success("Image Uploaded! ", $localize`:@@companyName:Rondeivu`);
          }, error: (err: any) => {
            this.toastr.error("Something went wrong! " + err.message);
          }
        });
      }
    });
  }

  private updateSelectedBusinessUnitImageUrl(url: string) {
    this.selectedBusinessUnit.imageUrl = url;
    this.store.dispatch(updateSelectedBusinessUnit({businessUnit: this.selectedBusinessUnit}));
    if (!!this.config.business_units) {
      this.config.business_units.forEach((businessUnit: IBusinessUnit) => {
        if (businessUnit.businessUnitId === this.selectedBusinessUnit.businessUnitId) {
          businessUnit.imageUrl = url;
          this.store.dispatch(updateBusinessUnits({businessUnits: this.config.business_units}))
        }
      });
    }
  }

  clearImage() {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog closed!");
      if (!!result) {
        const id = this.selectedBusinessUnit.businessUnitId;
        this.businessUnitService.clearBusinessUnitImageForEmployee(id).subscribe({
          next: (res: any) => {
            this.updateSelectedBusinessUnitImageUrl(res.url);
            this.toastr.success("Image Cleared! ", $localize`:@@companyName:Rondeivu`);
          }, error: (err: any) => {
            this.toastr.error("Something went wrong! " + err.message);
          }
        });
      }
    });
  }
}
