import {Injectable} from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {Observable} from "rxjs";
import {RondeivuHttpClient} from "../../../../handlers";
import {Store} from "@ngrx/store";
import {
  CognitoUser,
  Config, getCognitoUser,
  getConfig, initialCognitoUserState,
  initialConfigState
} from "../../../redux";

import {ToastrService} from "ngx-toastr";
import {IBusinessUnit, IBusinessUnitEmployee} from "../../models";
import {IThirdPartyDetermination} from "../../../../models";

@Injectable({
  providedIn: 'root'
})
export class BusinessUnitService {

  private readonly BASE_URL = environment.rondeivu_api;
  private readonly BU_FRAGMENT = "/businessunits";
  private readonly BUE_FRAGMENT = "/businessunitemployees";
  private readonly BUE_PERMISSION = '/businessunitemployeepermission';
  private readonly ALL_ROLES = '/all-roles';
  private readonly STRUCTURE = '/structure';
  private readonly ROLES = '/roles';
  private readonly PERMISSIONS = '/permissions';
  private readonly ALL_PERMISSIONS = '/all-permissions';
  private readonly EMPLOYEES = '/employee';
  private readonly UBU_FRAGMENT = "/userbusinessunits";
  private readonly RE_FRAGMENT = "/rondeivuemployees";
  private readonly UPLOAD = '/upload-image';
  private readonly RESET = '/reset-image';
  private readonly ADMIN_FRAGMENT = "/admin-settings";
  private readonly RONDEIVU_EMPLOYEE_FRAGMENT = "/rondeivu-employees";
  private readonly THIRD_PARTY = "/third-party";


  private config: Config = initialConfigState;
  private cognito: CognitoUser = initialCognitoUserState;

  constructor(private http: RondeivuHttpClient, private toastr: ToastrService, private store: Store) {
    this.store.select(getConfig).subscribe(c => {
      this.config = c;
    });
    this.store.select(getCognitoUser).subscribe(cu => {
      this.cognito = cu;
    });
  }

  /**
   * Returns a list of all business units for admin users
   */
  getBusinessUnits(): Observable<any> {
    return this.http.get(this.BASE_URL + this.BU_FRAGMENT);
  }

  /**
   * Returns a business unit by id for admin users
   * @param id
   */
  getBusinessUnit(id: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.BU_FRAGMENT + '/' + id);
  }

  /**
   * Creates a new business unit
   */
  addBusinessUnit(businessUnit: IBusinessUnit): Observable<any> {
    return this.http.post(this.BASE_URL + this.BU_FRAGMENT, businessUnit);
  }

  /**
   * Edits a business unit
   */
  editBusinessUnit(businessUnit: IBusinessUnit): Observable<any> {
    return this.http.put(this.BASE_URL + this.BU_FRAGMENT + '/' + businessUnit.businessUnitId, businessUnit);
  }

  /**
   * Deletes a business unit
   */
  deleteBusinessUnit(businessUnit: any): Observable<any> {
    return this.http.delete(this.BASE_URL + this.BU_FRAGMENT + '/' + businessUnit.businessUnitId, businessUnit);
  }

  /**
   * Returns admin setting for business unit by id for S24 users
   * @param id
   */
  getBusinessUnitAdminSettings(): Observable<any> {
    return this.http.get(this.BASE_URL + this.BU_FRAGMENT + this.ADMIN_FRAGMENT + '/' + this.config.editing_business_unit.businessUnitId);
  }

  /**
   * Edits admin setting for business unit by id for S24 users
   */
  editBusinessUnitAdminSettings(businessUnit: any): Observable<any> {
    return this.http.put(this.BASE_URL + this.BU_FRAGMENT + this.ADMIN_FRAGMENT + '/' + this.config.editing_business_unit.businessUnitId, businessUnit);
  }

  /**
   * Returns gets the rondeivu employees for the selected business unit.
   * @param id
   */
  getBusinessUnitRepresentatives(): Observable<any> {
    return this.http.get(this.BASE_URL + this.BU_FRAGMENT + this.RONDEIVU_EMPLOYEE_FRAGMENT);
  }

  /**
   * Returns the third-party-determination for the business unit
   */
  getBusinessUnitThirdParty(): Observable<any> {
    return this.http.get(this.BASE_URL + this.BU_FRAGMENT + this.THIRD_PARTY);
  }

  /**
   * Edits the third-party-determination
   * @param thirdParty
   */
  editBusinessUnitThirdParty(thirdParty: IThirdPartyDetermination): Observable<any> {
    return this.http.put(this.BASE_URL + this.BU_FRAGMENT + this.THIRD_PARTY + '/' + thirdParty.id, thirdParty);
  }


  /**
   * EMPLOYEES
   */

  /**
   * Returns a list of business unit employees based on id
   * @param businessUnitId
   */
  getBusinessUnitEmployees(businessUnitId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.BUE_FRAGMENT + '/' + businessUnitId);
  }

  /**
   * Add a new Business unit employee
   */
  addBusinessUnitEmployee(payload: { firstName: string, lastName: string, email: string }): Observable<any> {
    return this.http.post(this.BASE_URL + this.BUE_FRAGMENT, payload);
  }

  /**
   * Update a Business Unit employee attributes
   */
  updateBusinessUnitEmployee(employee: IBusinessUnitEmployee): Observable<any> {
    return this.http.put(this.BASE_URL + this.BUE_FRAGMENT + '/' + employee.id, employee);
  }

  /**
   * Get the buisiness unit employee by id
   * @param id
   */
  getEmployee(id: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.BUE_FRAGMENT + this.EMPLOYEES + '/' + id);
  }

  saveEmployee(id: string, payload: any): Observable<any> {
    return this.http.put(this.BASE_URL + this.BUE_FRAGMENT + this.EMPLOYEES + '/' + id, payload);
  }

  /**
   * Deletes a business unit employee by id
   * @param employee
   */
  deleteBusinessUnitEmployee(employeeId: string) {
    return this.http.delete(this.BASE_URL + this.BUE_FRAGMENT + '/' + employeeId)
  }

  /**
   * PERMISSIONS
   */


  /**
   * Get the roles for the business unit employee
   * @param employeeId
   */
  getAllRoles(employeeId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.BUE_PERMISSION + this.ALL_ROLES + '/' + employeeId);
  }

  /**
   * Get the permissions form structure for the business unit
   */
  getPageStructure(): Observable<any> {
    return this.http.get(this.BASE_URL + this.BUE_PERMISSION + this.STRUCTURE);
  }

  /**
   * Get all the current employee roles
   * @param employeeId
   */
  getEmployeeRoles(employeeId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.BUE_PERMISSION + this.ROLES + '/' + employeeId);
  }

  /**
   * Save the new employee roles
   * @param employeeId
   * @param payload
   */
  saveEmployeeRoles(employeeId: string, payload: any): Observable<any> {
    return this.http.put(this.BASE_URL + this.BUE_PERMISSION + this.ROLES + '/' + employeeId, payload);
  }

  /**
   * Get all the current employee permissions
   * @param employeeId
   */
  getEmployeePermissions(employeeId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.BUE_PERMISSION + this.PERMISSIONS, {params: {employeeId: employeeId}});
  }

  /**
   * TESTING ONLY
   * Get all the app permissions
   */
  getAllPermissions(): Observable<any> {
    return this.http.get(this.BASE_URL + this.BUE_PERMISSION + this.ALL_PERMISSIONS);
  }


  /**
   * USER BUSINESS UNITS
   */


  /**
   * Returns a list of business units for the logged in user
   */
  getUserBusinessUnits(): Observable<any> {
    return this.http.get(this.BASE_URL + this.UBU_FRAGMENT);
  }

  /**
   * Returns the third-party-determination for the business unit
   */
  getUserBusinessUnitThirdParty(): Observable<any> {
    return this.http.get(this.BASE_URL + this.UBU_FRAGMENT + this.THIRD_PARTY);
  }

  /**
   * Edits the third-party-determination
   * @param thirdParty
   */
  editUserBusinessUnitThirdParty(thirdParty: IThirdPartyDetermination): Observable<any> {
    return this.http.put(this.BASE_URL + this.UBU_FRAGMENT + this.THIRD_PARTY + '/' + thirdParty.id, thirdParty);
  }

  /**
   * RONDEIVU EMPLOYEES
   */

  /**
   * Get the Rondeivu employees object
   */
  getRondeivuEmployees(): Observable<any> {
    return this.http.get(this.BASE_URL + this.BU_FRAGMENT + this.RONDEIVU_EMPLOYEE_FRAGMENT);
  }

  /**
   * Get a list of Rondeivu employees for dropdown
   */
  getRondeivuEmployeesUsers(): Observable<any> {
    return this.http.get(this.BASE_URL + this.RE_FRAGMENT + '/users');
  }

  /**
   * Add a new Rondivu employee from a app user id. Note: This will not create a new app user.
   * @param appUserId the app user id
   * @param re The rondeivu employee to add
   */
  addRondeivuEmployee(appUserId: string, re: any): Observable<any> {
    return this.http.post(this.BASE_URL + this.RE_FRAGMENT + '/' + appUserId, re);
  }

  /**
   * Update a Rondivu employee attributes
   * @param employeeId the RE id
   * @param re the RE object to patch
   */
  updateRondeivuEmployee(employeeId: string, re: any): Observable<any> {
    return this.http.patch(this.BASE_URL + this.RE_FRAGMENT + '/' + employeeId, re);
  }

  /**
   * Delete a Rondeivu employee by their employee id
   * @param employeeId
   */
  deleteRondeivuEmployees(employeeId: string): Observable<any> {
    return this.http.delete(this.BASE_URL + this.RE_FRAGMENT + '/' + employeeId);
  }

  /**
   * Upload the business unit image
   */
  uploadBusinessUnitImage(file: any, id: string): Observable<any> {
    let formData = new FormData();
    formData.append("formFile", file);
    return this.http.post(this.BASE_URL + this.BU_FRAGMENT + this.UPLOAD + '/' + id, formData);
  }

  /**
   * Clears the business unit image
   * @param id
   */
  clearBusinessUnitImage(id: string) {
    return this.http.post(this.BASE_URL + this.BU_FRAGMENT + this.RESET + '/' + id, {});
  }

  /**
   * Upload the business unit image for employee
   */
  uploadBusinessUnitImageForEmployee(file: any, id: string): Observable<any> {
    let formData = new FormData();
    formData.append("formFile", file);
    return this.http.post(this.BASE_URL + this.BUE_FRAGMENT + this.UPLOAD + '/' + id, formData);
  }

  /**
   * Clears the business unit image for employee
   * @param id
   */
  clearBusinessUnitImageForEmployee(id: string) {
    return this.http.post(this.BASE_URL + this.BUE_FRAGMENT + this.RESET + '/' + id, {});
  }


}
