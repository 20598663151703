import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-iframe-thumbnail',
  templateUrl: './iframe-thumbnail.component.html',
  styleUrls: ['./iframe-thumbnail.component.scss']
})
export class IframeThumbnailComponent implements OnInit {

  @Input() src:any;

  constructor() { }

  ngOnInit(): void {
  }

}
