import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {Subscription} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {
  Config, fetchTasks,
  getConfig,
  getSelectedBusinessUnit,
  initialConfigState,
  initialThemeState,
  Theme,
  toggleLoading, updateDealId, updateEditingBusinessUnit
} from "../../../redux";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {IBusinessUnit} from "../../../business-units/models";
import {KycService, UtilService} from "../../../shared/services";
import {KycModalComponent, UserCardSize} from "../../../shared/components";
import {MatDialog} from "@angular/material/dialog";
import {ModalSize} from "../../../shared/services/util/util.service";
import {IKycResponse} from "../../models/kyc";

@Component({
  selector: 'app-kyc-list',
  templateUrl: './kyc-list.component.html',
  styleUrls: ['./kyc-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class KycListComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();

  dataSource: MatTableDataSource<IKycResponse> = new MatTableDataSource();

  kycList: IKycResponse[] = [];
  sortedKyc: IKycResponse[] = [];

  config: Config = initialConfigState;
  theme: Theme = initialThemeState;
  columnsToDisplay = ['User', 'status', 'kycProcessCount', 'pendingKycProcessesCount', 'declinedKycProcessesCount'];
  kycProcessCols = ['name', 'status', 'created', 'view'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: any | null = {};

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  userCardSize = UserCardSize;

  constructor(private store: Store,
              private router: Router,
              private kycService: KycService,
              private toastr: ToastrService,
              private dialog: MatDialog,
              public util: UtilService
  ) {

    this.config$ = this.store.select(getConfig).subscribe((configState: any) => {
      this.config = configState;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      if (!!bu && !!bu.businessUnitId) {
        this.subscribeToDatasource();
      }
    });
  }

  ngOnInit(): void {
    this.store.dispatch(updateEditingBusinessUnit({businessUnit: {} as IBusinessUnit}));
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
  }

  subscribeToDatasource() {
    console.log("Fetching KYC...");
    this.store.dispatch(updateDealId({dealId: ''}));
    this.store.dispatch(toggleLoading({loading: true}));
    //fetch the users
    this.kycService.getKycs().subscribe({
      next: (kycs: IKycResponse[]) => {
        this.kycList = kycs;
        this.dataSource = new MatTableDataSource(this.kycList);
        this.initDataSource();
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: err => {
        this.toastr.error("Unable to get KYC!");
        this.store.dispatch(toggleLoading({loading: false}));
        // todo Reload the nav items and tasks
        // if (err.status == 403) {
        //   this.util.getUserNavItems().then(() => {
        //     console.log('Fetching tasks...');
        //     this.store.dispatch({type: fetchTasks});
        //   });
        // }
      }
    });
  }

  initDataSource() {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      return data.businessUnit?.displayName?.toLowerCase().includes(filter.toLowerCase()) ||
        data.businessUnit?.profile?.toLowerCase().includes(filter.toLowerCase()) ||
        data.status?.toLowerCase().includes(filter.toLowerCase());
    };
    if (!!this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  /**
   * Navigate to view the kyc process
   * @param processId the io of the kyc process
   */
  viewKycProcess(processId: string) {
    this.store.dispatch(toggleLoading({loading: true}));
    setTimeout(() => {
      this.store.dispatch(toggleLoading({loading: false}));
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/kyc/' + processId]);
    }, 1000);
  }

  viewBusinessUnit(businessUnitId: string) {
    this.store.dispatch(toggleLoading({loading: true}));
    setTimeout(() => {
      this.store.dispatch(toggleLoading({loading: false}));
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/business-units/' + businessUnitId + '/kyc']);
    }, 1000);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  editUser(user: any) {
    this.toastr.warning('Not Implemented!', $localize`:@@companyName:Rondeivu`);
  }

  delete(user: any) {
    this.toastr.warning('Not Implemented!', $localize`:@@companyName:Rondeivu`);
  }

  getBusinessUnitUrl(bue: any) {
    return '/' + this.config.selected_business_unit.slug + '/business-units/' + bue.businessUnit.id + '/employees';
  }

  addKyc(kycObject?: any) {
    const delRef = this.dialog.open(KycModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      disableClose: true,
      data: {
        isEditing: false,
        kyc: {},
        profile: kycObject?.businessUnit?.profile
      }
    });

    delRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.store.dispatch(toggleLoading({loading: true}));
        this.kycService.addKyc(result).subscribe({
          next: (res: any) => {
            if (!!res && !!res.id) {
              this.viewKycProcess(res.id);
              this.store.dispatch({type: fetchTasks});
            }
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("KYC Initiated!", $localize`:@@companyName:Rondeivu`);
          }, error: (err: any) => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to initiate KYC!");
          }
        });
      }
    });
  }

  //sort
  sortData(sort: Sort) {
    const data = this.kycList.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedKyc = data;
      return;
    }

    this.sortedKyc = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'business':
          const n1 = a.businessUnit?.displayName;
          const n2 = b.businessUnit?.displayName;
          return compare(n1, n2, isAsc);
        default:
          return 0;
      }
    });

    this.dataSource = new MatTableDataSource(this.sortedKyc);
    this.initDataSource();
    this.expandedElement = {};

  }

}


function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

