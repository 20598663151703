import {Injectable} from '@angular/core';
import {Config, getConfig, initialConfigState} from "../../redux";
import {environment} from "../../../../environments/environment";
import {Store} from "@ngrx/store";
import {RondeivuHttpClient} from "../../../handlers";
import {Observable} from "rxjs";
import {HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private config: Config = initialConfigState;
  private readonly BASE_URL = environment.rondeivu_api;
  private readonly FRAGMENT = '/rondeivuemployees';
  private readonly POTENTIAL_URI = '/potential-employees';
  private readonly LOOKUP_FRAGMENT = '/lookups';
  private readonly LOOKUP_DESCRIPTION_FRAGMENT = '/lookup-description';

  constructor(private store: Store, private http: RondeivuHttpClient) {
    this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
  }

  /**
   * Get Rondivu Employees
   */
  getRondeivuEmployees(): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT);
  }

  /**
   * Get Potential Employees
   */
  getPotentialRondeivuEmployees(): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.POTENTIAL_URI);
  }

  /**
   * Add a Rondeivu Employee
   * @param appUserId
   * @param employee
   */
  addRondeivuEmployees(employee: { appUserId: string }): Observable<any> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.BASE_URL + this.FRAGMENT, employee, {headers: httpHeaders});
  }

  /**
   * Update a Rondeivu Employee
   * @param employee
   */
  updateRondeivuEmployees(employee: any): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + '/' + employee.id, employee);
  }

  /**
   * Delete a Rondeivu Employee
   * @param employee
   */
  deleteRondeivuEmployees(employee: any): Observable<any> {
    return this.http.delete(this.BASE_URL + this.FRAGMENT + '/' + employee.id, employee);
  }


  /**
   * Fetches the lookup description array
   */
  getLookupList(): Observable<any> {
    return this.http.get(this.BASE_URL + this.LOOKUP_FRAGMENT + this.LOOKUP_DESCRIPTION_FRAGMENT);
  }

  /**
   * Fetched the lookup by lookup name
   * @param lookupName the name of the lookup
   */
  getLookup(lookupName: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.LOOKUP_FRAGMENT + '/' + lookupName);
  }

  /**
   * Updates the lookup
   * @param lookupName the name of the lookup
   * @param lookup the new lookup list
   */
  updateLookup(lookupName: string, lookup: any): Observable<any> {
    return this.http.post(this.BASE_URL + this.LOOKUP_FRAGMENT + '/' + lookupName, lookup);
  }

  /**
   * Deletes the lookup
   * @param lookupName the name of the lookup
   * @param lookupId the id of the lookup
   */
  deleteLookup(lookupName: string, lookupId: string): Observable<any> {
    return this.http.delete(this.BASE_URL + this.LOOKUP_FRAGMENT + '/' + lookupName + '/' + lookupId);
  }
}
