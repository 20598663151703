import {Component, OnDestroy, OnInit} from '@angular/core';
import {ThemePalette} from "@angular/material/core";
import {
  Config,
  getConfig, getSelectedBusinessUnit,
  initialConfigState,
  initialThemeState,
  Theme,
  toggleLoading
} from "../../../redux";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {MandateService} from "../../services";
import {IBusinessUnit} from "../../../business-units/models";
import {updateMandateId} from "../../../redux";
import {ToastrService} from "ngx-toastr";
import {Subscription} from "rxjs";
import {showNotesTab, updateMandate} from "../../../redux";
import {UtilService} from "../../../shared/services";
import {BusinessUnitService} from "../../../business-units/services";
import {ApprovalsService} from "../../../approvals/services/approvals/approvals.service";
import {IApprovalRequest} from "../../../approvals/models";
import {toggleNavigating} from "../../../redux/stores/config/config.actions";
import {AppFade} from "../../../../animations/animations";

@Component({
  selector: 'app-mandated-navigation',
  templateUrl: './mandates-navigation.component.html',
  styleUrls: ['./mandates-navigation.component.scss'],
  animations: [AppFade()]
})
export class MandatesNavigationComponent implements OnInit, OnDestroy {
  config$: Subscription = new Subscription();
  route$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();

  loading = false;
  animationState = false;

  links = [
    {title: 'Information', link: '/information', enabled: true, hidden: false}
  ];
  activeLink = {};
  background: ThemePalette = undefined;

  mId: string | null = '';
  selectedMandate: any = {};
  mandateBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;

  config: Config = initialConfigState;
  theme: Theme = initialThemeState;

  constructor(
    private approvalService: ApprovalsService,
    private mdService: MandateService,
    private businessUnitService: BusinessUnitService,
    private toastrService: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    public util: UtilService
  ) {
    this.route$ = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.loading = true;
        this.store.dispatch(toggleNavigating({navigating: true}));
        setTimeout(() => {
          this.store.dispatch(toggleNavigating({navigating: false}));
          this.loading = false;
        }, 1000);
      }
    });

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      if (!!bu && !!bu.businessUnitId) {
        this.subscribeToDatasource();
      }
    });
  }

  ngOnInit(): void {
    this.animationState = true;
  }

  ngOnDestroy() {
    this.store.dispatch(showNotesTab({show: false}));
    this.route$.unsubscribe();
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
  }

  subscribeToDatasource(): void {
    this.dispatchMandateId();
    this.getMandate();
    this.setActiveLink();
    //update the notes view
    if (this.util.isType('admin')) {
      this.store.dispatch(showNotesTab({show: true}));
    } else {
      this.store.dispatch(showNotesTab({show: false}));
    }
  }

  setActiveLink() {
    const fragments = this.router.url.split('/');
    let link = '/' + fragments[fragments.length - 1];
    this.links.forEach(l => {
      if (l.link == link) {
        this.activeLink = l;
      }
    });
  }

  allowApproval() {
    if (!!this.selectedMandate) {
      if (!!this.config.selected_business_unit && !!this.config.selected_business_unit.businessUnitType) {
        return this.selectedMandate.status === 'PENDING' && this.config.selected_business_unit.businessUnitType.toLowerCase() === 'admin';
      }
    }
    return false;
  }

  /**
   * Approve a mandate
   * @param m mandate
   */
  approveMandate(m: any) {
    this.sendApproval("Mandate Approved!", {id: m.id, type: "mandate", status: "APPROVED"});
  }

  /**
   * Decline a business unit
   * @param m business unit
   */
  declineMandate(m: any) {
    this.sendApproval("Mandate Declined!", {id: m.id, type: "mandate", status: "DECLINED"});
  }

  private sendApproval(msg: string, payload: IApprovalRequest) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.approvalService.addApproval(payload).subscribe({
      next: (res: { status: string }) => {
        this.selectedMandate.status = res.status;
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.success(msg, $localize`:@@companyName:Rondeivu`);
      }, error: err => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.error("Unable to send approval!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  /**
   * mock mandates fetch
   */
  getMandate() {
    this.loading = true;
    setTimeout(() => {
      if (!!this.mId) {
        this.mdService.getMandate(this.mId).subscribe({
          next: (m: any) => {
            this.selectedMandate = m;
            if (this.util.isType('admin')) {
              this.getBusinessUnit();
            }
            this.toastrService.success("Mandate Loaded!", $localize`:@@companyName:Rondeivu`);
            this.store.dispatch(updateMandate({mandate: this.selectedMandate}));
            this.loading = false;
          }, error: (error: any) => {
            this.loading = false;
            this.toastrService.error("Unable to get mandate!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
      this.loading = false;
    }, 1000);
  }

  getBusinessUnit() {
    this.businessUnitService.getBusinessUnit(this.selectedMandate.businessUnitId).subscribe({
      next: (businessUnit: IBusinessUnit) => {
        this.mandateBusinessUnit = businessUnit
      }, error: (error: any) => {
        this.toastrService.error("Unable to get business unit!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  dispatchMandateId() {
    this.mId = this.route.snapshot.paramMap.get('id');
    //TODO the user id store is needed
    if (!!this.mId) {
      this.store.dispatch(updateMandateId({mandateId: this.mId}));
    }
  }

  navigate(obj: { title: string; link: string; enabled: boolean }) {
    this.store.dispatch(toggleNavigating({navigating: true}));
    this.animationState = false;
    setTimeout(() => {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/mandates/' + this.mId + obj.link]).then(() => {
        this.animationState = true;
        this.store.dispatch(toggleNavigating({navigating: false}));
      });
    }, 300);
  }

  goBack() {
    this.store.dispatch(toggleNavigating({navigating: true}));
    this.animationState = false;
    setTimeout(() => {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/mandates']).then(() => {
        this.animationState = true;
        this.store.dispatch(toggleNavigating({navigating: false}));
      });
    }, 300);
  }

}
