<div style="height:calc(100vh - 112px); overflow-y: auto;">
  <div style="margin: 25px;">
    <span style="font-size: large; font-weight: bold;">Business Unit</span><br/>
    <div style="margin: 15px;" *ngFor="let c of permissions.bu">
      <span style="margin-left: 25px; font-weight: bold;">{{c.name}}</span><br/>
      <div *ngFor="let p of c.allPossiblePermissions">
        <span style="margin-left: 50px;">{{p}}</span><br/>
      </div>
    </div>
  </div>
  <div style="margin: 25px;">
    <span style="font-size: large; font-weight: bold;">Global Deal Team</span><br/>
    <div style="margin: 15px;" *ngFor="let c of permissions.gdt">
      <span style="margin-left: 25px; font-weight: bold;">{{c.name}}</span><br/>
      <div *ngFor="let p of c.allPossiblePermissions">
        <span style="margin-left: 50px;">{{p}}</span><br/>
      </div>
    </div>
  </div>
</div>

