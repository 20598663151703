import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {InvitationsService, UserSettingsService} from "../../services";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {UtilService} from "../../../shared/services";
import {
  Config,
  getConfig,
  getLookups,
  getSelectedBusinessUnit,
  initialConfigState,
  initialLookupsState,
  Lookups
} from "../../../redux";
import {Subscription} from "rxjs";
import {IBusinessUnit, IBusinessUnitEmployee} from "../../../business-units/models";
import {IUserSettings} from "../../models/i-user-settings";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {ConfirmationModalComponent} from "../../../shared/components";
import {IInviteResponse} from "../../models/i-invitations";

@Component({
  selector: 'app-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.scss']
})
export class InvitationsComponent implements OnInit, OnDestroy {
  user$: Subscription = new Subscription();
  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  selectedBusinessUnit: IBusinessUnit | undefined;

  private config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;

  invites: IInviteResponse[] = [];
  sortedInvites: IInviteResponse[] = [];
  userSettings: IUserSettings = {} as unknown as IUserSettings;

  dataSource: MatTableDataSource<IInviteResponse> = new MatTableDataSource();

  columnsToDisplay = ['invite', 'type', 'name', 'created', 'status', 'actions'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: IBusinessUnitEmployee | null = {} as IBusinessUnitEmployee;
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  constructor(
    private store: Store,
    private router: Router,
    private toastr: ToastrService,
    private invitesService: InvitationsService,
    private userSettingsService: UserSettingsService,
    private dialog: MatDialog,
    public util: UtilService
  ) {
    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });

    this.lookup$ = this.store.select(getLookups).subscribe(lookups => {
      this.lookups = lookups;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe(selectedBusinessUnit => {
      this.selectedBusinessUnit = selectedBusinessUnit;
      if (!!selectedBusinessUnit && !!selectedBusinessUnit.businessUnitId) {
        this.subscribeToDatasource();
      }
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.user$.unsubscribe();
  }

  subscribeToDatasource(): void {
    this.getUserSettings();
  }

  getInvites(appUserId: string) {
    this.invitesService.getInvites(appUserId).subscribe({
      next: (res: IInviteResponse[]) => {
        this.invites = res;
        this.dataSource = new MatTableDataSource<IInviteResponse>(this.invites);
        this.initDataSource();
      }, error: error => {
        this.toastr.error("Something went wrong!");
      }
    });
  }

  getUserSettings() {
    this.userSettingsService.getUserSettings().subscribe({
      next: (userSettings: IUserSettings) => {
        this.userSettings = userSettings;
        this.getInvites(this.userSettings.id);
      }, error: (error: any) => {
        this.toastr.error("Something went wrong!");
      }
    });
  }

  initDataSource() {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const NAME = data.businessUnit.displayName.toLowerCase().includes(filter.toLowerCase());
      const TYPE = data.type.toLowerCase().includes(filter.toLowerCase());
      const PROJECT = data.projectName.toLowerCase().includes(filter.toLowerCase());
      const STATUS = data.status.toLowerCase().includes(filter.toLowerCase());
      return NAME || TYPE || PROJECT || STATUS
    };
    if (!!this.paginator) {
      this.paginator.pageSize = 10;
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  acceptInvite(invite: IInviteResponse) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog closed!");
      if (!!result) {
        this.invitesService.approveInvite(invite.id, invite.type).subscribe({
          next: (res: any) => {
            this.subscribeToDatasource();
            this.toastr.success("Invite Accepted! ", $localize`:@@companyName:Rondeivu`);
          }, error: (err: any) => {
            this.toastr.error("Something went wrong! " + err.message);
          }
        });
      }
    });
  }

  declineInvite(invite: IInviteResponse) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog closed!");
      if (!!result) {
        this.invitesService.declineInvite(invite.id, invite.type).subscribe({
          next: (res: any) => {
            this.subscribeToDatasource();
            this.toastr.warning("Invite Declined! ", $localize`:@@companyName:Rondeivu`);
          }, error: (err: any) => {
            this.toastr.error("Something went wrong! " + err.message);
          }
        });
      }
    });
  }

  //sort
  sortData(sort: Sort) {
    const data = this.invites.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedInvites = data;
      return;
    }

    this.sortedInvites = data.sort((a: IInviteResponse, b: IInviteResponse) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'invite':
          return compare(a.businessUnit?.displayName || '', b.businessUnit?.displayName || '', isAsc);
        case 'name':
          return compare(a.projectName || '', b.projectName || '', isAsc);
        case 'type':
          return compare(a.type || '', b.type || '', isAsc);
        case 'created':
          return compare(a.created || '', b.created || '', isAsc);
        case 'status':
          return compare(a.status || '', b.status || '', isAsc);
        default:
          return 0;
      }
    });

    this.dataSource = new MatTableDataSource(this.sortedInvites);
    this.initDataSource();
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
