export enum KycSectionStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  ERROR = 'ERROR',
  WARN = 'WARN'
}

export interface IQuestionAnswer {
  questionAnswerAttribute: string;
  questionAnswer: any;
}

export enum SectionType {
  ONBOARDING = 'ONBOARDING',
  ENTITY_INFORMATION = 'ENTITY_INFORMATION',
  CONTROL_PERSON = 'CONTROL_PERSON',
  REGULATORY_QUESTIONS_1 = 'REGULATORY_QUESTIONS_1',
  REGULATORY_QUESTIONS_2 = 'REGULATORY_QUESTIONS_2',
  DIRECT_BENEFICIAL_OWNERS = 'DIRECT_BENEFICIAL_OWNERS',
  INDIRECT_BENEFICIAL_OWNERS = 'INDIRECT_BENEFICIAL_OWNERS',
  TRUSTEES = 'TRUSTEES',
  TRUST_SETTLORS = 'TRUST_SETTLORS',
  TRUST_BENEFICIARIES = 'TRUST_BENEFICIARIES',
  GOVERNING_BODY = 'GOVERNING_BODY',
  AUTHORIZED_SIGNATORIES = 'AUTHORIZED_SIGNATORIES',
  DIRECTORS = 'DIRECTORS',
  PRIMARY_CONTACT = 'PRIMARY_CONTACT',
  CERTIFICATION = 'CERTIFICATION'
}

export enum Level {
  BASIC = 'BASIC',
  LONG = 'LONG',
  FULL = 'FULL',
  EXTENDED = 'EXTENDED',
}

export enum ModalFunction {
  ADD = 'ADD',
  EDIT = 'EDIT',
  SELECT = 'SELECT'
}
