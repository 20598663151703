import {Component, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {Subscription} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {
  Config,
  getConfig,
  getLookups,
  getSelectedBusinessUnit,
  initialConfigState,
  initialLookupsState,
  initialThemeState,
  Lookups,
  Theme,
  toggleLoading, updateDealId, updateEditingBusinessUnit,
} from "../../../redux";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {IBusinessUnit} from "../../../business-units/models";
import {LegalService} from "../../services/legal.service";
import {UtilService} from "../../../shared/services";
import {IUserResponse} from "../../../users/models/i-users";

@Component({
  selector: 'app-legal-list',
  templateUrl: './legal-list.component.html',
  styleUrls: ['./legal-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class LegalListComponent implements OnInit {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();

  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  ndaList: any[] = [];
  sortedNda: any[] = [];
  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;
  theme: Theme = initialThemeState;
  columnsToDisplay = ['business', 'signatory', 'type', 'status', 'created', 'actions'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: IUserResponse | null = {} as IUserResponse;
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  constructor(private store: Store,
              private router: Router,
              private legalService: LegalService,
              private toastr: ToastrService,
              public util: UtilService) {

    this.config$ = this.store.select(getConfig).subscribe((configState: any) => {
      this.config = configState;
    });

    this.lookup$ = this.store.select(getLookups).subscribe((lookups: any) => {
      this.lookups = lookups;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      if (!!bu && !!bu.businessUnitId) {
        this.subscribeToDatasource();
      }
    });
  }

  ngOnInit(): void {
    this.store.dispatch(updateEditingBusinessUnit({businessUnit: {} as IBusinessUnit}));
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
  }

  subscribeToDatasource() {
    console.log("Fetching NDA for legal...");
    this.store.dispatch(updateDealId({dealId: ''}));
    this.store.dispatch(toggleLoading({loading: true}));
    //fetch the users
    this.legalService.getNda().subscribe({
      next: (nda: any[]) => {
        this.ndaList = nda;
        this.dataSource = new MatTableDataSource(this.ndaList);
        this.initDataSource();
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: err => {
        this.toastr.error("Unable to get deal NDA!");
        this.store.dispatch(toggleLoading({loading: false}));
      }
    });
  }

  initDataSource() {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const lc_filter = filter.toLowerCase();
      let DN = !!data.businessUnit.displayName ? data.businessUnit.displayName?.toLowerCase().includes(lc_filter) : false;
      let SL = !!data.businessUnit.slug ? data.businessUnit.slug?.toLowerCase().includes(lc_filter) : false;

      return DN || SL;
    };
    if (!!this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  viewNda(element: any) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.router.navigate(['/' + this.config.selected_business_unit.slug + '/deals/' + element.dealId + '/nda']).then(() => {
      this.store.dispatch(toggleLoading({loading: false}));
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  editUser(user: any) {
    this.toastr.warning('Not Implemented!', $localize`:@@companyName:Rondeivu`);
  }

  delete(user: any) {
    this.toastr.warning('Not Implemented!', $localize`:@@companyName:Rondeivu`);
  }

  getBusinessUnitUrl(bue: any) {
    return '/' + this.config.selected_business_unit.slug + '/business-units/' + bue.businessUnit.id + '/employees';
  }

  getTypeText(key: string) {
    let rtn = ''
    for (let i = 0; i <= this.lookups.ndaTypes.length - 1; i++) {
      if (this.lookups.ndaTypes[i].key == key) {
        rtn = this.lookups.ndaTypes[i].value;
        break;
      }
    }
    return rtn;
  }

  //sort
  sortData(sort: Sort) {
    const data = this.ndaList.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedNda = data;
      return;
    }

    this.sortedNda = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'business':
          return compare(a.businessUnit?.displayName, b.businessUnit?.displayName, isAsc);
        case 'user':
          const n1 = a.signatory?.firstName + ' ' + a.signatory?.lastName;
          const n2 = b.signatory?.firstName + ' ' + b.signatory?.lastName;
          return compare(n1, n2, isAsc);
        case 'type':
          return compare(a.type, b.type, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'created':
          return compare(a.created, b.created, isAsc);
        default:
          return 0;
      }
    });

    this.dataSource = new MatTableDataSource(this.sortedNda);
    this.initDataSource();
  }

}


function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
