import {Component, OnInit} from '@angular/core';
import {S3Service} from "../../../deals/services";
import {from, Observable} from "rxjs";
import {RondeivuHttpClient} from "../../../../handlers";
import {environment} from "../../../../../environments/environment";

import {ToastrService} from "ngx-toastr";
import {AdminService} from "../../../../services";
import {IBusinessUnit} from "../../../business-units/models";

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss']
})
export class AdminPanelComponent implements OnInit {

  documentName = '';
  documentNamePrivate = '';
  documentNameProtected = '';
  file$: Observable<any> = new Observable<any>();
  protectedFile$: Observable<any> = new Observable<any>();
  privateFile$: Observable<any> = new Observable<any>();

  businessUnit$: Observable<IBusinessUnit[]> = new Observable<IBusinessUnit[]>();

  dealId = '';
  mandateId = '';


  constructor(private s3: S3Service, private http: RondeivuHttpClient,
              private toastr: ToastrService, private adminService: AdminService) {
    this.fetchAll();
    this.businessUnit$ = this.adminService.getAdminBusinessUnits();
  }

  ngOnInit(): void {
  }

  fetchAll() {
    this.file$ = from(this.s3.getFiles(''));
    this.privateFile$ = from(this.s3.getFiles('', {level: 'private'}));
    this.protectedFile$ = from(this.s3.getFiles('', {level: 'protected'}));
  }

  addDocuments(event: any, level?: string) {
    // console.log(event);
    if (!!event && !!event.target) {
      for (let i = 0; i <= event.target.files.length; i++) {
        const f = event.target.files[i];
        if (!!f) {
          this.s3.uploadDocument(f.name, f, level).then(res => {
            // console.log(res);
            this.fetchAll();
          });
        }
      }
    }
  }

  addUser(type: string) {
    this.adminService.adminAddBusinessUnitByType(type);
  }

  applyToUser(bu: IBusinessUnit) {
    this.adminService.applyBusinessUnitToUser(bu, '', 'issuer');
  }

  match(dealId: string, mandateId: string) {
    let payload = {
      MandateId: mandateId,
      dealId: dealId
    };

    this.http.post(environment.rondeivu_api + '/admin/investordealmatch', payload).subscribe(res => {
      this.toastr.success("Deal Matched!", $localize`:@@companyName:Rondeivu`);
    });
  }

  deleteBu(bu: IBusinessUnit) {
    this.adminService.deleteBusinessUnit(bu).subscribe(res => {
      this.toastr.success("Business Unit Deleted!", $localize`:@@companyName:Rondeivu`);
      this.businessUnit$ = this.adminService.getAdminBusinessUnits();
    });
  }
}
