import { Component, OnInit } from '@angular/core';
import {Store} from "@ngrx/store";
import {getThemeState, initialThemeState, Theme} from "../../../redux";

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {

  saleData = [
    { name: "Approvals", value: 105000 },
    { name: "Deals", value: 55000 },
    { name: "Mandates", value: 15000 },
    { name: "Users", value: 150000 },
    { name: "Example", value: 20000 }
  ];

  colorScheme = [
    {'name': 'Approvals', 'value':'#333'},
    {'name': 'Deals', 'value':'#333'},
    {'name': 'Mandates', 'value':'#333'},
    {'name': 'Users', 'value':'#333'},
    {'name': 'Example', 'value':'#333'}
  ];

  theme: any;
  constructor(public store:Store) {
      this.store.select(getThemeState).subscribe((theme:any)=>{
        this.theme = theme.modules.dashboard;
      });
  }

  ngOnInit(): void {
  }
}
