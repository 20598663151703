<div class="rm-header">
  <span>Rondeivu</span>
</div>
<mat-dialog-content #scroll appCustomScroll #appScrollElement="appCustomScroll" style="height: 350px;">
  <div>
    <h1 mat-dialog-title>Terms & Conditions</h1>
    <div>
      <span style="font-size:small; color: orange; margin-left:auto;">
        <mat-icon style="padding-top: 5px;">warning</mat-icon>Please read and scroll to the bottom of the Terms & Conditions before accepting
      </span>
    </div>
    <br/>
    <p [innerHTML]="data.bodyHtml"></p>
  </div>

</mat-dialog-content>

<mat-dialog-actions style="display: flex; justify-content: space-between;">
  <div>
    <button mat-raised-button (click)="onNoClick()" [hidden]="!appScrollElement.disableBtn">Logout</button>&nbsp;
  </div>
  <div>
    <button mat-raised-button color="primary" (click)="action(data)" cdkFocusInitial
            [disabled]="appScrollElement.disableBtn">Agree
    </button>
  </div>
</mat-dialog-actions>






