import {Component, Inject, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {
  Config,
  getConfig,
  getLookups,
  initialConfigState,
  initialLookupsState,
  Lookups
} from "../../../redux";
import {FormControl, FormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {UtilService} from "../../../shared/services";
import {UserCardSize} from "../../../shared/components";
import {ModalFunction} from "../../models/kyc";


@Component({
  selector: 'app-kyc-business-select-modal',
  templateUrl: './kyc-business-select-modal.component.html',
  styleUrls: ['./kyc-business-select-modal.component.scss']
})
export class KycBusinessSelectModalComponent implements OnInit {

  protected readonly userCardSize = UserCardSize;
  protected readonly ModalFunction = ModalFunction;

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;

  form = new FormGroup({
    businesses: new FormControl(),
    level: new FormControl(),
  });

  constructor(public dialogRef: MatDialogRef<KycBusinessSelectModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { businesses: any, level: string },
              private store: Store,
              public util: UtilService,
              public toastr: ToastrService
  ) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });

  }

  ngOnInit(): void {
    if (!!this.data.businesses) {
      this.form.patchValue(this.data);
    }
    console.log(this.data.level);
  }

  submit(): void {
    //do nothing
  }

  addNewBusiness() {
    this.dialogRef.close({function: this.ModalFunction.ADD, business: null});
  }

  editBusiness(business: any) {
    this.dialogRef.close({function: this.ModalFunction.EDIT, business: business});
  }

  selectBusiness(business: any) {
    if (this.isMoreInfoRequired(business?.['kycLegalEntityType'])) {
      this.toastr.warning("More information is required for this legal entity!", $localize`:@@companyName:Rondeivu`);
      this.editBusiness(business);
    } else {
      this.dialogRef.close({function: this.ModalFunction.SELECT, business: business});
    }
  }

  isMoreInfoRequired(type: string): boolean {
    switch (this.data.level) {
      case 'BASIC':
        return !(['BASIC', 'LONG', 'FULL', 'EXTENDED'].includes(type));
      case 'LONG':
        return !(['LONG', 'FULL', 'EXTENDED'].includes(type));
      case 'FULL':
        return !(['FULL', 'EXTENDED'].includes(type));
      case 'EXTENDED':
        return !(['EXTENDED'].includes(type));
      default:
        return true;
    }
  }

}
