import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {
  Config,
  getConfig,
  getLookups,
  getSelectedBusinessUnit,
  getSelectedDeal,
  initialConfigState,
  initialLookupsState,
  Lookups,
  toggleLoading
} from "../../../redux";
import {IBusinessUnit} from "../../../business-units/models";
import {MediaService} from "../../services/media/media.service";
import {IMediaRoom} from "../../models";
import {MatDialog} from "@angular/material/dialog";
import {MediaModalComponent} from "./media-modal/media-modal.component";
import {
  DeleteConfirmationModalComponent
} from "../../../shared/components";
import {DomSanitizer} from "@angular/platform-browser";
import {UtilService} from "../../../shared/services";
import {ModalSize} from "../../../shared/services/util/util.service";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {IDealCard} from "../../../shared/models";

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit, OnDestroy {
  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();

  private config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;
  mediaRooms: IMediaRoom[] = [];
  bu: IBusinessUnit = {} as unknown as IBusinessUnit;
  selectedDeal$: Subscription = new Subscription();
  selectedDeal: IDealCard = {} as unknown as IDealCard;


  showLinkPreviews = false;

  constructor(private store: Store,
              private router: Router,
              private toastr: ToastrService,
              private mediaService: MediaService,
              public dialog: MatDialog,
              private sanitizer: DomSanitizer,
              public util: UtilService
  ) {

    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });
    this.lookup$ = this.store.select(getLookups).subscribe(lookups => {
      this.lookups = lookups;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.bu = Object.assign({}, bu);
    });

    this.selectedDeal$ = this.store.select(getSelectedDeal).subscribe((deal: IDealCard) => {
      if (this.selectedDeal.id != deal.id) {
        this.selectedDeal = deal;
        this.subscribeToDatasource();
      } else {
        this.selectedDeal = deal;
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedDeal$.unsubscribe();
  }

  subscribeToDatasource(): void {
    console.log("Fetching Deal Media...");
    this.mediaService.getMediaRooms().subscribe({
      next: (mr: IMediaRoom[]) => {
        this.mediaRooms = Object.assign([], mr);
      }, error: error => {
        this.toastr.error("Unable to get media!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  add() {
    const mediaRef = this.dialog.open(MediaModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.MEDIUM),
      data: {},
      disableClose: true
    });

    mediaRef.afterClosed().subscribe(result => {
      // console.log("Dialog output:", result);
      if (!!result) {
        this.store.dispatch(toggleLoading({loading: true}));
        this.mediaService.addMediaRoom(result).subscribe({
          next: (res: IMediaRoom) => {
            this.subscribeToDatasource();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("Media Added!", $localize`:@@companyName:Rondeivu`);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to add media!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  edit(mediaRoom: IMediaRoom) {
    const mediaRef = this.dialog.open(MediaModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.MEDIUM),
      disableClose: true,
      data: {
        isEditing: true,
        mediaRoom: mediaRoom
      }
    });

    mediaRef.afterClosed().subscribe(result => {
      // console.log("Dialog output:", result);
      if (!!result) {
        this.store.dispatch(toggleLoading({loading: true}));
        this.mediaService.updateMediaRoom(result).subscribe({
          next: (res: IMediaRoom) => {
            this.subscribeToDatasource();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("Media Updated!", $localize`:@@companyName:Rondeivu`);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to update media!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  delete(mediaRoom: IMediaRoom) {
    const delRef = this.dialog.open(DeleteConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      data: mediaRoom
    });

    delRef.afterClosed().subscribe(result => {
      // console.log("Dialog output:", result);
      if (!!result) {
        this.store.dispatch(toggleLoading({loading: true}));
        this.mediaService.removeMediaRoom(result).subscribe({
          next: (res: IMediaRoom) => {
            this.subscribeToDatasource();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("Media Removed!", $localize`:@@companyName:Rondeivu`);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to remove media!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  navigate(m: IMediaRoom) {
    if (!!m && !!m.linkUrl) {
      window.open(m.linkUrl, '_blank');
    } else {
      this.toastr.error("This media has no link!", $localize`:@@companyName:Rondeivu`);
    }
  }

  getSanitizedUrl(link: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(link);
  }

  drop(event: CdkDragDrop<IMediaRoom[]>) {
    const p = new Observable((subscriber) => {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      } else {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex,
        );
      }
      subscriber.next();
      subscriber.complete();
    });
    //wait for promise
    const sub = p.subscribe(() => {
      this.store.dispatch(toggleLoading({loading: true}));
      this.mediaService.reorder(this.mediaRooms, this.selectedDeal.id).subscribe({
        next: (res: IMediaRoom) => {
          this.store.dispatch(toggleLoading({loading: false}));
          this.toastr.success("Media Reordered!", $localize`:@@companyName:Rondeivu`);
        }, error: err => {
          this.store.dispatch(toggleLoading({loading: false}));
          this.toastr.error("Unable to remove media!", $localize`:@@companyName:Rondeivu`);
        }
      });
    });

    sub.unsubscribe();
  }

}
