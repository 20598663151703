import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup} from "@angular/forms";
import {
  Config,
  getConfig,
  getLookups,
  initialConfigState,
  initialLookupsState,
  Lookups,
  toggleLoading
} from "../../../redux";
import {Subscription} from "rxjs";
import {UtilService} from "../../../shared/services";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {UuidService} from "../../../../services";
import {SubdocService} from "../../services/subdoc/subdoc.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-deal-subscription-subdoc-modal',
  templateUrl: './deal-subscription-subdoc-modal.component.html',
  styleUrls: ['./deal-subscription-subdoc-modal.component.scss']
})
export class DealSubscriptionSubdocModalComponent implements OnInit {
  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;


  form = new FormGroup({
    subscriptionId: new FormControl(''),
    amount: new FormControl(),
    investingEntity: new FormControl(),
    selectedSignatory: new FormControl(),
    subdocSignatories: new FormControl(),
    signOffPersonName: new FormControl('')
  });

  constructor(
    public util: UtilService,
    private store: Store,
    private uuid: UuidService,
    private toastr: ToastrService,
    private subDocService: SubdocService,
    private router: Router,
    public dialogRef: MatDialogRef<DealSubscriptionSubdocModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { businessUnitId: string, subscriptionId: string, amount: number }) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });
  }

  protected readonly FORM_ID = 'subscriptionId';
  protected readonly FORM_AMOUNT = 'amount';
  protected readonly FORM_INV_ENT = 'investingEntity';
  protected readonly FORM_SELECTED_SIG = 'selectedSignatory';
  protected readonly FORM_SIGNATORIES = 'subdocSignatories';
  protected readonly FORM_SIGN = 'signOffPersonName';

  dealSubdocs: any[] = [];
  businessUnitEntities: IEntityDTO[] = [];

  protected readonly PageStatus = PageStatus;
  pageStatus = PageStatus.EDITING;

  ngOnInit(): void {
    if (!!this.data) {
      this.form.get(this.FORM_ID)?.setValue(this.data.subscriptionId);
      this.form.get(this.FORM_AMOUNT)?.setValue(this.data.amount);
      this.getEntitiesForBusinessUnit(this.data.businessUnitId);
      // this.getSubdocs();
    }

  }

  submit(): void {
    this.dialogRef.close(this.form.getRawValue());
  }

  getSignatories() {
    return this.form.get(this.FORM_SIGNATORIES)?.value || [];
  }

  addNewSignatory() {
    if (!this.isDisabled()) {
      let signatories: any[] = this.form.get(this.FORM_SIGNATORIES)?.value || [];
      signatories?.push({
        id: this.uuid.generateUUID(),
        name: '',
        email: '',
        accessType: null,
        external: true
      });
      this.form.get(this.FORM_SIGNATORIES)?.setValue(signatories);
    }
  }

  addSelectedSignatory() {
    if (!this.isDisabled()) {
      let selected = this.form.get(this.FORM_SELECTED_SIG)?.value || {} as unknown as ISignatoryDTO;
      let signatories: any[] = this.form.get(this.FORM_SIGNATORIES)?.value || [];
      signatories?.push({
        id: selected.businessUnitEntityKycAuthorizedSignatoryId,
        name: selected.name,
        email: '',
        accessType: null,
        external: false
      });
      this.form.get(this.FORM_SIGNATORIES)?.setValue(signatories);
    }
  }

  removeSignatory(idx: number) {
    let arr = this.form.get(this.FORM_SIGNATORIES)?.value;
    arr.splice(idx, 1);
    this.form.get(this.FORM_SIGNATORIES)?.setValue(arr);
  }

  isDisabled() {
    let signatories: any[] = this.form.get(this.FORM_SIGNATORIES)?.value || [];
    return signatories.length >= 2;
  }

  getSubdocs() {
    console.log("Fetching SubDocs...");
    this.store.dispatch(toggleLoading({loading: true}));
    this.subDocService.getSubdocsForDeal(this.config.selected_deal_id).subscribe({
      next: (subdocs: any) => {
        this.dealSubdocs = subdocs;
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: err => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to get subdocs!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  getEntitiesForBusinessUnit(businessUnitId: string) {
    console.log("Fetching Entities...");
    this.store.dispatch(toggleLoading({loading: true}));
    this.subDocService.getSubdocEntities(businessUnitId).subscribe({
      next: (entities: IEntityDTO[]) => {
        this.businessUnitEntities = entities;
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: err => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to get entities!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  getKycSignatoriesFromSelectedEntity() {
    let selectedEntity = this.form.get(this.FORM_INV_ENT)?.value || {} as unknown as IEntityDTO;
    return selectedEntity.kycSignatories;
  }

  getSelectedEntity() {
    return this.form.get(this.FORM_INV_ENT)?.value || {} as unknown as IEntityDTO;
  }

  getErrorMsg() {
    return this.getSelectedEntity().kycErrorMessage;
  }

  changeEntity() {
    this.form.get(this.FORM_SELECTED_SIG)?.setValue(null);
    this.form.get(this.FORM_SIGNATORIES)?.setValue([]);
  }

  openKyc() {
    let entity = this.form.get(this.FORM_INV_ENT)?.value || {} as unknown as IEntityDTO;
    if (!!entity.entityId) {
      setTimeout(() => {
        let namedRoute = '/' + this.config.selected_business_unit.slug + '/entities/' + entity.entityId + '/kyc/' + entity.businessUnitEntityKycId;
        let newRelativeUrl = this.router.createUrlTree([namedRoute]);
        let baseUrl = window.location.href.replace(this.router.url, '');
        window.open(baseUrl + newRelativeUrl, '_blank');
      }, 1000);
    }
  }

  initSubDoc() {
    console.log("Initialising Sub Doc...");
    this.store.dispatch(toggleLoading({loading: true}));
    this.subDocService.addSubdoc(this.config.selected_deal_id, this.getPayloadFromForm()).subscribe({
      next: (res: any) => {
        this.pageStatus = PageStatus.COMPLETE;
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: err => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to initialise sub doc!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  getPayloadFromForm(): ISubdocRequest {
    let payload = {} as unknown as ISubdocRequest;
    payload.subscriptionId = this.form.get(this.FORM_ID)?.value || '';
    payload.amount = this.form.get(this.FORM_AMOUNT)?.value || 0;
    let entityDTO = this.form.get(this.FORM_INV_ENT)?.value || {} as unknown as IEntityDTO;
    payload.investingEntityId = entityDTO.entityId;
    payload.businessUnitKycId = entityDTO.businessUnitEntityKycId;
    payload.signOffPersonName = this.form.get(this.FORM_SIGN)?.value || '';
    let signatories = this.form.get(this.FORM_SIGNATORIES)?.value || [];
    let accessPersons = []
    for (let i = 0; i <= signatories.length - 1; i++) {
      let sig = signatories[i];
      let person = {} as unknown as ISubdocAccessPerson;
      person.id = sig.id;
      person.fullName = sig.name;
      person.accessType = sig.accessType || 'SIGN';
      person.email = sig.email;
      person.sortOrder = i;
      accessPersons.push(person);
    }
    payload.investorSubdocAccessPersons = accessPersons;
    return payload;
  }

}

interface IEntityDTO {
  entityId: string;
  entityName: string;
  entityType: string;
  businessUnitEntityKycId: string;
  kycErrorMessage: string;
  kycSignatories: any[];
}

interface ISignatoryDTO {
  businessUnitEntityKycAuthorizedSignatoryId: string;
  name: string;
}

enum PageStatus {
  EDITING = 'EDITING',
  COMPLETE = 'COMPLETE'
}

interface ISubdocRequest {
  subscriptionId: string;
  amount: number;
  investingEntityId: string;
  businessUnitKycId: string;
  investorSubdocAccessPersons: ISubdocAccessPerson[];
  signOffPersonName: string;
}

interface ISubdocAccessPerson {
  id: string;
  fullName: string;
  email: string;
  profile: string;
  businessUnitEntityKycPersonId: string;
  emailStatus: string;
  signatureStatus: string;
  sortOrder: number;
  accessType: string;
}
