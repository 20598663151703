<!--<span style="color: red;"> {{ getField() | json }}</span><br/>-->
<div [ngSwitch]="getField().type" style="color: blue;">
  <div *ngSwitchCase="SubDocFieldType.SIGNATURE">
    <div class="no-fill-field">
      <span *ngIf="getField().values.length == 0">SIGNATURE</span>
      <div *ngFor="let value of getField().values">
        <span>{{ value }}</span><br/>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="SubDocFieldType.SINGLE_CLAUSE">
    <div class="no-fill-field">
      <span *ngIf="getField().values.length == 0">SINGLE_CLAUSE</span>
      <div *ngFor="let value of getField().values">
        <span>{{ value }}</span><br/>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="SubDocFieldType.SIGNER_TITLE">
    <div class="no-fill-field">
      <span *ngIf="getField().values.length == 0">SIGNER_TITLE</span>
      <div *ngFor="let value of getField().values">
        <span>{{ value }}</span><br/>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="SubDocFieldType.SIGNER_FULL_NAME">
    <div class="no-fill-field">
      <span *ngIf="getField().values.length == 0">SIGNER_FULL_NAME</span>
      <div *ngFor="let value of getField().values">
        <span>{{ value }}</span><br/>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="SubDocFieldType.SIGNER_COMPANY">
    <div class="no-fill-field">
      <span *ngIf="getField().values.length == 0">SIGNER_COMPANY</span>
      <div *ngFor="let value of getField().values">
        <span>{{ value }}</span><br/>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="SubDocFieldType.CHECKBOX">
    <div style="padding-top: 15px;">
      <div *ngFor="let opt of getField().options">
        <mat-checkbox color="primary"
                      [checked]="isOptionChecked(opt.key)"
                      (change)="changeCheckbox(opt,$event)"
                      [disabled]="opt.locked"
                      [required]="getField().required">
          {{ opt.value }}
        </mat-checkbox>
        <br/>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="SubDocFieldType.TEXT">
    <mat-form-field class="form-field" appearance="outline" style="padding-top: 15px;">
      <mat-label>{{ getField().placeholder }}</mat-label>
      <input matInput id="value" type="text"
             [ngModel]="getInput()"
             (change)="changeInput($event)"
             [disabled]="getField().locked"
             [placeholder]="getField().placeholder"
             [required]="getField().required"/>
    </mat-form-field>
  </div>
  <div *ngSwitchCase="SubDocFieldType.PARAGRAPH">
    <!--    text area-->
    <mat-form-field class="form-field" style="padding-top: 15px;">
      <textarea matInput
                style="max-height: 300px;"
                [ngModel]="getInput()"
                (change)="changeInput($event)"
                [placeholder]="getField().placeholder"
                [disabled]="getField().locked"
                [required]="getField().required"></textarea>
    </mat-form-field>
  </div>
  <div *ngSwitchCase="SubDocFieldType.RADIO">
    <div style="padding-top: 15px;">
      <mat-radio-group color="primary" aria-label="Select an option"
                       [ngModel]="getRadio()"
                       (change)="changeRadio($event)"
                       [disabled]="getField().locked"
                       [required]="getField().required">
        <div *ngFor="let opt of getField().options">
          <mat-radio-button [value]="opt.key">{{ opt.value }}</mat-radio-button>
          <br/>
        </div>
      </mat-radio-group>
    </div>
  </div>
  <div *ngSwitchCase="SubDocFieldType.SELECT">
    <mat-form-field class="form-field" appearance="outline" style="padding-top: 15px;">
      <mat-label>Lookup</mat-label>
      <mat-select [disabled]="getField().locked"
                  [required]="getField().required"
                  (selectionChange)="changeSelect($event)">
        <mat-option *ngFor="let option of getField().options" [value]="option.key">
          {{ option.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
