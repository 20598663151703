import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-mnpi-modal',
  templateUrl: './mnpi-modal.component.html',
  styleUrls: ['./mnpi-modal.component.scss']
})
export class MnpiModalComponent implements OnInit {

  userForm = new FormGroup({
    agree: new FormControl(false)
  });

  constructor(public dialogRef: MatDialogRef<MnpiModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  submit(){}

}
