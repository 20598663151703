import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {UtilService} from "../../../shared/services";
import {Config, getConfig, getLookups, initialConfigState, initialLookupsState, Lookups} from "../../../redux";
import {Subscription} from "rxjs";
import {FormControl, FormGroup} from "@angular/forms";
import {MatCheckboxChange} from "@angular/material/checkbox";

@Component({
  selector: 'app-kyc-import-modal',
  templateUrl: './kyc-import-modal.component.html',
  styleUrls: ['./kyc-import-modal.component.scss']
})
export class KycImportModalComponent implements OnInit {
  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;

  form = new FormGroup({
    id: new FormControl('')
  });

  selectedEntity: any;
  selectedKyc: any;
  selectedIds: string[] = [];

  constructor(public dialogRef: MatDialogRef<KycImportModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { type: string, collection: any[] },
              private store: Store,
              private toastr: ToastrService,
              public util: UtilService) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });

  }

  ngOnInit(): void {
  }

  selectEntity(entity: any) {
    this.selectedEntity = entity;
  }

  selectKyc(kyc: any) {
    this.selectedKyc = kyc;
  }

  addOrRemoveId(event: MatCheckboxChange, id: string) {
    let idx = this.selectedIds.indexOf(id);
    if (event.checked) {
      if (idx < 0) {
        this.selectedIds.push(id);
      }
    } else {
      if (idx > 0) {
        this.selectedIds.splice(idx, 1);
      }
    }
  }

  getCheckboxState(id: string) {
    if (!id) {
      return false;
    }
    return this.selectedIds.indexOf(id) > 0;
  }

  submit() {
    this.dialogRef.close(this.selectedIds)
  }
}
