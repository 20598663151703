import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Config, getConfig, getLookups, initialConfigState, initialLookupsState, Lookups} from "../../../redux";
import {FormControl, FormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {UtilService} from "../../../shared/services";

@Component({
  selector: 'app-entity-kyc-modal',
  templateUrl: './entity-kyc-modal.component.html',
  styleUrls: ['./entity-kyc-modal.component.scss']
})
export class EntityKycModalComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;

  userForm = new FormGroup({
    id: new FormControl(),
    entityId: new FormControl(),
    entityName: new FormControl(),
    entityType: new FormControl(),
  });

  constructor(public dialogRef: MatDialogRef<EntityKycModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { entityId: string },
              private store: Store,
              private toastr: ToastrService,
              public util: UtilService) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });
  }

  ngOnInit(): void {
    if (!!this.data) {
      this.userForm.get('entityId')?.setValue(this.data.entityId);
    }
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
  }

  submit() {
    this.dialogRef.close(this.userForm.getRawValue());
  }

}
