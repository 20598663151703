import {Injectable} from "@angular/core";
import {HttpClient, HttpEvent, HttpHandler, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {Config, initialConfigState} from "../modules/redux";

@Injectable()
export class RondeivuHttpHandler extends HttpHandler {
  private config: Config = initialConfigState;

  constructor(
    private readonly next: HttpHandler,  // injects the "default" handler -> HttpInterceptingHandler
    private store: Store
  ) {
    super();
    // this.store.select(getConfig).subscribe(config=>{
    //   this.config = config;
    // })
  }

  /** @override */
  handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    // const clone = req.clone({
    //   headers: req.headers
    //     .set('buid', this.config.selected_business_unit.businessUnitId)
    // });
    return this.next.handle(req);
  }
}

@Injectable()
export class RondeivuHttpClient extends HttpClient {
  constructor(handler: RondeivuHttpHandler) {
    super(handler);
  }
}


