<div class="container-fluid" style="overflow-y: auto; height: calc(100vh - 64px);" hidden>
  <h1>Admin</h1>
  <!--  <p>{{getFilesList()}}</p>-->
  <!--  <button mat-raised-button color="primary" (click)="addFile()">Add</button>-->
  <input type="file" [(ngModel)]="documentName" [value]="" placeholder="New File" (change)="addDocuments($event)"/>;
  PUBLIC <br/>
  <input type="file" [(ngModel)]="documentNamePrivate" [value]="" placeholder="New File"
         (change)="addDocuments($event, 'private')"/>; PRIVATE <br/>
  <input type="file" [(ngModel)]="documentNameProtected" [value]="" placeholder="New File"
         (change)="addDocuments($event, 'protected')"/>; PROTECTED <br/><br/>

  <!--  PUBLIC-->
  <h4>PUBLIC</h4>
  <table class="table" style="width: 100%;">
    <thead>
    <th>KEY</th>
    <th>eTAG</th>
    <th>LAST MODIFIED</th>
    <th>SIZE</th>
    </thead>
    <tbody>
    <tr *ngFor="let f of file$ | async; index as i;">
      <td>{{f.key}}</td>
      <td>{{f.eTag}}</td>
      <td>{{f.lastModified}}</td>
      <td>{{f.size}}</td>
    </tr>
    </tbody>
  </table>
  <br/><br/>
  <!--  END PUBLIC-->

  <!--  PRIVATE-->
  <h4>PRIVATE</h4>
  <table class="table" style="width: 100%;">
    <thead>
    <th>KEY</th>
    <th>eTAG</th>
    <th>LAST MODIFIED</th>
    <th>SIZE</th>
    </thead>
    <tbody>
    <tr *ngFor="let f of privateFile$ | async; index as i;">
      <td>{{f.key}}</td>
      <td>{{f.eTag}}</td>
      <td>{{f.lastModified}}</td>
      <td>{{f.size}}</td>
    </tr>
    </tbody>
  </table>
  <br/><br/>
  <!--  END PRIVATE-->

  <!--  PROTECTED-->
  <h4>PROTECTED</h4>
  <table class="table" style="width: 100%;">
    <thead>
    <th>KEY</th>
    <th>eTAG</th>
    <th>LAST MODIFIED</th>
    <th>SIZE</th>
    </thead>
    <tbody>
    <tr *ngFor="let f of protectedFile$ | async; index as i;">
      <td>{{f.key}}</td>
      <td>{{f.eTag}}</td>
      <td>{{f.lastModified}}</td>
      <td>{{f.size}}</td>
    </tr>
    </tbody>
  </table>
  <br/><br/>
  <!--  END PROTECTED-->
</div>
<br/><br/>
<h1 style="font-weight: bold; color: darkblue;">
  BUSINESS UNITS SET-UP
</h1>
<br/>
&nbsp;
<button mat-raised-button color="primary" (click)="addUser('admin')" style="height: auto;width: auto;">
  Add Admin
</button>
&nbsp;
<button mat-raised-button color="primary" (click)="addUser('investor')" style="height: auto;width: auto;">
  Add Investor
</button>
&nbsp;
<button mat-raised-button color="primary" (click)="addUser('issuer')" style="height: auto;width: auto;">
  Add Issuer
</button>
<br/><br/>
<div style="overflow-y: scroll; height:500px;">
  <table class="table">
    <thead>
    <th>slug</th>
    <th>displayName</th>
    <th>internalName</th>
    <th>businessUnitId</th>
    <th>businessUnitType</th>
    <th>kycStatus</th>
    <th>&nbsp;</th>
    </thead>
    <tbody>
    <tr *ngFor="let b of businessUnit$ | async">
      <td>{{b.slug}}</td>
      <td>{{b.displayName}}</td>
      <td>{{b.internalName}}</td>
      <td>{{b.businessUnitId}}</td>
      <td>{{b.businessUnitType}}</td>
      <td>{{b.kycStatus}}</td>
      <td>
        <button mat-raised-button (click)="applyToUser(b)" style="height: auto;width: auto;">
          + Add to User
        </button>
      </td>
      <td>
        <button mat-raised-button (click)="deleteBu(b)" style="height: auto;width: auto;" color="warn">
          DELETE
        </button>
      </td>
    </tr>
    </tbody>
  </table>
</div>

<div class="container">
  <h3 style="font-weight: bold; color: darkblue;">
    DEAL MATCHING
  </h3>
  <div class="row">
    <div class="col-4">
      <mat-form-field class="form-field">
        <mat-label>Deal ID</mat-label>
        <input matInput id="did" name="did" [(ngModel)]="dealId"/>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field class="form-field">
        <mat-label>Mandate ID</mat-label>
        <input matInput id="mid" name="mid" [(ngModel)]="mandateId"/>
      </mat-form-field>
    </div>
    <div class="col-4">
      <button mat-raised-button color="primary" style="height: auto; width: auto;" (click)="match(dealId,mandateId)">
        MATCH
      </button>
    </div>
  </div>
</div>



