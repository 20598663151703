import {environment} from "../../../../../environments/environment";
import {Config, getConfig, initialConfigState} from "../../../redux";
import {IDocument} from "../../../deals/models";
import {Store} from "@ngrx/store";
import {RondeivuHttpClient} from "../../../../handlers";
import {Subscription} from "rxjs";

export abstract class DocumentBaseService {
  protected readonly BASE_URL = environment.rondeivu_api;
  protected readonly DOCUMENTS_FRAGMENT = '/documents';
  protected readonly KYC_DOCUMENTS_FRAGMENT = '/kycdocuments';
  protected readonly CREATE_FOLDER = '/create-folder';
  protected readonly MOVE = '/move';
  protected readonly UPLOAD = '/upload';
  protected readonly REORDER = '/reorder';
  protected readonly DOWNLOAD = '/download';
  protected readonly VIEW = '/view';
  protected readonly DOWNLOAD_ALL = '/download-all';
  protected readonly CONFIG = '/config';
  protected config: Config = initialConfigState;
  private config$: Subscription = new Subscription();

  constructor(protected store: Store, protected http: RondeivuHttpClient) {
    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
  }

  /**
   * Get the data rooms by the deal id
   */
  getDataRoomsByDealId(objectId: string, types: string[]) {
  }

  /**
   * Get the documents by the dataroom id
   * @param dataRoomId
   */
  getDocumentsByDataRoomId(dataRoomId: string) {
  }

  /**
   * Get the dataroom config by the dataroom id
   * @param dataRoomId
   */
  getDataroomConfigDataRoomId(dataRoomId: string) {
  }

  /**
   * Download the document
   * @param id
   */
  downloadDocument(id: string) {
  }

  downloadAllDocuments(dataRoomId: string) {
  }

  /**
   * Create a new folder
   * @param dataRoomId
   * @param parentId
   * @param name
   */
  createFolder(dataRoomId: string, parentId: string, name: string) {
  }

  /**
   * Move the document to a new parent
   * @param data
   * @param parentId
   */
  moveDocument(data: IDocument, parentId: string) {
  }

  /**
   * Update the document
   * @param data
   */
  updateDocument(data: IDocument) {
  }

  /**
   * Upload the file
   * @param parentId
   * @param file
   */
  uploadDocument(parentId: string, file: any) {
  }

  /**
   * Delete the document
   * @param id
   */
  deleteDocument(id: string) {
  }

  reorder(parentId: string, datarooms?: IDocument) {
  }
}
