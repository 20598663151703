<div class="row" style="padding-top: 50px; padding-bottom: 50px;">
  <div class="col-12 text-center">
    <h1 id="title">{{title}}</h1>
  </div>
</div>

<div class="row justify-content-center">
  <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 col-xxl-4">
    <form [formGroup]="userForm"
          (ngSubmit)="confirmReset()">
      <app-glass-card>
        <div class="card-body">
          <!--     email input page -->
          <div *ngIf="curPage == 0">
            <div class="row justify-content-center" style="padding-top: 25px;">
              <div class="col-11">
                <span>{{msg}}</span>
              </div>
            </div>
            <br/><br>
            <div class="row justify-content-center" style="padding-bottom: 25px;">
              <div class="col-11">
                <mat-form-field appearance="outline">
                  <mat-label>Email</mat-label>
                  <input id="email" formControlName="email" matInput/>
                  <mat-error *ngIf="userForm.get('email')?.hasError('required')"
                  >Email is required
                  </mat-error
                  >
                  <mat-error *ngIf="userForm.get('email')?.hasError('email')"
                  >Please enter a valid email address
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-11 text-end">
                <button type="button" mat-raised-button class="rnd-btn" [disabled]="loading || userForm.get('email')?.invalid" (click)="resendEmailCode()">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                        *ngIf="loading"></span>
                  SEND CODE
                </button>
              </div>
            </div>
          </div>
          <!--  reset confirm-->
          <div *ngIf="curPage ==1">
            <div class="row">
              <div class="col mb-2">
                <!--    email verification start -->
                <app-code-input [title]="'Email Address Verification'" [loading]="loading" (onReset)="resendEmailCode()"
                                (onNext)="confirmEmailCode($event)">
                <span>We sent a code via email to
                  <span style="color: dodgerblue;">{{userForm.get('email')?.value}}</span>
                  , please enter the code above to verify your email.
                </span>
                </app-code-input>
                <!--    email verification end-->
              </div>
            </div>
          </div>
          <div *ngIf="curPage==2">
            <div class="row justify-content-center" style="padding-top: 25px;">
              <div class="col-11">
                <span>Please enter your NEW password below:</span>
              </div>
            </div>
            <br><br>
            <div class="row justify-content-center" style="padding-bottom: 25px;">
              <div class="col-11">
                <mat-form-field class="form-field">
                  <mat-label>Password</mat-label>
                  <input id="password" matInput formControlName="password" [type]="!showPassword ? 'password' : 'text'"/>
                  <span matSuffix (click)="showPassword = !showPassword" style="cursor: pointer;"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="!showPassword">
                    <mat-icon>{{!showPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </span>
                  <mat-error>Password must be a combination of lower-case, upper-case, numbers, special characters, and at
                    least 8 characters long.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <br/>
            <div class="row justify-content-center">
              <div class="col-11" style="display: flex; justify-content: space-between;">
                <button type="button" mat-raised-button (click)="goTo(1)">
                  <!--            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>-->
                  BACK
                </button>&nbsp;
                <button type="submit" mat-raised-button class="rnd-btn"
                        [disabled]="loading || !userForm.get('password')?.value">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                        *ngIf="loading"></span>
                  RESET
                </button>
              </div>
            </div>
          </div>
        </div>
      </app-glass-card>
    </form>
  </div>
</div>

<div class="row" *ngIf="curPage==0">
  <div id="last" class="col-12 text-center">
    Don't have an account?<br><span class="link" (click)="loader.navTo(REGISTER_PATH)">REGISTER</span>
  </div>
</div>

