<div style="height:calc(100vh - 176px); overflow-y: auto;">
  <div class="container-fluid">
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-8 col-md-9">
           <span
             class="rm-title rm-title-3">{{ config.editing_business_unit.businessUnitType.toUpperCase() == 'INVESTOR' ? 'Matched ' : '' }}
             Deals</span><br/>
        <span class="rm-title rm-title-6 rm-lighter">These are all of the deals for the selected business unit:</span>
      </div>
      <div class="col-4 col-md-2 text-end">
        <button *ngIf="config.editing_business_unit.businessUnitType.toUpperCase() == 'ISSUER'"
                mat-raised-button
                color="primary"
                style="height: auto;width: auto;"
                (click)="openDealModal()"
                [disabled]="!util.hasPermission(util.Permission.CAN_ADD_DEALS)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_DEALS) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_ADD_DEALS) ? util.NoAccessMsg : ''"
                matTooltipPosition="left"
        >
          <mat-icon>library_add</mat-icon>&nbsp;ADD NEW DEAL
        </button>
      </div>
    </div>
    <!--    table start-->
    <div class="row justify-content-center d-none d-md-flex" style="padding-bottom: 150px; padding-top: 15px;">
      <div class="col-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <table mat-table [dataSource]="dataSource"
                     [multiTemplateDataRows]="true"
                     matSort
                     (matSortChange)="sortData($event)"
              >
                <!-- Search Header -->
                <ng-container matColumnDef="table-name">
                  <th colspan="4" mat-header-cell *matHeaderCellDef>
                    <span style="font-size: large;">Matched Deals</span>
                  </th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="search-header">
                  <th colspan="5" mat-header-cell *matHeaderCellDef>
                    <form>
                      <mat-form-field style="width: 100%;">
                        <mat-label>Search</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" #input/>
                        <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                    </form>
                  </th>
                </ng-container>

                <!-- project Column -->
                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="projectName"> Project</th>
                  <td mat-cell *matCellDef="let element">
                    <app-user-card [firstName]="element?.projectName"
                                   [displayName]="element?.assetName"
                                   [borderColor]="'transparent'"
                                   [sideColor]="'#345'"
                                   [image]="element?.dealImageUrl"
                                   [imageShape]="'square'"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- Business Unit Column -->
                <ng-container matColumnDef="businessUnit">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="displayName"> Business Unit</th>
                  <td mat-cell *matCellDef="let element" style="padding: 5px;">
                    <app-user-card [firstName]="element.businessUnit?.['displayName']"
                                   [displayName]="element.businessUnit?.['slug']"
                                   [borderColor]="'transparent'"
                                   [sideColor]="'#ff0000'"
                                   [image]="element.businessUnit?.['imageUrl']"
                                   (click)="util.isType('admin')? util.viewBusinessUnit(element.businessUnit?.id) : null"
                                   [ngStyle]="{'cursor': util.isType('admin')? 'pointer':''}"
                                   [highlightOnHover]="util.isType('admin')"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- Deal Size Column -->
                <ng-container matColumnDef="dealSize">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="fundraiseSize"> Deal Size</th>
                  <td mat-cell *matCellDef="let element"> {{ '$' + element?.fundraiseSize + 'M USD' }}
                  </td>
                </ng-container>
                <!-- SEC Type Column -->
                <ng-container matColumnDef="secType">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="secDealType"> SEC Type</th>
                  <td mat-cell *matCellDef="let element"> {{ element?.secDealType }}
                  </td>
                </ng-container>
                <!-- status Column -->
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="dealWorkflow"> Status</th>
                  <td mat-cell *matCellDef="let element">
                    <span [appStatus]="element?.dealWorkflow">{{ element?.dealWorkflow }}</span>
                  </td>
                </ng-container>
                <!-- CITY Column -->
                <ng-container matColumnDef="City">
                  <th mat-header-cell *matHeaderCellDef> City</th>
                  <td mat-cell *matCellDef="let element"> {{ element?.city }}
                  </td>
                </ng-container>
                <!-- DESCRIPTION Column -->
                <ng-container matColumnDef="desc">
                  <th mat-header-cell *matHeaderCellDef> Post NDA Description</th>
                  <td mat-cell *matCellDef="let element" style="word-wrap: break-word;max-width: 150px;">
                    <span style="font-weight: lighter;" [innerHTML]="element?.description"></span>
                  </td>
                </ng-container>
                <!-- Close Date Column -->
                <ng-container matColumnDef="closeDate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="closeDate"> Close Date</th>
                  <td mat-cell *matCellDef="let element"> {{ element?.closeDate | date }}</td>
                </ng-container>
                <!-- created Column -->
                <ng-container matColumnDef="created">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="created"> Created</th>
                  <td mat-cell *matCellDef="let element"> {{ element?.created | date }}</td>
                </ng-container>
                <!-- CLOSE Column -->
                <ng-container matColumnDef="closeType">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="dealCloseType"> Close Type</th>
                  <td mat-cell *matCellDef="let element"> {{ element?.dealCloseType }}</td>
                </ng-container>

                <!-- EDIT Column -->
                <ng-container matColumnDef="edit">
                  <th mat-header-cell *matHeaderCellDef> View</th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-icon-button (click)="viewDeal(element)" matTooltip="View Deal"
                            matTooltipPosition="left">
                      <mat-icon>east</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="['table-name','search-header']"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"></tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter.</td>
                </tr>
              </table>
            </div>
          </div>
          <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[5, 10, 25, 100]"
                         aria-label="Select page of users"></mat-paginator>
        </div>
      </div>
    </div>
    <!--    list start-->
    <div class="row justify-content-center d-flex d-md-none" style="padding-bottom: 150px; padding-top: 15px;">
      <div class="col-12">
        <ul style="list-style-type: none; padding: 25px 0 0 0;">
          <li *ngFor="let element of dataSource.data"
              style="margin-bottom: 5px; display: flex; justify-content: space-between;">
            <app-user-card [firstName]="element?.projectName"
                           [displayName]="element?.assetName"
                           [borderColor]="'transparent'"
                           [sideColor]="'#345'"
                           [image]="element?.dealImageUrl"
                           [imageShape]="'square'"
            ></app-user-card>
            <div style="display: flex;">
              <span style="line-height: 42px; font-size: xx-small;"
                    [appStatus]="element.dealWorkflow">{{ element.dealWorkflow.substring(0, 4) }}</span>
              <button mat-icon-button (click)="viewDeal(element)" matTooltip="View Deal"
                      matTooltipPosition="left">
                <mat-icon>east</mat-icon>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
