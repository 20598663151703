<div appCustomScroll #appScrollElement="appCustomScroll" #scroll style="overflow-y: auto; height: calc(100vh - 176px);">
  <div class="container-fluid">
    <br/><br/>
    <!--    top form start-->
    <div class="row justify-content-center">
      <div class="col-11">
        <span class="rm-title rm-title-3">Deal Information</span><br/>
        <span
          class="rm-title rm-title-6 rm-lighter">This is where you can take a detailed look at this deal's information.</span>
      </div>
    </div>

    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-11" id="card-title">
        <span style="font-size: large;"><b>{{ deal.projectName }}</b></span><br/>
        <span style="font-size: small; color: grey">{{ deal.assetName }}</span><br/>
      </div>
    </div>
    <!--    top form end-->
    <!--    timeline-->
    <!--    <div class="row justify-content-center" style="padding-top:25px;" hidden>-->
    <!--      <div class="col-11" style="background: whitesmoke; padding:50px 50px 20px 50px;">-->
    <!--        <mat-progress-bar id="progress-raised"-->
    <!--                          [color]="'primary'"-->
    <!--                          [value]="deal.percentFundRaise"-->
    <!--                          [matTooltip]="deal.projectName + '  has raised ' + deal.percentFundRaise + '% of a ' + deal.fundraiseSize + ' (Million USD) target.'"-->
    <!--                          matTooltipPosition="above">-->
    <!--        </mat-progress-bar>-->
    <!--        <br/>-->
    <!--        <div style="width:100%; text-align: center;">-->
    <!--          <span style="font-size: small; color: grey;">{{deal.percentFundRaise + '% raised'}}</span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    main-->
    <div class="row justify-content-center"
         style="padding-top:15px; padding-bottom: 25px;">
      <div class="col-11 col-xl-5 img-card">
        <div class="row" style="padding-bottom: 15px;">
          <div class="col-12" style="padding-bottom: 15px;">
            <!--    the pills-->
            <div id="card-pills" *ngFor="let p of deal.pills"
                 class="rounded-pill" [matTooltip]="p.tooltip" matTooltipPosition="right"
                 [ngStyle]="{'background-color': p.backgroundColor, 'color':p.fontColor, 'border-color': p.borderColor}"
            ><span style="font-size: 10px; width: 100%;">{{ p.name.toUpperCase() }}</span>
            </div>
          </div>
          <!--      image-->
          <div class="col-12" style="padding: 0 0 0 12px;" (mouseenter)="hovering=true;" (mouseleave)="hovering=false;">
            <div id="card-img-top">
              <img id="img-top" [class]="getImgClass()" [src]="deal.dealImageUrl"
                   style="object-fit: cover; border-radius: 12px;"
                   [default]="'/assets/images/image-placeholder.png'"
                   alt="Deal Image Broken :(">
            </div>
          </div>
        </div>
      </div>
      <!--      title-->
      <div class="col-11 col-xl-6">
        <div class="row justify-content-center">
          <div class="col-12 col-xl-9">
            <!--    MOIC-->
            <div class="row justify-content-start">
              <div class="col-9">
                <span style="font-size: large; color: #002150;">MOIC (Modeled Returns)</span>
              </div>
            </div>
            <div class="row justify-content-start">
              <div class="col-11 col-md-3 inner-well">
                <span class="card-value">{{ deal.targetMoicBear }}x</span><br/>
                <span class="card-title">Bear Case</span>
              </div>
              <div class="col-11 col-md-3 inner-well">
                <span class="card-value">{{ deal.targetMoicBase }}x</span><br/>
                <span class="card-title">Base Case</span>
              </div>
              <div class="col-11 col-md-3 inner-well">
                <span class="card-value"> {{ deal.targetMoicBest }}x</span><br/>
                <span class="card-title">Best Case</span>
              </div>
            </div>
            <br/>
            <!--    DURATION-->
            <div class="row justify-content-start" style="padding-top:10px;">
              <div class="col-9">
                <span style="font-size: large; color: #002150;">Duration</span>
              </div>
            </div>
            <div class="row justify-content-start">
              <div class="col-11 col-md-3 inner-well">
                <span class="card-value">{{ deal.minimumDuration }} years</span><br/>
                <span class="card-title">Min Duration </span>
              </div>
              <div class="col-11 col-md-3 inner-well">
                <span class="card-value">{{ deal.expectedDuration }} years</span><br/>
                <span class="card-title">Expected Duration</span>
              </div>
              <div class="col-11 col-md-3 inner-well">
                <span class="card-value"> {{ deal.maximumDuration }} years</span><br/>
                <span class="card-title">Max Duration</span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!--    info section-->
    <div class="row justify-content-center" style="padding-bottom:150px;">
      <div class="col-11" style="overflow: auto;">
        <table id="info-table">
          <tr class="info-row">
            <td class="info-title-cell">
              <span class="info-title">Asset Class</span>
            </td>
            <td style="padding-left: 10px;">
              <!--              {{getDealType(deal?.secDealType)}}-->
              <div *ngIf="!!deal?.assetClasses" style="display: flex;">
                <div *ngFor="let a of deal.assetClasses" class="chip">
                  {{ a }}
                </div>
              </div>
            </td>
          </tr>
          <tr class="info-row">
            <td class="info-title-cell">
              <span class="info-title">Deal Type</span>
            </td>
            <td style="padding-left: 10px;">
              <!--              {{getDealType(deal?.secDealType)}}-->
              <div *ngIf="!!deal?.dealTypes" style="display: flex;">
                <div *ngFor="let a of deal.dealTypes" class="chip">
                  {{ a }}
                </div>
              </div>
            </td>
          </tr>
          <tr class="info-row">
            <td class="info-title-cell">
              <span class="info-title">Total Fundraise Size ($M)</span>
            </td>
            <td style="padding-left: 10px;">
              <!--              {{getNDARequirementType(deal?.dealNdaRequirementType)}}-->
              {{ deal?.fundraiseSize | currency: 'USD':true:'2.0' }}
            </td>
          </tr>
          <tr class="info-row">
            <td class="info-title-cell">
              <span class="info-title">Close Date</span>
            </td>
            <td style="padding-left: 10px;">
              {{ deal?.closeDate | date }}
            </td>
          </tr>
          <tr class="info-row">
            <td class="info-title-cell">
              <span class="info-title">Deal Close Type</span>
            </td>
            <td style="padding-left: 10px;">
              {{ deal?.dealCloseType }}
            </td>
          </tr>
          <tr class="info-row">
            <td class="info-title-cell" colspan="2">
              <span class="info-title">Transaction Description</span>
            </td>
          </tr>
          <tr style="padding-bottom: 50px;">
            <td colspan="2" style="padding: 10px 25px;">
              <span [innerHTML]="deal?.description"></span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
