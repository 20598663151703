import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {
  Config,
  getConfig,
  getLookups,
  getSelectedBusinessUnit, getSelectedDealId,
  initialConfigState,
  initialLookupsState,
  Lookups, toggleLoading
} from "../../../redux";
import {IBusinessUnit} from "../../../business-units/models";
import {ICommunicationRequest, IDealTeam, IDealTeamMemberRequest} from "../../models";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {DealService, DealTeamMembersService} from "../../services";
import {HttpErrorResponse} from "@angular/common/http";
import {UtilService} from "../../../shared/services";

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss']
})
export class CommunicationComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  dealId$: Subscription = new Subscription();


  private config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;
  bu: IBusinessUnit = {} as unknown as IBusinessUnit;
  dealId = '';
  dealCommunication: ICommunicationRequest = {} as unknown as ICommunicationRequest;

  today: Date | null;

  userForm = new FormGroup({
    callAgenda: new FormControl('', Validators.required),
    teamEmails: new FormControl([], [Validators.min(1), Validators.required]),
    callLength: new FormControl(15, Validators.required),
    callDate: new FormControl(new Date()),
    callTimeHr: new FormControl("9"),
    callTimeMin: new FormControl("0")
  });

  timeHrOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
  timeMinOptions = [0, 15, 30, 45];

  sent = false;
  readonly MAX_LEN = 5;
  scheduleDateTimes: string[] = [];

  memberEmails: string[] = [];

  constructor(
    private store: Store,
    private router: Router,
    private toastr: ToastrService,
    private dealTeamMembersService: DealTeamMembersService,
    private dealService: DealService,
    public util: UtilService
  ) {

    this.today = new Date();

    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });
    this.lookup$ = this.store.select(getLookups).subscribe(lookups => {
      this.lookups = lookups;
    });
    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.bu = Object.assign({}, bu);
    });

    this.dealId$ = this.store.select(getSelectedDealId).subscribe((id: string) => {
      this.dealId = id;
      if (!!this.bu && !!this.bu.businessUnitId && !!this.dealId) {
        this.subscribeToDatasource();
      }
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.dealId$.unsubscribe();
  }

  private subscribeToDatasource(): void {
    console.log("Communication Request Started...");
    this.getDealTeams();
    // this.dealService.getDealSettings().subscribe((ds: any) => {
    //   console.log(JSON.stringify(ds));
    //   this.dealSettings = Object.assign({}, ds);
    //   this.userForm.patchValue(this.dealSettings);
    // });
  }

  addSchedule() {
    if (this.scheduleDateTimes.length < this.MAX_LEN) {
      let selectedDate = this.userForm.get('callDate')?.value;
      const hr = parseInt(this.userForm.get('callTimeHr')?.value || '0');
      const min = parseInt(this.userForm.get('callTimeMin')?.value || '0');
      selectedDate?.setTime(Date.UTC(selectedDate?.getFullYear(), selectedDate?.getUTCMonth(), selectedDate?.getUTCDate(), hr, min, 0));
      let dt = selectedDate?.toString();
      if (!!dt) {
        this.scheduleDateTimes.push(dt);
      }
    }
  }

  save() {
    let payload = {
      suggestedDatesTimes: this.scheduleDateTimes.join(','),
      teamEmails: this.userForm.get('teamEmails')?.value,
      callAgenda: this.userForm.get('callAgenda')?.value,
      callLength: this.userForm.get('callLength')?.value
    } as ICommunicationRequest;

    this.store.dispatch(toggleLoading({loading: true}));
    this.dealService.investorCallRequest(payload).subscribe({
      next: (res: any) => {
        // console.log(JSON.stringify(res));
        // this.userForm.reset();
        this.sent = true;
        this.userForm.get('callAgenda')?.disable();
        this.userForm.get('teamEmails')?.disable();
        this.userForm.get('callLength')?.disable();
        this.userForm.get('callDate')?.disable();
        this.userForm.get('callTimeHr')?.disable();
        this.userForm.get('callTimeMin')?.disable();
        // this.scheduleDateTimes = [];
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Communication Request Sent!", $localize`:@@companyName:Rondeivu`);
      }, error: (error: HttpErrorResponse) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to send communication request!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }


  /**
   * Gets the available deal teams
   */
  private getDealTeams() {
    this.dealTeamMembersService.getDealTeams().subscribe({
      next: (dealTeams: IDealTeam[]) => {
        // console.log(JSON.stringify(dealTeams));
        dealTeams.forEach(team => {
          this.getDealTeamMembers(team.id);
        });
      }, error: (err: any) => {
        this.toastr.error("Unable to get deal teams!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  /**
   * Gets the members of the deal team and adds them to the list
   * @param dealTeamId
   */
  private getDealTeamMembers(dealTeamId: string) {
    this.dealTeamMembersService.getDealTeamMembers(dealTeamId).subscribe({
      next: (members: IDealTeamMemberRequest[]) => {
        // console.log(JSON.stringify(members));
        members.forEach(member => {
          // console.log(member.email);
          this.memberEmails.push(member.email);
        });
      }, error: (err: any) => {
        this.toastr.error("Unable to get deal team members!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

}
