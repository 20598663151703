<div style="overflow: hidden;">
  <!-- toolbar start-->
  <mat-toolbar
    id="toolbar-login"
    class="mat-primary">
    <div id="img-box-login">
      <!--    <img src="assets/images/rondeivu-logo-type-transparent.png" height="32">-->
      <img id="app-img" src="assets/images/Rondeivu_Logo_Lockup-3_white.png">&nbsp;
    </div>
    <!--  <span id="app-title">Rondeivu</span>-->
  </mat-toolbar>
  <div id="main-login" class="container-fluid">
    <div id="blur"></div>
    <router-outlet></router-outlet>
  </div>

  <div id="footer" class="container-fluid">
    <!-- Footer -->
    <footer class="text-end text-white">
      <br/>
      <div class="row justify-content-start">
        <div class="col-2">
          CONTACT
        </div>
        <div class="col-1">
          <mat-icon class="c-icon">location_pin</mat-icon>
        </div>
        <div class="col-2 text-start">
          {{config.rondeivu_address_1}} <br>
          {{config.rondeivu_address_2}}<br>
          {{config.rondeivu_address_3}}
        </div>
        <div class="col-1">
          <mat-icon class="c-icon">email</mat-icon>
          <br>
          <mat-icon class="c-icon">phone</mat-icon>
        </div>
        <div class="col-2 text-start">
          {{config.rondeivu_email}}<br><br>
          {{config.rondeivu_phone}}
        </div>
        <!-- Copyright -->
        <div class="col-4">
          © <a class="text-white" href="http://www.rondeivu.com/">Rondeivu.com</a> 2022 &nbsp;
        </div>
        <!-- Copyright -->
      </div>
    </footer>
    <!-- Footer -->
  </div>
</div>

