<div class="row" style="padding-top: 50px;">
  <div class="col-12 text-center">
    <h1 id="title">REGISTER</h1>
  </div>
</div>
<!--  there is no profile-->
<div class="row justify-content-center" *ngIf="curPage === 0" style="padding-top:25px; max-height:600px;">
  <div class="col-10 col-md-5 reg-card">
    <!--    issuer card-->
    <div class="glass" style="max-height: 340px;">
      <div class="tile" (click)="goToReg('issuer')">
        <div class="row justify-content-center d-none d-md-flex">
          <img class="icon" src="assets/images/default-issuer.png">
        </div>
        <div class="row justify-content-center" style="padding: 12px;">
          <div class="col-12 bpad-15">
            <label class="lab-1">ISSUER</label>
          </div>
          <div class="col-12">
            <span class="desc">Rondeivu's bespoke technology is built for private placements / direct deals, GP-led co-investments, alternative funds, GP-stakes and seeding opportunities.</span>
            <br/><br/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-10 col-md-5 reg-card">
    <!--    investor card-->
    <div class="glass" style="max-height: 340px;">
      <div class="tile" (click)="goToReg('investor')">
        <div class="row justify-content-center d-none d-md-flex">
          <img class="icon" src="assets/images/default-investor.png">
        </div>
        <div class="row justify-content-center" style="padding: 12px;">
          <div class="col-12 bpad-15">
            <label class="lab-1">INVESTOR</label>
          </div>
          <div class="col-12">
            <span class="desc">Rondeivu leverages decades of investing experience to originate, vet, and curate institutional quality deal flow with the investor's objectives in mind.</span>
            <br/><br/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12 text-center">
    <h3 *ngIf="!!userForm.get('profile')?.value && (curPage === 1 || curPage === 2)">As an <span
      style="color: dodgerblue">{{ userForm.get('profile')?.value?.toUpperCase() }}</span></h3>
  </div>
</div>
<!--  there is a profile-->
<div class="row justify-content-center" [hidden]="!userForm.get('profile')?.value">
  <div class="col-11 col-sm-11 col-md-8 col-lg-6 col-xl-6 col-xxl-4">
    <div class="glass">
      <form [formGroup]="userForm"
            (ngSubmit)="registerUser()">
        <!--  registration form start-->
        <div class="card-body" *ngIf="curPage === 1">
          <!--          First Name-->
          <div class="row" style="padding-top: 25px;">
            <div class="col-6">
              <mat-form-field class="form-field">
                <mat-label>First Name</mat-label>
                <input id="fn" matInput formControlName="firstName"/>
              </mat-form-field>
            </div>
            <!--            Last Name -->
            <div class="col-6">
              <mat-form-field class="form-field">
                <mat-label>Last Name</mat-label>
                <input id="ln" matInput formControlName="lastName"/>
              </mat-form-field>
            </div>
          </div>
          <!--          Email-->
          <div class="row">
            <mat-form-field class="form-field">
              <mat-label>Email</mat-label>
              <input id="email" matInput formControlName="email"/>
            </mat-form-field>
          </div>
          <!--          Country Code-->
          <div class="row">
            <div class="col-3">
              <mat-form-field class="form-field">
                <mat-label>Country Code</mat-label>
                <mat-select id="cc" formControlName="countryCode">
                  <mat-option *ngFor="let cc of lookups.countryCodes" [value]="cc.key">
                    {{ cc.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!--            Phone-->
            <div class="col-9">
              <mat-form-field class="form-field">
                <mat-label>Phone</mat-label>
                <input id="phone" matInput formControlName="mobile"/>
                <mat-icon matSuffix [matTooltip]="'We only use your phone number for 2 Factor Authentication'"
                          matTooltipPosition="left" color="primary">info
                </mat-icon>
              </mat-form-field>
            </div>
          </div>
          <!--          Password-->
          <div class="row" style="padding-bottom: 25px;">
            <mat-form-field class="form-field">
              <mat-label>Password</mat-label>
              <input id="password" matInput [type]="!showPassword ? 'password' : 'text'" formControlName="password"
                     (keydown.enter)="goTo(2);"/>
              <span matSuffix (click)="showPassword = !showPassword"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="!showPassword" style="cursor: pointer;">
                <mat-icon>{{ !showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
              </span>
              <mat-error>Password must be a combination of lower-case, upper-case, numbers, special characters, and at
                least 8 characters long.
              </mat-error>
            </mat-form-field>
          </div>
          <br>
          <div class="row justify-content-end">
            <div class="col-6">
              <button mat-raised-button (click)="goTo(0); userForm.get('profile')?.setValue('');" class="back-btn">
                BACK
              </button>
            </div>
            <div class="col-6">
              <div *ngIf="!userForm.get('inviteToken')?.value">
                <button mat-raised-button (click)="goTo(2);" class="fwd"
                        [disabled]="userForm.get('firstName')?.invalid ||
                       userForm.get('lastName')?.invalid  ||
                        userForm.get('mobile')?.invalid  ||
                        userForm.get('email')?.invalid  ||
                        userForm.get('password')?.invalid  || loading"
                        style="height: auto; width: 100%;">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                        *ngIf="loading"></span>
                  NEXT
                </button>
              </div>
              <div *ngIf="!!userForm.get('inviteToken')?.value && userForm.get('inviteToken')?.value != ''">
                <button mat-raised-button type="submit" class="fwd"
                        [disabled]="userForm.get('firstName')?.invalid  ||
                       userForm.get('lastName')?.invalid ||
                        userForm.get('mobile')?.invalid||
                         userForm.get('email')?.invalid ||
                          userForm.get('password')?.invalid|| loading">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                        *ngIf="loading"></span>
                  REGISTER
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--    registration form end-->
        <!--    additional info start -->
        <div class="card-body" *ngIf="curPage === 2">
          <!--          Company Name-->
          <div class="row" style="padding-top: 25px;">
            <div class="col-6">
              <mat-form-field class="form-field">
                <mat-label>Company Name</mat-label>
                <input id="cn" matInput formControlName="companyName"/>
              </mat-form-field>
            </div>
            <!--            Job Title-->
            <div class="col-6">
              <mat-form-field class="form-field">
                <mat-label>Job Title</mat-label>
                <input id="jt" matInput formControlName="jobTitle"/>
              </mat-form-field>
            </div>
          </div>
          <!--Country of Domicile-->
          <div class="row">
            <mat-form-field class="form-field">
              <mat-label>Country of Domicile</mat-label>
              <mat-select id="cod" formControlName="countryId">
                <mat-option *ngFor="let opt of lookups.countries" [value]="opt.key">
                  {{ opt.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!--          Referred by-->
          <div class="row">
            <mat-form-field class="form-field">
              <mat-label>Referred By (email address)</mat-label>
              <input id="rb" matInput formControlName="referredByEmail"/>
            </mat-form-field>
          </div>
          <!--          Type of investor-->
          <div class="row" *ngIf="userForm.get('profile')?.value === 'investor'" style="padding-bottom: 25px;">
            <mat-form-field class="form-field">
              <mat-label>Type of Investor</mat-label>
              <mat-select id="toi" formControlName="subType">
                <mat-option *ngFor="let i of lookups.investorTypes" [value]="i.key">
                  {{ i.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!--          Type of issuer-->
          <div class="row" *ngIf="userForm.get('profile')?.value === 'issuer'" style="padding-bottom: 25px;">
            <mat-form-field class="form-field">
              <mat-label>Type of Issuer</mat-label>
              <mat-select id="ti" formControlName="subType">
                <mat-option *ngFor="let i of lookups.issuerTypes" [value]="i.key">
                  {{ i.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!--        <div class="row">-->
          <!--          <div class="col-12">-->
          <!--            <input id="terms" name="terms" type="checkbox" [(ngModel)]="agreeToTerms"/>&nbsp; Agree to <a target="_blank" routerLink="/terms-and-conditions">Terms &-->
          <!--            Conditions</a>-->
          <!--          </div>-->
          <!--        </div>-->
          <br>
          <div class="row justify-content-end">
            <div class="col-6">
              <button mat-raised-button type="button" (click)="goTo(1)" class="back-btn">
                BACK
              </button>
            </div>
            <div class="col-6">
              <button mat-raised-button type="submit" class="fwd"
                      [disabled]="!userForm.valid">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
                REGISTER
              </button>
            </div>
          </div>
        </div>
        <!--additional info end-->
      </form>
      <!--    email verification start -->
      <div class="card-body" *ngIf="curPage === 3">
        <app-code-input [title]="'Email Address  Verification'" [loading]="loading" (onReset)="requestEmailCode()"
                        (onNext)="confirmEmailCode($event)">
          <span>We sent a code via email to <span style="color: dodgerblue">{{ userForm.get('email')?.value }}</span>, please enter the code above to verify your email.</span>
        </app-code-input>
      </div>
      <!--    email verification end-->

      <!--    sms verification start -->
      <div class="card-body" *ngIf="curPage === 4">
        <app-code-input [title]="'Phone Verification'" [loading]="loading" (onReset)="requestSmsCode()"
                        (onNext)="confirmSmsCode($event)">
          <span>We sent a code via SMS to <span style="color: dodgerblue">{{ userForm.get('mobile')?.value }}</span>, please enter the code above to verify your phone number.</span>
        </app-code-input>
      </div>
      <!--    sms verification end-->
    </div>
  </div>
</div>
<div class="row">
  <div id="last" class="col-12 text-center">
    Already have an account?<br/><span class="link" (click)="loader.navTo(SIGN_IN_PATH)">LOG IN</span><br/><br/>
  </div>
</div>



