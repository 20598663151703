import {Injectable} from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {from, Observable, Subscription} from 'rxjs';
import {
  Config,
  getConfig,
  initialConfigState,
  updateUser,
  updateUserId
} from "../modules/redux";
import {Location} from "@angular/common";
import {ToastrService} from "ngx-toastr";
import {AppConfigService} from "../services";
import {Store} from "@ngrx/store";
import {UsersService} from "../modules/users/services/users/users.service";

@Injectable({
  providedIn: 'root'
})
export class UserResolver implements Resolve<boolean> {
  private readonly LOGIN_PATH = '/auth/login';
  config$: Subscription = new Subscription();
  config: Config = initialConfigState;

  constructor(
    private router: Router,
    private location: Location,
    private toastr: ToastrService,
    private usersService: UsersService,
    private appConfig: AppConfigService,
    private store: Store
  ) {
    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return from(this.fetchAndDispatchUser(route, state));
  }

  private fetchAndDispatchUser(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let userId = route.paramMap.get('id') || '';
      this.usersService.getUserById(userId).subscribe({
        next: (user: any) => {
          this.store.dispatch(updateUserId({userId: userId}));
          this.store.dispatch(updateUser({user: user}));
          resolve(true);
        }, error: err => {
          reject(false);
          let redirect = '/' + this.config.selected_business_unit.slug + '/users/';
          this.router.navigate([redirect]).then(() => {
            this.toastr.error("Access denied!", $localize`:@@companyName:Rondeivu`);
          });
        }
      });
    });
  }
}
