<div class="rm-header">
  <span>Confirm Delete</span>
</div>
<mat-dialog-content>
  <div style="padding-bottom: 25px; padding-top: 25px; min-width: 150px;">
    <span style="font-size: large;">Please confirm you would like to delete.</span>
  </div>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: space-between;">
  <div>
    <button mat-raised-button (click)="dialogRef.close()">CANCEL</button>
  </div>
  <div>
    <button mat-raised-button color="warn" (click)="dialogRef.close(data)">DELETE</button>
  </div>
</mat-dialog-actions>
