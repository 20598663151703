
<!-- <div [@fadeIn]="animationState" (@fadeIn.start)="animStart($event)" > -->
<div>
  <!-- <div [@slideOutLeft]="animateExit" (@slideOutLeft.done)="exitEnd($event)"> -->
  <div>
    <mat-toolbar id="toolbar" dense [ngStyle]="{'background-color':theme.subheader_bg, 'color':theme.subheader_fg}">
      <button  mat-button (click)="goBack()" [matTooltip]="'Show all legal'" matTooltipPosition="below">
        <mat-icon>list</mat-icon>
      </button>
      &nbsp;
      <span id="deal-header" *ngIf="!loading">
        <span>
          {{selectedUser?.firstName || ''}} {{selectedUser?.lastName || ''}}
        </span>
        <br/>
        <span style="color: grey;">
         {{selectedUser?.email || ''}}
        </span>
      </span>
      <mat-spinner *ngIf="loading" [diameter]="36" color="accent"></mat-spinner>
    </mat-toolbar>
    <nav id="deal-nav" dense
         mat-tab-nav-bar
         [tabPanel]="tabPanel"
         style="width: 100%"
         [ngStyle]="{'background-color': theme.subheader_bg}"
    >
      <a
        mat-tab-link
        *ngFor="let link of links"
        (click)="activeLink = link; navigate(link);"
        [active]="activeLink == link"
        [disabled]="!link.enabled"
        [hidden]="link.hidden"
        [ngStyle]="{'background-color':theme.subheader_bg, 'color':theme.subheader_fg}"
      >
        {{ link.title }}
      </a>
    </nav>
    <mat-tab-nav-panel #tabPanel>
      <div [@fadeIn]="animateSlide">
        <router-outlet></router-outlet>
      </div>
    </mat-tab-nav-panel>
  </div>
</div>

