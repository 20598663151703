import {Component, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {IDataRoom, IDocument} from "../../../deals/models";
import {UuidService} from "../../../../services";
import {FlatTreeControl} from "@angular/cdk/tree";
import {MatTreeFlatDataSource, MatTreeFlattener} from "@angular/material/tree";
import {
  Config,
  getConfig, initialConfigState,
  toggleLoading
} from "../../../redux";
import {Store} from "@ngrx/store";
import {RondeivuHttpClient} from "../../../../handlers";
import {Observable, of, Subscription} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {Clipboard} from "@angular/cdk/clipboard";
import {Router} from "@angular/router";
import {DocumentsService, UtilService} from "../../services";
import {DeleteConfirmationModalComponent} from "../delete-confirmation-modal/delete-confirmation-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {MatSort, Sort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {KycDocumentService} from "../../services/documents/kyc-document.service";

/** Flat node with expandable and level information */
interface IFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  data: IDocument;
}

export enum DataRoomType {
  DATAROOM = 'DATAROOM',
  DOCUMENT = 'DOCUMENT',
  KYC = 'KYC'
}

@Component({
  selector: 'app-data-room',
  templateUrl: './data-room.component.html',
  styleUrls: ['./data-room.component.scss']
})
export class DataRoomComponent implements OnInit, OnChanges, OnDestroy {

  @Input() dataroomType?: DataRoomType;

  @Input() pageTitle?: string = '';
  @Input() pageIcon?: string = '';
  @Input() pageTooltip: string = '';
  @Input() pageDesc?: string = '';
  @Input() dataRoomId: string = '';
  @Input() documentTypes: string[] = [];
  @Input() objectId = '';

  @Input() canDropFiles?: boolean = true;
  @Input() showToolbar?: boolean = true;
  @Input() showNavBar?: boolean = true;
  @Input() showNavButtons?: boolean = true;
  @Input() showRootFolders?: boolean = true;
  @Input() showEditButton?: boolean = false;
  @Input() showDeleteButton?: boolean = false;
  @Input() showNewFolderButton?: boolean = false;
  @Input() showAddFileButton?: boolean = false;
  @Input() showFileMove?: boolean = true;
  @Input() showHistoryButton?: boolean = true;
  @Input() downloadAllEnabled?: boolean = false;

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  //fs height
  private readonly NOTIFY_HEIGHT = 480;
  private readonly DEFAULT_HEIGHT = 430;
  private readonly MAX_DRAWER_WIDTH = 1000;

  private readonly DEFAULT_COLS: string[] = ['name', 'size', 'type', 'created', 'downloadable', 'password', 'actions', 'saveActions'];
  private readonly ADMIN_COLS: string[] = ['name', 'size', 'type', 'created', 'password', 'actions'];

  // pathbar variables
  path = '/';
  pathIdx = 0;
  paths: string[] = ['/'];
  // the main directory
  directory: IDocument = {} as unknown as IDocument;
  // the dir that is selected in the file explorer
  selectedDir: IDocument = this.directory;
  sortedDir: IDocument[] = [];
  // the dir that is selected in the move selector
  moveDir: IDocument = {} as unknown as IDocument;
  // the files selected in the data table
  selectedFiles: IDocument[] = [] as unknown as IDocument[];
  // the selected dataroom to move
  dirToMove: IDocument = {} as unknown as IDocument;
  // placeholders
  placeholder = this.getPlaceholder("New Folder");
  isAddingFolder = false;
  dupIdx = 1;

  //the search bucket
  searchFiles: IDocument = {children: []} as unknown as IDocument;
  searchStr: string = '';
  searchLabel: { title: string, message: string } = {title: '', message: ''};

  isMoving = false;
  isDeleting = false;
  isEditing = false;
  isSearching = false;
  isGlobalSearch = false;
  isEditingRowDirty = false;
  isDragging = false;
  editingFiles: IDocument[] = [] as unknown as IDocument[];
  editingPlaceholder: IDocument = this.getPlaceholder("New File");

  isDropping = false;
  filesToUpload: any[] = [];
  file$: Observable<any> = new Observable<any>();

  config = initialConfigState;

  leftContainerWidth: string = '';
  mouseDownOnHandle: boolean = false;
  highlightHandle: string = 'whitesmoke';

  /**
   * the mat tree action
   */
  private _transformer = (node: IDocument, level: number) => {
    return {
      expandable: this.nodeHasInnerNode(node),
      name: node.documentName,
      level: level,
      data: node
    };
  };
  // mat tree control
  treeControl = new FlatTreeControl<IFlatNode>(
    node => node.level,
    node => node.expandable,
  );
  // mat tree flattener
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.dataRoom,
  );

  // mat datasource
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  tableSource: MatTableDataSource<IDocument> = new MatTableDataSource();

  dataRooms: IDataRoom[] = [];
  selectedDataRoom: IDataRoom = {} as unknown as IDataRoom;
  dataRoomConfig: any = {};

  private documentsService: any;

  constructor(
    private injector: Injector,
    private uuid: UuidService,
    private store: Store,
    private http: RondeivuHttpClient,
    private toastr: ToastrService,
    private clipboard: Clipboard,
    private router: Router,
    private dialog: MatDialog,
    public util: UtilService
  ) {
    switch (this.dataroomType) {
      case DataRoomType.KYC:
        this.documentsService = this.injector.get(KycDocumentService);
        break;
      default:
        this.documentsService = this.injector.get(DocumentsService);
    }

    this.config$ = this.store.select(getConfig).subscribe(((config: Config) => {
      this.config = config;
    }));
  }

  private initDataSource() {
    this.tableSource.filterPredicate = (data: IDocument, filter: string) => {
      const lc_filter = filter.toLowerCase();
      return true;
    };
    if (!!this.paginator) {
      this.tableSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.tableSource.sort = this.sort;
    }
  }

  /**
   * Action for clicking on the resize bar
   * @param value
   */
  changeResizeMode(value: boolean): void {
    this.mouseDownOnHandle = value;
    if (value) {
      this.highlightHandle = 'rgba(25,118,210,0.4)';
    } else {
      this.highlightHandle = 'whitesmoke';
    }
  }

  /**
   * The mouse move event
   * @param event
   */
  changeLeftContainerWidth(event: MouseEvent): void {
    if (this.mouseDownOnHandle) {
      let w = event.clientX - 250;
      if (w <= this.MAX_DRAWER_WIDTH) {
        this.leftContainerWidth = w + "px";
      }
    }
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes) {

      if (!!changes['dataroomType']) {
        this.documentTypes = changes['dataroomType'].currentValue;
      }

      if (!!changes['documentTypes']) {
        this.documentTypes = changes['documentTypes'].currentValue;
      }

      if (!!changes['objectId']) {
        this.objectId = changes['objectId'].currentValue;
      }

      if (!!changes['dataRoomId']) {
        this.dataRoomId = changes['dataRoomId'].currentValue;
      }

      if (!!changes['canDropFiles']) {
        this.canDropFiles = changes['canDropFiles'].currentValue;
      }

      if (!!changes['showEditButton']) {
        this.showEditButton = changes['showEditButton'].currentValue;
      }

      if (!!changes['showDeleteButton']) {
        this.showDeleteButton = changes['showDeleteButton'].currentValue;
      }

      if (!!changes['showNewFolderButton']) {
        this.showNewFolderButton = changes['showNewFolderButton'].currentValue;
      }

      if (!!changes['showAddFileButton']) {
        this.showAddFileButton = changes['showAddFileButton'].currentValue;
      }

      if (!!changes['showFileMove']) {
        this.showFileMove = changes['showFileMove'].currentValue;
      }

      if (!!changes['showHistoryButton']) {
        this.showHistoryButton = changes['showHistoryButton'].currentValue;
      }

      if (!!changes['downloadAllEnabled']) {
        this.downloadAllEnabled = changes['downloadAllEnabled'].currentValue;
      }

      //update the document service depending on the dataroom type
      switch (this.dataroomType) {
        case DataRoomType.KYC:
          this.documentsService = this.injector.get(KycDocumentService);
          break;
        default:
          this.documentsService = this.injector.get(DocumentsService);
      }

      //clear the view
      this.directory = Object.assign({});
      this.setSelectedDir(this.directory, true, true);
      this.refreshTree();

      //get docs by object id and document types
      if (this.objectId != '' && this.documentTypes.length > 0) {
        //get datarooms and dataroom config
        this.getDataRooms();
      } else if (this.dataRoomId != '') {
        //there is no dataroom just a document id
        this.getDocumentsByDataroomId(this.dataRoomId);
      }
    }
  }

  /**
   * Fetches the datarooms based on the selected deal id and sets the first as selected
   */
  getDataRooms() {
    console.log("Fetching Datarooms...");
    this.documentsService.getDataRoomsByDealId(this.objectId, this.documentTypes).subscribe((dataRooms: IDocument[]) => {
      this.dataRooms = Object.assign([], dataRooms);
      if (!!this.dataRooms && this.dataRooms.length > 0) {
        this.selectedDataRoom = this.dataRooms[0];
        // this.getDataroomConfig();
        this.getDocumentsByDataroomId(this.selectedDataRoom.id);
      }
    });
  }

  /**
   * Fetches the configuration for the dataroom
   * @deprecated
   */
  getDataroomConfig() {
    console.log("Fetching Dataroom Config...");
    this.documentsService.getDataroomConfigDataRoomId(this.selectedDataRoom.id).subscribe((config: any) => {
      this.dataRoomConfig = Object.assign({}, config);
    });
  }

  /**
   * Reloads the root document for the selected dataroom
   * @param dataroom
   */
  changeDataRoom(dataroom: IDataRoom) {
    if (!!dataroom) {
      this.selectedDataRoom = dataroom;
      // this.getDataroomConfig();
      this.getDocumentsByDataroomId(this.selectedDataRoom.id);
    }
  }

  /**
   * Fetches the documents for the dataroom id
   */
  getDocumentsByDataroomId(dataRoomId: string) {
    // console.log("Fetching Document by dataroom id..." + dataRoomId);
    this.documentsService.getDocumentsByDataRoomId(dataRoomId).subscribe((docs: IDocument) => {
      // console.table(docs);
      this.directory = Object.assign({}, docs);
      this.setSelectedDir(this.directory, true, true);
      this.refreshTree();
    });
  }

  /**
   * Refresh the directory view
   */
  refreshTree() {
    if (!!this.directory.dataRoom) {
      const copy = this.directory.dataRoom.filter((dr: any) => {
        return dr.dataRoom != null && !!dr.dataRoom && dr.fileType === '';
      });
      this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener, copy);
    }
  }

  /**
   * Determines if the node has children
   * @param _
   * @param node
   */
  hasChild = (_: number, node: IFlatNode) => !!node.data['dataRoom'] && node.data.dataRoom?.length > 0;

  /**
   * Fetch all the user files from s3 for the selected deal
   */
  fetchAll() {
    this.file$ = this.documentsService.getDataRoomsByDealId(this.objectId, this.documentTypes);
  }

  getTableHeight() {
    if (this.isSearching || this.util.isType('investor')) {
      return '100%';
    }
    return 'calc(100% - 64px)';
  }

  /**
   * Action for adding a new file
   * @param event
   */
  addDocuments(event: any) {
    if (!!event && !!event.target) {
      for (let i = 0; i <= event.target.files.length; i++) {
        const f = event.target.files[i];
        if (!!f) {
          this.uploadDoc(f);
        }
      }
    }
  }

  /**
   * The file drop event action
   * @param event
   */
  onFilesDropped(event: any) {
    //only allow drop if enabled
    if (this.canDropFiles) {
      for (let f of event) {
        this.uploadDoc(f);
      }
      this.isDropping = false;
    }
  }

  /**
   * Files hovered event
   * @param event
   */
  onFilesHovered(event: any) {
    if (this.canDropFiles) {
      this.isDropping = true;
    }
  }

  /**
   * Mouse leave event
   * @param event
   */
  onMouseLeave(event: any) {
    if (this.canDropFiles) {
      this.isDropping = false;
    }
  }

  private uploadDoc(file: any) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.documentsService.uploadDocument(this.selectedDir.id, file).subscribe({
      next: (res: any) => {
        // add the response to the current dir
        this.selectedDir.dataRoom?.push(res);
        this.tableSource = new MatTableDataSource(this.selectedDir.dataRoom);
        this.initDataSource();
        this.isAddingFolder = false;
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("File(s) Uploaded!", $localize`:@@companyName:Rondeivu`);
      }, error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.warning("File Upload Failed!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  /**
   * Returns a placeholder dataroom object
   * @param name
   */
  getPlaceholder(name: string): IDocument {
    return {
      id: this.uuid.generateUUID(),
      sortOrder: 0,
      fullPath: "",
      fileType: "",
      documentName: name,
      isLockedReason: "",
      isPreNDA: false,
      isDownloadable: false,
      isMoveable: false,
      password: "",
      size: 0,
      created: new Date().toString(),
      dataRoom: []
    } as unknown as IDocument;
  }

  /**
   * Determines if the provided node contains children that are folders
   * @param node
   */
  nodeHasInnerNode(node: IDocument) {
    if (!!node.dataRoom) {
      for (let i = 0; i <= node.dataRoom.length - 1; i++) {
        if (!!node.dataRoom[i].dataRoom) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Row single click action
   * @param dir
   */
  setSelectedFile(dir: IDocument) {
    if (!dir.isLockedReason) {
      //editing row is not dirty
      if (!this.isEditingRowDirty) {
        //this make only one selection at a time
        this.selectedFiles = Object.assign([]);

        //check for selected file
        if (this.selectedFiles.includes(dir)) {
          this.selectedFiles.splice(this.selectedFiles.indexOf(dir), 1);
        } else {
          // then empty
          this.selectedFiles.push(dir);
          //copy to edit array
          if (this.isEditing) {
            this.editingPlaceholder = Object.assign({}, dir);
            this.editingFiles = Object.assign([], this.selectedFiles);
          }
        }
      } else {
        // editing and editing row not clicked
        if (!this.selectedFiles.includes(dir)) {
          this.toastr.warning("Please complete the file edit action.", $localize`:@@companyName:Rondeivu`);
        }
      }
    }
  }

  rowDblClick(dir: IDocument, savePath: boolean, clearSearch?: boolean) {
    if (!this.isEditing) {
      this.setSelectedDir(dir, savePath, clearSearch);
    }
  }

  /**
   * Row double-click action
   * @param dir
   * @param savePath determines whether the path should be saved in history
   * @param clearSearch optional for clearing search results
   */
  setSelectedDir(dir: IDocument, savePath: boolean, clearSearch?: boolean) {
    if (!dir.isLockedReason) {
      if (clearSearch) {
        this.isSearching = false;
      }

      // console.log("\r\nSetting Selected Dataroom!");
      if (!this.isEditingRowDirty) {
        //clear selected filed
        this.selectedFiles = [];
        this.editingFiles = [];
        //directory double click
        if (!!dir.dataRoom) {
          this.selectedDir = Object.assign(dir);
          this.tableSource = new MatTableDataSource(this.selectedDir.dataRoom);
          this.initDataSource();
          // The path needs to be constructed
          let p = this.getPathFromDirectory('', this.directory, this.selectedDir);
          if (p != '') {
            this.path = p;
            if (savePath) {
              this.addPath(this.path);
            }
          }
        } else {
          // file double click
          this.selectedFiles[0] = Object.assign(dir);
          if (this.directory.dataRoom?.includes(this.selectedFiles[0])) {
            this.selectedDir = Object.assign(this.directory);
            this.tableSource = new MatTableDataSource(this.selectedDir.dataRoom);
            this.initDataSource();
            this.path = this.selectedDir.documentName;
          }
        }
        this.editingFiles = this.selectedFiles;
      } else {
        // editing and editing row not clicked
        if (!this.selectedFiles.includes(dir)) {
          this.toastr.warning("Please complete the file edit action.", $localize`:@@companyName:Rondeivu`);
        }
      }
    }
  }

  setMoveDir(dataroom: IDocument) {
    if (!dataroom.isLockedReason) {
      this.moveDir = Object.assign(dataroom);
    }
  }

  /**
   * The edit save action
   * @param dir
   */
  saveEdit(dir: IDocument) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.documentsService.updateDocument(dir).subscribe({
      next: (res: any) => {
        // find the index
        if (!!this.selectedDir && !!this.selectedDir.dataRoom) {
          // the editing placeholder will be of a different state so match on id instead
          for (let i = 0; i <= this.selectedDir.dataRoom.length - 1; i++) {
            if (dir.id === this.selectedDir.dataRoom[i].id) {
              this.selectedDir.dataRoom[i] = Object.assign({}, res);
              this.tableSource = new MatTableDataSource(this.selectedDir.dataRoom);
              this.initDataSource();
              break;
            }
          }
          this.refreshTree();
        }

        let doc = Object.assign({}, res);
        this.selectedFiles[0] = doc;
        this.editingFiles[0] = doc;
        this.isEditingRowDirty = false;
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Document Updated!", $localize`:@@companyName:Rondeivu`);
      }, error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to update document!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  /**
   * The edit cancel action
   * @param dir
   */
  cancelEdit(dir: IDocument) {
    this.editingPlaceholder = Object.assign(this.editingFiles[0]);
    this.isEditingRowDirty = false;
  }

  /**
   * Provides the selected CSS class if the row is selected
   * @param dir
   */
  getRowCssClass(dir: IDocument) {
    if (!!this.selectedDir && this.selectedDir == dir) {
      return 'selected';
    }
    return '';
  }

  getMoveCssClass(dir: IDocument) {
    if (!!this.moveDir && this.moveDir == dir) {
      return 'selected';
    }
    return '';
  }

  /**
   * Back arrow click action
   */
  back() {
    this.store.dispatch(toggleLoading({loading: true}));
    if (this.pathIdx > 0) {
      this.pathIdx -= 1;
      this.path = this.paths[this.pathIdx];
      // navigate but don't save to history
      this.navToPath(false);
      // console.log("Path idx: " + this.pathIdx);
    }
    this.store.dispatch(toggleLoading({loading: false}));
  }

  /**
   * Home button click action
   */
  home() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.path = '/';
    this.navToPath(true);
    this.store.dispatch(toggleLoading({loading: false}));
  }

  /**
   * Forward arrow click action
   */
  forward() {
    this.store.dispatch(toggleLoading({loading: true}));
    if (this.pathIdx < this.paths.length - 1) {
      this.pathIdx += 1;
      this.path = this.paths[this.pathIdx];
      // navigate but don't save to history
      this.navToPath(false);
      // console.log("Path idx: " + this.pathIdx);
    }
    this.store.dispatch(toggleLoading({loading: false}));
  }

  /**
   * Edit mode click action
   */
  toggleEdit() {
    this.isEditing = !this.isEditing;
    if (this.isEditing) {
      this.isDeleting = false;
      this.isAddingFolder = false;
      this.editingFiles = this.selectedFiles;
      this.editingPlaceholder = Object.assign({}, this.editingFiles[0]);
    } else {
      this.isEditingRowDirty = false;
    }
  }

  /**
   * Delete mode click action
   */
  toggleDelete() {
    this.isDeleting = !this.isDeleting;
    if (this.isDeleting) {
      this.isEditingRowDirty = false;
      this.isEditing = false;
      this.isAddingFolder = false;
    }
  }

  /**
   * New folder click action
   */
  newFolder() {
    this.store.dispatch(toggleLoading({loading: true}));
    let name = "New Folder"
    let folder = this.getPlaceholder(name);
    folder.fileType = "File folder";
    // post the new folder
    this.documentsService.createFolder(this.selectedDataRoom.id, this.selectedDir.id, 'New Folder').subscribe({
      next: (res: any) => {
        //todo the response here is allowing move even when parent is root
        // modify movable if we are currenly on root
        if (this.selectedDir.documentName == '/') {
          res.isMoveable = false;
        }
        this.selectedDir.dataRoom?.push(res);
        this.tableSource = new MatTableDataSource(this.selectedDir.dataRoom);
        this.initDataSource();
        this.refreshTree();
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("New Folder Created!", $localize`:@@companyName:Rondeivu`);
      }, error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.warning("Unable to create new folder!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  /**
   * Adds path to path stack
   * @param p
   */
  addPath(p: string) {
    if (p != this.paths[this.paths.length - 1]) {
      // console.log("Adding Path to History: " + p);
      this.paths.push(p);
    } else {
      // console.log("Already last item in History: " + p);
      // always set the path index to the last item
      this.pathIdx = this.paths.length - 1;
    }
    // console.log(this.paths.toString());
  }

  /**
   * returns the style for the dataroom row if selected
   * @param dir
   */
  getRowBg(dir: IDocument) {
    if (!!this.selectedFiles && this.selectedFiles.includes(dir)) {
      return 'rgba(255,209,128,0.5)';
    }
    return '';
  }

  /**
   * Returns the icon name for the dataroom based on filetype
   * @param dir
   */
  getItemIcon(dir: IDocument) {
    if (!!dir.dataRoom) {
      return 'folder';
    }
    return this.getIconFromType(dir.fileType);
  }

  private getIconFromType(type?: string) {
    if (!!type) {
      switch (type.toLowerCase()) {
        case 'pdf':
          return 'picture_as_pdf';
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'bmp':
          return 'image';
        case 'html':
          return 'html';
        case 'js':
          return 'javascript';
        case 'css':
          return 'css';
        case 'php':
          return 'php';
        default:
          return 'description';
      }
    }
    return 'description';
  }

  /**
   * Action when ENTER is pressed on the path bar
   */
  userNavToPath() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.navToPath(true);
    this.store.dispatch(toggleLoading({loading: false}));
  }

  private navToPath(savePath: boolean) {
    // get the dataroom object from the directory tree using the path
    let found = this.getDirFromPath('', this.path, this.directory);
    if (!!found) {
      if (!found?.isLockedReason) {
        this.setSelectedDir(found, savePath, true);
      } else {
        this.setSelectedDir(this.directory, savePath, true);
        this.toastr.warning("You do not have access to this path!", $localize`:@@companyName:Rondeivu`);
      }
    } else {
      this.setSelectedDir(this.directory, savePath, true);
      if (this.path != '/') {
        this.toastr.warning("Path not found!", $localize`:@@companyName:Rondeivu`);
      }
    }
  }

  /**
   * Recursive function for determining the dataroom node from the path
   * @param validationPath
   * @param searchPath
   * @param dir
   * @private
   */
  private getDirFromPath(validationPath: string, searchPath: string, dir: IDocument): IDocument | null {
    // console.log("\r\nSearching For Path!");
    // console.log("input path: " + this.path);
    // console.log("dir name: " + dir.documentName);
    // console.log("searching path: " + searchPath);

    //update validate path
    if (dir.documentName == "/") {
      validationPath = dir.documentName;
    } else {
      if (validationPath == "/") {
        validationPath = validationPath + dir.documentName;
      } else {
        validationPath = validationPath + '/' + dir.documentName;
      }
    }
    // console.log("validation path: " + validationPath);

    //get the expected dir name
    let nodes = searchPath.split('/');
    //compensate for root
    if (nodes[0] == '') {
      nodes[0] = '/';
    }
    // console.log("full path nodes: " + nodes);

    //validate root node
    if (nodes.length == 1 && validationPath == this.path) {
      // console.log("Node found: " + dir.documentName);
      return dir;
    }

    //validate node children
    let found = null;
    nodes.splice(0, 1);
    if (!!dir.dataRoom && dir.dataRoom.length > 0 && nodes.length > 0) {
      for (let i = 0; i <= dir.dataRoom.length - 1; i++) {
        const next = nodes.join('/');
        // console.log("searching recursive: " + next);
        found = this.getDirFromPath(validationPath, next, dir.dataRoom[i]);
        if (!!found) {
          break;
        }
      }
    }
    return found;
  }

  applySearch(searchStr: string, directory: IDocument) {
    this.searchFiles.dataRoom = Object.assign([]);
    //Already searching so must search root
    if (this.isSearching) {
      this.search(searchStr, this.directory);
      this.isGlobalSearch = true;
    } else {
      //Not searching yet so search the input directory
      this.search(searchStr, directory);
      this.isGlobalSearch = false;
    }
    this.setSelectedDir(this.searchFiles, false);
    this.setSearchType();
    this.setSearchMessage();
    this.isSearching = true;
  }

  clearSearch() {
    this.searchStr = '';
    this.setSelectedDir(this.directory, false, true);
  }

  private setSearchType() {
    if (this.searchStr == '') {
      this.searchLabel.title = '';
    } else {
      this.searchLabel.title = this.isGlobalSearch ? 'Global' : 'Local';
    }
  }

  private setSearchMessage() {
    if (this.searchStr == '') {
      this.searchLabel.message = 'All items.';
    } else {
      if (!!this.searchFiles && !!this.searchFiles.dataRoom && (this.searchFiles.dataRoom.length > 0)) {
        this.searchLabel.message = 'Click on a folder to navigate away.';
      } else {
        this.searchLabel.message = 'There are no files or directories matching your search.';
      }
    }
  }

  /**
   * Recursive function for populating the search bucket
   * @param searchStr the string to search
   * @param directory the starting directory
   * @private
   */
  private search(searchStr: string, directory: IDocument) {
    if (!!directory.dataRoom && directory.dataRoom.length > 0) {
      for (let i = 0; i <= directory.dataRoom.length - 1; i++) {
        this.search(searchStr, directory.dataRoom[i]);
      }
    }
    const dirLower = directory.documentName.toLowerCase();
    const searchLower = searchStr.toLowerCase();
    if (dirLower.includes(searchLower)) {
      this.searchFiles?.dataRoom?.push(directory);
    }
  }

  /**
   * Gets the path of the selected dataroom in the directory tree
   * @param suffix the string suffix of the recursive path
   * @param directory the dataroom tree
   * @param selected the search node
   */
  private getPathFromDirectory(suffix: string, directory: IDocument, selected: IDocument): string {
    let rtn = '';
    if (directory.id === selected.id) {
      if (selected.documentName === '/') {
        rtn = selected.documentName;
      } else {
        rtn = suffix + '/' + directory.documentName;
      }
    } else if (!!directory.dataRoom && directory.dataRoom.length > 0) {
      for (let i = 0; i <= directory.dataRoom.length - 1; i++) {
        if (directory.dataRoom[i].id === selected.id) {
          rtn = suffix + '/' + directory.dataRoom[i].documentName;
          break;
        } else {
          // console.log("searching children: " + suffix);
          rtn = this.getPathFromDirectory(suffix + '/' + directory.dataRoom[i].documentName, directory.dataRoom[i], selected);
          if (rtn != '') {
            break;
          }
        }
      }
    }
    // console.log("searching found: " + rtn);
    return rtn;
  }

  /**
   * Action for deleting a dataroom node
   * @param d
   */
  delete(d: IDocument) {
    const delRef = this.dialog.open(DeleteConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      data: d,
      disableClose: true
    });

    delRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.store.dispatch(toggleLoading({loading: true}));
        this.documentsService.deleteDocument(d.id).subscribe({
          next: (res: any) => {
            // find the index
            if (!!this.selectedDir && !!this.selectedDir.dataRoom) {
              let idx = this.selectedDir.dataRoom.indexOf(d);
              if (idx >= 0) {
                this.selectedDir.dataRoom.splice(idx, 1);
                this.tableSource = new MatTableDataSource(this.selectedDir.dataRoom);
                this.initDataSource();
                this.refreshTree();
              }
            }
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("Document Deleted", $localize`:@@companyName:Rondeivu`);
          }, error: (err: any) => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.warning("Unable to delete document!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  /**
   * Returns the body height of the file explorer window base on if the notification bar is showing
   */
  getBodyHeightOffset() {
    let h = this.isEditing || this.isDeleting ? this.NOTIFY_HEIGHT : this.DEFAULT_HEIGHT;
    h = this.isSearching ? h + 50 : h;
    return h + 'px';
  }

  /**
   * The password key click action
   * @param pass
   */
  copyPass(pass: string) {
    this.clipboard.copy(pass);
    this.toastr.info("Copied to Clipboard");
  }

  /**
   * Returns the datatable colums to display based on component mode
   */
  getColsToDisplay() {
    if (this.isEditing) {
      return this.DEFAULT_COLS;
    } else {
      return this.ADMIN_COLS;
    }
  }


  /**
   * Open the file in the pdf viewer
   * @param dataroom
   */
  openFile(dataroom: IDocument) {
    if (!!dataroom && dataroom?.fileType.toLowerCase() == 'pdf') {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['/' + this.config.selected_business_unit.slug + '/documents/' + dataroom.id], {queryParams: {'dealId': this.config.selected_deal_id}})
      );
      window.open(url, '_blank');
    } else {
      this.toastr.info("Previewing this file type is not permitted!");
    }
  }


  /**
   * Set the move destination
   * @param dataroom
   */
  setDataRoomToMove(dataroom: IDocument) {
    this.moveDir = this.directory;
    this.dirToMove = dataroom;
    this.isMoving = true;
  }

  /**
   * Execute the file move to move destination
   */
  moveDataRoomToParent() {
    if (!this.moveDir.isLockedReason) {
      this.documentsService.moveDocument(this.dirToMove, this.moveDir.id).subscribe({
        next: (res: any) => {
          //fetch and refresh the tree
          this.getDocumentsByDataroomId(this.selectedDataRoom.id);
          this.isMoving = false;
          this.toastr.success("Document Moved!", $localize`:@@companyName:Rondeivu`);
        }, error: (error: any) => {
          console.log(error);
          this.toastr.error("Unable to move document!", $localize`:@@companyName:Rondeivu`);
        }
      });
    }
  }

  /**
   * The action to download the file
   * @param dataroom
   */
  download(dataroom: IDocument) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.documentsService.downloadDocument(dataroom.id).subscribe({
      next: (res: any) => {
        let filename = dataroom.documentName + '.' + dataroom.fileType.toLowerCase();
        let blob: Blob = res as Blob;
        let a = document.createElement('a');
        a.download = filename;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        this.store.dispatch(toggleLoading({loading: false}));
      },
      error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to download file!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  /**
   * The action to download all
   */
  downloadAll() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.documentsService.downloadAllDocuments(this.selectedDataRoom.id).subscribe({
      next: (res: any) => {
        let filename = this.selectedDataRoom.name + '.zip';
        let blob: Blob = res as Blob;
        let a = document.createElement('a');
        a.download = filename;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        this.store.dispatch(toggleLoading({loading: false}));
      },
      error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to download data room!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  viewHistory() {
    this.store.dispatch(toggleLoading({loading: true}));
    setTimeout(() => {
      this.store.dispatch(toggleLoading({loading: false}));
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/history/documents'], {queryParams: {id: this.selectedDataRoom.id}});
    }, 1000);
  }

  //sort
  sortData(sort: Sort) {
    if (!!this.selectedDir.dataRoom) {
      if (!sort.active || sort.direction === '') {
        this.sortedDir = this.selectedDir.dataRoom;
        return;
      }
      this.sortedDir = this.selectedDir.dataRoom.sort((a: IDocument, b: IDocument) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'fileType':
            return compare(a.fileType, b.fileType, isAsc);
          case 'size':
            return compare(a.size || '', b.size || '', isAsc);
          case 'created':
            return compare(a.created || '', b.created || '', isAsc);
          default:
            return 0;
        }
      });
    }
  }

  drop(event: CdkDragDrop<any>) {
    console.log(event);
    const p = new Observable((subscriber) => {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      } else {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex,
        );
      }
      subscriber.next();
      subscriber.complete();
    });

    //wait for promise
    const sub = p.subscribe(() => {
      this.store.dispatch(toggleLoading({loading: true}));
      this.documentsService.reorder(this.selectedDir.id, this.selectedDir).subscribe({
        next: (res: any) => {
          this.isDragging = false;
          this.tableSource = new MatTableDataSource(this.selectedDir.dataRoom);
          this.initDataSource();
          this.refreshTree();
          this.toastr.success("Documents Sorted!", $localize`:@@companyName:Rondeivu`);
          this.store.dispatch(toggleLoading({loading: false}));
        }, error: (err: any) => {
          this.toastr.error("Documents Sort Failed!", $localize`:@@companyName:Rondeivu`);
          this.store.dispatch(toggleLoading({loading: false}));
        }
      });


    });

    sub.unsubscribe();
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
