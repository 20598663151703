import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Location} from '@angular/common';
import {
  ApiConfigService,
  AppConfigService,
  LoadingService,
  UuidService
} from 'src/app/services';
import {environment} from 'src/environments/environment';
import {delay, Subscription} from 'rxjs';
import {Store} from "@ngrx/store";
import {
  CognitoUser,
  Config, fetchTasks,
  getCognitoUser,
  getConfig,
  getNavItems,
  getSelectedBusinessUnit,
  getThemeState, getUserSettings,
  initialCognitoUserState,
  initialConfigState,
  initialThemeState,
  initialUserState,
  Theme,
  toggleDarkMode,
  toggleLoading,
  updateConfig,
  updateDrawerState,
  updateDrawerTabIdx,
  updateNavItems,
  updateSelectedBusinessUnit,
  updateSelectedUserView,
  updateUserSettings
} from "../../modules/redux";
import {IBusinessUnit} from "../../modules/business-units/models";
import {ToastrService} from "ngx-toastr";
import {IAttestation, INavItem, INote, ITask, LookupType} from "../../models";
import {TermsAndConditionsService} from "../../services/terms-and-conditions/terms-and-conditions.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AttestationService} from "../../services/attestation/attestation.service";
import {AttestationModalComponent} from "../attestation-modal/attestation-modal.component";
import {TermsAndConditionsModalComponent} from "../terms-and-conditions-modal/terms-and-conditions-modal.component";
import {loadLookup} from "../../modules/redux/stores/lookups/lookups.actions";
import {UtilService} from "../../modules/shared/services";
import {ModalSize} from "../../modules/shared/services/util/util.service";
import {UserCardSize} from "../../modules/shared/components";
import {UserSettingsService} from "../../modules/settings/services";
import {IUserSettings} from "../../modules/settings/models/i-user-settings";
import {toggleNavigating} from "../../modules/redux/stores/config/config.actions";
import {AppFade} from "../../animations/animations";


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [AppFade()],
})
export class NavigationComponent implements OnInit, OnDestroy, AfterViewInit {

  appVersion = require('../../../../package.json').version;
  apiVersion: string = '';

  //redux
  animationState = false;
  config$: Subscription = new Subscription();
  nav$: Subscription = new Subscription();
  cognito$: Subscription = new Subscription();
  theme$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  userSetting$: Subscription = new Subscription();
  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;
  cognitoUser = initialCognitoUserState;
  userSettings: IUserSettings = initialUserState;
  config: Config = initialConfigState;
  theme: Theme = initialThemeState;
  //navigation
  readonly ADMIN_REDIRECT_PATH = '/dashboard';
  readonly REDIRECT_PATH = '/deals';
  //animation
  enterAnim = false;
  exitAnim = false;
  show = true;
  //sidenav
  link = '';
  links: INavItem[] = [];
  //notes
  notes: INote[] = [];
  //view props

  private readonly HEADER_HEIGHT = 64;
  screenHeight = 0;
  //modals
  private attestationModalRef: MatDialogRef<AttestationModalComponent> | undefined;

  userCardSize = UserCardSize;

  constructor(
    private store: Store,
    private titleService: Title,
    private appConfig: AppConfigService,
    private uuidService: UuidService,
    private router: Router,
    private location: Location,
    private loader: LoadingService,
    private toastr: ToastrService,
    private termsService: TermsAndConditionsService,
    private attestationService: AttestationService,
    private dialog: MatDialog,
    public util: UtilService,
    private userSettingsService: UserSettingsService,
    private apiConfigService: ApiConfigService
  ) {
    this.uuidService.generateUUID();
    this.titleService.setTitle(environment.rondeivu_app_title);

    // subscribe to nav items
    this.nav$ = this.store.select(getNavItems).subscribe((navItems: any[]) => {
      this.links = navItems;
    });

    // subscribe to config
    this.config$ = this.store.select(getConfig).pipe(delay(0)).subscribe((configState: Config) => {
      this.config = configState;
      if (this.config?.show_navigation_toolbar) {
        this.screenHeight = screen.height - this.HEADER_HEIGHT;
      } else {
        this.screenHeight = screen.height
      }
    });

    // subscribe to the cognito store
    this.cognito$ = this.store.select(getCognitoUser).subscribe((cognito: CognitoUser) => {
      this.cognitoUser = cognito;
    });

    // subscribe to the theme store
    this.theme$ = this.store.select(getThemeState).subscribe((themeState: any) => {
      this.theme = themeState;
    });

    // attestations is business unit specific
    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((selected: IBusinessUnit) => {
      if (this.selectedBusinessUnit.businessUnitId != selected.businessUnitId) {
        this.selectedBusinessUnit = Object.assign(selected);
        // this.getAttestation();
        this.getTermsAndConditions();
        this.getUserSettings();
        this.getApiConfig();
        this.store.dispatch({type: fetchTasks});
      }
    });

    this.userSetting$ = this.store.select(getUserSettings).subscribe((userSettings: IUserSettings) => {
      this.userSettings = userSettings;
    });

    this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationStart) {
        this.animationState = false;
      }
      if (e instanceof NavigationEnd) {
        this.animationState = true;
      }
    });
  }

  ngOnInit() {
    this.animationState = true;
  }

  ngOnDestroy() {
    this.theme$.unsubscribe();
    this.config$.unsubscribe();
    this.cognito$.unsubscribe();
    this.nav$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.userSetting$.unsubscribe();
  }

  ngAfterViewInit() {
    this.store.dispatch(loadLookup({lookup: LookupType.Private}));
  }

  /**
   * Get the user settings
   */
  getUserSettings(): void {
    // this.store.dispatch(toggleLoading({loading: true}));
    this.userSettingsService.getUserSettings().subscribe({
      next: (userSettings: IUserSettings) => {
        this.store.dispatch(updateUserSettings({user: userSettings}));
      }, error: error => {
        console.log("Unable to get user settings!");
      }
    });
  }

  getApiConfig() {
    this.apiConfigService.getVersion().subscribe({
      next: (apiConfig: any) => {
        this.apiVersion = apiConfig.version;
      }, error: error => {
        console.log("Unable to get api version!");
      }
    })
  }

  /**
   * Creates the attestation modal and handles signing
   */
  getAttestation() {
    this.attestationService.getAttestation().subscribe({
      next: (res: IAttestation) => {
        if (!!res && res.attestationRequired) {
          this.attestationModalRef = this.dialog.open(AttestationModalComponent, {
            height: 'auto',
            width: this.util.getModalWidth(ModalSize.MEDIUM),
            backdropClass: "black-out-modal",
            disableClose: true,
            data: res
          });

          this.attestationModalRef.afterClosed().subscribe(result => {
            if (!!result) {
              // console.log("Dialog closed with payload!");
              this.attestationService.signAttestation().subscribe((res: any) => {
                this.toastr.success("Thank you, Welcome to Rondeivu!", $localize`:@@companyName:Rondeivu`);
              });
            } else {
              //redirect to login
              this.logOut();
            }
          });
        }
      }, error: err => {

      }
    });
  }

  /**
   * Creates the terms and conditions modal and handles signing
   */
  getTermsAndConditions() {
    this.termsService.getTermsAndConditions().subscribe({
      next: (res: { termsAndConditionsRequired: boolean, bodyHtml: string, pdfDownloadLink: string }) => {
        if (!!res && res.termsAndConditionsRequired) {
          const termsRef = this.dialog.open(TermsAndConditionsModalComponent, {
            height: 'auto',
            width: this.util.getModalWidth(ModalSize.MEDIUM),
            backdropClass: "black-out-modal",
            disableClose: true,
            data: res
          });

          termsRef.afterClosed().subscribe(result => {
            if (!!result) {
              // console.log("Dialog closed with payload!");
              this.termsService.signTermsAndConditions().subscribe((res: any) => {
                this.toastr.success("Terms and Conditions Signed!", $localize`:@@companyName:Rondeivu`);
                this.getAttestation();
              });
            } else {
              //redirect to login
              this.logOut();
            }
          });
        } else {
          this.getAttestation();
        }
      }, error: err => {

      }
    });
  }

  /**
   * Contact us action
   */
  contactUs() {
    this.store.dispatch(toggleNavigating({navigating: true}));
    this.animationState = false;
    setTimeout(() => {
      this.router.navigate([this.config.selected_business_unit.slug + '/contact-us']).then(() => {
        this.store.dispatch(toggleNavigating({navigating: false}));
        this.animationState = true;
      });
    }, 500);
  }

  //TODO - third party determination needs to be provided by api as a nav option
  thirdPartyDetermination() {
    this.store.dispatch(toggleNavigating({navigating: true}));
    this.animationState = false;
    setTimeout(() => {
      this.router.navigate([this.config.selected_business_unit.slug + '/third-party-determination']).then(() => {
        this.store.dispatch(toggleNavigating({navigating: false}));
        this.animationState = true;
      });
    }, 500);
  }


  /**
   * User selects a business unit
   * @param businessUnit
   */
  selectBusinessUnit(businessUnit: IBusinessUnit) {
    if (businessUnit.isEmployeeActive) {
      this.store.dispatch(toggleLoading({loading: true}));
      this.store.dispatch(updateSelectedBusinessUnit({businessUnit: businessUnit}));
      this.store.dispatch(updateSelectedUserView({view: businessUnit.businessUnitType.toString()}));
      this.store.dispatch(loadLookup({lookup: LookupType.Private}));
      this.util.clearStoreCache();

      this.getUserNavItems().then((navItemsResolved: boolean) => {
        this.store.dispatch(toggleLoading({loading: false}));
      }).catch(navErr => {
        this.util.logOut().then(() => {
          this.toastr.warning('You have no route access! Please contact your company Administrator.', $localize`:@@companyName:Rondeivu`);
        });
      });
    }
  }

  private getUserNavItems(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      //fetch the new nav items
      this.appConfig.getNavigationItems().subscribe({
        next: (navItems: INavItem[]) => {
          if (navItems.length == 0 || this.util.allNavItemsHidden(navItems) || this.util.allNavItemsDisabled(navItems)) {
            // the user has no nav items
            this.store.dispatch(toggleNavigating({navigating: true}));
            this.router.navigate(['/' + this.selectedBusinessUnit.slug]).then(() => {
              resolve(true);
              this.store.dispatch(toggleNavigating({navigating: false}));
            });
          } else {
            this.links = navItems;
            this.store.dispatch(updateNavItems({items: navItems}));
            // route to their first valid item
            const item = navItems.filter((n: INavItem) => {
              return (n.enabled && !n.hidden) ? n : null;
            })[0];
            if (!!item) {
              this.store.dispatch(toggleNavigating({navigating: true}));
              this.router.navigate(['/' + this.selectedBusinessUnit.slug + item.link], {queryParams: item.queryParams}).then(() => {
                resolve(true);
                this.store.dispatch(toggleNavigating({navigating: false}));
              });
            } else {
              this.store.dispatch(toggleNavigating({navigating: true}));
              this.router.navigate(['/' + this.selectedBusinessUnit.slug]).then(() => {
                resolve(true);
                this.store.dispatch(toggleNavigating({navigating: false}));
              });
            }
          }
        }, error: () => {
          reject(false);
        }
      });
    });
  }

  /**
   * Helper funcion to get the row color as determined by business unit selection
   * @param b selected business unit
   */
  getRowColor(b: IBusinessUnit): string {
    if (!!b && !!this.config.selected_business_unit) {
      if (b.businessUnitId === this.config.selected_business_unit.businessUnitId) {
        return 'whitesmoke';
      }
    }
    return '';
  }

  //legacy testing
  navToProfile() {
    this.store.dispatch(toggleNavigating({navigating: true}));
    this.animationState = false;
    setTimeout(() => {
      this.router.navigate([this.config.selected_business_unit.slug + '/settings']).then(() => {
        this.store.dispatch(toggleNavigating({navigating: false}));
        this.animationState = true;
      });
    }, 500);
  }

  /**
   * Navigate to the route according to the task
   * @param task
   */
  goToTask(task: ITask) {
    let link = ''
    if (!!task) {
      // if an action exists then dispatch it
      if (!!task.action) {
        this.store.dispatch({type: task.action});
      }
      // routing construction
      if (!!task.businessUnitSlug && !!task.route) {
        link = task.businessUnitSlug + '/' + task.route;
        if (!!task.routeId) {
          link += '/' + task.routeId;
          if (!!task.fragment) {
            link += '/' + task.fragment;
          }
        }
        if (!!task.fragment) {
          this.toastr.warning("Task Error: No Object Id", $localize`:@@companyName:Rondeivu`);
        }
      } else {
        this.toastr.warning("Task Error: No Business Unit", $localize`:@@companyName:Rondeivu`);
      }
      // task routing
      if (link != '') {
        if (!!task.queryParams) {
          this.animationState = false;
          this.store.dispatch(toggleNavigating({navigating: true}));
          setTimeout(() => {
            this.router.navigate([link], {queryParams: task.queryParams}).then(() => {
              this.store.dispatch(toggleNavigating({navigating: false}));
              this.animationState = true;
            });
          }, 500);
        } else {
          this.animationState = false;
          this.store.dispatch(toggleNavigating({navigating: true}));
          setTimeout(() => {
            this.router.navigate([link]).then(() => {
              this.store.dispatch(toggleNavigating({navigating: false}));
              this.animationState = true;
            });
          }, 500);
        }
      }
    }
  }

  getTaskBg(task: ITask) {
    // switch on status
    switch (task.status?.toLowerCase()) {
      case 'task':
        return this.theme.tasks.task;
      case 'complete':
        return this.theme.tasks.complete;
      case 'pending':
        return this.theme.tasks.pending;
      case 'info':
        return this.theme.tasks.info;
      default:
        return '';
    }
  }

  getTaskImage(status: string) {
    // switch on status
    switch (status?.toLowerCase()) {
      case 'task':
        return 'pending_actions';
      case 'complete':
        return 'task_alt';
      case 'pending':
        return 'schedule';
      case 'info':
        return 'info_outline';
      default:
        return 'pending_action';
    }
  }

  home() {
    const path = '/' + this.config.selected_business_unit.slug + '/deals';
    window.open(path, "_self")?.focus();
  }

  /**
   * The main navigation triggers an animation before routing
   * @param navObj
   */
  navigate(navObj: INavItem) {
    if (navObj.enabled) {
      const path = '/' + this.config.selected_business_unit.slug + '/' + navObj.link;
      if (!!navObj.queryParams) {
        this.animationState = false;
        this.store.dispatch(toggleNavigating({navigating: true}));
        setTimeout(() => {
          this.router.navigate([path], {queryParams: navObj.queryParams}).then(() => {
            this.store.dispatch(toggleNavigating({navigating: false}));
            this.animationState = true;
          });
        }, 500);
      } else {
        this.animationState = false;
        this.store.dispatch(toggleNavigating({navigating: true}));
        setTimeout(() => {
          this.router.navigate([path]).then(() => {
            this.store.dispatch(toggleNavigating({navigating: false}));
            this.animationState = true;
          });
        }, 500);
      }
    }
  }

  /**
   * Determines the location of the navigation highlight and arrow
   */
  isActiveRoute(navObj: INavItem): boolean {
    const location = this.location.path();
    let fragment = '/' + location.split('/')[2];
    //special case for admin deals route
    if (!!navObj.queryParams) {
      return fragment == (navObj.link + this.getQueryParamsStr(navObj));
    } else {
      fragment = fragment.split('?')[0];
      return fragment == navObj.link;
    }
  }

  getActiveRouteClass(navObj: INavItem) {
    let isActive = this.isActiveRoute(navObj);
    if (!navObj.enabled) {
      return 'nav-item nav-item-disabled';
    }
    if (isActive) {
      return 'nav-item active-title';
    }
    return 'nav-item';
  }

  private getQueryParamsStr(navObj: INavItem) {
    let str = "";
    if (!!navObj && !!navObj.queryParams) {
      str = "?";
      const keys = Object.keys(navObj.queryParams);
      for (let i = 0; i <= keys.length - 1; i++) {
        str += keys[i] + "=" + navObj.queryParams[keys[i]]
        if (i != (keys.length - 1)) {
          str += "&";
        }
      }
    }
    return str;
  }

  handleEsc() {
    //do nothing
  }

  getNavItemLinkWithParams(navObj: any) {
    if (!!navObj.params) {
      let str = '?';
      let keys = Object.keys(navObj.params);
      keys.forEach(key => {
        str += key + '=' + navObj.params[key].toString();
      });
      return navObj.link + str;
    }
    return navObj.link;
  }

  /**
   * Toggles the right drawer open even dispatching the tab index based off the icon name
   * @param event
   */
  toggleDrawer(event: string) {
    //the event returns the name of the icon being used
    switch (event) {
      case 'description':
        // console.log('dispatch: ' + updateConfig.type);
        this.store.dispatch(updateConfig({
          config: {
            ...this.config,
            right_drawer_open: !this.config.right_drawer_open,
            right_drawer_tab_idx: 0
          }
        }));
        break;
      case 'format_list_numbered':
        // console.log('dispatch: ' + updateConfig.type);
        this.store.dispatch(updateConfig({
          config: {
            ...this.config,
            right_drawer_open: !this.config.right_drawer_open,
            right_drawer_tab_idx: 1
          }
        }));
        break;
      case 'speaker_notes':
        // console.log('dispatch: ' + updateConfig.type);
        this.store.dispatch(updateConfig({
          config: {
            ...this.config,
            right_drawer_open: !this.config.right_drawer_open,
            right_drawer_tab_idx: 2
          }
        }));
        break;
      default:
        // console.log('dispatch: ' + updateConfig.type);
        this.store.dispatch(updateConfig({
          config: {
            ...this.config,
            right_drawer_open: !this.config.right_drawer_open,
            right_drawer_tab_idx: 0
          }
        }));
    }
  }

  /**
   * logs out of the app
   */
  public async logOut(): Promise<void> {
    return this.util.logOut();
  }

  /**
   * Toggles the app dark mode
   * @param val
   */
  toggleDarkMode(val: boolean) {
    // console.log('drawer tab idx change: ' + val);
    this.config.rondeivu_theme_dark = val;
    // console.log('dispatch: ' + toggleDarkMode.type);
    this.store.dispatch(toggleDarkMode({mode: val}));
  }

  /**
   * Triggered when the right drawer is closed
   * @param event
   */
  closeDrawer(event: boolean) {
    // console.log('drawer tab idx change: ' + event);
    if (this.config.right_drawer_open && !event) {
      // console.log('dispatch: ' + updateDrawerState.type);
      this.store.dispatch(updateDrawerState({open: event}));
    }
  }

  /**
   * Triggered when the right drawer tab group is changed
   * @param event
   */
  changeTab(event: number) {
    // console.log('drawer tab idx change: ' + event);
    if (this.config.right_drawer_tab_idx != event) {
      // console.log('dispatch: ' + updateDrawerTabIdx.type);
      this.store.dispatch(updateDrawerTabIdx({idx: event}));
    }
  }

}
