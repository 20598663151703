import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IKycSectionText} from "../../models/entity";

@Component({
  selector: 'app-kyc-input-section-text',
  templateUrl: './kyc-input-section-text.component.html',
  styleUrls: ['./kyc-input-section-text.component.scss']
})
export class KycInputSectionTextComponent implements OnInit, OnChanges {

  @Input() sectionTexts?: IKycSectionText[] = [];

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'sectionTexts':
            this.sectionTexts = changes['sectionTexts'].currentValue;
            break;
        }
      }
    }
  }

}


