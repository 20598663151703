<div id="main-body" class="container-fluid" style="overflow-x: hidden; padding: 0; bottom:0;">
  <div id="blur">
    <div id="header" class="row">
      <mat-toolbar
        id="toolbar-login"
        class="mat-primary">
        <div id="img-box-login">
          <img id="app-img" src="assets/images/Rondeivu_Logo_Lockup-3_white.png">&nbsp;
        </div>
      </mat-toolbar>
    </div>
    <div id="outlet" class="row">
      <router-outlet></router-outlet>
    </div>
    <div id="footer" class="row fixed-bottom">
      <br/>
      <!-- Footer -->
      <footer class="text-white">
        <div class="row justify-content-start">
          <div class="col-12 col-sm-12 col-md-2">
            CONTACT
          </div>
          <div class="d-none d-md-block col-md-3" style="display: inline-flex;">
            <div class="row">
              <div class="col-1">
                <mat-icon class="c-icon">location_pin</mat-icon>
              </div>
              <div class="col-11">
                <span [innerHTML]="config.rondeivu_address_1"></span>
              </div>
            </div>
          </div>
          <div class="d-none d-md-block col-md-3" style="display: inline-flex;">
            <div class="row">
              <div class="col-1">
                <mat-icon class="c-icon">location_pin</mat-icon>
              </div>
              <div class="col-11">
                <span [innerHTML]="config.rondeivu_address_2"></span>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-6 col-md-2" style="display: inline-flex;">
            <mat-icon class="c-icon">email</mat-icon>&nbsp;<a [href]="'mailto:'+ config.rondeivu_email"
                                                              style="padding-top: 4px; color: white;"><span>{{ config.rondeivu_email }}</span></a>
          </div>
          <!-- Copyright -->
          <div class="col-6 col-sm-6 col-md-2" style="display: inline-flex;">
            Copyright &copy; {{ getYear() }} Rondeivu, Inc.
          </div>
          <!-- Copyright -->
        </div>
      </footer>
      <!-- Footer -->
    </div>
  </div>
</div>
