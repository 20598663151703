import {createAction, props} from '@ngrx/store';
import {Update, EntityMap, EntityMapOne, Predicate} from '@ngrx/entity';
import {iMandateResponse} from "../../../mandates/models";


export const loadMandates = createAction('[iMandateResponse/API] Load iMandateResponses', props<{ mandates: iMandateResponse[] }>());
export const setMandates = createAction('[iMandateResponse/API] Set iMandateResponses', props<{ mandates: iMandateResponse[] }>());
export const addMandate = createAction('[iMandateResponse/API] Add iMandateResponse', props<{ mandate: iMandateResponse }>());
export const setMandate = createAction('[iMandateResponse/API] Set iMandateResponse', props<{ mandate: iMandateResponse }>());
export const upsertMandate = createAction('[iMandateResponse/API] Upsert iMandateResponse', props<{ mandate: iMandateResponse }>());
export const addMandates = createAction('[iMandateResponse/API] Add iMandateResponses', props<{ mandates: iMandateResponse[] }>());
export const upsertMandates = createAction('[iMandateResponse/API] Upsert iMandateResponses', props<{ mandates: iMandateResponse[] }>());
export const updateMandate = createAction('[iMandateResponse/API] Update iMandateResponse', props<{ update: Update<iMandateResponse> }>());
export const updateMandates = createAction('[iMandateResponse/API] Update iMandateResponses', props<{ updates: Update<iMandateResponse>[] }>());
export const mapMandate = createAction('[iMandateResponse/API] Map iMandateResponse', props<{ entityMap: EntityMapOne<iMandateResponse> }>());
export const mapMandates = createAction('[iMandateResponse/API] Map iMandateResponses', props<{ entityMap: EntityMap<iMandateResponse> }>());
export const deleteMandate = createAction('[iMandateResponse/API] Delete iMandateResponse', props<{ id: string }>());
export const deleteMandates = createAction('[iMandateResponse/API] Delete iMandateResponses', props<{ ids: string[] }>());
export const deleteMandatesByPredicate = createAction('[iMandateResponse/API] Delete iMandateResponses By Predicate', props<{ predicate: Predicate<iMandateResponse> }>());
export const clearMandates = createAction('[iMandateResponse/API] Clear iMandateResponses');
