import {Injectable, OnDestroy} from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {from, Observable, Subscription} from 'rxjs';
import {
  Config,
  getConfig,
  initialConfigState,
  updateSelectedEntity,
  updateSelectedEntityId
} from "../modules/redux";
import {Location} from "@angular/common";
import {ToastrService} from "ngx-toastr";
import {AppConfigService} from "../services";
import {Store} from "@ngrx/store";
import {EntitiesService} from "../modules/entities/services/entities.service";

@Injectable({
  providedIn: 'root'
})
export class EntityResolver implements Resolve<boolean>, OnDestroy {
  private readonly LOGIN_PATH = '/auth/login';
  config$: Subscription = new Subscription();
  config: Config = initialConfigState;
  entityId = '';

  constructor(
    private router: Router,
    private location: Location,
    private toastr: ToastrService,
    private entitiesService: EntitiesService,
    private appConfig: AppConfigService,
    private store: Store) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    console.log("Running entity resolver!");
    this.entityId = route.paramMap.get('id') || '';
    this.store.dispatch(updateSelectedEntityId({entityId: this.entityId}));
    return from(this.fetchAndDispatchEntity(route, state));
  }

  private fetchAndDispatchEntity(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      //entity already exists in the store
      if (!!this.config.selected_entity) {
        console.log("Entity resolver found existing entity...");
        resolve(true);
      } else {
        console.log("Entity resolver fetching entity...");
        this.entitiesService.getBusinessUnitEntityById(this.entityId).subscribe({
          next: (entity: any) => {
            this.store.dispatch(updateSelectedEntity({entity: entity}));
            this.store.dispatch(updateSelectedEntityId({entityId: entity.id}));
            resolve(true);
          }, error: (error: any) => {
            reject(false);
          }
        });
      }
    });
  }
}
