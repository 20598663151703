import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {
  AdminConfigComponent,
  AdminNavigationComponent, CloudwatchSnsBounceLogComponent, CloudwatchSnsComplaintLogComponent,
  CloudwatchSnsDeliveryLogComponent,
  CloudwatchSnsLogComponent, PermissionsComponent, UserEmailNotificationsComponent
} from "./components";
import {RondeivuEmployeesComponent} from "./components";

const routes: Routes = [
  {
    path: '', component: AdminNavigationComponent, children: [
      {path: 'config', component: AdminConfigComponent},
      {path: 'rondeivu-employees', component: RondeivuEmployeesComponent},
      {path: 'sms-log', component: CloudwatchSnsLogComponent},
      {path: 'email-delivery-log', component: CloudwatchSnsDeliveryLogComponent},
      {path: 'email-bounce-log', component: CloudwatchSnsBounceLogComponent},
      {path: 'email-complaint-log', component: CloudwatchSnsComplaintLogComponent},
      {path: 'user-email-notifications', component: UserEmailNotificationsComponent},
      {path: 'permissions', component: PermissionsComponent},
      {path: '', redirectTo: 'config', pathMatch: 'full'},
      {path: '**', redirectTo: ''},
    ]
  },
  {path: '', redirectTo: 'config', pathMatch: 'full'},
  {path: '**', redirectTo: 'config'},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {
}
