<div id="spinner-container" class="mod">
  <!-- Modal content -->
  <div class="spinner-content">
    <div [hidden]="spinner != 1">
      <div class="lds-dual-ring"></div>
    </div>
    <div [hidden]="spinner != 2">
      <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div [hidden]="spinner != 3">
      <div class="lds-default">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div [hidden]="spinner != 4">
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div [hidden]="spinner != 5">
      <div class="lds-grid">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div [hidden]="spinner != 6">
      <div class="lds-hourglass"></div>
    </div>
    <div [hidden]="spinner != 7">
      <div class="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
    <div [hidden]="spinner != 8">
      <div class="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div [hidden]="spinner != 9">
      <mat-spinner color="accent"></mat-spinner>
    </div>
  </div>
</div>
