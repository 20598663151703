import {Injectable, OnDestroy} from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot, Router,
} from '@angular/router';
import {distinct, from, Observable, Subscription} from 'rxjs';
import {BusinessUnitService} from "../modules/business-units/services";
import {Store} from "@ngrx/store";
import {
  Config,
  getConfig,
  getSelectedBusinessUnit, initialConfigState,
  updateEditingBusinessUnit
} from "../modules/redux";
import {IBusinessUnit} from "../modules/business-units/models";
import {ToastrService} from "ngx-toastr";
import {UtilService} from "../modules/shared/services";

@Injectable({
  providedIn: 'root'
})
export class SelectedBusinessUnitResolver implements Resolve<boolean>, OnDestroy {
  config$: Subscription = new Subscription();
  config: Config = initialConfigState;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private businessUnitService: BusinessUnitService,
    private store: Store,
    private util: UtilService
  ) {
    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return from(this.fetchAndDispatchEditingBusinessUnit(route, state));
  }


  private fetchAndDispatchEditingBusinessUnit(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let editingBusinessUnitId = route.paramMap.get('id');
      if (this.util.isType('admin')) {
        if (!!editingBusinessUnitId) {
          this.businessUnitService.getBusinessUnit(editingBusinessUnitId).subscribe({
            next: (bu: IBusinessUnit) => {
              this.store.dispatch(updateEditingBusinessUnit({businessUnit: bu}));
              resolve(true);
            }, error: err => {
              resolve(true);
              this.router.navigate(['/' + this.config.selected_business_unit.slug + '/business-units']).then(() => {
                this.toastr.error("Access denied!", $localize`:@@companyName:Rondeivu`);
              });
            }
          });
        }
      } else {
        resolve(true);
        this.router.navigate(['/' + this.config.selected_business_unit.slug]).then(() => {
          this.toastr.error("Access denied!", $localize`:@@companyName:Rondeivu`);
        });
      }
    });
  }
}
