import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {
  Config, fetchTasks,
  getConfig,
  getLookups,
  getSelectedBusinessUnit,
  getSelectedUserId,
  initialConfigState,
  initialLookupsState,
  Lookups,
  toggleLoading,
} from "../../../redux";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {IBusinessUnit} from "../../../business-units/models";
import {UsersService} from "../../services/users/users.service";

import {
  ConfirmationModalComponent,
  UserCardModalComponent,
  UserCardSize
} from "../../../shared/components";
import {MatDialog} from "@angular/material/dialog";
import {ModalSize, UtilService} from "../../../shared/services/util/util.service";
import {IAppUserRequest} from "../../models/i-users";


@Component({
  selector: 'app-user-information',
  templateUrl: './user-information.component.html',
  styleUrls: ['./user-information.component.scss']
})
export class UserInformationComponent implements OnInit {
  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  selectedUserId$: Subscription = new Subscription();

  private config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;
  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;
  selectedUserId: string = '';

  userCardSize = UserCardSize;

  //reactive form
  userForm = new FormGroup({
    id: new FormControl(),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    companyName: new FormControl(),
    created: new FormControl(),
    email: new FormControl('', Validators.required),
    countryCode: new FormControl('', Validators.required),
    mobile: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
    mfaType: new FormControl(),
    phoneNumberVerified: new FormControl({value: false, disabled: true}),
    emailVerified: new FormControl({value: false, disabled: true}),
    active: new FormControl(false, Validators.required),
    referredByEmail: new FormControl(),
    status: new FormControl(),
    userHasRegistered: new FormControl(),
    imageUrl: new FormControl(),
    profileLink: new FormControl()
  });

  constructor(
    private store: Store,
    private router: Router,
    private toastr: ToastrService,
    private usersService: UsersService,
    private dialog: MatDialog,
    public util: UtilService
  ) {
    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });
    this.lookup$ = this.store.select(getLookups).subscribe(lookups => {
      this.lookups = lookups;
    });
    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe(businessUnit => {
      this.selectedBusinessUnit = businessUnit;
    });
    this.selectedUserId$ = this.store.select(getSelectedUserId).subscribe(userId => {
      this.selectedUserId = userId;
      if (!!this.selectedBusinessUnit && !!this.selectedBusinessUnit.businessUnitId) {
        this.subscribeToDatasource();
      }
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedUserId$.unsubscribe();
  }

  subscribeToDatasource(): void {
    this.usersService.getUser().subscribe({
      next: (user: any) => {
        this.userForm.patchValue(user);
      }, error: error => {
        this.toastr.error("Something went wrong!");
      }
    });
  }

  save() {
    let payload = this.userForm.getRawValue() as unknown as IAppUserRequest;
    this.store.dispatch(toggleLoading({loading: true}));
    this.usersService.updateUser(payload).subscribe({
      next: (user: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("User Information Saved!", $localize`:@@companyName:Rondeivu`);
        this.subscribeToDatasource();
        this.store.dispatch({type: fetchTasks});
      }, error: error => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Something went wrong!");
      }
    });
  }

  forcePasswordReset() {
    const confRef = this.dialog.open(ConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      data: this.selectedUserId
    });

    confRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.store.dispatch(toggleLoading({loading: true}));
        this.usersService.forcePasswordReset(this.selectedUserId).subscribe({
          next: (res: any) => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("Force Password Reset!", $localize`:@@companyName:Rondeivu`);
          }, error: error => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Something went wrong!");
          }
        });
      }
    });
  }

  reset2Fa() {
    const confRef = this.dialog.open(ConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      data: this.selectedUserId
    });

    confRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.store.dispatch(toggleLoading({loading: true}));
        this.usersService.reset2Fa(this.selectedUserId).subscribe({
          next: (res: any) => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("User 2FA Reset!", $localize`:@@companyName:Rondeivu`);
            this.subscribeToDatasource();
            this.store.dispatch({type: fetchTasks});
          }, error: error => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Something went wrong!");
          }
        });
      }
    });
  }

  userPasswordReset() {
    const confRef = this.dialog.open(ConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      data: this.selectedUserId
    });

    confRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.store.dispatch(toggleLoading({loading: true}));
        this.usersService.userPasswordReset(this.selectedUserId).subscribe({
          next: (res: any) => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("User Password Reset!", $localize`:@@companyName:Rondeivu`);
          }, error: error => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Something went wrong!");
          }
        });
      }
    });
  }

  openImageUploadModal() {
    const dialogRef = this.dialog.open(UserCardModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog closed!");
      if (!!result) {
        const uId = this.userForm.get('id')?.value || '';
        const file = new File([result], 'user.png');
        this.usersService.uploadUserImage(file, uId).subscribe({
          next: (res: any) => {
            let url = res.url || ''
            this.userForm.get('imageUrl')?.setValue(url);
            this.toastr.success("Image Uploaded! ", $localize`:@@companyName:Rondeivu`);
          }, error: (err: any) => {
            this.toastr.error("Something went wrong! " + err.message);
          }
        });
      }
    });
  }

  clearImage() {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog closed!");
      if (!!result) {
        const uId = this.userForm.get('id')?.value || '';
        this.usersService.clearUserImage(uId).subscribe({
          next: (res: any) => {
            let url = res.url || ''
            this.userForm.get('imageUrl')?.setValue(url);
            this.toastr.success("Image Cleared! ", $localize`:@@companyName:Rondeivu`);
          }, error: (err: any) => {
            this.toastr.error("Something went wrong! " + err?.errors[0]);
          }
        });
      }
    });
  }

}
