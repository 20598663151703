import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CompanyDataComponent, CompanyEmployeeInfoComponent,
  CompanyEmployeesComponent, InvitationsComponent,
  ProfileComponent,
  SettingsNavigationComponent
} from './components';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {NgMaterialModule} from "../ng-material/ng-material.module";
import {SharedModule} from "../shared/shared.module";
import {SettingsRoutingModule} from "./settings-routing.module";
import {CompanyEmployeeModalComponent} from './components/company-employee-modal/company-employee-modal.component';
import {PhoneVerifyModalComponent} from './components/profile/phone-verify-modal/phone-verify-modal.component';
import {DragDropModule} from "@angular/cdk/drag-drop";

@NgModule({
  declarations: [
    SettingsNavigationComponent,
    ProfileComponent,
    CompanyEmployeesComponent,
    CompanyDataComponent,
    CompanyEmployeeModalComponent,
    PhoneVerifyModalComponent,
    CompanyEmployeeInfoComponent,
    InvitationsComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SettingsRoutingModule,
    HttpClientModule,
    NgMaterialModule,
    SharedModule,
    DragDropModule
  ]
})
export class SettingsModule { }
