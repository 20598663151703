import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deal-analytics',
  templateUrl: './deal-analytics.component.html',
  styleUrls: ['./deal-analytics.component.scss']
})
export class DealAnalyticsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
