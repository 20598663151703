import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanDeactivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import {from, Observable, Subscription} from 'rxjs';
import {AppConfigService} from "../services";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {IDealCard} from "../modules/shared/models";
import {
  Config,
  getConfig,
  initialConfigState,
  updateDeal,
  updateDealId,
  updateDealRole,
  updateDealTabs
} from "../modules/redux";
import {IBusinessUnitRole} from "../modules/business-units/models";
import {INavItem} from "../models";
import {Location} from "@angular/common";
import {DealService, DealTeamMembersService} from "../modules/deals/services";

@Injectable({
  providedIn: 'root'
})
export class DealGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {

  private readonly LOGIN_PATH = '/auth/login';
  config$: Subscription = new Subscription();
  config: Config = initialConfigState;
  dealId = '';

  constructor(
    private router: Router,
    private location: Location,
    private toastr: ToastrService,
    private dealService: DealService,
    private dealTeamMemberService: DealTeamMembersService,
    private appConfig: AppConfigService,
    private store: Store) {
    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log("Running deal guard!");
    this.dealId = route.paramMap.get('id') || '';
    this.store.dispatch(updateDealId({dealId: this.dealId}));
    return this.fetchAndDispatchDeal(route, state);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  private fetchAndDispatchDeal(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const reroutePath = '/' + this.config.selected_business_unit.slug + '/deals';
      console.log("Deal guard fetching deal...");
      this.dealService.getDeal(this.dealId).subscribe({
        next: (deal: IDealCard) => {
          this.store.dispatch(updateDeal({deal: deal}));
          this.fetchAndDispatchDealTabs(route, state).then((success: boolean) => {
            if (success) {
              this.fetchAndDispatchDealRole(route, state).then((allow: boolean) => {
                if (allow) {
                  resolve(true);
                } else {
                  reject(false);
                }
              });
            } else {
              reject(false);
            }
          });
        }, error: (error: any) => {
          reject(false);
          this.router.navigate([reroutePath]).then(() => {
            this.toastr.error("Access denied!", $localize`:@@companyName:Rondeivu`);
          });
        }
      });
    });
  }

  private fetchAndDispatchDealRole(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const reroutePath = '/' + this.config.selected_business_unit.slug + '/deals';
      console.log("Deal guard fetching deal team member role...");
      this.dealTeamMemberService.getDealTeamMemberPermissions(this.dealId).subscribe({
        next: (role: IBusinessUnitRole) => {
          this.store.dispatch(updateDealRole({dealRole: role}));
          resolve(true);
        }, error: err => {
          resolve(false);
          this.router.navigate([reroutePath]).then(() => {
            this.toastr.error("Unable to get Deal Role!", $localize`:@@companyName:Rondeivu`);
          });
        }
      });
    });
  }

  private fetchAndDispatchDealTabs(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const reroutePath = '/' + this.config.selected_business_unit.slug + '/deals/' + this.dealId;
      let childRoute = '/' + state.url.split('/')[4];
      childRoute = childRoute.split('?')[0];
      console.log("Deal guard fetching deal tabs...");
      this.appConfig.getDealTabsByDealId(this.dealId).subscribe({
        next: (tabs: INavItem[]) => {
          this.store.dispatch(updateDealTabs({tabs: tabs}));
          // page access and rerouting
          if (this.validateTabs(tabs, childRoute)) {
            resolve(true);
          } else {
            resolve(false);
            this.router.navigate([reroutePath]).then(() => {
              this.toastr.error("Access denied!", $localize`:@@companyName:Rondeivu`);
            });
          }
        }, error: (error: any) => {
          resolve(false);
          this.router.navigate(['/' + this.config.selected_business_unit.slug + '/deals']).then(() => {
            this.toastr.error("Unable to get Deal Tabs!", $localize`:@@companyName:Rondeivu`);
          });
        }
      });
    });
  }

  private validateTabs(tabs: INavItem[], childRoute: string): boolean {
    // check the nav items
    for (let i = 0; i <= tabs.length - 1; i++) {
      if ((tabs[i].link == childRoute) && tabs[i].enabled && !tabs[i].hidden) {
        return true;
      }
    }
    return false;
  }

}
