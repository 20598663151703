<!--{{structure|json}}-->
<div style="overflow-y: auto; height: calc(100vh - 242px);">
  <div class="container-fluid">
    <div style="overflow-x: hidden;">
      <div class="row justify-content-center" style="padding-top: 25px;">
        <div class="col-11">
          <span class="rm-title rm-title-3">Deal Team Member Permissions</span><br/><br/>
          <span>
            Deal team members can be assigned custom roles from the drop down menu below.
            Each role defines how the employee can access information for <b>this specific deal only</b>.
            Default permissions have been set by role type.
            Customise these permissions individually to manage access control using the associated drop down menus.
          </span>
        </div>
      </div>
      <br/>
      <!--    WELL START-->
      <div class="row justify-content-center" style="padding-bottom: 25px;">
        <div class="col-11">
          <!--          <app-user-card [firstName]="selectedEmployee.user.firstName"-->
          <!--                         [lastName]="selectedEmployee.user.lastName"-->
          <!--                         [displayName]="selectedEmployee.user.email"-->
          <!--                         [borderColor]="'transparent'"-->
          <!--                         [sideColor]="'#002150'"-->
          <!--                         [image]="selectedEmployee.user.imageUrl"-->
          <!--          ></app-user-card>-->
          <form [formGroup]="userForm" (ngSubmit)="savePermissions()">
            <div class="row justify-content-center" style="padding-bottom: 20px;">
              <div class="col-12 col-md-6">
                <div *ngIf="!!allRoles && (allRoles.length > 0)">
                  <mat-form-field appearance="outline" class="form-field">
                    <mat-label>Select Role</mat-label>
                    <mat-select (selectionChange)="updateForm($event)" formControlName="role">
                      <mat-option *ngFor="let role of allRoles" [value]="role">
                        <div matTooltipPosition="right" [matTooltip]="!!role? role.description : ''">
                          {{ !!role ? role.name : '' }}
                        </div>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-12 col-md-6 text-end">
                <button type="button" mat-raised-button color="primary" (click)="savePermissions()"
                        [disabled]="!selectedRole">SAVE
                </button>
              </div>
            </div>

            <div class="row">
              <mat-accordion multi="true">
                <mat-expansion-panel expanded="true">
                  <mat-expansion-panel-header>
                    <mat-panel-title style="font-size: 1.1rem;">
                      DEAL PERMISSIONS
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <span>
            Previously assigned employee roles and permissions are not affected by this deal based role assignment.
          </span><br/><br/>
                  <div *ngFor="let pg of structure.dealTeamPermissionGroups"
                       class="row">
                    <div class="col-12 col-md-6" style="display: flex;">
                      <div>
                        <mat-icon color="primary">{{ pg.icon }}</mat-icon>
                      </div>
                      &nbsp;
                      <div style="margin-top: 5px;">
                        <mat-label style="font-weight: bold;">{{ pg.name }}</mat-label>
                        <br/>
                        <mat-label style="font-weight: lighter;">{{ pg.description }}</mat-label>
                      </div>
                    </div>
                    <!--dynamic form start-->
                    <div class="col-12 col-md-6" [formGroup]="dynamicControls">
                      <mat-form-field appearance="outline" class="form-field">
                        <mat-label>Permission</mat-label>
                        <mat-select [formControlName]="pg.id" [disabled]="!selectedRole"
                                    (selectionChange)="onChange(pg, $event)">
                          <mat-select-trigger>
                            <div style="display: flex;">
                              <mat-icon
                                [ngStyle]="{'color': selectedOptions.get(pg.id)?.color}">{{ selectedOptions.get(pg.id)?.icon }}
                              </mat-icon>&nbsp;
                              <span style="line-height: 24px;">{{ selectedOptions.get(pg.id)?.name }}</span>
                            </div>
                          </mat-select-trigger>
                          <mat-option *ngFor="let option of pg.permissionOptions"
                                      [value]="option"
                                      class="perm-opt">
                            <mat-icon [ngStyle]="{'color': option.color}">{{ option.icon }}</mat-icon>
                            <span class="opt-title">{{ option.name }}</span><br/>
                            <span class="opt-desc">{{ option.description }}</span>
                            {{ option.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <!-- dataroom options-->
                    <div *ngIf="pg.name.includes('Deal Dataroom')" class="col-12" style="padding: 0 10px;"
                         [hidden]="selectedOptions.get(pg.id)?.name == 'No Access'">
                      <mat-checkbox color="primary" formControlName="canAccessEntireDataroom"
                                    style="padding-bottom: 20px;"
                                    [disabled]="selectedOptions.get(pg.id)?.name == 'No Access'"
                                    (change)="checkRootFolders($event)">Member can access entire data room
                      </mat-checkbox>
                      <br/>
                      <div
                        style="padding: 25px; margin-bottom: 25px; background: whitesmoke; border:1px solid grey; border-radius: 12px;">
                        <mat-label>Root Folders</mat-label>
                        <br/><br/>
                        <div *ngFor="let rf of memberRole?.dataroomRootFolders">
                          <mat-checkbox color="primary" [checked]="rf.active"
                                        [disabled]="userForm.get('canAccessEntireDataroom')?.value == true"
                                        (change)="toggleRootFolderActive($event, rf)">{{ rf.name }}
                          </mat-checkbox>
                          <br/><br>
                        </div>
                      </div>

                    </div>
                    <!-- document options-->
                    <div *ngIf="pg.name.includes('Deal Documents')" class="col-12" style="padding: 0 10px;"
                         [hidden]="selectedOptions.get(pg.id)?.name == 'No Access'">
                      <mat-checkbox color="primary" formControlName="canAccessEntireDocumentRoom"
                                    style="padding-bottom: 20px;"
                                    [disabled]="selectedOptions.get(pg.id)?.name == 'No Access'"
                                    (change)="checkDocumentRootFolders($event)">Member can access all documents
                      </mat-checkbox>
                      <div
                        style="padding: 25px; margin-bottom: 25px; background: whitesmoke; border:1px solid grey; border-radius: 12px;">
                        <mat-label>Root Folders</mat-label>
                        <br><br>
                        <div *ngFor="let drf of memberRole?.documentsRoomRootFolders">
                          <mat-checkbox color="primary" [checked]="drf.active"
                                        [disabled]="userForm.get('canAccessEntireDocumentRoom')?.value == true"
                                        (change)="toggleDocumentFolderActive($event, drf)">{{ drf.name }}
                          </mat-checkbox>
                          <br><br>
                        </div>
                      </div>
                    </div>
                    <!-- document options end-->
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <!--          row start-->
            <div class="row" style="padding-top: 20px; padding-bottom: 150px;">
              <div class="col-12 text-end">
                <!--              <button type="button" mat-raised-button (click)="back()">BACK</button> &nbsp;-->
                <button type="submit" mat-raised-button color="primary" [disabled]="!selectedRole">SAVE</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

