import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {IMediaRoom} from "../../../models";
import {MediaService} from "../../../services/media/media.service";
import {Router} from "@angular/router";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {NgxCaptureService} from "ngx-capture";
import html2canvas from "html2canvas";

@Component({
  selector: 'app-media-modal',
  templateUrl: './media-modal.component.html',
  styleUrls: ['./media-modal.component.scss']
})
export class MediaModalComponent implements OnInit {
  //reactive form
  userForm = new FormGroup({
    id: new FormControl(),
    title: new FormControl(),
    description: new FormControl(),
    linkUrl: new FormControl(),
    linkUrlPreview: new FormControl(),
    imageUrl: new FormControl('', Validators.required),
    created: new FormControl()
  });

  now = new Date();
  safeSrc: SafeResourceUrl | unknown;

  @ViewChild('screen', {static: true}) screen: any;

  constructor(public dialogRef: MatDialogRef<MediaModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { isEditing: boolean, mediaRoom: IMediaRoom },
              private mediaService: MediaService,
              private router: Router,
              private sanitizer: DomSanitizer,
              private captureService:NgxCaptureService
  ) {
  }

  ngOnInit(): void {
    if (!!this.data && this.data.isEditing) {
      this.userForm.patchValue(this.data.mediaRoom);
      this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.mediaRoom.linkUrl);
    } else {
      this.userForm.get("created")?.setValue(this.now);
    }
  }

  save() {
    this.dialogRef.close(this.userForm.getRawValue());
  }

  capture() {

    html2canvas(this.screen.nativeElement, {allowTaint:true, foreignObjectRendering:true, useCORS:true}).then(canvas => {
      document.body.appendChild(canvas);
    });

    // this.captureService.getImage(this.screen.nativeElement, true)
    //   .pipe(
    //     tap(img => {
    //       const file = this.DataURIToBlob(img);
    //       this.mediaService.addMediaRoomImage(file).subscribe((res: { url: string }) => {
    //         this.userForm.get("imageUrl")?.setValue(res.url);
    //       });
    //     })
    //   ).subscribe();
  }

  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }

  /**
   * Action for adding a new file
   * @param event
   */
  addImageFile(event: any) {
    // console.log(event);
    if (!!event && !!event.target && !!event.target.files[0]) {
      this.mediaService.addMediaRoomImage(event.target.files[0]).subscribe((res: { url: string }) => {
        this.userForm.get("imageUrl")?.setValue(res.url);
      });
    }
  }

}
