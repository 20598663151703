import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {CognitoUser, Config, getCognitoUser, getConfig, initialCognitoUserState, initialConfigState} from "../../redux";
import {RondeivuHttpClient} from "../../../handlers";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ComplyAdvantageService {
  readonly BASE_URL = environment.rondeivu_api;
  readonly FRAGMENT = '/complyadvantage';
  readonly USERS = '/users';
  readonly SEARCHES = '/searches';

  readonly DETAILS = '/details';
  readonly CERTIFICATE = '/certificate';
  readonly MONITORS = '/monitors';
  readonly COMMENTS = '/comments';
  readonly ENTITIES = '/entities';

  private config: Config = initialConfigState;
  private cognito: CognitoUser = initialCognitoUserState;

  constructor(private http: RondeivuHttpClient, private store: Store) {
    this.store.select(getConfig).subscribe(c => {
      this.config = c;
    });
    this.store.select(getCognitoUser).subscribe(cu => {
      this.cognito = cu;
    });
  }

  getUsers(): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.USERS);
  }

  getSearches(): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.SEARCHES);
  }

  getSearchedById(id: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.SEARCHES + '/' + id);
  }

  updateSearchesById(id: string, search: any): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + this.SEARCHES + '/' + id, search);
  }

  deleteSearches(id: string): Observable<any> {
    return this.http.delete(this.BASE_URL + this.FRAGMENT + this.SEARCHES + '/' + id);
  }

  getDetailsBySearchId(id: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.SEARCHES + '/' + id + this.DETAILS);
  }

  getCertificateBySearchId(id: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.SEARCHES + '/' + id + this.CERTIFICATE);
  }

  getMonitorsBySearchId(id: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.SEARCHES + '/' + id + this.MONITORS);
  }

  updateMonitorsBySearchId(id: string, monitor: any): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + this.SEARCHES + '/' + id + this.MONITORS, monitor);
  }

  getCommentsBySearchId(id: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.SEARCHES + '/' + id + this.COMMENTS);
  }

  addCommentBySearchId(id: string, comment: any): Observable<any> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.SEARCHES + '/' + id + this.COMMENTS, comment, {headers: httpHeaders});
  }

  updateEntitiesBySearch(id: string, entity: any): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + this.SEARCHES + '/' + id + this.ENTITIES, entity);
  }
}
