import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Storage} from 'aws-amplify';



@Injectable({
  providedIn: 'root'
})
export class S3Service {

  constructor(private https: HttpClient) {
  }

  // for listing ALL files without prefix, pass ''
  public getFiles(prefix: string, config?:any) {
    return Storage.list(prefix, !!config? config : {});
  }

  /**
   * Main download
   * @param fileKey
   */
  async download(fileKey: any) {
    const result = await Storage.get(fileKey, {download: true});
    this.downloadBlob(result.Body, 'filename');
  }

  /**
   * Blob download
   * @param blob
   * @param filename
   */
  downloadBlob(blob: any, filename: any) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  }

  async downloadLogProgress(filename: string) {
    const result = await Storage.get(filename, {
      download: true,
      progressCallback(progress: any) {
        console.log(`Downloaded: ${progress.loaded}/${progress.total}`);
      }
    });
  }

  /**
   * public: Accessible by all users of your app. Files are stored under the public/ path in your S3 bucket.
   * protected: Readable by all users, but writable only by the creating user. Files are stored under protected/{user_identity_id}/
   * where the user_identity_id corresponds to the unique Amazon Cognito Identity ID for that user.
   * @param filename
   * @param access
   */
  async downloadAsAccess(filename: string, access: string) {
    // Gets the welcome.png belonging to current user
    const result = await Storage.get(filename, {level: 'private'}); // same effect

    // To get current user's objects
    //const result = await Storage.get('filename.txt', {level: 'protected'});

    // To get other users' objects
    // const result = await Storage.get('filename.txt', {
    //   level: 'protected',
    //   identityId: 'xxxxxxx' // the identityId of that user
    // });
  }

  async uploadDocument(filename: string, text: string, level?: string) {
    switch (level) {
      case 'private':
      case 'protected':
        return this.uploadAs(filename, text, level);
      default:
        return this.uploadPublic(filename, text);
    }
  }

  async uploadPublic(filename: string, text: string) {
    return Storage.put(filename, text, {
      progressCallback(progress: any) {
        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
      }
    });
  }

  async uploadAs(filename: string, text: string, level: any) {
    return Storage.put(filename, text, {
      level: level,
      progressCallback(progress: any) {
        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
      },
    });
  }

  async uploadEncrypted(filename: string, text: string) {
    return Storage.put(filename, text, {
      serverSideEncryption: 'AES256',
      SSECustomerAlgorithm: 'string',
      SSECustomerKey: 'string',
      SSECustomerKeyMD5: 'string',
      SSEKMSKeyId: 'string'
    });
  }

}


