import {Injectable, OnDestroy} from '@angular/core';
import {HubConnection, HubConnectionBuilder} from "@microsoft/signalr";
import {environment} from "../../../environments/environment";
import {Observable, Subject, Subscription} from "rxjs";
import {ISignalr} from "../../models";
import {Store} from "@ngrx/store";
import {
  CognitoUser,
  getCognitoUser,
  getSelectedBusinessUnit,
  getSelectedDeal,
  getSelectedMandate
} from "../../modules/redux";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {IDealCard} from "../../modules/shared/models";
import {iMandateResponse} from "../../modules/mandates/models";
import {IBusinessUnit} from "../../modules/business-units/models";

@Injectable({
  providedIn: 'root'
})
export class SignalrService implements OnDestroy {

  private readonly ADD = "AddToGroup";
  private readonly SEND = "SendNotification";

  private hubConnection: any;
  private feed$: Subject<ISignalr> = new Subject<ISignalr>();
  private selectedDeal$: Subscription = new Subscription();
  private selectedDeal: IDealCard = {} as unknown as IDealCard;

  private cognitoUser$: Subscription = new Subscription();
  private cognitoUser: CognitoUser = {} as unknown as CognitoUser;

  private selectedMandate$: Subscription = new Subscription();
  private selectedMandate: iMandateResponse = {} as unknown as iMandateResponse;
  private selectedBusinessUnit$: Subscription = new Subscription();
  private selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;
  private selectedUser$: Subscription = new Subscription();


  constructor(private store: Store, private toastr: ToastrService, private router: Router) {
    //selected deal
    this.selectedDeal$ = this.store.select(getSelectedDeal).subscribe((deal: IDealCard) => {
      this.selectedDeal = deal;
    });
    //selected mandate
    this.selectedMandate$ = this.store.select(getSelectedMandate).subscribe((mandate: iMandateResponse) => {
      this.selectedMandate = mandate;
    });
    //selected business unit
    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((businessUnit: IBusinessUnit) => {
      this.selectedBusinessUnit = businessUnit;
    });
    //the current user
    this.selectedUser$ = this.store.select(getCognitoUser).subscribe((cognito: CognitoUser) => {
    });

    //cognito user
    this.cognitoUser$ = this.store.select(getCognitoUser).subscribe((cognito: CognitoUser) => {
      this.cognitoUser = cognito;
    });

  }

  ngOnDestroy() {
    this.selectedDeal$.unsubscribe();
    this.selectedMandate$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedUser$.unsubscribe();
    this.cognitoUser$.unsubscribe();
  }

  public startConnection(businessUnitId: string) {
    return new Promise((resolve, reject) => {
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(environment.rondeivu_signalr).build();

      this.hubConnection.start({withCredentials: false})
        .then(() => {
          this.hubConnection.invoke(this.ADD, businessUnitId).then(() => {
            console.log("connection established");
            return resolve(true);
          });
        })
        .catch((err: any) => {
          console.log("error occurred" + err);
          reject(err);
        });
    });
  }

  public get AllFeedObservable(): Observable<ISignalr> {
    return this.feed$.asObservable();
  }

  public listenToAllFeeds() {
    (<HubConnection>this.hubConnection).on(this.SEND, (data: ISignalr) => {
      this.feed$.next(data);
    });
  }

  public processFeed(feed: any) {
    try {
      if (!!feed) {
        const s = JSON.parse(feed) as ISignalr;
        // console.log(s);
        if (this.cognitoUser.attributes['sub'] != s.subId) {
          switch (s.object) {
            case "Deal":
              if (this.selectedDeal.id == s.objectId) {
                this.notify(s.object);
              }
              break;
            case "Mandate":
              if (this.selectedMandate.id == s.objectId) {
                this.notify(s.object);
              }
              break;
            case "BusinessUnit":
              if (this.selectedBusinessUnit.businessUnitId == s.objectId) {
                this.notify(s.object);
              }
              break;
            case "User":
              break;
            default:
              break;
          }
        }
      }
    } catch (e: any) {
      console.log("Error processing feed!");
    }
  }

  private notify(type: string) {
    this.toastr.warning("The asset you are viewing has been modified by another user. <br/>" + this.getReloadLink(type), $localize`:@@companyName:Rondeivu`, {
      disableTimeOut: true,
      closeButton: true,
      tapToDismiss: false,
      enableHtml: true
    });
  }

  private getReloadLink(type: string) {
    const r = this.router.url;
    return `<a href='${r}'>Reload ${type}</a>`;
  }

}
