import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-admin-config-modal',
  templateUrl: './admin-config-modal.component.html',
  styleUrls: ['./admin-config-modal.component.scss']
})
export class AdminConfigModalComponent implements OnInit {

  //reactive form
  userForm = new FormGroup({
    key: new FormControl('', Validators.required),
    value: new FormControl('', Validators.required),
    active: new FormControl(true)
  });

  constructor(public dialogRef: MatDialogRef<AdminConfigModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { isEditing: boolean, lookup: any }) {
  }

  ngOnInit(): void {
    this.userForm.patchValue(this.data.lookup);
  }

  submit() {
    this.dialogRef.close(this.userForm.getRawValue());
  }

}
