<div style="height:calc(100vh - 112px); overflow-y: auto;">
  <div class="container-fluid">
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-11">
        <span class="rm-title rm-title-3">User Email Notifications</span><br/>
        <!--        <span class="rm-title rm-title-6 rm-lighter">This is the user's sent email notifications:</span>-->
      </div>
    </div>
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <table mat-table [dataSource]="dataSource" [multiTemplateDataRows]="true" matSort
                     (matSortChange)="sortData($event)">
                <!-- Search Header -->
                <ng-container matColumnDef="table-name">
                  <th colspan="4" mat-header-cell *matHeaderCellDef>
                    <!--                    <span style="font-size: large;">Email Notifications</span>-->
                  </th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="search-header">
                  <th colspan="4" mat-header-cell *matHeaderCellDef>
                    <form>
                      <mat-form-field style="width: 100%;">
                        <mat-label>Search</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" #input>
                        <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                    </form>
                  </th>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="appUser">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="appUser"> User</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    <app-user-card *ngIf="!!element.appUser"
                                   [firstName]="element.appUser['firstName'] "
                                   [lastName]="element.appUser['lastName']"
                                   [displayName]="element.appUser['email']"
                                   [borderColor]="'transparent'"
                                   [sideColor]="'#002150'"
                                   [background]="'#F1EDE4'"
                                   [image]="element.appUser['imageUrl']"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="emailTo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="emailTo"> Email To</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    {{ element.emailTo }}
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="emailFrom">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="emailFrom"> Email From</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    {{ element.emailFrom }}
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="subject">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="subject"> Subject</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    {{ element.subject }}
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="platform">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="platform"> Platform</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    {{ element.platform }}
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="type"> Type</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    {{ element.type }}
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="emailStatus">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="emailStatus"> Email Status</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    {{ element.emailStatus }}
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="statusChangedTime">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="statusChangedTime"> Status Changed Time</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    {{ element.statusChangedTime | date:'medium' }}
                  </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="['table-name','search-header']"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                    class="example-element-row">
                  <!--              (click)="expandedElement = expandedElement === element ? null : element">-->
                </tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter.</td>
                </tr>
              </table>
            </div>
          </div>
          <mat-paginator style="background-color: white; z-index: 99;"
                         [pageSizeOptions]="[100, 250, 500]"
                         [pageSize]="100"
                         aria-label="Select page of users"
          ></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

