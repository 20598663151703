import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {CognitoUser, Config, getCognitoUser, getConfig, initialCognitoUserState, initialConfigState} from "../../redux";
import {RondeivuHttpClient} from "../../../handlers";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class EntityKycService {
  readonly BASE_URL = environment.rondeivu_api;
  private readonly FRAGMENT = '/businessunitentitykyc';
  private readonly ENTITY_FRAGMENT = '/businessunitentity';

  private readonly BY_BUSINESS_UNIT_ID = '/bybusinessunitid';
  private readonly VALIDATE = '/validate';
  private readonly DOWNLOAD = '/download';
  private readonly SUBMIT = '/submit';
  private readonly RONDEIVU_STATUS = '/change-rondeivu-status';

  private readonly IMPORT = '/import';
  private readonly PEOPLE = '/people';
  private readonly LEGAL_ENTITIES = '/legalentities';

  private readonly CLONE = '/clone';
  private readonly ADMIN_SETTINGS = '/admin-settings';

  private config: Config = initialConfigState;
  private cognito: CognitoUser = initialCognitoUserState;

  constructor(private http: RondeivuHttpClient, private store: Store) {
    this.store.select(getConfig).subscribe(c => {
      this.config = c;
    });
    this.store.select(getCognitoUser).subscribe(cu => {
      this.cognito = cu;
    });
  }

  getEntityKyc(): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT);
  }

  getEntityKycByBusinessUnitId(businessUnitId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.BY_BUSINESS_UNIT_ID + '/' + businessUnitId);
  }


  getEntityKycById(kycId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + '/' + kycId);
  }

  getKycByEntityId(entityId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.ENTITY_FRAGMENT + '/' + entityId);
  }

  addEntityKyc(entityKyc: any): Observable<any> {
    return this.http.post(this.BASE_URL + this.FRAGMENT + '/' + entityKyc.entityId, entityKyc);
  }

  updateEntityKyc(entityKyc: any): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + '/' + entityKyc.id, entityKyc);
  }

  deleteEntityKyc(entityKycId: string): Observable<any> {
    return this.http.delete(this.BASE_URL + this.FRAGMENT + '/' + entityKycId);
  }

  validate(entityKycId: string, section: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.VALIDATE + '/' + entityKycId + '/' + section);
  }

  updateEntityKycStatus(kycId: string, payload: {
    kycId: string,
    kycRondeivuStatus: string
  }): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + this.RONDEIVU_STATUS + '/' + kycId, payload);
  }

  submitEntityKyc(entityKyc: any): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + this.SUBMIT + '/' + entityKyc.id, entityKyc);
  }

  downloadKyc(kycId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.DOWNLOAD + '/' + kycId, {responseType: 'blob'});
  }

  importPeople(entityKycId: string, ids: string[]): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + this.IMPORT + this.PEOPLE + '/' + entityKycId, ids);
  }

  importLegalEntities(entityKycId: string, ids: string[]): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + this.IMPORT + this.LEGAL_ENTITIES + '/' + entityKycId, ids);
  }

  updateEntityKycAdminSettings(adminSettings: any): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + this.ADMIN_SETTINGS + '/' + adminSettings.kycId, adminSettings);
  }

  cloneEntityKyc(entityKyc: any): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + this.CLONE + '/' + entityKyc.businessUnitEntityKycId, entityKyc);
  }

}
