import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ApprovalsRoutingModule} from './approvals-routing.module';
import {ApprovalsService} from "./services/approvals/approvals.service";
import {
  ApprovalsNavigationComponent,
  InvestorApprovalsComponent,
  IssuerApprovalsComponent
} from './components/';
import {FormsModule} from "@angular/forms";
import {NgMaterialModule} from "../ng-material/ng-material.module";
import {SharedModule} from "../shared/shared.module";


@NgModule({
  declarations: [
    ApprovalsNavigationComponent,
    InvestorApprovalsComponent,
    IssuerApprovalsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ApprovalsRoutingModule,
    NgMaterialModule,
    SharedModule
  ],
  providers: [ApprovalsService],
})
export class ApprovalsModule {
}
