import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-business-unit-card',
  templateUrl: './business-unit-card.component.html',
  styleUrls: ['./business-unit-card.component.scss']
})
export class BusinessUnitCardComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
