import {Injectable} from '@angular/core';
import {Config, getConfig, initialConfigState} from "../../redux";
import {environment} from "../../../../environments/environment";
import {Store} from "@ngrx/store";
import {RondeivuHttpClient} from "../../../handlers";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CloudwatchService {
  private config: Config = initialConfigState;
  private readonly BASE_URL = environment.rondeivu_api;
  private readonly FRAGMENT = '/cloudwatch';
  private readonly SNS_LOG = '/sns-log';
  private readonly SES_DELIVERY_LOG = '/ses-delivery-log';
  private readonly SES_BOUNCE_LOG = '/ses-bounce-log';
  private readonly SES_COMPLAINT_LOG = '/ses-complaint-log';
// user email notifications
  private readonly USERS_FRAGMENT = '/users';
  private readonly EMAIL_NOTIFICATIONS = '/email-notification';
  constructor(private store: Store, private http: RondeivuHttpClient) {
    this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
  }

  getSnsLog(): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.SNS_LOG);
  }

  getSnsDeliveryLog(): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.SES_DELIVERY_LOG);
  }

  getSnsBounceLog(): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.SES_BOUNCE_LOG);
  }

  getSnsComplaintLog(): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.SES_COMPLAINT_LOG);
  }

  getUserEmailNotifications(): Observable<any> {
    return this.http.get(this.BASE_URL + this.USERS_FRAGMENT + this.EMAIL_NOTIFICATIONS);
  }
}
