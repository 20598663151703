//AppUserWorkflowStatus
import {createAction, props} from "@ngrx/store";
import {ILookup, ILookups, LookupType} from "../../../../models";


//lookups
export const loadLookups = '[Load Lookups] Lookups';
export const fetchLookups = '[Fetch Lookups] Lookups';
export const loadedLookups = '[Loaded Lookups] Lookups';

export const loadLookup = createAction(
  '[Load Lookup] Lookups',
  props<{ lookup: LookupType }>()
);

export const updateLookups = createAction(
  '[Update Lookups] Lookups',
  props<{ lookups: ILookups }>()
);

export const updateBusinessUnitProfileTypes = createAction(
  '[Update BusinessUnitProfileTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateBusinessUnitWorkflowStatus = createAction(
  '[Update BusinessUnitWorkflowStatus] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateAreasOfFocus = createAction(
  '[Update AreasOfFocus] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateAssetTypes = createAction(
  '[Update AssetTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateCountries = createAction(
  '[Update Countries] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateCountryCodes = createAction(
  '[Update Country Codes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateDealNdaRequirementTypes = createAction(
  '[Update DealNdaRequirementTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateDealTypes = createAction(
  '[Update DealTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateDealWorkflowStatus = createAction(
  '[Update DealWorkflowStatus] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateInvestorDescriptionTypes = createAction(
  '[Update InvestorDescriptionTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateInvestorTypes = createAction(
  '[Update InvestorTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateProvinceTypes = createAction(
  '[Update ProvinceTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateStateTypes = createAction(
  '[Update StateTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateTimelineTypes = createAction(
  '[Update TimelineTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateInvestorMandateWorkflowStatus = createAction(
  '[Update InvestorMandateWorkflowStatus] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateIssuerTypes = createAction(
  '[Update IssuerTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateDealCloseTypes = createAction(
  '[Update DealCloseTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateSecDealTypes = createAction(
  '[Update SecDealTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateCurrencyTypes = createAction(
  '[Update CurrencyTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateDealInterestTypes = createAction(
  '[Update DealInterestTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateUserIntakeSourceTypes = createAction(
  '[Update UserIntakeSourceTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateIssuerEngagementAgreementStatusTypes = createAction(
  '[Update EngagementAgreementStatusTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateIssuerNDAStatusTypes = createAction(
  '[Update IssuerNDAStatusTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateKYCStatusTypes = createAction(
  '[Update KYCStatusTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateDealNotesAccessTypes = createAction(
  '[Update DealNotesAccessTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateTeamUserRoleTypes = createAction(
  '[Update TeamUserRoleTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateMemberInviteStatus = createAction(
  '[Update MemberInviteStatus] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateDealAlertTypes = createAction(
  '[Update DealAlertTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateViewAccessControls = createAction(
  '[Update ViewAccessControls] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateDataroomTypes = createAction(
  '[Update DataroomTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateIndicationOfInterestRestrictionTypes = createAction(
  '[Update IndicationOfInterestRestrictionTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateInvestorDealWorkflowStatus = createAction(
  '[Update InvestorDealWorkflowStatus] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateNoteTypes = createAction(
  '[Update NoteTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateSyndicationRestrictionTypes = createAction(
  '[Update SyndicationRestrictionTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateSyndicationStatus = createAction(
  '[Update SyndicationStatus] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateSubscriptionRestrictionTypes = createAction(
  '[Update SubscriptionRestrictionTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateSubscriptionStatus = createAction(
  '[Update SubscriptionStatus] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateRondeivuEmployeeRoleTypes = createAction(
  '[Update RondeivuEmployeeRoleTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateNdaTypes = createAction(
  '[Update NdaTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateEnvelopeStatus = createAction(
  '[Update EnvelopeStatus] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateKycInvestorTypes = createAction(
  '[Update KycInvestorTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateKycIssuerTypes = createAction(
  '[Update KycIssuerTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateAnchorInterestTypes = createAction(
  '[Update anchorInterestTypes] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateIssuerLegalEntityType = createAction(
  '[Update issuerLegalEntityType] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateTaxIdType = createAction(
  '[Update taxIdType] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateLegalEntityProofofDocumentType = createAction(
  '[Update legalEntityProofofDocumentType] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateEntityTabDocumentationType = createAction(
  '[Update entityTabDocumentationType] Lookups',
  props<{ items: ILookup[] }>()
);
export const updatePersonGovernmentIssuedIdType = createAction(
  '[Update personGovernmentIssuedIdType] Lookups',
  props<{ items: ILookup[] }>()
);
export const updatePersonProofofIdentificationIdType = createAction(
  '[Update personProofofIdentificationIdType] Lookups',
  props<{ items: ILookup[] }>()
);
export const updatePersonProofofAddressDocumentType = createAction(
  '[Update personProofofAddressDocumentType] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateEntityTaxDocumentationType = createAction(
  '[Update entityTaxDocumentationType] Lookups',
  props<{ items: ILookup[] }>()
);
export const updatePersonProofofIdentificationType = createAction(
  '[Update personProofofIdentificationType] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateKycPersonRondeivuStatus = createAction(
  '[Update kycPersonRondeivuStatus] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateKycLegalEntityRondeivuStatus = createAction(
  '[Update kycLegalEntityRondeivuStatus] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateKycType = createAction(
  '[Update kycType] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateKycRondeivuStatus = createAction(
  '[Update kycRondeivuStatus] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateKycPersonType = createAction(
  '[Update kycPersonType] Lookups',
  props<{ items: ILookup[] }>()
);
export const updateKycLegalEntityType = createAction(
  '[Update kycLegalEntityType] Lookups',
  props<{ items: ILookup[] }>()
);

export const updateBusinessUnitEntityType = createAction(
  '[Update businessUnitEntityType] Lookups',
  props<{ items: ILookup[] }>()
);

export const updateSubDocStatus = createAction(
  '[Update subDocStatus] Lookups',
  props<{ items: ILookup[] }>()
);

