import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {KycRoutingModule} from "./kyc-routing.module";
import {HttpClientModule} from "@angular/common/http";
import {NgMaterialModule} from "../ng-material/ng-material.module";
import {SharedModule} from "../shared/shared.module";
import {
  BusinessUnitKycListComponent,
  KycDocumentsComponent,
  KycInformationComponent,
  KycListComponent,
  KycNavigationComponent
} from './components';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";



@NgModule({
  declarations: [
    KycNavigationComponent,
    KycListComponent,
    KycInformationComponent,
    KycDocumentsComponent,
    BusinessUnitKycListComponent,
  ],
  imports: [
    CommonModule,
    KycRoutingModule,
    HttpClientModule,
    NgMaterialModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class KycModule { }
