import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  CognitoUser,
  Config, getCognitoUser,
  getConfig,
  getLookups, getSelectedBusinessUnit,
  initialCognitoUserState,
  initialConfigState,
  initialLookupsState, Lookups, toggleLoading
} from "../../../redux";
import {Subscription} from "rxjs";
import {IThirdPartyDetermination} from "../../../../models";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {FormControl, FormGroup} from "@angular/forms";
import {Store} from "@ngrx/store";
import {AppConfigService, UuidService} from "../../../../services";
import {BusinessUnitService} from "../../services";
import {ToastrService} from "ngx-toastr";
import {IBusinessUnit} from "../../models";
import {DeleteConfirmationModalComponent} from "../../../shared/components";
import {ModalSize, UtilService} from "../../../shared/services/util/util.service";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-business-unit-third-party-determination',
  templateUrl: './business-unit-third-party-determination.component.html',
  styleUrls: ['./business-unit-third-party-determination.component.scss']
})
export class BusinessUnitThirdPartyDeterminationComponent implements OnInit, OnDestroy {

  lookups = initialLookupsState;
  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  config: Config = initialConfigState;
  cognito$: Subscription = new Subscription();
  cognitoUser = initialCognitoUserState;

  thirdParty: IThirdPartyDetermination = {} as unknown as IThirdPartyDetermination;
  link: SafeResourceUrl | null = null;

  showTPD = false;

  //reactive form
  form = new FormGroup({
    id: new FormControl(),
    businessUnitId: new FormControl(),
    isThisPartyRegisteredOnBehalf: new FormControl(),
    isAthirdPartyDirecting: new FormControl(),
    willAthirdPartyHasAfinancialInterest: new FormControl(),
    personFirstName: new FormControl(),
    personMiddleName: new FormControl(),
    personLastName: new FormControl(),
    personSuffix: new FormControl(),
    businessStreetAddress: new FormControl(),
    businessCity: new FormControl(),
    businessStateOrProvince: new FormControl(),
    businessCountryId: new FormControl(),
    businessZipPostalCode: new FormControl(),
    businessLegalDomicile: new FormControl(),
    isRegistered: new FormControl(), //boolean
    isOwnerofVotingRights: new FormControl(), //boolean
    isPubliclyTraded: new FormControl(), //boolean
    regulatoryPeople: new FormControl(),
    // "regulatoryPeople": [ { "id": "89a43aeb-bca8-b50a-911e-1250e9842dad", "ownerOfVotingRight": "test", "publicilyTradedCompany": "TEST", "ownerShip": 1 } ]
    // "regulatoryEntities": [ { "id": "96c7ae69-ec6e-46be-e60f-892f44e11ca3", "authorizedSignatory": "TEST", "companyName": "TEST", "ownerShip": 99 } ] }
    regulatoryEntities: new FormControl()
  });

  protected readonly FORM_REG_PEOPLE = 'regulatoryPeople';
  protected readonly FORM_REG_ENT = 'regulatoryEntities';

  constructor(private store: Store,
              private appConfig: AppConfigService,
              private domSanitizer: DomSanitizer,
              private businessUnitService: BusinessUnitService,
              private toastr: ToastrService,
              public util: UtilService,
              private uuid: UuidService,
              private dialog: MatDialog
  ) {
    this.config$ = this.store.select(getConfig).subscribe((configState: Config) => {
      this.config = configState;
    });

    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });

    this.cognito$ = this.store.select(getCognitoUser).subscribe((cognito: CognitoUser) => {
      this.cognitoUser = cognito;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((selected: IBusinessUnit) => {
      if (!!selected.businessUnitId) {
        this.getThirdParty();
      }
    });
  }

  chooseOption() {
    if (this.isOneOrMoreSelected()) {
      this.setFormControlsEnabled();
    } else {
      this.setFormControlsDisabled();
      this.clearFormControls();
    }
  }

  isOneOrMoreSelected() {
    const Q1 = this.form.get('isThisPartyRegisteredOnBehalf')?.value || false;
    const Q2 = this.form.get('isAthirdPartyDirecting')?.value || false;
    const Q3 = this.form.get('willAthirdPartyHasAfinancialInterest')?.value || false;
    return Q1 || Q2 || Q3;
  }


  private setFormControlsDisabled() {
    this.form.get('personFirstName')?.disable();
    this.form.get('personMiddleName')?.disable();
    this.form.get('personLastName')?.disable();
    this.form.get('personSuffix')?.disable();
    this.form.get('businessStreetAddress')?.disable();
    this.form.get('businessCity')?.disable();
    this.form.get('businessStateOrProvince')?.disable();
    this.form.get('businessCountryId')?.disable();
    this.form.get('businessZipPostalCode')?.disable();
    this.form.get('businessLegalDomicile')?.disable();
    this.showTPD = false;
  }

  private clearFormControls() {
    this.form.get('personFirstName')?.setValue('');
    this.form.get('personMiddleName')?.setValue('');
    this.form.get('personLastName')?.setValue('');
    this.form.get('personSuffix')?.setValue('');
    this.form.get('businessStreetAddress')?.setValue('');
    this.form.get('businessCity')?.setValue('');
    this.form.get('businessStateOrProvince')?.setValue('');
    this.form.get('businessCountryId')?.setValue('');
    this.form.get('businessZipPostalCode')?.setValue('');
    this.form.get('businessLegalDomicile')?.setValue('');
    this.showTPD = false;
  }

  private setFormControlsEnabled() {
    this.form.get('personFirstName')?.enable();
    this.form.get('personMiddleName')?.enable();
    this.form.get('personLastName')?.enable();
    this.form.get('personSuffix')?.enable();
    this.form.get('businessStreetAddress')?.enable();
    this.form.get('businessCity')?.enable();
    this.form.get('businessStateOrProvince')?.enable();
    this.form.get('businessCountryId')?.enable();
    this.form.get('businessZipPostalCode')?.enable();
    this.form.get('businessLegalDomicile')?.enable();
    this.showTPD = true;
  }

  showNaturalPersonsTable(): boolean {
    return this.form.get('isOwnerofVotingRights')?.value || false;
  }

  showLegalEntitiesTable(): boolean {
    return this.form.get('isPubliclyTraded')?.value || false;
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.cognito$.unsubscribe();
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
  }

  getThirdParty() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.businessUnitService.getUserBusinessUnitThirdParty().subscribe({
      next: res => {
        this.thirdParty = res;
        this.form.patchValue(this.thirdParty);
        this.chooseOption();
        this.store.dispatch(toggleLoading({loading: false}));
        // this.toastr.success("Third Party Determination Loaded!", $localize`:@@companyName:Rondeivu`);
      }, error: error => {
        this.toastr.error("Unable to get third party determination!", $localize`:@@companyName:Rondeivu`);
        this.store.dispatch(toggleLoading({loading: false}));
      }
    });
  }

  submit() {
    if (this.form.valid) {
      this.store.dispatch(toggleLoading({loading: true}));
      let payload = this.form.getRawValue() as unknown as IThirdPartyDetermination;
      this.businessUnitService.editUserBusinessUnitThirdParty(payload).subscribe({
        next: res => {
          this.store.dispatch(toggleLoading({loading: false}));
          this.toastr.success("Third Party Determination Saved!", $localize`:@@companyName:Rondeivu`);
        }, error: error => {
          this.toastr.error("Unable to update third party determination!", $localize`:@@companyName:Rondeivu`);
          this.store.dispatch(toggleLoading({loading: false}));
        }
      });
    }
  }

  addNewObjectToCollection(collection: string) {
    let objs = this.form.get(collection)?.value || [];
    objs.push({id: this.uuid.generateUUID()});
    this.form.get(collection)?.setValue(objs);
  }

  deleteFormArray(control: string, idx: number) {
    const delRef = this.dialog.open(DeleteConfirmationModalComponent, {
      height: 'auto',
      maxWidth: this.util.getModalWidth(ModalSize.SMALL),
      disableClose: true,
      data: {
        index: idx
      }
    });

    delRef.afterClosed().subscribe(result => {
      if (!!result) {
        let arr = this.form.get(control)?.value;
        arr.splice(result.index, 1);
        this.form.get(control)?.setValue(arr);
      }
    });
  }

}

