<div class="main-dash">
  <!--add button-->
  <div class="row justify-content-center header">
    <div class="col-12">
      <div style="display: flex; justify-content: space-between; padding: 5px 35px;">
        <span class="rm-title rm-title-3" style="line-height: 36px;">Closed Deals</span><br/>
        <!--deal modal-->
        <button
          *ngIf="config.selected_business_unit?.businessUnitType == 'ISSUER'"
          mat-raised-button
          color="primary"
          (click)="openDealModal()"
          [disabled]="!util.hasPermission(util.Permission.CAN_ADD_DEALS)"
          [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_DEALS) ? util.NoAccessCursor: ''}"
          [matTooltip]="!util.hasPermission(util.Permission.CAN_ADD_DEALS) ? util.NoAccessMsg : ''"
          matTooltipPosition="left"
        >
          <mat-icon>library_add
          </mat-icon>&nbsp;{{'NEW DEAL'}}
        </button>
        <!--mandate modal-->
        <button
          *ngIf="config.selected_business_unit?.businessUnitType == 'INVESTOR'"
          mat-raised-button
          color="primary"
          (click)="openMandateModal()"
          [disabled]="!util.hasPermission(util.Permission.CAN_ADD_MANDATES)"
          [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_MANDATES) ? util.NoAccessCursor: ''}"
          [matTooltip]="!util.hasPermission(util.Permission.CAN_ADD_MANDATES) ? util.NoAccessMsg : ''"
          matTooltipPosition="left"
        >
          <mat-icon>library_add
          </mat-icon>&nbsp;{{'NEW MANDATE'}}
        </button>
      </div>
    </div>
  </div>


  <div class="row" *ngIf="deals.length > 0">
    <div id="deal-body">
      <!--      deal cards-->
      <div class="row" *ngIf="deals.length > 0">
        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4 deal-row" *ngFor="let d of deals;index as i;">
          <app-deal-card class="deal-card" [deal]="deals[i]"
                         [canView]="util.hasPermission(util.Permission.CAN_ACCESS_DEALS) || false"></app-deal-card>
        </div>
      </div>
      <!--deal cards end-->
    </div>
  </div>

  <!--  no live deals start-->
  <div class="row justify-content-center" *ngIf="deals.length==0" style="margin: 50px;">
    <img alt="No Live Deals" src="assets/images/plane.png"
         style="height: calc(100vh - 64px); width: 50vw; object-fit: contain;opacity: 0.2;"
         *ngIf="util.isType('issuer')"/>
    <img alt="No Live Deals" src="assets/images/plane.png"
         style="height: calc(100vh - 64px); width: 50vw; object-fit: contain;opacity: 0.2;"
         *ngIf="util.isType('investor')"/>
  </div>
</div>

