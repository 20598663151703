import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {RondeivuHttpClient} from "../../handlers";
import {NoteTypes} from "../../models";
import {HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  private readonly BASE_URL = environment.rondeivu_api;
  private readonly FRAGMENT = '/notes';

  constructor(private httpClient: RondeivuHttpClient) {
  }

  /**
   * Get the note collections for the desired object
   * @param type the type of the object
   * @param objectId the object id
   */
  getNoteCollections(type: NoteTypes, objectId: string): Observable<any> {
    return this.httpClient.get(this.BASE_URL + this.FRAGMENT + '/' + type.toString() + '/' + objectId);
  }


  /**
   * Get the note collection by id
   * @param collectionId the collection id
   */
  getNoteCollection(collectionId: string): Observable<any> {
    return this.httpClient.get(this.BASE_URL + this.FRAGMENT + '/' + collectionId);
  }

  /**
   * Add a note to a note collection
   * @param collectionId the object id
   * @param note the note
   */
  addNote(collectionId: string, note: string): Observable<any> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const payload = {
      note: note
    };
    return this.httpClient.post(this.BASE_URL + this.FRAGMENT + '/' + collectionId, payload, {headers: httpHeaders});
  }

  /**
   * Update a note
   * @param noteId the note id
   * @param note the note
   */
  updateNote(noteId: string, note: string): Observable<any> {
    const payload = {
      note: note
    };
    return this.httpClient.put(this.BASE_URL + this.FRAGMENT + '/' + noteId, payload);
  }

  /**
   * Delete a note
   * @param noteId the note id
   */
  deleteNote(noteId: string): Observable<any> {
    return this.httpClient.delete(this.BASE_URL + this.FRAGMENT + '/' + noteId);
  }
}
