import {Component, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {LoadingService} from "../../../services";
import {IBusinessUnit} from "../../../modules/business-units/models";
import {
  updateCognitoUser
} from "../../../modules/redux";
import {environment} from "../../../../environments/environment";
import {BusinessUnitService} from "../../../modules/business-units/services";
import {HttpErrorResponse} from "@angular/common/http";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UtilService} from "../../../modules/shared/services";
import {Auth} from "aws-amplify";

enum LoginPage {
  'LOGIN' = 0,
  'EMAIL_VERIFY' = 1,
  'PHONE_VERIFY' = 2,
  'NEW_PASSWORD' = 3
}

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss']
})
export class AuthLoginComponent implements OnInit {
  readonly TIMEOUT = 3000;
  readonly FORGOT_PASS_PATH = '/auth/reset';
  readonly REGISTER_PATH = '/auth/register';

  readonly REDIRECT_PATH = '/deals';
  readonly ADMIN_REDIRECT_PATH = '/dashboard';

  readonly SMS_CODE_LENGTH = 6;

  curPage = LoginPage.LOGIN;

  allowRemember = false;
  loading = false;
  cognitoUser: any;
  userPhone = '';
  smsCode: number[] = [];
  showPassword = false;

  bus: any[] = [];

  userForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.pattern("^(?=.*[`!@#$%^&*()_+\\-=\\[\\]{};':\\\\|,.<>\\/?~])(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$")]),
    remember: new FormControl(false)
  });

  newPasswordForm = new FormGroup({
    newPassword: new FormControl('', [Validators.required, Validators.pattern("^(?=.*[`!@#$%^&*()_+\\-=\\[\\]{};':\\\\|,.<>\\/?~])(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$")])
  });

  constructor(private store: Store,
              private router: Router,
              private toastrService: ToastrService,
              public loader: LoadingService,
              private businessUnitService: BusinessUnitService,
              private util: UtilService,
  ) {

  }

  redirect() {
    this.loader.start();
    this.businessUnitService.getUserBusinessUnits().subscribe({
      next: (bus: IBusinessUnit[]) => {
        if (bus.length == 0) {
          this.util.logOut().then(() => {
            this.curPage = LoginPage.LOGIN;
            this.loading = false;
            this.loader.stop();
            this.toastrService.warning("You don't have an assigned business unit! Please contact Rondeivu to at " + environment.rondeivu_email, $localize`:@@companyName:Rondeivu`);
          });
        } else {
          if (this.util.canRedirect()) {
            let path;
            if (!!this.util.getRedirectPath() && (this.util.getRedirectPath() != '')) {
              // override with redirect path set by auth guard if session or business unit errors
              path = this.util.getRedirectPath();
            } else {
              // test for special characters
              const specials = /[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g;
              if (!specials.test(bus[0].slug)) {
                // default path of first business unit
                const fragment = bus[0].businessUnitType?.toLowerCase() === 'admin' ? this.ADMIN_REDIRECT_PATH : this.REDIRECT_PATH;
                path = '/' + bus[0].slug + fragment;
              } else {
                this.util.logOut().then(() => {
                  this.curPage = LoginPage.LOGIN;
                  this.loading = false;
                  this.loader.stop();
                  this.toastrService.warning("Invalid Business Unit Slug!", $localize`:@@companyName:Rondeivu`);
                });
              }
            }
            console.log("Redirecting to: " + path);
            this.router.navigate([path]).then(() => {
              this.loader.stop();
              this.util.incrementRedirectRetries();
            });
          } else {
            this.toastrService.error("Redirect Limit Exceeded!", $localize`:@@companyName:Rondeivu`);
            this.curPage = LoginPage.LOGIN;
            this.loading = false;
            this.loader.stop();
          }
        }
      }, error: (err: HttpErrorResponse) => {
        console.log(err);
        this.loader.stop();
        this.toastrService.error("Unable to retrieve user business units!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  ngOnInit() {
    Auth.currentUserInfo().then((cognito: any) => {
      if (!!cognito) {
        Auth.currentSession().then((session: any) => {
          if (session.isValid()) {
            this.redirect();
          }
        });
      }
    });
  }

  /**
   * Sign the user into user pool
   */
  public async signInEmailAndPass() {
    this.loader.start();
    const e = this.userForm.get('email')?.value?.toLowerCase() || '';
    const p = this.userForm.get('password')?.value || '';
    Auth.signIn(e, p)
      .then((cognitoUser: any) => {
        this.cognitoUser = cognitoUser;
        // console.log(this.cognitoUser);

        if (!!this.cognitoUser) {
          if (!!this.cognitoUser.challengeName) {
            switch (this.cognitoUser.challengeName) {
              case 'NEW_PASSWORD_REQUIRED':
                this.curPage = LoginPage.NEW_PASSWORD; //redirect user to new password view
                this.toastrService.info('New Password Required');
                this.loader.stop();
                break;
              case 'SMS_MFA':
                this.smsCode = [];
                this.userPhone = this.cognitoUser.challengeParam['CODE_DELIVERY_DESTINATION'];
                this.curPage = LoginPage.PHONE_VERIFY; //redirect user to SMS code view
                this.loader.stop();
                break;
              case 'PASSWORD_VERIFIER':
                this.loader.stop(); //redirect to forgot password path
                this.router.navigate([this.FORGOT_PASS_PATH], {queryParams: {email: e}});
                break;
              default:
                break;
            }
          } else {
            // No MFA Challenge
            Auth.currentAuthenticatedUser().then((user: any) => {
              if (!!user) {
                //get the session
                Auth.currentSession().then((session: any) => {
                  if (!!session && session.isValid()) {
                    //fetch user
                    this.fetchAndDispatchCognitoUser();
                    //navigate to app
                    this.redirect();
                  }
                });
              }
            }).catch((err: any) => {
              // There is no logged in user because their MFA settings have changed
              // or their phone number has been unverified, so send them to MFA code input
              this.loader.stop();
              console.log("Failed to authenticate: " + err);
              this.smsCode = [];
              this.userPhone = this.cognitoUser.challengeParam['CODE_DELIVERY_DESTINATION'];
              this.curPage = LoginPage.PHONE_VERIFY;
            });
          }
        }
      }).catch((err: any) => {
      console.log(err);
      switch (err.code) {
        case 'PasswordResetRequiredException':
          this.loader.stop();
          this.toastrService.info('Password Reset Required');
          this.router.navigate([this.FORGOT_PASS_PATH], {queryParams: {email: e}});
          break;
        case 'UserNotConfirmedException':
          this.loader.stop();
          this.toastrService.info('Email Verification Required');
          this.curPage = LoginPage.EMAIL_VERIFY;
          break;
        case 'NotAuthorizedException':
        case 'UserNotFoundException':
        default:
          this.loader.stop();
          this.toastrService.error('Not Authorized', $localize`:@@companyName:Rondeivu`);
      }
    });
  }


  /**
   * New password submit
   */
  public setNewPassword() {
    const newPass = this.newPasswordForm.get('newPassword')?.value;
    if (!!newPass) {
      Auth.completeNewPassword(this.cognitoUser, newPass).then((user: any) => {
        // at this time the user is logged in
        //fetch user
        this.fetchAndDispatchCognitoUser();
        //navigate to app
        this.redirect();
      }).catch((e: any) => {
        console.log(e);
      });
    }
  }

  /**
   * Confirm the user MFA code
   */
  public confirmMFACode(code: string) {
    this.loader.start();

    Auth.confirmSignIn(
      this.cognitoUser, // Return object from Auth.signIn()
      code, // Confirmation code
      this.cognitoUser.challengeName // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    ).then((loggedUser: any) => {
      console.log("MFA Verified!");
      Auth.currentUserInfo()
        .then((user: any) => {
          //email has been manually unverified in cognito
          //this is an illegal state and causes a NotAuthorizedException
          if (!!user.attributes && user.attributes['email_verified'] === false) {
            this.toastrService.error('Your email is not verified. Please contact your administrator.', $localize`:@@companyName:Rondeivu`);
            this.curPage = LoginPage.LOGIN;
          }
          //fetch user
          this.store.dispatch(updateCognitoUser({cognito: user}));
          //navigate to app
          this.redirect();
        });
    }).catch((err: any) => {
      switch (err.code) {
        case 'UserNotConfirmedException':
        case 'PasswordResetRequiredException':
        case 'NotAuthorizedException':
        case 'UserNotFoundException':
        case 'CodeMismatchException':

        default:
          this.loader.stop();
          this.toastrService.error('Not Authorized', $localize`:@@companyName:Rondeivu`);
          console.log(err);
      }
    });
  }

  /**
   * Request SMS code by signing user in forcing MFA
   */
  public requestSmsCode(): void {
    let e = this.userForm.get('email')?.value?.toLowerCase() || '';
    let p = this.userForm.get('password')?.value || '';
    Auth.signOut().then(() => {
      Auth.signIn(e, p).then((cognitoUser: any) => {
        this.cognitoUser = cognitoUser;
        // console.log(this.cognitoUser);
        this.toastrService.success('SMS Sent', $localize`:@@companyName:Rondeivu`);
      });
    })
  }

  /**
   * Request a new email verification code
   */
  public requestEmailCode(): void {
    this.loading = true;
    let e = this.userForm.get('email')?.value?.toLowerCase() || '';
    Auth.resendSignUp(e)
      .then(() => {
        this.loading = false;
        this.toastrService.success('Email Verification Code Sent', $localize`:@@companyName:Rondeivu`);
      }).catch((err: any) => {
      this.toastrService.error(err.message, $localize`:@@companyName:Rondeivu`);
      this.loading = false;
    });
  }

  /**
   * Verify the user email
   */
  public confirmEmailSignUp(code: string): void {
    this.loading = true;
    let e = this.userForm.get('email')?.value?.toLowerCase() || '';
    Auth.confirmSignUp(e, code)
      .then((cognitoUser: any) => {
        console.log("Email Confirmed!");
        this.curPage = LoginPage.EMAIL_VERIFY;
        // this.fetchAndDispatchCognitoUser();
        this.signInEmailAndPass();
      }).catch((err: any) => {
      //email confirmation code is not verified
      console.log(err);
      this.toastrService.error(err.code);
      this.loading = false;
    });
  }

  /**
   * Use the form toggle to call remember me after signin
   * WARNING: Forgetting device causes the refreshToken to not be able to renew the authToken
   * @private
   */
  private toggleRememberMe() {
    if (this.userForm.get('remember')?.value) {
      console.log("Remember Device...");
      return Auth.rememberDevice();
    } else {
      console.log("Forget Device...");
      return Auth.forgetDevice();
    }
  }

  private fetchAndDispatchCognitoUser() {
    Auth.currentUserInfo()
      .then((user: any) => {
        this.store.dispatch(updateCognitoUser({cognito: user}));
      });
  }

  public pasteError(errPrefix: string, event: string) {
    this.toastrService.error(errPrefix + event);
  }

}
