import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ThemePalette} from "@angular/material/core";
import {
  Config,
  getConfig,
  getSelectedBusinessUnit,
  initialConfigState,
  initialThemeState,
  Theme
} from "../../../redux";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {IBusinessUnit} from "../../../business-units/models";
import {INavItem} from "../../../../models";
import {toggleNavigating} from "../../../redux/stores/config/config.actions";
import {AppFade} from "../../../../animations/animations";

@Component({
  selector: 'app-settings-navigation',
  templateUrl: './settings-navigation.component.html',
  styleUrls: ['./settings-navigation.component.scss'],
  animations: [AppFade()]
})
export class SettingsNavigationComponent implements OnInit, OnDestroy {
  route$: Subscription = new Subscription();
  config$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  config: Config = initialConfigState;
  theme: Theme = initialThemeState;

  animationState = false;

  links: INavItem[] = [];
  activeLink: INavItem = {} as unknown as INavItem;
  background: ThemePalette = undefined;

  userId: string | null = '';
  selectedUser: any = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store
  ) {
    this.route$ = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.store.dispatch(toggleNavigating({navigating: true}));
        setTimeout(() => {
          this.store.dispatch(toggleNavigating({navigating: false}));
        }, 1000);
      }
    });

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      // modify the user settings if the user is not an employee of the business unit
      if (!!bu) {
        this.links = [
          {title: 'User Settings', link: '/user', icon: '', enabled: true, hidden: false, toolTip: ''},
          {title: 'Invitations', link: '/invitations', icon: '', enabled: true, hidden: false, toolTip: ''}
        ];
        if (bu.isEmployeeOfEntity) {
          this.links.push(
            {title: 'Company Settings', link: '/employees', icon: '', enabled: true, hidden: true, toolTip: ''},
            {title: 'Company Data', link: '/data', icon: '', enabled: true, hidden: true, toolTip: ''}
          );
        }
        this.setActiveLink();
      }
    });
  }

  ngOnInit(): void {
    this.animationState = true;
    this.setActiveLink();
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.route$.unsubscribe();
  }

  navigate(obj: { title: string; link: string; enabled: boolean }) {
    this.store.dispatch(toggleNavigating({navigating: true}));
    this.animationState = false;
    setTimeout(() => {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/settings' + obj.link]).then(() => {
        this.animationState = true;
        this.store.dispatch(toggleNavigating({navigating: false}));
      });
    }, 500);
  }

  goBack() {
    this.store.dispatch(toggleNavigating({navigating: true}));
    this.animationState = false;
    setTimeout(() => {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/settings']).then(() => {
        this.animationState = true;
        this.store.dispatch(toggleNavigating({navigating: false}));
      });
    }, 300);
  }

  /**
   * Sets the current deal tab link from the url fragment
   */
  setActiveLink() {
    const fragments = this.router.url.split('/');
    const link = '/' + fragments[fragments.length - 1];
    this.links.forEach(l => {
      if (l.link == link) {
        this.activeLink = l;
      }
    });
  }
}
