<div style="height:calc(100vh - 64px); overflow-y: auto;">
  <div class="container-fluid">
    <br/><br/>
    <div class="row justify-content-center">
      <div class="col-6 col-md-5">
        <div style="display: flex;">
          <mat-icon color="primary" class="rm-header-icon">fact_check</mat-icon>&nbsp;
          <span class="rm-title rm-title-3">Mandates</span>
        </div>
      </div>
      <div class="col-6 text-end">
        <button
          *ngIf="config.selected_business_unit.businessUnitType.toLowerCase() != 'admin'"
          mat-raised-button
          color="primary"
          (click)="openMandateModal()"
          [disabled]="!util.hasPermission(util.Permission.CAN_ADD_MANDATES)"
          [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_MANDATES) ? util.NoAccessCursor: ''}"
          [matTooltip]="!util.hasPermission(util.Permission.CAN_ADD_MANDATES) ? util.NoAccessMsg : ''"
          matTooltipPosition="left"
        >
          <mat-icon>library_add</mat-icon>&nbsp;NEW MANDATE
        </button>
      </div>
      <div class="col-12 col-md-11">
        <span class="rm-title rm-title-6">
           View, edit, and create new company mandates that align with your business objectives.
        </span>
      </div>
    </div>
    <!--    table start-->
    <div class="row justify-content-center d-none d-md-flex" style="padding-bottom:150px; padding-top: 25px;">
      <div class="col-12 col-md-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <table mat-table [dataSource]="dataSource" [multiTemplateDataRows]="true" matSort
                     (matSortChange)="sortData($event)">
                <!-- Search Header -->
                <ng-container matColumnDef="table-name">
                  <th colspan="3" mat-header-cell *matHeaderCellDef>
                    <span style="font-size: large;"> Mandates</span>
                  </th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="search-header">
                  <th colspan="4" mat-header-cell *matHeaderCellDef>
                    <form>
                      <mat-form-field style="width: 100%;">
                        <mat-label>Search</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" #input/>
                        <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                    </form>
                  </th>
                </ng-container>
                <!-- Title Column -->
                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef> Title</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ element.title }}
                  </td>
                </ng-container>
                <!-- Employee Column -->
                <ng-container matColumnDef="employee">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="employee"> Created By</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    <app-user-card [firstName]="element.createdBy.firstName"
                                   [lastName]="element.createdBy.lastName"
                                   [displayName]="element.createdBy.email"
                                   [borderColor]="'transparent'"
                                   [sideColor]="'#002150'"
                                   [image]="element.createdBy['imageUrl']"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- Business Unit Column -->
                <ng-container matColumnDef="businessUnit">
                  <!--                  <th mat-header-cell *matHeaderCellDef> Business Unit</th>-->
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="displayName"
                      sortActionDescription="Sort by Business Unit"> Business Unit
                  </th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    <app-user-card [firstName]="element.businessUnit?.displayName"
                                   [displayName]="element.businessUnit?.slug"
                                   [borderColor]="'transparent'"
                                   [sideColor]="'#ff0000'"
                                   [image]="element.businessUnit?.['imageUrl']"
                                   (click)="util.isType('admin')? util.viewBusinessUnit(element.businessUnit?.id, 'mandates') : null"
                                   [ngStyle]="{'cursor': util.isType('admin')? 'pointer':''}"
                                   [highlightOnHover]="util.isType('admin')"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- Created Column -->
                <ng-container matColumnDef="created">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="created"
                      sortActionDescription="Sort by Created Date"> Created
                  </th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ element.created | date }}
                  </td>
                </ng-container>
                <!-- Deal Types Column -->
                <ng-container matColumnDef="dealTypes">
                  <th mat-header-cell *matHeaderCellDef> Deal Types</th>
                  <td mat-cell
                      *matCellDef="let element">
                    <ul style="padding: 0;">
                      <li style="font-size: x-small;" *ngFor="let d of element.investorMandateDeals">
                        {{ d.value }}
                      </li>
                    </ul>

                  </td>
                </ng-container>
                <!-- Asset Classes Column -->
                <ng-container matColumnDef="assetClasses">
                  <th mat-header-cell *matHeaderCellDef> Asset Classes</th>
                  <td mat-cell
                      *matCellDef="let element">
                    <ul style="padding: 0;">
                      <li style="font-size: x-small;" *ngFor="let a of element.investorMandateAssets">
                        {{ a.value }}
                      </li>
                    </ul>
                  </td>
                </ng-container>
                <!-- Anchor Interests Column -->
                <ng-container matColumnDef="anchorInterests">
                  <th mat-header-cell *matHeaderCellDef> Anchor Interests</th>
                  <td mat-cell
                      *matCellDef="let element">
                    <ul style="padding: 0;">
                      <li style="font-size: x-small;" *ngFor="let a of element.investorMandateAnchorInterests">
                        {{ a.value }}
                      </li>
                    </ul>
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="f-aum">
                  <th mat-header-cell *matHeaderCellDef> AUM</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ 'Firm: ' + element['firmAum'] + ', Program: ' + element['programAum'] }}
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="ticket">
                  <th mat-header-cell *matHeaderCellDef> Ticket Size</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ 'Min: ' + element['minTicketSize'] + ', Max: ' + element['maxTicketSize'] + ', Avg: ' + element['avgTicketSize'] }}
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="duration">
                  <th mat-header-cell *matHeaderCellDef> Duration</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ 'Min: ' + element['minimumDuration'] + ', Max: ' + element['maximumDuration'] }}
                  </td>
                </ng-container>
                <!-- Created By Column -->
                <ng-container matColumnDef="Created By">
                  <th mat-header-cell *matHeaderCellDef> User</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ element["createdBy"] }}<br/>{{ element["createdByEmail"] }}
                  </td>
                </ng-container>
                <!-- Company Column -->
                <ng-container matColumnDef="Company">
                  <th mat-header-cell *matHeaderCellDef> BUID</th>
                  <td mat-cell *matCellDef="let element"> {{ element["businessUnitId"] }}<br/>
                    <span style="font-weight: lighter;">{{ element["job_title"] }}</span>
                  </td>
                </ng-container>
                <!-- City Column -->
                <ng-container matColumnDef="City">
                  <th mat-header-cell *matHeaderCellDef> CITY</th>
                  <td mat-cell *matCellDef="let element"> {{ element["city"] }}</td>
                </ng-container>
                <!-- Status Column -->
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="status" sortActionDescription="Sort by Status">
                    Status
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span [appStatus]="element.status">{{ element.status }} </span>
                  </td>
                </ng-container>
                <!-- Date Submitted Column -->
                <ng-container matColumnDef="Date Submitted">
                  <th mat-header-cell *matHeaderCellDef> Date Submitted</th>
                  <td mat-cell *matCellDef="let element"> {{ element["date_submitted"]|date }}</td>
                </ng-container>

                <!-- Firm AUM -->
                <ng-container matColumnDef="firmAum">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="firmAum"
                      sortActionDescription="Sort by Firm AUM"> Firm AUM
                  </th>
                  <td mat-cell *matCellDef="let element"> {{ '$' + element["firmAum"] + 'M USD' }}</td>
                </ng-container>
                <!-- Program AUM -->
                <ng-container matColumnDef="programAum">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="programAum"
                      sortActionDescription="Sort by Program AUM"> Program AUM
                  </th>
                  <td mat-cell *matCellDef="let element"> {{ '$' + element["programAum"] + 'M USD' }}</td>
                </ng-container>
                <!-- Min Duration -->
                <ng-container matColumnDef="minDuration">
                  <th mat-header-cell *matHeaderCellDef> Min. Duration</th>
                  <td mat-cell *matCellDef="let element"> {{ element["minimumDuration"] }}</td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="edit">
                  <th mat-header-cell *matHeaderCellDef> {{ util.isType('admin') ? 'Actions' : 'View' }}</th>
                  <td mat-cell *matCellDef="let element">
                    <div style="display: flex;">
                      <button mat-icon-button
                              (click)="viewHistory(element)"
                              matTooltip="View History"
                              matTooltipPosition="left"
                              *ngIf="util.isType('admin')"
                      >
                        <mat-icon>history</mat-icon>
                      </button>
                      <button mat-icon-button
                              (click)="viewMandate(element)"
                              [disabled]="!util.hasPermission(util.Permission.CAN_ACCESS_MANDATES)"
                              [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ACCESS_MANDATES) ? util.NoAccessCursor: ''}"
                              [matTooltip]="!util.hasPermission(util.Permission.CAN_ACCESS_MANDATES) ? util.NoAccessMsg : 'View Mandate'"
                              matTooltipPosition="left"
                      >
                        <mat-icon>east</mat-icon>
                      </button>
                    </div>
                  </td>
                </ng-container>

                <!--              table rows-->
                <tr mat-header-row *matHeaderRowDef="['table-name','search-header'];"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"></tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter.</td>
                </tr>

              </table>
            </div>
          </div>
          <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[100, 200, 500]"
                         [pageSize]="100"
                         aria-label="Select page of users"></mat-paginator>
        </div>
      </div>
    </div>
    <!--    list start-->
    <div class="row justify-content-center d-flex d-md-none" style="padding-bottom: 150px; padding-top: 15px;">
      <div class="col-12">
        <ul style="list-style-type: none; padding: 25px 0 0 0;">
          <li *ngFor="let element of dataSource.data"
              style="margin-bottom: 5px; display: flex; justify-content: space-between;">
            <app-user-card [firstName]="element.businessUnit?.displayName"
                           [displayName]="element.createdBy?.firstName + ' ' + element.createdBy?.lastName"
                           [borderColor]="'transparent'"
                           [sideColor]="'#345'"
                           [image]="element.businessUnit?.['imageUrl']"
                           [imageShape]="'circle'"
            ></app-user-card>
            <div style="display: flex;">
              <button *ngIf="util.isType('admin')"
                      mat-icon-button
                      (click)="viewHistory(element)"
                      matTooltip="View History"
                      matTooltipPosition="left">
                <mat-icon>history</mat-icon>
              </button>
              <button mat-icon-button
                      (click)="viewMandate(element)"
                      [disabled]="!util.hasPermission(util.Permission.CAN_ACCESS_MANDATES)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ACCESS_MANDATES) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_ACCESS_MANDATES) ? util.NoAccessMsg : 'View Mandate'"
                      matTooltipPosition="left">
                <mat-icon>east</mat-icon>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
