import {Component, Inject, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Config, getConfig, getLookups, initialConfigState, initialLookupsState, Lookups} from "../../../redux";
import {FormControl, FormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {ILookup} from "../../../../models";
import {UtilService} from "../../../shared/services";
import {UserCardSize} from "../../../shared/components";

@Component({
  selector: 'app-entity-modal',
  templateUrl: './entity-modal.component.html',
  styleUrls: ['./entity-modal.component.scss']
})
export class EntityModalComponent implements OnInit {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;
  showPassword = false;

  entityForm = new FormGroup({
    id: new FormControl(),
    businessUnit: new FormControl(),
    entityName: new FormControl(),
    entityType: new FormControl(),
    domicile: new FormControl(),
  });

  constructor(public dialogRef: MatDialogRef<EntityModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { entity: any },
              private store: Store,
              private toastr: ToastrService, public util: UtilService) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });
  }

  ngOnInit(): void {
    this.entityForm.patchValue(this.data.entity);
  }

  submit() {
    this.dialogRef.close(this.entityForm.getRawValue());
  }

  getTypeLookup(): ILookup[] {
    if (!!this.config) {
      // for admin look at the editing business unit type of the entity
      // if (this.util.isType('admin')) {
      //   if (!!this.config.editing_business_unit && !!this.config.editing_business_unit.businessUnitType) {
      //     if (this.config.editing_business_unit.businessUnitType.toLowerCase() == 'investor') {
      //       return this.lookups.kycInvestorTypes;
      //     } else if (this.config.editing_business_unit.businessUnitType.toLowerCase() == 'issuer') {
      //       return this.lookups.kycIssuerTypes;
      //     }
      //   }
      // } else if (this.util.isType('investor')) {
      //   return this.lookups.kycInvestorTypes;
      // } else if (this.util.isType('issuer')) {
      //   return this.lookups.kycIssuerTypes;
      // }
      //todo does this need to be different based on profile type?
      return this.lookups.businessUnitEntityType;
    }
    return [];
  }


  protected readonly UserCardSize = UserCardSize;
}
