import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';

@Component({
  selector: 'app-code-input',
  templateUrl: './code-input.component.html',
  styleUrls: ['./code-input.component.scss']
})
export class CodeInputComponent implements OnInit {

  @ViewChildren('codeInput') codeInputs!: QueryList<any>;
  @ViewChild('resend') resendBtn!: ElementRef;
  @ViewChild('submit') submitBtn!: ElementRef;
  @Input() title = '';
  @Input() backTitle = 'RESEND CODE';
  @Input() nextTitle = 'SUBMIT';
  @Input() codeLen = 6;
  @Input() loading = false;
  @Output() onReset = new EventEmitter;
  // @Output() onBack = new EventEmitter;
  @Output() onNext = new EventEmitter;

  @Output() onPasteError = new EventEmitter;

  readonly RESET_TIMEOUT_MS = 15000;

  code: any[] = [];
  resendEnabled = true;
  submitEnabled = false;

  curKey: any;

  constructor() {
    document.onpaste = (event: ClipboardEvent) => {
      this.codePaste(event);
    }
  }

  ngOnInit(): void {
  }

  request() {
    this.resendEnabled = false;
    this.onReset.emit();
    setTimeout(() => {
      this.resendEnabled = true;
    }, this.RESET_TIMEOUT_MS);

  }

  next() {
    let c = '';
    this.code.forEach((el: number) => {
      c = c + el?.toString();
    });
    this.onNext.emit(c);
  }

  isValid() {
    let valid = true;
    for (let i = 0; i <= this.code.length - 1; i++) {
      if (this.code[i] === null || isNaN(this.code[i]) || this.code[i] === "") {
        valid = false;
        break;
      }
    }
    return valid;
  }

  /**
   * Helper func to return an array for ng-for
   * @param i
   */
  counter(i: number) {
    return new Array(i);
  }

  /**
   * UI Function for changing focus of sms code focus input
   * @param event
   */
  codeUp(event: any) {
    if (event.target.key == "v" && event.target.ctrlKey) {
      console.log("pasting code...");
    } else if (this.curKey === event.code) {
      const keyVal = event.key;
      if (keyVal >= 0 && keyVal <= 9) {
        const idx = event.target.id.split("-")[1];
        if (idx == (this.codeLen - 1)) {
          //were on the last sms element so focus submit
          if (!!this.submitBtn && this.isValid()) {
            this.submitEnabled = true;
            this.submitBtn.nativeElement?.focus();
          }
        } else {
          this.submitEnabled = false;
          const nextIdx = parseInt(idx) + 1;
          let el: ElementRef = this.codeInputs.get(nextIdx);
          if (!!el) {
            el.nativeElement?.focus();
          }
        }
      }
      this.curKey = null;
    }
  }

  codeDown(event: any) {
    if (!this.curKey) {
      this.curKey = event.code;
      const keyVal = event.key;
      const idx = event.target.id.split("-")[1];
      // backspace key
      if (this.curKey == 'Backspace') {
        this.submitEnabled = false;
        // on the last element
        if (idx == (this.codeLen - 1)) {
          // already cleared
          if (this.code[idx] == null) {
            // move to previous element
            const prevIdx = parseInt(idx) - 1;
            let el: ElementRef = this.codeInputs.get(prevIdx);
            if (!!el) {
              el.nativeElement?.focus();
            }
          } else {
            this.code[idx] = null;
          }
        } else if (idx > 0) {
          // all elements but last
          const prevIdx = parseInt(idx) - 1;
          let el: ElementRef = this.codeInputs.get(prevIdx);
          if (!!el) {
            el.nativeElement?.focus();
          }
          this.code[idx] = null;
        }
      }
      // other keys
      if (keyVal >= 0 && keyVal <= 9) {
        this.code[idx] = keyVal;
      }
    }
  }

  codePaste(event: ClipboardEvent) {
    let cd = event.clipboardData;
    if (!!cd) {
      let txt = cd.getData('text');
      let subTxt = txt.substring(0, this.codeLen);
      let isnum = /^\d+$/.test(subTxt);
      if (isnum) {
        //set the code
        this.code = Array.from(subTxt).map((v) => {
          return parseInt(v);
        });

        // show and focus submit
        if (!!this.submitBtn && this.isValid()) {
          this.submitEnabled = true;
          this.submitBtn.nativeElement?.focus();
        }

      } else {
        this.onPasteError.emit(txt);
      }
    }
  }

  /**
   * UI Function for clearing the value of a sms code input when clicked
   * @param event
   */
  codeClick(event: any) {
    const idx = event.target.id.split("-")[1];
    let el: ElementRef = this.codeInputs.get(idx);
    if (!!el) {
      this.code[idx] = null;
      el.nativeElement.value = '';
    }
  }
}
