import { Injectable } from '@angular/core';
import {Config, getConfig, initialConfigState} from "../../../redux";
import {environment} from "../../../../../environments/environment";
import {Store} from "@ngrx/store";
import {RondeivuHttpClient} from "../../../../handlers";
import {HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {iMandateRequest} from "../../../mandates/models";

@Injectable({
  providedIn: 'root'
})
export class BusinessUnitMandatesService {
  private config:Config = initialConfigState;
  private readonly BASE_URL = environment.rondeivu_api;
  private readonly FRAGMENT = '/mandates';

  constructor(private store: Store, private http: RondeivuHttpClient) {
    this.store.select(getConfig).subscribe((config:Config)=>{
      this.config = config;
    });
  }

  addMandate(mandate: iMandateRequest) {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    // const req = JSON.stringify(mandate);
    // console.log(req);
    return this.http.post(this.BASE_URL + this.FRAGMENT, mandate, {headers: httpHeaders});
  }

  getMandates():Observable<any>{
    console.log("Fetching Mandates...");
    return this.http.get(this.BASE_URL + this.FRAGMENT);
  }

  getMandate(id:string):Observable<any>{
    return this.http.get(this.BASE_URL + this.FRAGMENT + '/' + id);
  }
}
