import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LegalRoutingModule} from "./legal-routing.module";
import {HttpClientModule} from "@angular/common/http";
import {NgMaterialModule} from "../ng-material/ng-material.module";
import {SharedModule} from "../shared/shared.module";
import {LegalInformationComponent, LegalListComponent, LegalNavigationComponent} from './components';
import {ReactiveFormsModule} from "@angular/forms";


@NgModule({
  declarations: [
    LegalNavigationComponent,
    LegalListComponent,
    LegalInformationComponent
  ],
  imports: [
    CommonModule,
    LegalRoutingModule,
    HttpClientModule,
    NgMaterialModule,
    SharedModule,
    ReactiveFormsModule
  ]
})
export class LegalModule {
}
