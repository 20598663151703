import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {
  Config,
  getConfig,
  getLookups,
  getSelectedBusinessUnit,
  initialConfigState,
  initialLookupsState,
  Lookups, toggleLoading
} from "../../../redux";
import {UserCardSize} from "../../../shared/components";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {UserSettingsService} from "../../services";
import {MatDialog} from "@angular/material/dialog";
import {UtilService} from "../../../shared/services";

@Component({
  selector: 'app-company-employee-info',
  templateUrl: './company-employee-info.component.html',
  styleUrls: ['./company-employee-info.component.scss']
})
export class CompanyEmployeeInfoComponent implements OnInit {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();

  private config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;

  userCardSize = UserCardSize;

  //reactive form
  userForm = new FormGroup({
    id: new FormControl(),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    jobTitle: new FormControl('', Validators.required),
    email: new FormControl({value: '', disabled: true}, [Validators.email, Validators.required]),
    isActive: new FormControl(true, Validators.required),
    imageUrl: new FormControl()
  });

  constructor(
    private store: Store,
    private router: Router,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public util: UtilService
  ) {
    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });
    this.lookup$ = this.store.select(getLookups).subscribe(lookups => {
      this.lookups = lookups;
    });
    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe(selectedBusinessUnit => {
      if (!!selectedBusinessUnit && !!selectedBusinessUnit.businessUnitId) {
        this.subscribeToDatasource();
      }
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
  }

  subscribeToDatasource() {
  }

  save() {
  }

  back() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.router.navigate(['/' + this.config.selected_business_unit.slug + '/settings/employees/']).then(() => {
      this.store.dispatch(toggleLoading({loading: false}));
    });
  }

}
