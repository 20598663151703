import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {
  Config,
  getConfig,
  getLookups, getSelectedBusinessUnit,
  initialConfigState,
  initialLookupsState,
  Lookups, toggleLoading, updateDealId
} from "../../../redux";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {UtilService} from "../../../shared/services";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatDialog} from "@angular/material/dialog";
import {AdminConfigModalComponent} from "../admin-config-modal/admin-config-modal.component";
import {ModalSize} from "../../../shared/services/util/util.service";
import {ILookup} from "../../../../models";
import {DeleteConfirmationModalComponent} from "../../../shared/components";
import {AdminService} from "../../services";
import {IBusinessUnit} from "../../../business-units/models";
import {ILookupDescription} from "../../models";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-admin-config',
  templateUrl: './admin-config.component.html',
  styleUrls: ['./admin-config.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AdminConfigComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  lookupDescription$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();

  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;
  //reactive form
  userForm = new FormGroup({
    id: new FormControl(),
    selectedLookup: new FormControl()
  });

  cachedLookups: ILookup[] = [];
  selectedLookups: ILookup[] = [];
  selectedLookupName = '';

  lookupDescription: ILookupDescription[] = [];
  selectedLookupDescription: ILookupDescription = {} as unknown as ILookupDescription;
  bu: IBusinessUnit = {} as unknown as IBusinessUnit;

  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  columnsToDisplay = ['key', 'value', 'active', 'sort', 'actions'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: ILookup | null = {} as ILookup;
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  isDirty = false;

  constructor(
    private store: Store,
    private router: Router,
    private toastr: ToastrService,
    public util: UtilService,
    private dialog: MatDialog,
    private adminService: AdminService
  ) {
    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });

    this.lookup$ = this.store.select(getLookups).subscribe(lookups => {
      this.lookups = lookups;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.bu = Object.assign({}, bu);
      // only allow admin
      if (!!this.bu && !!this.bu.businessUnitId && this.bu.businessUnitType.toLowerCase() == 'admin') {
        this.subscribeToDatasource();
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.lookupDescription$.unsubscribe();
  }

  /**
   * The save lookup list action
   */
  save() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.adminService.updateLookup(this.selectedLookupDescription.key, this.selectedLookups).subscribe({
      next: (res: any[]) => {
        this.isDirty = false;
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Lookup Updated!", $localize`:@@companyName:Rondeivu`);
      }, error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to get update lookup!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  subscribeToDatasource(): void {
    this.store.dispatch(updateDealId({dealId: ''}));
    this.adminService.getLookupList().subscribe({
      next: (re: any[]) => {
        this.lookupDescription = Object.assign([], re);
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to get lookup list!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  /**
   * Init the datasource
   */
  initDataSource() {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const lc_filter = filter.toLowerCase();
      const KEY = !!data.key ? data.key.toLowerCase().includes(lc_filter) : false;
      const VAL = !!data.value ? data.value.toLowerCase().includes(lc_filter) : false;
      const ACTIVE = !!data.active ? data.active.toString().toLowerCase().includes(lc_filter) : false;
      return KEY || VAL || ACTIVE;
    };
    if (!!this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  /**
   * Apply table filter
   * @param event
   */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * Change the lookup list
   */
  changeLookup(event: any) {
    // console.log(event);
    const lookupDescription = event.value as ILookupDescription;
    this.store.dispatch(toggleLoading({loading: true}));
    this.selectedLookupDescription = Object.assign({}, lookupDescription);

    //clear first
    this.selectedLookups = Object.assign([]);
    this.cachedLookups = Object.assign([]);
    this.dataSource = new MatTableDataSource(this.selectedLookups);
    this.initDataSource();

    //now fetch new lookup
    this.lookupDescription$ = this.adminService.getLookup(this.selectedLookupDescription.key).subscribe({
      next: (res: any[]) => {
        this.cachedLookups = Object.assign([], res);
        this.selectedLookups = Object.assign([], res);
        this.dataSource = new MatTableDataSource(this.selectedLookups);
        this.initDataSource();
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to get lookup!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  /**
   * Add a new lookup item
   */
  add() {
    const addRef = this.dialog.open(AdminConfigModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.MEDIUM),
      data: {isEditing: false, lookup: {}}
    });

    addRef.afterClosed().subscribe(result => {
      console.log("Dialog output:", result);
      if (!!result) {
        this.selectedLookups.push(result);
        this.dataSource = new MatTableDataSource(this.selectedLookups);
        this.initDataSource();
        this.isDirty = true;
      }
    });
  }

  /**
   * Action to cancel the lookup edit
   */
  cancel() {
    this.selectedLookups = Object.assign([], this.cachedLookups);
    this.dataSource = new MatTableDataSource(this.selectedLookups);
    this.initDataSource();
    this.isDirty = false;
  }

  /**
   * Edit the lookup item
   * @param lookup the lookup item
   * @param idx the index of the item
   */
  edit(lookup: ILookup, idx: number) {
    const editRef = this.dialog.open(AdminConfigModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.MEDIUM),
      data: {isEditing: true, lookup: lookup}
    });

    editRef.afterClosed().subscribe(result => {
      console.log("Dialog output:", result);
      if (!!result) {
        this.selectedLookups.splice(idx, 1, result);
        this.dataSource = new MatTableDataSource(this.selectedLookups);
        this.initDataSource();
        this.isDirty = true;
      }
    });
  }

  /**
   * Delete the lookup item
   * @param lookup the lookup item
   * @param idx the index of the item
   */
  delete(lookup: ILookup, idx: number) {
    const idxToDelete = this.selectedLookups.indexOf(lookup);
    const delRef = this.dialog.open(DeleteConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      data: lookup
    });
    delRef.afterClosed().subscribe(result => {
      console.log("Dialog output:", result);
      if (!!result) {
        this.deleteFromApi(idxToDelete);
        this.dataSource = new MatTableDataSource(this.selectedLookups);
        this.initDataSource();
      }
    });
  }

  private deleteFromApi(idx: number) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.adminService.deleteLookup(this.selectedLookupDescription.key, this.selectedLookups[idx].key).subscribe({
      next: (res: any[]) => {
        this.selectedLookups.splice(idx, 1);
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Item Removed!", $localize`:@@companyName:Rondeivu`);
      }, error: (err: HttpErrorResponse) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to remove item!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  /**
   * Move the lookup item up
   * @param lookup the item to move
   */
  sortUp(lookup: any) {
    const idx = this.selectedLookups.indexOf(lookup);
    // console.log(idx);
    if (idx != 0) {
      this.selectedLookups = this.arrayMove(this.selectedLookups, idx, idx - 1);
      this.dataSource = new MatTableDataSource(this.selectedLookups);
      this.initDataSource();
      this.isDirty = true;
    } else {
      this.toastr.warning("Cannot move up!", $localize`:@@companyName:Rondeivu`);
    }

  }

  /**
   * Move the lookup item down
   * @param lookup the lookup to move
   */
  sortDown(lookup: any) {
    const idx = this.selectedLookups.indexOf(lookup);
    // console.log(idx);
    if (idx != this.selectedLookups.length - 1) {
      this.selectedLookups = this.arrayMove(this.selectedLookups, idx, idx + 1);
      this.dataSource = new MatTableDataSource(this.selectedLookups);
      this.initDataSource();
      this.isDirty = true;
    } else {
      this.toastr.warning("Cannot move down!", $localize`:@@companyName:Rondeivu`);
    }
  }

  arrayMove(arr: any[], old_index: number, new_index: number) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };
}
