import {ActionReducerMap} from "@ngrx/store";
//models
import {Config} from "./stores/config/config.model";
import {Theme} from "./stores/theme/theme.model";
import {UserSettings} from "./stores/user/user.model";
import {Lookups} from "./stores/lookups/lookups.model";
import {CognitoUser} from "./stores/cognito-user/cognito-user.model";

//reducers
import {configReducer} from "./stores/config/config.reducer";
import {themeReducer} from "./stores/theme/theme.reducer";
import {userReducer} from "./stores/user/user.reducer";
import {cognitoUserReducer} from "./stores/cognito-user/cognito-user.reducer";
import {lookupsReducer} from "./stores/lookups/lookups.reducer";
import {dealReducer, DealState} from "./stores/deal/deal.reducer";
import {mandateReducer, MandateState} from "./stores/mandate/mandate.reducer";
import {businessUnitReducer, BusinessUnitState} from "./stores/business-unit/business-unit.reducer";

//EXPORT
//user settings
export {UserSettings} from "./stores/user/user.model";
export {initialUserState} from "./stores/user/user.init";
export {getUserSettings} from "./stores/user/user.selectors";
export {updateUserSettings} from "./stores/user/user.actions";

//config
export {Config} from "./stores/config/config.model";
export {initialConfigState} from "./stores/config/config.init";
export {
  updateConfig,
  updateScrenHeight,
  updateScreenWidth,
  toggleDarkMode,
  toggleLoading,
  updateDrawerState,
  updateDrawerTabIdx,
  updateUserGroups,
  updateNavItems,
  updateSelectedUserView,
  showMandateModal,
  showDealModal,
  showNavigationToolbar,
  showNavigationMenu,
  showNotesTab,
  updateDealId,
  updateDeal,
  updateDealTabs,
  updateDealRole,
  updateDealDocumentRootFolders,
  updateBusinessUnits,
  updateSelectedBusinessUnit,
  updateSelectedBusinessUnitRole,
  updateSelectedBusinessUnitEntityId,
  updateSelectedBusinessUnitEntity,
  updateEditingBusinessUnit,
  updateEditingBusinessUnitEmployee,
  updateUserId,
  updateUser,
  updateLegalId,
  updateLegal,
  updateKycId,
  updateKyc,
  updateMandateId,
  updateMandate,
  updateSelectedDealTeamId,
  updateDealSubscription,
  updateDealSyndication,
  updateTasks,
  updateDealTeamMember,
  updateSelectedEntity,
  updateSelectedEntityId,
  updateSelectedEntityKyc,
  updateSelectedEntityKycId,
  updateEmployee,
  updateEmployeeId,
  fetchTasks
} from "./stores/config/config.actions";
export {
  getConfig,
  getScreenHeight,
  getScreenWidth,
  getUserGroups,
  getNavItems,
  isDarkMode,
  isLoading,
  drawerState,
  selectedView,
  getSelectedBusinessUnit,
  getSelectedDealId,
  getSelectedDeal,
  getSelectedDealTabs,
  getSelectedDealRole,
  getEditingBusinessUnit,
  getEditingBusinessUnitEmployee,
  getSelectedMandateId,
  getSelectedMandate,
  getSelectedUserId,
  getSelectedUser,
  getSelectedLegalId,
  getSelectedLegal,
  getSelectedKycId,
  getSelectedKyc,
  getShowNotesTabState,
  getDealSubscription,
  getDealSyndication,
  getTasks,
  getSelectedDealTeamMember,
  getSelectedEntityId,
  getSelectedEntity,
  getSelectedEntityKyc
} from "./stores/config/config.selectors";

//theme
export {Theme} from "./stores/theme/theme.model";
export {initialThemeState} from "./stores/theme/theme.init";
export {updateTheme} from "./stores/theme/theme.actions";
export {getThemeState} from "./stores/theme/theme.selectors";
export {ThemeEffects} from "./stores/theme/theme.effects";

//cognito
export {CognitoUser} from "./stores/cognito-user/cognito-user.model";
export {initialCognitoUserState} from "./stores/cognito-user/cognito-user.init";
export {updateCognitoUser} from "./stores/cognito-user/cognito-user.actions";
export {getCognitoUser} from "./stores/cognito-user/cognito-user.selectors";

//lookups
export {Lookups} from "./stores/lookups/lookups.model";
export {initialLookupsState} from "./stores/lookups/lookups.init";
export {
  loadedLookups,
  loadLookups,
  fetchLookups,
  updateLookups,
  updateBusinessUnitProfileTypes,
  updateBusinessUnitWorkflowStatus,
  updateAreasOfFocus,
  updateAssetTypes,
  updateCountries,
  updateCountryCodes,
  updateDealNdaRequirementTypes,
  updateDealTypes,
  updateDealWorkflowStatus,
  updateInvestorDescriptionTypes,
  updateInvestorTypes,
  updateInvestorMandateWorkflowStatus,
  updateIssuerTypes,
  updateProvinceTypes,
  updateStateTypes,
  updateTimelineTypes,
  updateDealCloseTypes,
  updateSecDealTypes,
  updateCurrencyTypes,
  updateDealInterestTypes,
  updateUserIntakeSourceTypes,
  updateIssuerEngagementAgreementStatusTypes,
  updateIssuerNDAStatusTypes,
  updateKYCStatusTypes,
  updateDealNotesAccessTypes,
  updateTeamUserRoleTypes,
  updateMemberInviteStatus,
  updateDealAlertTypes,
  updateViewAccessControls,
  updateDataroomTypes,
  updateIndicationOfInterestRestrictionTypes,
  updateInvestorDealWorkflowStatus,
  updateNoteTypes,
  updateSyndicationRestrictionTypes,
  updateSyndicationStatus,
  updateSubscriptionRestrictionTypes,
  updateSubscriptionStatus,
  updateRondeivuEmployeeRoleTypes,
  updateNdaTypes,
  updateEnvelopeStatus,
  updateKycInvestorTypes,
  updateKycIssuerTypes,
  updateAnchorInterestTypes,
  updateIssuerLegalEntityType,
  updateTaxIdType,
  updateLegalEntityProofofDocumentType,
  updateEntityTabDocumentationType,
  updatePersonGovernmentIssuedIdType,
  updatePersonProofofIdentificationIdType,
  updatePersonProofofAddressDocumentType,
  updateEntityTaxDocumentationType,
  updatePersonProofofIdentificationType,
  updateKycRondeivuStatus,
  updateKycLegalEntityRondeivuStatus,
  updateKycType,
  updateKycLegalEntityType,
  updateKycPersonRondeivuStatus,
  updateKycPersonType,
  updateBusinessUnitEntityType,
  updateSubDocStatus
} from "./stores/lookups/lookups.actions";
export {getLookups, getDealTypes, getAssetTypes} from "./stores/lookups/lookups.selectors"


export interface AppState {
  cognito: CognitoUser;
  user: UserSettings;
  config: Config;
  theme: Theme;
  lookups: Lookups;
  deals: DealState;
  mandates: MandateState;
  businessUnits: BusinessUnitState;
}

export const reducers: ActionReducerMap<AppState> = {
  cognito: cognitoUserReducer,
  user: userReducer,
  config: configReducer,
  theme: themeReducer,
  lookups: lookupsReducer,
  deals: dealReducer,
  mandates: mandateReducer,
  businessUnits: businessUnitReducer
}
