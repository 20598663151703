import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  Config,
  getConfig,
  getSelectedBusinessUnit,
  getSelectedDeal,
  initialConfigState,
  toggleLoading
} from "../../../redux";
import {IDealCard} from "../../../shared/models";
import {DealMatchingService, DealService} from "../../services";
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {MatTableDataSource} from "@angular/material/table";
import {IBusinessUnit} from "../../../business-units/models";
import {Subscription} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {DealMatchingModalComponent} from "./deal-matching-modal/deal-matching-modal.component";
import {environment} from "../../../../../environments/environment";
import {ModalSize, UtilService} from "../../../shared/services/util/util.service";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";

@Component({
  selector: 'app-deal-matching',
  templateUrl: './deal-matching.component.html',
  styleUrls: ['./deal-matching.component.scss']
})
export class DealMatchingComponent implements OnInit, OnDestroy {

  private readonly DEFAULT_LOAD_TIME = environment.rondeivu_default_load_delay_ms;

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  selectedDeal$: Subscription = new Subscription();
  selectedDeal: IDealCard = {} as unknown as IDealCard;

  bu: IBusinessUnit = {} as unknown as IBusinessUnit;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  matchesColsToDisplay = ['businessUnit', 'viewBusinessUnit', 'mandate', 'viewMandate', 'compatability', 'internalScore', 'anchorInterests', 'actions', 'matchDetails', 'notes'];
  config: Config = initialConfigState;
  matches: any[] = [];
  exclusions: string = '';
  numbers = Array(11).fill(11).map((x, i) => i);

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  constructor(
    private dealService: DealService,
    private dealMatchingService: DealMatchingService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private toastrService: ToastrService,
    public dialog: MatDialog,
    public util: UtilService
  ) {
    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.bu = Object.assign({}, bu);
    });

    this.selectedDeal$ = this.store.select(getSelectedDeal).subscribe((deal: IDealCard) => {
      if (this.selectedDeal.id != deal.id) {
        this.selectedDeal = deal;
        this.subscribeToDatasource();
      } else {
        this.selectedDeal = deal;
      }
    });

  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedDeal$.unsubscribe();
  }

  subscribeToDatasource(): void {
    // this.getMandates();
    this.getMatches();
    this.getExclusions();
  }

  initDataSource() {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const lc_filter = filter.toLowerCase();
      let BU = !!data.businessUnitMiniModel.displayName ? data.businessUnitMiniModel.displayName.toLowerCase().includes(lc_filter) : false;
      return BU;
    };
    if (!!this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  /**
   * fetch exclusions
   */
  getExclusions() {
    console.log("Fetching Deal Match Exclusions...");
    this.dealMatchingService.getExclusions().subscribe({
      next: (exclusion: any) => {
        this.exclusions = exclusion.reason;
      }, error: (err: any) => {
        this.toastrService.error("Unable to get exclusions!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  saveExclusions(ex: string) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.dealMatchingService.updateExclusions(ex).subscribe({
      next: (exclusion: string) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.success("Exclusions Saved!", $localize`:@@companyName:Rondeivu`);
      }, error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.error("Unable to update exclusions!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  setInternalScore(element: any) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.dealMatchingService.updateInternalScore(element?.mandate?.mandateId, element?.internalScore).subscribe({
      next: (res: any) => {
        if (!!res && res.internalScore) {
          element.internalScore = res.internalScore;
        }
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.success("Internal Score Updated!", $localize`:@@companyName:Rondeivu`);
      }, error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.error("Unable to update internal score!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  getMatches() {
    console.log("Fetching Deal Matches...");
    this.dealMatchingService.getDealMatches().subscribe({
      next: (matches: any[]) => {
        this.matches = matches;
        this.dataSource = new MatTableDataSource(this.matches);
        this.initDataSource();
      }, error: (err: any) => {
        this.toastrService.error("Unable to get deal matches!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  match(element: any) {
    let id = !!element ? element.id : '';
    let mandateId = !!element.mandate ? element.mandate.mandateId : '';
    let matchType = element.isMatched ? 'unmatch' : 'match';
    let displayName = !!element ? element.businessUnitMiniModel?.['displayName'] : 'Unknown';

    const matchRef = this.dialog.open(DealMatchingModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      data: {reason: "", type: matchType, assetName: element.mandate?.title, displayName: displayName},
      disableClose: true // This has to be disabled for flow to work properly on close
    });

    matchRef.afterClosed().subscribe(result => {
      // console.log("Dialog output:", result);
      if (!!result && result.save) {
        this.store.dispatch(toggleLoading({loading: true}));
        this.dealMatchingService.addDealMatch(id, mandateId, result.reason).subscribe({
          next: (res: any) => {
            this.store.dispatch(toggleLoading({loading: false}));
            element.isMatched = true;
            this.toastrService.success("Mandate Matched!", $localize`:@@companyName:Rondeivu`);
            this.getMatches(); // Reza added this so that when matched the MatchId is present for instant unmatching
          }, error: (err: any) => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastrService.error("Unable to match mandate!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  unmatch(element: any) {
    let id = !!element ? element.id : '';
    let matchType = element.isMatched ? 'unmatch' : 'match';
    let displayName = !!element ? element.businessUnitMiniModel?.['displayName'] : 'Unknown';

    const unMatchRef = this.dialog.open(DealMatchingModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      data: {reason: "", type: matchType, assetName: element.mandate?.title, displayName: displayName},
      disableClose: true // This has to be disabled for flow to work properly on close
    });

    unMatchRef.afterClosed().subscribe(result => {
      // console.log("Dialog output:", result);
      if (!!result && result.save) {
        this.store.dispatch(toggleLoading({loading: true}));
        this.dealMatchingService.deleteMatch(id, result.reason).subscribe({
          next: (res: any) => {
            this.store.dispatch(toggleLoading({loading: false}));
            element.isMatched = false;
            element.id = "";
            this.getMatches(); // Reza added this so everything refreshes when unmatched
            this.toastrService.success("Mandate Unmatched!", $localize`:@@companyName:Rondeivu`);
          }, error: (err: any) => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastrService.error("Unable to unmatch mandate!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  viewMandate(id: string) {
    this.store.dispatch(toggleLoading({loading: true}));
    setTimeout(() => {
      this.store.dispatch(toggleLoading({loading: false}));
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/mandates/' + id]);
    }, this.DEFAULT_LOAD_TIME);
  }

  viewHistory(element: any) {
    let id = !!element ? element.id : '';
    this.store.dispatch(toggleLoading({loading: true}));
    setTimeout(() => {
      this.store.dispatch(toggleLoading({loading: false}));
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/history/investordealmatch'], {queryParams: {id: id}});
    }, 1000);
  }

  viewBusinessUnit(id: string) {
    this.store.dispatch(toggleLoading({loading: true}));
    setTimeout(() => {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/business-units/' + id]).then(() => {
        this.store.dispatch(toggleLoading({loading: false}));
      });
    }, this.DEFAULT_LOAD_TIME);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
