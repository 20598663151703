<div style="overflow-y: auto; height: calc(100vh - 178px);">
  <div class="container-fluid">
    <div style="overflow-x: hidden;">
      <div class="row justify-content-center" style="padding-top: 25px;">
        <div class="col-12 col-md-11">
          <span class="rm-title rm-title-3">Entity Information</span><br/>
          <span class="rm-title rm-title-6 rm-lighter">This is where you can edit a business entity.</span>
        </div>
      </div>
      <br/>
      <!--    WELL START-->
      <div class="row justify-content-center" style="padding-bottom: 50px;">
        <div class="col-11">
          <form [formGroup]="entityForm"
                (ngSubmit)="save()">
            <div class="row justify-content-center" style="padding-bottom: 25px;">
              <div class="col-12 ">
                <app-user-card [firstName]="entityForm.get('entityName')?.value || undefined"
                               [displayName]="getEntityTypeString(entityForm.getRawValue())"
                               [borderColor]="'transparent'"
                               [sideColor]="'#002150'"
                               [background]="'#F1EDE4'"
                               [size]="userCardSize.XL"
                               [image]="entityForm.get('businessUnit')?.value?.imageUrl || undefined"
                ></app-user-card>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 ">
                <!--          row start-->
                <div class="row">
                  <div class="col-12 col-md-6">
                    <mat-label style="font-size: medium;">Entity Name</mat-label>
                    <mat-form-field class="form-field" appearance="outline">
                      <input class="form-input" matInput formControlName="entityName" [required]="true"
                             placeholder="My Company Entity"/>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-6">
                    <mat-label style="font-size: medium;">Issuer Entity Type</mat-label>
                    <mat-form-field class="form-field" appearance="outline">
                      <mat-select id="entityType" formControlName="entityType">
                        <mat-option *ngFor="let c of getTypeLookup()" [value]="c.key">
                          {{ c.value }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <!--          row start-->
                <div class="row" style="padding-bottom: 50px;">
                  <div class="col-12 col-md-6">
                    <mat-label style="font-size: medium;">Domicile</mat-label>
                    <mat-form-field class="form-field" appearance="outline">
                      <mat-select id="country" formControlName="domicile">
                        <mat-option *ngFor="let c of lookups.countries" [value]="c.key">
                          {{ c.value }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" style="padding-top:15px;">
              <div class="col-12 text-end">
                <button type="submit"
                        mat-raised-button
                        color="primary"
                >SAVE CHANGES
                </button>
              </div>
            </div>
            <!--          rows end-->
          </form>
        </div>
      </div>
      <br/><br/>
    </div>
  </div>
</div>

