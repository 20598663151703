import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription, tap} from "rxjs";
import {IBusinessUnit} from "../../../business-units/models";
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {UtilService} from "../../../shared/services";
import {
  Config, getConfig, getLookups,
  getSelectedBusinessUnit,
  getSelectedEntity,
  getSelectedEntityKyc, initialConfigState, initialLookupsState, initialThemeState, Lookups, toggleLoading,
  updateSelectedEntityKyc
} from "../../../redux";
import {IBusinessUnitEntity, IEntityKyc} from "../../models/entity";
import {EntityKycService} from "../../services/entity-kyc.service";
import {ToastrService} from "ngx-toastr";
import {FormControl, FormGroup} from "@angular/forms";
import {UserCardSize} from "../../../shared/components";

@Component({
  selector: 'app-entity-kyc-navigation',
  templateUrl: './entity-kyc-navigation.component.html',
  styleUrls: ['./entity-kyc-navigation.component.scss']
})
export class EntityKycNavigationComponent implements OnInit, OnDestroy {

  selectedBusinessUnit$: Subscription = new Subscription();
  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;
  selectedEntity$: Subscription = new Subscription();
  selectedEntity: IBusinessUnitEntity = {} as unknown as IBusinessUnitEntity;

  selectedEntityKyc$: Subscription = new Subscription();
  selectedEntityKyc: IEntityKyc = {} as unknown as IEntityKyc;

  public viewingPDF = false;
  public base64: string | ArrayBuffer | Blob | Uint8Array | URL | { range: any; } = '';

  form = new FormGroup({
    id: new FormControl(''),
    kycRondeivuStatus: new FormControl('')
  });

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    public util: UtilService,
    private entityKycService: EntityKycService,
    private toastr: ToastrService
  ) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((selectedBusinessUnit: IBusinessUnit) => {
      this.selectedBusinessUnit = selectedBusinessUnit;
    });

    this.selectedEntity$ = this.store.select(getSelectedEntity).subscribe((entity: any) => {
      this.selectedEntity = entity;
    });

    this.selectedEntityKyc$ = this.store.select(getSelectedEntityKyc).subscribe((entityKyc: any) => {
      this.selectedEntityKyc = entityKyc;
      this.form.patchValue(entityKyc);
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.lookup$.unsubscribe();
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedEntity$.unsubscribe();
    this.selectedEntityKyc$.unsubscribe();
  }

  back() {
    this.router.navigate([this.selectedBusinessUnit.slug + '/entities/' + this.selectedEntity.id + '/kyc']);
  }

  submit() {

  }

  togglePdfView() {
    this.store.dispatch(toggleLoading({loading: true}));
    if (this.viewingPDF) {
      this.viewingPDF = false;
      this.store.dispatch(toggleLoading({loading: false}));
    } else {
      this.entityKycService.downloadKyc(this.selectedEntityKyc.id).pipe(
        tap((base64) => (this.base64 = base64)),
      ).subscribe({
        next: res => {
          // console.log(res);
          this.viewingPDF = true;
          this.store.dispatch(toggleLoading({loading: false}));
        }, error: error => {
          this.viewingPDF = false;
          this.store.dispatch(toggleLoading({loading: false}));
          this.toastr.error("Unable to view document!", $localize`:@@companyName:Rondeivu`);
        }
      });
    }
  }

  /*
  changeStatus() {
    this.store.dispatch(toggleLoading({loading: true}));
    let id = this.form.get('id')?.value || '';
    let status = this.form.get('kycRondeivuStatus')?.value || '';
    this.entityKycService.updateEntityKycStatus(id, {kycId: id, kycRondeivuStatus: status}).subscribe({
      next: (res: any) => {
        this.fetchAndDispatchEntityKyc();
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Status Updated!", $localize`:@@companyName:Rondeivu`);
      },
      error: (err) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Could not update status!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }
  */

  private fetchAndDispatchEntityKyc() {
    this.entityKycService.getEntityKycById(this.selectedEntityKyc.id).subscribe({
      next: (kyc: any) => {
        this.store.dispatch(updateSelectedEntityKyc({entityKyc: kyc}));
      }, error: (error: any) => {
        this.toastr.error("Unable to get entity kyc!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  protected readonly UserCardSize = UserCardSize;
  protected readonly theme = initialThemeState;
}
