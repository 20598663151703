<!-- <div [@fadeIn]="animationState" (@fadeIn.start)="animStart($event)" > -->
<div>
  <!-- <div [@slideOutLeft]="animateExit" (@slideOutLeft.done)="exitEnd($event)"> -->
  <div>
    <mat-toolbar id="toolbar" dense [ngStyle]="{'background-color':theme.subheader_bg, 'color':theme.subheader_fg}">
      <button mat-button (click)="goBack()" [matTooltip]="'Show all mandates'" matTooltipPosition="below">
        <mat-icon>list</mat-icon>
      </button>
      <div id="deal-header" *ngIf="!loading" class="d-flex">
        <span *ngIf="!util.isType('admin')" style="color: grey;">
          &nbsp;{{(!!selectedMandate.id ? 'Mandate #' + selectedMandate.id : '')}}
        </span>
        <!--Admin view only-->
        <div *ngIf="util.isType('admin')">
          <app-user-card [firstName]="mandateBusinessUnit.displayName"
                         [displayName]="mandateBusinessUnit.slug"
                         [borderColor]="'transparent'"
                         [sideColor]="'transparent'"
                         [image]="mandateBusinessUnit?.imageUrl"
                         [imageShape]="'circle'"
          ></app-user-card>
        </div>
      </div>
      <mat-spinner *ngIf="loading" [diameter]="36" color="accent"></mat-spinner>
      <div *ngIf="allowApproval()" style="position: absolute; right:0; margin-right: 10px;">
        <button mat-raised-button color="primary" (click)="approveMandate(selectedMandate)">Approve</button>&nbsp;
        <button mat-raised-button color="warn" (click)="declineMandate(selectedMandate)">Decline</button>
      </div>
    </mat-toolbar>
    <nav id="deal-nav" dense
         mat-tab-nav-bar
         [tabPanel]="tabPanel"
         style="width: 100%"
         [ngStyle]="{'background-color': theme.subheader_bg}"
    >
      <a
        mat-tab-link
        *ngFor="let link of links"
        (click)="activeLink = link; navigate(link);"
        [active]="activeLink == link"
        [disabled]="!link.enabled"
        [hidden]="link.hidden"
        [ngStyle]="{'background-color':theme.subheader_bg, 'color':theme.subheader_fg}"
      >
        {{ link.title }}
      </a>
    </nav>
    <mat-tab-nav-panel #tabPanel>
      <div [@appFade]="animationState">
        <router-outlet></router-outlet>
      </div>
    </mat-tab-nav-panel>
  </div>
</div>

