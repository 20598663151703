import { Injectable } from '@angular/core';
import {Config, getConfig, initialConfigState} from "../../../redux";
import {environment} from "../../../../../environments/environment";
import {Store} from "@ngrx/store";
import {RondeivuHttpClient} from "../../../../handlers";
import {Observable} from "rxjs";
import {IDealAdminSettingsRequest, IDealStatusRequest} from "../../models";

@Injectable({
  providedIn: 'root'
})
export class DealAdminService {
  private config: Config = initialConfigState;
  private readonly BASE_URL = environment.rondeivu_api;
  private readonly FRAGMENT = '/deals';
  private readonly DEAL_INFO = '/deal-info';
  private readonly SETTINGS_FRAGMENT = '/dealsettings';
  private readonly ADMIN_FRAGMENT = '/admin-settings';
  private readonly STATUS_FRAGMENT = '/status';

  constructor(private store: Store, private http: RondeivuHttpClient) {
    this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
  }

  /**
   * Get the deal admin setting for the selected deal id
   */
  getDealAdminSettings(): Observable<any> {
    return this.http.get(this.BASE_URL + this.SETTINGS_FRAGMENT + this.ADMIN_FRAGMENT + '/' + this.config.selected_deal_id);
  }

  /**
   * Update the deal admin settings for the selected deal id
   * @param adminSettingsRequest
   */
  updateDealAdminSettings(adminSettingsRequest: IDealAdminSettingsRequest): Observable<any> {
    return this.http.put(this.BASE_URL + this.SETTINGS_FRAGMENT + this.ADMIN_FRAGMENT + '/' + this.config.selected_deal_id, adminSettingsRequest);
  }

  /**
   * Update the deal workflow status for the selected deal id
   * @param dealStatusRequest
   */
  updateDealStatus(dealStatusRequest: IDealStatusRequest): Observable<any> {
    return this.http.put(this.BASE_URL + this.SETTINGS_FRAGMENT + this.STATUS_FRAGMENT + '/' + this.config.selected_deal_id, dealStatusRequest);
  }

  getDealAdminMetrics():Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.DEAL_INFO + '/' + this.config.selected_deal_id);
  }

}
