import {Injectable} from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {Config, getConfig, initialConfigState} from "../../../redux";
import {RondeivuHttpClient} from "../../../../handlers";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class InvitationsService {
  readonly BASE_URL = environment.rondeivu_api;
  readonly FRAGMENT = '/invites';
  readonly APPROVE = '/approve';
  readonly DECLINE = '/decline';

  private config: Config = initialConfigState;

  constructor(private http: RondeivuHttpClient, private store: Store) {
    this.store.select(getConfig).subscribe(c => {
      this.config = c;
    });
  }

  /**
   * Get the user invites
   */
  getInvites(appUserId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + '/' + appUserId);
  }

  /**
   * Approve the invite
   */
  approveInvite(id: string, type: 'EMPLOYEE' | 'DEALTEAM'): Observable<any> {
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.APPROVE + '/' + id + '?inviteType=' + type, {});
  }

  /**
   * Decline the invites
   */
  declineInvite(id: string, type: 'EMPLOYEE' | 'DEALTEAM'): Observable<any> {
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.DECLINE + '/' + id + '?inviteType=' + type, {});
  }
}
