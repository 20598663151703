<div style="height:calc(100vh - 112px); overflow-y: auto;">
  <div class="container-fluid">
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-11">
        <span class="rm-title rm-title-3">Email Delivery Log</span><br/>
        <!--        <span class="rm-title rm-title-6 rm-lighter">This is the user's sent email notifications:</span>-->
      </div>
    </div>
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <table mat-table [dataSource]="dataSource" [multiTemplateDataRows]="true" matSort
                     (matSortChange)="sortData($event)">
                <!-- Search Header -->
                <ng-container matColumnDef="table-name">
                  <th colspan="2" mat-header-cell *matHeaderCellDef>
                    <!--                    <span style="font-size: large;">Email Notifications</span>-->
                  </th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="search-header">
                  <th colspan="3" mat-header-cell *matHeaderCellDef>
                    <form>
                      <mat-form-field style="width: 100%;">
                        <mat-label>Search</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" #input>
                        <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                    </form>
                  </th>
                </ng-container>

                <!-- COLUMN -->
                <ng-container matColumnDef="time">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="time"> Date</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    {{ element.mail.timestamp | date:'medium' }}
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <!--                <ng-container matColumnDef="destination">-->
                <!--                  <th mat-header-cell *matHeaderCellDef mat-sort-header="destination"> Recipient</th>-->
                <!--                  <td mat-cell-->
                <!--                      *matCellDef="let element" style="padding: 5px;">-->
                <!--                    {{element.destination[0]}}-->
                <!--                  </td>-->
                <!--                </ng-container>-->
                <!-- COLUMN -->
                <ng-container matColumnDef="to">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="to"> To</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    {{ getHeader(element, 'To') }}
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="from">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="from"> From</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    {{ getHeader(element, 'From') }}
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="subject">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="subject"> Subject</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    {{ getHeader(element, 'Subject') }}
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="response">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="response"> Response</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    {{ element.delivery.smtpResponse }}
                  </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="['table-name','search-header']"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                    class="example-element-row">
                  <!--              (click)="expandedElement = expandedElement === element ? null : element">-->
                </tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter.</td>
                </tr>
              </table>
            </div>
          </div>
          <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[100,250,500]"
                         aria-label="Select page of users"></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

