<div style="height:calc(100vh - 112px); overflow-y: auto;">
  <div class="container-fluid">
    <br/>
    <!--    GENERAL SETTINGS-->
    <form id="buForm" #buForm
          [formGroup]="userForm"
          (ngSubmit)="save()">
      <div class="row justify-content-center">
        <div class="col-11">
          <!--row-->
          <div class="row">
            <div class="col-8 col-md-4">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Lookup</mat-label>
                <mat-select formControlName="selectedLookup" [disabled]="isDirty"
                            (selectionChange)="changeLookup($event)">
                  <mat-option *ngFor="let r of lookupDescription" [value]="r">
                    {{r.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-3 col-md-6" style="padding-top: 12px;">
              <div style="display: flex;">
                <button mat-raised-button type="button"
                        [disabled]="!isDirty || !userForm.get('selectedLookup')?.value?.isEditable" (click)="cancel()">
                  Cancel
                </button>&nbsp;
                <button mat-raised-button type="submit" color="primary"
                        [disabled]="!isDirty || !userForm.get('selectedLookup')?.value?.isEditable">
                  Save
                </button>
              </div>
            </div>
            <!--            <div class="col-11 col-md-2" style="padding-top: 12px;">-->
            <!--              <button mat-raised-button type="button" color="primary" (click)="add()"-->
            <!--                      [disabled]="!userForm.get('selectedLookup')?.value?.isEditable">-->
            <!--                <mat-icon>playlist_add</mat-icon>-->
            <!--                Add-->
            <!--              </button>-->
            <!--            </div>-->
          </div>
          <!--          row-->
          <div class="row" *ngIf="!!userForm.get('selectedLookup')?.value">
            <div class="col-11">
              <span><b>Description:</b> {{userForm.get("selectedLookup")?.value?.description}}</span><br/>
              <span><b>Can Edit:</b> {{userForm.get("selectedLookup")?.value?.isEditable}}</span>
            </div>
          </div>
          <!--          table start-->
          <div class="table-container">
            <div class="row table-inner-container">
              <div class="table-inner-inner-container">
                <table mat-table [dataSource]="dataSource"
                       [multiTemplateDataRows]="true" matSort>
                  <!-- Search Header -->
                  <ng-container matColumnDef="table-name">
                    <th colspan="3" mat-header-cell *matHeaderCellDef>
                      <div style="display: flex; justify-content: space-between; margin-right: 12px;">
                        <div>
                          <span style="font-size: large;">Items for <span
                            style="color: #0033ff">{{userForm.get("selectedLookup")?.value?.name}}</span></span>
                        </div>
                        <button mat-raised-button type="button" color="primary" (click)="add()"
                                [disabled]="!userForm.get('selectedLookup')?.value?.isEditable">
                          <mat-icon>playlist_add</mat-icon>
                          Add
                        </button>
                      </div>
                    </th>
                  </ng-container>
                  <!-- Search Header -->
                  <ng-container matColumnDef="search-header">
                    <th colspan="2" mat-header-cell *matHeaderCellDef>
                      <form>
                        <mat-form-field style="width: 100%;">
                          <mat-label>Search</mat-label>
                          <input type="text" matInput (keyup)="applyFilter($event)" #input/>
                          <mat-icon matSuffix>search</mat-icon>
                        </mat-form-field>
                      </form>
                    </th>
                  </ng-container>
                  <!-- COLUMN -->
                  <ng-container matColumnDef="rowNum">
                    <th mat-header-cell *matHeaderCellDef> INDEX</th>
                    <td mat-cell
                        *matCellDef="let element; let i = dataIndex;">
                      {{i}}
                    </td>
                  </ng-container>
                  <!-- COLUMN -->
                  <ng-container matColumnDef="key">
                    <th mat-header-cell *matHeaderCellDef> KEY</th>
                    <td mat-cell
                        *matCellDef="let element">
                      {{element?.key}}
                    </td>
                  </ng-container>
                  <!-- COLUMN -->
                  <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef> VALUE</th>
                    <td mat-cell
                        *matCellDef="let element">
                      {{element?.value}}
                    </td>
                  </ng-container>
                  <!-- COLUMN -->
                  <ng-container matColumnDef="active">
                    <th mat-header-cell *matHeaderCellDef class="text-center"> ACTIVE</th>
                    <td mat-cell
                        *matCellDef="let element" style="background-color: rgba(128,128,128,0.1)" class="text-center">
                      {{element?.active.toString().toUpperCase()}}
                    </td>
                  </ng-container>

                  <!-- SORT Column -->
                  <ng-container matColumnDef="sort">
                    <th class="text-center" mat-header-cell *matHeaderCellDef> SORT</th>
                    <td class="text-center" mat-cell *matCellDef="let element; let i = dataIndex;">
                      <button mat-icon-button type="button" matTooltip="Move Up" matTooltipPosition="left"
                              [disabled]="!userForm.get('selectedLookup')?.value?.isEditable"
                              (click)="sortUp(element)">
                        <mat-icon>north</mat-icon>
                      </button>
                      <button mat-icon-button type="button" matTooltip="Move Down" matTooltipPosition="above"
                              [disabled]="!userForm.get('selectedLookup')?.value?.isEditable"
                              (click)="sortDown(element)">
                        <mat-icon>south</mat-icon>
                      </button>
                    </td>
                  </ng-container>

                  <!-- ACTIONS Column -->
                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> ACTIONS</th>
                    <td mat-cell *matCellDef="let element; let i = dataIndex;">
                      <button mat-icon-button type="button" matTooltip="Edit Item" matTooltipPosition="left"
                              [disabled]="!userForm.get('selectedLookup')?.value?.isEditable"
                              (click)="edit(element, i)">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button mat-icon-button type="button" color="warn" matTooltip="Delete Item"
                              [disabled]="!userForm.get('selectedLookup')?.value?.isEditable"
                              matTooltipPosition="above"
                              (click)="delete(element, i)">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="expand">
                    <th mat-header-cell *matHeaderCellDef aria-label="row actions" style="width: 50px;">&nbsp;</th>
                    <td mat-cell *matCellDef="let approval" style="width: 50px;">
                      <button mat-icon-button type="button" aria-label="expand row"
                              (click)="(expandedElement = expandedElement === approval ? null : approval); $event.stopPropagation()">
                        <mat-icon *ngIf="expandedElement !== approval">keyboard_arrow_down</mat-icon>
                        <mat-icon *ngIf="expandedElement === approval">keyboard_arrow_up</mat-icon>
                      </button>
                    </td>
                  </ng-container>

                  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                  <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                      <div class="example-element-detail"
                           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <div class="approval-row detail-row">

                        </div>
                      </div>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="['table-name','search-header']"></tr>
                  <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true;"></tr>
                  <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                      class="example-element-row"
                      [class.example-expanded-row]="expandedElement === element"
                      (click)="expandedElement = expandedElement === element ? null : element">
                  </tr>
                  <!--          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>-->
                  <!-- Row shown when there is no matching data. -->
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data matching the filter.</td>
                  </tr>
                </table>
                <!--                table end-->
              </div>
            </div>
            <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[100, 200, 500]"
                           [pageSize]="100"
                           aria-label="Select page of lookups"></mat-paginator>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

