import {Injectable} from '@angular/core';
import {Store} from "@ngrx/store";
import {Config, getConfig, initialConfigState} from "../../../redux";
import {RondeivuHttpClient} from "../../../../handlers";
import {environment} from "../../../../../environments/environment";
import {Observable} from "rxjs";
import {IDealTeamMemberRequest} from "../../models";
import {HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class DealTeamMembersService {

  private config: Config = initialConfigState;
  private readonly BASE_URL = environment.rondeivu_api;
  private readonly DT_FRAGMENT = '/dealteams';
  private readonly CONFIG_FRAGMENT = '/config';
  private readonly DTM_FRAGMENT = '/dealteammembers';
  private readonly MEMBER = '/member';
  private readonly RF_FRAGMENT = '/rootfolders';
  private readonly DTM_PERMISSION = '/dealteampermissions';
  private readonly ALL_ROLES = '/all-roles';
  private readonly STRUCTURE = '/structure';
  private readonly ROLES = '/roles';
  private readonly PERMISSION = '/permissions';
  private readonly EMPLOYEES = '/employees';
  private readonly ADD_EMPLOYEE = '/add-employee';
  private readonly ADD_EXTERNAL = '/add-external-member';

  constructor(private store: Store, private http: RondeivuHttpClient) {
    this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
  }

  //TODO need this endpoint
  getDealTeamMemberById(id: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.DTM_FRAGMENT + this.MEMBER + '/' + id);
  }

  //TODO need this endpoint
  saveDealTeamMember(id: string, post: any): Observable<any> {
    return new Observable<any>();
  }

  /**
   * Get the roles for the deal team member
   * @param dealTeamMemberId
   */
  getAllRoles(dealTeamMemberId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.DTM_PERMISSION + this.ALL_ROLES + '/' + dealTeamMemberId);
  }

  /**
   * Get the permissions form structure for the deal
   */
  getPageStructure(dealId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.DTM_PERMISSION + this.STRUCTURE + '/' + dealId);
  }

  /**
   * Get all the current deal team member roles
   * @param dealTeamMemberId
   */
  getDealTeamMemberRoles(dealTeamMemberId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.DTM_PERMISSION + this.ROLES + '/' + dealTeamMemberId);
  }

  /**
   * Save the new deal team member roles
   * @param dealTeamMemberId
   * @param payload
   */
  saveDealTeamMemberRoles(dealTeamMemberId: string, payload: any): Observable<any> {
    return this.http.put(this.BASE_URL + this.DTM_PERMISSION + this.ROLES + '/' + dealTeamMemberId, payload);
  }

  /**
   * Get the deal team member permissions
   * @param dealId
   */
  getDealTeamMemberPermissions(dealId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.DTM_PERMISSION + this.PERMISSION + '/' + dealId);
  }


  /**
   * Get all the deal teams
   */
  getDealTeams(): Observable<any> {
    // console.log("fetching deal teams..." + this.config.selected_deal_id);
    return this.http.get(this.BASE_URL + this.DT_FRAGMENT + "/" + this.config.selected_deal_id);
  }

  /**
   * Get the config for the deal team
   * @param dealTeamId
   */
  getDealTeamConfig(dealTeamId: string): Observable<any> {
    // console.log("fetching deal team config..." + + dealTeamId);
    return this.http.get(this.BASE_URL + this.DT_FRAGMENT + this.CONFIG_FRAGMENT + '/' + +dealTeamId);
  }

  /**
   * Get all the deal team members
   * @param dealTeamId
   */
  getDealTeamMembers(dealTeamId: string): Observable<any> {
    // console.log("fetching deal team members..." + dealTeamId);
    return this.http.get(this.BASE_URL + this.DTM_FRAGMENT + "/" + dealTeamId);
  }

  /**
   * Get the root folders for the deal team
   * @param dealTeamId
   */
  getRootFolders(dealTeamId: string): Observable<any> {
    // console.log("fetching deal team members root folders..." + dealTeamId);
    return this.http.get(this.BASE_URL + this.DTM_FRAGMENT + this.RF_FRAGMENT + "/" + dealTeamId);
  }

  /**
   * Invites a member to the deal team
   * @param member the new member payload
   * @deprecated
   */
  addDealTeamMember(member: { email: string, role: string }): Observable<any> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const payload = {
      dealTeamId: this.config.selected_deal_team_id,
      email: member.email,
      role: member.role
    }
    return this.http.post(this.BASE_URL + this.DTM_FRAGMENT, payload, {headers: httpHeaders});
  }

  /**
   * Update a member
   * @param dealTeamId
   * @param dtm
   */
  updateDealTeamMember(dealTeamId: string, dtm: IDealTeamMemberRequest) {
    return this.http.put(this.BASE_URL + this.DTM_FRAGMENT + '/' + dealTeamId, dtm);
  }

  /**
   * Deletes a deal team member
   * @param dtm
   */
  deleteDealTeamMember(dtm: IDealTeamMemberRequest) {
    return this.http.delete(this.BASE_URL + this.DTM_FRAGMENT + '/' + dtm.id);
  }

  /**
   * Gets the employees not on the deal team
   * @param dealTeamId
   */
  getEmployees(dealTeamId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.DTM_FRAGMENT + this.EMPLOYEES + '/' + dealTeamId);
  }

  /**
   * Adds a current employee to the deal team
   * @param dealTeamId
   * @param ids
   */
  addEmployees(dealTeamId: string, ids: string[]): Observable<any> {
    return this.http.post(this.BASE_URL + this.DTM_FRAGMENT + this.ADD_EMPLOYEE + '/' + dealTeamId, ids);
  }

  /**
   * Adds an external member to the deal team
   * @param dealTeamId
   * @param employee
   */
  addExternal(dealTeamId: string, employee: { firstName: string, lastName: string, email: string }): Observable<any> {
    return this.http.post(this.BASE_URL + this.DTM_FRAGMENT + this.ADD_EXTERNAL + '/' + dealTeamId, employee);
  }
}
