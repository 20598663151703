<div class="rm-header">
  <span>Decline Deal</span>
</div>

<div class="row justify-content-center" style="padding-bottom: 25px; padding-top: 25px;">
  <div class="col-11">
    <span style="font-size: medium;">Please let us know why you choose to decline this deal:</span>
  </div>
</div>
<div class="row justify-content-center" style="padding-bottom: 50px;">
  <div class="col-11 text-center">
    <form>
      <mat-form-field class="form-field">
        <mat-label>Reason</mat-label>
        <textarea id="reason" name="reason" [(ngModel)]="data.reason" matInput placeholder="Type here..."
                  [required]="true"></textarea>
      </mat-form-field>
    </form>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-6">
    <button mat-raised-button (click)="dialogRef.close()">CANCEL</button>
  </div>
  <div class="col-6 text-end">
    <button mat-raised-button color="primary" (click)="dialogRef.close(data)" [disabled]="!data.reason || data.reason == ''">SUBMIT</button>
  </div>
</div>
