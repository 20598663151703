import {Injectable} from '@angular/core';
import {Config, getConfig, initialConfigState} from "../../../redux";
import {environment} from "../../../../../environments/environment";
import {Store} from "@ngrx/store";
import {RondeivuHttpClient} from "../../../../handlers";
import {UuidService} from "../../../../services";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  private config: Config = initialConfigState;
  private readonly BASE_URL = environment.rondeivu_api;
  private readonly AUDIT_FRAGMENT = '/audit';

  constructor(private store: Store, private http: RondeivuHttpClient, private uuid: UuidService) {
    this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
  }

  getHistory(type: string, id: string, startDate: string, endDate: string): Observable<any> {
    return this.http.get(this.BASE_URL + '/' + type + this.AUDIT_FRAGMENT + '/' + id, {
      params: {
        startDate: startDate,
        endDate: endDate
      }
    });
  }
}
