import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {LoadingService} from "../../../services";
import {Auth} from "aws-amplify";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";

enum ResetPage {
  'RESET' = 0,
  'CODE' = 1,
  'NEW_PASS' = 2
}

@Component({
  selector: 'app-auth-reset',
  templateUrl: './auth-reset.component.html',
  styleUrls: ['./auth-reset.component.scss']
})
export class AuthResetComponent implements OnInit, OnDestroy {
  param$: Subscription = new Subscription();
  readonly TIMEOUT = 3000;
  readonly SIGNIN_PATH = '/login';
  readonly REGISTER_PATH = '/auth/register';

  title = "RESET PASSWORD";
  msg = "Enter the email associated with your account and we will send an email with a code to reset your password."

  email = '';
  curPage = ResetPage.RESET;

  isResend: boolean
  isConfirm: boolean;
  loading: boolean;
  showPassword = false;

  userForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    code: new FormControl('', [Validators.required]),
  });

  constructor(private store: Store, private router: Router, private route: ActivatedRoute,
              private toastrService: ToastrService, public loader: LoadingService) {
    this.isResend = false;
    this.isConfirm = false;
    this.loading = false;

    this.param$ = this.route.queryParamMap
      .subscribe(params => {
        const email = params.get('email');
        if(!!email && email != ''){
          this.userForm.get('email')?.setValue(email);
          this.userForm.get('email')?.disable();
          this.title = "PASSWORD EXPIRED";
          this.msg = "Please reset your password.";
          this.curPage = ResetPage.CODE;
        }
      });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.param$.unsubscribe();
  }

  public resendEmailCode(): void {
    const email = this.userForm.get('email')?.value;
    if (!!email) {
      this.loader.start();
      Auth.forgotPassword(email.toLowerCase())
        .then((cognito: any) => {
          //email confirmation code is not verified
          this.toastrService.success('Code Sent', $localize`:@@companyName:Rondeivu`);
          this.loading = false;
          this.curPage = ResetPage.CODE;
          // console.log(cognito);
        }).catch((err: any) => {
        this.toastrService.error(err.message, $localize`:@@companyName:Rondeivu`);
      });
      this.loader.stop();
    }

  }

  public confirmReset(): void {
    const email = this.userForm.get('email')?.value;
    const pass = this.userForm.get('password')?.value;
    const code = this.userForm.get('code')?.value;

    if (!!email && !!pass && !!code) {
      this.loader.start();
      Auth.forgotPasswordSubmit(email.toLowerCase(), code, pass)
        .then(() => {
          // email confirmation code is verified
          this.toastrService.success('Password Reset', $localize`:@@companyName:Rondeivu`);
          this.router.navigate([this.SIGNIN_PATH]);
        }).catch((err: any) => {
        //email confirmation code is not verified
        this.toastrService.error(err.message, $localize`:@@companyName:Rondeivu`);
      });
      this.loader.stop();
    }

  }

  public confirmEmailCode(code: string): void {
    this.userForm.get('code')?.setValue(code);
    this.goTo(ResetPage.NEW_PASS);
  }

  public goTo(page: number) {
    this.loader.start();
    this.curPage = page;
    this.loader.stop();
  }

}
