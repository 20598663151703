<div class="container-fluid">
  <br/><br/>
  <div class="row justify-content-center">
    <div class="col-4">
      <span
        style="font-family: 'Segoe UI',serif; font-size: x-large; color: #002150;">History for {{ title }}</span><br/>
      <span style="font-weight: lighter;">This is the history for asset #{{ id }}</span>
    </div>
    <div class="col-3">
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>From Date</mat-label>
        <input matInput [matDatepicker]="datepicker" [(ngModel)]="fromDate"/>
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>To Date</mat-label>
        <input matInput [matDatepicker]="datepicker2" [(ngModel)]="toDate"/>
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="datepicker2"></mat-datepicker-toggle>
        <mat-datepicker #datepicker2></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-1" style="padding-top: 10px;">
      <button type="button" mat-raised-button color="primary" (click)="subscribeToDatasource();" [disabled]="!id">Search
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </div>
  <div class="row justify-content-center" style="padding-top: 50px;">
    <div class="col-12 col-md-11">
      <div class="table-container">
        <div class="row table-inner-container">
          <div class="table-inner-inner-container">
            <table mat-table [dataSource]="dataSource" [multiTemplateDataRows]="true" matSort>
              <!-- Search Header -->
              <ng-container matColumnDef="table-name">
                <th colspan="4" mat-header-cell *matHeaderCellDef>
                  <span style="font-size: large;">History for {{ title }}</span>
                </th>
              </ng-container>
              <!-- Search Header -->
              <ng-container matColumnDef="prop-name">
                <th colspan="3" mat-header-cell *matHeaderCellDef class="text-end">
                  <mat-form-field appearance="outline" style="padding-top: 15px; width: 350px; margin-right: 25px;">
                    <mat-label>Filter Property Name</mat-label>
                    <mat-select (selectionChange)="filterProp($event.value)">
                      <mat-option [value]="''">&nbsp;</mat-option>
                      <mat-option *ngFor="let u of uniquePropertyNames;" [value]="u">
                        {{ u }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </th>
              </ng-container>
              <!-- Search Header -->
              <ng-container matColumnDef="search-header">
                <th colspan="3" mat-header-cell *matHeaderCellDef>
                  <form>
                    <mat-form-field style="width: 100%;">
                      <mat-label>Search Property Name</mat-label>
                      <input type="text" matInput (keyup)="applyFilter($event)" #input/>
                      <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                  </form>
                </th>
              </ng-container>
              <!-- Column -->
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> ID</th>
                <td mat-cell *matCellDef="let element">
                  <span>{{ element.id }}</span>
                </td>
              </ng-container>
              <!-- Column -->
              <ng-container matColumnDef="businessUnit">
                <th mat-header-cell *matHeaderCellDef> Business Unit</th>
                <td mat-cell *matCellDef="let element" style="padding-right: 15px;">
                  <span>{{ element.businessUnit?.displayName }}</span><br/>
                  <span style="font-weight: lighter;">{{ element.businessUnit?.slug }}</span>
                </td>
              </ng-container>
              <!-- Column -->
              <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef> Updated By</th>
                <td mat-cell *matCellDef="let element" style="padding-right: 15px;">
                  <span>{{ element.updatedBy?.firstName + ' ' + element.updatedBy?.lastName }}</span><br/>
                  <span style="font-weight: lighter;">{{ element.updatedBy?.email }}</span>
                </td>
              </ng-container>
              <!-- Column -->
              <ng-container matColumnDef="changedDate">
                <th mat-header-cell *matHeaderCellDef> Updated</th>
                <td mat-cell *matCellDef="let element" style="margin-right: 25px;">
                  <span>{{ element.updated | date:'medium' }}</span>
                </td>
              </ng-container>

              <!-- Column -->
              <ng-container matColumnDef="propertyName">
                <th mat-header-cell *matHeaderCellDef style="background-color: #f8f8f8;"> Property Name</th>
                <td mat-cell *matCellDef="let element">
                  <span>{{ element.name }}</span>
                </td>
              </ng-container>

              <!-- Column -->
              <ng-container matColumnDef="arrow">
                <th mat-header-cell *matHeaderCellDef style="background-color: #f8f8f8;"> &nbsp;</th>
                <td mat-cell *matCellDef="let element">
                  <mat-icon style="margin-left:15px; margin-right: 15px;">trending_flat</mat-icon>
                </td>
              </ng-container>

              <!-- Column -->
              <ng-container matColumnDef="oldValue">
                <th mat-header-cell *matHeaderCellDef style="background-color: #f8f8f8;"> Old Value</th>
                <td mat-cell *matCellDef="let element">
                  <span>{{ element.oldValue }}</span>
                </td>
              </ng-container>

              <!-- Column -->
              <ng-container matColumnDef="newValue">
                <th mat-header-cell *matHeaderCellDef style="background-color: #f8f8f8;"> New Value</th>
                <td mat-cell *matCellDef="let element" style="padding-right: 15px;">
                  <span>{{ element.newValue }}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions" style="width: 50px;">&nbsp;</th>
                <td mat-cell *matCellDef="let element" style="width: 50px;">
                  <div>
                    <button mat-icon-button aria-label="expand row"
                            (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                      <mat-icon *ngIf="expandedElement !== element" [matBadge]="element.changes?.length"
                                matBadgeColor="accent">keyboard_arrow_down
                      </mat-icon>
                      <mat-icon *ngIf="expandedElement === element" [matBadge]="element.changes?.length"
                                matBadgeColor="accent">keyboard_arrow_up
                      </mat-icon>
                    </button>
                  </div>
                </td>
              </ng-container>

              <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length"
                    style="padding:0;">
                  <div class="example-element-detail"
                       [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="['table-name','prop-name'];"></tr>
              <!--              <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true;"></tr>-->
              <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand; sticky:true;"></tr>
              <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
                  class="example-element-row"
                  [class.example-expanded-row]="expandedElement === element"
                  [ngStyle]="{'cursor': canExpand(element) ? 'pointer' : ''}"
                  (click)="expandRow(element)">
              </tr>
              <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter.</td>
              </tr>
            </table>
          </div>
        </div>
        <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[100, 200, 500]"
                       [pageSize]="100"
                       aria-label="Select page of approvals"></mat-paginator>
      </div>
    </div>
  </div>
</div>
