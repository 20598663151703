import {Injectable} from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {
  Config,
  getConfig,
  initialConfigState
} from "../../../redux";
import {RondeivuHttpClient} from "../../../../handlers";
import {Store} from "@ngrx/store";
import {HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {
  private readonly BASE_URL = environment.rondeivu_api;
  private readonly FRAGMENT = '/usersettings';
  private readonly VERIFY = '/verify-phone-send-code';
  private readonly CODE = '/verify-phone';
  private readonly UPLOAD = '/upload-image';
  private readonly RESET = '/reset-image';
  private readonly SET_PHONE = '/set-phone';
  private readonly VERIFY_PHONE = '/verify-phone';
  private config: Config = initialConfigState;

  constructor(private http: RondeivuHttpClient, private store: Store) {
    this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });
  }

  /**
   * Get the user settings
   */
  getUserSettings(): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT);
  }

  /**
   * Update the user settings
   * @param userSettings
   */
  updateUserSettings(userSettings: any): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + '/' + userSettings.id, userSettings);
  }

  /**
   * Upload the user image
   */
  uploadUserImage(file: any, appUserId: string): Observable<any> {
    let formData = new FormData();
    formData.append("formFile", file);
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.UPLOAD + '/' + appUserId, formData);
  }

  /**
   * Clears the user image
   * @param appUserId
   */
  clearUserImage(appUserId: string): Observable<any> {
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.RESET + '/' + appUserId, {});
  }

  setPhone(phoneSettings: any): Observable<any> {
    const payload = {
      appUserId: phoneSettings.id,
      countryCode: phoneSettings.countryCode,
      mobile: phoneSettings.mobile
    }
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.SET_PHONE + '/' + phoneSettings.id, payload);
  }

  verifyPhone(appUserId: string, code: string): Observable<any> {
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.VERIFY_PHONE + '/' + appUserId + '?code=' + code, {});
  }

}
