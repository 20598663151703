<!-- <div [@fadeIn]="animationState" (@fadeIn.start)="animStart($event)" > -->
<div>
  <!-- <div [@slideOutLeft]="animateExit" (@slideOutLeft.done)="exitEnd($event)"> -->
  <div>
    <mat-toolbar id="toolbar" dense [ngStyle]="{'background-color':theme.subheader_bg, 'color':theme.subheader_fg}">
      <button mat-button (click)="goBack()" [matTooltip]="'Show all business units'" matTooltipPosition="below">
        <mat-icon>list</mat-icon>
      </button>
      <div id="deal-header" *ngIf="!loading">
        <app-user-card [firstName]="editingBusinessUnit.displayName"
                       [displayName]="editingBusinessUnit.businessUnitType"
                       [borderColor]="'transparent'"
                       [sideColor]="'transparent'"
                       [image]="editingBusinessUnit?.imageUrl"
                       [imageShape]="'circle'"
        ></app-user-card>
      </div>
      <mat-spinner *ngIf="loading" [diameter]="36" color="accent"></mat-spinner>
      <div *ngIf="allowApproval()" style="position: absolute; right:0; margin-right: 10px;">
        <button mat-raised-button
                color="primary"
                (click)="approveBusinessUnit(editingBusinessUnit)"
                [disabled]="!getApprovalPermission()"
                [ngStyle]="{'cursor':!getApprovalPermission() ? util.NoAccessCursor: ''}"
                [matTooltip]="!getApprovalPermission()? util.NoAccessMsg : ''"
                matTooltipPosition="left"
        >Approve
        </button>&nbsp;
        <button mat-raised-button
                color="warn"
                (click)="declineBusinessUnit(editingBusinessUnit)"
                [disabled]="!getApprovalPermission()"
                [ngStyle]="{'cursor':!getApprovalPermission() ? util.NoAccessCursor: ''}"
                [matTooltip]="!getApprovalPermission()? util.NoAccessMsg : ''"
                matTooltipPosition="left"
        >Decline
        </button>
      </div>
    </mat-toolbar>
    <nav id="deal-nav" dense
         mat-tab-nav-bar
         [tabPanel]="tabPanel"
         style="width: 100%"
         [ngStyle]="{'background-color': theme.subheader_bg}"
    >
      <div *ngFor="let link of links">
        <a *ngIf="link.enabled"
           mat-tab-link
           (click)="activeLink = link; navigate(link);"
           [active]="activeLink.link == link.link"
           [disabled]="!link.enabled"
           [hidden]="link.hidden"
           [ngStyle]="{'background-color':theme.subheader_bg, 'color':theme.subheader_fg}"
        ><span>{{ link.title }}</span></a>
        <span class="fake-tab" *ngIf="!link.enabled" [matTooltip]="link.toolTip"
              matTooltipPosition="below">{{ link.title }}</span>
      </div>
    </nav>
    <mat-tab-nav-panel #tabPanel>
      <div [@appFade]="animationState">
        <router-outlet></router-outlet>
      </div>
    </mat-tab-nav-panel>
  </div>
</div>

