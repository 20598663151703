<div class="employee-header">
  <button type="button" mat-icon-button (click)="back()">
    <mat-icon>west</mat-icon>
  </button>
  <app-user-card
    [firstName]="selectedDealTeamMember?.firstName"
    [lastName]="selectedDealTeamMember?.lastName"
    [displayName]="selectedDealTeamMember?.email"
    [image]="selectedDealTeamMember?.imageUrl"
  >
  </app-user-card>
</div>
<router-outlet></router-outlet>
