<div style="height:calc(100vh - 176px); overflow-y: auto;">
  <div class="container-fluid">
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-11">
        <span class="rm-title rm-title-3">Email Notifications</span><br/>
        <span class="rm-title rm-title-6 rm-lighter">This is the user's sent email notifications:</span>
      </div>
    </div>
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <table mat-table [dataSource]="dataSource"
                     [multiTemplateDataRows]="true"
                     matSort
                     (matSortChange)="sortData($event)"
              >
                <!-- Search Header -->
                <ng-container matColumnDef="table-name">
                  <th colspan="3" mat-header-cell *matHeaderCellDef>
                    <span style="font-size: large;">User Email Notifications</span>
                  </th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="search-header">
                  <th colspan="2" mat-header-cell *matHeaderCellDef>
                    <form>
                      <mat-form-field style="width: 100%;">
                        <mat-label>Search Subject</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" #input>
                        <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                    </form>
                  </th>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="status"> Status</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ element.emailStatus }}
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="platform">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="platform"> Platform</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ element.platform }}
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="subject">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="subject"> Subject</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ element.subject }}
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="date">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="date"> Date</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ element.statusChangedTime | date:'medium' }}
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="type"> Type</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ element.type }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="['table-name','search-header']"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                    class="example-element-row">
                  <!--              (click)="expandedElement = expandedElement === element ? null : element">-->
                </tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter.</td>
                </tr>
              </table>
            </div>
          </div>
          <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[5, 10, 25, 100]"
                         aria-label="Select page of users"></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

