import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {
  ContactUsComponent,
  NavigationComponent,
  PageNotFoundComponent, ThirdPartyDeterminationComponent, ViewerComponent
} from './components';
import {AuthGuard} from "./guards/auth.guard";
import {AuthBaseComponent, AuthLoginComponent, AuthRegisterComponent, AuthResetComponent} from "./components/auth";
import {BusinessUnitResolver} from "./resolvers/business-unit.resolver";
import {ClosedDealsComponent, LiveDealsComponent, PendingDealsComponent} from "./modules/dashboard/components";
import {HistoryComponent} from "./modules/shared/components";

const routes: Routes = [
  {path: 'lobby', component: PageNotFoundComponent},
  {path: 'terms-and-conditions', component: PageNotFoundComponent},
  {path: '', redirectTo: '/auth/login', pathMatch: 'full'},
  {
    path: 'auth',
    component: AuthBaseComponent,
    children: [
      {path: 'login', component: AuthLoginComponent},
      {path: 'register', component: AuthRegisterComponent},
      {path: 'reset', component: AuthResetComponent},
      {path: '*', redirectTo: '/auth/login', pathMatch: 'full'},
      {path: '**', redirectTo: '/auth/login', pathMatch: 'full'}
    ]
  },
  {
    path: ':id',
    component: NavigationComponent,
    runGuardsAndResolvers: 'paramsChange',
    canActivate: [AuthGuard],
    resolve: {
      BusinessUnitResolver
    },
    children: [
      {
        path: '',
        component: PageNotFoundComponent
      },
      {
        path: 'contact-us',
        component: ContactUsComponent,
      },
      {
        path: 'regulatory',
        component: ThirdPartyDeterminationComponent,
      },
      {
        path: 'pending-deals',
        component: PendingDealsComponent,
      },
      {
        path: 'live-deals',
        component: LiveDealsComponent,
      },
      {
        path: 'closed-deals',
        component: ClosedDealsComponent,
      },
      {
        path: 'documents',
        children: [
          {path: ':id', component: ViewerComponent}
        ],
      },
      {
        path: 'history', children: [
          {path: ':assetType', component: HistoryComponent}
        ]
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules').then(m => m.DashboardModule),
      },
      {
        path: 'approvals',
        loadChildren: () => import('./modules').then(m => m.ApprovalsModule),
      },
      {
        path: 'business-units',
        loadChildren: () => import('./modules').then(m => m.BusinessUnitsModule),
      },
      {
        path: 'deals',
        loadChildren: () => import('./modules').then(m => m.DealsModule),
      },
      {
        path: 'mandates',
        loadChildren: () => import('./modules').then(m => m.MandatesModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./modules').then(m => m.UsersModule),
      },
      {
        path: 'admin',
        loadChildren: () => import('./modules').then(m => m.AdminModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./modules').then(m => m.SettingsModule),
      },
      {
        path: 'legal',
        loadChildren: () => import('./modules').then(m => m.LegalModule),
      },
      {
        path: 'entities',
        loadChildren: () => import('./modules').then(m => m.EntitiesModule),
      },
      {
        path: 'kyc',
        loadChildren: () => import('./modules').then(m => m.KycModule),
      },
      {
        path: 'my-company',
        loadChildren: () => import('./modules').then(m => m.CompanyModule),
      },
      {path: '*', redirectTo: '', pathMatch: 'full'},
      {path: '**', redirectTo: '', pathMatch: 'full'}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
