import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Store} from "@ngrx/store";
import {
  Config,
  getConfig,
  getSelectedBusinessUnit, getSelectedDeal,
  getSelectedDealId,
  getThemeState,
  initialConfigState,
  toggleLoading
} from "../../../redux";
import {Subscription} from "rxjs";
import {IBusinessUnit} from "../../../business-units/models";
import {Router} from "@angular/router";
import {DealService} from "../../services";
import {ToastrService} from "ngx-toastr";
import {HttpErrorResponse} from "@angular/common/http";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {DealHighlightsModalComponent} from "./deal-highlights-modal/deal-highlights-modal.component";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {IDealCard} from "../../../shared/models";
import {IDealHighlightsRequest} from "../../models";
import {ModalSize, UtilService} from "../../../shared/services/util/util.service";

@Component({
  selector: 'app-deal-highlights',
  templateUrl: './deal-highlights.component.html',
  styleUrls: ['./deal-highlights.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DealHighlightsComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  dealId$: Subscription = new Subscription();
  selectedDeal$: Subscription = new Subscription();
  theme$: Subscription = new Subscription();
  private config: Config = initialConfigState;
  bu: IBusinessUnit = {} as unknown as IBusinessUnit;
  selectedDeal: IDealCard = {} as unknown as IDealCard;
  theme: any;

  highlights: any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  columnsToDisplay = ['business', 'admins', 'dealTeams', 'kycStatus', 'ndaStatus', 'preNda', 'status', 'interest', 'actions'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: any | null = {};

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  constructor(private store: Store,
              private router: Router,
              private dealService: DealService,
              private toastr: ToastrService,
              public dialog: MatDialog,
              public util: UtilService
  ) {

    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });

    this.theme$ = this.store.select(getThemeState).subscribe((theme: any) => {
      this.theme = theme;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.bu = Object.assign({}, bu);
    });

    this.selectedDeal$ = this.store.select(getSelectedDeal).subscribe((deal: IDealCard) => {
      if (this.selectedDeal.id != deal.id) {
        this.selectedDeal = deal;
        this.getDealHighlights();
      } else {
        this.selectedDeal = deal;
      }
    });

  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.dealId$.unsubscribe();
    this.theme$.unsubscribe();
    this.selectedDeal$.unsubscribe();
  }

  getDealHighlights() {
    console.log("Fetching Deal Highlights...");
    this.dealService.getDealHighlights().subscribe({
      next: (highlights: any) => {
        this.highlights = Object.assign({}, highlights);
        this.dataSource = new MatTableDataSource(this.highlights.investors);
        this.initDataSource();
      }, error: (error: HttpErrorResponse) => {
        this.toastr.error("Unable to get deal highlights!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  initDataSource() {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      return data.businessUnit.displayName?.toLowerCase().includes(filter.toLowerCase());
    };
    if (!!this.paginator) {
      this.paginator.pageSize = 100;
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  edit(element: any) {
    const hightlightsRef = this.dialog.open(DealHighlightsModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.MEDIUM),
      disableClose: true,
      data: element
    });

    hightlightsRef.afterClosed().subscribe((result: IDealHighlightsRequest) => {
      // console.log("Dialog closed!");
      if (!!result) {
        this.store.dispatch(toggleLoading({loading: true}));
        this.dealService.investorNdaUpdate(result).subscribe({
          next: (res: any) => {
            this.getDealHighlights();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("Investor Updated!", $localize`:@@companyName:Rondeivu`);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to update investor!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });

  }

  view(user: any) {
    if (!!user.id) {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/users/' + user.id]);
    }
  }

  viewBusinessUnit(element:any){
    if(!!element){
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/business-units/' + element.businessUnit.id]);
    }
  }
}
