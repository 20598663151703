import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  Config,
  getConfig,
  getSelectedBusinessUnit,
  getSelectedDeal,
  initialConfigState,
  toggleLoading
} from "../../../redux";
import {IDealInfoRequest} from "../../models";
import {IDealCard} from "../../../shared/models";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {DealService} from "../../services";
import {ToastrService} from "ngx-toastr";
import {IBusinessUnit} from "../../../business-units/models";
import {HttpErrorResponse} from "@angular/common/http";
import {FormControl} from "@angular/forms";
import {Subscription} from "rxjs";
import {UtilService} from "../../../shared/services";

@Component({
  selector: 'app-deal-edit',
  templateUrl: './deal-edit.component.html',
  styleUrls: ['./deal-edit.component.scss']
})
export class DealEditComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  selectedDeal$: Subscription = new Subscription();
  selectedDeal: IDealCard = {} as unknown as IDealCard;

  private config: Config = initialConfigState;
  selected = new FormControl(0);

  dealInfo: IDealInfoRequest = {} as unknown as IDealInfoRequest;
  preNdaDeal: IDealCard = {} as unknown as IDealCard;
  postNdaDeal: IDealCard = {} as unknown as IDealCard;
  postClose: IDealCard = {} as unknown as IDealCard;

  bu: IBusinessUnit = {} as unknown as IBusinessUnit;

  @ViewChild('fileUpload') private fileInput: ElementRef | undefined;

  constructor(
    private store: Store,
    private router: Router,
    private dealService: DealService,
    private toastr: ToastrService,
    public util: UtilService
  ) {
    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.bu = Object.assign({}, bu);
    });

    this.selectedDeal$ = this.store.select(getSelectedDeal).subscribe((deal: IDealCard) => {
      if (this.selectedDeal.id != deal.id) {
        this.selectedDeal = deal;
        this.subscribeToDatasource();
      } else {
        this.selectedDeal = deal;
      }
    });

  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedDeal$.unsubscribe();
  }

  subscribeToDatasource() {
    console.log("Fetching Deal Edit Information...");
    this.dealService.getDealInformation().subscribe({
      next: (di: IDealInfoRequest) => {
        this.dealInfo = di;
        this.setFormObjects(this.dealInfo);
      }, error: (error: HttpErrorResponse) => {
        this.toastr.error("Unable to get deal information!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  private setFormObjects(di: IDealInfoRequest) {
    //pre nda card view
    this.preNdaDeal.dealImageUrl = di.preImageUrl;
    this.preNdaDeal.projectName = di.projectName;
    this.preNdaDeal.description = di.preNdaDescription;
    this.preNdaDeal.pills = [{
      name: "Pre-NDA",
      backgroundColor: "#E8EDF6",
      fontColor: "#002150",
      borderColor: "#E8EDF6",
      tooltip: "Example of the Pre-NDA Deal"
    }];
    this.preNdaDeal.dealTimeline = {
      current: {tooltip: "Example of the Pre-NDA Deal", xPos: 0},
      markers: [{title: "Pending", titlePos: "top", xPos: 0, status: "CURRENT"}, {
        title: "Live",
        titlePos: "top",
        xPos: 50,
        status: "FUTURE"
      }, {title: "Closed", titlePos: "top", xPos: 100, status: "FUTURE"}]
    }

    //post nda card view
    this.postNdaDeal.dealImageUrl = di.postImageUrl;
    this.postNdaDeal.projectName = di.projectName;
    this.postNdaDeal.assetName = di.assetName;
    this.postNdaDeal.description = di.postNdaDescription;
    this.postNdaDeal.pills = [{
      name: "Post-NDA",
      backgroundColor: "#002150",
      fontColor: "#E8EDF6",
      borderColor: "#002150",
      tooltip: "Example of the Post-NDA Deal"
    }];
    this.postNdaDeal.dealTimeline = {
      current: {tooltip: "Example of the Post-NDA Deal", xPos: 50},
      markers: [{title: "Pending", titlePos: "top", xPos: 0, status: "PAST"}, {
        title: "Live",
        titlePos: "top",
        xPos: 50,
        status: "CURRENT"
      }, {title: "Closed", titlePos: "top", xPos: 100, status: "FUTURE"}]
    }

    //post close card view
    this.postClose.dealImageUrl = di.closedImageUrl;
    this.postClose.projectName = di.projectName;
    this.postClose.assetName = di.assetName;
    this.postClose.description = di.postCloseDescription;
    this.postClose.pills = [{
      name: "Closed",
      backgroundColor: "#B71C1C",
      fontColor: "#FFEBE5",
      borderColor: "#B71C1C",
      tooltip: "Example of the Closed Deal"
    }];
    this.postClose.dealTimeline = {
      current: {tooltip: "Example of the Post-Close Deal", xPos: 100},
      markers: [{title: "Pending", titlePos: "top", xPos: 0, status: "PAST"}, {
        title: "Live",
        titlePos: "top",
        xPos: 50,
        status: "PAST"
      }, {title: "Closed", titlePos: "top", xPos: 100, status: "CURRENT"}]
    }
  }

  changeTab(event: any) {
    this.selected.setValue(event);
  }

  /**
   * Action for adding a new file
   * @param event
   */
  addDocuments(event: any) {
    // console.log(event);
    if (!!event && !!event.target && !!event.target.files[0]) {
      this.uploadDealImg(event.target.files[0]);
      if (!!this.fileInput) {
        this.fileInput.nativeElement.value = '';
      }
    }
  }

  private uploadDealImg(file: any) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.dealService.uploadDealImage(file, this.getType()).subscribe({
      next: (res: { url: string }) => {
        console.log(this.selected.value);
        switch (this.selected.value) {
          case 0:
            //pre nda card view
            this.preNdaDeal.dealImageUrl = res.url;
            break;
          case 1:
            //post nda card view
            this.postNdaDeal.dealImageUrl = res.url;
            break;
          case 2:
            //post close card view
            this.postClose.dealImageUrl = res.url;
            break;
        }
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Image Updated!", $localize`:@@companyName:Rondeivu`);
      }, error: (err: HttpErrorResponse) => {
        console.log(err);
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to update deal image!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  saveDealInformation() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.dealService.updateDealInfo(this.getDealInfoRequest()).subscribe({
      next: (di: IDealInfoRequest) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Deal Card Updated!", $localize`:@@companyName:Rondeivu`);
      }, error: (err: HttpErrorResponse) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to update deal information!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  getDealInfoRequest(): IDealInfoRequest {
    return {
      id: this.dealInfo.id,
      projectName: this.preNdaDeal.projectName,
      preNdaDescription: this.preNdaDeal.description,
      assetName: this.postNdaDeal.assetName,
      postNdaDescription: this.postNdaDeal.description,
      preImageUrl: this.preNdaDeal.dealImageUrl,
      postImageUrl: this.postNdaDeal.dealImageUrl,
      closedImageUrl: this.postClose.dealImageUrl,
      postCloseDescription: this.postClose.description
    } as IDealInfoRequest;
  }

  changeName(event: any) {
    this.postNdaDeal.projectName = this.preNdaDeal.projectName;
    this.postClose.projectName = this.preNdaDeal.projectName;
  }

  /**
   * Get the display for the state
   */
  getStateTitle() {
    switch (this.selected.value) {
      case 0:
        return 'Pre-NDA';
      case 1:
        return 'Post-NDA';
      case 2:
        return 'Post-Close';
    }
    return 'Pre-NDA';
  }

  private getType() {
    switch (this.selected.value) {
      case 0:
        return 'pre';
      case 1:
        return 'post';
      case 2:
        return 'closed';
    }
    return '';
  }
}
