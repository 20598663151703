<div style="height:calc(100vh - 176px); overflow-y: auto;">
  <div class="container-fluid">
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-12 col-md-11">
        <span class="rm-title rm-title-3">Investor Metrics</span><br/>
      </div>
    </div>
    <div class="row justify-content-center" style="padding-bottom: 25px;">
      <div class="col-12 col-md-11">
        <span class="rm-title rm-title-6 rm-lighter">These are all of this deal's investor metrics.</span>
      </div>
    </div>
    <div class="row justify-content-center" style="padding-bottom: 25px;">
      <div class="col-12 col-md-11">
        <span style="font-size: large; font-weight: bold;">Chart Configurations</span> <br/><br/>
        <mat-checkbox [(ngModel)]="gradient">Use Gradients</mat-checkbox>&nbsp;
        <mat-checkbox [(ngModel)]="showLegend">Show Legend</mat-checkbox>&nbsp;
        <mat-checkbox [(ngModel)]="animation">Use Animation</mat-checkbox>&nbsp;
        <mat-checkbox [(ngModel)]="showTooltip">Show Tooltip</mat-checkbox>&nbsp;
        <mat-checkbox [(ngModel)]="explode">Explode Chart</mat-checkbox>&nbsp;
        <mat-checkbox [(ngModel)]="showLabels">Show Labels</mat-checkbox>&nbsp;
      </div>
    </div>
    <div class="row justify-content-center" style="padding-bottom: 25px;">
      <div class="col-12 col-md-6">
        <mat-form-field style="width: 100%;" appearance="outline">
          <mat-label style="font-size: large">Color Scheme</mat-label>
          <mat-select [(ngModel)]="selectedColor">
            <mat-option *ngFor="let c of colorSchemes" [value]="c">
              {{c}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-5">
        &nbsp;
      </div>
    </div>
    <div class="row justify-content-center" style="padding-bottom: 80px;">
      <div class="col-12 col-md-8">
        <div style="max-height: 300px; margin-bottom:50px;">
          <ngx-charts-pie-chart
            [results]="tableData"
            [scheme]="selectedColor"
            [animations]="animation"
            [gradient]="gradient"
            [tooltipDisabled]="!showTooltip"
            [legend]="showLegend"
            [legendPosition]="LegendPosition.Below"
            [explodeSlices]="explode"
            [labels]="showLabels"
            [legendTitle]="'Investor Types'">
            <ng-template #tooltipTemplate let-model="model">
              <span>{{model.name}}</span><br/>
              <span>{{Math.round(model.value) + '%'}}</span>
            </ng-template>
          </ngx-charts-pie-chart>
        </div>
      </div>

      <div class="col-12 col-md-3" *ngIf="!!investors">
        <div class="row block">
          <span class="title">Deal Fundraise Size</span><br/><br/>
          <span class="money">{{ '$' + investors?.totalDealFundraiseSize + ' Million USD' }}</span>
        </div>

        <div class="row block">
          <span class="title">Total Ticket Size for this deal</span><br/><br/>
          <span class="money">{{ '$' + investors?.totalCurrentCapacityOnRondeivu + ' Million USD' }}</span>
        </div>

      </div>
    </div>
  </div>
</div>






