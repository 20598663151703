import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {
  Config,
  getConfig,
  getLookups,
  getSelectedBusinessUnit,
  getSelectedKycId,
  initialConfigState,
  initialLookupsState,
  Lookups,
  toggleLoading
} from "../../../redux";
import {IBusinessUnit} from "../../../business-units/models";
import {FormControl, FormGroup} from "@angular/forms";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {KycService, UtilService} from "../../../shared/services";
import {IKycProcess} from "../../models/kyc";
import {DataRoomType} from "../../../shared/components/data-room/data-room.component";

@Component({
  selector: 'app-kyc-documents',
  templateUrl: './kyc-documents.component.html',
  styleUrls: ['./kyc-documents.component.scss']
})
export class KycDocumentsComponent implements OnInit, OnDestroy {

  DataRoomType = DataRoomType;

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  selectedUserId$: Subscription = new Subscription();

  private config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;
  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;

  //reactive form
  userForm = new FormGroup({
    id: new FormControl(),
    entityName: new FormControl(),
    status: new FormControl(),
    kycProfileSubType: new FormControl(),
  });

  loading: boolean = false;
  docs: { name: string, url: string }[] = [];

  selectedKyc: IKycProcess = {} as unknown as IKycProcess;

  constructor(private store: Store,
              private router: Router,
              private toastr: ToastrService,
              private kycService: KycService,
              public util: UtilService
  ) {
    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });
    this.lookup$ = this.store.select(getLookups).subscribe(lookups => {
      this.lookups = lookups;
    });
    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe(businessUnit => {
      this.selectedBusinessUnit = businessUnit;
    });
    this.selectedUserId$ = this.store.select(getSelectedKycId).subscribe(kycId => {
      if (!!this.selectedBusinessUnit && !!this.selectedBusinessUnit.businessUnitId) {
        this.subscribeToDatasource();
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedUserId$.unsubscribe();
  }

  subscribeToDatasource(): void {
    this.store.dispatch(toggleLoading({loading: true}));
    this.kycService.getKycById(this.config.selected_kyc_id).subscribe({
      next: (kyc: any) => {
        this.selectedKyc = Object.assign({}, kyc);
        this.userForm.patchValue(kyc);
        //get the kyc documents
        if (!!this.selectedKyc?.kycProfileSubType) {
          this.getKycDocuments(this.selectedKyc.kycProfileSubType)
        }
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: error => {
        this.toastr.error("Something went wrong!");
        this.store.dispatch(toggleLoading({loading: false}));
      }
    });
  }

  save() {
    let payload = this.userForm.getRawValue();
    this.store.dispatch(toggleLoading({loading: true}));
    this.kycService.updateKyc(payload).subscribe((kyc: any) => {
      this.store.dispatch(toggleLoading({loading: false}));
      this.toastr.success("KYC Information Saved!", $localize`:@@companyName:Rondeivu`);
    }, error => {
      this.store.dispatch(toggleLoading({loading: false}));
      this.toastr.error("Something went wrong!");
    });
  }

  /**
   * Fetched the kyc documents when the kyc type changes
   * @param type the form control event value
   */
  getKycDocuments(type: string) {
    this.loading = true;
    this.kycService.getKycDetails(type).subscribe({
      next: (res: any) => {
        this.docs = Object.assign([], res.result);
        this.loading = false;
      }, error: (err: any) => {
        this.loading = false;
        this.toastr.error("Something went wrong!");
      }
    });
  }
}
