export interface INote {
  id: string;
  note: string;
  createdBy: any;
  created: string;
}

export enum NoteTypes {
  BUSINESSUNIT = "BUSINESSUNIT",
  MANDATE = "MANDATE",
  DEAL = "DEAL",
  ADMIN = "ADMIN"
}

export interface INoteCollection {
  notesCollectionId: string;
  name: string;
}

export interface INoteItem {
  id: string;
  note: string;
  isNoteOwner: boolean;
  createdBy: {
    firstName: string,
    lastName: string,
    created: string
  }
  created: string;
}

export enum ActionType {
  ADD = 'ADD',
  EDIT = 'EDIT',
  DELETE = 'DELETE'
}
