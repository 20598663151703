import { Injectable } from '@angular/core';
import {toggleLoading} from "../../modules/redux";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private delayTime = environment.rondeivu_default_load_delay_ms;

  constructor(private store:Store, private router:Router) { }

  /**
   * Start the loading animation
   */
  start(){
    this.store.dispatch(toggleLoading({loading: true}));
  }
  /**
   * Stop the loading animation with default delay
   */
  stop(){
    setTimeout(() => {
      this.store.dispatch(toggleLoading({loading: false}));
    }, this.delayTime);
  }
  /**
   * Stop the loading animation immediately
   */
  stopNow(){
    this.store.dispatch(toggleLoading({loading: false}));
  }
  /**
   * Start and stop loading animation for given time
   * @param ms
   */
  delay(ms:number){

  }

  /**
   * Set the defult delay time
   * @param delay
   */
  setDelay(delay:number){
    this.delayTime = delay;
  }
  getDelay(){
    return this.delayTime;
  }

  /**
   * Navigate to route with loading animation
   * @param route
   */
  public navTo(route:string){
    this.store.dispatch(toggleLoading({loading: true}));
    setTimeout(() => {
      this.store.dispatch(toggleLoading({loading: false}));
      this.router.navigate([route]);
    }, this.delayTime);
  }
}
