import {Component, Inject, OnInit} from '@angular/core';
import {UserCardSize} from "../../../shared/components";
import {Subscription} from "rxjs";
import {
  Config,
  getConfig,
  getLookups,
  initialConfigState,
  initialLookupsState,
  Lookups,
} from "../../../redux";
import {FormControl, FormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {UtilService} from "../../../shared/services";
import {ModalFunction} from "../../models/kyc";

@Component({
  selector: 'app-kyc-person-select-modal',
  templateUrl: './kyc-person-select-modal.component.html',
  styleUrls: ['./kyc-person-select-modal.component.scss']
})
export class KycPersonSelectModalComponent implements OnInit {

  protected readonly userCardSize = UserCardSize;
  protected readonly ModalFunction = ModalFunction;

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;

  form = new FormGroup({
    people: new FormControl(),
    level: new FormControl(),
  });

  constructor(public dialogRef: MatDialogRef<KycPersonSelectModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { people: any, level: string },
              private store: Store,
              private toastr: ToastrService,
              public util: UtilService
  ) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });

  }

  ngOnInit(): void {
    if (!!this.data.people) {
      this.form.patchValue(this.data);
    }
    console.log(this.data.level);
  }

  submit(): void {
    // do nothing
  }

  addNewPerson() {
    this.dialogRef.close({function: this.ModalFunction.ADD, person: null});
  }

  editPerson(person: any) {
    this.dialogRef.close({function: this.ModalFunction.EDIT, person: person});
  }

  selectPerson(person: any) {
    if (this.isMoreInfoRequired(person?.['kycPersonType'])) {
      this.toastr.warning("More information is required for this person!", $localize`:@@companyName:Rondeivu`);
      this.editPerson(person);
    } else {
      this.dialogRef.close({function: this.ModalFunction.SELECT, person: person});
    }
  }

  isMoreInfoRequired(type: string): boolean {
    switch (this.data.level) {
      case 'BASIC':
        return !(['BASIC', 'LONG', 'FULL', 'EXTENDED'].includes(type));
      case 'LONG':
        return !(['LONG', 'FULL', 'EXTENDED'].includes(type));
      case 'FULL':
        return !(['FULL', 'EXTENDED'].includes(type));
      case 'EXTENDED':
        return !(['EXTENDED'].includes(type));
      default:
        return true;
    }
  }
}
