import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Location} from '@angular/common';
import {Store} from "@ngrx/store";
import {
  Config,
  fetchTasks,
  getConfig, getLookups,
  getSelectedBusinessUnit,
  initialConfigState, initialLookupsState, Lookups,
  showDealModal,
  showMandateModal,
  toggleLoading,
  updateDealId,
  updateEditingBusinessUnit,
} from "../../../redux";
import {DealService} from "../../services";
import {IDealCard} from "../../../shared/models";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {IBusinessUnit} from "../../../business-units/models";
import {ToastrService} from "ngx-toastr";
import {Subscription} from "rxjs";
import {addDeals} from "../../../redux/stores/deal/deal.actions";
import {UtilService} from "../../../shared/services";
import {ILookup} from "../../../../models";
import {toggleNavigating} from "../../../redux/stores/config/config.actions";
import {DealModalComponent} from "../deal-modal/deal-modal.component";
import {ModalSize} from "../../../shared/services/util/util.service";
import {MatDialog} from "@angular/material/dialog";


enum DealView {
  CARD,
  TABLE
}

@Component({
  selector: 'app-deal-list',
  templateUrl: './deal-list.component.html',
  styleUrls: ['./deal-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DealListComponent implements OnInit, OnDestroy {
  lookup$: Subscription = new Subscription();
  config$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  route$: Subscription = new Subscription();

  DealView = DealView;
  filterView = ['LIVE'];
  filterList: ILookup[] = [];
  pageView: DealView = DealView.CARD;
  dataSource: MatTableDataSource<IDealCard> = new MatTableDataSource();

  dealsList: IDealCard[] = [];
  filteredDeals: IDealCard[] = [];
  sortedDeals: IDealCard[] = [];
  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;
  columnsToDisplay = ['businessUnit', 'title', 'dealSize', 'secType', 'status', 'closeType', 'closeDate', 'created', 'edit'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: IDealCard | null = {} as IDealCard;
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  constructor(
    public util: UtilService,
    private store: Store,
    private toastr: ToastrService,
    private dealService: DealService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private dialog: MatDialog
  ) {

    this.route$ = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        const status = this.route.snapshot.queryParamMap.get('status')?.toUpperCase();
        this.filterView = status?.split(',') || ['LIVE'];
        this.filterCards();
      }
    });

    this.config$ = this.store.select(getConfig).subscribe((configState: any) => {
      this.config = configState;
    });

    this.lookup$ = this.store.select(getLookups).subscribe((lookups: any) => {
      this.lookups = lookups;
      this.filterDealWorkflowListByProfile();
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      if (!!bu && !!bu.businessUnitId) {
        this.subscribeToDatasource();
        this.filterDealWorkflowListByProfile();
      }
    });
  }

  ngOnInit(): void {
    this.store.dispatch(updateEditingBusinessUnit({businessUnit: {} as IBusinessUnit}));
    const status = this.route.snapshot.queryParamMap.get('status')?.toUpperCase();
    this.filterView = status?.split(',') || ['LIVE'];
    this.filterCards();
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.route$.unsubscribe();
  }

  subscribeToDatasource() {
    console.log("Fetching Deals...");
    this.store.dispatch(updateDealId({dealId: ''}));
    this.store.dispatch(toggleLoading({loading: true}));
    //fetch the deals
    this.dealService.getDeals().subscribe({
      next: (deals: IDealCard[]) => {
        //todo ngrx entities should only be cached once
        this.store.dispatch(addDeals({deals: deals}));
        //set the table data
        this.dealsList = deals;
        this.filterCards();
        this.dataSource = new MatTableDataSource(this.dealsList);
        this.initDataSource();
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: err => {
        this.toastr.error(" Unable to get deals!", $localize`:@@companyName:Rondeivu`);
        this.store.dispatch(toggleLoading({loading: false}));
        // todo Reload the nav items and tasks
        // if (err.status == 403) {
        //   this.util.getUserNavItems().then(() => {
        //     console.log('Fetching tasks...');
        //     this.store.dispatch({type: fetchTasks});
        //   });
        // }
      }
    });
  }

  initDataSource() {
    this.dataSource.filterPredicate = (data: IDealCard, filter: string) => {
      const lc_filter = filter.toLowerCase();
      let BU = !!data.businessUnit.displayName ? data.businessUnit.displayName.toLowerCase().includes(lc_filter) : false;
      let SL = !!data.businessUnit.slug ? data.businessUnit.slug?.toLowerCase().includes(lc_filter) : false;
      let PN = !!data.projectName ? data.projectName.toLowerCase().includes(lc_filter) : false;
      let AN = !!data.assetName ? data.assetName.toLowerCase().includes(lc_filter) : false;

      return BU || PN || AN || SL;
    };
    if (!!this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  viewDeal(deal: IDealCard) {
    this.store.dispatch(updateDealId({dealId: deal.id}));
    this.store.dispatch(toggleNavigating({navigating: true}));
    setTimeout(() => {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/deals/' + deal.id]).then(() => {
        this.store.dispatch(toggleNavigating({navigating: false}));
      });
    }, 1000);
  }

  viewHistory(deal: IDealCard) {
    this.store.dispatch(toggleNavigating({navigating: true}));
    setTimeout(() => {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/history/deals'], {queryParams: {id: deal.id}}).then(() => {
        this.store.dispatch(toggleNavigating({navigating: false}));
      });
    }, 1000);
  }

  filterCards() {
    // none selected so select all
    if (this.filterView.length == 0) {
      this.filterView = this.filterList.map((lookup: ILookup) => {
        return lookup.key.toUpperCase();
      });
    }
    const spoofUrl = ('/' + this.config.selected_business_unit.slug + '/deals?status=' + this.filterView.join(',')).toLowerCase();
    this.location.go(spoofUrl);

    this.filteredDeals = this.dealsList.filter((deal: IDealCard) => {
      return this.filterView.includes(deal.dealWorkflow);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * Opens the deal modal on this page. Note, this dialog is required to be different from the deal modal that is
   * opened by the store action because the successful post must reload all the deals on the page.
   */
  openDealModal() {
    const dealModalRef = this.dialog.open(DealModalComponent, {
      width: this.util.getModalWidth(ModalSize.SMALL),
      disableClose: true
    });

    dealModalRef.afterClosed().subscribe((result: any) => {
      if (!!result) {
        console.log("Creating deal...");
        this.dealService.addDeal(result).subscribe({
          next: (res: IDealCard) => {
            this.subscribeToDatasource();
            this.filterView = ['PENDING', 'DECLINED', 'ONBOARDING'];
            this.filterCards();
            this.store.dispatch({type: fetchTasks});
            this.toastr.success("Deal Created!", $localize`:@@companyName:Rondeivu`);
          }, error: (err: any) => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to create deal!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  /**
   * Opens the mandate modal by dispatching the store action.
   */
  openMandateModal() {
    this.store.dispatch({type: showMandateModal});
  }

  //sort
  sortData(sort: Sort) {
    const data = this.dealsList.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedDeals = data;
      return;
    }

    this.sortedDeals = data.sort((a: IDealCard, b: IDealCard) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'projectName':
          return compare(a.projectName || '', b.projectName || '', isAsc);
        case 'displayName':
          return compare(a.businessUnit?.displayName || '', b.businessUnit?.displayName || '', isAsc);
        case 'closeDate':
          return compare(a.closeDate, b.closeDate, isAsc);
        case 'created':
          return compare(a.created, b.created, isAsc);
        case 'secDealType':
          return compare(a.secDealType, b.secDealType, isAsc);
        case 'dealCloseType':
          return compare(a.dealCloseType, b.dealCloseType, isAsc);
        case 'dealWorkflow':
          return compare(a.dealWorkflow, b.dealWorkflow, isAsc);
        case 'fundraiseSize':
          return compare(a.fundraiseSize, b.fundraiseSize, isAsc);
        default:
          return 0;
      }
    });

    this.dataSource = new MatTableDataSource(this.sortedDeals);
    this.initDataSource();
  }

  private filterDealWorkflowListByProfile() {
    switch (this.config.selected_business_unit.businessUnitType.toUpperCase()) {
      case 'ADMIN':
        this.filterList = this.lookups.dealWorkflowStatus;
        break;
      case 'ISSUER':
        this.filterList = this.lookups.dealWorkflowStatus.filter((status: ILookup) => {
          return ['LIVE', 'PENDING', 'ONBOARDING', 'DECLINED', 'CLOSED'].includes(status.key);
        });
        break;
      case 'INVESTOR':
        this.filterList = this.lookups.dealWorkflowStatus.filter((status: ILookup) => {
          return ['LIVE', 'CLOSED'].includes(status.key);
        });
        break;
    }
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}



