import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {Store} from "@ngrx/store";
import {RondeivuHttpClient} from "../../handlers";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AttestationService {
  private readonly BASE_URL = environment.rondeivu_api;
  private readonly FRAGMENT = '/attestation';

  constructor(private store: Store, private http: RondeivuHttpClient) {
  }

  /**
   * Get the attestations
   */
  getAttestation(): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT);
  }

  /**
   * Sign the terms and conditions
   */
  signAttestation():Observable<any>{
    return this.http.post(this.BASE_URL + this.FRAGMENT, '');
  }
}
