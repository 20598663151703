import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {RondeivuHttpClient} from "../../handlers";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private readonly BASE_URL = environment.rondeivu_api;
  private readonly FRAGMENT = '/appconfig';
  private readonly TASKS_FRAGMENT = '/tasks';
  private readonly CONTACT_US_FRAGMENT = '/contact-us';
  private readonly NAV_FRAGMENT = '/nav';
  private readonly DEAL_FRAGMENT = '/deal';
  private readonly EMPLOYEE_FRAGMENT = '/company';

  constructor(private http: RondeivuHttpClient) {
  }

  /**
   * Fetch the users tasks
   */
  getTasks(): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.TASKS_FRAGMENT);
  }

  /**
   * Fetch the rondeivu contact information for the user
   */
  getContactInfo(): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.CONTACT_US_FRAGMENT);
  }

  /**
   * Fetch the navigation items
   */
  getNavigationItems(): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.NAV_FRAGMENT);
  }

  /**
   * Fetch the deal tabs by deal id
   * @param dealId the deal id
   */
  getDealTabsByDealId(dealId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.DEAL_FRAGMENT + '/' + dealId);
  }

  /**
   * Fetch the employee tabs by employee id
   * @param employeeId the deal id
   */
  getEmployeeTabsByDealId(employeeId: string): Observable<any> {
    //TODO the employee tabs need to be provided based on the employee
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.EMPLOYEE_FRAGMENT + '/' + employeeId);
  }
}
