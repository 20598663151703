export interface IApproval {
  id: string;
  businessUnit: any;
  mandates: any[];
  deals: any[];
}

export interface IApprovalRequest {
  id: string;
  type: 'deal' | 'mandate' | 'businessunit';
  status: string;
}

export enum ApprovalType {
  ISSUER = "issuer",
  INVESTOR = "investor"
}
