import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {RondeivuHttpClient} from "../../handlers";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ViewerService {

  private readonly BASE_URL = environment.rondeivu_api;
  private readonly FRAGMENT = '/documents';
  private readonly NDA_FRAGMENT = '/nda';
  private readonly VIEW_NDA_FRAGMENT = '/view-nda-template';
  private readonly DOWNLOAD = '/download';
  private readonly VIEW = '/view';

  constructor(private store: Store, private http: RondeivuHttpClient) { }

  /**
   * Download the document
   * @param id
   */
  downloadDocument(id: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.DOWNLOAD + '/' + id, {responseType: 'blob'});
  }

  /**
   * View the document
   * @param id
   */
  viewDocument(id: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.VIEW + '/' + id, {responseType: 'blob'});
  }

  /**
   * Get the Rondeivu NDA template
   * @param model
   */
  viewNdaTemplate(model: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.NDA_FRAGMENT + this.VIEW_NDA_FRAGMENT + "?model=" + model, {responseType: 'blob'});
  }
}
