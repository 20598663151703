import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {IDealCard} from "../../../shared/models";
import {MatTableDataSource} from "@angular/material/table";
import {
  Config,
  getConfig,
  getDealSubscription,
  getSelectedBusinessUnit,
  getSelectedDeal,
  initialConfigState, toggleLoading, updateDealSubscription
} from "../../../redux";
import {IDealSubscription} from "../../models";
import {IBusinessUnit} from "../../../business-units/models";
import {DealService, SubscriptionService} from "../../services";
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {UtilService} from "../../../shared/services";
import {LockInModalComponent} from "../lock-in-modal/lock-in-modal.component";
import {ModalSize} from "../../../shared/services/util/util.service";
import {DeleteConfirmationModalComponent} from "../../../shared/components";
import {NotesModalComponent} from "../notes-modal/notes-modal.component";
import {
  DealSubscriptionSubdocModalComponent
} from "../deal-subscription-subdoc-modal/deal-subscription-subdoc-modal.component";

@Component({
  selector: 'app-deal-subscription',
  templateUrl: './deal-subscription.component.html',
  styleUrls: ['./deal-subscription.component.scss']
})
export class DealSubscriptionComponent implements OnInit {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  subscriptionItem$: Subscription = new Subscription();
  selectedDeal$: Subscription = new Subscription();
  selectedDeal: IDealCard = {} as unknown as IDealCard;

  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  matchesColsToDisplay = ['businessUnit', 'amount', 'date', 'status', 'notes', 'actions'];
  config: Config = initialConfigState;

  subscription: IDealSubscription = {} as unknown as IDealSubscription;
  reason: string = '';
  numbers = Array(11).fill(11).map((x, i) => i);

  bu: IBusinessUnit = {} as unknown as IBusinessUnit;


  constructor(
    private dealService: DealService,
    private subscriptionService: SubscriptionService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private toastrService: ToastrService,
    public dialog: MatDialog,
    public util: UtilService
  ) {
    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.bu = Object.assign({}, bu);
    });

    this.selectedDeal$ = this.store.select(getSelectedDeal).subscribe((deal: IDealCard) => {
      if (this.selectedDeal.id != deal.id) {
        this.selectedDeal = deal;
        this.subscribeToDatasource();
      } else {
        this.selectedDeal = deal;
      }
    });

    this.subscriptionItem$ = this.store.select(getDealSubscription).subscribe((dealSubscription: IDealSubscription) => {
      this.subscription = dealSubscription;
      this.dataSource = new MatTableDataSource(this.subscription.subscriptionItems);
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedDeal$.unsubscribe();
    this.subscriptionItem$.unsubscribe();
  }

  subscribeToDatasource(): void {
    this.getSubscription();
  }

  getSubscription() {
    console.log("Fetching Subscription...");
    // this.store.dispatch(toggleLoading({loading: true}));
    this.subscriptionService.getSubscription().subscribe({
      next: (subscription: any) => {
        this.subscription = subscription;
        this.store.dispatch(updateDealSubscription({dealSubscription: subscription}));
        this.dataSource = new MatTableDataSource(this.subscription.subscriptionItems);
        // this.store.dispatch(toggleLoading({loading: false}));
      }, error: err => {
        // this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.error("Unable to get subscription!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  changeLockStatus(locked: boolean, silent: boolean) {
    let payload = {
      isLocked: locked,
      silentPoolLockChange: silent
    };
    this.store.dispatch(toggleLoading({loading: true}));
    this.subscriptionService.changeLockStatus(payload).subscribe((res: any) => {
      this.subscription.issuerPoolIsLocked = locked;
      this.store.dispatch(toggleLoading({loading: false}));
      this.toastrService.success(locked ? "Pool Locked!" : "Pool Unlocked!", $localize`:@@companyName:Rondeivu`);
    }, err => {
      this.store.dispatch(toggleLoading({loading: false}));
      this.toastrService.error("Unable to change lock status!", $localize`:@@companyName:Rondeivu`);
    });
  }

  lock(element: any) {
    const lockRef = this.dialog.open(LockInModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      data: {},
      disableClose: true
    });

    lockRef.afterClosed().subscribe(result => {
      // console.log("Dialog output:", result);
      if (!!result) {
        let silent = !result.notify;
        this.changeLockStatus(true, silent);
      }
    });
  }

  unlock(element: any) {
    this.changeLockStatus(false, true);
  }

  save(element: any) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.subscriptionService.updateSubscription(element).subscribe({
      next: (res: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.success("Subscription Updated!", $localize`:@@companyName:Rondeivu`);
      }, error: err => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastrService.error("Unable to update subscription!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  delete(element: any) {
    const delRef = this.dialog.open(DeleteConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      data: element
    });

    delRef.afterClosed().subscribe(result => {
      // console.log("Dialog output:", result);
      if (!!result) {
        this.store.dispatch(toggleLoading({loading: true}));
        this.subscriptionService.deleteSubscription(result).subscribe({
          next: (res: any) => {
            //replace in list
            if (!!result) {
              let idx = -1;
              for (let i = 0; i <= this.subscription.subscriptionItems?.length - 1; i++) {
                if (this.subscription.subscriptionItems[i].id == result.id) {
                  idx = i;
                  break;
                }
              }
              this.subscription.subscriptionItems.splice(idx, 1);
              this.dataSource = new MatTableDataSource(this.subscription.subscriptionItems);
            }
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastrService.success("Subscription Removed!", $localize`:@@companyName:Rondeivu`);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastrService.error("Something went wrong!");
          }
        });
      }
    });
  }

  showNotes(notes: string) {
    const delRef = this.dialog.open(NotesModalComponent, {
      height: 'auto',
      width: 'auto',
      data: {notes: notes}
    });
  }

  initiateSubscription() {
    const delRef = this.dialog.open(DealSubscriptionSubdocModalComponent, {
      height: 'auto',
      width: 'auto',
      data: null
    });

    delRef.afterClosed().subscribe(result => {
      if (!!result) {

      }
    });
  }


}
