<div class="rm-header">
  <span>{{ data.isEditing ? 'Edit' : 'Initiate' }} KYC</span>
</div>


<form [formGroup]="userForm" (ngSubmit)="submit()">
  <mat-dialog-content>
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-11">
    <span>
      <b>Exciting changes are underway at Rondeivu!</b><br/><br/> To ensure the highest standards of security and compliance,
      we are introducing a new and improved <b>Know Your Customer (KYC)</b> process.<br/><br/> Rest assured, this enhancement is designed
      to make the KYC experience smoother and more efficient for you. Stay tuned for further details on the specific
      requirements and steps to follow. We appreciate your ongoing trust and look forward to continuing to provide
      you with a secure and seamless experience on our platform.
    </span>
      </div>
    </div>
    <!--      row-->
    <div class="row justify-content-center" *ngIf="shouldShowTypesFor('investor')" style="padding-top: 25px;">
      <div class="col-11">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Type</mat-label>
          <mat-select formControlName="kycProfileSubType" [required]="true" (selectionChange)="getKycDocuments($event)">
            <mat-option *ngFor="let r of lookups.kycInvestorTypes" [value]="r.key">
              {{ r.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!--      row-->
    <div class="row justify-content-center" *ngIf="shouldShowTypesFor('issuer')" style="padding-top: 25px;">
      <div class="col-11">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Type</mat-label>
          <mat-select formControlName="kycProfileSubType" [required]="true" (selectionChange)="getKycDocuments($event)">
            <mat-option *ngFor="let r of lookups.kycIssuerTypes" [value]="r.key">
              {{ r.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!--      row-->
    <div class="row justify-content-center" style="padding-bottom: 25px;">
      <div class="col-11">
        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Entity Name</mat-label>
          <input id="ename" matInput type="text" placeholder="Type Here..." formControlName="entityName"
                 [required]="true"/>
        </mat-form-field>
      </div>
    </div>
    <!--      row-->
    <div class="row justify-content-center" style="padding-bottom: 20px;" hidden>
      <div class="col-11">
        <span>Documents to Download:</span><br/>
        <span style="font-weight: lighter;" *ngIf="!userForm.get('kycProfileSubType')?.value">Please select a KYC type to view required documents.</span>
        <span style="font-weight: lighter;" *ngIf="!!userForm.get('kycProfileSubType')?.value">Please click to download these documents below, they are required to be submitted for KYC:</span>
      </div>
    </div>
    <!--      row-->
    <div class="row justify-content-center" style="padding-bottom: 50px;" *ngIf="loading" hidden>
      <div class="col-11 text-center">
        <mat-spinner [diameter]="36" color="accent"></mat-spinner>
      </div>
    </div>
    <!--      row-->
    <div class="row justify-content-center" style="padding-bottom: 50px;" *ngIf="!loading" hidden>
      <div class="col-11">
        <ul>
          <li *ngFor="let d of docs"><a [href]="d.url" target="_blank">{{ d.name }}</a></li>
        </ul>
      </div>
    </div>
  </mat-dialog-content>

  <!--      row-->
  <mat-dialog-actions class="row justify-content-center">
    <div class="col-6 text-start">
      <button mat-raised-button type="button" (click)="dialogRef.close()">CANCEL</button>
    </div>
    <div class="col-6 text-end">
      <button mat-raised-button color="primary" type="submit">SUBMIT</button>
    </div>
  </mat-dialog-actions>
</form>
