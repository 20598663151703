import {Component, Inject, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {
  Config,
  getConfig,
  getLookups,
  initialConfigState,
  initialLookupsState,
  Lookups,
  toggleLoading
} from "../../../redux";
import {FormControl, FormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {UtilService} from "../../../shared/services";
import {UuidService} from "../../../../services";
import {IKycLegalEntity} from "../../models/i-kyc-legal-entity";
import {EntityKycBusinessService} from "../../services/entity-kyc-business.service";
import {Level} from "../../models/kyc";

@Component({
  selector: 'app-kyc-business-modal',
  templateUrl: './kyc-business-modal.component.html',
  styleUrls: ['./kyc-business-modal.component.scss']
})
export class KycBusinessModalComponent implements OnInit {

  protected readonly Level = Level;

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;

  form = new FormGroup({
    id: new FormControl(''),
    kycLegalEntityType: new FormControl({value: 'BASIC', disabled: this.data.disabled}),
    rondeivuStatus: new FormControl({value: '', disabled: this.data.disabled}),
    businessUnitId: new FormControl(''),
    businessUnitEntityKycId: new FormControl(''),

    legalName: new FormControl({value: '', disabled: this.data.disabled}),
    streetAddress: new FormControl({value: '', disabled: this.data.disabled}),
    city: new FormControl({value: '', disabled: this.data.disabled}),
    stateOrProvince: new FormControl({value: '', disabled: this.data.disabled}),
    countryId: new FormControl({value: '', disabled: this.data.disabled}),
    zipPostalCode: new FormControl({value: '', disabled: this.data.disabled}),
    legalDomicile: new FormControl({value: '', disabled: this.data.disabled}),

    natureoftheBusiness: new FormControl({value: '', disabled: this.data.disabled}),
    businessSourceofFund: new FormControl({value: '', disabled: this.data.disabled}),

    taxIdType: new FormControl({value: '', disabled: this.data.disabled}),
    taxIdNumber: new FormControl({value: '', disabled: this.data.disabled}),

    kycLegalEntityProofofDocuments: new FormControl(),
    legalEntityProofofDocumentType: new FormControl({value: '', disabled: this.data.disabled}),
    legalEntityProofofDocumentOtherName: new FormControl({value: '', disabled: this.data.disabled}),
  });

  constructor(public dialogRef: MatDialogRef<KycBusinessModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { business: IKycLegalEntity, level: string, disabled: boolean },
              private store: Store,
              private toastr: ToastrService,
              private legalEntityService: EntityKycBusinessService,
              public util: UtilService,
              private uuid: UuidService) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });
  }

  ngOnInit(): void {
    if (!!this.data.business) {
      this.form.patchValue(this.data.business);
    } else {
      this.form.get('businessUnitId')?.setValue(this.config.selected_business_unit.businessUnitId);
    }

    if (!!this.data.level) {
      this.form.get('kycLegalEntityType')?.setValue(this.data.level);
    }
  }

  submit(): void {
    this.dialogRef.close(this.form.getRawValue());
  }

  isBusiness(type: string) {
    return this.form.get('kycLegalEntityType')?.value == type;
  }

  changeStatus() {
    this.store.dispatch(toggleLoading({loading: true}));
    let id = this.form.get('id')?.value || '';
    let status = this.form.get('rondeivuStatus')?.value || '';
    this.legalEntityService.updateLegalEntityStatus(id, {
      kycLegalEntityId: id,
      kycLegalEntityRondeivuStatus: status
    }).subscribe({
      next: (res: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Status Updated!", $localize`:@@companyName:Rondeivu`);
      },
      error: (err) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Could not update status!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  /**
   * Add document(s) to the legal entity
   * @param event
   * @param property
   */
  addDocuments(event: any) {
    console.log(event);
    if (!!event && !!event.target && !!event.target.files) {
      if (!!this.data.business) {
        let files = event.target.files;
        let id = this.form.get('id')?.value || '';
        let formData = new FormData();
        // get the field cache and init if null
        let cache: any[] = this.form.get('kycLegalEntityProofofDocuments')?.value;
        if (!cache) {
          cache = [];
        }
        // append files to the PUT form
        formData.append('kycLegalEntityId', id);
        for (let i = 0; i <= files.length - 1; i++) {
          formData.append('kycLegalEntityProofofDocuments', files[i]);
        }
        // proof docs
        this.store.dispatch(toggleLoading({loading: true}));
        this.legalEntityService.uploadProofOfDocument(id, formData).subscribe({
          next: (res: any) => {
            // add the response to the collection
            cache.push(res);
            this.form.get('kycLegalEntityProofofDocuments')?.setValue(cache);
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("Document Added!", $localize`:@@companyName:Rondeivu`);
          },
          error: (err) => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Could not add file!", $localize`:@@companyName:Rondeivu`);
          }
        });
        // LE EDIT - add files
        this.legalEntityService.uploadProofOfDocument(event.target.files, 'kycLegalEntityProofofDocuments');
      } else {
        // LE ADD - add files
        let docs = this.form.get('kycLegalEntityProofofDocuments')?.value;
        if (!docs) {
          docs = [];
        }
        for (let i = 0; i <= event.target.files.length - 1; i++) {
          docs.push(event.target.files[i]);
        }
        this.form.get('kycLegalEntityProofofDocuments')?.setValue(docs);
      }
    }
  }

  /**
   * Download a document
   * @param docId
   * @param fileName
   */
  downloadDoc(docId: string, fileName: string) {
    // download
    this.store.dispatch(toggleLoading({loading: true}));
    this.legalEntityService.getProofOfDocument(docId).subscribe({
      next: (res: any) => {
        let filename = fileName;
        let blob: Blob = res as Blob;
        let a = document.createElement('a');
        a.download = filename;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Document Downloaded!", $localize`:@@companyName:Rondeivu`);
      },
      error: (err) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Could not download file!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  /**
   * Delete a doc
   * @param docId
   * @param idx
   */
  deleteDoc(docId: string, idx: number) {
    // download
    this.store.dispatch(toggleLoading({loading: true}));
    this.legalEntityService.deleteProofOfDocument(docId).subscribe({
      next: (res: any) => {
        let docs: any[] = this.form.get('kycLegalEntityProofofDocuments')?.value;
        docs.splice(idx, 1);
        this.form.get('kycLegalEntityProofofDocuments')?.setValue(docs);
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Document Removed!", $localize`:@@companyName:Rondeivu`);
      },
      error: (err) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Could not download file!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

}
