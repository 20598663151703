<div class="rm-header">
  <span>Sub Doc Review/Edit & Approve for Signature(s)</span>
</div>
<form [formGroup]="form" (submit)="submit()">

  <div class="row" style="padding-top: 25px;">
    <div class="col-6">
      <span style="font-weight: bold;">Sub Doc Review/Edit & Approve for Signature(s)</span>
    </div>
    <div class="col-6 text-end">
      <button mat-raised-button type="button" color="primary"
              (click)="downloadPdf()"
      >
        DOWNLOAD SUB DOC PDF
      </button>
    </div>
  </div>
  <div class="row" style="padding-bottom: 25px;">
    <div class="col-12">
      <em style="font-weight: bold;">This is where you can review,edit, and approve a sub doc for signing:</em>
    </div>
  </div>
  <mat-dialog-content>
    <!--  HTML TOP PADDING ON THIS DIV -->
    <div class="row" style="height: 1000px; padding: 25px 0;">
      <div class="col-12">
        <div *ngFor="let field of splitFieldHtml; index as index">
          <span [innerHTML]="getSafeHtml(field)"></span>
          <app-deal-subscription-subdoc-form-field [field]="matches ? matches[index]: ''"
                                                   [fieldList]="fieldsList"
                                                   (onInputChange)="inputChange($event)"
                                                   (onCheckChange)="checkChange($event)"
                                                   (onRadioChange)="radioChange($event)"
                                                   (onSelectChange)="selectChange($event)"
          ></app-deal-subscription-subdoc-form-field>
          <!--  HTML BOTTOM PADDING FOR LAST ELEMENT -->
          <div *ngIf="index == splitFieldHtml.length - 1" style="padding:50px;">&nbsp;</div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="justify-content: space-between; justify-items: end;">
    <div>
      <button mat-raised-button type="button" (click)="dialogRef.close()">CLOSE</button>&nbsp;
    </div>
    <div style="display: flex; justify-items: end; justify-content: end;">
      <button mat-raised-button type="button" color="primary" (click)="saveFields(false)">SAVE DRAFT</button>&nbsp;
      <button mat-raised-button type="button" color="primary" (click)="saveFields(true)">SAVE & SEND FOR INVESTOR
        SIGNATURES
      </button>
    </div>
  </mat-dialog-actions>
</form>
