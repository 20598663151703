<div>
  <div class="row" style="padding-top: 50px;">
    <div class="col-12 text-center">
      <h1 id="title">LOGIN</h1>
      <h3>Welcome</h3>
    </div>
  </div>
  <br/>

  <div class="row justify-content-center">
    <div class="col-11 col-sm-11 col-md-8 col-lg-6 col-xl-6 col-xxl-4">
      <app-glass-card>
        <!--      sign in-->
        <div class="card-body" *ngIf="curPage==0">
          <form [formGroup]="userForm"
                (ngSubmit)="signInEmailAndPass()">
            <div class="row justify-content-center" style="padding-top: 20px;">
              <div class="col-11">
                <mat-form-field class="form-field">
                  <mat-label>Email</mat-label>
                  <input id="email" formControlName="email" type="email" matInput
                         required pattern=".+@.+\..+"/>
                  <mat-error *ngIf="userForm.get('email')?.hasError('required')"
                  >Email is required
                  </mat-error
                  >
                  <mat-error *ngIf="userForm.get('email')?.hasError('email')"
                  >Please enter a valid email address
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-11">
                <mat-form-field id="pass-field" class="form-field">
                  <mat-label>Password</mat-label>
                  <input id="password" formControlName="password" matInput [type]="!showPassword ? 'password' : 'text'"
                         required/>
                  <span matSuffix (click)="showPassword = !showPassword"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="!showPassword"
                        style="cursor: pointer;">
                  <mat-icon>{{!showPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                </span>
                  <mat-error *ngIf="userForm.get('password')?.invalid">
                    Password is invalid.
                  </mat-error>
                  <!--                <mat-error *ngIf="userForm.get('password')?.invalid">-->
                  <!--                  Password must be a combination of lower-case, upper-case, numbers, special characters, and at-->
                  <!--                  least 8 characters long.-->
                  <!--                </mat-error>-->
                </mat-form-field>
              </div>
            </div>
            <div class="row justify-content-center" style="padding-bottom: 5px;">
              <div class="col-5">
                <div [hidden]="!allowRemember">
                  <input formControlName="remember" id="rm" type="checkbox"/>&nbsp; Remember me
                </div>
              </div>
              <div class="col-6 text-end">
                <span class="link" (click)="loader.navTo(FORGOT_PASS_PATH)">Forgot Password</span>
              </div>
            </div>
            <br>
            <div class="row justify-content-center">
              <!--            <div class="col-5">&nbsp;</div>-->
              <div class="col-11">
                <button mat-raised-button type="submit" class="login"
                        [disabled]="!userForm.valid">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                        *ngIf="loading"></span>
                  LOGIN
                </button>
              </div>
            </div>
            <br>
          </form>
        </div>

        <!--    email verification start -->
        <div class="card-body" *ngIf="curPage == 1">
          <app-code-input [title]="'Email Address  Verification'" [loading]="loading" (onReset)="requestEmailCode()"
                          (onPasteError)="pasteError('Email Code Error: ',$event);"
                          (onNext)="confirmEmailSignUp($event)">
            <span>We sent a code via email to <span style="color: dodgerblue">{{ userForm.get('email')?.value }}</span>, please enter the code above to verify your email.</span>
          </app-code-input>
        </div>
        <!--    email verification end-->

        <!--    sms verification start -->
        <div class="card-body" *ngIf="curPage == 2">
          <app-code-input [title]="'Phone Verification'" [loading]="loading" (onReset)="requestSmsCode()"
                          (onPasteError)="pasteError('Phone Code Error: ',$event);"
                          (onNext)="confirmMFACode($event)">
            <span>We sent a code via SMS to <span style="color: dodgerblue">{{ userPhone }}</span>, please enter the code above to verify your phone number.</span>
          </app-code-input>
        </div>
        <!--    sms verification end-->

        <!--      new password -->
        <div class="card-body" *ngIf="curPage == 3">
          <div class="row justify-content-center" style="padding-top: 25px;">
            <div class="col-11">
              <span>Please enter your NEW password below:</span>
            </div>
          </div>
          <br><br>
          <form [formGroup]="newPasswordForm"
                (ngSubmit)="setNewPassword()">
            <div class="row justify-content-center" style="padding-bottom: 25px;">
              <div class="col-11">
                <mat-form-field class="form-field">
                  <mat-label>Password</mat-label>
                  <input id="new-password" matInput formControlName="newPassword"
                         [type]="!showPassword ? 'password' : 'text'"/>
                  <span matSuffix (click)="showPassword = !showPassword"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="!showPassword"
                        style="cursor: pointer;">
                  <mat-icon>{{!showPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                </span>
                  <mat-error>Password must be a combination of lower-case, upper-case, numbers, special characters, and
                    at
                    least 8 characters long.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <br/>
            <div class="row justify-content-end">
              <div class="col-11 text-end">
                <button type="submit" mat-raised-button class="rnd-btn" color="primary"
                        [disabled]="newPasswordForm.invalid">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                        *ngIf="loading"></span>
                  SUBMIT
                </button>
              </div>
            </div>
          </form>
        </div>
        <!--      new password end-->
      </app-glass-card>
    </div>
  </div>


  <div class="row" *ngIf="curPage == 0">
    <div id="last" class="col-12 text-center">
      Don't have an account?<br><span class="link" (click)="loader.navTo(REGISTER_PATH)">REGISTER</span>
    </div>
  </div>
</div>
