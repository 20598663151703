import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Config, getConfig, getSelectedBusinessUnit, getSelectedDealId, initialConfigState} from "../../../redux";
import {IBusinessUnit} from "../../../business-units/models";
import {Store} from "@ngrx/store";

@Component({
  selector: 'app-company-data',
  templateUrl: './company-data.component.html',
  styleUrls: ['./company-data.component.scss']
})
export class CompanyDataComponent implements OnInit, OnDestroy {

  config$:Subscription = new Subscription();
  selectedBusinessUnit$:Subscription = new Subscription();
  config = initialConfigState;
  docTypes = ['BUSINESS_UNIT_DOCS'];
  objectId = '';
  selectedBusinessUnit:IBusinessUnit = {} as unknown as IBusinessUnit;

  constructor(private store:Store) {
    this.config$ = this.store.select(getConfig).subscribe((config:Config)=>{
      this.config = config;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu:IBusinessUnit)=>{
      this.selectedBusinessUnit = bu;
      if(!!this.selectedBusinessUnit){
        //assigning this value triggers the on changes in the data room component
        this.objectId = this.selectedBusinessUnit.businessUnitId;
      }
    });
  }

  ngOnInit(): void {
    console.log("Company Data Init...");
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
  }

}
