<ngx-extended-pdf-viewer
  [src]="base64"
  [useBrowserLocale]="true"
  [textLayer]="true"
  [showHandToolButton]="true"
  [showPresentationModeButton]="false"
  [showDownloadButton]="false"
  [showPrintButton]="false"
  [showOpenFileButton]="false"
  [showPropertiesButton]="false"
  [showSidebarButton]="false"
  backgroundColor="#333333"
></ngx-extended-pdf-viewer>







