<div class="rm-header">
  <span>Person Select</span>
</div>
<mat-dialog-content>

  <form [formGroup]="form" (submit)="submit()">
    <div class="row" style="padding-top:25px;">
      <div *ngFor="let element of form.get('people')?.value" style="margin-bottom: 5px; cursor: pointer;"
           (click)="selectPerson(element)">
        <mat-card>
          <div style="display: flex; justify-content: space-between; width: 100%; vertical-align: middle;">
            <div>
              <app-user-card
                [firstName]="element?.['firstName']"
                [lastName]="element?.['lastName']"
                [displayName]="element?.['email']"
                [borderColor]="'transparent'"
                [background]="'#ffffff'"
                [sideColor]="'#002150'"
                [image]="element?.['imageUrl']"
                [size]="userCardSize.MD"
              ></app-user-card>
            </div>
            <div style="padding-top: 10px;">
              <div *ngIf="isMoreInfoRequired(element?.['kycPersonType'])" matTooltip="More information is required"
                   matTooltipPosition="left">
                <mat-icon color="accent">warning</mat-icon>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </form>

</mat-dialog-content>
<mat-dialog-actions>
  <!--      row-->
    <div class="col-6 text-start">
      <button mat-raised-button type="button" (click)="dialogRef.close()">CANCEL</button>
    </div>
    <div class="col-6 text-end">
      <button mat-raised-button type="button" (click)="addNewPerson()" color="primary">ADD NEW</button>
    </div>
</mat-dialog-actions>

