import { Component, OnInit } from '@angular/core';
import {Subscription} from "rxjs";
import {
  Config,
  getConfig,
  getLookups,
  getSelectedBusinessUnit, getSelectedUserId,
  initialConfigState,
  initialLookupsState,
  Lookups,
  toggleLoading
} from "../../../redux";
import {IBusinessUnit} from "../../../business-units/models";
import {FormControl, FormGroup} from "@angular/forms";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {UsersService} from "../../../users/services/users/users.service";

@Component({
  selector: 'app-legal-information',
  templateUrl: './legal-information.component.html',
  styleUrls: ['./legal-information.component.scss']
})
export class LegalInformationComponent implements OnInit {

  config$:Subscription = new Subscription();
  lookup$:Subscription = new Subscription();
  selectedBusinessUnit$:Subscription = new Subscription();
  selectedUserId$:Subscription = new Subscription();

  private config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;
  selectedBusinessUnit:IBusinessUnit = {} as unknown as IBusinessUnit;

  //reactive form
  userForm = new FormGroup({
    id: new FormControl(),
    firstName: new FormControl(),
    lastName: new FormControl(),
    jobTitle: new FormControl(),
    companyName: new FormControl(),
    email: new FormControl(),
    countryCode: new FormControl(),
    mobile: new FormControl(),
    mfaType: new FormControl(),
    phoneVerified: new FormControl(),
    emailVerified: new FormControl(),
    referredByEmail: new FormControl(),
  });

  constructor(private store: Store, private router: Router, private toastr: ToastrService, private usersService: UsersService) {
    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });
    this.lookup$ = this.store.select(getLookups).subscribe(lookups => {
      this.lookups = lookups;
    });
    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe(businessUnit => {
      this.selectedBusinessUnit = businessUnit;
    });
    this.selectedUserId$ = this.store.select(getSelectedUserId).subscribe(userId => {
      if(!!this.selectedBusinessUnit && !!this.selectedBusinessUnit.businessUnitId){
        this.subscribeToDatasource();
      }
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedUserId$.unsubscribe();
  }

  subscribeToDatasource(): void {

  }

  save(){
    this.toastr.warning("Not Implemented!", $localize`:@@companyName:Rondeivu`);
  }

  forcePasswordReset(){
    this.toastr.warning("Not Implemented!", $localize`:@@companyName:Rondeivu`);
  }

}
