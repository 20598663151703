// the store model
export class Theme {
  flag_bg: string = '';
  header_bg: string = '';
  header_fg: string = '';
  subheader_bg: string = '';
  subheader_fg: string = '';
  nav_bg: string = '';
  nav_fg: string = '';
  nav_highlight: string = '';
  content_bg: string = '';
  content_fg: string = '';
  pill_1_bg: string = '';
  pill_1_fg: string = '';
  pill_2_bg: string = '';
  pill_2_fg: string = '';
  pill_3_bg: string = '';
  pill_3_fg: string = '';
  status: {
    approved: string,
    accepted: string,
    auto_accepted: string,
    active: string,
    declined: string,
    closed: string,
    live: string,
    onboarding: string,
    pending: string,
    completed: string,
    not_required: string,
    sent: string,
    suspended: string,
    in_review: string,
    confirmed: string,
    unconfirmed: string,
    not_registered: string,
    force_change_password: string
  } = {
    approved: '',
    accepted: '',
    auto_accepted: '',
    active: '',
    declined: '',
    closed: '',
    live: '',
    onboarding: '',
    pending: '',
    completed: '',
    not_required: '',
    sent: '',
    suspended: '',
    in_review: '',
    confirmed: '',
    unconfirmed: '',
    not_registered: '',
    force_change_password: ''
  };
  tasks: {
    task: string,
    complete: string,
    pending: string,
    info: string
  } = {
    task: '',
    complete: '',
    pending: '',
    info: ''
  };

  constructor(data: any) {
    Object.assign(this, data);
  }
}
