import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {IDealCard} from "../../models";
import {Router} from "@angular/router";
import {Config, getConfig, initialConfigState, toggleLoading} from "../../../redux";
import {Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import {toggleNavigating} from "../../../redux/stores/config/config.actions";


export enum CardSide {
  FRONT, BACK
}

@Component({
  selector: 'app-deal-card',
  templateUrl: './deal-card.component.html',
  styleUrls: ['./deal-card.component.scss'],
  animations: [
    trigger('cardFlip', [
      state('0', style({
        transform: 'none'
      })),
      state('1', style({
        transform: 'rotateY(180deg)'
      })),
      transition('0 => 1', [
        animate('500ms')
      ]),
      transition('1 => 0', [
        animate('500ms')
      ])
    ])
  ]
})
export class DealCardComponent implements OnInit, OnChanges, OnDestroy {

  config$: Subscription = new Subscription();

  @Input() deal: IDealCard = {} as IDealCard;
  @Input() canFlip: boolean = true;
  @Input() canView: boolean = true;
  hovering: boolean = false;
  side: CardSide = CardSide.FRONT;
  config: Config = initialConfigState;

  closedTypes = ['CLOSED', 'DECLINED', 'ARCHIVED'];

  constructor(private router: Router, private store: Store) {
    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes && changes['side']) {
      this.side = changes['side'].currentValue;
    }
    if (!!changes && changes['deal']) {
      this.deal = changes['deal'].currentValue;
    }
  }

  cardClicked() {
    if (this.side === CardSide.FRONT) {
      this.side = CardSide.BACK;
    } else {
      this.side = CardSide.FRONT;
    }
  }

  getImgClass() {
    return this.hovering ? 'img-top-hover' : '';
  }

  getSideClass() {
    return this.side === CardSide.FRONT ? 'front' : 'back';
  }

  navToDeal(id: string) {
    this.store.dispatch(toggleNavigating({navigating: true}));
    this.router.navigate(['/' + this.config.selected_business_unit.slug + '/deals/' + id + '/deal-information']).then(() => {
      this.store.dispatch(toggleNavigating({navigating: true}));
    });
  }

}


