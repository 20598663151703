// the store model
export class CognitoUser {
  id: string = '';
  username: string = '';
  attributes: CognitoAttributes = {} as unknown as CognitoAttributes;

  constructor(data: any) {
    Object.assign(this, data);
  }
}

export interface CognitoAttributes {
  name: string;
  email: string;
  email_verified: boolean;
  given_name: string;
  family_name: string;
  phone_number: string;
  phone_number_verified: boolean;
  sub: string;
}
