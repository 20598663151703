<div class="rm-header">
  <span *ngIf="!data">Add Employee</span>
  <span *ngIf="!!data">Edit Employee</span>
</div>

<div class="container-fluid">
  <!--header-->
  <div class="row justify-content-center" *ngIf="!data">
    <div class="col-12 text-align-c text-center" style="padding-bottom: 40px; padding-top:25px;">
      <span style="font-size: medium;">Please select a user to add as a Rondeivu employee from the dropdown below.<br/> If an employee does not have an account, please create one first.</span>
    </div>
  </div>

  <form [formGroup]="userForm" (ngSubmit)="submitEdit()">
    <!--  body-->
    <div class="row justify-content-center" style="padding-bottom: 25px;">
      <div class="col-12">
        <mat-dialog-content class="mat-typography" style="overflow: hidden;">
          <!--        EMPLOYEE ADD-->
          <div class="row" *ngIf="!data">
            <div class="col-12">
              <mat-form-field class="form-field" appearance="outline" *ngIf="!!userList && userList.length > 0">
                <mat-label>User</mat-label>
                <mat-select id="tur" style="font-size: medium;" [(ngModel)]="selectedUser"
                            [ngModelOptions]="{standalone: true}">
                  <mat-option *ngFor="let u of userList" [value]="u">
                    {{ u.firstName + ' ' + u.lastName + ' - ' + u.email }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <!--        EMPLOYEE ADD END-->
          <!--        EMPLOYEE EDIT-->
          <div class="row form-row" *ngIf="!!data" style="padding-top: 35px;">
            <div class="col-12">
              <!--        user menu button-->
              <app-user-card [firstName]="data.user['firstName'] "
                             [lastName]="data.user['lastName']"
                             [displayName]="data.user['email']"
                             [image]="data.user['imageUrl']"
                             [borderColor]="'transparent'"
                             [background]="'#F1EDE4'"
                             [sideColor]="'#002150'"
              ></app-user-card>
            </div>
            <!--            <div class="col-12" style="padding-top: 25px;">-->
            <!--              <mat-form-field class="form-field" appearance="outline">-->
            <!--                <mat-label>Role</mat-label>-->
            <!--                <mat-select [multiple]="true" id="role" formControlName="roles">-->
            <!--                  <mat-option *ngFor="let r of lookups.rondeivuEmployeeRoleTypes" [value]="r.key"-->
            <!--                              [disabled]="r.key == 'ADMIN'">-->
            <!--                    {{r.value}}-->
            <!--                  </mat-option>-->
            <!--                </mat-select>-->
            <!--              </mat-form-field>-->
            <!--            </div>-->
            <div class="col-12" style="padding-top: 25px;">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Link To Schedule (URL)</mat-label>
                <input id="link" matInput type="text" formControlName="calendarLink"
                       placeholder="i.e. www.workation.com/rondeivu"/>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Public Notes</mat-label>
                <textarea id="notes" matInput formControlName="publicNotes"
                          style="min-height: 90px; max-height: 250px;"></textarea>
              </mat-form-field>
            </div>
            <!--  row-->
            <div class="col-4">
              <mat-label style="font-size: medium;">Country Code</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <mat-select id="cc" formControlName="countryCode">
                  <mat-option *ngFor="let cc of lookups.countryCodes" [value]="cc.key">
                    {{ cc.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-8">
              <mat-label style="font-size: medium;">Phone Number</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input class="form-input" matInput formControlName="mobile" [required]="true"/>
              </mat-form-field>
            </div>
          </div>
          <!--EMPLOYEE EDIT END-->
        </mat-dialog-content>
      </div>
    </div>
    <!--Navigation-->
    <div class="row justify-content-center" mat-dialog-actions>
      <mat-spinner color="primary" *ngIf="userList.length == 0"></mat-spinner>
      <div class="col-6 text-start">
        <button mat-raised-button type="button" (click)="dialogRef.close()">CANCEL</button>
      </div>
      <div class="col-6 text-end" *ngIf="!!userList && (userList.length > 0) && !data">
        <button mat-raised-button color="primary" type="button" (click)="addNewEmployee()"
                [disabled]="!selectedUser.id">SUBMIT
        </button>
      </div>
      <div class="col-6 text-end" *ngIf="!!data">
        <button mat-raised-button color="primary" type="submit">SUBMIT
        </button>
      </div>
    </div>
  </form>
</div>






