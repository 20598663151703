import {AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {
  Config,
  getConfig,
  getEditingBusinessUnit,
  getSelectedDeal,
  getSelectedMandate,
  getThemeState,
  initialConfigState,
  initialThemeState,
  Theme
} from "../../modules/redux";
import {KycService, UtilService} from "../../modules/shared/services";
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {IBusinessUnit} from "../../modules/business-units/models";
import {NotesService} from "../../services";
import {IDealCard} from "../../modules/shared/models";
import {iMandateResponse} from "../../modules/mandates/models";
import {ActionType, INoteItem, INoteCollection, NoteTypes} from "../../models";
import {DeleteConfirmationModalComponent} from "../../modules/shared/components";
import {MatDialog} from "@angular/material/dialog";
import {Action} from "rxjs/internal/scheduler/Action";



@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit, OnDestroy, AfterViewChecked {
  config$: Subscription = new Subscription();
  theme$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  selectedDeal$: Subscription = new Subscription();
  selectedMandate$: Subscription = new Subscription();
  config: Config = initialConfigState;
  theme: Theme = initialThemeState;

  currentCollectionType: NoteTypes = NoteTypes.DEAL;
  ActionType = ActionType;
  noteCollections: INoteCollection[] = [];
  selectedCollection: INoteCollection = {} as INoteCollection;
  notesList: INoteItem[] = [];

  loading = false;
  notePost: string = '';
  noteEdit: string = '';
  isEditing: boolean = false;
  editIdx = 0;

  @ViewChild('scrollMe') private myScrollContainer: ElementRef | undefined;

  constructor(
    private kycService: KycService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private toastrService: ToastrService,
    private notesService: NotesService,
    private dialog: MatDialog,
    public util: UtilService
  ) {
    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.theme$ = this.store.select(getThemeState).subscribe((theme: any) => {
      this.theme = theme;
    });

    this.selectedBusinessUnit$ = this.store.select(getEditingBusinessUnit).subscribe((businessUnit: IBusinessUnit) => {
      if (!!businessUnit && !!businessUnit.businessUnitId) {
        this.currentCollectionType = NoteTypes.BUSINESSUNIT;
        this.getNoteCollections(this.currentCollectionType, businessUnit.businessUnitId);
      }
    });

    this.selectedDeal$ = this.store.select(getSelectedDeal).subscribe((deal: IDealCard) => {
      if (!!deal && !!deal.id) {
        this.currentCollectionType = NoteTypes.DEAL;
        this.getNoteCollections(this.currentCollectionType, deal.id);
      }
    });

    this.selectedMandate$ = this.store.select(getSelectedMandate).subscribe((mandate: iMandateResponse) => {
      if (!!mandate && !!mandate.id) {
        this.currentCollectionType = NoteTypes.MANDATE;
        this.getNoteCollections(this.currentCollectionType, mandate.id);
      }
    });
  }

  ngOnInit(): void {

  }

  ngAfterViewChecked() {

  }

  /**
   * Kill the subscriptions
   */
  ngOnDestroy() {
    this.config$.unsubscribe();
    this.theme$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedDeal$.unsubscribe();
    this.selectedMandate$.unsubscribe();
  }

  /**
   * Action to scroll the notes to bottom
   */
  scrollToBottom(): void {
    setTimeout(() => {
      if (!!this.myScrollContainer) {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      }
    }, 1000);
  }

  /**
   * Get the note collection from the top level object currently being inspected
   * @param type the type of object DEAL, BUSINESSUNIT, MANDATE
   * @param id the id of the object
   */
  getNoteCollections(type: NoteTypes, id: string) {
    if (!!type && !!id) {
      // this.store.dispatch(toggleLoading({loading: true}));
      this.notesService.getNoteCollections(type, id).subscribe({
        next: (collections: INoteCollection[]) => {
          this.noteCollections = collections;
          if (this.noteCollections.length > 0) {
            this.selectedCollection = this.noteCollections[0];
            this.getNotes();
          }
          // this.toastrService.success("KYC Loaded!", $localize `:@@companyName:Rondeivu`);
          // this.store.dispatch(toggleLoading({loading: false}));
        }, error: (error: any) => {
          // this.store.dispatch(toggleLoading({loading: false}));
          this.toastrService.error("Something went wrong!");
        }
      });
    }
  }

  /**
   * The change collection action
   * @param event a note collection event
   */
  changeNoteCollection(event: any) {
    // console.log(event);
    this.selectedCollection = event.value as INoteCollection;
    this.getNotes();
  }

  /**
   * Get the notes for the selected collection
   */
  getNotes() {
    if (!!this.selectedCollection) {
      this.showSpinner();
      // this.store.dispatch(toggleLoading({loading: true}));
      this.notesService.getNoteCollection(this.selectedCollection.notesCollectionId).subscribe({
        next: (notes: INoteItem[]) => {
          this.notesList = notes;
          this.scrollToBottom();
        }, error: (error: any) => {
          // this.store.dispatch(toggleLoading({loading: false}));
          this.toastrService.error("Something went wrong!");
        }
      });
    }
  }

  /**
   * Add a note by pressing enter on the input
   * @param text the note text
   */
  addNote(text: string) {
    if (!!this.selectedCollection && !!this.notePost) {
      // this.showSpinner();
      // this.store.dispatch(toggleLoading({loading: true}));
      this.notesService.addNote(this.selectedCollection.notesCollectionId, text).subscribe({
        next: (res: any) => {
          this.notePost = '';
          this.getNotes();
          this.toastrService.success("Note Added!", $localize`:@@companyName:Rondeivu`);
        }, error: (error: any) => {
          // this.store.dispatch(toggleLoading({loading: false}));
          this.toastrService.error("Something went wrong!");
        }
      });
    }
  }

  /**
   * Edit note action switches to edit mode for the selected note
   * @param note the note being edited
   * @param text the new text
   */
  editNote(note: INoteItem, text: string) {
    if (!!note) {
      // this.showSpinner();
      // this.store.dispatch(toggleLoading({loading: true}));
      this.notesService.updateNote(note.id, text).subscribe({
        next: (res: any) => {
          this.getNotes();
          this.isEditing = false;
          this.toastrService.success("Note Updated!", $localize`:@@companyName:Rondeivu`);
        }, error: (error: any) => {
          // this.store.dispatch(toggleLoading({loading: false}));
          this.toastrService.error("Something went wrong!");
        }
      });
    }
  }

  /**
   * Delete action
   * @param element
   */
  delete(element: INoteItem) {
    const delRef = this.dialog.open(DeleteConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      data: element
    });

    delRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.deleteNote(result);
      }
    });
  }

  /**
   * Returns the permission to test access against given the action type and the current note collection type
   * @param type
   * @param note
   */
  canAccessActionByUserPermission(type: ActionType, note?: INoteItem): boolean {
    switch (this.currentCollectionType) {
      case NoteTypes.DEAL:
        switch (type) {
          case ActionType.ADD:
            return this.util.hasPermission(this.util.Permission.CAN_ADD_DEAL_NOTES);
          case ActionType.EDIT:
            if (!note) {
              return false;
            }
            return this.util.hasPermission(this.util.Permission.CAN_EDIT_DEAL_ALL_NOTES) ||
              (this.util.hasPermission(this.util.Permission.CAN_EDIT_DEAL_NOTES) && note.isNoteOwner);
          case ActionType.DELETE:
            if (!note) {
              return false;
            }
            return this.util.hasPermission(this.util.Permission.CAN_DELETE_DEAL_ALL_NOTES) ||
              (this.util.hasPermission(this.util.Permission.CAN_DELETE_DEAL_NOTES) && note.isNoteOwner);
          default:
            return false;
        }
      case NoteTypes.MANDATE:
        switch (type) {
          case ActionType.ADD:
            return this.util.hasPermission(this.util.Permission.CAN_ADD_MANDATE_NOTES);
          case ActionType.EDIT:
            return this.util.hasPermission(this.util.Permission.CAN_EDIT_MANDATE_NOTES);
          case ActionType.DELETE:
            return this.util.hasPermission(this.util.Permission.CAN_DELETE_MANDATE_NOTES);
          default:
            return false;
        }
      case NoteTypes.BUSINESSUNIT:
        switch (type) {
          case ActionType.ADD:
            return this.util.hasPermission(this.util.Permission.CAN_ADD_BUSINESS_UNITS_NOTES);
          case ActionType.EDIT:
            return this.util.hasPermission(this.util.Permission.CAN_EDIT_BUSINESS_UNITS_NOTES);
          case ActionType.DELETE:
            return this.util.hasPermission(this.util.Permission.CAN_DELETE_BUSINESS_UNITS_NOTES);
          default:
            return false;
        }
      default:
        //default should never be used
        return false;
    }


  }

  /**
   * Delete a note
   * @param note the note to remove
   */
  private deleteNote(note: INoteItem) {
    if (!!note) {
      // this.showSpinner();
      // this.store.dispatch(toggleLoading({loading: true}));
      this.notesService.deleteNote(note.id).subscribe({
        next: (res: any) => {
          this.getNotes();
          this.toastrService.success("Note Removed!", $localize`:@@companyName:Rondeivu`);
        }, error: (error: any) => {
          // this.store.dispatch(toggleLoading({loading: false}));
          this.toastrService.error("Something went wrong!");
        }
      });
    }
  }

  private showSpinner() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  protected readonly Action = Action;
}
