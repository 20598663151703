<!-- <div [@fadeIn]="animationState" (@fadeIn.start)="animStart($event)" > -->
<div>
  <!-- <div [@slideOutLeft]="animateExit" (@slideOutLeft.done)="exitEnd($event)"> -->
  <div>
    <mat-toolbar id="toolbar" dense [ngStyle]="{'background-color':theme.subheader_bg, 'color':theme.subheader_fg}">
      <button mat-button (click)="goBack()" [matTooltip]="'Show all kyc'" matTooltipPosition="below">
        <mat-icon>list</mat-icon>
      </button>
      &nbsp;
      <div id="deal-header" *ngIf="!loading" class="d-block">
        <span>
          {{selectedKyc?.entityName || ''}}
        </span>
        <br/>
        <span style="color: grey;">
         KYC #{{selectedKyc?.id || ''}}
        </span>
      </div>
      <mat-spinner *ngIf="loading" [diameter]="36" color="accent"></mat-spinner>
      <!--      approval buttons-->
      <div class="nav-end">
        <button mat-raised-button
                color="primary"
                type="button"
                (click)="review()"
                *ngIf="!util.isType('admin') && selectedKyc?.status == 'PENDING'"
                [disabled]="!util.hasPermission(util.Permission.CAN_SUBMIT_KYC)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_SUBMIT_KYC) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_SUBMIT_KYC) ? util.NoAccessMsg : ''"
                matTooltipPosition="left"
        >SUBMIT FOR REVIEW
        </button>
      </div>
    </mat-toolbar>
    <nav id="deal-nav" dense
         mat-tab-nav-bar
         [tabPanel]="tabPanel"
         style="width: 100%"
         [ngStyle]="{'background-color': theme.subheader_bg}"
    >
      <a
        mat-tab-link
        *ngFor="let link of links"
        (click)="activeLink = link; navigate(link);"
        [active]="activeLink == link"
        [disabled]="!link.enabled"
        [hidden]="link.hidden"
        [ngStyle]="{'background-color':theme.subheader_bg, 'color':theme.subheader_fg}"
      >
        {{ link.title }}
      </a>
    </nav>
    <mat-tab-nav-panel #tabPanel>
      <div [@appFade]="animationState">
        <router-outlet></router-outlet>
      </div>
    </mat-tab-nav-panel>
  </div>
</div>

