<div style="overflow-y: auto; height: calc(100vh - 176px);">
  <div class="container-fluid">
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-12 col-md-9">
        <span class="rm-title rm-title-3">Media</span><br/>
        <span class="rm-title rm-title-6 rm-lighter">Rondeivu will add deal-related media links to this page.</span>
      </div>
      <div class="col-12 col-md-2">
        <button mat-raised-button
                color="primary"
                class="frm-btn"
                (click)="add()"
                *ngIf="util.isType('admin')"
                [disabled]="!util.hasPermission(util.Permission.CAN_ADD_DEAL_MEDIA)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_DEAL_MEDIA) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_ADD_DEAL_MEDIA) ? util.NoAccessMsg : ''"
                matTooltipPosition="left"
        >
          <mat-icon>add_to_photos</mat-icon>&nbsp;ADD MEDIA
        </button>
      </div>
    </div>
    <div style="padding-top: 50px; padding-bottom: 150px;">
      <!--  media cards  -->
      <div class="row justify-content-center"
           cdkDropList
           [cdkDropListDisabled]="!util.isType('admin')"
           [cdkDropListData]="mediaRooms"
           (cdkDropListDropped)="drop($event)">
        <div class="col-12 col-md-11 media" *ngFor="let m of mediaRooms" cdkDrag>
          <!--          the dashed outline-->
          <div class="cdk-drag-placeholder" *cdkDragPlaceholder></div>
          <!--the card-->
          <div style="display: flex;">
            <div style="width: 250px; min-width: 100px; max-width: 250px;"
                 [ngStyle]="{'cursor': util.isType('admin')? 'move': ''}">
              <img [hidden]="showLinkPreviews" class="img-top" [src]="m.imageUrl"
                   [default]="'/assets/images/image-placeholder.png'"
                   alt="Media Image Broken :(" (click)="navigate(m)">
              <app-iframe-thumbnail *ngIf="showLinkPreviews" class="img-top"
                                    [src]="getSanitizedUrl(m.linkUrl)"
                                    (click)="navigate(m)"
                                    style="border-radius: 12px;">
              </app-iframe-thumbnail>
            </div>
            <div class="info-side" [ngStyle]="{'cursor': util.isType('admin')? 'move': ''}">
              <div class="row">
                <div class="col-12" style="overflow: hidden; display: flex; justify-content: space-between;">
                  <span class="title">{{m.title}}</span>
                  <div style="display:flex;padding-right:12px;">
                    <button mat-icon-button
                            (click)="edit(m)"
                            [hidden]="!util.isType('admin')"
                            [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_MEDIA)"
                            [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_MEDIA) ? util.NoAccessCursor: ''}"
                            [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_MEDIA) ? util.NoAccessMsg : ''"
                            matTooltipPosition="left"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button
                            (click)="delete(m)"
                            [hidden]="!util.isType('admin')"
                            [disabled]="!util.hasPermission(util.Permission.CAN_DELETE_DEAL_MEDIA)"
                            [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_DELETE_DEAL_MEDIA) ? util.NoAccessCursor: ''}"
                            [matTooltip]="!util.hasPermission(util.Permission.CAN_DELETE_DEAL_MEDIA) ? util.NoAccessMsg : ''"
                            matTooltipPosition="left"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                    <button mat-icon-button
                            (click)="navigate(m)"
                            [disabled]="!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_MEDIA)"
                            [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_MEDIA) ? util.NoAccessCursor: ''}"
                            [matTooltip]="!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_MEDIA) ? util.NoAccessMsg : m.linkUrl"
                            matTooltipPosition="left"
                    >
                      <mat-icon>open_in_new</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="description">
                    <span>{{m.description}}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                <span style="font-weight: lighter;font-size: 12px;" [matTooltip]="m.linkUrl"
                      matTooltipPosition="right">{{m.linkUrlPreview}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

