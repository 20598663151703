import {Directive, ElementRef, Input, OnChanges, OnDestroy, Renderer2, SimpleChanges} from '@angular/core';
import {AppStatus} from "../models";
import {Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import {getThemeState, initialThemeState, Theme} from "../../redux";

@Directive({
  selector: '[appStatus]'
})
export class StatusDirective implements OnChanges, OnDestroy {
  @Input() appStatus: string = '';

  elem: ElementRef;
  renderer: Renderer2;
  theme$:Subscription = new Subscription();
  theme:Theme = initialThemeState;

  constructor(elem: ElementRef, renderer: Renderer2, private store: Store) {
    this.elem = elem;
    this.renderer = renderer;

    this.theme$ = this.store.select(getThemeState).subscribe(((theme:Theme)=>{
      this.theme = theme;
    }));
  }

  ngOnDestroy() {
    this.theme$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'appStatus': {
            let status = changes['appStatus'].currentValue?.toUpperCase();
            this.renderer.setStyle(this.elem.nativeElement, 'font-weight', '600');
            if (!!this.elem && !!this.renderer) {
              switch (status) {
                case AppStatus.APPROVED:
                  this.renderer.setStyle(this.elem.nativeElement, 'color', this.theme.status.approved || '');
                  break;
                case AppStatus.ACCEPTED:
                  this.renderer.setStyle(this.elem.nativeElement, 'color', this.theme.status.accepted || '');
                  break;
                case AppStatus.AUTO_ACCEPTED:
                  this.renderer.setStyle(this.elem.nativeElement, 'color', this.theme.status.auto_accepted || '');
                  break;
                case AppStatus.ACTIVE:
                  this.renderer.setStyle(this.elem.nativeElement, 'color', this.theme.status.active || '');
                  break;
                case AppStatus.DECLINED:
                  this.renderer.setStyle(this.elem.nativeElement, 'color', this.theme.status.declined || '');
                  break;
                case AppStatus.CLOSED:
                  this.renderer.setStyle(this.elem.nativeElement, 'color', this.theme.status.closed || '');
                  break;
                case AppStatus.LIVE:
                  this.renderer.setStyle(this.elem.nativeElement, 'color', this.theme.status.live || '');
                  break;
                case AppStatus.ONBOARDING:
                  this.renderer.setStyle(this.elem.nativeElement, 'color', this.theme.status.onboarding || '');
                  break;
                case AppStatus.PENDING:
                  this.renderer.setStyle(this.elem.nativeElement, 'color', this.theme.status.pending || '');
                  break;
                case AppStatus.COMPLETED:
                  this.renderer.setStyle(this.elem.nativeElement, 'color', this.theme.status.completed || '');
                  break;
                case AppStatus.SENT:
                  this.renderer.setStyle(this.elem.nativeElement, 'color', this.theme.status.sent || '');
                  break;
                case AppStatus.SUSPENDED:
                  this.renderer.setStyle(this.elem.nativeElement, 'color', this.theme.status.suspended || '');
                  break;
                case AppStatus.NOT_REQUIRED:
                  this.renderer.setStyle(this.elem.nativeElement, 'color', this.theme.status.not_required || '');
                  break;
                case AppStatus.IN_REVIEW:
                  this.renderer.setStyle(this.elem.nativeElement, 'color', this.theme.status.in_review || '');
                  break;
                case AppStatus.CONFIRMED:
                  this.renderer.setStyle(this.elem.nativeElement, 'color', this.theme.status.confirmed || '');
                  break;
                case AppStatus.UNCONFIRMED:
                  this.renderer.setStyle(this.elem.nativeElement, 'color', this.theme.status.unconfirmed || '');
                  break;
                case AppStatus.NOT_REGISTERED:
                  this.renderer.setStyle(this.elem.nativeElement, 'color', this.theme.status.not_registered || '');
                  break;
                case AppStatus.FORCE_CHANGE_PASSWORD:
                  this.renderer.setStyle(this.elem.nativeElement, 'color', this.theme.status.force_change_password || '');
                  break;
                default:
                  this.renderer.setStyle(this.elem.nativeElement, 'color', '');
              }
            }
          }
        }
      }
    }
  }


}
