import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {
  InvitationsComponent,
  ProfileComponent,
  SettingsNavigationComponent
} from "./components";
import {PageNotFoundComponent} from "../../components";


const routes: Routes = [
  {
    path: '', component: SettingsNavigationComponent, children: [
      {path: 'user', component: ProfileComponent},
      {path: 'invitations', component: InvitationsComponent},
      {path: '', redirectTo: 'user', pathMatch: 'full'},
      {path: '**', redirectTo: 'user'},
    ]
  },
  {path: '**', redirectTo: '', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {
}
