import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  Config,
  getConfig, getLookups,
  getSelectedDeal,
  initialConfigState,
  initialLookupsState,
  Lookups,
} from "../../../redux";
import {IDealCard} from "../../../shared/models";
import {DealService} from "../../services";
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {IBusinessUnit} from "../../../business-units/models";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-deal-view',
  templateUrl: './deal-view.component.html',
  styleUrls: ['./deal-view.component.scss']
})
export class DealViewComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  selectedDeal$: Subscription = new Subscription();
  selectedDeal: IDealCard = {} as unknown as IDealCard;

  hovering = false;
  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;
  deal: IDealCard = {} as unknown as IDealCard;
  bu: IBusinessUnit = {} as unknown as IBusinessUnit;

  constructor(
    private dealService: DealService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private toastrService: ToastrService
  ) {
    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    })

    this.selectedDeal$ = this.store.select(getSelectedDeal).subscribe((deal: IDealCard) => {
      if (this.selectedDeal.id != deal.id) {
        this.selectedDeal = deal;
        this.subscribeToDatasource();
      } else {
        this.selectedDeal = deal;
      }
    });

  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedDeal$.unsubscribe();
  }

  subscribeToDatasource(): void {
    this.getDeal();
  }

  /**
   * Deal fetch that shows global spinner
   */
  getDeal() {
    console.log("Fetching Deal Information...");
    this.dealService.getDeal(this.config.selected_deal_id).subscribe({
      next: (deal: IDealCard) => {
        this.deal = deal;
      }, error: err => {
        this.toastrService.error("Unable to get deal!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  getImgClass() {
    return this.hovering ? 'img-top-hover' : '';
  }

}
