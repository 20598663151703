import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {AngularEditorConfig} from "@kolkov/angular-editor";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-notepad',
  templateUrl: './notepad.component.html',
  styleUrls: ['./notepad.component.scss']
})
export class NotepadComponent implements OnInit, OnChanges {
  @Input() text: string = '';
  @Input() minHeight: string = '0';
  @Output() textChange: EventEmitter<string> = new EventEmitter<string>();

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    width: 'auto',
    minWidth: '0',
    minHeight: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter notes here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    uploadUrl: 'v1/image',
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'toggleEditorMode',
        'backgroundColor',
        'customClasses',
        'indent',
        'outdent',
        'fontName',
        'link',
        'unlink'
      ]
    ]
  };

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'minHeight':
            if (changes['minHeight'].currentValue != changes['minHeight'].previousValue) {
              this.editorConfig.minHeight = changes['minHeight'].currentValue;
            }
            break;
        }
      }
    }
  }

  ngOnInit(): void {
  }

  textChanged(event: any) {
    // console.log(event);
    this.textChange.emit(event);
  }

}
