import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {Config, getConfig, getLookups, initialConfigState, initialLookupsState, Lookups} from "../../../redux";
import {Subscription} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss']
})
export class UserModalComponent implements OnInit {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;
  showPassword = false;

  userForm = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.email, Validators.required]),
    countryCode: new FormControl('+1', Validators.required),
    mobile: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
    tempPassword: new FormControl('',[Validators.required, Validators.pattern("^(?=.*[`!@#$%^&*()_+\\-=\\[\\]{};':\\\\|,.<>\\/?~])(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$")]),
    timeZone: new FormControl(Intl.DateTimeFormat().resolvedOptions().timeZone)
  });


  constructor(public dialogRef: MatDialogRef<UserModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { isEditing: boolean, user: any },
              private store: Store,
              private toastr: ToastrService) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });
  }

  ngOnInit(): void {
  }

  submit() {
    this.dialogRef.close(this.userForm.getRawValue());
  }

}
