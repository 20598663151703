import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {
  CompanyEmployeeInfoComponent, CompanyEmployeePermissionsComponent,
  CompanyListComponent, CompanyNavigationComponent
} from './components';
import {CompanyEmployeeResolver} from "../../resolvers/company-employee.resolver";


const routes: Routes = [
  {path: '', component: CompanyListComponent},
  {
    path: ':id', component: CompanyNavigationComponent,
    resolve: {
      CompanyEmployeeResolver
    },
    children: [
      {path: 'information', component: CompanyEmployeeInfoComponent},
      {path: 'permissions', component: CompanyEmployeePermissionsComponent},
      {path: '', redirectTo: 'information', pathMatch: 'full'},
      {path: '**', redirectTo: 'information'},
    ]
  },
  {path: '', redirectTo: '', pathMatch: 'full'},
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CompanyRoutingModule {
}
