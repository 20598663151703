<div style="overflow-y: auto; height: calc(100vh - 176px);">
  <div class="container-fluid">
    <!--    COMMUNICATION REQUEST-->
    <form [formGroup]="userForm"
          (ngSubmit)="save()">
      <br/><br/>
      <div class="row justify-content-center">
        <div class="col-11">
          <span style="font-size: x-large; color: #002150;">Communication Request</span><br/>
          <span style="font-size: small;">This is where you can send a communication request to the issuer.</span>
        </div>
      </div>
      <br/><br/>
      <div class="row justify-content-center">
        <div class="col-11">
          <div class="row">
            <span style="font-size: large;font-weight: bold;">Communication Request with Issuer</span>
          </div>
          <br/>
          <div class="row">
            <!--            LEFT COLUMN-->
            <div class="col-12 col-md-6">
              <!--          row start-->
              <div class="row">
                <div class="col-12">
                  <mat-label style="font-size: medium;">Call Agenda</mat-label>
                  <mat-form-field class="form-field" appearance="outline" floatLabel="always">
                <textarea id="agenda" class="form-input" matInput formControlName="callAgenda"
                          placeholder="Type here..."></textarea>
                  </mat-form-field>
                </div>
              </div>
              <!--              row start-->
              <div class="row">
                <div class="col-12">
                  <mat-label style="font-size: medium;">Team Members on the Call</mat-label>
                  <mat-form-field class="form-field" appearance="outline" floatLabel="always">
                    <mat-select multiple id="team-members" formControlName="teamEmails">
                      <mat-option *ngFor="let e of memberEmails"  [value]="e">
                        {{e}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <!--              row start-->
              <div class="row">
                <div class="col-12">
                  <mat-label style="font-size: medium;">Call Length</mat-label>
                  <mat-form-field class="form-field" appearance="outline" floatLabel="always">
                    <mat-select id="call-len" formControlName="callLength" placeholder="15 minutes">
                      <mat-option *ngFor="let c of [15,30,60,90]" [value]="c">
                        {{c + ' minutes'}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="userForm.invalid">Please choose one.</mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="row" style="padding-top: 20px;" *ngIf="sent">
                <div class="col-12">
                  <h2 style="color: green;">Request Sent!</h2>
                </div>
              </div>
            </div>

            <!--            RIGHT COLUMN-->
            <div class="col-12 col-md-6">
              <div class="row">
                <div class="col-12" style="margin-bottom: 8px;">
                  <span style="font-size: medium;">
                    Suggest a few Dates and Times (max. {{MAX_LEN}})
                    <br/>
                    Your timezone will be automatically selected
                  </span>
                </div>
                <!--              CALL DATE-->
                <div class="col-12 col-md-5">
                  <mat-form-field appearance="outline" class="form-field">
                    <input id="date-times" matInput [matDatepicker]="datepicker" [min]="today"
                           formControlName="callDate" placeholder="Choose a date"/>
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                    <mat-datepicker #datepicker></mat-datepicker>
                    <mat-error *ngIf="userForm.invalid">Please select a date.</mat-error>
                  </mat-form-field>
                </div>
                <!--              CALL TIME HOURS-->
                <div class="col-12 col-md-2">
                  <mat-form-field class="form-field" appearance="outline" floatLabel="always">
                    <mat-label>Hours</mat-label>
                    <mat-select id="call-time" formControlName="callTimeHr">
                      <mat-option *ngFor="let t of timeHrOptions" [value]="t">
                        {{t}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <!--              CALL TIME MINUTES-->
                <div class="col-12 col-md-2">
                  <mat-form-field class="form-field" appearance="outline" floatLabel="always">
                    <mat-label>Minutes</mat-label>
                    <mat-select id="call-time-min" formControlName="callTimeMin">
                      <mat-option *ngFor="let m of timeMinOptions" [value]="m">
                        {{m == 0 ? "00" : m}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <!--                CALL ADD BUTTON-->
                <div class="col-12 col-md-2">
                  <div style="padding-top: 8px;">
                    <button mat-raised-button color="primary" type="button" (click)="addSchedule()"
                            [disabled]="scheduleDateTimes.length >= MAX_LEN || sent">ADD
                    </button>
                  </div>
                </div>
              </div>

              <div class="row" style="padding-top: 20px;" *ngIf="scheduleDateTimes.length > 0">
                <div class="col-12">
                  <span style="font-weight: lighter;">Suggested Times</span>
                </div>
                <div class="col-12" >
                  <table style="list-style-type: none;" >
                    <tr *ngFor="let s of scheduleDateTimes;index as i;">
                      <td [ngStyle]="{'font-weight': sent ? 'lighter' : ''}">{{s}}</td>
                      <td><button mat-icon-button type="button" (click)="scheduleDateTimes.splice(i, 1)" [disabled]="sent"><mat-icon>close</mat-icon></button></td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <br/>
          <!-- BUTTONS-->
          <div class="row" style="padding-top: 50px;">
            <div class="col-12 text-end">
              <button mat-raised-button
                      color="primary"
                      type="submit"
                      [disabled]="scheduleDateTimes.length == 0 || userForm.invalid || sent || !util.hasPermission(util.Permission.CAN_EDIT_DEAL_INVESTOR_COMMUNICATIONS)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_INVESTOR_COMMUNICATIONS) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_INVESTOR_COMMUNICATIONS) ? util.NoAccessMsg : ''"
                      matTooltipPosition="left"
              >SEND REQUEST
              </button>
            </div>
          </div>
        </div>
      </div>
      <br/>
    </form>
  </div>
</div>

