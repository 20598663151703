
<div class="rm-header">
  <span>{{data.type.charAt(0).toUpperCase() + data.type.slice(1) + ' Deal ' + (data.type == 'match' ? 'to' : 'and') + ' Investor'}}</span>
</div>

<div class="container">
  <!--<h1 mat-dialog-title>{{data.type.charAt(0).toUpperCase() + data.type.slice(1) + ' Deal ' +  (data.type == 'match'? 'to':'and') + ' Investor'}}</h1>-->
  <div mat-dialog-content class="row">
    <div class="col-12" style="padding-top: 25px;">
      <span *ngIf="data.type == 'match'">Confirm this <span style="color: blue; font-weight: bold;">match</span> between <b>Rondeivu</b> and <b>{{ data.displayName }}</b></span>
      <span *ngIf="data.type == 'unmatch'">Confirm that you wish to <span
        style="color: red; font-weight: bold;">unmatch </span> <b>Rondeivu</b> and <b>{{ data.displayName }}</b></span>
    </div>
    <div class="col-12" style="padding-top: 25px;">
      <mat-label style="font-size: medium;">Reason for {{data.type}}ing this investor:</mat-label><br/>
      <mat-form-field appearance="outline" style="width: 100%;">
        <textarea matInput [(ngModel)]="data.reason" placeholder="(Optional)" style="max-height: 400px;"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions style="display: flex; justify-content: space-between;">
    <button mat-raised-button (click)="onNoClick()">CANCEL</button>&nbsp;
    <button mat-raised-button color="primary" (click)="action(data)" cdkFocusInitial>{{data.type.toUpperCase()}}</button>
  </div>
</div>

