import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from "@ngrx/store";
import {AppConfigService, LookupService} from "../../../../services";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MandateModalComponent} from "../../../mandates/components";
import {DealModalComponent} from "../../../deals/components";
import {map} from "rxjs/operators";
import {
  fetchTasks,
  showDealModal,
  showMandateModal, toggleLoading,
  updateTasks
} from "./config.actions";
import {getConfig} from "./config.selectors";
import {Config} from "./config.model";
import {initialConfigState} from "./config.init";
import {ITask} from "../../../../models";
import {ToastrService} from "ngx-toastr";
import {ModalSize, UtilService} from "../../../shared/services/util/util.service";
import {IDealCard} from "../../../shared/models";
import {Router} from "@angular/router";
import {DealService} from "../../../deals/services";
import {MandateService} from "../../../mandates/services";


@Injectable({
  providedIn: 'root'
})
export class ConfigEffects {

  config: Config = initialConfigState;
  private mandateModalRef: MatDialogRef<MandateModalComponent> | undefined;
  private dealModalRef: MatDialogRef<DealModalComponent> | undefined;

  //DEAL MODAL
  showDealModal$ = createEffect(() => this.actions$.pipe(
    ofType(showDealModal),
    map((result: any) => {
      this.openDealModalTask();
      return {type: '[Effect End] Config'};
    })));

  //MANDATE MODAL
  showMandateModal$ = createEffect(() => this.actions$.pipe(
    ofType(showMandateModal),
    map((result: any) => {
      this.openMandateModalTask();
      return {type: '[Effect End] Config'};
    })));

  //DEAL TEAM MEMBER MODAL
  fetchTasks$ = createEffect(() => this.actions$.pipe(
    ofType(fetchTasks),
    map(() => {
      this.fetchTasks();
      return {type: '[Fetch Tasks End] Config'};
    })));

  constructor(
    private actions$: Actions,
    private store: Store,
    private lookupService: LookupService,
    private dialog: MatDialog,
    private appConfig: AppConfigService,
    private toastr: ToastrService,
    private util: UtilService,
    private router: Router,
    private dealService: DealService,
    private mandateService: MandateService
  ) {

    this.store.select(getConfig).subscribe((config: Config) => {
        this.config = config;
      }
    );

  }

  /**
   * Deal Modal
   */
  openDealModalTask() {
    this.dealModalRef = this.dialog.open(DealModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      disableClose: true
    });

    this.dealModalRef.afterClosed().subscribe((result: any) => {
      if (!!result) {
        console.log("Creating deal...");
        this.store.dispatch(toggleLoading({loading: true}));
        this.dealService.addDeal(result).subscribe({
          next: (res: IDealCard) => {
            this.router.navigate(['/' + this.config.selected_business_unit.slug + '/deals'], {queryParams: {status: 'pending,declined,onboarding'}}).then(() => {
              this.store.dispatch(toggleLoading({loading: false}));
              this.store.dispatch({type: fetchTasks});
              this.toastr.success("Deal Created!", $localize`:@@companyName:Rondeivu`);
            });
          }, error: (err: any) => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to create deal!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  /**
   * Mandate modal
   */
  openMandateModalTask() {
    this.mandateModalRef = this.dialog.open(MandateModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.MEDIUM),
      disableClose: true
    });

    this.mandateModalRef.afterClosed().subscribe((result: any) => {
      if (!!result) {
        console.log("Creating mandate...");
        this.store.dispatch(toggleLoading({loading: true}));
        this.mandateService.addMandate(result).subscribe({
          next: (res: any) => {
            this.router.navigate(['/' + this.config.selected_business_unit.slug + '/mandates']).then(() => {
              this.store.dispatch(toggleLoading({loading: false}));
              this.store.dispatch({type: fetchTasks});
              this.toastr.success("Mandate Created!", $localize`:@@companyName:Rondeivu`);
            });
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to create mandate!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  /**
   * Fetch the user tasks
   */
  fetchTasks() {
    this.appConfig.getTasks().subscribe({
      next: (tasks: ITask[]) => {
        this.store.dispatch(updateTasks({tasks: tasks}));
      }, error: (err: any) => {
        this.toastr.error("Unable to get tasks!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }
}
