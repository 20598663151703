import {Component, Input, OnInit} from '@angular/core';
import {IPermissionGroup} from "../../models";


@Component({
  selector: 'app-permission-check',
  templateUrl: './permission-check.component.html',
  styleUrls: ['./permission-check.component.scss']
})
export class PermissionCheckComponent implements OnInit {

  @Input() permissionGroups: IPermissionGroup[] = [] as unknown as IPermissionGroup[];
  selectedRole: any = {} as unknown as any;

  constructor() {
  }

  ngOnInit(): void {
  }

  updateSelectedRole(event: any) {
    this.selectedRole = event.value;
  }
}
