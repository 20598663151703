import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IKycQuestion} from "../../models/entity";
import {MatRadioChange} from "@angular/material/radio";
import {MatCheckboxChange} from "@angular/material/checkbox";

@Component({
  selector: 'app-kyc-input-section-questions',
  templateUrl: './kyc-input-section-questions.component.html',
  styleUrls: ['./kyc-input-section-questions.component.scss']
})
export class KycInputSectionQuestionsComponent implements OnInit, OnChanges {

  @Input() sectionQuestions?: IKycQuestion[] = [];
  @Input() disableAll: boolean = false;
  @Output() onValueChange = new EventEmitter;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'sectionQuestions':
            this.sectionQuestions = changes['sectionQuestions'].currentValue;
            break;
          case 'disableAll':
            this.disableAll = changes['disableAll'].currentValue;
            break;
        }
      }
    }
  }

  emitMatInputChange(questionAttribute: string, event: any) {
    this.onValueChange.emit({questionAnswerAttribute: questionAttribute, questionAnswer: event.srcElement.value});
  }

  emitMatCheckboxChange(questionAttribute: string, event: MatCheckboxChange) {
    this.onValueChange.emit({questionAnswerAttribute: questionAttribute, questionAnswer: event.checked});
  }

  emitMatRadioChange(questionAttribute: string, event: MatRadioChange) {
    this.onValueChange.emit({questionAnswerAttribute: questionAttribute, questionAnswer: event.value});
  }

}
