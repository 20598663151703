import {Injectable} from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {Store} from "@ngrx/store";
import {RondeivuHttpClient} from "../../../../handlers";
import {Config, getConfig, initialConfigState} from "../../../redux";
import {HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";

import {
  concatMap,
  delay,
  of,
  retryWhen,
} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataRoomService {

  private baseUrl = environment.rondeivu_api;
  private readonly FRAGMENT = '/dealdocuments';
  private readonly ROOT_FRAGMENT = '/rootfolders';
  private readonly DEAL_QUERY = '?dealId=';

  config: Config = initialConfigState;

  constructor(private store: Store, private http: RondeivuHttpClient) {
    this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
  }

  addDealDocument(deal: any) {
    const req = JSON.stringify(deal);
    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Content-length', req.length.toString());
    // console.log(req);
    return this.http.post(this.baseUrl + this.FRAGMENT + this.DEAL_QUERY + this.config.selected_deal_id, deal)
      .pipe(delay(5000), this.handleRetryError(2000));
  }

  getDealDocuments(): Observable<any> {
    return this.http.get(this.baseUrl + this.FRAGMENT + this.DEAL_QUERY + this.config.selected_deal_id);
  }

  deleteDealDocument(id: string): Observable<any> {
    return this.http.delete(this.baseUrl + this.FRAGMENT + '/' + id);
  }

  getDealDocumentRootFolders() {
    return this.http.get(this.baseUrl + this.FRAGMENT + this.ROOT_FRAGMENT + this.DEAL_QUERY + this.config.selected_deal_id);
  }

  handleRetryError(delayTime: number) {
    let retries = 0;
    let exceedAttemptLimit = 1;
    return retryWhen((error) => {
      return error.pipe(
        delay(delayTime),
        concatMap((err) => {
          retries = retries + 1;
          if (retries < exceedAttemptLimit) {
            return of(err);
          } else {
            throw err;
          }
        })
      );
    });
  }
}
