<mat-form-field appearance="outline">
  <mat-label>Role</mat-label>
  <mat-select (selectionChange)="updateSelectedRole($event)">
    <mat-option *ngFor="let r of permissionGroups" [value]="r">
      {{r.name}}
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-accordion multi="true">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Business Unit Permissions
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div *ngFor="let pg of permissionGroups" style="display: flex; justify-content: space-between;">
      <div style="display: flex;">
        <div>
          <mat-icon color="primary">info</mat-icon>
        </div>
        &nbsp;
        <div>
          <mat-label>{{pg.name}}</mat-label>
          <br/>
          <mat-label>{{pg.description}}</mat-label>
        </div>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Permission</mat-label>
          <mat-select>
            <mat-option *ngFor="let p of pg.permissionOptions" [matTooltip]="p.description"
                        matTooltipPosition="right">{{p.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </mat-expansion-panel>

  <!--  deal team-->
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Global Deal Team Permissions
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div *ngFor="let pg of permissionGroups" style="display: flex; justify-content: space-between;">
      <div style="display: flex;">
        <div>
          <mat-icon color="primary">info</mat-icon>
        </div>
        &nbsp;
        <div>
          <mat-label>{{pg.name}}</mat-label>
          <br/>
          <mat-label>{{pg.description}}</mat-label>
        </div>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Permission</mat-label>
          <mat-select>
            <mat-option *ngFor="let p of pg.permissionOptions" [matTooltip]="p.description"
                        matTooltipPosition="right">{{p.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
