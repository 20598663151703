import {Component, OnDestroy, OnInit} from '@angular/core';
import {IRegistrationRequest, ICognitoUser} from "../../../models";
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {AuthService, LoadingService} from "../../../services";
import {Auth} from "aws-amplify";
import {
  Config, getConfig, getLookups,
  initialCognitoUserState, initialConfigState, initialLookupsState, Lookups,
  updateBusinessUnits,
  updateCognitoUser,
  updateSelectedBusinessUnit
} from "../../../modules/redux";
import {BusinessUnitService} from "../../../modules/business-units/services";
import {environment} from "../../../../environments/environment";
import {HttpErrorResponse} from "@angular/common/http";
import {Subscription} from "rxjs";
import jwtDecode from "jwt-decode";
import {FormControl, FormGroup, Validators} from "@angular/forms";

enum WizardPage {
  'TYPE' = 0,
  'REGISTER' = 1,
  'INFO' = 2,
  'EMAIL_VERIFY' = 3,
  'PHONE_VERIFY' = 4
}

@Component({
  selector: 'app-auth-register',
  templateUrl: './auth-register.component.html',
  styleUrls: ['./auth-register.component.scss']
})
export class AuthRegisterComponent implements OnInit, OnDestroy {

  param$: Subscription = new Subscription();
  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();

  readonly TIMEOUT = environment.rondeivu_default_load_delay_ms;
  readonly SIGN_IN_PATH = '/auth';
  readonly LOGIN_PATH = '/auth/login';
  readonly VERIFY_PATH = '/auth/verify';
  readonly FORGOT_PASS_PATH = '/auth/reset';
  readonly REGISTER_PATH = '/auth/register';
  readonly PROFILE_PATH = '/auth/profile';
  readonly REDIRECT_PATH = '/deals';

  curPage = WizardPage.TYPE;
  showPassword = false;
  agreeToTerms = false;
  loading = false;
  cognitoUser: any;

  userForm = new FormGroup({
    profile: new FormControl('', [Validators.required]),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.pattern("^(?=.*[`!@#$%^&*()_+\\-=\\[\\]{};':\\\\|,.<>\\/?~])(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$")]),
    countryCode: new FormControl('+1', [Validators.required]),
    mobile: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
    companyName: new FormControl('', [Validators.required]),
    jobTitle: new FormControl('', [Validators.required]),
    countryId: new FormControl('', [Validators.required]),
    referredByEmail: new FormControl('', [Validators.email]),
    subType: new FormControl('', [Validators.required]),
    inviteToken: new FormControl('', []),
    timeZone: new FormControl(Intl.DateTimeFormat().resolvedOptions().timeZone, [])
  });

  // Cognito DTO
  user: ICognitoUser;
  userPhone = '';
  smsCode: number[] = [];

  config: Config = initialConfigState;
  lookups:Lookups = initialLookupsState;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private store: Store,
              private toastrService: ToastrService,
              public loader: LoadingService,
              public auth: AuthService,
              public businessUnits: BusinessUnitService) {

    //subscribe to config
    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });

    //sub to lookups
    this.lookup$ = this.store.select(getLookups).subscribe(lookups=>{
      this.lookups = lookups;
    })

    //form user
    this.user = {} as ICognitoUser;
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
          this.userForm.get('profile')?.setValue(params['profile']);
          this.userForm.get('inviteToken')?.setValue(params['invite']);

          //invite from token
          if (!!this.userForm.get('inviteToken')?.value) {
            let invt = this.userForm.get('inviteToken')?.value || '';
            let decoded: any = jwtDecode(invt);
            // console.log(decoded);
            this.userForm.get('firstName')?.setValue(decoded.given_name);
            this.userForm.get('lastName')?.setValue(decoded.family_name);
            this.userForm.get('email')?.setValue(decoded.email);
            this.userForm.get('email')?.disable();
            this.userForm.get('profile')?.setValue('invite');
            this.goTo(WizardPage.REGISTER);
          }

          //profile from query
          if (!!this.userForm.get('profile')?.value && this.userForm.get('profile')?.value !== '') {
            this.goTo(WizardPage.REGISTER);
          }

        }
      );
  }

  ngOnDestroy() {
    this.param$.unsubscribe();
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
  }

  /**
   * Registers the user with Rondeivu API
   */
  public registerUser(): void {
    let payload = this.userForm.getRawValue() as IRegistrationRequest;
    payload.email = this.userForm.get("email")?.value?.toLowerCase() || '';
    // send registration request
    this.loader.start();
    this.auth.addAppUser(payload).subscribe({
      next: res => {
        // go to email verify
        this.goTo(WizardPage.EMAIL_VERIFY);
        this.toastrService.success("Registration Success!", $localize`:@@companyName:Rondeivu`);
        this.loader.stop();
      },
      error: (err: HttpErrorResponse) => {
        // failed to add an app user
        this.failToRegister(err);
      }
    });
  }

  /**
   * Notify the user of registration failure and stop loading
   * @param err
   */
  public failToRegister(err: any) {
    //get the first error returned
    console.log(err);
    let m = '';
    if (!!err && !!err.error) {
      let e = err.error;
      m = (!!e.errors && e.errors.length > 0) ? e.errors[0] : 'unknown';
    }
    this.toastrService.error("Registration Failed: " + m, $localize`:@@companyName:Rondeivu`);
    this.loader.stop();
  }

  /**
   * Verify the user email and register with the
   */
  public confirmEmailCode(code: string): void {
    const email = this.userForm.get('email')?.value;
    if (!!email) {
      this.loader.start();
      Auth.confirmSignUp(email.toLowerCase(), code)
        .then((cognitoUser: any) => {
          // sign in the user so we can get a token
          this.signInAndRedirect();
        }).catch((err: any) => {
        // email confirmation code is NOT verified
        console.log(err);
        this.failToRegister({errors: [err.code]});
      });
    }
    // this.loader.stop();
  }


  /**
   * Signs the user into Cognito, fetches the user business units, and redirects to app
   */
  public signInAndRedirect() {
    const email = this.userForm.get('email')?.value;
    const password = this.userForm.get('password')?.value;
    if (!!email && !!password) {
      Auth.signIn(email.toLowerCase(), password).then((res: any) => {
        // Sign in OK
        this.fetchAndDispatchUserBusinessUnits();
      }, (err: any) => {
        this.failToRegister({errors: [err.code]});
      });
    }
  }

  /**
   * Fetches the user business units and dispatch to store
   */
  public fetchAndDispatchUserBusinessUnits() {
    // OK - fetch and dispatch the business units
    this.businessUnits.getUserBusinessUnits().subscribe({
      next: res => {
        // console.log(res);
        // there MUST be business units to continue in to app
        if (!!res && res.length > 0) {
          // dispatch the BU and select the first
          this.store.dispatch(updateBusinessUnits({businessUnits: res}));
          this.store.dispatch(updateSelectedBusinessUnit({businessUnit: res[0]}));
          let path = '/' + this.config.selected_business_unit.slug + '/' + this.REDIRECT_PATH;
          this.router.navigate([path]).then(() => {
            this.loader.stop();
            this.toastrService.success("Welcome to Rondeivu!", $localize`:@@companyName:Rondeivu`);
          });
        } else {
          // no user business units
          this.failToRegister({errors: ['No Business Units']});
        }
      }, error: err => {
        // failed to fetch business units
        this.failToRegister(err);
      }
    });
  }

  /**
   * Fetch and dispatch user to store
   * @private
   */
  private fetchAndDispatchCognitoUser() {
    Auth.currentUserInfo()
      .then((user: any) => {
        this.store.dispatch(updateCognitoUser({cognito: user}));
        // Auth.currentSession().then((session) => {
        //   if (session.isValid()) {
        //     let token = session.getIdToken().getJwtToken();
        //     console.log("Setting Token...");
        //     localStorage.setItem('cognito', token);
        //     console.log("Logging In...");
        //   }
        // });
      });
  }

  /**
   * Request a new email verification code
   */
  public requestEmailCode(): void {
    const email = this.userForm.get('email')?.value;
    if (!!email) {
      this.loading = true;
      // console.log(this.user);
      Auth.resendSignUp(email.toLowerCase())
        .then(() => {
          this.loading = false;
          this.toastrService.success('Email Verification Code Sent', $localize`:@@companyName:Rondeivu`);
        }).catch((err: any) => {
        this.toastrService.error(err.message, $localize`:@@companyName:Rondeivu`);
        this.loading = false;
      });
    }
  }

  /**
   * Resend the email code using cognito
   */
  public resendEmailCode(): void {
    const email = this.userForm.get('email')?.value;
    if (!!email) {
      this.loading = true;
      // console.log(this.user);
      Auth.resendSignUp(email.toLowerCase())
        .then((cognitoUser: any) => {
          this.loading = false;
          this.toastrService.success('Email Verification Code Sent', $localize`:@@companyName:Rondeivu`);
          return Auth.updateUserAttributes(cognitoUser, this.userForm.getRawValue());
        });
      this.loading = false;
    }
  }

  /**
   * Sign the user into user pool
   */
  public async signInEmailAndPass() {
    this.loader.start();
    try {
      this.cognitoUser = await Auth.signIn(this.user.email.toLowerCase(), this.user.password);
      // console.log(this.cognitoUser);
      switch (this.cognitoUser.challengeName) {
        case 'SMS_MFA':
          this.smsCode = [];
          this.userPhone = this.cognitoUser.challengeParam['CODE_DELIVERY_DESTINATION'];
          //redirect to verify
          // this.router.navigate([this.VERIFY_PATH]);
          console.log("MFA Challenge!");
          this.curPage = WizardPage.PHONE_VERIFY;
          break;
        case 'PASSWORD_VERIFIER':
          // alert('hit');
          break;
        default:
      }
    } catch (err: any) {
      console.log(err);
      this.toastrService.error(err.code, $localize`:@@companyName:Rondeivu`);
      switch (err.code) {
        case 'PasswordResetRequiredException':
          this.router.navigate([this.FORGOT_PASS_PATH]);
          break;
        case 'UserNotConfirmedException':
          this.goTo(WizardPage.EMAIL_VERIFY);
          break;
        case 'NotAuthorizedException':
        case 'UserNotFoundException':
        default:
      }
    }
    this.loader.stop();
  }

  /**
   * Confirm the user MFA code
   */
  public confirmSmsCode(code: string) {
    this.loader.start();

    Auth.confirmSignIn(
      this.cognitoUser, // Return object from Auth.signIn()
      code, // Confirmation code
      this.cognitoUser.challengeName // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    ).then((loggedUser: any) => {
      console.log("MFA Verified!");
      // console.log(loggedUser);

      Auth.currentUserInfo()
        .then((user: any) => {
          // console.log(user);
          this.store.dispatch(updateCognitoUser({cognito: user}));
          // cognito user is successfully registered and verified
          // now register with Rondeivu API
          //TODO The API registration needs to be done after cognito registration but before the verification
          this.auth.legacyAddAppUser(user.attributes['sub'], this.user).subscribe((res: any) => {
            // console.log(JSON.stringify(res));
          });

          this.router.navigate([this.REDIRECT_PATH]).then(() => {
            this.toastrService.success('Thank you for registering!', $localize`:@@companyName:Rondeivu`)
          });
        });
      // get the session and dispatch the user
      // this.fetchAndDispatchCognitoUser();
    }).catch((err: any) => {
      this.toastrService.error(err.code, $localize`:@@companyName:Rondeivu`);
      switch (err.code) {
        case 'UserNotConfirmedException':
        case 'PasswordResetRequiredException':
        case 'NotAuthorizedException':
        case 'UserNotFoundException':
        case 'CodeMismatchException':

        default:
          console.log(err);
      }
    });
    this.loader.stop();
  }

  public requestSmsCode(): void {
    this.signInEmailAndPass();
  }


  private getErrType(err: any) {
    return err.toString().split(":")[0];
  }

  private getUnixTimeStamp(): string {
    const date = new Date();
    return Math.floor(date.getTime() / 1000).toString();
  }

  /**
   * logs out of user pool
   */
  public async logOut(): Promise<void> {
    Auth.signOut().then(() => {
      localStorage.clear();
      this.store.dispatch(updateCognitoUser({cognito: initialCognitoUserState}));
      this.goTo(WizardPage.REGISTER);
    })
  }

  public goToReg(type: string) {
    this.userForm.get('profile')?.setValue(type);
    this.goTo(WizardPage.REGISTER);
  }

  public goTo(page: number) {
    this.loader.start();
    this.curPage = page;
    this.loader.stop();
  }

}
