<!--The KYC document dataroom-->
<app-data-room *ngIf="!!selectedKyc.dataroomId"
               [dataroomType]="DataRoomType.KYC"
               pageTitle="KYC Document Upload"
               pageDesc="Please upload the filled-in KYC form(s) you have downloaded, along with documents requested in the KYC form(s)."
               [pageIcon]="(!util.isType('admin') && selectedKyc.status != 'PENDING') ? 'lock' : ''"
               [pageTooltip]="(!util.isType('admin') && selectedKyc.status != 'PENDING') ? 'Upload is locked' : ''"
               [showRootFolders]="util.isType('admin')"
               [showNewFolderButton]="util.isType('admin')"
               [showDeleteButton]="util.isType('admin')"
               [showEditButton]="util.isType('admin')"
               [showFileMove]="util.isType('admin')"
               [showAddFileButton]="util.isType('admin') || selectedKyc.status == 'PENDING'"
               [canDropFiles]="util.isType('admin') || selectedKyc.status == 'PENDING'"
               [showHistoryButton]="util.isType('admin')"
               [dataRoomId]="selectedKyc.dataroomId"
>
</app-data-room>
