import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdminRoutingModule} from './admin-routing.module';
import {
  AdminConfigComponent,
  AdminPanelComponent,
  RondeivuEmployeesComponent,
  RondeivuEmployeeModalComponent,
  AdminConfigModalComponent,
  CloudwatchSnsLogComponent,
  CloudwatchSnsBounceLogComponent,
  CloudwatchSnsComplaintLogComponent,
  PermissionsComponent,
  UserEmailNotificationsComponent,
  CloudwatchSnsDeliveryLogComponent
} from './components';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgMaterialModule} from "../ng-material/ng-material.module";
import {AdminNavigationComponent} from './components';
import {SharedModule} from "../shared/shared.module";
import {HttpClientModule} from "@angular/common/http";
import {MandatesRoutingModule} from "../mandates/mandates-routing.module";


@NgModule({
  declarations: [
    AdminPanelComponent,
    AdminNavigationComponent,
    RondeivuEmployeesComponent,
    RondeivuEmployeeModalComponent,
    AdminConfigComponent,
    AdminConfigModalComponent,
    CloudwatchSnsLogComponent,
    CloudwatchSnsDeliveryLogComponent,
    CloudwatchSnsBounceLogComponent,
    CloudwatchSnsComplaintLogComponent,
    PermissionsComponent,
    UserEmailNotificationsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AdminRoutingModule,
    ReactiveFormsModule,
    MandatesRoutingModule,
    HttpClientModule,
    NgMaterialModule,
    SharedModule
  ]
})
export class AdminModule {
}
