import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {
  Config,
  getConfig, getEditingBusinessUnitEmployee,
  getLookups,
  getSelectedBusinessUnit,
  initialConfigState,
  initialLookupsState,
  Lookups, toggleLoading
} from "../../../redux";
import {UserCardSize} from "../../../shared/components";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {UtilService} from "../../../shared/services";
import {BusinessUnitService} from "../../services";

@Component({
  selector: 'app-business-unit-employee-info',
  templateUrl: './business-unit-employee-info.component.html',
  styleUrls: ['./business-unit-employee-info.component.scss']
})
export class BusinessUnitEmployeeInfoComponent implements OnInit {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  selectedEmployee$: Subscription = new Subscription();
  private config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;

  selectedEmployee: any = {} as unknown as any;
  userCardSize = UserCardSize;

  //reactive form
  userForm = new FormGroup({
    id: new FormControl(),
    title: new FormControl('', Validators.required),
    isEmployeeActive: new FormControl(false),
  });

  constructor(
    private store: Store,
    private router: Router,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public util: UtilService,
    private businessUnitService: BusinessUnitService
  ) {
    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });
    this.lookup$ = this.store.select(getLookups).subscribe(lookups => {
      this.lookups = lookups;
    });
    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe(selectedBusinessUnit => {

    });

    this.selectedEmployee$ = this.store.select(getEditingBusinessUnitEmployee).subscribe((employee: any) => {
      this.selectedEmployee = employee;
      this.subscribeToDatasource();
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedEmployee$.unsubscribe();
  }

  subscribeToDatasource() {
    this.getEmployeeInfo();
  }

  getEmployeeInfo() {
    if (!!this.selectedEmployee?.id) {
      this.store.dispatch(toggleLoading({loading: true}));
      this.businessUnitService.getEmployee(this.selectedEmployee.id).subscribe({
        next: (res: any) => {
          this.store.dispatch(toggleLoading({loading: false}));
          this.userForm.patchValue(res);
          // this.toastr.success('Employee Loaded', $localize`:@@companyName:Rondeivu`);
        }, error: (err: any) => {
          this.store.dispatch(toggleLoading({loading: false}));
          this.toastr.error("Unable to get employee!", $localize`:@@companyName:Rondeivu`);
        }
      });
    }
  }

  save() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.businessUnitService.saveEmployee(this.userForm.get('id')?.value, this.userForm.getRawValue()).subscribe({
      next: (res: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success('Employee Information Updated', $localize`:@@companyName:Rondeivu`);
      }, error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to update employee!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  back() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.router.navigate(['/' + this.config.selected_business_unit.slug + '/settings/employees/']).then(() => {
      this.store.dispatch(toggleLoading({loading: false}));
    });
  }

}
