<div class="rm-header">
  <span>Notes</span>
</div>
<mat-dialog-content>
  <div style="padding-top:15px; min-width: 500px; min-height: 150px;">
    <span>{{ data.notes }}</span>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div style="width: 100%; display: flex; justify-items: end; justify-content: end;">
    <button mat-raised-button type="button" (click)="dialogRef.close()">CLOSE</button>
  </div>
</mat-dialog-actions>

