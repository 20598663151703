<div class="rm-header">
  <span>Investor Details</span>
</div>
<!--form-->
<form [formGroup]="userForm" (ngSubmit)="submit()">
  <div mat-dialog-content style="padding-top: 50px;">
    <!--info-->
    <div class="row justify-content-center" style="padding-bottom: 50px;">
      <div class="col-11">
        <!--        row start-->
        <div class="row" style="padding-bottom: 30px;">
          <div class="col-12">
        <span
          style="font-weight: bolder; font-size: large; margin-right: 10px;">{{data?.businessUnit?.displayName}}</span>
            <span [appStatus]="data?.kycStatus">{{data?.kycStatus}}</span>
          </div>
        </div>
        <!--        row start-->
        <div class="row" style="padding-bottom: 10px;">
          <div class="col-12 col-md-3">
            <span style="font-weight: lighter; min-width: 250px;">Administrators:</span>
          </div>
          <div class="col-12 col-md-9">
            <div *ngFor="let admin of data?.businessUnitEmployees" style="display: inline-flex;">
              <div class="pill"
                   [class]="(admin?.firstName != '') && (admin?.lastName != '') ? 'grey-pill' : 'yellow-pill'"
                   (click)="view(admin)">
                <span>{{(admin?.firstName != '') && (admin?.lastName != '') ? (admin.firstName + ' ' + admin.lastName) : admin?.email}}</span>
              </div>
            </div>
          </div>
        </div>
        <!--        row start-->
        <div class="row" style="padding-bottom: 10px;">
          <div class="col-12 col-md-3">
            <span style="font-weight: lighter;">Users On the Deal Team:</span>
          </div>
          <div class="col-12 col-md-9">
            <div *ngFor="let user of data?.dealTeamMembers" style="display: inline-flex;">
              <div class="pill"
                   [class]="(user?.firstName != '') && (user?.lastName != '') ? 'grey-pill' : 'yellow-pill'"
                   (click)="view(user)">
                <span>{{(user?.firstName != '') && (user?.lastName != '') ? (user.firstName + ' ' + user.lastName) : user?.email}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center" style="padding-bottom: 50px;">
      <div class="col-12 col-md-4" style="padding-bottom: 50px;">
        <mat-label>INVESTOR DEAL-BY-DEAL NDA STATUS</mat-label>
        <br/>
        <mat-checkbox formControlName="isDealNdaSigned" style="padding-top: 20px;" color="primary"> NDA Signed
        </mat-checkbox>
      </div>
      <div class="col-12 col-md-4" style="padding-bottom: 50px;">
        <mat-label>INVESTOR DEAL-BY-DEAL NDA STATUS</mat-label>
        <br/>
        <mat-checkbox formControlName="isIssuerNdaSigned" style="padding-top: 20px;" color="primary"> Issuer Deal NDA
          Signed
        </mat-checkbox>
      </div>
      <div class="col-12 col-md-4">
        <mat-label>INVESTOR WORKFLOW STATUS</mat-label>
        <br/>
        <mat-form-field class="form-field" appearance="outline">
          <mat-select id="status" formControlName="investorWorkflowStatus">
            <mat-option *ngFor="let c of lookups.investorDealWorkflowStatus" [value]="c.key">
              {{c.value}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="userForm.invalid">Please choose one.</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div mat-dialog-actions align="center" class="justify-content-between">
    <button mat-raised-button type="button" (click)="dialogRef.close()">CANCEL</button>
    <button mat-raised-button color="primary" type="submit">SUBMIT</button>
  </div>
</form>

