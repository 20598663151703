import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {
  Config, fetchTasks,
  getConfig,
  getLookups, getSelectedBusinessUnit,
  initialConfigState,
  initialLookupsState,
  Lookups,
  toggleLoading,
  updateUserSettings
} from "../../../redux";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {UserSettingsService} from "../../services";
import {Auth} from "aws-amplify";
import {MatDialog} from "@angular/material/dialog";
import {PhoneVerifyModalComponent} from "./phone-verify-modal/phone-verify-modal.component";
import {IUserSettings} from "../../models/i-user-settings";
import {ModalSize, UtilService} from "../../../shared/services/util/util.service";
import {ConfirmationModalComponent, UserCardModalComponent, UserCardSize} from "../../../shared/components";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  private readonly LOGIN = '/auth/login';
  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();

  private config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;

  userCardSize = UserCardSize;

  //reactive form
  userForm = new FormGroup({
    id: new FormControl(),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    jobTitle: new FormControl('', Validators.required),
    companyName: new FormControl('', Validators.required),
    email: new FormControl({value: '', disabled: true}, [Validators.email, Validators.required]),
    emailVerified: new FormControl(false),
    phoneNumberVerified: new FormControl(false),
    mfaType: new FormControl(),
    subId: new FormControl(),
    created: new FormControl(),
    profileLink: new FormControl(),
    imageUrl: new FormControl()
  });

  phoneForm = new FormGroup({
    id: new FormControl(),
    countryCode: new FormControl('+1', Validators.required),
    mobile: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")])
  });


  adminForm = new FormGroup({
    id: new FormControl(),
    oldPassword: new FormControl('', [Validators.required,
      Validators.pattern("^(?=.*[`!@#$%^&*()_+\\-=\\[\\]{};':\\\\|,.<>\\/?~])(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$")]),
    newPassword: new FormControl('', [Validators.required,
      Validators.pattern("^(?=.*[`!@#$%^&*()_+\\-=\\[\\]{};':\\\\|,.<>\\/?~])(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$")]),
  });

  constructor(
    private store: Store,
    private router: Router,
    private toastr: ToastrService,
    private userSettingsService: UserSettingsService,
    private dialog: MatDialog,
    public util: UtilService
  ) {
    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });
    this.lookup$ = this.store.select(getLookups).subscribe(lookups => {
      this.lookups = lookups;
    });
    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe(selectedBusinessUnit => {
      if (!!selectedBusinessUnit && !!selectedBusinessUnit.businessUnitId) {
        this.subscribeToDatasource();
      }
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
  }

  subscribeToDatasource(): void {
    // this.store.dispatch(toggleLoading({loading: true}));
    this.userSettingsService.getUserSettings().subscribe({
      next: (userSettings: IUserSettings) => {
        this.userForm.patchValue(userSettings);
        this.phoneForm.patchValue(userSettings);
        // if (this.userForm.get('phoneNumberVerified')?.value == true) {
        //   this.userForm.get('countryCode')?.disable();
        //   this.userForm.get('mobile')?.disable();
        // }
      }, error: error => {
        this.toastr.error("Something went wrong!");
      }
    });
  }

  save() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.userSettingsService.updateUserSettings(this.userForm.getRawValue()).subscribe({
      next: (userSettings: any) => {
        this.userForm.patchValue(userSettings);
        this.phoneForm.patchValue(userSettings);
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("User Settings Saved!", $localize`:@@companyName:Rondeivu`);
      }, error: error => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Something went wrong!");
      }
    });
  }

  savePhone() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.userSettingsService.setPhone(this.phoneForm.getRawValue()).subscribe({
      next: (res: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Phone 2FA Sent!", $localize`:@@companyName:Rondeivu`);
        this.openPhoneVerifyModal();
      }, error: error => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Something went wrong!");
      }
    });
  }

  openPhoneVerifyModal() {
    const phoneModal = this.dialog.open(PhoneVerifyModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      disableClose: true,
      data: this.phoneForm.getRawValue()
    });

    phoneModal.afterClosed().subscribe(result => {
      console.log("Dialog closed!");
      if (!!result) {
        this.verifyPhone(result.id, result.code);
      }
    });
  }

  private verifyPhone(id: string, code: string) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.userSettingsService.verifyPhone(id, code).subscribe({
      next: (res: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Phone 2FA Verified!", $localize`:@@companyName:Rondeivu`);
        this.subscribeToDatasource();
        this.store.dispatch({type: fetchTasks});
      }, error: error => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Invalid 2FA Code!");
      }
    });
  }

  private subscribeToMFA(countryCode: string, mobile: string) {
    Auth.currentAuthenticatedUser().then((user: any) => {
      // You can select preferred mfa type, for example:
      Auth.updateUserAttributes(user, {
        phone_number: countryCode + mobile
      }).then(() => {
        // Select TOTP as preferred
        Auth.setPreferredMFA(user, 'SMS_MFA')
          .then((data: any) => {
            // console.log(data);
            this.toastr.success("SMS MFA Applied!", $localize`:@@companyName:Rondeivu`);
            this.router.navigate([this.LOGIN]).then(() => {
              Auth.signOut().then((user: any) => {
                this.toastr.info("Please login to verify your phone number.", $localize`:@@companyName:Rondeivu`);
              });
            });
          })
          .catch((e: any) => {
            console.log(e);
            this.toastr.error("Unable to apply SMS MFA!", $localize`:@@companyName:Rondeivu`);
          });
      });
    });
  }

  /**
   * Updates the cognito user password
   */
  changePassword() {
    const oldPass = this.adminForm.get('oldPassword')?.value;
    const newPass = this.adminForm.get('newPassword')?.value;

    if (!!oldPass && !!newPass) {
      Auth.currentAuthenticatedUser()
        .then((user: any) => {
          return Auth.changePassword(user, oldPass, newPass);
        })
        .then((data: any) => {
          // console.log(data);
          this.adminForm.reset();
          this.adminForm.get('oldPassword')?.setErrors(null);
          this.adminForm.get('newPassword')?.setErrors(null);
          this.toastr.success("Password Updated!", $localize`:@@companyName:Rondeivu`);
        })
        .catch((err: any) => {
          console.log(err);
          this.toastr.error("Something went wrong! " + err.message);
        });
    }
  }

  openImageUploadModal() {
    const dialogRef = this.dialog.open(UserCardModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog closed!");
      if (!!result) {
        const uId = this.userForm.get('id')?.value || '';
        const file = new File([result], 'user.png');
        this.userSettingsService.uploadUserImage(file, uId).subscribe({
          next: (res: any) => {
            let url = res.url || ''
            this.userForm.get('imageUrl')?.setValue(url);
            const u = this.userForm.getRawValue() as {} as IUserSettings
            this.store.dispatch(updateUserSettings({user: u}));

            this.toastr.success("Image Uploaded! ", $localize`:@@companyName:Rondeivu`);
          }, error: (err: any) => {
            this.toastr.error("Something went wrong! " + err.message);
          }
        });
      }
    });
  }

  clearImage() {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog closed!");
      if (!!result) {
        const uId = this.userForm.get('id')?.value || '';
        this.userSettingsService.clearUserImage(uId).subscribe({
          next: (res: any) => {
            let url = res.url || ''
            this.userForm.get('imageUrl')?.setValue(url);
            this.toastr.success("Image Cleared! ", $localize`:@@companyName:Rondeivu`);
          }, error: (err: any) => {
            this.toastr.error("Something went wrong! " + err.message);
          }
        });
      }
    });
  }
}
