<!-- <div [@fadeIn]="animationState" (@fadeIn.start)="animStart($event)" > -->
<div>
  <!-- <div [@slideOutLeft]="animateExit" (@slideOutLeft.done)="exitEnd($event)"> -->
  <div>
    <mat-toolbar id="toolbar" dense [ngStyle]="{'background-color':theme.subheader_bg, 'color':theme.subheader_fg}">
      <button mat-button (click)="goBack()" [matTooltip]="'Show My Company'"
              matTooltipPosition="below">
        <mat-icon>list</mat-icon>
      </button>
      &nbsp;
      <div id="company-header" *ngIf="!loading">
        <div id="nav-header" *ngIf="!loading">
          <app-user-card [firstName]="selectedEmployee?.user?.firstName"
                         [lastName]="selectedEmployee?.user?.lastName"
                         [displayName]="selectedEmployee?.user?.email"
                         [borderColor]="'transparent'"
                         [sideColor]="'transparent'"
                         [image]="selectedEmployee?.user?.imageUrl"
                         [imageShape]="'circle'"
          ></app-user-card>
        </div>
      </div>

      <div style="margin-left: 18px;">
        <mat-spinner *ngIf="loading" [diameter]="36" color="accent"></mat-spinner>
      </div>
    </mat-toolbar>
    <nav id="deal-nav" dense
         mat-tab-nav-bar
         [tabPanel]="tabPanel"
         style="width: 100%"
         [ngStyle]="{'background-color': theme.subheader_bg}"
    >
      <div *ngFor="let link of links">
        <a *ngIf="link.enabled"
           mat-tab-link
           (click)="activeLink = link; navigate(link)"
           [active]="activeLink.link == link.link"
           [disabled]="!link.enabled"
           [hidden]="link.hidden"
           [ngStyle]="{'background-color':theme.subheader_bg, 'color':theme.subheader_fg}"
        ><span>{{ link.title }}</span></a>
        <span class="fake-tab" *ngIf="!link.enabled" [matTooltip]="link.toolTip"
              matTooltipPosition="below">{{ link.title }}</span>
      </div>
    </nav>
    <mat-tab-nav-panel #tabPanel>
      <div [@appFade]="animationState">
        <router-outlet></router-outlet>
      </div>
    </mat-tab-nav-panel>
  </div>
</div>

