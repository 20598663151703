import {Component, OnDestroy, OnInit} from '@angular/core';
import {ViewerService} from "../../services";
import {ActivatedRoute} from "@angular/router";
import {Subscription, tap} from "rxjs";
import {
  Config,
  getConfig,
  getSelectedBusinessUnit,
  initialConfigState,
  showNavigationMenu, updateDealId,
} from "../../modules/redux";
import {IBusinessUnit} from "../../modules/business-units/models";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {NdaType} from "../../modules/deals/models";

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent implements OnInit, OnDestroy {
  NdaType = NdaType;
  config$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  config: Config = initialConfigState;
  bu: IBusinessUnit = {} as unknown as IBusinessUnit;
  public base64: string | ArrayBuffer | Blob | Uint8Array | URL | { range: any; } = '';

  constructor(
    private viewerService: ViewerService,
    private route: ActivatedRoute,
    private store: Store,
    private toastr: ToastrService
  ) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.bu = Object.assign({}, bu);
      if (!!this.bu && !!this.bu.businessUnitId) {
        this.getDocument();
      }
    });
  }

  ngOnInit(): void {
    this.store.dispatch(showNavigationMenu({show: false}));
  }

  ngOnDestroy() {
    this.selectedBusinessUnit$.unsubscribe();
    this.config$.unsubscribe();
  }

  getDocument() {
    const docId = this.route.snapshot.paramMap.get('id') || '';
    const dealId = this.route.snapshot.queryParams['dealId'];
    this.store.dispatch(updateDealId({dealId: dealId}));

    if (!!docId) {
      const staticDocs = Object.keys(NdaType);
      if (staticDocs.includes(docId)) {
        //static nda documents
        this.viewerService.viewNdaTemplate(docId).pipe(
          tap((base64) => (this.base64 = base64)),
        ).subscribe({
          next: res => {
            // console.log(res);
          }, error: error => {
            this.toastr.error("Unable to view document!", $localize`:@@companyName:Rondeivu`);
          }
        });
      } else {
        //documents by id
        this.viewerService.viewDocument(docId).pipe(
          tap((base64) => (this.base64 = base64)),
        ).subscribe({
          next: res => {
            // console.log(res);
          }, error: error => {
            this.toastr.error("Unable to view document!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    }
  }
}

