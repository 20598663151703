import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {createReducer, on} from "@ngrx/store";
import * as BusinessUnitActions from './business-unit.actions';
import {IBusinessUnit} from "../../../business-units/models";


export interface BusinessUnitState extends EntityState<IBusinessUnit> {
  selectedBusinessUnitId: string | null;
}

export const adapter: EntityAdapter<IBusinessUnit> = createEntityAdapter<IBusinessUnit>({
  selectId: selectBusinessUnitId,
  sortComparer: sortByName,
});

export const initialBusinessUnitState: BusinessUnitState = adapter.getInitialState({
  // additional entity state properties
  selectedBusinessUnitId: null,
});

export const businessUnitReducer = createReducer(initialBusinessUnitState,
  on(BusinessUnitActions.addBusinessUnit, (state, {businessUnit}) => {
    return adapter.addOne(businessUnit, state)
  }),
  on(BusinessUnitActions.setBusinessUnit, (state, {businessUnit}) => {
    return adapter.setOne(businessUnit, state)
  }),
  on(BusinessUnitActions.upsertBusinessUnit, (state, {businessUnit}) => {
    return adapter.upsertOne(businessUnit, state);
  }),
  on(BusinessUnitActions.addBusinessUnits, (state, {businessUnits}) => {
    return adapter.addMany(businessUnits, state);
  }),
  on(BusinessUnitActions.upsertBusinessUnits, (state, {businessUnits}) => {
    return adapter.upsertMany(businessUnits, state);
  }),
  on(BusinessUnitActions.updateBusinessUnit, (state, {update}) => {
    return adapter.updateOne(update, state);
  }),
  on(BusinessUnitActions.updateBusinessUnits, (state, {updates}) => {
    return adapter.updateMany(updates, state);
  }),
  on(BusinessUnitActions.mapBusinessUnit, (state, {entityMap}) => {
    return adapter.mapOne(entityMap, state);
  }),
  on(BusinessUnitActions.mapBusinessUnits, (state, {entityMap}) => {
    return adapter.map(entityMap, state);
  }),
  on(BusinessUnitActions.deleteBusinessUnit, (state, {id}) => {
    return adapter.removeOne(id, state);
  }),
  on(BusinessUnitActions.deleteBusinessUnits, (state, {ids}) => {
    return adapter.removeMany(ids, state);
  }),
  on(BusinessUnitActions.deleteBusinessUnitsByPredicate, (state, {predicate}) => {
    return adapter.removeMany(predicate, state);
  }),
  on(BusinessUnitActions.loadBusinessUnits, (state, {businessUnits}) => {
    return adapter.setAll(businessUnits, state);
  }),
  on(BusinessUnitActions.setBusinessUnits, (state, {businessUnits}) => {
    return adapter.setMany(businessUnits, state);
  }),
  on(BusinessUnitActions.clearBusinessUnits, state => {
    return adapter.removeAll({...state, selectedBusinessUnitId: null});
  })
);

export const getSelectedBusinessUnitId = (state: BusinessUnitState) => state.selectedBusinessUnitId;

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of businessUnit ids
export const selectBusinessUnitIds = selectIds;

// select the dictionary of businessUnit entities
export const selectBusinessUnitEntities = selectEntities;

// select the array of businessUnits
export const selectAllBusinessUnits = selectAll;

// select the total businessUnit count
export const selectBusinessUnitTotal = selectTotal;

export function selectBusinessUnitId(a: IBusinessUnit): string {
  //In this case this would be optional since primary key is id
  return a.businessUnitId;
}

export function sortByName(a: IBusinessUnit, b: IBusinessUnit): number {
  if (!a.displayName || !b.displayName) {
    return 0;
  }
  return a.displayName.localeCompare(b.displayName);
}


