import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DashboardRoutingModule} from './dashobard-routing.module';
import {
  ChartComponent,
  DashboardComponent,
  InvestorDashboardComponent,
  IssuerDashboardComponent,
  AdminDashboardComponent, PendingDealsComponent
} from './components';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {GoogleMapsModule} from '@angular/google-maps';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MandateModalComponent} from '../mandates/components';
import {HeartbeatComponent} from './components/heartbeat/heartbeat.component';
import {MockDealCardService} from "./services";
import {CommonDashboardComponent} from './components/common-dashboard/common-dashboard.component';
import {NgMaterialModule} from "../ng-material/ng-material.module";
import {LiveDealsComponent} from './components';
import {ClosedDealsComponent} from './components';
import {SharedModule} from "../shared/shared.module";


@NgModule({
  declarations: [
    ChartComponent,
    InvestorDashboardComponent,
    IssuerDashboardComponent,
    AdminDashboardComponent,
    DashboardComponent,
    MandateModalComponent,
    HeartbeatComponent,
    CommonDashboardComponent,
    LiveDealsComponent,
    ClosedDealsComponent,
    PendingDealsComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    DashboardRoutingModule,
    NgxChartsModule,
    GoogleMapsModule,
    FormsModule,
    ReactiveFormsModule,
    NgMaterialModule
  ],
  exports: [

  ],
  providers: [
    MockDealCardService
  ]
})
export class DashboardModule {
}
