<div style="height:calc(100vh - 176px); overflow-y: auto;">
  <div class="container-fluid">
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-12 col-md-11">
        <span class="rm-title rm-title-3">Deal NDA</span><br/>
        <span class="rm-title rm-title-6 rm-lighter">This is where you can sign and review this deal's NDAs.</span>
      </div>
    </div>
    <!--    spinner-->
    <div class="row justify-content-center" *ngIf="loading" style="padding-bottom: 20px; padding-top: 25px;">
      <div class="col-11" style="display: inline-flex;">
        <span style="padding-top: 10px; margin-right: 15px; font-size: large;font-weight: bold;">
          We're processing your NDA status with DocuSign! This could take up to 30 seconds, please wait...
        </span>
        <mat-spinner [diameter]="36" color="accent"></mat-spinner>
      </div>
    </div>
    <!--    comments-->
    <div class="row justify-content-center" style="padding-bottom: 20px; padding-top: 25px;" *ngIf="!!nda.comments">
      <div class="col-11 comment">
        <span>{{ nda?.comments }}</span>
      </div>
    </div>
    <!--    not loading view-->
    <div *ngIf="!loading">
      <div class="row justify-content-center" style="padding-bottom: 20px;" *ngIf="isStatus('COMPLETED')">
        <div class="col-md-11 signed">
          <div style="display: inline-flex;">
            <mat-icon class="signed-icon">info</mat-icon>&nbsp;
            <span class="signed-text">DEAL NDA SIGNED</span>
          </div>
          <br/>
          <span class="signed-subtext">Thank you for completing your deal NDA process. Your acting business unit is now Post-NDA on this deal!</span>
        </div>
      </div>

      <div class="row justify-content-center" style="padding-bottom: 20px;" *ngIf="isStatus('DECLINE')">
        <div class="col-md-11 negotiate">
          <div style="display: inline-flex;">
            <mat-icon class="negotiate-icon">info</mat-icon>&nbsp;
            <span class="negotiate-text">DEAL NDA DECLINED</span>
          </div>
          <br/>
          <span class="negotiate-subtext">You have declined the NDA for this deal. If you wish to negotiate this deal NDA further, please click below to begin this process with Rondeivu.</span>
        </div>
      </div>
      <!--investor nda-->
      <div class="row justify-content-center" style="padding-bottom: 20px;"
           *ngIf="nda.investorShowDealNdaOption || nda.investorShowGlobalNdaOption">
        <!--global-->
        <div class="col-11 col-md-5 nda" style="margin-right: 25px;" *ngIf="nda.investorShowGlobalNdaOption">
          <div style="display: inline-flex;">
            <mat-icon class="nda-icon">border_color</mat-icon>&nbsp;
            <span class="nda-text">SIGN GLOBAL NDA</span>
          </div>
          <br/><br/>
          <span style="margin-top: 10px">
            <p>If you prefer to sign an NDA <b>once</b> and have it apply to all of the deals you review, please have
              an authorized signatory sign this Global NDA.
            </p>
            <p [hidden]="!util.hasPermission(util.Permission.CAN_NEGOTIATE_DEAL_NDA)">
              An authorized signatory must sign an NDA in order for you to get access to additional
                information about your prospective deal. If you are not an authorized signatory, please add them
                to the deal team and have them sign the NDA. You can also
                <a style="font-size: small; cursor: pointer;"
                   (click)="negotiate(NdaType.INVESTOR_GLOBAL_NDA)"
                >negotiate</a>
                the terms of this NDA.
            </p>
          </span>
          <br/>
          <button mat-raised-button
                  (click)="viewNdaTemplate(NdaType.INVESTOR_GLOBAL_NDA)">
            View Global NDA (pdf)
          </button>&nbsp;
          <button mat-raised-button
                  color="primary"
                  (click)="createNda(NdaType.INVESTOR_GLOBAL_NDA)"
                  [disabled]="!util.hasPermission(util.Permission.CAN_INITIATE_DEAL_NDA)"
                  [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_INITIATE_DEAL_NDA) ? util.NoAccessCursor: ''}"
                  [matTooltip]="!util.hasPermission(util.Permission.CAN_INITIATE_DEAL_NDA) ? util.NoAccessMsg : ''"
                  matTooltipPosition="right"
          >Review & Sign Global NDA (DocuSign)
          </button>
          <!-- <br/>
          <a style="font-size: small; cursor: pointer;" (click)="negotiate(NdaType.INVESTOR_GLOBAL_NDA)">Negotiate</a> -->
        </div>
        <!--    deal by deal-->
        <div class="col-11 col-md-5 nda" *ngIf="nda.investorShowDealNdaOption">
          <div style="display: inline-flex">
            <mat-icon class="nda-icon">border_color</mat-icon>&nbsp;
            <span class="nda-text">SIGN DEAL-BY-DEAL NDA</span>
          </div>
          <br/><br/>
          <span style="margin-top: 10px">
            <p>
              If you prefer to sign a new NDA <b>each time</b> you access a deal, please have an authorized
              signatory sign this Deal-by-Deal NDA.
            </p>
            <p [hidden]="!util.hasPermission(util.Permission.CAN_NEGOTIATE_DEAL_NDA)">
              An authorized signatory must sign an NDA in order for you to get access to additional
              information about your prospective deal. If you are not an authorized signatory, please add them
              to the deal team and have them sign the NDA. You can also
              <a style="font-size: small; cursor: pointer;" (click)="negotiate(NdaType.INVESTOR_DEAL_BY_DEAL_NDA)">negotiate</a>
              the terms of this NDA.
            </p>
        </span>
          <br/>
          <button mat-raised-button
                  (click)="viewNdaTemplate(NdaType.INVESTOR_DEAL_BY_DEAL_NDA)">
            View Deal-by-Deal NDA (pdf)
          </button>&nbsp;
          <button mat-raised-button
                  color="primary"
                  (click)="createNda(NdaType.INVESTOR_DEAL_BY_DEAL_NDA)"
                  [disabled]="!util.hasPermission(util.Permission.CAN_INITIATE_DEAL_NDA)"
                  [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_INITIATE_DEAL_NDA) ? util.NoAccessCursor: ''}"
                  [matTooltip]="!util.hasPermission(util.Permission.CAN_INITIATE_DEAL_NDA) ? util.NoAccessMsg : ''"
                  matTooltipPosition="right"
          >Review & Sign Deal-by-Deal NDA (DocuSign)
          </button>
        </div>
      </div>
      <!--issuer nda-->
      <div class="row justify-content-center" style="padding-bottom: 20px;" *ngIf="nda.issuerShowDealNdaOption">
        <div class="col-12 col-md-11 nda">
          <div style="display: inline-flex">
            <mat-icon class="nda-icon">border_color</mat-icon>&nbsp;
            <span class="nda-text">SIGN MUTUAL NDA</span>
          </div>
          <br/><br/>
          <span>
            <p>
              We have prepared a mutual NDA for you and Rondeivu to sign. Please have an authorized
              signatory sign this Mutual NDA. If you are not an authorized signatory, please add them to the
              deal team and have them sign the NDA.
            </p>
            <p [hidden]="!util.hasPermission(util.Permission.CAN_NEGOTIATE_DEAL_NDA)">
              An authorized signatory must sign an NDA in order for you to get access to additional
              information about your prospective deal. If you are not an authorized signatory, please add them
              to the deal team and have them sign the NDA. You can also
              <a style="font-size: small; cursor: pointer;" (click)="negotiate(NdaType.ISSUER_NDA)">negotiate</a>
              the terms of this NDA.
            </p>
          </span>
          <br/>
          <button mat-raised-button
                  (click)="viewNdaTemplate(NdaType.ISSUER_NDA)">
            View NDA (pdf)
          </button>&nbsp;
          <button mat-raised-button
                  color="primary"
                  (click)="createNda(NdaType.ISSUER_NDA)"
                  [disabled]="!util.hasPermission(util.Permission.CAN_INITIATE_DEAL_NDA)"
                  [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_INITIATE_DEAL_NDA) ? util.NoAccessCursor: ''}"
                  [matTooltip]="!util.hasPermission(util.Permission.CAN_INITIATE_DEAL_NDA) ? util.NoAccessMsg : ''"
                  matTooltipPosition="right"
          >Review & Sign NDA (DocuSign)
          </button>
        </div>
      </div>
    </div>
    <!--    nda process table start-->
    <div class="row justify-content-center d-none d-md-flex" style="padding: 25px 0 50px 0;">
      <div class="col-11" style="padding: 0;">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <table mat-table [dataSource]="dataSource"
                     matSort
                     (matSortChange)="sortData($event)"
              >
                <!-- Search Header -->
                <ng-container matColumnDef="table-name">
                  <th colspan="3" mat-header-cell *matHeaderCellDef>
                    <div style="display: flex;">
                      <span style="font-size: large; padding-top: 2px;">DocuSign Deal-by-Deal NDAs Only</span>&nbsp;
                      <mat-icon style="cursor:help;"
                                [matTooltip]="'Only automated DocuSign documents will appear here.  All fully executed NDAs will appear in the Documents tab.'"
                                matTooltipPosition="right">info
                      </mat-icon>
                    </div>
                  </th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="search-header">
                  <th colspan="3" mat-header-cell *matHeaderCellDef>
                    <form>
                      <mat-form-field style="width: 100%;">
                        <mat-label>Search</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" #input>
                        <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                    </form>
                  </th>
                </ng-container>
                <!-- Employee Column -->
                <ng-container matColumnDef="businessUnit">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="company"> Business Unit</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    <app-user-card [firstName]="element.businessUnit?.['displayName']"
                                   [displayName]="element.businessUnit?.['profile']"
                                   [borderColor]="'transparent'"
                                   [sideColor]="'#ff0000'"
                                   [image]="element.businessUnit?.['imageUrl']"
                                   (click)="util.isType('admin')? util.viewBusinessUnit(element.businessUnit?.id) : null"
                                   [ngStyle]="{'cursor': util.isType('admin')? 'pointer':''}"
                                   [highlightOnHover]="util.isType('admin')"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- Employee Column -->
                <ng-container matColumnDef="signatory">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="employee"> Signatory</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    <!--        user menu button-->
                    <app-user-card [firstName]="element.signatory?.['firstName']"
                                   [lastName]="element.signatory?.['lastName']"
                                   [displayName]="element.signatory?.['email']"
                                   [borderColor]="'transparent'"
                                   [background]="'#F1EDE4'"
                                   [sideColor]="'#002150'"
                                   [image]="element.signatory?.['imageUrl']"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- Status Column -->
                <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="type"> Type</th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ getTypeText(element.type) }}</span>
                  </td>
                </ng-container>
                <!-- Last Name Column -->
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="status"> Status</th>
                  <td mat-cell
                      *matCellDef="let element">
                    <span [appStatus]="element['status']">{{ element['status']?.toUpperCase() }}</span>
                  </td>
                </ng-container>
                <!-- Title Column -->
                <ng-container matColumnDef="created">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="created"> Created On</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ element['created'] | date }}
                  </td>
                </ng-container>
                <!-- EDIT Column -->
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef> Actions</th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-icon-button
                            (click)="download(element.envelopId)"
                            [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_NDA)"
                            [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_NDA) ? util.NoAccessCursor: ''}"
                            [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_NDA) ? util.NoAccessMsg : 'Download PDF Document'"
                            matTooltipPosition="left"
                    >
                      <mat-icon>download</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <!--expand-->
                <ng-container matColumnDef="expand">
                  <th mat-header-cell *matHeaderCellDef aria-label="row actions" style="width: 50px;">&nbsp;</th>
                  <td mat-cell *matCellDef="let approval" style="width: 50px;">
                    <button mat-icon-button aria-label="expand row"
                            (click)="(expandedElement = expandedElement === approval ? null : approval); $event.stopPropagation()">
                      <mat-icon *ngIf="expandedElement !== approval">keyboard_arrow_down</mat-icon>
                      <mat-icon *ngIf="expandedElement === approval">keyboard_arrow_up</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                  <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                    <div class="example-element-detail"
                         [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="['table-name','search-header']"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                    class="example-element-row"
                    [class.example-expanded-row]="expandedElement === element">
                  <!--              (click)="expandedElement = expandedElement === element ? null : element">-->
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter.</td>
                </tr>
              </table>
            </div>
          </div>
          <mat-paginator style="background-color: white; z-index: 99;"
                         [pageSizeOptions]="[100, 200, 500]"
                         [pageSize]="100"
                         aria-label="Select page of users">
          </mat-paginator>
        </div>
      </div>
    </div>
    <!--    list start-->
    <div class="row justify-content-center d-flex d-md-none" style="padding-bottom: 150px; padding-top: 15px;">
      <div class="col-12">
        <ul style="list-style-type: none; padding: 25px 0 0 0;">
          <li *ngFor="let element of dataSource.data"
              style="margin-bottom: 5px; display: flex; justify-content: space-between;">
            <app-user-card [firstName]="element?.businessUnit?.displayName"
                           [displayName]="element?.signatory?.firstName + ' ' + element?.signatory?.lastName"
                           [borderColor]="'transparent'"
                           [sideColor]="'#ff0000'"
                           [image]="element?.businessUnit?.['imageUrl']"
                           [imageShape]="'square'"
            ></app-user-card>
            <div style="display: flex;">
              <span style="line-height: 42px;"
                    [appStatus]="element['status']">{{ element['status']?.toUpperCase() }}</span>
              <button mat-icon-button
                      (click)="download(element.envelopId)"
                      [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_NDA)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_NDA) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_NDA) ? util.NoAccessMsg : 'Download PDF Document'"
                      matTooltipPosition="left"
              >
                <mat-icon>download</mat-icon>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
