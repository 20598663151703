import {Directive, HostListener, ElementRef} from '@angular/core';

@Directive({
  selector: '[appCustomScroll]',
  exportAs: 'appCustomScroll'
})
export class CustomScrollDirective {

private readonly BOTTOM_BUFFER = 20;
  disableBtn: boolean = true;
  top: number | undefined;
  offSetHeight: number | undefined;
  scrollHeight: number | undefined;

  constructor(private eleRef: ElementRef) {
  }

  @HostListener('scroll') onScrollEvent(event: Event) {
    this.top = this.eleRef.nativeElement.scrollTop;
    this.offSetHeight = this.eleRef.nativeElement.offsetHeight;
    this.scrollHeight = this.eleRef.nativeElement.scrollHeight;
    if (this.top === 0) {
      this.disableBtn = true;
    }
    if(!!this.top && !!this.offSetHeight && !!this.scrollHeight){
      if (this.top >= this.scrollHeight - this.offSetHeight - this.BOTTOM_BUFFER) {
        this.disableBtn = false;
      }
    } else {
      this.disableBtn = true;
    }
  }
}
