import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  CognitoUser,
  Config, fetchTasks, getCognitoUser, getConfig, getSelectedBusinessUnit,
  initialCognitoUserState,
  initialConfigState,
  toggleLoading
} from "../../../redux";
import {IDealCard} from "../../../shared/models";
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {MockDealCardService} from "../../services";
import {DealService} from "../../../deals/services";
import {IBusinessUnit} from "../../../business-units/models";
import {Subscription} from "rxjs";
import {DealModalComponent} from "../../../deals/components";
import {MatDialog} from "@angular/material/dialog";
import {MandateModalComponent} from "../../../mandates/components";
import {ToastrService} from "ngx-toastr";
import {MandateService} from "../../../mandates/services";
import {ModalSize, UtilService} from "../../../shared/services/util/util.service";

@Component({
  selector: 'app-pending-deals',
  templateUrl: './pending-deals.component.html',
  styleUrls: ['./pending-deals.component.scss']
})
export class PendingDealsComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  cognito$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();

  readonly types: string[] = ['pending', 'onboarding'];
  config: Config = initialConfigState;
  deals: IDealCard[] = [];
  showDealNotification = false;
  showMandateNotification = true;
  cognito: CognitoUser = initialCognitoUserState;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private store: Store,
              private dealCardService: MockDealCardService,
              private dealService: DealService,
              private mandateService: MandateService,
              private dialog: MatDialog,
              private toastr: ToastrService,
              public util: UtilService
  ) {

    this.cognito$ = this.store.select(getCognitoUser).subscribe((cognito: any) => {
      this.cognito = cognito;
    });

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
      this.showDealNotification = config.selected_user_view.toLowerCase() == 'issuer';
      this.showMandateNotification = config.selected_user_view.toLowerCase() == 'investor';
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      if (!!bu && !!bu.businessUnitId && bu.businessUnitType == 'ISSUER') {
        this.loadDeals();
      }
    });
  }


  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.cognito$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
  }

  getName() {
    let n = '';
    if (!!this.cognito && !!this.cognito.attributes) {
      n = this.cognito.attributes['given_name'] + ' ' + this.cognito.attributes['family_name'];
    }
    return n;
  }

  getDate() {
    return new Date().toLocaleDateString('en-US');
  }

  loadDeals() {
    console.log("Fetching pending deals...");
    this.dealService.getDeals(this.types.join(',')).subscribe((deals: IDealCard[]) => {
      // console.log(JSON.stringify(deals));
      this.deals = Object.assign([], deals);
    });
  }

  /**
   * Deal Modal
   */
  openDealModal() {
    const dealModalRef = this.dialog.open(DealModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.MEDIUM),
      disableClose: true
    });

    dealModalRef.afterClosed().subscribe((result: any) => {
      // console.log(`Dialog result: ${result}`);
      if (!!result) {
        console.log("Creating deal...");
        this.store.dispatch(toggleLoading({loading: true}));
        this.dealService.addDeal(result).subscribe({
          next: (res: IDealCard) => {
            this.loadDeals();
            this.store.dispatch(toggleLoading({loading: false}));
            this.store.dispatch({type: fetchTasks});
            this.toastr.success("Deal Created!", $localize`:@@companyName:Rondeivu`);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to create deal!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  /**
   * Mandate modal
   */
  openMandateModal() {
    const mandateModalRef = this.dialog.open(MandateModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.MEDIUM),
      disableClose: true
    });

    mandateModalRef.afterClosed().subscribe((result: any) => {
      // console.log(`Dialog result: ${result}`);
      if (!!result) {
        console.log("Creating mandate...");
        this.store.dispatch(toggleLoading({loading: true}));
        this.mandateService.addMandate(result).subscribe({
          next: (res: any) => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.store.dispatch({type: fetchTasks});
            this.toastr.success("Mandate Created!", $localize`:@@companyName:Rondeivu`);
            this.router.navigate(['/' + this.config.selected_business_unit.slug + '/mandates']);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to create mandate!");
          }
        });
      }
    });
  }

}
