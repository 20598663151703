import {Component, OnInit} from '@angular/core';
import {Subject, Subscription} from "rxjs";
import {IEmployeePermissionGroup, IEmployeeRole, IPermissionGroup, IPermissionOption} from "../../../shared/models";
import {
  Config,
  getConfig,
  getLookups,
  getSelectedDealId, getSelectedDealTeamMember,
  initialConfigState,
  initialLookupsState,
  Lookups, toggleLoading
} from "../../../redux";
import {UserCardSize} from "../../../shared/components";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {UtilService} from "../../../shared/services";
import {DealTeamMembersService} from "../../services";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {IDealTeamMemberRoleResponse} from "../../models";

@Component({
  selector: 'app-deal-team-member-permissions',
  templateUrl: './deal-team-member-permissions.component.html',
  styleUrls: ['./deal-team-member-permissions.component.scss']
})
export class DealTeamMemberPermissionsComponent implements OnInit {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedDeal$: Subscription = new Subscription();
  selectedDealId = '';
  selectedDealTeamMember$: Subscription = new Subscription();

  allRole$: Subscription = new Subscription();
  allRolesSubject = new Subject<IEmployeeRole[]>();

  private config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;

  userCardSize = UserCardSize;
  // permissions
  allRoles: IEmployeeRole[] = [];
  selectedRole: IEmployeeRole | undefined = undefined;
  selectedOptions: Map<string, IPermissionOption> = new Map<string, IPermissionOption>();

  selectedDealTeamMember: any = {} as unknown as any;
  memberRole: IDealTeamMemberRoleResponse = {} as unknown as IDealTeamMemberRoleResponse;
  structure: { dealTeamPermissionGroups: any[] } = {} as unknown as { dealTeamPermissionGroups: any[] };

  //reactive form
  userForm = new FormGroup({
    role: new FormControl(),
    canAccessEntireDataroom: new FormControl(false),
    canAccessEntireDocumentRoom: new FormControl(false),
    dataroomRootFolders: new FormControl(),
    documentsRoomRootFolders: new FormControl()
  });
  dynamicControls: FormGroup = new FormGroup({});

  constructor(
    private store: Store,
    private router: Router,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public util: UtilService,
    private dealTeamMembersService: DealTeamMembersService,
    private fb: FormBuilder
  ) {
    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });
    this.lookup$ = this.store.select(getLookups).subscribe(lookups => {
      this.lookups = lookups;
    });

    this.selectedDeal$ = this.store.select(getSelectedDealId).subscribe(id => {
      this.selectedDealId = id;
    });

    // get all available roles when employee is selected
    this.selectedDealTeamMember$ = this.store.select(getSelectedDealTeamMember).subscribe((dtm: any) => {
      this.selectedDealTeamMember = dtm;
      if (!!this.selectedDealId && !!this.selectedDealTeamMember) {
        this.subscribeToDatasource();
        this.getAllRoles();
      }
    });

    // fetch the employee role only after all available roles have been updated
    this.allRole$ = this.allRolesSubject.subscribe((roles: IEmployeeRole[]) => {
      this.getEmployeeRole();
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedDeal$.unsubscribe();
    this.selectedDealTeamMember$.unsubscribe();
    this.allRole$.unsubscribe();
  }

  subscribeToDatasource() {
    this.getStructure();
  }

  /**
   * Get all available roles
   */
  getAllRoles() {
    if (!!this.selectedDealTeamMember) {
      this.dealTeamMembersService.getAllRoles(this.selectedDealTeamMember?.id).subscribe({
        next: (roles: IEmployeeRole[]) => {
          if (!!roles) {
            this.allRoles = roles;
            this.allRolesSubject.next(this.allRoles);
          }
        }, error: (err: any) => {
          this.toastr.error("Unable to get roles!", $localize`:@@companyName:Rondeivu`);
        }
      });
    }
  }

  /**
   * Get the current employee role
   */
  getEmployeeRole() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.dealTeamMembersService.getDealTeamMemberRoles(this.selectedDealTeamMember?.id).subscribe({
      next: (response: IDealTeamMemberRoleResponse) => {
        if (!!response) {
          this.memberRole = response;
          this.userForm.patchValue(response);
          this.selectRoleById(response?.role?.id);
        }
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to get user role!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  private selectRoleById(id: string) {
    for (let i = 0; i <= this.allRoles.length - 1; i++) {
      if (id == this.allRoles[i].id) {
        this.userForm.get('role')?.patchValue(this.allRoles[i]);
        this.updateFormRole(this.allRoles[i]);
      }
    }
  }

  toggleRootFolderActive(event: MatCheckboxChange, rootFolder: any) {
    let folders = this.userForm.get('dataroomRootFolders')?.value;
    folders.forEach((f: any) => {
      if (rootFolder.id == f.id) {
        f.active = event.checked;
      }
    });
    this.userForm.get('dataroomRootFolders')?.patchValue(folders);
  }

  toggleDocumentFolderActive(event: MatCheckboxChange, rootFolder: any) {
    let folders = this.userForm.get('documentsRoomRootFolders')?.value;
    folders.forEach((f: any) => {
      if (rootFolder.id == f.id) {
        f.active = event.checked;
      }
    });
    this.userForm.get('documentsRoomRootFolders')?.patchValue(folders);
  }

  /**
   * Get the form structure to create
   */
  getStructure() {
    if (!!this.selectedDealId) {
      this.dealTeamMembersService.getPageStructure(this.selectedDealTeamMember?.id).subscribe({
        next: (structure: { dealTeamPermissionGroups: any[] }) => {
          this.structure = structure;
          this.dynamicControls = this.fb.group(this.getFormFields());
        }, error: (err: any) => {
          this.toastr.error("Unable to get page options!", $localize`:@@companyName:Rondeivu`);
        }
      });
    }
  }

  private getFormFields() {
    let formGroupFields = {} as any;
    this.structure.dealTeamPermissionGroups.forEach((buPg: IPermissionGroup) => {
      // @ts-ignore
      formGroupFields[`${buPg.id}`] = new FormControl('');
    });
    return formGroupFields;
  }

  /**
   * Navigate back
   */
  back() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.router.navigate(['/' + this.config.selected_business_unit.slug + '/settings/employees/']).then(() => {
      this.store.dispatch(toggleLoading({loading: false}));
    });
  }

  /**
   * Update the generated form with the role options
   * @param event
   */
  updateForm(event: any) {
    //update the form
    const r = event.value as IEmployeeRole;
    this.updateFormRole(r);
  }

  private updateFormRole(role: IEmployeeRole) {
    this.selectedRole = role;
    this.selectedRole.permissionGroups.forEach((pg: IEmployeePermissionGroup) => {
      // this.dynamicControls.get(`${pg.permissionGroupId}`)?.patchValue(pg.permissionGroupOptionId);
      //find the option
      let option = this.getPermissionGroupOption(pg.permissionGroupId, pg.permissionGroupOptionId);
      //update the dynamic form
      this.dynamicControls.get(`${pg.permissionGroupId}`)?.patchValue(option);
      //add the selection to the option map
      if (this.selectedOptions.has(pg.permissionGroupId)) {
        this.selectedOptions.delete(pg.permissionGroupId);
      }
      this.selectedOptions.set(pg.permissionGroupId, option);
    });
  }

  //helper to get the option object by ids
  private getPermissionGroupOption(permissionGroupId: string, permissionGroupOptionId: string): IPermissionOption {
    let rtnOption = {} as unknown as IPermissionOption;
    if (!!this.structure) {
      //bu options
      this.structure.dealTeamPermissionGroups.forEach((pg: IPermissionGroup) => {
        if (permissionGroupId == pg.id) {
          pg.permissionOptions?.forEach((opt: IPermissionOption) => {
            if (opt.id == permissionGroupOptionId) {
              rtnOption = opt;
            }
          });
        }
      });
    }
    // console.log(rtnOption);
    return rtnOption;
  }

  /**
   * Save the employee role
   */
  savePermissions() {
    // this.getPayload();
    const payload = this.userForm.getRawValue();
    console.log(payload);
    this.store.dispatch(toggleLoading({loading: true}));
    this.dealTeamMembersService.saveDealTeamMemberRoles(this.selectedDealTeamMember?.id, payload).subscribe({
      next: (role: IEmployeeRole) => {
        if (!!role && role.isCustomRole) {
          this.setCustomRoleId(role.id);
        }
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Member Permissions Updated", $localize`:@@companyName:Rondeivu`);
      }, error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to get update member permissions!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  private getPayload() {
    console.log(this.userForm.getRawValue());
    console.log(this.dynamicControls.getRawValue());
  }

  /**
   * When an employee permission changes
   * @param permissionGroup
   * @param event
   */
  onChange(permissionGroup: IPermissionGroup, event: any) {
    const option = event.value as unknown as IPermissionOption;
    const permissionGroupId = permissionGroup.id;

    this.uncheckRootFoldersIfNoAccess(permissionGroup, option);

    //add the selection to the option map
    if (this.selectedOptions.has(permissionGroupId)) {
      this.selectedOptions.delete(permissionGroupId);
    }
    this.selectedOptions.set(permissionGroupId, option);

    let customRole = this.getCustomRole();
    // clone the currently selected role
    customRole.permissionGroups = JSON.parse(JSON.stringify(this.selectedRole?.permissionGroups));
    // find the permission group being changed
    // replace the permission group option to the event payload
    customRole.permissionGroups.forEach((pg: { permissionGroupId: string, permissionGroupOptionId: string }) => {
      if (pg.permissionGroupId == permissionGroupId) {
        pg.permissionGroupOptionId = option.id;
      }
    });

    // rename the custom role
    if (!this.selectedRole?.isCustomRole) {
      customRole.name = 'Custom [' + this.selectedDealTeamMember.firstName + ' ' + this.selectedDealTeamMember.lastName + ']';
    }

    // add the custom role
    if (!this.hasCustomRole()) {
      this.allRoles.push(customRole);
    }

    this.selectedRole = customRole;
    this.userForm.get('role')?.patchValue(this.selectedRole);
  }

  uncheckRootFoldersIfNoAccess(permissionGroup: IPermissionGroup, permissionOption: IPermissionOption) {
    if (permissionGroup.name.includes("Deal Dataroom") && permissionOption.name.includes("No Access")) {
      let folders = this.userForm.get('dataroomRootFolders')?.value;
      folders.forEach((f: any) => {
        f.active = false;
      });
      this.userForm.get('dataroomRootFolders')?.patchValue(folders);
      this.userForm.get('canAccessEntireDataroom')?.patchValue(false);
    } else if (permissionGroup.name.includes("Deal Documents") && permissionOption.name.includes("No Access")) {
      let folders = this.userForm.get('documentsRoomRootFolders')?.value;
      folders.forEach((f: any) => {
        f.active = false;
      });
      this.userForm.get('documentsRoomRootFolders')?.patchValue(folders);
      this.userForm.get('canAccessEntireDocumentRoom')?.patchValue(false);
    }
  }

  checkRootFolders(event: MatCheckboxChange) {
    if (event.checked) {
      let folders = this.userForm.get('dataroomRootFolders')?.value;
      folders.forEach((f: any) => {
        f.active = true;
      });
      this.userForm.get('dataroomRootFolders')?.patchValue(folders);
    }
  }

  checkDocumentRootFolders(event: MatCheckboxChange) {
    if (event.checked) {
      let folders = this.userForm.get('documentsRoomRootFolders')?.value;
      folders.forEach((f: any) => {
        f.active = true;
      });
      this.userForm.get('documentsRoomRootFolders')?.patchValue(folders);
    }
  }

  private hasCustomRole(): boolean {
    for (let i = 0; i <= this.allRoles.length - 1; i++) {
      if (this.allRoles[i].isCustomRole) {
        return true;
      }
    }
    return false;
  }

  private getCustomRole(): IEmployeeRole {
    for (let i = 0; i <= this.allRoles.length - 1; i++) {
      if (this.allRoles[i].isCustomRole) {
        return this.allRoles[i];
      }
    }
    return {
      id: '',
      description: 'This is a custom user permission profile',
      isCustomRole: true,
      permissionGroups: []
    } as unknown as IEmployeeRole;
  }

  private setCustomRoleId(id: string) {
    for (let i = 0; i <= this.allRoles.length - 1; i++) {
      if (this.allRoles[i].isCustomRole) {
        this.allRoles[i].id = id;
      }
    }
  }

  //this is a custom comparator for the root folder selection
  rootCompare(r1: { id: string, name: string, active: boolean }, r2: {
    id: string,
    name: string,
    active: boolean
  }): boolean {
    return r1 && r2 ? r1.id === r2.id : r1 === r2;
  }

}


