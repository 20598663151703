import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {UtilService} from "../../../shared/services";
import {AppConfigService} from "../../../../services";
import {BusinessUnitService} from "../../services";
import {
  getEditingBusinessUnit,
  getEditingBusinessUnitEmployee, getSelectedBusinessUnit, updateEditingBusinessUnitEmployee,
} from "../../../redux";
import {Subscription} from "rxjs";
import {IBusinessUnit} from "../../models";

@Component({
  selector: 'app-business-unit-employee-inner-navigation',
  templateUrl: './business-unit-employee-inner-navigation.component.html',
  styleUrls: ['./business-unit-employee-inner-navigation.component.scss']
})
export class BusinessUnitEmployeeInnerNavigationComponent implements OnInit {

  selectedBusinessUnit$: Subscription = new Subscription();
  editingBusinessUnit$: Subscription = new Subscription();
  selectedEmployee$: Subscription = new Subscription();

  selectedEmployee: any = {};
  editingBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;
  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;

  constructor(
    private businessUnitService: BusinessUnitService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public util: UtilService,
    private appConfig: AppConfigService
  ) {
    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((selectedBusinessUnit: IBusinessUnit) => {
      this.selectedBusinessUnit = selectedBusinessUnit;
    });

    this.editingBusinessUnit$ = this.store.select(getEditingBusinessUnit).subscribe((bu: IBusinessUnit) => {
      if (!!bu && !!bu.businessUnitId && this.editingBusinessUnit.businessUnitId != bu.businessUnitId) {
        this.editingBusinessUnit = Object.assign({}, bu);
      }
    });

    this.selectedEmployee$ = this.store.select(getEditingBusinessUnitEmployee).subscribe((employee: any) => {
      this.selectedEmployee = employee;
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.editingBusinessUnit$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedEmployee$.unsubscribe();
  }

  back() {
    this.store.dispatch(updateEditingBusinessUnitEmployee({businessUnitEmployee: {}}));
    this.router.navigate([this.selectedBusinessUnit.slug + '/business-units/' + this.editingBusinessUnit.businessUnitId + '/employees']);
  }

}
