<div class="rm-header">
  <span class="">Add Business Unit</span>
</div>
<div>
  <form id="buForm" #buForm
        [formGroup]="userForm"
        (ngSubmit)="submit()">
    <mat-dialog-content class="mat-typography">
      <!-- Display Name -->
      <div class="row justify-content-center" style="padding-top: 25px;">
        <div class="col-11">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Display Name</mat-label>
            <input matInput id="dname" [required]="true" minlength="4" formControlName="displayName"/>
            <mat-error>Must be at least 4 characters long.</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--  Legal Entity Name -->
      <div class="row justify-content-center">
        <div class="col-11">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Legal Entity Name</mat-label>
            <input matInput id="iname" [required]="true" minlength="4" formControlName="internalName"/>
            <mat-error>Must be at least 4 characters long.</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--  Business Unit Slug -->
      <div class="row justify-content-center">
        <div class="col-11">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Business Unit Slug</mat-label>
            <input matInput id="buslug" [required]="true" pattern="^\S+$" formControlName="slug"/>
            <mat-error>Slug must be non-empty and contain no whitespace.</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--Country of Domicile-->
      <div class="row justify-content-center">
        <div class="col-11">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Business Unit Domicile</mat-label>
            <mat-select id="cod" [required]="true" formControlName="domicile">
              <mat-option *ngFor="let opt of lookups.countries" [value]="opt.key">
                {{ opt.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <!-- Type -->
      <div class="row justify-content-center" style="padding: 20px 0 20px 0;">
        <div class="col-11">
          <mat-label>Type</mat-label>
          <br/>
          <mat-radio-group
            class="radio-group" id="type" [required]="true" formControlName="businessUnitType">
            <mat-radio-button class="radio-button" [value]="'INVESTOR'">INVESTOR</mat-radio-button>&nbsp;
            <mat-radio-button class="radio-button" [value]="'ISSUER'">ISSUER</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!--  Business Sub Type -->
      <!--  Type of investor-->
      <div class="row justify-content-center" *ngIf="userForm.get('businessUnitType')?.value === 'INVESTOR'">
        <div class="col-11">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Subtype (Investor)</mat-label>
            <mat-select id="toi" [required]="true" formControlName="subType">
              <mat-option *ngFor="let i of lookups.investorTypes" [value]="i.value">
                {{ i.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- Type of issuer-->
      <div class="row justify-content-center" *ngIf="userForm.get('businessUnitType')?.value === 'ISSUER'">
        <div class="col-11">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Subtype (Issuer)</mat-label>
            <mat-select id="ti" [required]="true" formControlName="subType">
              <mat-option *ngFor="let i of lookups.issuerTypes" [value]="i.value">
                {{ i.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- Intake Description -->
      <div class="row justify-content-center">
        <div class="col-11">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Intake Description</mat-label>
            <input matInput id="iDesc" formControlName="intakeDescription"/>
          </mat-form-field>
        </div>
      </div>
      <!-- KYC Processe-->
      <!--      <div class="row" style="padding-top: 20px;">-->
      <!--        <div class="col-12">-->
      <!--          <div class="radio-group">-->
      <!--            <mat-label>KYC Process</mat-label><br/>-->
      <!--            <mat-checkbox id="kyc" [(ngModel)]="buRequest.isKYCRequired"></mat-checkbox>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->


    </mat-dialog-content>
    <mat-dialog-actions class="row" style="padding-top: 20px; padding-bottom: 5px;">
      <div class="col-6  text-start">
        <button mat-raised-button type="button" (click)="dialogRef.close()">CANCEL</button>
      </div>
      <div class="col-6 text-end">
        <button mat-raised-button color="primary" type="submit" [disabled]="userForm.invalid">SUBMIT</button>
      </div>
    </mat-dialog-actions>
  </form>
</div>
