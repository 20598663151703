<div class="row justify-content-center" style="padding-bottom: 20px;">
  <div class="col-11">
    <label for="{{'code-0'}}" class="form-label code-label">{{ title }}</label>
  </div>
</div>
<br/>
<div class="row justify-content-center">

  <input *ngFor="let e of counter(codeLen); index as i;"
         type="text"
         id="{{'code-' + i}}"
         name="{{'code-' + i}}"
         class="form-control code-input"
         #codeInput
         [(ngModel)]="code[i]"
         (focus)="codeClick($event)"
         (keydown)="codeDown($event)"
         (keyup)="codeUp($event)"
         size="1"
         min="0"
         max="9"
         maxlength="1"
         pattern="[0-9]+"
         autocomplete="off"/>
</div>
<br/>
<br/>
<div class="row justify-content-center">
  <div class="col-11" style="font-size: small;">
    <ng-content></ng-content>
  </div>
</div>
<br/>
<br/>
<div class="row justify-content-center">
  <div class="col-11" style="display: flex; justify-content: space-between;">
    <button type="button" #resend mat-raised-button (click)="request()" [disabled]="!resendEnabled" class="resend-btn">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
      {{ backTitle }}
    </button>&nbsp;
    <button type="button" #submit mat-raised-button class="submit-btn" (click)="next()"
            [disabled]="loading || !submitEnabled">
      {{ nextTitle }}
    </button>
  </div>
</div>

