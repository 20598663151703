import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from "@ngrx/store";
import {Config, getConfig, initialConfigState} from "../../modules/redux";
import {Subscription} from "rxjs";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  config: Config = initialConfigState;

  constructor(private _router: Router, private store: Store) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    })
  }

  public ngOnInit(): void {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
  }
}
