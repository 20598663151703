export interface IDealCard {
  id: string;
  assetName?: string;
  projectName?: string;
  businessUnit: any;
  dealImageUrl: string;
  dealIsMnpi: boolean;
  dealNdaRequirementType: string;
  dealCloseType: string;
  secDealType: string;
  dealInterestType: string;
  goLiveDate: string;
  closeDate: string;
  syndicationDate: string;
  preNDA: boolean;
  status: string;
  pills: IDealPill[];
  dealTimeline: IDealTimeline;
  city: string;
  countryId: string
  created: string;
  currencyType: string;
  description: string;
  expectedDuration: number;
  firstCloseOccured: boolean;
  fundraiseSize: number;
  isPreNda: boolean;
  dealWorkflow: DealWorkFlow;
  investorDealWorkflow: string;
  maximumDuration: number;
  minimumDuration: number;
  percentFundRaise: number;
  stateorProvinceId: string;
  targetMoicBase: number;
  targetMoicBear: number;
  targetMoicBest: number;
  timeLineTypeId: number;
  title?: string;
  dealAlerts: IDealAlert[];
  dealTypes: string[];
  assetClasses: string[];
}

export enum DealWorkFlow {
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  ONBOARDING = 'ONBOARDING',
  LIVE = 'LIVE',
  CLOSED = 'CLOSED',
  ARCHIVED = 'ARCHIVED',
  NA = 'NA'
}

export interface IDealTimeline {
  current: IDealCardEvent;
  markers: IDealCardEvent[];
}

export interface IDealCardEvent {
  created?: string;
  title?: string;
  titlePos?: any;
  tooltip?: string;
  color?: string;
  xPos?: number;
  status?: 'CURRENT' | 'FUTURE' | 'PAST';
}

export interface IDealPill {
  name: string;
  backgroundColor: string;
  fontColor: string;
  borderColor: string;
  tooltip: string;
  link?: string;
  hover?: string;
}

export interface IDealAlert {
  id: string;
  title: string;
  status: "COMPLETE" | "INFO" | "PENDING" | "TASK";
  message: string; //the display text
  linkText: string; //display text for the link
  businessUnitSlug: string;
  route: string;
  routeId: string;
  fragment: string;
}
