<div class="container-fluid">
  <div class="row justify-content-center" style="padding-top: 150px;">
    <div class="col-11 text-center">
      <span style="font-size: 2rem;"
            i18n="welcome|Dashboard welcome message">Welcome to Rondeivu Admin Dashboard!</span>
      <br/>
      <em style="font-size:1.2rem; color: grey;" i18n="welcome-sub|Dashboard welcome sub message">This is where the
        magic happens!</em>
    </div>
    <div style="text-align: center; margin-top: 50px">
      <img src="../../../../../assets/images/wheel.png">
    </div>
    <!--    <div style="font-size: large; margin-top: 50px; display:flex; justify-content: center;">-->
    <!--      <span style="display: flex; align-items: center;">-->
    <!--        <mat-icon>arrow_back</mat-icon> &nbsp;-->
    <!--        Please select from the left side navigation buttons.-->
    <!--      </span>-->
    <!--    </div>-->
  </div>
</div>
<!--<app-chart></app-chart>-->
