import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-investor-dashboard',
  templateUrl: './investor-dashboard.component.html',
  styleUrls: ['./investor-dashboard.component.scss']
})
export class InvestorDashboardComponent implements OnInit {

  constructor() {

  }

  ngOnInit(): void {

  }
}
