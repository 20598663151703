export interface IDeal {
  id: string;
  name: string;
  desc: string;
  value: number;
  status: IDealStatus;
}

export enum IDealStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  DECLINED = 'DECLINED'
}

export interface IDealInfoRequest {
  id: string;
  projectName: string;
  preNdaDescription: string;
  assetName: string;
  postNdaDescription: string;
  postCloseDescription: string;
  preImageUrl: string;
  postImageUrl: string;
  closedImageUrl: string;
}

export interface IDealSettingsRequest {
  id: string;
  dealNdaRequirementType: string;
  dealCloseType: string;
  secDealType: string;
  dealInterestType: string;
  dealIsMnpi: boolean;
  closeDate: Date | null;
  syndicationDate: Date | null;
}

export interface IDealAdminSettingsRequest {
  id: string;
  dealWorkflow: string;
  issuerNdaStatus: string;
  issuerEngagementAgreementStatus: string;
  issuerCanEditDealDataroom: boolean;
  InvestorCanDownloadDataroom: boolean;
  issuerCanEditDealCardInformation: boolean;
}

export interface IDealStatusRequest {
  id: string;
  dealWorkflow: string;
}

export interface IMediaRoom {
  id: string;
  title: string;
  description: string;
  linkUrl: string;
  linkUrlPreview: string;
  imageUrl: string;
  created: string;
}

export interface ICommunicationRequest {
  callAgenda: string;
  teamEmails: string[];
  suggestedDatesTimes: string;
  callLength: number;
}

export interface IDealHighlightsRequest {
  id: string;
  isIssuerNdaSigned: boolean;
  isDealNdaSigned: boolean;
  investorWorkflowStatus: string;
}

export interface IDealMatchData {
  save: boolean;
  reason: string;
  type: string;
  assetName: string;
  displayName: string;
}

