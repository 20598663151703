import { Injectable } from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MockMandatesService {
  env = environment;
  fragment = '/mandates';

  constructor(private http: HttpClient) {}

  getMandates():Observable<any> {
    return this.http.get(this.env.rondeivu_api_mock + this.fragment);
  }

  getMandate(id:string):Observable<any> {
    return this.http.get(this.env.rondeivu_api_mock + this.fragment + '/' + id);
  }
}
