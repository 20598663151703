import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {
  Config,
  getConfig,
  getSelectedBusinessUnit,
  getSelectedDeal,
  initialConfigState
} from "../../../redux";
import {IBusinessUnit} from "../../../business-units/models";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {DealMatchingService} from "../../services";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {HttpErrorResponse} from "@angular/common/http";
import {LegendPosition} from "@swimlane/ngx-charts";
import {IDealCard} from "../../../shared/models";


@Component({
  selector: 'app-investor-test',
  templateUrl: './investor-test.component.html',
  styleUrls: ['./investor-test.component.scss']
})
export class InvestorTestComponent implements OnInit, OnDestroy {

  protected readonly Math = Math;
  config$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  selectedDeal$: Subscription = new Subscription();
  selectedDeal: IDealCard = {} as unknown as IDealCard;

  private config: Config = initialConfigState;
  bu: IBusinessUnit = {} as unknown as IBusinessUnit;
  investors: any;
  view: any = [700, 400];

  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  animation: boolean = false;
  showTooltip: boolean = true;
  explode: boolean = false;
  showLabels: boolean = false;

  selectedColor = 'vivid';
  colorSchemes = ['neons', 'vivid', 'solar', 'natural', 'cool', 'fire', 'air', 'aqua', 'flame', 'night'];
  tableData: any[] = [];


  LegendPosition = LegendPosition;


  constructor(private store: Store,
              private router: Router,
              private dealMatchingService: DealMatchingService,
              private toastr: ToastrService,
              public dialog: MatDialog) {

    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.bu = Object.assign({}, bu);
    });

    this.selectedDeal$ = this.store.select(getSelectedDeal).subscribe((deal: IDealCard) => {
      if (this.selectedDeal.id != deal.id) {
        this.selectedDeal = deal;
        this.subscribeToDatasource();
      } else {
        this.selectedDeal = deal;
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedDeal$.unsubscribe();
  }

  subscribeToDatasource() {
    console.log("Fetching Deal Investor Metrics...");
    this.dealMatchingService.getPotentialInvestors().subscribe({
      next: (investors: any) => {
        this.investors = Object.assign({}, investors);
        this.tableData = investors.tableData;
      }, error: (error: HttpErrorResponse) => {
        this.toastr.error("Unable to get potential investors!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  onSelect(data: any): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data: any): void {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data: any): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

}
