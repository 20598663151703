<div class="rm-header">
  <span>Lock Syndication Pool</span>
</div>
<div class="row justify-content-center" style="padding-bottom: 20px; padding-top: 25px;">
  <div class="col-11 text-center">
    <mat-icon style="color: orange; font-size: x-large; padding-top: 5px;">warning</mat-icon>&nbsp;<span style="font-size: x-large">Do you wish to proceed?</span>
  </div>
</div>
<div class="row justify-content-center" style="padding-bottom: 20px;">
  <div class="col-11 text-center">
    <span style="font-weight: lighter; font-size: medium;">You are about to lock this deals funding pool:</span>
  </div>
</div>
<div class="row justify-content-center" style="padding-bottom: 50px;">
  <div class="col-11 text-center">
    <mat-checkbox color="primary" [(ngModel)]="data.notify">Notify all investors via email (even if their allocation amount is zero).</mat-checkbox>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-6">
    <button mat-raised-button (click)="dialogRef.close()">CANCEL</button>
  </div>
  <div class="col-6 text-end">
    <button mat-raised-button color="primary" (click)="dialogRef.close(data)">SUBMIT</button>
  </div>
</div>
