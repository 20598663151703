import {Injectable} from '@angular/core';
import {HttpHeaders} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {RondeivuHttpClient} from "../../../../handlers";
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {Config, getConfig, initialConfigState} from "../../../redux";
import {UuidService} from "../../../../services";

@Injectable({
  providedIn: 'root'
})
export class DealMatchingService {

  private config: Config = initialConfigState;
  private readonly BASE_URL = environment.rondeivu_api;
  private readonly MANDATES_FRAGMENT = '/mandates';
  private readonly DEAL_MATCH_FRAGMENT = '/investordealmatch';
  private readonly POTENTIAL_INVESTORS = '/potential-investors';
  private readonly EXCLUSIONS_FRAGMENT = '/exclusion';
  private readonly SCORE_FRAGMENT = '/internal-score';

  constructor(private store: Store, private http: RondeivuHttpClient, private uuid: UuidService) {
    this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
  }

  /**
   * Get deal exclusions
   */
  getExclusions(): Observable<any> {
    return this.http.get(this.BASE_URL + this.DEAL_MATCH_FRAGMENT + this.EXCLUSIONS_FRAGMENT + '/' + this.config.selected_deal_id);
  }

  /**
   * Update deal exclusions
   * @param ex
   */
  updateExclusions(ex: string): Observable<any> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'text/plain');
    let payload = {
      reason: ex
    }
    return this.http.put(this.BASE_URL + this.DEAL_MATCH_FRAGMENT + this.EXCLUSIONS_FRAGMENT + '/' + this.config.selected_deal_id, payload);
  }

  /**
   * Get all investor deal matches
   */
  getDealMatches(): Observable<any> {
    return this.http.get(this.BASE_URL + this.DEAL_MATCH_FRAGMENT + '/' + this.config.selected_deal_id);
  }

  /**
   * Get all potential investor matches
   */
  getPotentialInvestors(): Observable<any> {
    return this.http.get(this.BASE_URL + this.DEAL_MATCH_FRAGMENT + this.POTENTIAL_INVESTORS + '/' + this.config.selected_deal_id);
  }

  /**
   * Match an investor deal
   * @param matchId match id to pair
   * @param mandateId mandate id to match to selected deal
   * @param reason reason for the match
   */
  addDealMatch(matchId: string, mandateId: string, reason: string) {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    let payload = {
      id: matchId,
      mandateId: mandateId,
      dealId: this.config.selected_deal_id,
      reason: reason
    }
    return this.http.post(this.BASE_URL + this.DEAL_MATCH_FRAGMENT + '/' + this.config.selected_deal_id, payload, {headers: httpHeaders});
  }

  /**
   * Unmatch an investor deal
   * @param id the of the deal match
   * @param reason reason for unmatching
   */
  deleteMatch(id: string, reason: string) {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      body: {
        reason: reason
      }
    };
    return this.http.delete(this.BASE_URL + this.DEAL_MATCH_FRAGMENT + '/' + id, options);
  }

  /**
   * Update internal score
   * @param mandateId mandate to score
   * @param score the internal score
   */
  updateInternalScore(mandateId: string, score: number): Observable<any> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'text/plain');
    let payload = {
      mandateId: mandateId,
      dealId: this.config.selected_deal_id,
      score: score
    }
    return this.http.put(this.BASE_URL + this.DEAL_MATCH_FRAGMENT + this.SCORE_FRAGMENT + '/' + this.config.selected_deal_id, payload);
  }

}
