import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IDealTeam, IDealTeamMemberRequest} from "../../models";
import {Store} from "@ngrx/store";
import {
  Config,
  getConfig,
  getLookups,
  getSelectedBusinessUnit, getSelectedDeal,
  getSelectedDealId,
  initialConfigState,
  initialLookupsState,
  initialThemeState,
  Lookups,
  Theme,
  toggleLoading,
  updateDealDocumentRootFolders, updateDealTeamMember,
  updateSelectedDealTeamId
} from "../../../redux";
import {DealTeamMembersService} from "../../services";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {IBusinessUnit} from "../../../business-units/models";
import {ToastrService} from "ngx-toastr";
import {Subscription} from "rxjs";
import {DealTeamMemberModalComponent} from "../deal-team-member-modal/deal-team-member-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {
  DeleteConfirmationModalComponent, UserCardSize
} from "../../../shared/components";
import {ModalSize, UtilService} from "../../../shared/services/util/util.service";
import {Router} from "@angular/router";
import {DealTeamModalComponent} from "./deal-team-modal/deal-team-modal.component";
import {IMiniAppUser} from "../../../../models";
import {IDealCard} from "../../../shared/models";


export enum DealTeamAccess {
  FULL = 0,
  READ_ONLY = 1,
  NONE = 2
}

@Component({
  selector: 'app-deal-team',
  templateUrl: './deal-team.component.html',
  styleUrls: ['./deal-team.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DealTeamComponent implements OnInit, OnDestroy, AfterViewInit {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();

  //form access
  dtConfig: DealTeamAccess = DealTeamAccess.NONE;
  dealTeams: IDealTeam[] = [];
  selectedDealTeam: IDealTeam = {} as unknown as IDealTeam;
  selectedDealTeamConfig: any = {};

  members: IDealTeamMemberRequest[] = [];
  sortedMembers: IDealTeamMemberRequest[] = [];
  dataSource: MatTableDataSource<IDealTeamMemberRequest> = new MatTableDataSource();
  config: Config = initialConfigState;
  theme: Theme = initialThemeState;
  lookups: Lookups = initialLookupsState;
  bu: IBusinessUnit = {} as unknown as IBusinessUnit;
  selectedDeal$: Subscription = new Subscription();
  selectedDeal: IDealCard = {} as unknown as IDealCard;

  columnsToDisplayInvestor = ['firstName', 'role', 'invite', 'created', 'actions'];
  columnsToDisplayIssuer = ['firstName', 'role', 'invite', 'created', 'actions'];

  columnsToDisplay = ['firstName', 'role', 'invite', 'created', 'actions'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: IDealTeamMemberRequest | null = {} as IDealTeamMemberRequest;
  resetElement: IDealTeamMemberRequest = {} as IDealTeamMemberRequest;
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  userCardSize = UserCardSize;

  constructor(
    private router: Router,
    private store: Store,
    private toastr: ToastrService,
    private dealTeamMembersService: DealTeamMembersService,
    public dialog: MatDialog,
    public util: UtilService
  ) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.bu = Object.assign({}, bu);
    });

    this.selectedDeal$ = this.store.select(getSelectedDeal).subscribe((deal: IDealCard) => {
      if (this.selectedDeal.id != deal.id) {
        this.selectedDeal = deal;
        this.subscribeToDatasource();
      } else {
        this.selectedDeal = deal;
      }
    });

  }

  ngAfterViewInit() {

  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedDeal$.unsubscribe();
  }

  subscribeToDatasource(): void {
    this.getDealTeams();
  }

  addMember() {
    // this.store.dispatch(showDealTeamMemberModal({show: true}));
    const matchRef = this.dialog.open(DealTeamMemberModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.MEDIUM),
      disableClose: true,
      panelClass: 'rondeivu-modal'
    });

    matchRef.afterClosed().subscribe((result: IMiniAppUser[]) => {
      // console.log("Dialog output:", result);
      if (!!result) {
        this.getDealTeamMembers();
      }
    });
  }

  viewHistory() {
    this.store.dispatch(toggleLoading({loading: true}));
    setTimeout(() => {
      this.store.dispatch(toggleLoading({loading: false}));
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/history/dealteams'], {queryParams: {id: this.selectedDealTeam.id}});
    }, 1000);
  }

  /**
   * Gets the available deal teams for the user and selects the first one
   */
  getDealTeams() {
    //fetch the deal teams
    console.log("Fetching Deal Teams...");
    this.dealTeamMembersService.getDealTeams().subscribe({
      next: (dt: IDealTeam[]) => {
        // console.log(JSON.stringify(dt));
        this.dealTeams = dt;
        if (!!this.dealTeams && this.dealTeams.length > 0) {
          this.selectedDealTeam = this.dealTeams[0];
          this.store.dispatch(updateSelectedDealTeamId({selectedDealTeamId: this.selectedDealTeam.id}));
          this.getDealTeamMembers();
          //set the table display columns
          this.columnsToDisplay = this.isSelectedDealTeamType('investor') ? this.columnsToDisplayInvestor : this.columnsToDisplayIssuer;
        }
      }, error: (err: any) => {
        this.toastr.error("Unable to get deal teams!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  onChangeDealTeam(event: any) {
    // console.log(event);
    this.resetElement = Object.assign({});
    this.selectedDealTeam = event.value;
    this.store.dispatch(updateSelectedDealTeamId({selectedDealTeamId: this.selectedDealTeam.id}));
    this.getDealTeamMembers();
    //set the table display columns
    this.columnsToDisplay = this.isSelectedDealTeamType('investor') ? this.columnsToDisplayInvestor : this.columnsToDisplayIssuer;
  }

  /**
   * Gets the deal team config for the selected deal team
   */
  getDealTeamConfig() {
    this.dealTeamMembersService.getDealTeamConfig(this.selectedDealTeam.id).subscribe((config: any) => {
      // console.log(JSON.stringify(config));
      this.dtConfig = config as DealTeamAccess;
    });
  }

  /**
   * Gets the deal team root folders for the selected deal team
   */
  getRootFolders() {
    this.dealTeamMembersService.getRootFolders(this.config.selected_deal_id).subscribe((rf: any[]) => {
      // console.log(JSON.stringify(rf));
      this.store.dispatch(updateDealDocumentRootFolders({rootFolders: rf}));
    });
  }

  /**
   * Gets the deal team members for the selected deal team
   */
  getDealTeamMembers() {
    this.dealTeamMembersService.getDealTeamMembers(this.selectedDealTeam.id).subscribe((dtm: IDealTeamMemberRequest[]) => {
      this.members = dtm;
      this.dataSource = new MatTableDataSource(this.members);
      this.initDataSource();
    });
  }

  initDataSource() {
    this.dataSource.filterPredicate = (data: IDealTeamMemberRequest, filter: string) => {
      let lcName = data.firstName?.toLowerCase() + ' ' + data.lastName.toLowerCase();
      const name = lcName.includes(filter.toLowerCase());
      const email = data.email?.toLowerCase().includes(filter.toLowerCase());
      const invite = data.inviteStatus?.toLowerCase().includes(filter.toLowerCase());
      return name || email || invite;
    };
    if (!!this.paginator) {
      this.paginator.pageSize = 10;
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * The edit cancel button action
   * @param element
   */
  cancel(element: IDealTeamMemberRequest) {
    if (!!this.members) {
      for (let i = 0; i <= this.members.length - 1; i++) {
        if (this.members[i].id === this.resetElement?.id) {
          this.members[i] = Object.assign({}, this.resetElement);
          this.members[i].permissions = Object.assign({}, this.resetElement.permissions);
          this.resetElement = {} as IDealTeamMemberRequest;
          this.dataSource = new MatTableDataSource(this.members);
          this.initDataSource();
          break;
        }
      }
    }
  }

  /**
   * Save a deal team member
   * @param element
   */
  save(element: IDealTeamMemberRequest) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.dealTeamMembersService.updateDealTeamMember(this.selectedDealTeam.id, element).subscribe({
      next: (res: any) => {
        this.resetElement = {} as IDealTeamMemberRequest;
        this.getDealTeamMembers();
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Member Updated!", $localize`:@@companyName:Rondeivu`);
      }, error: error => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to update member!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  /**
   * The row click action
   * @param element
   */
  rowClickAction(element: any) {
    if (!!this.resetElement?.id) {
      // this.cancel(this.expandedElement);
      this.toastr.warning("Please cancel or save changes first", $localize`:@@companyName:Rondeivu`);
    } else {
      this.expandedElement = this.expandedElement === element ? null : element;
      this.resetElement = Object.assign({}, element);
      this.resetElement.permissions = !!element.permissions ? Object.assign({}, element.permissions) : {};
    }
  }

  isDirty(element: IDealTeamMemberRequest) {
    return this.resetElement?.role != element.role ||
      this.resetElement?.permissions.dealNotePermission != element.permissions.dealNotePermission ||
      this.resetElement?.permissions.canUploadDataroom != element.permissions.canUploadDataroom ||
      this.resetElement?.permissions.rootFolders != element.permissions.rootFolders;
  }

  /**
   * Action for editing a deal team member
   * @param element
   */
  edit(element: IDealTeamMemberRequest) {
    const editRef = this.dialog.open(DealTeamModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      disableClose: true,
      panelClass: 'rondeivu-modal',
      data: {
        selectedDealTeam: this.selectedDealTeam,
        member: element
      }

    });

    editRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.save(result);
      }
    });
  }

  /**
   * Delete action
   * @param element
   */
  delete(element: IDealTeamMemberRequest) {
    const delRef = this.dialog.open(DeleteConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      data: element
    });

    delRef.afterClosed().subscribe(result => {
      // console.log("Dialog output:", result);
      if (!!result) {
        this.store.dispatch(toggleLoading({loading: true}));
        if (!!result.id) {
          this.dealTeamMembersService.deleteDealTeamMember(result).subscribe((res: any) => {
            //remove from list
            let idx = -1;
            for (let i = 0; i <= this.members.length - 1; i++) {
              if (this.members[i].id == result.id) {
                idx = i;
                break;
              }
            }
            if (idx >= 0) {
              this.members.splice(idx, 1);
            }

            this.resetElement = Object.assign({});
            this.dataSource = new MatTableDataSource(this.members);
            this.initDataSource();

            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("Member Removed!", $localize`:@@companyName:Rondeivu`);
          }, error => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to remove member!", $localize`:@@companyName:Rondeivu`);
          });
        } else {
          this.toastr.error("Unable to get member id!", $localize`:@@companyName:Rondeivu`);
        }
      }
    });
  }

  viewInfo(dealTeamMember: any) {
    this.store.dispatch(updateDealTeamMember({dealTeamMember: dealTeamMember}))
    if (!!dealTeamMember.id) {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/deals/' + this.config.selected_deal_id + '/deal-team/' + dealTeamMember.id + '/account']);
    }
  }

  viewPermissions(dealTeamMember: any) {
    this.store.dispatch(updateDealTeamMember({dealTeamMember: dealTeamMember}))
    console.log('/' + this.config.selected_business_unit.slug + '/deals/' + this.config.selected_deal_id + '/deal-team/' + dealTeamMember.id + '/permissions');
    if (!!dealTeamMember.id) {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/deals/' + this.config.selected_deal_id + '/deal-team/' + dealTeamMember.id + '/permissions']);
    }
  }

  isSelectedDealTeamType(type: string) {
    if (!!this.selectedDealTeam && !!this.selectedDealTeam.businessUnitProfile) {
      return this.selectedDealTeam.businessUnitProfile.toLowerCase() === type;
    }
    return false;
  }

  getSorted(rf: { id: string, name: string, active: boolean }[]) {
    return rf.sort((a: any, b: any) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
  }

  //sort
  sortData(sort: Sort) {
    const data = this.members.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedMembers = data;
      return;
    }

    this.sortedMembers = data.sort((a: IDealTeamMemberRequest, b: IDealTeamMemberRequest) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'user':
          const n1 = a.firstName + ' ' + a.lastName;
          const n2 = b.firstName + ' ' + b.lastName;
          return compare(n1, n2, isAsc);
        case 'role':
          return compare(a.role, b.role, isAsc);
        case 'status':
          return compare(a.inviteStatus, b.inviteStatus, isAsc);
        case 'created':
          return compare(a.created, b.created, isAsc);
        default:
          return 0;
      }
    });

    this.dataSource = new MatTableDataSource(this.sortedMembers);
    this.initDataSource();
  }

  //this is a custom comparator for the root folder selection
  rootCompare(r1: { id: string, name: string, active: boolean }, r2: {
    id: string,
    name: string,
    active: boolean
  }): boolean {
    return r1 && r2 ? r1.id === r2.id : r1 === r2;
  }
}

//this is the table sort comparator
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
