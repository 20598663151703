import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  Config,
  getConfig,
  getEditingBusinessUnit,
  getSelectedBusinessUnit,
  initialConfigState,
  initialThemeState,
  Theme, toggleLoading, updateDealId
} from "../../../redux";
import {ToastrService} from "ngx-toastr";
import {Store} from "@ngrx/store";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {BusinessUnitService} from "../../services";
import {IBusinessUnit, IBusinessUnitEmployee} from "../../models";
import {IDealCard} from "../../../shared/models";
import {BusinessUnitDealsService} from "../../services";
import {Router} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";
import {Subscription} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {DealModalComponent} from "../../../deals/components";
import {ModalSize, UtilService} from "../../../shared/services/util/util.service";
import {toggleNavigating} from "../../../redux/stores/config/config.actions";


@Component({
  selector: 'app-business-unit-deals',
  templateUrl: './business-unit-deals.component.html',
  styleUrls: ['./business-unit-deals.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BusinessUnitDealsComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  editingBusinessUnit$: Subscription = new Subscription();

  dealsList: IDealCard[] = [];
  sortedDeals: IDealCard[] = [];
  dataSource: MatTableDataSource<IDealCard> = new MatTableDataSource();

  config: Config = initialConfigState;
  theme: Theme = initialThemeState;
  columnsToDisplay = ['businessUnit', 'title', 'dealSize', 'secType', 'status', 'closeType', 'closeDate', 'created', 'edit'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: IDealCard | null = {} as IDealCard;
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  adminBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;
  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;

  constructor(private businessUnitService: BusinessUnitService,
              private businessUnitDealsService: BusinessUnitDealsService,
              private toastr: ToastrService,
              private store: Store,
              private router: Router,
              private dialog: MatDialog,
              public util: UtilService
  ) {
    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.adminBusinessUnit = Object.assign({}, bu);
    });

    this.editingBusinessUnit$ = this.store.select(getEditingBusinessUnit).subscribe((sbu: IBusinessUnit) => {
      if (!!sbu && this.selectedBusinessUnit.businessUnitId != sbu.businessUnitId) {
        this.selectedBusinessUnit = Object.assign({}, sbu);
        if (!!this.adminBusinessUnit && !!this.adminBusinessUnit.businessUnitId &&
          !!this.selectedBusinessUnit && !!this.selectedBusinessUnit.businessUnitId &&
          this.adminBusinessUnit.businessUnitType == 'ADMIN') {
          this.subscribeToDatasource();
        }
      }
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.editingBusinessUnit$.unsubscribe();
  }

  subscribeToDatasource() {
    console.log("Fetching Business Unit Deals...");
    this.store.dispatch(toggleLoading({loading: true}));
    this.businessUnitDealsService.getDeals().subscribe({
      next: (deals: IDealCard[]) => {
        this.dealsList = deals;
        this.dataSource = new MatTableDataSource(this.dealsList);
        this.initDataSource();
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: (error: HttpErrorResponse) => {
        this.toastr.error("Unable to get business unit deals!", $localize`:@@companyName:Rondeivu`);
        this.store.dispatch(toggleLoading({loading: false}));
      }
    });
  }

  initDataSource() {
    this.dataSource.filterPredicate = (data: IDealCard, filter: string) => {
      const PN = data.projectName?.includes(filter) || false;
      const AN = data.assetName?.includes(filter) || false;
      return PN || AN;
    };
    if (!!this.paginator) {
      this.paginator.pageSize = 10;
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  delete(employee: IBusinessUnitEmployee) {

  }

  edit(employee: IBusinessUnitEmployee) {

  }

  viewDeal(deal: any) {
    this.store.dispatch(updateDealId({dealId: deal.id}));
    this.store.dispatch(toggleNavigating({navigating: true}));
    setTimeout(() => {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/deals/' + deal.id]).then(() => {
        this.store.dispatch(toggleNavigating({navigating: false}));
      });
    }, 1000);
  }

  /**
   * Opens the deal modal for this page. Note, this dialog is required so that the user is not redirected when
   * the post is returned successfully, and the business unit deals are reloaded.
   */
  openDealModal() {
    const dealModalRef = this.dialog.open(DealModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.MEDIUM),
      disableClose: true
    });

    dealModalRef.afterClosed().subscribe((result: any) => {
      // console.log(`Dialog result: ${result}`);
      if (!!result) {
        console.log("Creating deal...");
        this.store.dispatch(toggleLoading({loading: true}));
        this.businessUnitDealsService.addDeal(result).subscribe({
          next: (res: IDealCard) => {
            this.subscribeToDatasource();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("Deal Created!", $localize`:@@companyName:Rondeivu`);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to create deal!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  //sort
  sortData(sort: Sort) {
    const data = this.dealsList.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedDeals = data;
      return;
    }

    this.sortedDeals = data.sort((a: IDealCard, b: IDealCard) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'projectName':
          return compare(a.projectName || '', b.projectName || '', isAsc);
        case 'displayName':
          return compare(a.businessUnit?.displayName || '', b.businessUnit?.displayName || '', isAsc);
        case 'closeDate':
          return compare(a.closeDate, b.closeDate, isAsc);
        case 'created':
          return compare(a.created, b.created, isAsc);
        case 'secDealType':
          return compare(a.secDealType, b.secDealType, isAsc);
        case 'dealCloseType':
          return compare(a.dealCloseType, b.dealCloseType, isAsc);
        case 'dealWorkflow':
          return compare(a.dealWorkflow, b.dealWorkflow, isAsc);
        case 'fundraiseSize':
          return compare(a.fundraiseSize, b.fundraiseSize, isAsc);
        default:
          return 0;
      }
    });

    this.dataSource = new MatTableDataSource(this.sortedDeals);
    this.initDataSource();
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
