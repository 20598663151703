import {Component, OnInit, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {
  Config,
  getConfig,
  getLookups,
  getSelectedBusinessUnit,
  initialConfigState,
  initialLookupsState,
  initialThemeState,
  Lookups,
  Theme, toggleLoading,
} from "../../../redux";
import {IBusinessUnit} from "../../../business-units/models";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {AdminService} from "../../services";
import {IRondeivuEmployee} from "../../models";
import {RondeivuEmployeeModalComponent} from "./rondeivu-employee-modal/rondeivu-employee-modal.component";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {
  DeleteConfirmationModalComponent
} from "../../../shared/components";
import {ModalSize, UtilService} from "../../../shared/services/util/util.service";

@Component({
  selector: 'app-rondeivu-employees',
  templateUrl: './rondeivu-employees.component.html',
  styleUrls: ['./rondeivu-employees.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class RondeivuEmployeesComponent implements OnInit {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();

  members: IRondeivuEmployee[] = [] as IRondeivuEmployee[];
  panelOpenState = true;

  dataSource: MatTableDataSource<IRondeivuEmployee> = new MatTableDataSource();
  config: Config = initialConfigState;
  theme: Theme = initialThemeState;
  lookups: Lookups = initialLookupsState;
  bu: IBusinessUnit = {} as unknown as IBusinessUnit;

  columnsToDisplay = ['name', 'created', 'actions'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay];
  expandedElement: any | null = {} as any;
  resetElement: any = {} as any;
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  constructor(
    private store: Store,
    private toastr: ToastrService,
    private adminService: AdminService,
    public dialog: MatDialog,
    private util: UtilService
  ) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.bu = Object.assign({}, bu);
      if (!!this.bu && !!this.bu.businessUnitId) {
        this.subscribeToDatasource();
      }
    });
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
  }

  subscribeToDatasource(): void {
    this.getRondeivuEmployees();
  }

  /**
   * Gets the available deal teams for the user and selects the first one
   */
  getRondeivuEmployees() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.adminService.getRondeivuEmployees().subscribe({
      next: (re: any[]) => {
        // console.log(JSON.stringify(re));
        this.members = Object.assign([], re);
        this.dataSource = new MatTableDataSource(this.members);
        this.initDataSource();
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to get employees!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  initDataSource() {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const concat = data.user?.firstName + ' ' + data.user?.lastName;
      return concat.includes(filter) || data.user?.email.includes(filter);
    };
    if (!!this.paginator) {
      this.paginator.pageSize = 10;
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * The add action
   */
  addMember() {
    const addRef = this.dialog.open(RondeivuEmployeeModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      disableClose: true
    });
    addRef.afterClosed().subscribe(result => {
      // user is already added via modal so just refresh
      if (!!result) {
        this.subscribeToDatasource();
      }
    });
  }

  /**
   * Action for editing an employee
   * @param element
   */
  edit(element: IRondeivuEmployee) {
    const editRef = this.dialog.open(RondeivuEmployeeModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      disableClose: true,
      panelClass: 'rondeivu-modal',
      data: element
    });
    editRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.store.dispatch(toggleLoading({loading: true}));
        this.adminService.updateRondeivuEmployees(result).subscribe({
          next: (res: any) => {
            this.subscribeToDatasource();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("Employee Updated!", $localize`:@@companyName:Rondeivu`);
          }, error: error => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Something went wrong!");
          }
        });
      }
    });
  }

  /**
   * Action for deleting an employee
   * @param element
   */
  delete(element: IRondeivuEmployee) {
    const delRef = this.dialog.open(DeleteConfirmationModalComponent, {
      height: 'auto',
      width: 'auto',
      data: element
    });
    delRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.store.dispatch(toggleLoading({loading: true}));
        this.adminService.deleteRondeivuEmployees(element).subscribe({
          next: (res: any) => {
            this.subscribeToDatasource();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("Employee Removed!", $localize`:@@companyName:Rondeivu`);
          }, error: error => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Something went wrong!");
          }
        });
      }
    });
  }

}
