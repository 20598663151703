import {Component, OnInit} from '@angular/core';
import {
  Config, fetchTasks,
  getConfig,
  getLookups,
  getSelectedBusinessUnit, getUserSettings,
  initialConfigState, initialLookupsState, initialUserState, Lookups,
  toggleLoading, UserSettings
} from "../../../redux";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {UtilService} from "../../../shared/services";
import {Subject, Subscription} from "rxjs";
import {UserCardSize} from "../../../shared/components";
import {IEmployeePermissionGroup, IEmployeeRole, IPermissionGroup, IPermissionOption} from "../../../shared/models";
import {CompanyService} from "../../services/company.service";
import {getSelectedEmployee} from "../../../redux/stores/config/config.selectors";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {IStructureResponse} from "../../models";


@Component({
  selector: 'app-company-employee-permissions',
  templateUrl: './company-employee-permissions.component.html',
  styleUrls: ['./company-employee-permissions.component.scss']
})
export class CompanyEmployeePermissionsComponent implements OnInit {

  config$: Subscription = new Subscription();
  user$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  selectedEmployee$: Subscription = new Subscription();

  allRole$: Subscription = new Subscription();
  allRolesSubject = new Subject<IEmployeeRole[]>();

  private config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;
  currentUser: UserSettings = initialUserState;

  userCardSize = UserCardSize;
  // permissions
  allRoles: IEmployeeRole[] = [];
  selectedRole: IEmployeeRole | undefined = undefined;
  selectedOptions: Map<string, IPermissionOption> = new Map<string, IPermissionOption>();

  selectedEmployee: any = {} as unknown as any;
  employeeRole: any = {} as unknown as IEmployeeRole;
  structure: IStructureResponse = {} as unknown as IStructureResponse;

  //reactive form
  userForm = new FormGroup({
    role: new FormControl(),
    canAccessEntireDataroom: new FormControl(false),
    canAccessEntireDocumentRoom: new FormControl(false),
    dataroomRootFolders: new FormControl(),
    documentsRoomRootFolders: new FormControl()
  });
  form: FormGroup = new FormGroup({});

  memberRole: any = {} as unknown as any;

  constructor(
    private store: Store,
    private router: Router,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public util: UtilService,
    private companyService: CompanyService,
    private fb: FormBuilder
  ) {
    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });
    this.lookup$ = this.store.select(getLookups).subscribe(lookups => {
      this.lookups = lookups;
    });
    this.user$ = this.store.select(getUserSettings).subscribe(user => {
      this.currentUser = user;
    });
    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe(selectedBusinessUnit => {
      if (!!selectedBusinessUnit && !!selectedBusinessUnit.businessUnitId) {
        this.subscribeToDatasource();
      }
    });

    // get all available roles when employee is selected
    this.selectedEmployee$ = this.store.select(getSelectedEmployee).subscribe((employee: any) => {
      this.selectedEmployee = employee;
      this.getAllRoles();
    });

    // fetch the employee role only after all available roles have been updated
    this.allRole$ = this.allRolesSubject.subscribe((roles: IEmployeeRole[]) => {
      this.getEmployeeRole();
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.user$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedEmployee$.unsubscribe();
    this.allRole$.unsubscribe();
  }

  subscribeToDatasource() {
    this.getStructure();
  }

  /**
   * Get all available roles
   */
  getAllRoles() {
    if (!!this.selectedEmployee?.user) {
      this.companyService.getAllRoles(this.selectedEmployee?.id).subscribe({
        next: (roles: IEmployeeRole[]) => {
          if (!!roles) {
            this.allRoles = roles;
            this.allRolesSubject.next(this.allRoles);
          }
        }, error: (err: any) => {
          this.toastr.error("Unable to get roles!", $localize`:@@companyName:Rondeivu`);
        }
      });
    }
  }

  /**
   * Get the current employee role
   */
  getEmployeeRole() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.companyService.getEmployeeRoles(this.selectedEmployee?.id).subscribe({
      next: (employeeRole: IEmployeeRole) => {
        if (!!employeeRole?.id) {
          this.selectRoleById(employeeRole.id);
        }
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to get user role!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  private selectRoleById(id: string) {
    for (let i = 0; i <= this.allRoles.length - 1; i++) {
      if (id == this.allRoles[i].id) {
        this.userForm.get('role')?.patchValue(this.allRoles[i]);
        this.updateFormRole(this.allRoles[i]);
      }
    }
  }

  /**
   * Get the form structure to create
   */
  getStructure() {
    this.companyService.getPageStructure().subscribe({
      next: (structure: IStructureResponse) => {
        this.structure = structure;
        this.form = this.fb.group(this.getFormFields());
      }, error: (err: any) => {
        this.toastr.error("Unable to get page options!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  private getFormFields() {
    let formGroupFields = {};
    this.structure.businessUnitPermissionGroups.forEach((buPg: IPermissionGroup) => {
      // @ts-ignore
      formGroupFields[`${buPg.id}`] = new FormControl('');
    });
    this.structure.globalDealTeamPermissionGroups.forEach((gdtPg: IPermissionGroup) => {
      // @ts-ignore
      formGroupFields[`${gdtPg.id}`] = new FormControl('');
    });
    return formGroupFields;
  }

  /**
   * Navigate back
   */
  back() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.router.navigate(['/' + this.config.selected_business_unit.slug + '/settings/employees/']).then(() => {
      this.store.dispatch(toggleLoading({loading: false}));
    });
  }

  /**
   * Update the generated form with the role options
   * @param event
   */
  updateForm(event: any) {
    //update the form
    const r = event.value as IEmployeeRole;
    this.updateFormRole(r);
  }

  private updateFormRole(role: IEmployeeRole) {
    this.selectedRole = role;
    this.selectedRole.permissionGroups.forEach((pg: IEmployeePermissionGroup) => {
      //find the option
      let option = this.getPermissionGroupOption(pg.permissionGroupId, pg.permissionGroupOptionId);
      //update the dynamic form
      this.form.get(`${pg.permissionGroupId}`)?.patchValue(option);
      //add the selection to the option map
      if (this.selectedOptions.has(pg.permissionGroupId)) {
        this.selectedOptions.delete(pg.permissionGroupId);
      }
      this.selectedOptions.set(pg.permissionGroupId, option);
    });
    // console.log(this.form.getRawValue());
  }

  //helper to get the option object by ids
  private getPermissionGroupOption(permissionGroupId: string, permissionGroupOptionId: string): IPermissionOption {
    let rtnOption = {} as unknown as IPermissionOption;
    if (!!this.structure) {
      //bu options
      this.structure.businessUnitPermissionGroups.forEach((pg: IPermissionGroup) => {
        if (permissionGroupId == pg.id) {
          pg.permissionOptions?.forEach((opt: IPermissionOption) => {
            if (opt.id == permissionGroupOptionId) {
              rtnOption = opt;
            }
          });
        }
      });
      //deal options
      this.structure.globalDealTeamPermissionGroups.forEach((pg: IPermissionGroup) => {
        if (permissionGroupId == pg.id) {
          pg.permissionOptions?.forEach((opt: IPermissionOption) => {
            if (opt.id == permissionGroupOptionId) {
              rtnOption = opt;
            }
          });
        }
      });
    }
    // console.log(rtnOption);
    return rtnOption;
  }

  /**
   * Save the employee role
   */
  savePermissions() {
    // console.log(this.selectedRole);
    this.store.dispatch(toggleLoading({loading: true}));
    this.companyService.saveEmployeeRoles(this.selectedEmployee?.id, this.selectedRole).subscribe({
      next: (role: IEmployeeRole) => {
        if (!!role && role.isCustomRole) {
          this.setCustomRoleId(role.id);
        }
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Employee Permissions Updated", $localize`:@@companyName:Rondeivu`);
        //if the user is editing their own permissions then reload the nav items and navigate.
        if (this.currentUser.id == this.selectedEmployee.user.id) {
          // this.util.getUserNavItems().then(() => {
          //   console.log('Fetching tasks...');
          //   this.store.dispatch({type: fetchTasks});
          // });
          this.toastr.warning("You have modified your own company permissions and must refresh the page or log in again for these changes to take effect.", $localize`:@@companyName:Rondeivu`);
        }
      }, error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to get update employee permissions!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  /**
   * When an employee permission changes
   * @param permissionGroupId
   * @param event
   */
  onChange(permissionGroupId: string, event: any) {
    const option = event.value as unknown as IPermissionOption;

    //add the selection to the option map
    if (this.selectedOptions.has(permissionGroupId)) {
      this.selectedOptions.delete(permissionGroupId);
    }
    this.selectedOptions.set(permissionGroupId, option);

    //get the custom role
    let customRole = this.getCustomRole();
    // clone the currently selected role
    customRole.permissionGroups = JSON.parse(JSON.stringify(this.selectedRole?.permissionGroups));
    // find the permission group being changed
    // replace the permission group option to the event payload
    customRole.permissionGroups.forEach((pg: { permissionGroupId: string, permissionGroupOptionId: string }) => {
      if (pg.permissionGroupId == permissionGroupId) {
        pg.permissionGroupOptionId = option.id;
      }
    });

    // rename the custom role
    if (!this.selectedRole?.isCustomRole) {
      customRole.name = 'Custom [' + this.selectedEmployee?.user?.firstName + ' ' + this.selectedEmployee?.user?.lastName + ']';
    }

    // add the custom role
    if (!this.hasCustomRole()) {
      this.allRoles.push(customRole);
    }

    this.selectedRole = customRole;
    this.userForm.get('role')?.patchValue(this.selectedRole);
  }

  checkRootFolders(event: MatCheckboxChange) {
    if (event.checked) {
      let folders = this.userForm.get('dataroomRootFolders')?.value;
      // console.log(folders);
      folders.forEach((f: any) => {
        f.active = true;
      });
      this.userForm.get('dataroomRootFolders')?.patchValue(folders);
    }
  }

  checkDocumentRootFolders(event: MatCheckboxChange) {
    if (event.checked) {
      let folders = this.userForm.get('documentsRoomRootFolders')?.value;
      folders.forEach((f: any) => {
        f.active = true;
      });
      this.userForm.get('documentsRoomRootFolders')?.patchValue(folders);
    }
  }

  toggleRootFolderActive(event: MatCheckboxChange, rootFolder: any) {
    let folders = this.userForm.get('dataroomRootFolders')?.value;
    folders.forEach((f: any) => {
      if (rootFolder.id == f.id) {
        f.active = event.checked;
      }
    });
    this.userForm.get('dataroomRootFolders')?.patchValue(folders);
  }

  toggleDocumentFolderActive(event: MatCheckboxChange, rootFolder: any) {
    let folders = this.userForm.get('documentsRoomRootFolders')?.value;
    folders.forEach((f: any) => {
      if (rootFolder.id == f.id) {
        f.active = event.checked;
      }
    });
    this.userForm.get('documentsRoomRootFolders')?.patchValue(folders);
  }

  private hasCustomRole(): boolean {
    for (let i = 0; i <= this.allRoles.length - 1; i++) {
      if (this.allRoles[i].isCustomRole) {
        return true;
      }
    }
    return false;
  }

  private getCustomRole(): IEmployeeRole {
    for (let i = 0; i <= this.allRoles.length - 1; i++) {
      if (this.allRoles[i].isCustomRole) {
        return this.allRoles[i];
      }
    }
    return {
      id: '',
      description: 'This is a custom user permission profile',
      isCustomRole: true,
      permissionGroups: []
    } as unknown as IEmployeeRole;
  }

  private setCustomRoleId(id: string) {
    for (let i = 0; i <= this.allRoles.length - 1; i++) {
      if (this.allRoles[i].isCustomRole) {
        this.allRoles[i].id = id;
      }
    }
  }

}
