<div class="cont">
  <div class="box" [ngStyle]="{'height': getHeight()}"></div>
<!--  the line-->
  <div class="box overlay" [ngStyle]="{'margin-top': hasTitles(dealTimeline.markers, 'top') ? '37px': '12px'}">
    <span id="timeline"></span>
  </div>
<!--  top titles-->
  <div class="box overlay3" *ngIf="hasTitles(dealTimeline.markers, 'top') && showTitles">
    <div *ngFor="let t of dealTimeline.markers; index as z;">
      <span *ngIf="!!t.titlePos && t.titlePos =='top'"  class="tl" [ngStyle]="{'left': getPercent(t.xPos)}">{{t.title}}</span>
    </div>
  </div>
<!--  markers-->
  <div class="box overlay2" *ngIf="!!dealTimeline.markers" [ngStyle]="{'margin-top': hasTitles(dealTimeline.markers, 'top') ? '25px': 0}">
    <span [class]="t.tooltip !='' && circleHover[y] ? 'dot dot-md dot-tip' : 'dot dot-md'" *ngFor="let t of dealTimeline.markers; index as y;"
          [matTooltip]="!!t.tooltip? t.tooltip : ''"
          matTooltipPosition="above"
          [ngStyle]="getMarkerStyles(t)"
          (mouseenter)="circleHover[y]=true;"
          (mouseleave)="circleHover[y]=false;"
    >
<!--      <span class="ttt" *ngIf="!!t.tooltip && circleHover[y]">{{t.tooltip}}</span>-->
    </span>
  </div>
<!--  bottom titles-->
  <div class="box overlay4" *ngIf="hasTitles(dealTimeline.markers, 'top')">
    <div *ngFor="let t of dealTimeline.markers; index as z;" >
      <span *ngIf="t.titlePos=='bottom'" class="tl" [ngStyle]="{'left': getPercent(t.xPos)}">{{t.title}}</span>
    </div>
  </div>
<!--  current-->
  <div class="box overlay5" [ngStyle]="{'left': getPercent(dealTimeline.current.xPos, PADDING_LEFT), 'margin-top': hasTitles(dealTimeline.markers, 'top') ? '30px': '5px'}" *ngIf="!!dealTimeline.current">
    <span [class]="dealTimeline.current.tooltip !='' && markerHover ? 'dot dot-sm dot-tip' : 'dot dot-sm' " [ngStyle]="{'background-color':getCurrentColor(dealTimeline.current)}"
          [matTooltip]="!!dealTimeline.current.tooltip? dealTimeline.current.tooltip : ''"
          matTooltipPosition="below"
          (mouseenter)="markerHover=true;"
          (mouseleave)="markerHover=false;"
    >
<!--      <span class="ttt" *ngIf="!!dealTimeline.current.tooltip && markerHover">{{dealTimeline.current.tooltip}}</span>-->
    </span>
  </div>
</div>
