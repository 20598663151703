import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

export enum UserCardSize {
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
  XL = 'XL'
}

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit, OnChanges {

  @Input() firstName: string | undefined = '';
  @Input() lastName: string | undefined = '';
  @Input() displayName: string | undefined = '';
  @Input() borderColor: string | undefined = undefined;
  @Input() sideColor: string | undefined = undefined;
  @Input() icon: string | undefined = undefined;
  @Input() background: string | undefined = undefined;
  @Input() size: UserCardSize = UserCardSize.SM;
  @Input() image: string | undefined = '';
  @Input() imageShape: string | undefined = '';
  @Input() highlightOnHover: boolean | undefined = false
  userCardSize = UserCardSize;

  constructor() {
  }

  ngOnInit(): void {
  }

  getBorder() {
    if (!!this.borderColor) {
      return '1px solid' + this.borderColor;
    }
    return '';
  }

  getSide() {
    if (!!this.sideColor) {
      return '6px solid' + this.sideColor;
    }
    return '';
  }

  getImageShapeClass() {
    return this.imageShape == 'square' ? 'img-square' : 'img';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes && changes['size']) {
      this.size = changes['size'].currentValue;
    }
    if (!!changes && changes['image']) {
      this.image = changes['image'].currentValue;
    }
    if (!!changes && changes['highlightOnHover']) {
      this.highlightOnHover = changes['highlightOnHover'].currentValue;
    }
  }

}
