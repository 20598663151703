import { Component, OnInit } from '@angular/core';
import {Config, getConfig, initialConfigState} from "../../../modules/redux";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";

@Component({
  selector: 'app-auth-base',
  templateUrl: './auth-base.component.html',
  styleUrls: ['./auth-base.component.scss']
})
export class AuthBaseComponent implements OnInit {

  config:Config = initialConfigState;

  constructor( private _router: Router, private store:Store ) {

    this.store.select(getConfig).subscribe((config:Config)=>{
      this.config = config;
    })
  }

  public ngOnInit(): void {

  }

  ngOnDestroy() {

  }

  getYear() : string {
    const now = new Date;
    return now.getFullYear().toString();
  }
}
