import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {
  Config,
  getConfig,
  getLookups, getSelectedBusinessUnit,
  initialConfigState,
  initialLookupsState,
  Lookups, toggleLoading
} from "../../../redux";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {IBusinessUnit} from "../../../business-units/models";
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {HttpErrorResponse} from "@angular/common/http";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {HistoryService} from "../../services";
import {IHistory} from "../../models/i-history";

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class HistoryComponent implements OnInit, OnDestroy, OnChanges {

  @Input() title: string = '';
  @Input() subTitle: string = '';
  @Input() type: string = '';
  @Input() id: string = '';

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  lookups: Lookups = initialLookupsState;
  config: Config = initialConfigState;
  selectedBusinessUnit$: Subscription = new Subscription();
  bu: IBusinessUnit = {} as unknown as IBusinessUnit;

  expandedElement: IHistory | null = {} as IHistory;
  dataSource: MatTableDataSource<IHistory> = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  columnsToDisplay = ['propertyName', 'oldValue', 'arrow', 'newValue', 'businessUnit', 'user', 'changedDate',];
  historyCols = ['propertyName', 'oldValue', 'arrow', 'newValue'];

  // columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay];

  data: IHistory[] = [];
  uniquePropertyNames: string[] = [];

  // fromDate = new Date().toDateString();
  // toDate = new Date().toDateString();
  fromDate = '';
  toDate = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private toastr: ToastrService,
    private history: HistoryService
  ) {
    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.bu = Object.assign({}, bu);
      if (!!this.bu && !!this.bu.businessUnitId) {
        this.getParams();
      }
    });

    this.lookup$ = this.store.select(getLookups).subscribe(lookups => {
      this.lookups = lookups;
    });

  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
  }

  subscribeToDatasource() {
    console.log("Fetching History...");
    this.store.dispatch(toggleLoading({loading: true}));
    this.history.getHistory(this.type, this.id, this.formatDate(this.fromDate), this.formatDate(this.toDate)).subscribe({
      next: (res: any) => {
        this.data = res;
        this.dataSource = new MatTableDataSource(res);
        this.initDataSource();
        this.getUniquePropertyNamesFromData();
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: (err: HttpErrorResponse) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to get history!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  getParams() {
    let assetType = this.route.snapshot.paramMap.get('assetType');
    if (!!assetType) {
      this.type = assetType.toString();
      this.title = assetType.toString();

      //TODO the history route for users breaks the pattern due to additional fragment
      if (assetType == 'users') {
        this.type = 'test/appusers';
      }
    }

    this.route.queryParams.subscribe({
      next: (params: any) => {
        this.id = params.id;
      }
    })

  }

  initDataSource() {
    this.dataSource.filterPredicate = (data: IHistory, filter: string) => {
      return data.name.toLowerCase() == filter.toLowerCase();
    };
    if (!!this.paginator) {
      this.paginator.pageSize = 100;
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }


  private getUniquePropertyNamesFromData() {
    this.data.forEach((d: IHistory) => {
      if (!this.uniquePropertyNames.includes(d.name)) {
        this.uniquePropertyNames.push(d.name);
      }
    });
    this.uniquePropertyNames.sort();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterProp(filterValue);
  }

  filterProp(prop: string) {
    this.dataSource.filter = prop.trim();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  canExpand(element: any): boolean {
    return !!element && !!element.changes && element.changes.length > 0;
  }

  expandRow(element: any) {
    if (this.canExpand(element)) {
      this.expandedElement = this.expandedElement === element ? null : element;
    }
  }

  private formatDate(date: string) {
    if (date == '') {
      return date;
    }
    let d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return year + '-' + month + '-' + day + "T00:00:00.000";
    // return [year, month, day].join('-');
  }
}


