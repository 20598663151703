<div style="overflow-y: auto; height: calc(100vh - 176px);">
  <div class="container-fluid">
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-6 col-md-8">
        <span class="rm-title rm-title-3">Entity Kyc</span><br/>
        <span class="rm-title rm-title-6 rm-lighter">Select a KYC.</span>
      </div>
      <div class="col-6 col-md-3 text-end">
        <button mat-raised-button color="primary" (click)="addKyc()">Add</button>
      </div>
    </div>
    <!--    table start-->
    <div class="row justify-content-center d-none d-md-flex" style="padding-top:25px; padding-bottom: 150px;">
      <div class="col-12 col-md-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <table mat-table [dataSource]="dataSource"
                     [multiTemplateDataRows]="true"
                     matSort
                     (matSortChange)="sortData($event)"
              >
                <!-- Search Header -->
                <ng-container matColumnDef="table-name">
                  <th colspan="2" mat-header-cell *matHeaderCellDef>Kyc</th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="search-header">
                  <th colspan="3" mat-header-cell *matHeaderCellDef>
                    <form>
                      <mat-form-field style="width: 100%;">
                        <mat-label>Search</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" #input/>
                        <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                    </form>
                  </th>
                </ng-container>
                <!--  Column -->
                <ng-container matColumnDef="kycId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="kycId"> Kyc ID</th>
                  <td mat-cell *matCellDef="let element">
                    <span style="font-size: small;">{{ element["businessUnitEntityKycId"] }} </span>
                  </td>
                </ng-container>
                <!--  Column -->
                <ng-container matColumnDef="entityId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="entityId"> Entity ID</th>
                  <td mat-cell *matCellDef="let element">
                    <span style="font-size: small;">{{ element["businessUnitEntityId"] }} </span>
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="name"> KYC Name</th>
                  <td mat-cell *matCellDef="let element">
                    <div style="display: flex;">
                      <mat-icon *ngIf="element['archived']"
                                style="color: rgba(128,128,128,0.5)">{{ element["archived"] ? 'lock' : '' }}
                      </mat-icon>&nbsp;
                      <span style="font-size: small;">{{ element["kycName"] }} </span>
                    </div>
                  </td>
                </ng-container>
                <!-- Column -->
                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef> Type</th>
                  <td mat-cell *matCellDef="let element">
                    <span style="font-size: small;">{{ element["kycTitle"] }} </span>
                  </td>
                </ng-container>
                <!--  Column -->
                <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="type"> Entity Type</th>
                  <td mat-cell *matCellDef="let element">
                    <span style="font-size: small;">{{ element["entityType"] }} </span>
                  </td>
                </ng-container>
                <!--  Column -->
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="status"> Status</th>
                  <td mat-cell *matCellDef="let element">
                    <span style="font-size: small;">{{ element["kycRondeivuStatus"] }} </span>
                  </td>
                </ng-container>
                <!--  Column -->
                <ng-container matColumnDef="created">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="created"> Created</th>
                  <td mat-cell *matCellDef="let element">
                    <span style="font-size: small;">{{ element["created"] | date:'medium' }} </span>
                  </td>
                </ng-container>
                <!--  Column -->
                <ng-container matColumnDef="comments">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="comments"> Comments</th>
                  <td mat-cell *matCellDef="let element">
                    <span style="font-size: small;">{{ element["publicComments"] }} </span>
                  </td>
                </ng-container>
                <!-- Actions Column -->
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef class="text-center"> Actions</th>
                  <td mat-cell *matCellDef="let element">
                    <div style="display: flex; justify-content: end;">
                      <button *ngIf="element.archived"
                              mat-icon-button
                              (click)="downloadKyc(element)"
                              matTooltip="Download PDF"
                              matTooltipPosition="left"
                      >
                        <mat-icon>download</mat-icon>
                      </button>
                      <button *ngIf="!element.archived"
                              mat-icon-button
                              (click)="cloneKyc(element)"
                              matTooltip="Clone KYC"
                              matTooltipPosition="left"
                      >
                        <mat-icon>content_copy</mat-icon>
                      </button>
                      <button *ngIf="!element.archived || util.isType('admin')"
                              mat-icon-button
                              (click)="viewKyc(element)"
                              matTooltip="View KYC"
                              matTooltipPosition="left"
                      >
                        <mat-icon>east</mat-icon>
                      </button>
                    </div>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="['table-name','search-header']"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"></tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter.</td>
                </tr>
              </table>
            </div>
          </div>
          <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[5, 10, 25, 100]"
                         aria-label="Select page of users"></mat-paginator>
        </div>
      </div>
    </div>
    <!--    list start-->
    <div class="row justify-content-center d-flex d-md-none" style="padding-bottom: 150px; padding-top: 15px;">
      <div class="col-12">
        <ul style="list-style-type: none; padding: 25px 0 0 0;">
          <li *ngFor="let element of dataSource.data"
              style="margin-bottom: 5px; display: flex; justify-content: space-between;">
            <app-user-card [firstName]="element?.businessUnitEntityName"
                           [displayName]="element?.entityType"
                           [borderColor]="'transparent'"
                           [background]="'#F1EDE4'"
                           [sideColor]="'#002150'"
                           [size]="userCardSize.SM"
            ></app-user-card>
            <div style="display: flex;">
              <button
                mat-icon-button
                (click)="viewKyc(element)">
                <mat-icon>east</mat-icon>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
