export interface IDealSyndication {
  amountRemaining: number;
  issuerPoolIsLocked: boolean;
  percentCommitted: number;
  showIndicateInterestModalButton: boolean;
  showLockPoolButton: boolean;
  syndicationItems: ISyndicationItem[];
  totalAllocation: number;
  totalFundraiseSize: number;
  totalIndicationsOfInterest: number;
}

export interface ISyndicationItem {
  allocationAmount: number;
  created: string;
  id: string;
  indicationAmount: number;
  investor: ISyndicationInvestor;
  notes: string;
  restrictionType: string;
  showInitiateSubDocsButton: boolean;
  showSaveDraftButton: boolean;
  status: string;
  subDocs: ISyndicationSubdoc[]
}

export interface ISyndicationInvestor {
  displayName: string;
  id: string;
  imageUrl: string;
  profile: string;
}

export interface ISyndicationSubdoc {
  id: string;
  dealId: string;
  amount: number;
  status: string;
  entityId: string;
  entityName: string;
  entityType: string;
  subscriptionId: string;
  subscriptionAmount: number;
  subscriptionDate: string;
  showInvestorFillDataAndApproveButton: boolean;
  showRondeivuApprovalButton: boolean;
  showIssuerApprovalButton: boolean;
  subDocAccessUser: ISubDocUser[];
  uploadedFiles: ISubDocFile[];
  created: string;
}

export interface ISubDocUser {
  id: string;
  fullName: string;
  email: string;
  profile: string;
  businessUnitEntityKycPersonId: string;
  emailStatus: string;
  signatureStatus: string;
  sortOrder: number;
  accessType: string;
}

export interface ISubDocFile {
  id: string;
  filename: string;
  fileType: string;
  fileSize: string;
  contentType: string;
  uploadedBy: {
    id: string;
    subId: string;
    firstName: string;
    lastName: string;
    email: string;
    countryCode: string;
    mobile: string;
    profileLink: string;
    imageUrl: string;
    active: boolean;
    created: string;
  }
}

export interface ISubDocFieldsResponse {
  approveForInvestorSignatures: boolean;
  content: string;
  fieldsList: ISubDocField[];
}

export interface ISubDocField {
  locked: boolean;
  required: boolean;
  type: SubDocFieldType;
  uuid: string;
  placeholder: string;
  values: any[];
  options?: {
    key: string;
    title: string;
    value: string;
    locked: boolean;
  }[];
  model: any;
}

export enum SubDocFieldType {
  SIGNATURE = 'SIGNATURE',
  SINGLE_CLAUSE = 'SINGLE_CLAUSE',
  SIGNER_TITLE = 'SIGNER_TITLE',
  SIGNER_FULL_NAME = 'SIGNER_FULL_NAME',
  SIGNER_COMPANY = 'SIGNER_COMPANY',
  CHECKBOX = 'CHECKBOX',
  TEXT = 'TEXT',
  PARAGRAPH = 'PARAGRAPH',
  RADIO = 'RADIO',
  SELECT = 'SELECT'
}
