import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UsersService} from "../../services/users/users.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {MatTableDataSource} from "@angular/material/table";
import {
  Config,
  getConfig,
  getSelectedBusinessUnit,
  initialConfigState,
  initialThemeState,
  Theme,
  toggleLoading, updateDealId, updateEditingBusinessUnit
} from "../../../redux";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {IBusinessUnit} from "../../../business-units/models";
import {Subscription} from "rxjs";
import {UserModalComponent} from "../user-modal/user-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {ModalSize, UtilService} from "../../../shared/services/util/util.service";
import {toggleNavigating} from "../../../redux/stores/config/config.actions";
import {IUserResponse} from "../../models/i-users";

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class UserListComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();

  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  userList: IUserResponse[] = [];
  sortedUsers: IUserResponse[] = [];
  config: Config = initialConfigState;
  theme: Theme = initialThemeState;
  columnsToDisplay = ['user', 'registered', 'emailVerified', 'phoneVerified', 'cognito', 'Date', 'edit'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: IUserResponse | null = {} as IUserResponse;
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  constructor(private store: Store,
              private router: Router,
              private userService: UsersService,
              private toastr: ToastrService,
              private dialog: MatDialog,
              public util: UtilService
  ) {

    this.config$ = this.store.select(getConfig).subscribe((configState: any) => {
      this.config = configState;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      if (!!bu && !!bu.businessUnitId) {
        this.subscribeToDatasource();
      }
    });
  }

  ngOnInit(): void {
    this.store.dispatch(updateEditingBusinessUnit({businessUnit: {} as IBusinessUnit}));
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
  }

  subscribeToDatasource() {
    console.log("Fetching Users...");
    this.store.dispatch(updateDealId({dealId: ''}));
    this.store.dispatch(toggleLoading({loading: true}));
    //fetch the users
    this.userService.getUsers().subscribe({
      next: (users: IUserResponse[]) => {
        this.userList = users;
        this.dataSource = new MatTableDataSource(this.userList);
        this.initDataSource();
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: err => {
        this.toastr.error("Unable to get users!", $localize`:@@companyName:Rondeivu`);
        this.store.dispatch(toggleLoading({loading: false}));
      }
    });
  }

  initDataSource() {
    this.dataSource.filterPredicate = (data: IUserResponse, filter: string) => {
      const concatName = data.firstName + ' ' + data.lastName;
      const lc_filter = filter.toLowerCase();
      let NAME = !!concatName ? concatName?.toLowerCase().includes(lc_filter) : false;
      let EMAIL = !!data.email ? data.email?.toLowerCase().includes(lc_filter) : false;
      return NAME || EMAIL;
    };
    if (!!this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  /**
   * Navigate to the user route
   * @param u
   */
  viewUser(u: any) {
    this.store.dispatch(toggleNavigating({navigating: true}));
    setTimeout(() => {
      this.store.dispatch(toggleNavigating({navigating: false}));
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/users/' + u.id]);
    }, 1000);
  }

  viewHistory(u: any) {
    this.store.dispatch(toggleNavigating({navigating: true}));
    setTimeout(() => {
      this.store.dispatch(toggleNavigating({navigating: false}));
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/history/users'], {queryParams: {id: u.id}});
    }, 1000);
  }

  /**
   * Add a new user via modal
   */
  addUser() {
    let userRef = this.dialog.open(UserModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      disableClose: true,
      data: {
        isEditing: false,
        user: {}
      }
    });

    userRef.afterClosed().subscribe((result: any) => {
      if (!!result) {
        console.log("Adding new user!");
        this.store.dispatch(toggleLoading({loading: true}));
        this.userService.addUser(result).subscribe({
          next: (res: any) => {
            // this.userList.push(res);
            // this.dataSource = new MatTableDataSource(this.userList);
            // this.initDataSource();
            //TODO this is re-getting the entire list
            this.subscribeToDatasource();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("User added!", $localize`:@@companyName:Rondeivu`);
          }, error: error => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to add user!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });

  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  editUser(user: any) {
    this.toastr.warning('Not Implemented!', $localize`:@@companyName:Rondeivu`);
  }

  delete(user: any) {
    this.toastr.warning('Not Implemented!', $localize`:@@companyName:Rondeivu`);
  }

  getBusinessUnitUrl(bue: any) {
    return '/' + this.config.selected_business_unit.slug + '/business-units/' + bue.businessUnit.id + '/employees';
  }

  //sort
  sortData(sort: Sort) {
    const data = this.userList.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedUsers = data;
      return;
    }

    this.sortedUsers = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'user':
          const n1 = a.firstName + ' ' + a.lastName;
          const n2 = b.firstName + ' ' + b.lastName;
          return compare(n1, n2, isAsc);
        case 'created':
          return compare(a.created, b.created, isAsc);
        default:
          return 0;
      }
    });

    this.dataSource = new MatTableDataSource(this.sortedUsers);
    this.initDataSource();
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

