<div *ngFor="let section of sectionTexts" style="padding-bottom: 15px;">
  <div *ngIf="!!section.header" style="padding-bottom: 15px;">
    <span style="font-size: large; font-weight: bold;">{{ section.header }}</span>
  </div>
  <div *ngIf="!!section.detail" style="padding-bottom: 15px;">
    <span>{{ section.detail }}</span>
  </div>
  <div *ngIf="!!section.list && section.list.length > 0" style="padding-bottom: 15px;">
    <ul>
      <li *ngFor="let item of section.list">
        {{ item }}
      </li>
    </ul>
  </div>
</div>
