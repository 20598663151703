import {Injectable, OnDestroy} from '@angular/core';
import {Subscription} from "rxjs";
import {
  Config,
  getConfig,
  getScreenHeight,
  getScreenWidth, initialCognitoUserState,
  initialConfigState, toggleLoading,
  updateCognitoUser,
  updateConfig, updateDeal, updateDealId, updateDealRole, updateEditingBusinessUnit, updateNavItems
} from "../../../redux";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {IDealCard, Permission} from "../../models";
import {Auth} from "aws-amplify";
import {ILookup, INavItem} from "../../../../models";
import {clearDeals} from "../../../redux/stores/deal/deal.actions";
import {clearMandates} from "../../../redux/stores/mandate/mandate.actions";
import {clearBusinessUnits} from "../../../redux/stores/business-unit/business-unit.actions";
import {IBusinessUnit, IBusinessUnitRole} from "../../../business-units/models";
import {toggleNavigating} from "../../../redux/stores/config/config.actions";
import {AppConfigService} from "../../../../services";

export enum ModalSize {
  SMALL = 0,
  MEDIUM = 1,
  LARGE = 3,
  FULL = 4
}

@Injectable({
  providedIn: 'root'
})
export class UtilService implements OnDestroy {

  Permission = Permission;
  NoAccessMsg = $localize`:@@noAccess:You do not have permission to perform this action`;
  NoAccessCursor = 'not-allowed';
  private readonly MAX_REDIRECT_RETRIES: number = 1;
  private numRedirectRetries: number = 0;
  // Declare height and width variables
  screenHeight = window.innerHeight;
  screenWidth = window.innerWidth;
  config$: Subscription = new Subscription();

  //used to store the url before auth redirect to login
  private redirect: string = '';

  readonly MOBILE_WIDTH = 768;

  private config: Config = initialConfigState;

  constructor(
    private store: Store,
    private router: Router,
    private toastr: ToastrService,
    private appConfig: AppConfigService
  ) {
    //config
    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });
    //height
    this.config$.add(this.store.select(getScreenHeight).subscribe(height => {
      this.screenHeight = height;
    }));
    //width
    this.config$.add(this.store.select(getScreenWidth).subscribe(width => {
      this.screenWidth = width;
    }));
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
  }

  setRedirectPath(url: string) {
    this.redirect = url;
  }

  getRedirectPath(): string {
    return this.redirect;
  }

  incrementRedirectRetries() {
    this.numRedirectRetries += 1;
  }

  resetRedirectRetries() {
    this.numRedirectRetries = 0;
  }

  canRedirect(): boolean {
    return this.numRedirectRetries < this.MAX_REDIRECT_RETRIES;
  }

  /**
   * logs out of the app and redirects user to login
   */
  public async logOut(): Promise<void> {
    Auth.signOut().then(() => {
      localStorage.clear();
      this.setRedirectPath('');
      this.router.navigate(['/auth/login']).then(() => {
        this.clearStoreCache();
        this.resetRedirectRetries();
        this.store.dispatch(updateConfig({config: initialConfigState}));
        this.store.dispatch(updateCognitoUser({cognito: initialCognitoUserState}));
      });
    });
  }

  public clearStoreCache() {
    //clear out the cached objects
    this.store.dispatch(clearDeals());
    this.store.dispatch(clearMandates());
    this.store.dispatch(clearBusinessUnits());
    this.store.dispatch(updateDealId({dealId: ''}));
    this.store.dispatch(updateDealRole({dealRole: {} as unknown as IBusinessUnitRole}));
    this.store.dispatch(updateDeal({deal: {} as unknown as IDealCard}));
    this.store.dispatch(updateEditingBusinessUnit({businessUnit: {} as unknown as IBusinessUnit}));
  }

  /**
   * Provides the modal css width for the size based on the screen width
   * @param size the modal size
   */
  getModalWidth(size: ModalSize): string {
    switch (size) {
      case ModalSize.SMALL:
        return this.screenWidth <= this.MOBILE_WIDTH ? '100%' : '40vw';
      case ModalSize.MEDIUM:
        return this.screenWidth <= this.MOBILE_WIDTH ? '100%' : '60vw';
      case ModalSize.LARGE:
        return this.screenWidth <= this.MOBILE_WIDTH ? '100%' : '80vw';
      case ModalSize.FULL:
        return '100%';
      default:
        return 'auto';
    }
  }

  getDrawerWidth(): string {
    if ((this.screenWidth == null) || (this.screenWidth == 0)) {
      return '40%';
    }
    return this.screenWidth <= this.MOBILE_WIDTH ? '90%' : '40%';
  }

  /**
   * Determines if the stores selected business unit is the desired profile type
   * @param type the user profile type, i.e. 'admin','issuer','investor'
   */
  isType(type: string) {
    if (!!this.config && !!this.config.selected_business_unit) {
      return this.config.selected_business_unit.businessUnitType?.toLowerCase() === type.toLowerCase();
    }
    return false;
  }

  /**
   * Returns if the current user is an employee of the selected business unit
   */
  isEmployee() {
    if (!!this.config && !!this.config.selected_business_unit) {
      return this.config.selected_business_unit.isEmployeeOfEntity;
    }
    return false;
  }

  /**
   * Helper function to determine if the current user has a permission
   * @param permission
   */
  hasPermission(permission: Permission) {
    if (!!this.config) {
      const inBuRole = this.config.selected_business_unit_role?.permissions?.includes(permission.toString().trim()) || false;
      const inDealRole = this.config.selected_deal_role?.permissions?.includes(permission.toString().trim()) || false;
      return inBuRole || inDealRole;
    }
    return false;
  }

  /**
   * Helper function to determine if all nav items in the list are hidden
   * @param navitems
   */
  allNavItemsHidden(navitems: INavItem[]) {
    let allHidden = true;
    navitems.forEach((navitem: INavItem) => {
      if (!navitem.hidden) {
        allHidden = false;
      }
    });
    return allHidden;
  }

  /**
   * Helper function to determine if all nav items in the list are disabled
   * @param navitems
   */
  allNavItemsDisabled(navitems: INavItem[]) {
    let allDisabled = true;
    navitems.forEach((navitem: INavItem) => {
      if (navitem.enabled) {
        allDisabled = false;
      }
    });
    return allDisabled;
  }

  /**
   * Helper function to get the first nav item that is not hidden or disabled
   * @param navItems
   */
  getFirstValidNavItem(navItems: INavItem[]): INavItem {
    let navItem = {} as unknown as INavItem;
    for (let i = 0; i <= navItems.length - 1; i++) {
      if (navItems[i].enabled && !navItems[i].hidden) {
        navItem = navItems[i];
        break;
      }
    }
    return navItem;
  }


  getUserNavItems(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      //fetch the new nav items
      this.appConfig.getNavigationItems().subscribe({
        next: (navItems: INavItem[]) => {
          this.store.dispatch(updateNavItems({items: navItems}));
          if (navItems.length == 0 || this.allNavItemsHidden(navItems) || this.allNavItemsDisabled(navItems)) {
            // the user has no nav items
            this.store.dispatch(toggleNavigating({navigating: true}));
            this.router.navigate(['/' + this.config.selected_business_unit.slug]).then(() => {
              resolve(true);
              this.store.dispatch(toggleNavigating({navigating: false}));
            });
          } else {
            // route to their first valid item
            const item = navItems.filter((n: INavItem) => {
              return (n.enabled && !n.hidden) ? n : null;
            })[0];
            if (!!item) {
              this.store.dispatch(toggleNavigating({navigating: true}));
              this.router.navigate(['/' + this.config.selected_business_unit.slug + item.link], {queryParams: item.queryParams}).then(() => {
                resolve(true);
                this.store.dispatch(toggleNavigating({navigating: false}));
              });
            } else {
              this.store.dispatch(toggleNavigating({navigating: true}));
              this.router.navigate(['/' + this.config.selected_business_unit.slug]).then(() => {
                resolve(true);
                this.store.dispatch(toggleNavigating({navigating: false}));
              });
            }
          }
        }, error: () => {
          reject(false);
        }
      });
    });
  }

  /**
   * Allows the admin user to jump to the business unit given id
   * @param id the editing business unit id
   * @param tab optional business unit tab route
   */
  viewBusinessUnit(id: string, tab?: string) {
    if (this.isType('admin')) { // ADMIN ONLY
      this.store.dispatch(toggleLoading({loading: true}));
      console.log('/' + this.config.selected_business_unit.slug + '/business-units/' + id);
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/business-units/' + id + (!!tab ? '/' + tab : '')]).then(() => {
        this.store.dispatch(toggleLoading({loading: false}));
      });
    }
  }

  /**
   * Returns the value of a key from a specific lookup
   * @param lookup the lookup to search
   * @param key the key sto search
   */
  getLookupValueForKey(lookup: ILookup[], key: string): string {
    let item: string = '';
    lookup.forEach((l: ILookup) => {
      if (l.key == key) {
        item = l.value;
      }
    });
    return item;
  }

}
