import {createFeatureSelector, createSelector} from "@ngrx/store";
import {AppState} from "../../index";

export const getAppState = createFeatureSelector<AppState>('app');
//config
export const getConfig = createSelector(
  getAppState,
  (state: AppState) => state.config
);
//height
export const getScreenHeight = createSelector(
  getAppState,
  (state: AppState) => state.config.screenHeight
);
//width
export const getScreenWidth = createSelector(
  getAppState,
  (state: AppState) => state.config.screenWidth
);
//user groups
export const getUserGroups = createSelector(
  getAppState,
  (state: AppState) => state.config.user_groups
);
export const getNavItems = createSelector(
  getAppState,
  (state: AppState) => state.config.nav_items
);
//dark mode
export const isDarkMode = createSelector(
  getAppState,
  (state: AppState) => state.config.rondeivu_theme_dark
);
//loading
export const isLoading = createSelector(
  getAppState,
  (state: AppState) => state.config.is_loading
);
//navigating
export const isNavigating = createSelector(
  getAppState,
  (state: AppState) => state.config.is_navigating
);
//drawer state
export const drawerState = createSelector(
  getAppState,
  (state: AppState) => state.config.right_drawer_open
);
//selected view
export const selectedView = createSelector(
  getAppState,
  (state: AppState) => state.config.selected_user_view
);
//get selected business unit
export const getSelectedBusinessUnit = createSelector(
  getAppState,
  (state: AppState) => state.config.selected_business_unit
);
//get selected business unit role
export const getSelectedBusinessUnitRole = createSelector(
  getAppState,
  (state: AppState) => state.config.selected_business_unit_role
);
//get editing business unit
export const getEditingBusinessUnit = createSelector(
  getAppState,
  (state: AppState) => state.config.editing_business_unit
);
//get editing business unit
export const getEditingBusinessUnitEmployee = createSelector(
  getAppState,
  (state: AppState) => state.config.editing_business_unit_employee
);
//get selected deal id
export const getSelectedDealId = createSelector(
  getAppState,
  (state: AppState) => state.config.selected_deal_id
);
//get selected deal role
export const getSelectedDealRole = createSelector(
  getAppState,
  (state: AppState) => state.config.selected_deal_role
);
//get selected deal
export const getSelectedDeal = createSelector(
  getAppState,
  (state: AppState) => state.config.selected_deal
);
//get selected deal tabs
export const getSelectedDealTabs = createSelector(
  getAppState,
  (state: AppState) => state.config.deal_tabs
);
//get selected mandate id
export const getSelectedMandateId = createSelector(
  getAppState,
  (state: AppState) => state.config.selected_mandate_id
);
//get selected mandate
export const getSelectedMandate = createSelector(
  getAppState,
  (state: AppState) => state.config.selected_mandate
);
//get selected user id
export const getSelectedUserId = createSelector(
  getAppState,
  (state: AppState) => state.config.selected_user_id
);
//get selected user
export const getSelectedUser = createSelector(
  getAppState,
  (state: AppState) => state.config.selected_user
);
//get selected legal id
export const getSelectedLegalId = createSelector(
  getAppState,
  (state: AppState) => state.config.selected_legal_id
);
//get selected legal
export const getSelectedLegal = createSelector(
  getAppState,
  (state: AppState) => state.config.selected_legal
);
//get selected kyc id
export const getSelectedKycId = createSelector(
  getAppState,
  (state: AppState) => state.config.selected_kyc_id
);
//get selected kyc id
export const getSelectedKyc = createSelector(
  getAppState,
  (state: AppState) => state.config.selected_kyc
);
//get show notes tab state
export const getShowNotesTabState = createSelector(
  getAppState,
  (state: AppState) => state.config.show_notes_tab
);
//get the deal subscription
export const getDealSubscription = createSelector(
  getAppState,
  (state: AppState) => state.config.deal_subscription
);
//get the deal syndication
export const getDealSyndication = createSelector(
  getAppState,
  (state: AppState) => state.config.deal_syndication
);
//get the tasks
export const getTasks = createSelector(
  getAppState,
  (state: AppState) => state.config.tasks
);
//get selected employee id
export const getSelectedEmployeeId = createSelector(
  getAppState,
  (state: AppState) => state.config.selected_employee_id
);
//get selected employee
export const getSelectedEmployee = createSelector(
  getAppState,
  (state: AppState) => state.config.selected_employee
);

//get selected deal team member
export const getSelectedDealTeamMember = createSelector(
  getAppState,
  (state: AppState) => state.config.selected_deal_team_member
);

//get selected entity id
export const getSelectedEntityId = createSelector(
  getAppState,
  (state: AppState) => state.config.selected_business_unit_entity_id
);

//get selected entity
export const getSelectedEntity = createSelector(
  getAppState,
  (state: AppState) => state.config.selected_entity
);

//get selected entity kyc
export const getSelectedEntityKyc = createSelector(
  getAppState,
  (state: AppState) => state.config.selected_entity_kyc
);




