import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MandateService} from "../../services";
import {
  Config, fetchTasks,
  getConfig,
  getSelectedBusinessUnit,
  initialConfigState,
  initialThemeState,
  Theme,
  toggleLoading, updateDealId, updateEditingBusinessUnit
} from "../../../redux";
import {Store} from "@ngrx/store";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {Router} from "@angular/router";
import {IBusinessUnit} from "../../../business-units/models";
import {ToastrService} from "ngx-toastr";
import {iMandateResponse} from "../../models";
import {environment} from "../../../../../environments/environment";
import {Subscription} from "rxjs";
import {MandateModalComponent} from "../mandate-modal/mandate-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {ModalSize, UtilService} from "../../../shared/services/util/util.service";
import {addMandates} from "../../../redux/stores/mandate/mandate.actions";
import {toggleNavigating} from "../../../redux/stores/config/config.actions";

@Component({
  selector: 'app-mandates-list',
  templateUrl: './mandates-list.component.html',
  styleUrls: ['./mandates-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MandatesListComponent implements OnInit, OnDestroy {

  private readonly DEFAULT_LOAD_TIME = environment.rondeivu_default_load_delay_ms;

  config$: Subscription = new Subscription();
  mandate$: Subscription = new Subscription();

  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  mList: iMandateResponse[] = [];
  sortedMandates: iMandateResponse[] = [];
  config: Config = initialConfigState;
  theme: Theme = initialThemeState;
  columnsToDisplay = ['businessUnit', 'employee', 'firmAum', 'programAum', 'status', 'created', 'edit'];
  columnsInvestor = ['employee', 'firmAum', 'programAum', 'status', 'created', 'edit'];

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  constructor(private store: Store,
              private router: Router,
              private toastr: ToastrService,
              private mandateService: MandateService,
              private dialog: MatDialog,
              public util: UtilService
  ) {

    this.config$ = this.store.select(getConfig).subscribe((configState: any) => {
      this.config = configState;
    });

    this.mandate$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      if (!!bu && !!bu.businessUnitId) {
        this.subscribeToDatasource();

        //custom investor columns
        if (!this.util.isType('admin')) {
          this.columnsToDisplay = this.columnsInvestor;
        }
      }
    });

  }

  ngOnInit(): void {
    this.store.dispatch(updateEditingBusinessUnit({businessUnit: {} as IBusinessUnit}));
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.mandate$.unsubscribe();
  }

  subscribeToDatasource() {
    console.log("Fetching Mandates...");
    this.store.dispatch(updateDealId({dealId: ''}));
    this.store.dispatch(toggleLoading({loading: true}));
    //fetch the mandates
    this.mandateService.getMandates().subscribe({
      next: (mandates: iMandateResponse[]) => {

        //todo ngrx entities should only be cached once
        this.store.dispatch(addMandates({mandates: mandates}));

        this.mList = mandates;
        this.dataSource = new MatTableDataSource(this.mList);
        this.initDataSource();
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: err => {
        this.toastr.error("Unable to get mandates!", $localize`:@@companyName:Rondeivu`);
        this.store.dispatch(toggleLoading({loading: false}));
        // todo Reload the nav items and tasks
        // if (err.status == 403) {
        //   this.util.getUserNavItems().then(() => {
        //     console.log('Fetching tasks...');
        //     this.store.dispatch({type: fetchTasks});
        //   });
        // }
      }
    });
  }

  initDataSource() {
    // the search filter criteria
    this.dataSource.filterPredicate = (data: iMandateResponse, filter: string) => {
      const lc_filter = filter.toLowerCase();
      const DN = data.businessUnit.displayName?.toLowerCase().includes(lc_filter);
      const SL = data.businessUnit.slug?.toLowerCase().includes(lc_filter);
      const EMP = (data.createdBy.firstName + ' ' + data.createdBy.lastName).toLowerCase().includes(lc_filter);
      const EMAIL = data.createdBy.email.toLowerCase().includes(lc_filter);

      //custom search for admin
      if (this.util.isType('admin')) {
        return DN || SL || EMP || EMAIL;
      } else {
        return EMP || EMAIL;
      }

    };
    if (!!this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  viewMandate(m: any) {
    this.store.dispatch(toggleNavigating({navigating: true}));
    setTimeout(() => {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/mandates/' + m.id]).then(() => {
        this.store.dispatch(toggleNavigating({navigating: false}));
      });
    }, this.DEFAULT_LOAD_TIME);
  }

  viewHistory(m: any) {
    this.store.dispatch(toggleNavigating({navigating: true}));
    setTimeout(() => {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/history/mandates'], {queryParams: {id: m.id}}).then(() => {
        this.store.dispatch(toggleNavigating({navigating: false}));
      });
    }, this.DEFAULT_LOAD_TIME);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  isEmployee() {
    if (!!this.config && !!this.config.selected_business_unit) {
      return this.config.selected_business_unit.isEmployeeOfEntity;
    }
    return false;
  }

  /**
   * Mandate modal
   */
  openMandateModal() {
    const mandateModalRef = this.dialog.open(MandateModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.MEDIUM),
      disableClose: true
    });

    mandateModalRef.afterClosed().subscribe((result: any) => {
      // console.log(`Dialog result: ${result}`);
      if (!!result) {
        console.log("Creating mandate...");
        this.store.dispatch(toggleLoading({loading: true}));
        this.mandateService.addMandate(result).subscribe({
          next: (res: any) => {
            this.subscribeToDatasource();
            this.toastr.success("Mandate Created!", $localize`:@@companyName:Rondeivu`);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to create mandate!");
          }
        });
      }
    });
  }

  //sort
  sortData(sort: Sort) {
    const data = this.mList.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedMandates = data;
      return;
    }

    this.sortedMandates = data.sort((a: iMandateResponse, b: iMandateResponse) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'employee':
          const n1 = a.createdBy.firstName + ' ' + a.createdBy.lastName;
          const n2 = b.createdBy.firstName + ' ' + b.createdBy.lastName;
          return compare(n1, n2, isAsc);
        case 'displayName':
          return compare(a.businessUnit?.displayName, b.businessUnit?.displayName, isAsc);
        case 'firmAum':
          return compare(a.firmAum, b.firmAum, isAsc);
        case 'programAum':
          return compare(a.programAum, b.programAum, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'created':
          return compare(a.created, b.created, isAsc);
        default:
          return 0;
      }
    });

    this.dataSource = new MatTableDataSource(this.sortedMandates);
    this.initDataSource();
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
