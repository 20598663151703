import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {RondeivuHttpClient} from "../../../handlers";
import {Config, getConfig, initialConfigState} from "../../redux";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private config: Config = initialConfigState;
  private readonly BASE_URL = environment.rondeivu_api;
  private readonly FRAGMENT = '/businessunitemployees';
  private readonly PERMISSION = '/businessunitemployeepermission';
  private readonly ALL_ROLES = '/all-roles';
  private readonly STRUCTURE = '/structure';
  private readonly ROLES = '/roles';
  private readonly EMPLOYEES = '/employee';

  constructor(private store: Store, private http: RondeivuHttpClient) {
    this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
  }

  /**
   * Get the buisiness unit employee by id
   * @param id
   */
  getEmployee(id: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.EMPLOYEES + '/' + id);
  }

  saveEmployee(id: string, payload: any): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + this.EMPLOYEES + '/' + id, payload);
  }

  /**
   * Get the roles for the business unit employee
   * @param employeeId
   */
  getAllRoles(employeeId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.PERMISSION + this.ALL_ROLES + '/' + employeeId);
  }

  /**
   * Get the permissions form structure for the business unit
   */
  getPageStructure(): Observable<any> {
    return this.http.get(this.BASE_URL + this.PERMISSION + this.STRUCTURE);
  }

  /**
   * Get all the current employee roles
   * @param employeeId
   */
  getEmployeeRoles(employeeId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.PERMISSION + this.ROLES + '/' + employeeId);
  }

  /**
   * Save the new employee roles
   * @param employeeId
   * @param payload
   */
  saveEmployeeRoles(employeeId: string, payload: any): Observable<any> {
    return this.http.put(this.BASE_URL + this.PERMISSION + this.ROLES + '/' + employeeId, payload);
  }
}
