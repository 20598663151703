<div style="overflow-y: auto; height: calc(100vh - 178px);">
  <div class="container-fluid">
    <div style="overflow-x: hidden;">
      <div class="row justify-content-center" style="padding-top: 25px;">
        <div class="col-12 col-md-6">
          <span class="rm-title rm-title-3">User Information</span><br/>
          <span class="rm-title rm-title-6 rm-lighter">This is where you can edit a users settings.</span>
        </div>
        <div class="col-12 col-md-5">
          <button mat-raised-button
                  color="primary"
                  style="margin-bottom: 5px;"
                  (click)="userPasswordReset()"
                  [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_USERS)"
                  [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_USERS) ? util.NoAccessCursor: ''}"
                  [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_USERS) ? util.NoAccessMsg : ''"
                  matTooltipPosition="left"
          >USER PASSWORD RESET
          </button>&nbsp;
          <button mat-raised-button
                  color="warn"
                  (click)="forcePasswordReset()"
                  [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_USERS)"
                  [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_USERS) ? util.NoAccessCursor: ''}"
                  [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_USERS) ? util.NoAccessMsg : ''"
                  matTooltipPosition="left"
          >FORCE PASSWORD RESET
          </button>
        </div>
      </div>
      <br/>
      <!--    WELL START-->
      <div class="row justify-content-center" style="padding-bottom: 50px;">
        <div class="col-11">
          <form [formGroup]="userForm"
                (ngSubmit)="save()">
            <div class="row justify-content-center" style="padding-bottom: 25px;">
              <div class="col-12 ">
                <app-user-card [firstName]="userForm.get('firstName')?.value || undefined"
                               [lastName]="userForm.get('lastName')?.value || undefined"
                               [displayName]="userForm.get('email')?.value || undefined"
                               [borderColor]="'transparent'"
                               [sideColor]="'#002150'"
                               [background]="'#F1EDE4'"
                               [size]="userCardSize.XL"
                               [image]="userForm.get('imageUrl')?.value || undefined"
                ></app-user-card>
              </div>
            </div>
            <div class="row justify-content-center" style="padding-bottom: 25px;">
              <div class="col-12">
                <button type="button"
                        color="primary"
                        mat-raised-button
                        (click)="openImageUploadModal()"
                        [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_USERS)"
                        [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_USERS) ? util.NoAccessCursor: ''}"
                        [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_USERS) ? util.NoAccessMsg : ''"
                        matTooltipPosition="left"
                >UPLOAD IMAGE
                </button>&nbsp;
                <button type="button"
                        color="warn"
                        mat-raised-button
                        (click)="clearImage()"
                        [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_USERS)"
                        [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_USERS) ? util.NoAccessCursor: ''}"
                        [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_USERS) ? util.NoAccessMsg : ''"
                        matTooltipPosition="left"
                >CLEAR IMAGE
                </button>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 ">
                <!--          row start-->
                <div class="row">
                  <div class="col-12 col-md-6">
                    <mat-label style="font-size: medium;">First Name</mat-label>
                    <mat-form-field class="form-field" appearance="outline">
                      <input class="form-input" matInput formControlName="firstName" [required]="true"
                             placeholder="i.e. John"/>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-6">
                    <mat-label style="font-size: medium;">Last Name</mat-label>
                    <mat-form-field class="form-field" appearance="outline">
                      <input class="form-input" matInput formControlName="lastName" [required]="true"
                             placeholder="i.e. Smith"/>
                    </mat-form-field>
                  </div>
                </div>
                <!--          row start-->
                <div class="row" style="padding-bottom: 50px;">
                  <div class="col-12">
                    <mat-label style="font-size: medium;">Email</mat-label>
                    <mat-form-field class="form-field" appearance="outline">
                      <input class="form-input" matInput formControlName="email" [required]="true"
                             placeholder="i.e. example@rondeivu.com"/>
                    </mat-form-field>
                  </div>
                  <div class="col-6">
                    <mat-checkbox color="primary" formControlName="emailVerified">Email Verified</mat-checkbox>
                  </div>
                </div>
                <!--  row-->
                <div class="row">
                  <div class="col-4">
                    <mat-label style="font-size: medium;">Country Code</mat-label>
                    <mat-form-field class="form-field" appearance="outline">
                      <mat-select id="cc" formControlName="countryCode">
                        <mat-option *ngFor="let cc of lookups.countryCodes" [value]="cc.key">
                          {{cc.value}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-8">
                    <mat-label style="font-size: medium;">Phone Number</mat-label>
                    <mat-form-field class="form-field" appearance="outline">
                      <input class="form-input" matInput formControlName="mobile" [required]="true"/>
                    </mat-form-field>
                  </div>
                </div>
                <!--          row start-->
                <div class="row" style="padding-bottom: 50px;">
                  <div class="col-6">
                    <mat-checkbox color="primary" formControlName="phoneNumberVerified">Phone Verified</mat-checkbox>
                  </div>
                  <div class="col-6 text-end">
                    <button mat-raised-button
                            type="button"
                            color="warn"
                            (click)="reset2Fa()"
                            [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_USERS)"
                            [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_USERS) ? util.NoAccessCursor: ''}"
                            [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_USERS) ? util.NoAccessMsg : ''"
                            matTooltipPosition="left"
                    >RESET 2FA
                    </button>
                  </div>
                </div>
                <!--                row-->
                <div class="row">
                  <div class="col-12">
                    <mat-label>Profile Link</mat-label>
                    <mat-form-field class="form-field" appearance="outline">
                      <input matInput id="profile-link" formControlName="profileLink"/>
                    </mat-form-field>
                  </div>
                </div>
                <!--          row start-->
                <div class="row">
                  <div class="col-12">
                    <mat-label style="font-size: medium;">Referral’s Email Address</mat-label>
                    <mat-form-field class="form-field" appearance="outline">
                      <input class="form-input" matInput formControlName="referredByEmail" [required]="true"
                             placeholder="info@rondeivu.com"/>
                    </mat-form-field>
                  </div>
                  <div class="col-12" style="padding-bottom: 15px;">
                    <mat-label style="font-size: medium;">User State</mat-label>
                    <br/>
                    <mat-checkbox color="primary" formControlName="active">Active</mat-checkbox>
                    <br/>
                    <span style="font-weight: lighter;">If box is unchecked the user will be unable to access Rondeivu services.</span>
                  </div>
                </div>

                <!--          row start-->
                <div class="row">
                  <div class="col-6" hidden>
                    <mat-label style="font-size: medium;">MFA Method</mat-label>
                    <mat-form-field class="form-field" appearance="outline">
                      <mat-select formControlName="mfaType">
                        <mat-option>NONE</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" style="padding-top:15px;">
              <div class="col-12 text-end">
                <button type="submit"
                        mat-raised-button
                        color="primary"
                        [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_USERS)"
                        [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_USERS) ? util.NoAccessCursor: ''}"
                        [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_USERS) ? util.NoAccessMsg : ''"
                        matTooltipPosition="left"
                >SAVE CHANGES
                </button>
              </div>
            </div>
            <!--          rows end-->
          </form>
        </div>
      </div>
      <br/><br/>
    </div>
  </div>
</div>

