import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  CompanyEmployeeInfoComponent,
  CompanyEmployeeModalComponent, CompanyEmployeePermissionsComponent,
  CompanyListComponent,
  CompanyNavigationComponent
} from "./components";
import {SharedModule} from "../shared/shared.module";
import {ReactiveFormsModule} from "@angular/forms";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {CompanyRoutingModule} from "./company-routing.module";
import {NgxChartsModule} from "@swimlane/ngx-charts";
import {GoogleMapsModule} from "@angular/google-maps";
import {HttpClientModule} from "@angular/common/http";
import {ClipboardModule} from "@angular/cdk/clipboard";
import {NgMaterialModule} from "../ng-material/ng-material.module";


@NgModule({
  declarations: [
    CompanyNavigationComponent,
    CompanyListComponent,
    CompanyEmployeeInfoComponent,
    CompanyEmployeePermissionsComponent,
    CompanyEmployeeModalComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    ReactiveFormsModule,
    DragDropModule,
    CompanyRoutingModule,
    NgxChartsModule,
    GoogleMapsModule,
    HttpClientModule,
    ClipboardModule,
    NgMaterialModule
  ]
})
export class CompanyModule {
}
