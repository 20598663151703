import {Injectable} from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {CognitoUser, Config, getCognitoUser, getConfig, initialCognitoUserState, initialConfigState} from "../../../redux";
import {RondeivuHttpClient} from "../../../../handlers";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class KycService {

  readonly BASE_URL = environment.rondeivu_api;
  readonly FRAGMENT = '/kyc';
  readonly DETAILS = '/details';
  readonly SUBMIT = '/submit';
  readonly STATUS = '/status';
  readonly CHANGE_BU_KYS_STATUS = '/change-businessunit-kyc-status';

  private config: Config = initialConfigState;
  private cognito: CognitoUser = initialCognitoUserState;

  constructor(private http: RondeivuHttpClient, private store: Store) {
    this.store.select(getConfig).subscribe(c => {
      this.config = c;
    });
    this.store.select(getCognitoUser).subscribe(cu => {
      this.cognito = cu;
    });
  }

  /**
   * Get a list of kyc
   */
  getKycs(): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT);
  }

  /**
   * Get a kyc
   */
  getKyc(): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + "/" + this.config.selected_kyc_id);
  }

  /**
   * Get a kyc by id
   */
  getKycById(id:string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + "/" + id);
  }

  /**
   * Add a new kyc
   * @param kyc
   */
  addKyc(kyc: any): Observable<any> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.BASE_URL + this.FRAGMENT, kyc, {headers: httpHeaders});
  }

  /**
   * Submit the KYC process for review
   * @param kyc the kyc process
   */
  submitKyc(kyc: any): Observable<any> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.SUBMIT + '/' + kyc.id, kyc,{headers: httpHeaders});
  }

  /**
   * Update the KYC process status ADMIN ONLY
   * @param status
   */
  updateKycStatus(status: {status:string}): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + this.STATUS + '/' + this.config.selected_kyc_id, status);
  }

  /**
   * update a kyc process
   * @param kyc
   */
  updateKyc(kyc: any): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + '/' + kyc.id, kyc);
  }

  /**
   * Deletes a Kyc
   * @param kyc
   */
  deleteKyc(kyc: any): Observable<any> {
    return this.http.delete(this.BASE_URL + this.FRAGMENT + "/" + kyc.id);
  }

  /**
   * Get a kyc details
   */
  getKycDetails(type: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.DETAILS + "/" + type);
  }

  /**
   * Update business unit kyc status
   * @param kyc
   */
  updateBusinessUnitKycStatus(kyc: any): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + this.CHANGE_BU_KYS_STATUS, kyc);
  }
}
