import {Component, OnDestroy, OnInit} from '@angular/core';
import {INavItem} from "../../../../models";
import {ThemePalette} from "@angular/material/core";
import {Subscription} from "rxjs";
import {IBusinessUnit} from "../../../business-units/models";
import {
  Config,
  getConfig,
  getSelectedBusinessUnit,
  getThemeState,
  initialConfigState,
  initialThemeState, showNotesTab,
  Theme, toggleLoading,
} from "../../../redux";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {UtilService} from "../../../shared/services";
import {AppConfigService} from "../../../../services";

import {getSelectedEmployee} from "../../../redux/stores/config/config.selectors";
import {toggleNavigating, updateEmployee, updateEmployeeId} from "../../../redux/stores/config/config.actions";
import {CompanyService} from "../../services/company.service";
import {AppFade} from "../../../../animations/animations";


@Component({
  selector: 'app-company-navigation',
  templateUrl: './company-navigation.component.html',
  styleUrls: ['./company-navigation.component.scss'],
  animations: [AppFade()],
})
export class CompanyNavigationComponent implements OnInit, OnDestroy {

  loading = false;
  animationState = false;

  links: INavItem[] = [];
  activeLink: INavItem = {} as unknown as INavItem;
  background: ThemePalette = undefined;

  employeeId: string | null = '';
  selectedEmployee$: Subscription = new Subscription();
  selectedEmployee: any = {} as unknown as any;

  config$: Subscription = new Subscription();
  theme$: Subscription = new Subscription();
  route$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;
  config: Config = initialConfigState;
  theme: Theme = initialThemeState;

  constructor(
    private companyService: CompanyService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public util: UtilService,
    private appConfig: AppConfigService
  ) {
    this.route$ = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.setActiveLink();
        this.store.dispatch(toggleNavigating({navigating: true}));
        this.loading = true;
        setTimeout(() => {
          this.store.dispatch(toggleNavigating({navigating: false}));
          this.loading = false;
        }, 1000);
      }
    });

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.theme$ = this.store.select(getThemeState).subscribe((theme: any) => {
      this.theme = theme;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      if (!!bu && !!bu.businessUnitId && this.selectedBusinessUnit.businessUnitId != bu.businessUnitId) {
        this.selectedBusinessUnit = bu;
        this.subscribeToDatasource();
      }
    });

    this.selectedEmployee$ = this.store.select(getSelectedEmployee).subscribe((employee: any) => {
      this.selectedEmployee = employee;
      this.getLinks(this.selectedEmployee);
    });
  }

  ngOnInit(): void {
    this.animationState = true;
  }

  ngOnDestroy() {
    this.store.dispatch(updateEmployee({employee: {} as any}));
    this.store.dispatch(showNotesTab({show: false}));
    this.config$.unsubscribe();
    this.theme$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.route$.unsubscribe();
  }

  ngAfterViewInit() {

  }

  subscribeToDatasource() {
    this.getEmployeeIdFromRoute();
    this.getEmployee();
  }

  getEmployeeIdFromRoute() {
    this.employeeId = this.route.snapshot.paramMap.get('id');
    if (!!this.employeeId) {
      this.store.dispatch(updateEmployeeId({employeeId: this.employeeId}));
    }
  }

  /**
   * Employee fetch that shows local spinner and global spinner
   */
  getEmployee() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    this.fetchEmployeeAndDispatch();
  }

  /**
   * Get the employee and update the store
   */
  private fetchEmployeeAndDispatch() {
    if (!!this.employeeId) {
      this.store.dispatch(toggleLoading({loading: true}));
      this.companyService.getEmployee(this.employeeId).subscribe({
        next: (employee: any) => {
          //this will determine the notes load
          this.toastr.success("Employee Loaded!", $localize`:@@companyName:Rondeivu`);
          this.store.dispatch(updateEmployee({employee: employee}));
          this.store.dispatch(toggleLoading({loading: false}));
        }, error: (error: any) => {
          this.store.dispatch(toggleLoading({loading: false}));
          this.toastr.error("Unable to get employee!", $localize`:@@companyName:Rondeivu`);
        }
      });
    }
  }

  /**
   * Generates the possible links based on the employee information
   */
  getLinks(employee: any): INavItem[] {
    if (!!employee && !!employee.id) {
      this.appConfig.getEmployeeTabsByDealId(employee.id).subscribe({
        next: (navItems: INavItem[]) => {
          // console.log(navItems);
          this.links = navItems;
          this.setActiveLink();
          return navItems;
        }, error: () => {
          return [];
        }
      });
    }
    return [];
  }

  /**
   * Sets the current deal tab link from the url fragment
   */
  setActiveLink() {
    const fragments = this.router.url.split('/');
    const link = '/' + fragments[fragments.length - 1];
    this.links.forEach(l => {
      if (l.link == link) {
        this.activeLink = l;
      }
    });
  }

  navigate(obj: any) {
    if (obj.enabled) {
      this.store.dispatch(toggleNavigating({navigating: true}));
      this.animationState = false;
      setTimeout(() => {
        this.router.navigate(['/' + this.config.selected_business_unit.slug + '/my-company/' + this.employeeId + obj.link]).then(() => {
          this.animationState = true;
          this.store.dispatch(toggleNavigating({navigating: false}));
        });
      }, 300);
    }
  }

  goBack() {
    this.store.dispatch(toggleNavigating({navigating: true}));
    this.animationState = false;
    setTimeout(() => {
      let path = '/my-company';
      path = '/' + this.config.selected_business_unit.slug + path;
      this.router.navigate([path]).then(() => {
        this.animationState = true;
        this.store.dispatch(toggleNavigating({navigating: false}));
      });
    }, 300);
  }

}
