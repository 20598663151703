import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Subscription} from "rxjs";
import {Config, getConfig, getLookups, initialConfigState, initialLookupsState, Lookups} from "../../../redux";
import {Store} from "@ngrx/store";

@Component({
  selector: 'app-indicate-interest-modal',
  templateUrl: './indicate-interest-modal.component.html',
  styleUrls: ['./indicate-interest-modal.component.scss']
})
export class IndicateInterestModalComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;

  editingAmount = true;

  constructor(private store: Store, public dialogRef: MatDialogRef<IndicateInterestModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { amount: number, restrictionType: string, notes: string }) {

    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });
    this.lookup$ = this.store.select(getLookups).subscribe(lookups => {
      this.lookups = lookups;
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
  }

  getSelectedDealName() {
    return this.config.selected_deal.projectName || 'Unknown';
  }

}

