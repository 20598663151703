import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoadingService, UuidService} from "../../../../services";
import {
  ILookup
} from "../../../../models";
import {FormControl, FormGroup} from "@angular/forms";
import {Observable, startWith, Subscription} from "rxjs";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {getLookups, Lookups} from "../../../redux";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {MandateService} from "../../services";
import {iMandateRequest} from "../../models";
import {map} from "rxjs/operators";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

enum MandateWizard {
  'Investor Overview' = 0,
  'Mandate Overview' = 1,
  'Areas of Focus' = 2,
  'Objectives & Constraints' = 3,
  'Size & Timing' = 4,
}

@Component({
  selector: 'app-mandate-modal',
  templateUrl: './mandate-modal.component.html',
  styleUrls: ['./mandate-modal.component.scss']
})
export class MandateModalComponent implements OnInit, OnDestroy {

  lookup$: Subscription = new Subscription();

  curPage: MandateWizard = MandateWizard["Investor Overview"];
  mandate: iMandateRequest = {
    "id": "",
    "investorMandateDeals": [],
    "investorMandateAssets": [],
    "investorMandateAreasOfFocus": [],
    "investorMandateDescriptions": [],
    "investorMandateTimeLines": [],
    "investorMandateNotes": [],
    "investorMandateAnchorInterests": []
  } as unknown as iMandateRequest;
  lookups: Lookups = {} as Lookups;
  sameFirmAum = false;
  loading = false;
  showErr = false;

  // reactive form
  userForm = new FormGroup({
    id: new FormControl(),
    title: new FormControl('', []),
    firmAum: new FormControl(),
    programAum: new FormControl(),
    targetNetMoic: new FormControl(),
    targetNetIrr: new FormControl(),
    minimumDuration: new FormControl(),
    maximumDuration: new FormControl(),
    minTicketSize: new FormControl(),
    maxTicketSize: new FormControl(),
    avgTicketSize: new FormControl(),
    investorMandateDeals: new FormControl([], []),
    investorMandateAssets: new FormControl([], []),
    investorMandateAreasOfFocus: new FormControl([], []),
    investorMandateDescriptions: new FormControl(),
    investorMandateTimeLines: new FormControl(),
    notes: new FormControl(),
    investorMandateAnchorInterests: new FormControl([], []),
  });

  // deal type and asset class selection
  selectedAssetIdx: boolean[] = [];
  selectedDealIdx: boolean[] = [];
  selectedAnchorInterestIdx: boolean[] = [];

  //theme input
  @ViewChild('themeInput') themeInput: any;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  themeCtrl = new FormControl('');
  filteredThemes: Observable<string[]>;
  selectedThemes: string[] = [];

  constructor(public dialogRef: MatDialogRef<MandateModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private loader: LoadingService,
              private store: Store,
              private mandateService: MandateService,
              private uuid: UuidService,
              private toastr: ToastrService) {

    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });

    //add the possible themes
    this.filteredThemes = this.themeCtrl.valueChanges.pipe(
      startWith(null),
      map((theme: string | null) => (theme ? this._filter(theme) : this.lookups.areasOfFocus.map(l => {
        return l.value
      }).slice())),
    );
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.lookup$.unsubscribe();
  }

  /**
   * Form action for when sameFirmAUM is selected
   */
  toggleProgramAUM() {
    if (!this.sameFirmAum) {
      this.userForm.get('programAum')?.disable();
      if (!!this.userForm.get('firmAum')?.value) {
        this.userForm.get('programAum')?.setValue(this.userForm.get('firmAum')?.value);
      }
    } else {
      this.userForm.get('programAum')?.enable();
    }
  }

  getListFromEnum(e: any) {
    return Object.keys(e).slice(Object.keys(e).length / 2, Object.keys(e).length);
  }

  isCountry(id: string, country: string) {
    let found = false;
    for (let i = 0; i <= this.lookups.countries.length - 1; i++) {
      if (this.lookups.countries[i].key == id) {
        if (this.lookups.countries[i].value == country) {
          found = true;
          break;
        }
      }
    }
    return found;
  }

  getTitle(idx: number) {
    return MandateWizard[idx];
  }

  isSelectedPage(idx: number) {
    return idx === this.curPage;
  }

  getTitles() {
    return Object.keys(MandateWizard).slice(0, Object.keys(MandateWizard).length / 2);
  }

  back() {
    if (!this.firstPage()) {
      this.goTo(this.curPage - 1);
    }
  }

  next() {
    if (this.isStepValid()) {
      this.showErr = false;
      if (!this.lastPage()) {
        this.goTo(this.curPage + 1);
      }
    } else {
      this.showErr = true;
    }
  }

  isStepValid(): boolean {
    switch (this.curPage) {
      case 0:
        this.userForm.get('firmAum')?.markAsTouched();
        this.userForm.get('programAum')?.markAsTouched();
        this.userForm.get('investorMandateDescriptions')?.markAsTouched();
        return !!this.userForm.get('firmAum')?.value &&
          !!this.userForm.get('programAum')?.value &&
          !!this.userForm.get('investorMandateDescriptions')?.value;
      case 1:
        if (this.mandate.investorMandateDeals.length == 0 || this.mandate.investorMandateAssets.length == 0) {
          this.showErr = true;
        }
        return this.mandate.investorMandateDeals.length > 0 && this.mandate.investorMandateAssets.length > 0;
      case 2:
        return true;
      case 3:
        this.userForm.get('targetNetMoic')?.markAsTouched();
        this.userForm.get('targetNetIrr')?.markAsTouched();
        this.userForm.get('minimumDuration')?.markAsTouched();
        this.userForm.get('maximumDuration')?.markAsTouched();
        return !!this.userForm.get('targetNetMoic')?.value && !!this.userForm.get('targetNetIrr')?.value &&
          !!this.userForm.get('minimumDuration')?.value && !!this.userForm.get('maximumDuration')?.value;
      case 4:
        this.userForm.get('minTicketSize')?.markAsTouched();
        this.userForm.get('maxTicketSize')?.markAsTouched();
        this.userForm.get('avgTicketSize')?.markAsTouched();
        this.userForm.get('investorMandateTimeLines')?.markAsTouched();
        return !!this.userForm.get('minTicketSize')?.value && !!this.userForm.get('maxTicketSize')?.value &&
          !!this.userForm.get('avgTicketSize')?.value && !!this.userForm.get('investorMandateTimeLines')?.value;
      default:
        return false;
    }
  }

  createMandate() {
    let payload = this.userForm.getRawValue() as unknown as iMandateRequest;
    payload.investorMandateDescriptions = [this.userForm.get('investorMandateDescriptions')?.value];
    payload.investorMandateTimeLines = [this.userForm.get('investorMandateTimeLines')?.value];
    payload.investorMandateAssets = this.mandate.investorMandateAssets;
    payload.investorMandateDeals = this.mandate.investorMandateDeals;
    payload.investorMandateAnchorInterests = this.mandate.investorMandateAnchorInterests;
    //areas of focus has to be mapped to obj
    payload.investorMandateAreasOfFocus = Object.assign(this.selectedThemes.map(t => {
      return {"key": t};
    }));
    this.dialogRef.close(payload);
  }

  firstPage() {
    return this.curPage === 0;
  }

  lastPage() {
    return this.curPage === Object.keys(MandateWizard).length / 2 - 1;
  }

  private goTo(page: number) {
    this.curPage = page;
  }

  /**
   * Form action for selecting deal type
   * @param deal
   */
  toggleDealType(deal: ILookup) {
    this.toggleLookup(this.mandate.investorMandateDeals, deal);
  }

  /**
   * Form action for selecting asset class
   * @param asset
   */
  toggleAssetClass(asset: ILookup) {
    this.toggleLookup(this.mandate.investorMandateAssets, asset);
  }

  /**
   * Form action for selecting anchor interest
   * @param interest
   */
  toggleAnchorInterest(interest: ILookup) {
    this.toggleLookup(this.mandate.investorMandateAnchorInterests, interest);
  }

  /**
   * This is a core function for all component pages that use the multicheckbox for the lookup selection
   * @param list the list to modify
   * @param lookup the lookup to toggle
   * @private
   */
  private toggleLookup(list: { key: string }[], lookup: ILookup) {
    if (!!list) {
      //search for the key of the lookup
      let found = false;
      let foundIdx = -1;
      for (let i = 0; i <= list.length - 1; i++) {
        if (list[i].key === lookup.key) {
          found = true;
          foundIdx = i;
          break;
        }
      }
      //only add if not found
      if (!found) {
        list.push(lookup);
      } else {
        if (foundIdx >= 0) {
          list.splice(foundIdx, 1);
        }
      }
      // console.log(list);
    }
  }

  /**
   * Add theme/keyword to the list
   */
  addTheme(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add our theme
    // console.log(value);
    if (value && !this.selectedThemes.includes(value)) {
      this.selectedThemes.push(value);
    }
    // Clear the input value
    event.chipInput!.clear();
    this.themeCtrl.setValue(null);
  }

  /**
   * Remove theme/keyword form the list
   * @param theme
   */
  removeTheme(theme: string): void {
    const index = this.selectedThemes.indexOf(theme);

    if (index >= 0) {
      this.selectedThemes.splice(index, 1);
    }
  }

  /**
   * Form action for selecting or typing a theme/keyword
   * @param event the selected event
   */
  selectedTheme(event: MatAutocompleteSelectedEvent): void {
    if (!this.selectedThemes.includes(event.option.viewValue)) {
      this.selectedThemes.push(event.option.viewValue);
    }
    if (!!this.themeInput) {
      this.themeInput.nativeElement.value = '';
    }
    this.themeCtrl.setValue(null);
  }

  /*
  Filters the autocomplete list
   */
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    // console.log(this.lookups.areasOfFocus.map(l=>{return l.value}));
    return this.lookups.areasOfFocus.map(l => {
      return l.value
    }).filter(theme => theme.toLowerCase().includes(filterValue));
  }

}
