// the store model
export class UserSettings {
  emailVerified: boolean = false;
  phoneNumberVerified: boolean = false;
  mfaType: string = '';
  id: string = '';
  subId: string = '';
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  countryCode: string = '';
  mobile: string = '';
  created: string = '';
  profileLink: string = '';
  imageUrl: string = '';

  constructor(data: any) {
    Object.assign(this, data);
  }
}
