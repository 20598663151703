import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-floater',
  templateUrl: './floater.component.html',
  styleUrls: ['./floater.component.scss']
})
export class FloaterComponent implements OnInit {
  @Input() icon: string = '';
  @Input() topPos: string = '50vh';
  @Input() rightPos: string = '55px';
  @Input() toolTip: string = '';
  @Input() bgColor: string = environment.rondeivu_colors.default_theme.pill_1_bg;
  @Input() color: string = environment.rondeivu_colors.default_theme.pill_1_fg;
  @Input() hidden: boolean = false;
  @Output() onClick: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit(): void {
  }

  floaterClick() {
    this.onClick.emit(this.icon);
  }


}
