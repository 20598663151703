import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {
  Config,
  getConfig,
  getLookups,
  getSelectedBusinessUnit, getSelectedKyc,
  getSelectedKycId,
  initialConfigState,
  initialLookupsState,
  Lookups,
  toggleLoading,
} from "../../../redux";
import {IBusinessUnit} from "../../../business-units/models";
import {FormControl, FormGroup} from "@angular/forms";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {KycService, UtilService} from "../../../shared/services";
import {toggleNavigating} from "../../../redux/stores/config/config.actions";

@Component({
  selector: 'app-kyc-information',
  templateUrl: './kyc-information.component.html',
  styleUrls: ['./kyc-information.component.scss']
})
export class KycInformationComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  selectedKycId$: Subscription = new Subscription();
  selectedKyc$: Subscription = new Subscription();

  private config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;
  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;

  //reactive form
  userForm = new FormGroup({
    id: new FormControl(),
    entityName: new FormControl(),
    status: new FormControl(),
    kycProfileSubType: new FormControl(),
  });

  loading: boolean = false;
  docs: { name: string, url: string }[] = [];

  selectedKyc: any = {};

  constructor(private store: Store,
              private router: Router,
              private toastr: ToastrService,
              private kycService: KycService,
              public util: UtilService
  ) {
    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });
    this.lookup$ = this.store.select(getLookups).subscribe(lookups => {
      this.lookups = lookups;
    });
    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe(businessUnit => {
      this.selectedBusinessUnit = businessUnit;
    });
    this.selectedKycId$ = this.store.select(getSelectedKycId).subscribe(kycId => {
      if (!!this.selectedBusinessUnit && !!this.selectedBusinessUnit.businessUnitId) {
        this.subscribeToDatasource();
      }
    });
    this.selectedKyc$ = this.store.select(getSelectedKyc).subscribe(kyc => {
      if (!!this.selectedBusinessUnit && !!this.selectedBusinessUnit.businessUnitId) {
        this.subscribeToDatasource();
      }
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedKycId$.unsubscribe();
    this.selectedKyc$.unsubscribe();
  }

  subscribeToDatasource(): void {
    this.store.dispatch(toggleLoading({loading: true}));
    this.kycService.getKyc().subscribe({
      next: (kyc: any) => {
        this.selectedKyc = Object.assign({}, kyc);
        this.userForm.patchValue(kyc);
        //get the kyc documents
        if (!!this.selectedKyc?.kycProfileSubType) {
          this.getKycDocuments(this.selectedKyc.kycProfileSubType);
        }
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: error => {
        this.toastr.error("Something went wrong!");
        this.store.dispatch(toggleLoading({loading: false}));
      }
    });
  }

  save() {
    let payload = this.userForm.getRawValue();
    this.store.dispatch(toggleLoading({loading: true}));
    this.kycService.updateKyc(payload).subscribe({
      next: (kyc: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("KYC Information Saved!", $localize`:@@companyName:Rondeivu`);
      }, error: error => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Something went wrong!");
      }
    });
  }

  review() {
    let payload = this.userForm.getRawValue();
    this.store.dispatch(toggleLoading({loading: true}));
    this.kycService.submitKyc(payload).subscribe({
      next: (kyc: any) => {
        this.userForm.patchValue({status: 'IN_REVIEW'});
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("KYC Submitted!", $localize`:@@companyName:Rondeivu`);
      }, error: error => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Something went wrong!");
      }
    });
  }

  shouldShowTypesFor(type: string) {
    return this.selectedKyc.businessUnit?.profile?.toLowerCase() === type;
  }

  /**
   * Fetched the kyc documents when the kyc type changes
   * @param event the form control event
   */
  getKycDocuments(type: string) {
    this.loading = true;
    this.kycService.getKycDetails(type).subscribe({
      next: (res: { name: string, url: string }[]) => {
        this.docs = Object.assign([], res);
        this.loading = false;
      }, error: (err: any) => {
        this.loading = false;
        this.toastr.error("Something went wrong!");
      }
    });
  }

  updateStatus(key: string) {
    let payload = {
      status: key
    };
    this.store.dispatch(toggleLoading({loading: true}));
    this.kycService.updateKycStatus(payload).subscribe({
      next: (kyc: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("KYC Status Updated!", $localize`:@@companyName:Rondeivu`);
      }, error: error => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Something went wrong!");
      }
    });
  }

  viewHistory() {
    this.store.dispatch(toggleNavigating({navigating: true}));
    setTimeout(() => {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/history/kyc'], {queryParams: {id: this.selectedKyc.id}}).then(() => {
        this.store.dispatch(toggleNavigating({navigating: false}));
      });
    }, 1000);
  }

}
