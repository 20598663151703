import {createAction, props} from '@ngrx/store';
import {Update, EntityMap, EntityMapOne, Predicate} from '@ngrx/entity';
import {IDealCard} from "../../../shared/models";

export const loadDeals = createAction('[iDealCard/API] Load iDealCards', props<{ deals: IDealCard[] }>());
export const setDeals = createAction('[iDealCard/API] Set iDealCards', props<{ deals: IDealCard[] }>());
export const addDeal = createAction('[iDealCard/API] Add iDealCard', props<{ deal: IDealCard }>());
export const setDeal = createAction('[iDealCard/API] Set iDealCard', props<{ deal: IDealCard }>());
export const upsertDeal = createAction('[iDealCard/API] Upsert iDealCard', props<{ deal: IDealCard }>());
export const addDeals = createAction('[iDealCard/API] Add iDealCards', props<{ deals: IDealCard[] }>());
export const upsertDeals = createAction('[iDealCard/API] Upsert iDealCards', props<{ deals: IDealCard[] }>());
export const updateDeal = createAction('[iDealCard/API] Update iDealCard', props<{ update: Update<IDealCard> }>());
export const updateDeals = createAction('[iDealCard/API] Update iDealCards', props<{ updates: Update<IDealCard>[] }>());
export const mapDeal = createAction('[iDealCard/API] Map iDealCard', props<{ entityMap: EntityMapOne<IDealCard> }>());
export const mapDeals = createAction('[iDealCard/API] Map iDealCards', props<{ entityMap: EntityMap<IDealCard> }>());
export const deleteDeal = createAction('[iDealCard/API] Delete iDealCard', props<{ id: string }>());
export const deleteDeals = createAction('[iDealCard/API] Delete iDealCards', props<{ ids: string[] }>());
export const deleteDealsByPredicate = createAction('[iDealCard/API] Delete iDealCards By Predicate', props<{
  predicate: Predicate<IDealCard>
}>());
export const clearDeals = createAction('[iDealCard/API] Clear iDealCards');
