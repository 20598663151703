import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Subscription} from "rxjs";
import {Config, getConfig, getLookups, initialConfigState, initialLookupsState, Lookups} from "../../../redux";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";


@Component({
  selector: 'app-company-employee-modal',
  templateUrl: './company-employee-modal.component.html',
  styleUrls: ['./company-employee-modal.component.scss']
})
export class CompanyEmployeeModalComponent implements OnInit {


  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;

  userForm = new FormGroup({
    id: new FormControl(),
    firstName: new FormControl(),
    lastName: new FormControl(),
    email: new FormControl('', [Validators.required, Validators.email]),
    title: new FormControl(),
    role: new FormControl(),
  });

  constructor(public dialogRef: MatDialogRef<CompanyEmployeeModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { isEditing: boolean, employee: any },
              private store: Store,
              private toastr: ToastrService) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });
  }

  ngOnInit(): void {
    if (!!this.data && this.data.isEditing) {
      console.log("Editing Business Unit Employee");
      this.userForm.patchValue({
        id: this.data.employee.id,
        email: this.data.employee.user.email,
        title: this.data.employee.title,
        role: this.data.employee.role
      });

      // set form for editing
      this.userForm.get("email")?.disable();
      this.userForm.get("role")?.setValidators(Validators.required);
      this.userForm.get("role")?.updateValueAndValidity();
      this.userForm.get("title")?.setValidators(Validators.required);
      this.userForm.get("title")?.updateValueAndValidity();
    }
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
  }

  submit() {
    this.dialogRef.close(this.userForm.getRawValue());
  }

}
