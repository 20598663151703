import {RouterModule, Routes} from "@angular/router";
import {PageNotFoundComponent} from "../../components";
import {NgModule} from "@angular/core";
import {LegalListComponent, LegalNavigationComponent} from "./components";

const routes: Routes = [
  { path: '', component: LegalListComponent },
  {
    path: ':id', component: LegalNavigationComponent, children: [
      {path: 'information', component: PageNotFoundComponent},
      {path: '', redirectTo: 'information', pathMatch: 'full'},
      {path: '**', redirectTo: 'information'},
    ]
  },
  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LegalRoutingModule {}
