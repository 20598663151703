import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {Subscription} from "rxjs";
import {IBusinessUnit} from "../../../business-units/models";
import {MatTableDataSource} from "@angular/material/table";
import {
  Config,
  getConfig,
  getSelectedBusinessUnit,
  getSelectedUserId,
  initialConfigState,
  initialThemeState,
  Theme, toggleLoading
} from "../../../redux";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {UsersService} from "../../services/users/users.service";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {MatDialog} from "@angular/material/dialog";
import {IAuthEvent} from "../../models/i-activity";

@Component({
  selector: 'app-user-activity',
  templateUrl: './user-activity.component.html',
  styleUrls: ['./user-activity.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class UserActivityComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  selectedUserId$: Subscription = new Subscription();
  selectedUserId = '';

  dataSource: MatTableDataSource<IAuthEvent> = new MatTableDataSource();
  authEvents: IAuthEvent[] = [];
  sortedAuthEvents: IAuthEvent[] = [];

  config: Config = initialConfigState;
  theme: Theme = initialThemeState;
  columnsToDisplay = ['type', 'risk', 'level', 'ip', 'device', 'country', 'city', 'creation'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: IBusinessUnit | null = {} as IBusinessUnit;
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;

  constructor(private usersService: UsersService,
              private toastr: ToastrService,
              private router: Router,
              private store: Store,
              private dialog: MatDialog) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.selectedBusinessUnit = Object.assign({}, bu);
    });

    this.selectedUserId$ = this.store.select(getSelectedUserId).subscribe((id: string) => {
      this.selectedUserId = id;
      if (!!this.selectedBusinessUnit && !!this.selectedBusinessUnit.businessUnitId && !!this.selectedUserId) {
        this.subscribeToDatasource();
      }
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedUserId$.unsubscribe();
  }

  subscribeToDatasource(): void {
    console.log("Fetching User Activity...");
    this.getUserActivity();
  }

  getUserActivity() {
    this.usersService.getUserActivity(this.selectedUserId).subscribe({
      next: (businessUnits: { authEvents: IAuthEvent[] }) => {
        this.authEvents = businessUnits.authEvents;
        this.dataSource = new MatTableDataSource(this.authEvents);
        this.initDataSource();
      }, error: (err: any) => {
        this.toastr.error("Something went wrong!");
      }
    });
  }

  initDataSource() {
    this.dataSource.filterPredicate = (data: IAuthEvent, filter: string) => {
      const CITY = data.eventContextData.city?.toLowerCase()?.includes(filter.toLowerCase());
      const COUNTRY = data.eventContextData.country?.toLowerCase()?.includes(filter.toLowerCase());
      const DEVICE = data.eventContextData.deviceName?.toLowerCase()?.includes(filter.toLowerCase());
      const TYPE = data.eventType?.value?.toLowerCase()?.includes(filter.toLowerCase());
      const RISK = data.eventRisk?.riskDecision?.value?.toLowerCase()?.includes(filter.toLowerCase());
      const LEVEL = data.eventRisk?.riskLevel?.value?.toLowerCase()?.includes(filter.toLowerCase());
      return CITY || COUNTRY || DEVICE || TYPE || RISK || LEVEL;
    };
    if (!!this.paginator) {
      this.paginator.pageSize = 10;
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //sort
  sortData(sort: Sort) {
    const data = this.authEvents.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedAuthEvents = data;
      return;
    }

    this.sortedAuthEvents = data.sort((a: IAuthEvent, b: IAuthEvent) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'type':
          return compare(a.eventType?.value, b.eventType?.value, isAsc);
        case 'risk':
          return compare(a.eventRisk.riskDecision?.value, b.eventRisk.riskDecision?.value, isAsc);
        case 'level':
          return compare(a.eventRisk.riskLevel?.value || '', b.eventRisk.riskLevel?.value || '', isAsc);
        case 'date':
          return compare(a.creationDate, b.creationDate, isAsc);
        case 'city':
          return compare(a.eventContextData.city, b.eventContextData.city, isAsc);
        case 'country':
          return compare(a.eventContextData.country, b.eventContextData.country, isAsc);
        case 'device':
          return compare(a.eventContextData.deviceName, b.eventContextData.deviceName, isAsc);
        case 'ip':
          return compare(a.eventContextData.ipAddress, b.eventContextData.ipAddress, isAsc);
        default:
          return 0;
      }
    });

    this.dataSource = new MatTableDataSource(this.sortedAuthEvents);
    this.initDataSource();
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
