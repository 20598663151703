import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Config, getConfig, getLookups, initialConfigState, initialLookupsState, Lookups} from "../../../../redux";
import {Store} from "@ngrx/store";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {IDealHighlightsRequest} from "../../../models";

@Component({
  selector: 'app-deal-highlights-modal',
  templateUrl: './deal-highlights-modal.component.html',
  styleUrls: ['./deal-highlights-modal.component.scss']
})
export class DealHighlightsModalComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;

  userForm = new FormGroup({
    isIssuerNdaSigned: new FormControl(false),
    isDealNdaSigned: new FormControl(false),
    investorWorkflowStatus: new FormControl()
  });

  constructor(private router: Router,
              private store: Store,
              public dialogRef: MatDialogRef<DealHighlightsModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {

    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });
    this.lookup$ = this.store.select(getLookups).subscribe(lookups => {
      this.lookups = lookups;
    });
  }


  ngOnInit(): void {
    if (!!this.data) {
      // console.log(JSON.stringify(this.data));
      this.userForm.patchValue(this.data);
    }
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
  }

  submit() {
    let payload = this.userForm.getRawValue() as IDealHighlightsRequest;
    payload.id = this.data.dealMatchId;
    this.dialogRef.close(payload);
  }

  view(user: any) {
    if (!!user.id) {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/users/' + user.id]).then(() => {
        this.dialogRef.close();
      });
    }
  }

}
