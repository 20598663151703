import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {
  EntityNavigationComponent,
  EntityInformationComponent,
  EntityListComponent,
  KycInputComponent, EntityKycNavigationComponent, EntityKycListComponent, EntityInnerNavigationComponent
} from './components';
import {EntityKycResolver} from "../../resolvers/entity-kyc.resolver";
import {EntityResolver} from "../../resolvers/entity.resolver";


const routes: Routes = [
  {path: '', component: EntityListComponent},
  {
    path: ':id', component: EntityNavigationComponent,
    runGuardsAndResolvers: "always",
    resolve: {
      EntityResolver
    },
    children: [
      {path: 'information', component: EntityInformationComponent},
      {
        path: 'kyc', component: EntityInnerNavigationComponent,
        children: [
          {path: '', component: EntityKycListComponent},
          {
            path: ':id', component: EntityKycNavigationComponent,
            runGuardsAndResolvers: "always",
            resolve: {
              entityKyc: EntityKycResolver
            },
            children: [
              {path: '', component: KycInputComponent}
            ]
          }
        ]
      },
      {path: '', redirectTo: 'information', pathMatch: 'full'},
      {path: '**', redirectTo: 'information'},
    ]
  },
  {path: '', redirectTo: '', pathMatch: 'full'},
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EntitiesRoutingModule {
}
