import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ThemePalette} from "@angular/material/core";
import {
  Config,
  getConfig,
  initialConfigState,
  initialThemeState,
  Theme
} from "../../../redux";

import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import {INavItem} from "../../../../models";
import {toggleNavigating} from "../../../redux/stores/config/config.actions";
import {AppFade} from "../../../../animations/animations";

@Component({
  selector: 'app-admin-navigation',
  templateUrl: './admin-navigation.component.html',
  styleUrls: ['./admin-navigation.component.scss'],
  animations: [AppFade()],
})
export class AdminNavigationComponent implements OnInit, OnDestroy, AfterViewInit {

  config$: Subscription = new Subscription();
  animationState = false;
  links: INavItem[] = [
    {title: 'Config', link: '/config', enabled: true, hidden: false, icon: '', toolTip: ''},
    {title: 'Rondeivu Employees', link: '/rondeivu-employees', enabled: true, hidden: false, icon: '', toolTip: ''},
    {title: 'SMS Log', link: '/sms-log', enabled: true, hidden: false, icon: '', toolTip: ''},
    {title: 'Email Delivery Log', link: '/email-delivery-log', enabled: true, hidden: false, icon: '', toolTip: ''},
    {title: 'Email Bounce Log', link: '/email-bounce-log', enabled: true, hidden: false, icon: '', toolTip: ''},
    {title: 'Email Complaint Log', link: '/email-complaint-log', enabled: true, hidden: false, icon: '', toolTip: ''},
    {
      title: 'User Email Notifications',
      link: '/user-email-notifications',
      enabled: true,
      hidden: false,
      icon: '',
      toolTip: ''
    },
    {title: 'Permissions', link: '/permissions', enabled: true, hidden: false, icon: '', toolTip: ''}
  ];
  activeLink: INavItem = {} as unknown as INavItem;
  background: ThemePalette = undefined;

  userId: string | null = '';
  selectedUser: any = {};

  config: Config = initialConfigState;
  theme: Theme = initialThemeState;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store
  ) {
    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
  }

  ngOnInit(): void {
    this.animationState = true;
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
  }

  ngAfterViewInit() {
    this.setActiveLink();
  }

  navigate(obj: { title: string; link: string; enabled: boolean }) {
    this.store.dispatch(toggleNavigating({navigating: true}));
    this.animationState = false;
    setTimeout(() => {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/admin' + obj.link]).then(() => {
        this.animationState = true;
        this.store.dispatch(toggleNavigating({navigating: false}));
      });
    }, 400);
  }

  goBack() {
    this.store.dispatch(toggleNavigating({navigating: true}));
    this.animationState = false;
    setTimeout(() => {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/admin']).then(() => {
        this.store.dispatch(toggleNavigating({navigating: false}));
        this.animationState = true;
      });
    }, 400);
  }

  /**
   * Sets the current deal tab link from the url fragment
   */
  setActiveLink() {
    const fragments = this.router.url.split('/');
    const link = '/' + fragments[fragments.length - 1];
    this.links.forEach(l => {
      if (l.link == link) {
        this.activeLink = l;
      }
    });
  }

}
