import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-terms-and-conditions-modal',
  templateUrl: './terms-and-conditions-modal.component.html',
  styleUrls: ['./terms-and-conditions-modal.component.scss']
})
export class TermsAndConditionsModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TermsAndConditionsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {termsAndConditionsRequired:boolean,bodyHtml:string,pdfDownloadLink:string}
  ) {
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  action(data: {termsAndConditionsRequired:boolean,bodyHtml:string,pdfDownloadLink:string}):void{
    this.dialogRef.close(data);
  }
}
