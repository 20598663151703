import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ILookup} from "../../../../models";
import {getLookups, Lookups} from "../../../redux";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable, startWith, Subscription} from "rxjs";
import {LoadingService} from "../../../../services";
import {Store} from "@ngrx/store";
import {map} from "rxjs/operators";
import {MatChipInputEvent} from "@angular/material/chips";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {IDealRequest} from "../../models";
import {ToastrService} from "ngx-toastr";
import {DealService} from "../../services";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

enum DealWizard {
  'Issuer Overview' = 0,
  'Deal Overview' = 1,
  'Size & Timing' = 2,
  'Target & Return' = 3,
  'Additional Notes' = 4,
}

@Component({
  selector: 'app-deal-modal',
  templateUrl: './deal-modal.component.html',
  styleUrls: ['./deal-modal.component.scss']
})
export class DealModalComponent implements OnInit, OnDestroy {
  lookup$: Subscription = new Subscription();

  curPage: DealWizard = DealWizard["Issuer Overview"];
  @ViewChild('dealForm') dealForm: any;
  //TODO Convert to reactive form
  dealRequest: IDealRequest = {
    "currencyType": "USD",
    "issuerDealAreasOfFocus": [],
    "issuerDealAssets": [],
    "issuerDeals": []
  } as unknown as IDealRequest;
  lookups: Lookups = {} as Lookups;
  loading = false;
  showErr = false;

  // reactive form
  userForm = new FormGroup({
    id: new FormControl(),
    issuerEntityName: new FormControl('', Validators.required),
    countryId: new FormControl('', Validators.required),
    stateorProvinceId: new FormControl(),
    city: new FormControl('', Validators.required),
    issuerDeals: new FormControl([], []),
    issuerDealAssets: new FormControl([], []),
    issuerDealAreasOfFocus: new FormControl([], []),
    currencyType: new FormControl(),
    fundraiseSize: new FormControl('', Validators.required),
    rondeivuFundraiseSize: new FormControl('', Validators.required),
    timeLineTypeId: new FormControl(),
    firstCloseOccured: new FormControl(),
    anchorSeeking: new FormControl(),
    targetMoicBear: new FormControl('', Validators.required),
    targetMoicBase: new FormControl('', Validators.required),
    targetMoicBest: new FormControl('', Validators.required),
    minimumDuration: new FormControl('', Validators.required),
    expectedDuration: new FormControl('', Validators.required),
    maximumDuration: new FormControl('', Validators.required),
    notes: new FormControl(),
  });

  // deal type and asset class selection
  selectedAssetIdx: boolean[] = [];
  selectedDealIdx: boolean[] = [];

  //theme input
  @ViewChild('themeInput') themeInput: any;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  themeCtrl = new FormControl('');
  filteredThemes: Observable<string[]>;
  selectedThemes: string[] = [];


  constructor(public dialogRef: MatDialogRef<DealModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private loader: LoadingService,
              private store: Store,
              private dealService: DealService,
              private toastr: ToastrService) {

    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });

    //add the possible themes
    this.filteredThemes = this.themeCtrl.valueChanges.pipe(
      startWith(null),
      map((theme: string | null) => (theme ? this._filter(theme) : this.lookups.areasOfFocus.map(l => {
        return l.value
      }).slice())),
    );
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.lookup$.unsubscribe();
  }

  updateMyTextModel() {
    this.dealForm.control.markAsTouched();
    this.dealForm.control.markAsDirty();
  }

  getListFromEnum(e: any) {
    return Object.keys(e).slice(Object.keys(e).length / 2, Object.keys(e).length);
  }

  getTitle(idx: number) {
    return DealWizard[idx];
  }

  isSelectedPage(idx: number) {
    return idx === this.curPage;
  }

  getTitles() {
    return Object.keys(DealWizard).slice(0, Object.keys(DealWizard).length / 2);
  }

  back() {
    if (!this.firstPage()) {
      this.goTo(this.curPage - 1);
    }
  }

  next() {
    if (this.isStepValid()) {
      this.showErr = false;
      if (!this.lastPage()) {
        this.goTo(this.curPage + 1);
      }
    } else {
      this.showErr = true;
    }
  }

  isStepValid(): boolean {
    // console.log(this.userForm.getRawValue());
    // console.log(this.dealRequest);
    switch (this.curPage) {
      case 0:
        this.userForm.get('issuerEntityName')?.markAsTouched();
        this.userForm.get('countryId')?.markAsTouched();
        this.userForm.get('stateorProvinceId')?.markAsTouched();
        this.userForm.get('city')?.markAsTouched();
        if (!!this.userForm.get('issuerEntityName')?.value && !!this.userForm.get('countryId')?.value && !!this.userForm.get('city')?.value) {
          const key = this.userForm.get('countryId')?.value
          if (key == 'US' || key == 'CA') {
            return !!this.userForm.get('stateorProvinceId')?.value;
          } else {
            return true;
          }
        }
        return false;
      case 1:
        if (this.dealRequest.issuerDeals.length == 0 || this.dealRequest.issuerDealAssets.length == 0) {
          this.showErr = true;
        }
        return this.dealRequest.issuerDeals.length > 0 && this.dealRequest.issuerDealAssets.length > 0;
      case 2:
        this.userForm.get('fundraiseSize')?.markAsTouched();
        this.userForm.get('rondeivuFundraiseSize')?.markAsTouched();
        this.userForm.get('timeLineTypeId')?.markAsTouched();
        this.userForm.get('firstCloseOccured')?.markAsTouched();
        this.userForm.get('anchorSeeking')?.markAsTouched();
        return !!this.userForm.get('fundraiseSize')?.value && !!this.userForm.get('rondeivuFundraiseSize')?.value &&
          this.userForm.get('timeLineTypeId')?.value != null && this.userForm.get('firstCloseOccured')?.value != null
          && this.userForm.get('anchorSeeking')?.value != null;
      case 3:
        this.userForm.get('targetMoicBear')?.markAsTouched();
        this.userForm.get('targetMoicBase')?.markAsTouched();
        this.userForm.get('targetMoicBest')?.markAsTouched();
        this.userForm.get('minimumDuration')?.markAsTouched();
        this.userForm.get('expectedDuration')?.markAsTouched();
        this.userForm.get('maximumDuration')?.markAsTouched();
        return !!this.userForm.get('targetMoicBear')?.value && !!this.userForm.get('targetMoicBase')?.value &&
          !!this.userForm.get('targetMoicBest')?.value && !!this.userForm.get('minimumDuration')?.value &&
          !!this.userForm.get('expectedDuration')?.value && !!this.userForm.get('maximumDuration')?.value;
      case 4:
        return true;
      default:
        return false;
    }
  }

  submit() {
    let payload = this.userForm.getRawValue() as unknown as IDealRequest;
    payload.issuerDeals = this.dealRequest.issuerDeals;
    payload.issuerDealAssets = this.dealRequest.issuerDealAssets;
    //areas of focus has to be mapped to obj
    payload.issuerDealAreasOfFocus = Object.assign(this.selectedThemes.map(t => {
      return {"key": t};
    }));

    this.dialogRef.close(payload);

  }

  firstPage() {
    return this.curPage === 0;
  }

  lastPage() {
    return this.curPage === Object.keys(DealWizard).length / 2 - 1;
  }

  private goTo(page: number) {
    this.curPage = page;
  }

  isCountry(id: string, country: string) {
    let found = false;
    for (let i = 0; i <= this.lookups.countries.length - 1; i++) {
      if (this.lookups.countries[i].key == id) {
        if (this.lookups.countries[i].value == country) {
          found = true;
          break;
        }
      }
    }
    return found;
  }

  /**
   * Form action for selecting deal type
   * @param deal
   */
  toggleDealType(deal: ILookup) {
    this.toggleLookup(this.dealRequest.issuerDeals, deal);
  }

  /**
   * Form action for selecting asset class
   * @param asset
   */
  toggleAssetClass(asset: ILookup) {
    this.toggleLookup(this.dealRequest.issuerDealAssets, asset);
  }

  /**
   * This is a core function for all component pages that use the multicheckbox for the lookup selection
   * @param list the list to modify
   * @param lookup the lookup to toggle
   * @private
   */
  private toggleLookup(list: { key: string }[], lookup: ILookup) {
    if (!!list) {
      //search for the key of the lookup
      let found = false;
      let foundIdx = -1;
      for (let i = 0; i <= list.length - 1; i++) {
        if (list[i].key === lookup.key) {
          found = true;
          foundIdx = i;
          break;
        }
      }
      //only add if not found
      if (!found) {
        list.push(lookup);
      } else {
        if (foundIdx >= 0) {
          list.splice(foundIdx, 1);
        }
      }
      // console.log(list);
    }
  }

  /**
   * Add theme/keyword to the list
   */
  addTheme(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add our theme
    // console.log(value);
    if (value && !this.selectedThemes.includes(value)) {
      this.selectedThemes.push(value);
    }
    // Clear the input value
    event.chipInput!.clear();
    this.themeCtrl.setValue(null);
  }

  /**
   * Remove theme/keyword form the list
   * @param theme
   */
  removeTheme(theme: string): void {
    const index = this.selectedThemes.indexOf(theme);

    if (index >= 0) {
      this.selectedThemes.splice(index, 1);
    }
  }

  /**
   * Form action for selecting or typing a theme/keyword
   * @param event the selected event
   */
  selectedTheme(event: MatAutocompleteSelectedEvent): void {
    if (!this.selectedThemes.includes(event.option.viewValue)) {
      this.selectedThemes.push(event.option.viewValue);
    }
    if (!!this.themeInput) {
      this.themeInput.nativeElement.value = '';
    }
    this.themeCtrl.setValue(null);
  }

  /*
  Filters the autocomplete list
   */
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    // console.log(this.lookups.areasOfFocus.map(l=>{return l.value}));
    return this.lookups.areasOfFocus.map(l => {
      return l.value
    }).filter(theme => theme.toLowerCase().includes(filterValue));
  }

}
