import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {IBusinessUnit} from "../../../business-units/models";
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {UtilService} from "../../../shared/services";

import {
  getSelectedBusinessUnit, getSelectedDeal, getSelectedDealTeamMember,
  updateEditingBusinessUnitEmployee
} from "../../../redux";
import {IDealCard} from "../../../shared/models";

@Component({
  selector: 'app-deal-team-inner-navigation',
  templateUrl: './deal-team-inner-navigation.component.html',
  styleUrls: ['./deal-team-inner-navigation.component.scss']
})
export class DealTeamInnerNavigationComponent implements OnInit {

  selectedBusinessUnit$: Subscription = new Subscription();
  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;
  selectedDeal$: Subscription = new Subscription();
  selectedDeal: IDealCard = {} as unknown as IDealCard;

  selectedDealTeamMember$: Subscription = new Subscription();
  selectedDealTeamMember: any = {};


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    public util: UtilService,
  ) {
    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((selectedBusinessUnit: IBusinessUnit) => {
      this.selectedBusinessUnit = selectedBusinessUnit;
    });

    this.selectedDeal$ = this.store.select(getSelectedDeal).subscribe((deal: IDealCard) => {
      this.selectedDeal = deal;
    });

    this.selectedDealTeamMember$ = this.store.select(getSelectedDealTeamMember).subscribe((dtm: any) => {
      this.selectedDealTeamMember = dtm;
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedDeal$.unsubscribe();
    this.selectedDealTeamMember$.unsubscribe();
  }

  back() {
    this.store.dispatch(updateEditingBusinessUnitEmployee({businessUnitEmployee: {}}));
    this.router.navigate([this.selectedBusinessUnit.slug + '/deals/' + this.selectedDeal.id + '/deal-team']);
  }

}
