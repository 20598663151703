import {Injectable, OnDestroy} from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {from, Observable, Subscription} from 'rxjs';
import {
  Config,
  getConfig,
  initialConfigState,
  updateSelectedEntityKyc, updateSelectedEntityKycId
} from "../modules/redux";
import {ToastrService} from "ngx-toastr";
import {Store} from "@ngrx/store";
import {EntityKycService} from "../modules/entities/services/entity-kyc.service";

@Injectable({
  providedIn: 'root'
})
export class EntityKycResolver implements Resolve<boolean>, OnDestroy {
  config$: Subscription = new Subscription();
  config: Config = initialConfigState;
  entityKycId = '';

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private entityKycService: EntityKycService,
    private store: Store
  ) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    console.log("Running entity kyc resolver!");
    this.entityKycId = route.paramMap.get('id') || '';
    this.store.dispatch(updateSelectedEntityKycId({kycId: this.entityKycId}));
    return from(this.fetchAndDispatchEntityKyc(route, state));
  }

  private fetchAndDispatchEntityKyc(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      //entity already exists in the store
      if (!!this.config.selected_entity_kyc && !!this.config.selected_entity_kyc.id) {
        console.log("Entity Kyc resolver found existing entity kyc...");
        resolve(true);
      } else {
        console.log("Entity Kyc resolver fetching entity kyc...");
        this.entityKycService.getEntityKycById(this.entityKycId).subscribe({
          next: (kyc: any) => {
            this.store.dispatch(updateSelectedEntityKyc({entityKyc: kyc}));
            resolve(true);
          }, error: (error: any) => {
            reject(false);
            this.router.navigate(['/' + this.config.selected_business_unit.slug + '/entities/' + this.config.selected_entity_id + '/kyc']).then(() => {
              this.toastr.error("Access denied!", $localize`:@@companyName:Rondeivu`);
            });
          }
        });
      }
    });
  }
}
