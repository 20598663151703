<div style="overflow-y: auto; height:calc(100vh - 176px);">
  <div class="container-fluid" style="height: 100%;">
    <div class="row justify-content-center" style="padding-top: 25px;padding-bottom: 15px;">
      <div class="col-12 col-md-11">
        <span class="rm-title rm-title-3">{{ pageTitle }}</span>&nbsp;
        <mat-icon [matTooltip]="pageTooltip" matTooltipPosition="right">{{ pageIcon }}</mat-icon>
        <br/>
        <span class="rm-title rm-title-6 rm-lighter">{{ pageDesc }}</span>
      </div>
    </div>

    <div class="row justify-content-center" [hidden]="!dataRooms || dataRooms.length == 0">
      <div class="col-12 col-md-7" style="display: flex;">
        <mat-form-field style="width: 100%;" appearance="outline">
          <mat-label>Selected Data Room</mat-label>
          <mat-select id="tur" name="tur" style="font-size: small;"
                      [(ngModel)]="selectedDataRoom"
                      (selectionChange)="changeDataRoom($event.value)">
            <mat-option *ngFor="let d of dataRooms" [value]="d">
              {{ d?.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>&nbsp;
      </div>
      <div class="col-12 col-md-4" style="display: flex; justify-content: end; padding-bottom:15px;">
        <div style="padding-top: 10px; display: flex;">
          <div style="margin-right: 5px;">
            <button *ngIf="showHistoryButton  && util.isType('admin')" [hidden]="!dataRooms || dataRooms.length == 0"
                    type="button" mat-raised-button
                    (click)="viewHistory()"
            >
              <mat-icon>history</mat-icon>
              VIEW HISTORY
            </button>&nbsp;
          </div>
          <div>
            <button type="button" mat-raised-button color="primary"
                    (click)="downloadAll()"
                    [disabled]="!downloadAllEnabled"
                    [ngStyle]="{'cursor':!downloadAllEnabled ? util.NoAccessCursor: ''}"
                    [matTooltip]="!downloadAllEnabled ? util.NoAccessMsg : ''"
                    matTooltipPosition="left"
            >
              <mat-icon>download</mat-icon>
              DOWNLOAD ALL
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--                     -->
    <!-- FILE EXPLORER START -->
    <!--                     -->
    <div style="padding-bottom: 150px;">
      <div class="row justify-content-center" style="margin: 0 5px; height:calc(100% - 200px);">
        <div class="col-12 col-md-11" id="main">
          <mat-drawer-container id="move-pane" [hasBackdrop]="true">
            <!--                        -->
            <!-- MOVE FILE DRAWER START -->
            <!--                        -->
            <mat-drawer #drawer [mode]="'over'" style="width: 50%; padding: 25px 0 0 25px;" [(opened)]="isMoving">
              <div style="display: flex; justify-content: space-between;">
                <h1>Move <span style="color: blue;">{{ selectedFiles[0]?.documentName }}</span> To: <span
                  style="color: blue;">{{ moveDir.documentName }}</span></h1>
                <button type="button" mat-raised-button color="primary" style="margin-right: 25px;max-height: 36px;"
                        (click)="moveDataRoomToParent()">Apply
                </button>
              </div>
              <!--                      -->
              <!-- MOVE FILE TREE START -->
              <!--                      -->
              <mat-tree [dataSource]="dataSource" [treeControl]="treeControl"
                        style="overflow-y: scroll; height: calc(100% - 48px);">
                <!--            -->
                <!-- TREE NODES -->
                <!--            -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding
                               [class]="getMoveCssClass(node.data)"
                               [ngStyle]="{'cursor': !node.data['isLockedReason']? 'pointer':'not-allowed'}"
                               [hidden]="!node.data['dataRoom'] || node.data['fileType'] != ''"
                               (click)="setMoveDir(node.data)">
                  <div class="tree-row"
                       [ngStyle]="{'cursor': !node.data['isLockedReason']? 'pointer':'not-allowed'}"
                  >
                    <!-- locked-->
                    <div [hidden]="!node.data['isLockedReason']"
                         [matTooltip]="node.data['isLockedReason']"
                         [ngStyle]="{'cursor': !node.data['isLockedReason']? 'pointer':'not-allowed'}"
                         matTooltipPosition="above">
                      <img src="assets/images/locked_folder.png" class="folder-icon">&nbsp;
                    </div>
                    <!-- unlocked-->
                    <div [hidden]="node.data['isLockedReason']">
                      <!-- open-->
                      <div [hidden]="!treeControl.isExpanded(node)">
                        <img src="assets/images/open_folder.png" class="folder-icon">&nbsp;
                      </div>
                      <!-- closed-->
                      <div [hidden]="treeControl.isExpanded(node)">
                        <img src="assets/images/closed_folder.png" class="folder-icon">&nbsp;
                      </div>
                    </div>
                    <!-- title-->
                    <span>{{ node.name }}</span>&nbsp;
                    <div class="tree-icon">
                      <mat-icon
                        [matTooltip]="'This folder and its contents are visible to investors prior to signing an NDA'"
                        matTooltipPosition="right"
                        *ngIf="node?.data?.isPreNda"
                        color="warn">info
                      </mat-icon>
                    </div>
                  </div>
                </mat-tree-node>
                <!--                -->
                <!-- TREE NODES END -->
                <!--                -->

                <!--             -->
                <!-- CHILD NODES -->
                <!--             -->
                <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding
                               [class]="getMoveCssClass(node.data)"
                               [ngStyle]="{'cursor': !node.data['isLockedReason']? 'pointer':'not-allowed'}"
                               [hidden]="!node.data['dataRoom'] || node.data['fileType'] != ''"
                               (click)="setMoveDir(node.data)">
                  <div class="tree-row" matTreeNodeToggle (click)="setMoveDir(node.data)">
                    <!-- locked -->
                    <div [hidden]="!node.data['isLockedReason']"
                         [matTooltip]="node.data['isLockedReason']"
                         [ngStyle]="{'cursor': !node.data['isLockedReason']? 'pointer':'not-allowed'}"
                         matTooltipPosition="above">
                      <img src="assets/images/locked_folder.png" class="folder-icon">&nbsp;
                    </div>
                    <!-- unlocked -->
                    <div [hidden]="node.data['isLockedReason']">
                      <!-- open -->
                      <div [hidden]="!treeControl.isExpanded(node)">
                        <img src="assets/images/open_folder.png" class="folder-icon">&nbsp;
                      </div>
                      <!-- closed -->
                      <div [hidden]="treeControl.isExpanded(node)">
                        <img src="assets/images/closed_folder.png" class="folder-icon">&nbsp;
                      </div>
                    </div>
                    <!-- title -->
                    <span>{{ node.name }}</span>&nbsp;
                    <div class="tree-icon">
                      <mat-icon
                        [matTooltip]="'This folder and its contents are visible to investors prior to signing an NDA'"
                        matTooltipPosition="right"
                        *ngIf="node?.data?.isPreNda"
                        color="warn">info
                      </mat-icon>
                    </div>
                  </div>
                </mat-tree-node>
                <!--                 -->
                <!-- CHILD NODES END -->
                <!--                 -->
              </mat-tree>
              <!--                    -->
              <!-- MOVE FILE TREE END -->
              <!--                    -->
            </mat-drawer>
            <!--                      -->
            <!-- MOVE FILE DRAWER END -->
            <!--                      -->
            <mat-drawer-content style="height: 100%;">
              <!--               -->
              <!-- TOOLBAR START -->
              <!--               -->
              <div id="toolbar-row" class="row" *ngIf="showToolbar">
                <div style="display: flex; justify-content: space-between;">
                  <div class="toolbar" style="display: flex;">
                    <!-- left -->
                    <button type="button" mat-icon-button
                            [disabled]="pathIdx == 0"
                            *ngIf="showNavButtons"
                            (click)="back()">
                      <mat-icon>west</mat-icon>
                    </button>
                    <!-- home -->
                    <button type="button" mat-icon-button
                            [disabled]="selectedDir?.documentName == '/'"
                            *ngIf="showNavButtons"
                            (click)="home()">
                      <mat-icon>home</mat-icon>
                    </button>
                    <!-- right -->
                    <button type="button" mat-icon-button
                            [disabled]="pathIdx == paths.length - 1"
                            *ngIf="showNavButtons"
                            (click)="forward()">
                      <mat-icon>east</mat-icon>
                    </button>
                  </div>
                  <div class="d-none d-md-flex"
                       style="width: 100%; display: flex; justify-items: center; justify-content: center;">
                    <mat-form-field id="search-field" style="width: 100%; max-width: 450px;" appearance="outline">
                      <mat-label>Search</mat-label>
                      <input type="text" matInput [(ngModel)]="searchStr"
                             (keyup.enter)="applySearch(searchStr, selectedDir)"/>
                      <mat-icon matSuffix
                                class="search-icon"
                                *ngIf="!isSearching"
                                (click)="applySearch(searchStr, selectedDir)">search
                      </mat-icon>
                      <mat-icon matSuffix
                                class="search-icon"
                                *ngIf="isSearching"
                                (click)="clearSearch()">close
                      </mat-icon>
                    </mat-form-field>
                  </div>
                  <!--                    -->
                  <!-- MOBILE MENU BUTTON -->
                  <!--                    -->
                  <div class="toolbar d-block d-md-none"
                       [matMenuTriggerFor]="toolbarMenu"
                       style="display: flex; justify-content: right; justify-items: right; padding-left: 15px;"
                       *ngIf="showAddFileButton || showDeleteButton || showEditButton || showNewFolderButton"
                  >
                    <div>
                      <button mat-icon-button>
                        <mat-icon>
                          menu
                        </mat-icon>
                      </button>
                    </div>
                  </div>
                  <!--                       -->
                  <!-- TOOLBAR BUTTONS START -->
                  <!--                       -->
                  <div class="toolbar d-none d-md-inline-flex"
                       style="display: flex; justify-content: right; justify-items: right;">
                    <button type="button" mat-button class="tool-btn"
                            (click)="toggleEdit()"
                            *ngIf="showEditButton"
                            [disabled]="isSearching">
                      <mat-icon style="padding-top: 3px;"
                                [ngStyle]="{'color': isEditing? 'green':'black'}">edit
                      </mat-icon>
                      Edit Mode
                    </button>
                    <button type="button" mat-button class="tool-btn"
                            (click)="toggleDelete()"
                            *ngIf="showDeleteButton"
                            [disabled]="isSearching">
                      <mat-icon style="padding-top: 3px;"
                                [ngStyle]="{'color': isDeleting? 'red':'black'}">delete
                      </mat-icon>
                      Delete Mode
                    </button>
                    <button type="button" mat-button class="tool-btn"
                            (click)="newFolder()"
                            *ngIf="showNewFolderButton"
                            [disabled]="isSearching">
                      <mat-icon style="padding-top: 3px;"
                                [ngStyle]="{'color': isAddingFolder? 'orange':'black'}">folder
                      </mat-icon>
                      New Folder
                    </button>
                    <button type="button" mat-button class="tool-btn"
                            (click)="fileUpload.click()"
                            [disabled]="isSearching"
                            *ngIf="showAddFileButton">
                      <mat-icon style="padding-top: 3px;"
                                [ngStyle]="{'color': isAddingFolder? 'orange':'black'}">add
                      </mat-icon>
                      Add File
                    </button>&nbsp;
                    <!-- the add file input -->
                    <input class="add-input"
                           type="file"
                           placeholder="Choose File..."
                           (change)="addDocuments($event)"
                           #fileUpload/>
                  </div>
                  <!--                     -->
                  <!-- TOOLBAR BUTTONS END -->
                  <!--                     -->
                </div>
              </div>
              <!--             -->
              <!-- TOOLBAR END -->
              <!--             -->

              <!--                    -->
              <!-- TOOLBAR MENU START -->
              <!--                    -->
              <mat-menu #toolbarMenu="matMenu">
                <div style="display: flex;">
                  <ul style="list-style: none; padding-top:15px;">
                    <li (click)="toggleEdit()"
                        style="margin-bottom: 10px; cursor: pointer;"
                        *ngIf="!isSearching && showEditButton">
                      <mat-icon style="font-size: 16px; padding-top:10px;"
                                [ngStyle]="{'color': isEditing? 'green':'black'}">edit
                      </mat-icon>
                      Edit Mode
                    </li>
                    <li (click)="toggleDelete()"
                        style="margin-bottom: 10px; cursor: pointer;"
                        *ngIf="!isSearching && showDeleteButton">
                      <mat-icon style="font-size: 16px; padding-top:10px;"
                                [ngStyle]="{'color': isDeleting? 'red':'black'}">delete
                      </mat-icon>
                      Delete Mode
                    </li>
                    <li (click)="newFolder()"
                        style="margin-bottom: 10px; cursor: pointer;"
                        *ngIf="!isSearching && showNewFolderButton">
                      <mat-icon style="font-size: 16px; padding-top:10px;"
                                [ngStyle]="{'color': isAddingFolder? 'orange':'black'}">folder
                      </mat-icon>
                      New Folder
                    </li>
                    <li (click)="fileUpload2.click()"
                        style="margin-bottom: 10px; cursor: pointer;"
                        *ngIf="!isSearching && showAddFileButton">
                      <mat-icon style="font-size: 16px; padding-top:10px;"
                                [ngStyle]="{'color': isAddingFolder? 'orange':'black'}">add
                      </mat-icon>
                      Add File
                    </li>
                    <!-- the add file input -->
                    <input class="add-input" type="file" placeholder="Choose File..." (change)="addDocuments($event)"
                           #fileUpload2/>
                  </ul>
                </div>
              </mat-menu>
              <!--                  -->
              <!-- TOOLBAR MENU END -->
              <!--                  -->

              <!--             -->
              <!-- TOOLBAR END -->
              <!--             -->

              <!--               -->
              <!-- NAV BAR START -->
              <!--               -->
              <div class="row" *ngIf="showNavBar">
                <div class="col-12">
                  <input id="path" type="text" [(ngModel)]="path" (keyup.enter)="userNavToPath()"/>
                </div>
              </div>
              <!--             -->
              <!-- NAV BAR END -->
              <!--             -->

              <!--                  -->
              <!-- STATUS BAR START -->
              <!--                  -->
              <div *ngIf="isEditing" class="mode edit-mode">
                <mat-icon style="line-height: 24px;">info</mat-icon>&nbsp;
                <span style="line-height: 24px; min-width: 500px;"><b>Edit mode enabled:</b>&nbsp;
                  <span class="d-none d-md-inline-flex">Click on an item to begin editing.</span>
              </span>
              </div>
              <div *ngIf="isDeleting" class="mode delete-mode">
                <mat-icon style="line-height: 24px;">info</mat-icon>&nbsp;
                <span style="line-height: 24px; min-width: 500px;"><b>Delete mode enabled:</b>&nbsp;
                  <span class="d-none d-md-inline-flex">Click the delete icon to permanently delete an item.</span>
              </span>
              </div>
              <div *ngIf="isSearching" class="search-mode">
                <div *ngIf="!!searchFiles && !!searchFiles.dataRoom" class="mode">
                  <mat-icon style="padding-top: 5px;">info</mat-icon>&nbsp;
                  <span style="padding-top: 5px;"><b>Showing {{ searchLabel.title }} Search Results:</b>&nbsp;
                    {{ searchLabel.message }}
              </span>
                </div>
              </div>
              <!--                -->
              <!-- STATUS BAR END -->
              <!--                -->

              <!--                     -->
              <!-- EXPLORER BODY START -->
              <!--                     -->
              <div id="fs-body" class="row" [ngStyle]="{'height': 'calc(100vh - ' + getBodyHeightOffset() + ')'}">
                <div class="main-container" (mouseup)="changeResizeMode(false)"
                     (mousemove)="changeLeftContainerWidth($event)" (mouseleave)="changeResizeMode(false)">
                  <!--                      -->
                  <!-- LEFT CONTAINER START -->
                  <!--                      -->
                  <div class="left-container d-none d-md-flex" [style.flex-basis]="leftContainerWidth">
                    <div id="nav-pane" *ngIf="showRootFolders">
                      <div class="title-bar">
                        <mat-label>ROOT FOLDERS</mat-label>
                      </div>
                      <!--                 -->
                      <!-- FILE TREE START -->
                      <!--                 -->
                      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                        <!--            -->
                        <!-- TREE NODES -->
                        <!--            -->
                        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding
                                       [class]="getRowCssClass(node.data)"
                                       [ngStyle]="{'cursor': !node.data['isLockedReason']? 'pointer':'not-allowed'}"
                                       [disabled]="!node.data['isLockedReason']"
                                       [hidden]="!node.data['dataRoom'] || node.data['fileType'] != ''"
                                       (click)="setSelectedDir(node.data, true, true)">
                          <div class="tree-row"
                               [ngStyle]="{'cursor': !node.data['isLockedReason']? 'pointer':'not-allowed'}"
                               (click)="setSelectedDir(node.data, true, true)">
                            <!-- locked -->
                            <div [hidden]="!node.data['isLockedReason']"
                                 [matTooltip]="node.data['isLockedReason']"
                                 [ngStyle]="{'cursor': !node.data['isLockedReason']? 'pointer':'not-allowed'}"
                                 matTooltipPosition="above">
                              <img src="assets/images/locked_folder.png" class="folder-icon">&nbsp;
                            </div>
                            <!-- unlocked -->
                            <div [hidden]="node.data['isLockedReason']">
                              <!-- open -->
                              <div [hidden]="!treeControl.isExpanded(node)">
                                <img src="assets/images/open_folder.png" class="folder-icon">&nbsp;
                              </div>
                              <!-- closed -->
                              <div [hidden]="treeControl.isExpanded(node)">
                                <img src="assets/images/closed_folder.png" class="folder-icon">&nbsp;
                              </div>
                            </div>
                            <!-- title -->
                            <span>{{ node.name }}</span>&nbsp;
                            <div class="tree-icon">
                              <mat-icon
                                [matTooltip]="'This folder and its contents are visible to investors prior to signing an NDA'"
                                matTooltipPosition="right"
                                *ngIf="node?.data?.isPreNda"
                                color="warn">info
                              </mat-icon>
                            </div>
                          </div>
                        </mat-tree-node>
                        <!--                -->
                        <!-- TREE NODES END -->
                        <!--                -->

                        <!--             -->
                        <!-- CHILD NODES -->
                        <!--             -->
                        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding
                                       [class]="getRowCssClass(node.data)"
                                       [ngStyle]="{'cursor': !node.data['isLockedReason']? 'pointer':'not-allowed'}"
                                       [disabled]="!!node.data['isLockedReason']"
                                       [hidden]="!node.data['dataRoom'] || node.data['fileType'] != ''"
                                       (click)="setSelectedDir(node.data, true, true)">
                          <div matTreeNodeToggle class="tree-row"
                               [ngStyle]="{'cursor': !node.data['isLockedReason']? 'pointer':'not-allowed'}"
                               (click)="setSelectedDir(node.data, true, true)">
                            <!-- locked -->
                            <div [hidden]="!node.data['isLockedReason']"
                                 [matTooltip]="node.data['isLockedReason']"
                                 [ngStyle]="{'cursor': !node.data['isLockedReason']? 'pointer':'not-allowed'}"
                                 matTooltipPosition="above">
                              <img src="assets/images/locked_folder.png" class="folder-icon">&nbsp;
                            </div>
                            <!-- unlocked -->
                            <div [hidden]="node.data['isLockedReason']">
                              <!-- open -->
                              <div [hidden]="!treeControl.isExpanded(node)">
                                <img src="assets/images/open_folder.png" class="folder-icon">&nbsp;
                              </div>
                              <!-- closed -->
                              <div [hidden]="treeControl.isExpanded(node)">
                                <img src="assets/images/closed_folder.png" class="folder-icon">&nbsp;
                              </div>
                            </div>
                            <span>{{ node.name }}</span>&nbsp;
                            <div class="tree-icon">
                              <mat-icon
                                [matTooltip]="'This folder and its contents are visible to investors prior to signing an NDA'"
                                matTooltipPosition="right"
                                *ngIf="node?.data?.isPreNda"
                                color="warn">info
                              </mat-icon>
                            </div>
                          </div>
                        </mat-tree-node>
                        <!--                 -->
                        <!-- CHILD NODES END -->
                        <!--                 -->
                      </mat-tree>
                      <!--               -->
                      <!-- FILE TREE END -->
                      <!--               -->
                    </div>
                  </div>
                  <!--                    -->
                  <!-- LEFT CONTAINER END -->
                  <!--                    -->

                  <!--                     -->
                  <!-- RESIZE HANDLE START -->
                  <!--                     -->
                  <div class="resize-handle"
                       *ngIf="showRootFolders"
                       (mousedown)="changeResizeMode(true)"
                       (mouseup)="changeResizeMode(false)"
                       [style.background-color]="highlightHandle">
                    <div>||</div>
                  </div>
                  <!--                   -->
                  <!-- RESIZE HANDLE END -->
                  <!--                   -->

                  <!--                       -->
                  <!-- RIGHT CONTAINER START -->
                  <!--                       -->
                  <div class="right-container">
                    <div id="main-table-bg" [ngStyle]="{height:getTableHeight()}">
                      <!--                          -->
                      <!-- TABLE BLUR OVERLAY START -->
                      <!--                          -->
                      <div [class]="isDropping? 'table-blur':'table-read'">
                        <!--                  -->
                        <!-- FILE TABLE START -->
                        <!--                  -->
                        <table id="mat-data-table" mat-table [dataSource]="tableSource"
                               [multiTemplateDataRows]="true" matSort (matSortChange)="sortData($event)" cdkDropList
                               [cdkDropListData]="selectedDir.dataRoom"
                               (cdkDropListDropped)="!util.isType('investor') ? drop($event): null"
                               [cdkDropListSortingDisabled]="util.isType('investor')"
                               [cdkDropListDisabled]="util.isType('investor')"
                        >
                          <!-- Column -->
                          <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="documentName"> Name</th>
                            <td mat-cell *matCellDef="let element">
                              <div class="name-content text-start">
                                <div class="doc-img">
                                  <div *ngIf="!!element['dataRoom']">
                                    <div *ngIf="!!element['isLockedReason']"
                                         [matTooltip]="element['isLockedReason']"
                                         [ngStyle]="{'cursor': !element['isLockedReason']? 'pointer':'not-allowed'}"
                                    >
                                      <img src="assets/images/locked_folder.png" class="folder-icon-sm">&nbsp;
                                    </div>
                                    <!-- unlocked -->
                                    <div *ngIf="!element['isLockedReason']">
                                      <img src="assets/images/closed_folder.png" class="folder-icon-sm">&nbsp;
                                    </div>
                                  </div>
                                  <mat-icon *ngIf="!element['dataRoom']">
                                    {{ getItemIcon(element) }}
                                  </mat-icon>
                                </div>
                                <span class="doc-title"
                                      *ngIf="!isEditing || (isEditing && !editingFiles.includes(element))">{{ element.documentName }}</span>
                                <input matInput [(ngModel)]="editingPlaceholder.documentName"
                                       class="edit-input"
                                       [disabled]="!isEditing || (isEditing && !editingFiles.includes(element))"
                                       *ngIf="isEditing && editingFiles.includes(element)"
                                       (keydown)="isEditingRowDirty=true"
                                       (keydown.enter)="saveEdit(editingPlaceholder)"
                                />
                              </div>
                            </td>
                          </ng-container>
                          <!-- Column -->
                          <ng-container matColumnDef="size">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="size"> Size</th>
                            <td mat-cell
                                *matCellDef="let element">
                              <div class="cell-content">
                                {{ !!element['dataRoom'] ? element['dataRoom'].length + ' files' : (element.size | fileSize) }}
                              </div>
                            </td>
                          </ng-container>
                          <!-- Column -->
                          <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="fileType"> Type</th>
                            <td mat-cell *matCellDef="let element">
                              <div class="cell-content">
                                {{ !!element['dataRoom'] ? 'Folder' : element.fileType }}
                              </div>
                            </td>
                          </ng-container>
                          <!-- Column -->
                          <ng-container matColumnDef="created">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="created"> Date Created</th>
                            <td mat-cell *matCellDef="let element">
                              <div class="cell-content">
                                <app-date-display [utc]="element.created"></app-date-display>
                              </div>
                            </td>
                          </ng-container>
                          <!-- Column -->
                          <ng-container matColumnDef="downloadable">
                            <th mat-header-cell *matHeaderCellDef> Downloadable</th>
                            <td mat-cell *matCellDef="let element">
                              <div class="cell-content">
                                <mat-checkbox [checked]="editingPlaceholder?.isDownloadable || false"
                                              [(ngModel)]="element['isDownloadable']"
                                              [disabled]="true"
                                              *ngIf="isEditing && !editingFiles.includes(element) && !element['dataRoom']"
                                              color="primary"
                                ></mat-checkbox>
                                <mat-checkbox [checked]="editingPlaceholder?.isDownloadable || false"
                                              [(ngModel)]="editingPlaceholder['isDownloadable']"
                                              *ngIf="isEditing && editingFiles.includes(element) && !element['dataRoom']"
                                              (change)="isEditingRowDirty=true"
                                              color="primary"
                                ></mat-checkbox>
                              </div>
                            </td>
                          </ng-container>
                          <!-- Column -->
                          <ng-container matColumnDef="password">
                            <th mat-header-cell *matHeaderCellDef> Password</th>
                            <td mat-cell *matCellDef="let element" style="margin-top:auto; margin-bottom: auto;">
                              <div class="cell-content">
                                <span *ngIf="isEditing && !editingFiles.includes(element)">{{ element.password }}</span>
                                <input matInput [(ngModel)]="editingPlaceholder.password" class="edit-input"
                                       [disabled]="!isEditing || (isEditing && !editingFiles.includes(element))"
                                       *ngIf="isEditing && editingFiles.includes(element) && !element['dataRoom']"
                                       (keydown)="isEditingRowDirty=true"
                                       (keydown.enter)="saveEdit(editingPlaceholder)"
                                />
                                <button type="button" mat-icon-button (click)="copyPass(element.password)"
                                        [hidden]="isEditing"
                                        *ngIf="!!element.password">
                                  <mat-icon [matTooltip]="element.password" matTooltipPosition="right"
                                  >key
                                  </mat-icon>
                                </button>
                              </div>
                            </td>
                          </ng-container>
                          <!-- Column -->
                          <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef class="text-center"> Actions</th>
                            <td mat-cell *matCellDef="let element">
                              <div class="action-buttons text-center">
                                <!--                     -->
                                <!-- SELECT MODE BUTTONS -->
                                <!--                     -->
                                <!-- view -->
                                <button type="button" mat-icon-button
                                        *ngIf="!element['dataRoom'] && element['isOpenable'] && !isDeleting && !isEditing"
                                        [disabled]="!element['isOpenable']" (click)="openFile(element)">
                                  <mat-icon
                                    [matTooltip]="element['isOpenable'] ? (!!element['isLockedReason'] ? element['isLockedReason']: 'open') : ''"
                                    matTooltipPosition="right">open_in_new
                                  </mat-icon>
                                </button>
                                <!-- download -->
                                <button type="button" mat-icon-button
                                        *ngIf="!element['dataRoom'] && element['isDownloadable'] && !isDeleting && !isEditing"
                                        [disabled]="!element['isDownloadable']" (click)="download(element)">
                                  <mat-icon
                                    [matTooltip]="element['isDownloadable'] ? (!!element['isLockedReason'] ?  element['isLockedReason']: 'download') : ''"
                                    matTooltipPosition="above">download
                                  </mat-icon>
                                </button>
                                <!--                         -->
                                <!-- SELECT MODE BUTTONS END -->
                                <!--                         -->

                                <!--                     -->
                                <!-- DELETE MODE BUTTONS -->
                                <!--                     -->
                                <button type="button" mat-icon-button
                                        *ngIf="isDeleting && selectedFiles.includes(element)"
                                        (click)="delete(element)">
                                  <mat-icon [matTooltip]="'delete permanently'" matTooltipPosition="right"
                                            style="color:red;">
                                    delete
                                  </mat-icon>
                                </button>
                                <!--                         -->
                                <!-- DELETE MODE BUTTONS END -->
                                <!--                         -->

                                <!--                   -->
                                <!-- EDIT MODE BUTTONS -->
                                <!--                   -->
                                <!-- file move -->
                                <button type="button" mat-icon-button [disabled]="!element['isMoveable']"
                                        [hidden]="!showFileMove"
                                        *ngIf="element['isMoveable'] && !isDeleting && isEditing && editingFiles.includes(element) && !util.isType('investor')"
                                        (click)="setDataRoomToMove(element);">
                                  <mat-icon
                                    [matTooltip]="element['isMoveable'] ? (!!element['isLockedReason'] ? element['isLockedReason']: 'move'):''"
                                    matTooltipPosition="left">
                                    drive_file_move
                                  </mat-icon>
                                </button>
                                <!-- sort  -->
                                <button type="button"
                                        style="cursor: move;"
                                        mat-icon-button
                                        *ngIf="element['isMoveable'] && !isDeleting && isEditing && editingFiles.includes(element) && !util.isType('investor')"
                                        (mousedown)="isDragging = true;"
                                        (mouseup)="isDragging = false;"
                                >
                                  <mat-icon
                                    [matTooltip]="'sort'"
                                    matTooltipPosition="left">
                                    drag_handle
                                  </mat-icon>
                                </button>
                                <!--                       -->
                                <!-- EDIT MODE BUTTONS END -->
                                <!--                       -->
                              </div>
                            </td>
                          </ng-container>
                          <!-- Column -->
                          <ng-container matColumnDef="saveActions">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">
                              <!--              -->
                              <!-- SAVE ACTIONS -->
                              <!--              -->
                              <div class="action-buttons">
                                <!-- save -->
                                <button type="button" mat-icon-button
                                        *ngIf="isEditing && editingFiles.includes(element)"
                                        [disabled]="!isEditingRowDirty"
                                        (click)="saveEdit(editingPlaceholder)"
                                >
                                  <mat-icon [matTooltip]="isEditingRowDirty ? 'save':''" matTooltipPosition="left">done
                                  </mat-icon>
                                </button>
                                <!-- cancel -->
                                <button type="button" mat-icon-button
                                        *ngIf="isEditing && editingFiles.includes(element)"
                                        [disabled]="!isEditingRowDirty"
                                        (click)="cancelEdit(editingPlaceholder)"
                                >
                                  <mat-icon [matTooltip]="isEditingRowDirty ? 'cancel': ''" matTooltipPosition="right">
                                    close
                                  </mat-icon>
                                </button>
                              </div>
                              <!--                  -->
                              <!-- SAVE ACTIONS END -->
                              <!--                  -->
                            </td>
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="getColsToDisplay(); sticky:true;"></tr>
                          <tr mat-row *matRowDef="let row; columns: getColsToDisplay();"
                              (click)="setSelectedFile(row);"
                              (dblclick)="rowDblClick(row, true, true)"
                              [ngStyle]="{'background-color':getRowBg(row)}"
                              [ngStyle]="{'cursor': !row['isLockedReason']? 'pointer':'not-allowed'}"
                              cdkDrag
                              [cdkDragDisabled]="!isDragging"
                          >
                            <div class="cdk-drag-placeholder" *cdkDragPlaceholder></div>
                          </tr>
                        </table>
                        <!--                -->
                        <!-- FILE TABLE END -->
                        <!--                -->
                      </div>
                      <!--                        -->
                      <!-- TABLE BLUR OVERLAY END -->
                      <!--                        -->
                    </div>
                    <!--                -->
                    <!-- DROPZONE START -->
                    <!--                -->
                    <div id="dropzone"
                         appDnd
                         [hidden]="isSearching || util.isType('investor')"
                         (filesDropped)="onFilesDropped($event)"
                         (filesHovered)="onFilesHovered($event)"
                         [class]="canDropFiles ? 'drop-file-bg':''">
                      <div id="drop-files"
                           [hidden]="!isDropping"
                           (mouseleave)="onMouseLeave($event)">
                        <img src="assets/images/cloud_upload.png">
                        <h1>Drop files here to Upload them.</h1>
                      </div>
                    </div>
                    <!--              -->
                    <!-- DROPZONE END -->
                    <!--              -->
                  </div>
                  <!--                     -->
                  <!-- RIGHT CONTAINER END -->
                  <!--                     -->
                </div>
              </div>
              <!--                     -->
              <!-- EXPLORER BODY END -->
              <!--                     -->

              <!--              -->
              <!-- FOOTER START -->
              <!--              -->
              <div class="row justify-content-start"
                   id="footer">
                <div class="col-12">
                  &nbsp;<span></span>
                </div>
              </div>
              <!--            -->
              <!-- FOOTER END -->
              <!--            -->
            </mat-drawer-content>
          </mat-drawer-container>
        </div>
        <!--                   -->
        <!-- FILE EXPLORER END -->
        <!--                   -->
      </div>
    </div>
  </div>
</div>




