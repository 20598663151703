<div class="rm-header">
  <span>Upload Image</span>
</div>
<div class="row justify-content-center" style="padding-bottom: 25px; padding-top: 15px;">
  <div class="col-11" style="overflow: auto; max-height: 500px;">
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [aspectRatio]="1 / 1"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded($event)"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
    <input type="file" (change)="fileChangeEvent($event)"/>
    <!--    <img [src]="croppedImage" />-->
  </div>
  <div class="col-11">
    <span style="font-size: small; font-weight: lighter;">PNG and JPG files under 2MB are allowed.</span>
  </div>
</div>
<div class="row">
  <div class="col-6">
    <button mat-raised-button (click)="dialogRef.close()">CANCEL</button>
  </div>
  <div class="col-6 text-end">
    <button mat-raised-button color="primary" [disabled]="!imageChangedEvent" (click)="dialogRef.close(blob)">CONFIRM
    </button>
  </div>
</div>
