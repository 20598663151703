<div style="overflow-y: auto; height: calc(100vh - 242px);">
  <div class="container-fluid">
    <div style="overflow-x: hidden;">
      <div class="row justify-content-center" style="padding-top: 25px;">
        <div class="col-11">
          <span class="rm-title rm-title-3">Deal Team Member Account</span><br/>
        </div>
      </div>
      <br/>
      <!--    WELL START-->
      <div class="row justify-content-center">
        <div class="col-11" style="border: 1px solid rgba(128,128,128,0.5); border-radius: 12px; padding: 12px;">
          <app-user-card [firstName]="selectedDealTeamMember?.firstName"
                         [lastName]="selectedDealTeamMember?.lastName"
                         [displayName]="selectedDealTeamMember?.email"
                         [borderColor]="'transparent'"
                         [sideColor]="'#002150'"
                         [image]="selectedDealTeamMember?.imageUrl"
                         [size]="userCardSize.XL"
          ></app-user-card>
          <form [formGroup]="userForm"
                (ngSubmit)="save()">
            <!--          row start-->
            <!--            <div class="row">-->
            <!--              <div class="col-9">-->
            <!--                <mat-label style="font-size: medium;">Employee Title</mat-label>-->
            <!--                <mat-form-field class="form-field" appearance="outline">-->
            <!--                  <input class="form-input" matInput formControlName="title"-->
            <!--                         placeholder="e.g. Operations Manager"/>-->
            <!--                </mat-form-field>-->
            <!--              </div>-->
            <!--              <div class="col-3" style="padding-top: 36px;">-->
            <!--                <mat-checkbox color="primary" formControlName="isEmployeeActive">Active</mat-checkbox>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--          row start-->
            <!--            <div class="row" style="padding-top: 20px;">-->
            <!--              <div class="col-12 text-end">-->
            <!--                &lt;!&ndash;                <button type="button" mat-raised-button (click)="back()">BACK</button> &nbsp;&ndash;&gt;-->
            <!--                <button type="submit" mat-raised-button color="primary">SAVE</button>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--          rows end-->
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

