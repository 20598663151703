<div
  id="floater"
  [ngStyle]="{
    'background-color': bgColor,
    'color': color,
    'top': topPos,
    'right': rightPos
  }"
  (click)="floaterClick()"
  [hidden]="hidden"
>
  <mat-icon [matTooltip]="toolTip" matTooltipPosition="left">{{ icon }}</mat-icon>
</div>
