import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {
  Config,
  getConfig,
  getSelectedBusinessUnit,
  getSelectedDeal,
  initialConfigState
} from "../../../redux";
import {IBusinessUnit} from "../../../business-units/models";
import {UtilService} from "../../../shared/services";
import {IDealCard} from "../../../shared/models";

@Component({
  selector: 'app-deal-data-room',
  templateUrl: './deal-data-room.component.html',
  styleUrls: ['./deal-data-room.component.scss']
})
export class DealDataRoomComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  selectedDeal$: Subscription = new Subscription();
  selectedDeal: IDealCard = {} as unknown as IDealCard;

  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;
  config = initialConfigState;
  docTypes = ['DATAROOM_DEAL_DOCS'];
  objectId = '';


  constructor(
    private store: Store,
    public util: UtilService
  ) {
    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.selectedBusinessUnit = bu;
    });

    this.selectedDeal$ = this.store.select(getSelectedDeal).subscribe((deal: IDealCard) => {
      this.selectedDeal = deal;
      this.objectId = deal.id;
    });

  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedDeal$.unsubscribe();
  }

}
