import {createReducer, on} from '@ngrx/store';
import * as UserActions from './user.actions';
import {initialUserState} from "./user.init";

// the key feature
export const userFeatureKey = 'usersetttings';

// the update reducer
export const userReducer = createReducer(
  initialUserState,
  on(UserActions.updateUserSettings, (state, {user}) => ({
        emailVerified: user.emailVerified,
        phoneNumberVerified: user.phoneNumberVerified,
        mfaType: user.mfaType,
        id: user.id,
        subId: user.subId,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        countryCode: user.countryCode,
        mobile: user.mobile,
        created: user.created,
        profileLink: user.profileLink,
        imageUrl: user.imageUrl
      }
    )
  )
);

