import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map} from 'rxjs/operators';
import {toggleDarkModeConfig, updateConfig} from "../config/config.actions";
import {updateTheme} from "./theme.actions";
import {environment} from "../../../../../environments/environment";
import {Store} from "@ngrx/store";

@Injectable({
  providedIn: 'root'
})
export class ThemeEffects {

  //listen for a change in the theme configuration
  theme$ = createEffect(() => this.actions$.pipe(
    ofType(toggleDarkModeConfig),
    map((result: any) => {
      if (result.mode === true) {
        // console.log('dispatch: ' + updateTheme.type);
        return updateTheme({theme: environment.rondeivu_colors.default_theme});
      }
      // console.log('dispatch: ' + updateTheme.type);
      return updateTheme({theme: environment.rondeivu_colors.default_theme});
    })));

  constructor(
    private actions$: Actions,
    private store:Store
  ) {
  }
}
