import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from "@ngrx/store";
import {LookupService} from "../../../../services";
import {loadedLookups, loadLookup, loadLookups} from "./lookups.actions";
import {map} from "rxjs/operators";
import {LookupType} from "../../../../models";


@Injectable({
  providedIn: 'root'
})
export class LookupsEffects {

  //listen for a change in the lookup configuration
  loadLookup$ = createEffect(() => this.actions$.pipe(
    ofType(loadLookup),
    map((res:{lookup: LookupType}) => {
      this.lookupService.loadLookup(res.lookup);
      return {type: '[Loaded Lookup ' + res.lookup.toString() + '] Lookups'};
    })));

  //listen for a change in the lookup configuration
  loadLookups$ = createEffect(() => this.actions$.pipe(
    ofType(loadLookups),
    map(() => {
      this.lookupService.loadLookups();
      return {type: loadedLookups};
    })));

  constructor(
    private actions$: Actions,
    private store: Store,
    private lookupService: LookupService
  ) {
  }
}
