<!-- <div [@fadeIn]="animationState" (@fadeIn.start)="animStart($event)" > -->
<div>
  <!-- <div [@slideOutLeft]="animateExit" (@slideOutLeft.done)="exitEnd($event)"> -->
  <div>
    <mat-toolbar id="toolbar" dense [ngStyle]="{'background-color':theme.subheader_bg, 'color':theme.subheader_fg}">
      <button mat-button (click)="goBack()" [matTooltip]="util.isType('admin')? 'List Deals': 'List Deals'"
              matTooltipPosition="below">
        <mat-icon>list</mat-icon>
      </button>
      &nbsp;
      <div id="deal-header" *ngIf="!loading">
        <div style="display: flex;">
          <!--        inbox button-->
          <button style="margin-right: 15px; background-color: orange;" mat-mini-fab [matMenuTriggerFor]="alerts"
                  *ngIf="!!selectedDeal?.dealAlerts && selectedDeal.dealAlerts.length > 0">
            <mat-icon style="color: white;" [matBadge]="selectedDeal?.dealAlerts?.length" matBadgeColor="warn">warning
            </mat-icon>
          </button>
          <button style="margin-right: 15px; background-color: green; cursor: default;" mat-mini-fab
                  *ngIf="!!selectedDeal?.dealAlerts && selectedDeal.dealAlerts.length == 0">
            <mat-icon style="color: white; height: 40px; width: 40px;">verified</mat-icon>
          </button>
          <!--          <div class="d-none d-md-block">-->
          <div>
          <span>
          {{(!!selectedDeal.projectName ? 'Viewing ' + selectedDeal.projectName : '')}}
          </span><br/>
            <span style="color: grey;">
          {{(!!selectedDeal.id ? 'Deal #' + selectedDeal.id : '')}}
        </span>
          </div>
        </div>

      </div>

      <!-- ALERT MENU START-->
      <mat-menu #alerts="matMenu" id="alerts">
        <ul id="alert-item-list">
          <li *ngFor="let t of selectedDeal?.dealAlerts">
            <div class="task-item"
                 [ngStyle]="{'background-color':getTaskBg(t)}">
              <div class="text-center task-icon">
                <mat-icon>{{getTaskImage(t.status)}}</mat-icon>
              </div>
              <div>
                <span style="font-size:medium; font-weight: bold;">{{t?.title}}</span><br/>
                <span style="font-size:small;">{{t?.message}}</span><br/>
                <span style="font-size:small; font-weight: lighter; cursor: pointer; color: #0033ff;"
                      (click)="goToTask(t)"><u>{{t?.linkText}}</u></span>
              </div>
            </div>
          </li>
        </ul>
      </mat-menu>
      <!--ALERT MENU END-->

      <mat-spinner *ngIf="loading" [diameter]="36" color="accent"></mat-spinner>
      <!--      approval buttons-->
      <div *ngIf="allowApproval()" class="nav-end">
        <button mat-raised-button color="primary"
                (click)="approveDeal(selectedDeal)"
        >Approve
        </button>&nbsp;
        <button mat-raised-button
                color="warn"
                (click)="declineDeal(selectedDeal)"
        >Decline
        </button>
      </div>
      <!--      issuer actions-->
      <div *ngIf="util.isType('issuer')" class="nav-end">
        <button mat-raised-button color="primary" *ngIf="selectedDeal.isPreNda" (click)="investorSignNda(selectedDeal)">
          Sign NDA
        </button>
      </div>
      <!--      investor actions-->
      <div *ngIf="util.isType('investor')" class="nav-end">
        <button mat-raised-button color="warn"
                (click)="investorDecline(selectedDeal)"
                [disabled]="!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_DECLINE) || (selectedDeal.investorDealWorkflow == 'DECLINED')"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_DECLINE) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_ACCESS_DEAL_DECLINE) ? util.NoAccessMsg : ''"
                matTooltipPosition="left"
        >
          Decline
        </button>&nbsp;
        <button mat-raised-button color="primary"
                *ngIf="selectedDeal.isPreNda && selectedDeal.investorDealWorkflow != 'DECLINED'"
                (click)="investorSignNda(selectedDeal)"
                [disabled]="!util.hasPermission(util.Permission.CAN_INITIATE_DEAL_NDA)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_INITIATE_DEAL_NDA) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_INITIATE_DEAL_NDA) ? util.NoAccessMsg : ''"
                matTooltipPosition="left"
        >
          Sign NDA
        </button>&nbsp;
        <button mat-raised-button color="primary"
                *ngIf="!selectedDeal.isPreNda && selectedDeal.dealCloseType?.toLowerCase() === 'drawdown'"
                (click)="investorIndicateInterest(selectedDeal)"
                [disabled]="!util.hasPermission(util.Permission.CAN_ADD_DEAL_SYNDICATION)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_DEAL_SYNDICATION) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_ADD_DEAL_SYNDICATION) ? util.NoAccessMsg : ''"
                matTooltipPosition="left"
        >Indicate Interest
        </button>
        <button mat-raised-button color="primary"
                *ngIf="!selectedDeal.isPreNda && selectedDeal.dealCloseType?.toLowerCase() === 'evergreen'"
                (click)="investorSubscribe(selectedDeal)"
                [disabled]="!util.hasPermission(util.Permission.CAN_ADD_DEAL_SUBSCRIPTION)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_DEAL_SUBSCRIPTION) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_ADD_DEAL_SUBSCRIPTION) ? util.NoAccessMsg : ''"
                matTooltipPosition="left"
        >Subscribe
        </button>
      </div>

    </mat-toolbar>
    <nav id="deal-nav" dense
         mat-tab-nav-bar
         [tabPanel]="tabPanel"
         style="width: 100%"
         [ngStyle]="{'background-color': theme.subheader_bg}"
    >
      <div *ngFor="let link of links">
        <a *ngIf="link.enabled"
           mat-tab-link
           (click)="activeLink = link; navigate(link)"
           [active]="activeLink.link == link.link"
           [disabled]="!link.enabled"
           [hidden]="link.hidden"
           [ngStyle]="{'background-color':theme.subheader_bg, 'color':theme.subheader_fg}"
        ><span>{{ link.title }}</span></a>
        <span class="fake-tab" *ngIf="!link.enabled" [matTooltip]="link.toolTip" [hidden]="link.hidden"
              matTooltipPosition="below">{{ link.title }}</span>
      </div>
    </nav>
    <mat-tab-nav-panel #tabPanel>
      <div [@appFade]="animationState">
        <router-outlet></router-outlet>
      </div>
    </mat-tab-nav-panel>
  </div>
</div>

