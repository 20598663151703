import {createReducer, on} from '@ngrx/store';
import * as ThemeActions from './theme.actions';
import {initialThemeState} from "./theme.init";

// the key feature
export const themeFeatureKey = 'theme';

// the update reducer
export const themeReducer = createReducer(
  initialThemeState,
  on(ThemeActions.updateTheme, (state, {theme}) => ({
      flag_bg: theme.flag_bg,
      header_bg: theme.header_bg,
      header_fg: theme.header_fg,
      subheader_bg: theme.subheader_bg,
      subheader_fg: theme.subheader_fg,
      nav_bg: theme.nav_bg,
      nav_fg: theme.nav_fg,
      nav_highlight: theme.nav_highlight,
      content_bg: theme.content_bg,
      content_fg: theme.content_fg,
      pill_1_bg: theme.pill_1_bg,
      pill_1_fg: theme.pill_1_fg,
      pill_2_bg: theme.pill_2_bg,
      pill_2_fg: theme.pill_2_fg,
      pill_3_bg: theme.pill_3_bg,
      pill_3_fg: theme.pill_3_fg,
      status: theme.status,
      tasks:theme.tasks
    }
  ))
);
