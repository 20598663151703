<div id="message-container" class="container-fluid">
  <form>
    <!--    message selector-->
    <div id="msg-select" class="row">
      <div class="col-12">
        <em>Only you and your deal team can see these notes</em>
      </div>
      <div class="col-12">
        <mat-select name="collection" [(ngModel)]="selectedCollection" (selectionChange)="changeNoteCollection($event)">
          <mat-option *ngFor="let c of noteCollections" [value]="c">
            {{ c.name }}
          </mat-option>
        </mat-select>
        <br/>
        <div style="display: flex; justify-content: space-between;">
          <span></span>
          <mat-icon style="cursor: pointer" (click)="getNotes()" [matTooltip]="'Refresh Notes'"
                    matTooltipPosition="left">refresh
          </mat-icon>
        </div>
      </div>
    </div>
    <!--    spinner-->
    <div *ngIf="loading" id="spinner">
      <div class="row justify-content-center">
        <div class="col-2">
          <mat-spinner color="accent"></mat-spinner>
        </div>
      </div>
    </div>
    <!--    messages-->
    <div #scrollMe *ngIf="!loading" id="messages">
      <div class="row justify-content-center" *ngFor="let note of notesList; index as i;">
        <div class="col-11">
          <!--          the note bubbles-->
          <div class="row message-bubble" [class]="note.isNoteOwner? 'message-blue':'message-grey'">
            <div class="col-10 align-content-center">
              <span style="font-size: small;">{{ note.createdBy?.firstName + ' ' + note.createdBy?.lastName }}
                <span style="font-weight: lighter;">{{ ' on ' + (note.created | date) }}</span>
              </span>
            </div>
            <div class="col-2 text-end"
                 style="display: inline-flex; justify-items: center; justify-content: end; padding-top: 5px;">
              <mat-icon style="font-size: small; cursor: pointer;"
                        *ngIf="isEditing && editIdx === i"
                        (click)="isEditing = false;"
                        [matTooltip]="'Cancel Edit'"
                        matTooltipPosition="left"
              >cancel
              </mat-icon>
              <mat-icon style="font-size: small; cursor: pointer;"
                        *ngIf="(!isEditing || editIdx != i)"
                        (click)="isEditing = true; noteEdit = note.note; editIdx = i;"
                        [hidden]="!canAccessActionByUserPermission(ActionType.EDIT, note)"
                        [matTooltip]="'Edit Note'" matTooltipPosition="left">edit
              </mat-icon>
              <mat-icon style="font-size: small; cursor: pointer;"
                        *ngIf="(!isEditing || editIdx != i )"
                        (click)="delete(note)"
                        [hidden]="!canAccessActionByUserPermission(ActionType.DELETE, note)"
                        [matTooltip]="'Delete Note'" matTooltipPosition="right">delete
              </mat-icon>
            </div>
            <div class="col-12">
              <div *ngIf="isEditing && editIdx === i">
                <mat-form-field class="form-field">
                  <input matInput [name]="'note'+ i" placeholder="Type here..." [(ngModel)]="noteEdit"
                         (keydown.enter)="editNote(note, noteEdit)"/>
                  <button mat-icon-button matSuffix type="button" (click)="editNote(note, noteEdit)">
                    <mat-icon>send</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <div *ngIf="!isEditing || editIdx != i">
                <span style="word-wrap: break-word;">{{ note.note }}</span>
              </div>
            </div>
          </div>
          <!--          note bubble end-->
        </div>
      </div>
    </div>
    <!--    note input-->
    <div id="msg-input">
      <div class="row">
        <div class="col-12">
          <mat-form-field class="form-field" appearance="outline">
            <input name="note" matInput placeholder="Type here..."
                   [(ngModel)]="notePost"
                   [disabled]="!canAccessActionByUserPermission(ActionType.ADD)"
                   [ngStyle]="{'cursor':!canAccessActionByUserPermission(ActionType.ADD) ? util.NoAccessCursor: ''}"
                   [matTooltip]="!canAccessActionByUserPermission(ActionType.ADD) ? util.NoAccessMsg : ''"
                   (keydown.enter)="addNote(notePost)"/>
            <button mat-icon-button
                    matSuffix
                    type="button"
                    (click)="addNote(notePost)"
                    [disabled]="!canAccessActionByUserPermission(ActionType.ADD)"
                    [ngStyle]="{'cursor':!canAccessActionByUserPermission(ActionType.ADD) ? util.NoAccessCursor: ''}"
                    [matTooltip]="!canAccessActionByUserPermission(ActionType.ADD) ? util.NoAccessMsg : ''"
                    matTooltipPosition="left"
            >
              <mat-icon>send</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</div>
