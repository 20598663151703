import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UsersRoutingModule} from './users-routing.module';
import {UserBusinessUnitsComponent, UserInformationComponent, UserListComponent} from './components/';
import {UsersService} from "./services/users/users.service";
import {HttpClientModule} from "@angular/common/http";
import {UserNavigationComponent} from './components';
import {NgMaterialModule} from "../ng-material/ng-material.module";
import {SharedModule} from "../shared/shared.module";
import {ReactiveFormsModule} from "@angular/forms";
import {UserModalComponent} from './components/user-modal/user-modal.component';
import {UserActivityComponent} from './components/user-activity/user-activity.component';
import {
  UserEmailNotificationsComponent
} from './components/user-email-notifications/user-email-notifications.component';

@NgModule({
  declarations: [
    UserListComponent,
    UserNavigationComponent,
    UserInformationComponent,
    UserBusinessUnitsComponent,
    UserModalComponent,
    UserActivityComponent,
    UserEmailNotificationsComponent
  ],
  imports: [
    CommonModule,
    UsersRoutingModule,
    HttpClientModule,
    NgMaterialModule,
    SharedModule,
    ReactiveFormsModule
  ],
  providers: [UsersService]
})
export class UsersModule {
}
