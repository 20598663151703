import {Injectable} from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {from, Observable, of, Subscription} from 'rxjs';
import {Config, getConfig, initialConfigState} from "../modules/redux";
import {Location} from "@angular/common";
import {ToastrService} from "ngx-toastr";
import {AppConfigService} from "../services";
import {Store} from "@ngrx/store";
import {CompanyService} from "../modules/company/services/company.service";
import {updateEmployee} from "../modules/redux/stores/config/config.actions";

@Injectable({
  providedIn: 'root'
})
export class CompanyEmployeeResolver implements Resolve<boolean> {

  private readonly LOGIN_PATH = '/auth/login';
  config$: Subscription = new Subscription();
  config: Config = initialConfigState;

  constructor(
    private router: Router,
    private location: Location,
    private toastr: ToastrService,
    private companyService: CompanyService,
    private appConfig: AppConfigService,
    private store: Store
  ) {
    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return from(this.fetchAndDispatchCompanyEmployee(route, state));
  }

  private fetchAndDispatchCompanyEmployee(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let employeeId = route.paramMap.get('id') || '';
      this.companyService.getEmployee(employeeId).subscribe({
        next: (employee: any) => {
          this.store.dispatch(updateEmployee({employee: employee}));
          resolve(true);
        }, error: err => {
          reject(false);
          let redirect = '/' + this.config.selected_business_unit.slug + '/my-company/';
          this.router.navigate([redirect]).then(() => {
            this.toastr.error("Access denied!", $localize`:@@companyName:Rondeivu`);
          });
        }
      });
    });
  }
}
