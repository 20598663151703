import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {CognitoUser, Config, getCognitoUser, getConfig, initialCognitoUserState, initialConfigState} from "../../redux";
import {RondeivuHttpClient} from "../../../handlers";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {IAppUserRequest, IUsersRequest} from "../../users/models/i-users";
import {HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class LegalService {

  readonly BASE_URL = environment.rondeivu_api;
  readonly FRAGMENT = '/legal';
  readonly NDA_FRAGMENT = '/nda';
  readonly BU_FOR_USERS = '/businessunitsforusers';
  readonly FORCE_PR = '/force-password-reset';

  private config: Config = initialConfigState;
  private cognito: CognitoUser = initialCognitoUserState;

  constructor(private http: RondeivuHttpClient, private store:Store) {
    this.store.select(getConfig).subscribe(c => {
      this.config = c;
    });
    this.store.select(getCognitoUser).subscribe(cu => {
      this.cognito = cu;
    });
  }

  /**
   * Get all NDA
   */
  getNda(): Observable<any> {
    return this.http.get(this.BASE_URL + this.NDA_FRAGMENT);
  }

  /**
   * Get a list of all users
   */
  getUsers():Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT);
  }

  /**
   * Get a user by id
   */
  getUser():Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + '/' + this.config.selected_user_id);
  }

  /**
   * Add a new user
   * @param user
   */
  addUser(user: IUsersRequest): Observable<any> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.BASE_URL + this.FRAGMENT, user, {headers: httpHeaders});
  }

  /**
   * Update an app user
   * @param user
   */
  updateUser(user: IAppUserRequest): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + '/' + user.id, user);
  }

  /**
   * Get the business units for an app user
   * @param appUserId
   */
  getBusinessUnitsForUser(appUserId:string){
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.BU_FOR_USERS + '/' + appUserId);
  }

  /**
   * Force password reset for app user
   * @param appUserId
   */
  forcePasswordReset(appUserId:string){
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.FORCE_PR + '/' + appUserId, '');
  }
}
