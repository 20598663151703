import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {DealService, DealTeamMembersService, SubscriptionService, SyndicationService} from '../../services';
import {Store} from "@ngrx/store";
import {
  Config,
  getConfig,
  getSelectedBusinessUnit,
  getSelectedDeal, getSelectedDealTabs,
  getThemeState,
  initialConfigState,
  initialThemeState,
  showNotesTab,
  Theme,
  toggleLoading,
  updateDeal,
  updateDealId,
  updateDealRole,
  updateDealSubscription,
  updateDealSyndication, updateDealTabs
} from "../../../redux";
import {ToastrService} from "ngx-toastr";
import {IBusinessUnit, IBusinessUnitRole} from "../../../business-units/models";
import {INavItem, ITask} from "../../../../models";
import {DealWorkFlow, IDealCard} from "../../../shared/models";
import {IndicateInterestModalComponent} from "../indicate-interest-modal/indicate-interest-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {SubscriptionModalComponent} from "../subscription-modal/subscription-modal.component";
import {Subscription} from "rxjs";
import {DeclineModalComponent} from "../decline-modal/decline-modal.component";
import {UtilService} from "../../../shared/services";
import {ModalSize} from "../../../shared/services/util/util.service";
import {AppConfigService} from "../../../../services";
import {ApprovalsService} from "../../../approvals/services/approvals/approvals.service";
import {IApprovalRequest} from "../../../approvals/models";
import {toggleNavigating} from "../../../redux/stores/config/config.actions";
import {AppFade} from "../../../../animations/animations";

@Component({
  selector: 'app-deal-navigation',
  templateUrl: './deal-navigation.component.html',
  styleUrls: ['./deal-navigation.component.scss'],
  animations: [AppFade()]
})
export class DealNavigationComponent implements OnInit, OnDestroy, AfterViewInit {
  loading = false;
  animationState = true;

  links: INavItem[] = [];
  activeLink: INavItem = {} as unknown as INavItem;
  background: ThemePalette = undefined;

  dealId: string | null = '';
  selectedDeal$: Subscription = new Subscription();
  selectedDeal: IDealCard = {} as unknown as IDealCard;

  route$: Subscription = new Subscription();
  dealTab$: Subscription = new Subscription();
  config$: Subscription = new Subscription();
  theme$: Subscription = new Subscription();
  selectedDealTabs$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;
  config: Config = initialConfigState;
  theme: Theme = initialThemeState;

  constructor(
    private approvalService: ApprovalsService,
    private dealService: DealService,
    private dealTeamMemberService: DealTeamMembersService,
    private subscriptionService: SubscriptionService,
    private syndicationService: SyndicationService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public util: UtilService,
    private appConfig: AppConfigService
  ) {
    this.route$ = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.setActiveLink();
        this.store.dispatch(toggleNavigating({navigating: true}));
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.store.dispatch(toggleNavigating({navigating: false}));
        }, 1000);
      }
    });

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.theme$ = this.store.select(getThemeState).subscribe((theme: any) => {
      this.theme = theme;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      if (!!bu && !!bu.businessUnitId && this.selectedBusinessUnit.businessUnitId != bu.businessUnitId) {
        this.selectedBusinessUnit = bu;
        this.subscribeToDatasource();
      }
    });

    this.selectedDeal$ = this.store.select(getSelectedDeal).subscribe((deal: IDealCard) => {
      if ((this.selectedDeal.id != deal.id) || (this.selectedDeal.dealWorkflow != deal.dealWorkflow)) {
        this.selectedDeal = deal;
        if (!!this.selectedDeal.id) {
          this.fetchDealPermissionsAndDispatch();
        }
      }
    });

    this.selectedDealTabs$ = this.store.select(getSelectedDealTabs).subscribe((tabs: INavItem[]) => {
      this.links = tabs;
    });

  }

  ngOnInit(): void {
    this.store.dispatch(showNotesTab({show: true}));
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    this.store.dispatch(updateDealId({dealId: ''}));
    this.store.dispatch(updateDeal({deal: {} as unknown as IDealCard}));
    this.store.dispatch(showNotesTab({show: false}));
    this.config$.unsubscribe();
    this.theme$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedDeal$.unsubscribe();
    this.dealTab$.unsubscribe();
    this.selectedDealTabs$.unsubscribe();
    this.route$.unsubscribe();
  }

  subscribeToDatasource() {
    this.getDealIdFromRoute();
    // this.getDeal();
  }

  /**
   * Get the deal and update the store
   */
  private fetchDealAndDispatch() {
    if (!!this.dealId) {
      this.store.dispatch(toggleLoading({loading: true}));
      // fetch the deal
      this.dealService.getDeal(this.dealId).subscribe({
        next: (deal: IDealCard) => {
          this.selectedDeal = deal;
          this.store.dispatch(updateDeal({deal: deal}));
          this.store.dispatch(updateDealId({dealId: deal.id}));
          this.fetchDealPermissionsAndDispatch();
        }, error: (error: any) => {
          this.store.dispatch(toggleLoading({loading: false}));
          this.toastr.error("Unable to get deal!", $localize`:@@companyName:Rondeivu`);
        }
      });
    }
  }

  /**
   * Get the deal and update the store
   */
  private fetchDealPermissionsAndDispatch() {
    if (!!this.dealId) {
      this.store.dispatch(toggleLoading({loading: true}));
      // fetch the deal permissions
      this.dealTeamMemberService.getDealTeamMemberPermissions(this.selectedDeal.id).subscribe((role: IBusinessUnitRole) => {
        // fetch the deal tabs
        this.appConfig.getDealTabsByDealId(this.selectedDeal.id).subscribe({
          next: (tabs: INavItem[]) => {
            this.setActiveLink();
            // dispatch all
            this.store.dispatch(updateDealRole({dealRole: role}));
            this.store.dispatch(updateDealTabs({tabs: tabs}));
            this.toastr.success("Deal Tabs Loaded!", $localize`:@@companyName:Rondeivu`);
            this.store.dispatch(toggleLoading({loading: false}));
          }, error: (err: any) => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to get deal tabs!", $localize`:@@companyName:Rondeivu`);
          }
        });
      });
    }
  }


  private getDealIdFromRoute() {
    this.dealId = this.route.snapshot.paramMap.get('id');
    if (!!this.dealId) {
      this.store.dispatch(updateDealId({dealId: this.dealId}));
    }
  }

  navigate(obj: any) {
    if (obj.enabled) {
      this.store.dispatch(toggleNavigating({navigating: true}));
      this.animationState = false;
      setTimeout(() => {
        this.router.navigate(['/' + this.config.selected_business_unit.slug + '/deals/' + this.dealId + obj.link]).then(() => {
          this.store.dispatch(toggleNavigating({navigating: false}));
          this.animationState = true;
        });
      }, 400);
    }
  }

  goBack() {
    this.store.dispatch(toggleNavigating({navigating: true}));
    this.animationState = false;
    setTimeout(() => {
      let path = '/deals';
      path = '/' + this.config.selected_business_unit.slug + path;
      this.router.navigate([path], {queryParams: {status: 'live'}}).then(() => {
        this.animationState = true;
        this.store.dispatch(toggleNavigating({navigating: false}));
      });
    }, 400);
  }

  /**
   * Sets the current deal tab link from the url fragment
   */
  setActiveLink() {
    const fragments = this.router.url.split('/');
    const link = '/' + fragments[fragments.length - 1];
    this.links.forEach(l => {
      if (l.link == link) {
        this.activeLink = l;
      }
    });
  }

  allowApproval() {
    if (!!this.selectedDeal) {
      if (!!this.config.selected_business_unit && !!this.config.selected_business_unit.businessUnitType) {
        return this.selectedDeal.dealWorkflow == DealWorkFlow.PENDING && this.config.selected_business_unit.businessUnitType.toLowerCase() == 'admin';
      }
    }
    return false;
  }

  investorDecline(d: any) {
    const declineRef = this.dialog.open(DeclineModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      data: {},
      disableClose: true
    });

    declineRef.afterClosed().subscribe((result: any) => {
      // console.log("Dialog output:", result);
      if (!!result) {
        let reason = !!result.reason ? result.reason : '';
        this.store.dispatch(toggleLoading({loading: true}));
        this.dealService.declineDeal(reason).subscribe({
          next: (res: any) => {
            this.router.navigate(['/' + this.config.selected_business_unit.slug + '/deals']);
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("Declined!", $localize`:@@companyName:Rondeivu`);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to decline deal!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  investorSignNda(d: any) {
    this.router.navigate(['/' + this.config.selected_business_unit.slug + '/deals/' + this.dealId + '/nda']);
  }

  /**
   * Action for indicate interest
   * @param d
   */
  investorIndicateInterest(d: any) {
    const iiRef = this.dialog.open(IndicateInterestModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      maxWidth: '800px',
      data: {},
      disableClose: true
    });

    iiRef.afterClosed().subscribe((result: any) => {
      // console.log("Dialog output:", result);
      if (!!result) {
        this.store.dispatch(toggleLoading({loading: true}));
        this.syndicationService.indicateInterest(result).subscribe({
          next: (res: any) => {
            this.getSyndication();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("Interest Indicated!", $localize`:@@companyName:Rondeivu`);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to indicate interest!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  /**
   * Action for investor subscription
   * @param d
   */
  investorSubscribe(d: any) {
    const subRef = this.dialog.open(SubscriptionModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      data: {},
      disableClose: true
    });

    subRef.afterClosed().subscribe((result: any) => {
      // console.log("Dialog output:", result);
      if (!!result) {
        this.store.dispatch(toggleLoading({loading: true}));
        this.subscriptionService.subscribe(result).subscribe({
          next: (res: any) => {
            this.getSubscription();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("Subscribed!", $localize`:@@companyName:Rondeivu`);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to subscribe!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  private getSubscription() {
    console.log("Fetching Subscription...");
    this.subscriptionService.getSubscription().subscribe({
      next: (subscription: any) => {
        this.store.dispatch(updateDealSubscription({dealSubscription: subscription}));
      }, error: err => {
        this.toastr.error("Unable to get subscription!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  private getSyndication() {
    console.log("Fetching Syndication...");
    this.syndicationService.getSyndication().subscribe({
      next: (syndication: any) => {
        this.store.dispatch(updateDealSyndication({dealSyndication: syndication}));
      }, error: err => {
        this.toastr.error("Unable to get syndication!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  /**
   * Approve a deal
   * @param d deal
   */
  approveDeal(d: any) {
    this.sendApproval("Deal Approved!", {id: d.id, type: "deal", status: "APPROVED"});
  }

  /**
   * Decline a deal
   * @param d deal
   */
  declineDeal(d: any) {
    this.sendApproval("Deal Declined!", {id: d.id, type: "deal", status: "DECLINED"});
  }

  private sendApproval(msg: string, payload: IApprovalRequest) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.loading = true;
    setTimeout(() => {
      this.approvalService.addApproval(payload).subscribe({
        next: (res: { status: string }) => {
          // reload the store deal
          this.fetchDealAndDispatch();
          this.loading = false;
          this.store.dispatch(toggleLoading({loading: false}));
          this.toastr.success(msg, $localize`:@@companyName:Rondeivu`);
        }, error: err => {
          this.loading = false;
          this.store.dispatch(toggleLoading({loading: false}));
          this.toastr.error("Something went wrong!");
        }
      });
    }, 1000);
  }

  getTaskBg(task: ITask) {
    // switch on status
    switch (task.status?.toLowerCase()) {
      case 'task':
        return this.theme.tasks.task;
      case 'complete':
        return this.theme.tasks.complete;
      case 'pending':
        return this.theme.tasks.pending;
      case 'info':
        return this.theme.tasks.info;
      default:
        return '';
    }
  }

  getTaskImage(status: string) {
    // switch on status
    switch (status?.toLowerCase()) {
      case 'task':
        return 'pending_actions';
      case 'complete':
        return 'task_alt';
      case 'pending':
        return 'schedule';
      case 'info':
        return 'info_outline';
      default:
        return 'pending_action';
    }
  }

  /**
   * Navigate to the route according to the task
   * @param task
   */
  goToTask(task: ITask) {
    let link = ''
    if (!!task) {
      // if an action exists then dispatch it
      if (!!task.action) {
        this.store.dispatch({type: task.action});
      }
      // routing construction
      if (!!task.businessUnitSlug && !!task.route) {
        link = task.businessUnitSlug + '/' + task.route;
        if (!!task.routeId) {
          link += '/' + task.routeId;
          if (!!task.fragment) {
            link += '/' + task.fragment;
          }
        }
        if (!!task.fragment) {
          this.toastr.warning("Task Error: No Object Id", $localize`:@@companyName:Rondeivu`);
        }
      } else {
        this.toastr.warning("Task Error: No Business Unit", $localize`:@@companyName:Rondeivu`);
      }
      // task routing
      if (link != '') {
        if (!!task.queryParams) {
          this.router.navigate([link], {queryParams: task.queryParams});
        } else {
          this.router.navigate([link]);
        }
      }
    }
  }
}
