import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';


@Injectable({
  providedIn: 'root'
})
export class UuidService {
  public uuidValue = '';

  constructor() { }

  public generateUUID(){
    this.uuidValue=UUID.UUID();
    return this.uuidValue;
  }
}
