<div style="height:calc(100vh - 64px); overflow-y: auto;">
  <div class="container-fluid">
    <br/><br/>
    <div class="row justify-content-center">
      <div class="col-6 col-md-5">
        <div style="display: flex;">
          <mat-icon color="primary" class="rm-header-icon">business</mat-icon>&nbsp;
          <span class="rm-title rm-title-3">My Company</span>
        </div>
      </div>
      <div class="col-6 text-end">
        &nbsp;
      </div>
      <div class="col-12 col-md-11">
        <span class="rm-title rm-title-6 rm-lighter">Manage and modify your employees, disable access for added security, and customize employee information and permissions here.</span>
      </div>
    </div>
    <br/><br/>
    <!--    card start-->
    <div class="row justify-content-center" style="padding-bottom: 25px;">
      <div class="col-12 col-md-11">
        <app-user-card [firstName]="selectedBusinessUnit.displayName"
                       [displayName]="selectedBusinessUnit.slug"
                       [borderColor]="'transparent'"
                       [sideColor]="'#ff0000'"
                       [size]="userCardSize.XL"
                       [image]="selectedBusinessUnit.imageUrl"
        ></app-user-card>
      </div>
    </div>
    <!--    image actions start-->
    <div class="row justify-content-center" style="padding-bottom: 50px;">
      <div class="col-12 col-md-11 text-start">
        <button type="button"
                color="primary"
                mat-raised-button
                (click)="openImageUploadModal()"
                [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_COMPANY_LOGO)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_COMPANY_LOGO) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_COMPANY_LOGO) ? util.NoAccessMsg : ''"
                matTooltipPosition="right"
        >UPLOAD IMAGE
        </button>&nbsp;
        <button type="button"
                color="warn"
                mat-raised-button
                (click)="clearImage()"
                [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_COMPANY_LOGO)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_COMPANY_LOGO) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_COMPANY_LOGO) ? util.NoAccessMsg : ''"
                matTooltipPosition="right"
        >CLEAR IMAGE
        </button>
      </div>
    </div>
    <!--    add btn-->
    <div class="row justify-content-center d-flex d-md-none">
      <div class="col-12 col-md-11 text-start">
        <button mat-raised-button
                color="primary"
                style="height: auto;width: auto;"
                (click)="addEmployee()"
                [disabled]="!util.hasPermission(util.Permission.CAN_ADD_COMPANY_EMPLOYEES)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_COMPANY_EMPLOYEES) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_ADD_COMPANY_EMPLOYEES) ? util.NoAccessMsg : ''"
                matTooltipPosition="left"
        >
          <mat-icon>person_add</mat-icon>&nbsp;ADD EMPLOYEE
        </button>
      </div>
    </div>

    <!--    table start-->
    <div class="row justify-content-center d-none d-md-flex" style="padding-bottom: 150px;">
      <div class="col-12 col-md-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <table mat-table [dataSource]="dataSource"
                     [multiTemplateDataRows]="true"
                     matSort
                     (matSortChange)="sortData($event)"
              >
                <!-- Search Header -->
                <ng-container matColumnDef="table-name">
                  <th colspan="3" mat-header-cell *matHeaderCellDef>
                    <div style="display: flex; justify-content: space-between;">
                      <div><span style="font-size: large; line-height: 36px;">Company Employees</span></div>
                      <div>
                        <button mat-raised-button
                                color="primary"
                                style="margin-right: 20px;"
                                (click)="addEmployee()"
                                [disabled]="!util.hasPermission(util.Permission.CAN_ADD_COMPANY_EMPLOYEES)"
                                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_COMPANY_EMPLOYEES) ? util.NoAccessCursor: ''}"
                                [matTooltip]="!util.hasPermission(util.Permission.CAN_ADD_COMPANY_EMPLOYEES) ? util.NoAccessMsg : ''"
                                matTooltipPosition="left"
                        >
                          <mat-icon>person_add</mat-icon>&nbsp;ADD EMPLOYEE
                        </button>
                      </div>
                    </div>
                  </th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="search-header">
                  <th colspan="3" mat-header-cell *matHeaderCellDef>
                    <form>
                      <mat-form-field style="width: 100%;">
                        <mat-label>Search Employee</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" #input>
                        <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                    </form>
                  </th>
                </ng-container>
                <!-- Employee Column -->
                <ng-container matColumnDef="employee">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="employee"> Employee</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    <app-user-card [firstName]="element.user?.['firstName'] "
                                   [lastName]="element.user?.['lastName']"
                                   [displayName]="element.user?.['email']"
                                   [borderColor]="'transparent'"
                                   [sideColor]="'#002150'"
                                   [image]="element.user?.['imageUrl']"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- Title Column -->
                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="title"> Title</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{element['title']}}
                  </td>
                </ng-container>
                <!-- Role Column -->
                <ng-container matColumnDef="role">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="role"> Role</th>
                  <td mat-cell *matCellDef="let element"> {{element["role"]}}
                  </td>
                </ng-container>
                <!-- Member Invite Column -->
                <ng-container matColumnDef="invite">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="status"> Invite Status</th>
                  <td mat-cell *matCellDef="let element">
                    <span [appStatus]="element.memberInviteStatus">{{element.memberInviteStatus}} </span>
                  </td>
                </ng-container>
                <!-- Active Status Column -->
                <ng-container matColumnDef="active">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="active"> Employee Status</th>
                  <td mat-cell *matCellDef="let element">
                    <span
                      [appStatus]="element.isEmployeeActive ? 'Active':'Suspended'">{{element.isEmployeeActive ? 'Active' : 'Suspended'}} </span>
                  </td>
                </ng-container>
                <!-- Actions Column -->
                <ng-container matColumnDef="edit">
                  <th mat-header-cell *matHeaderCellDef class="text-center"> Actions</th>
                  <td mat-cell *matCellDef="let element" class="text-center">
                    <div style="display: inline-flex;">
                      <button color="primary"
                              mat-icon-button
                              (click)="viewInfo(element)"
                              [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_COMPANY_EMPLOYEES)"
                              [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_COMPANY_EMPLOYEES) ? util.NoAccessCursor: ''}"
                              [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_COMPANY_EMPLOYEES) ? util.NoAccessMsg : 'Employee Account'"
                              matTooltipPosition="left"
                      >
                        <mat-icon>account_circle</mat-icon>
                      </button>
                      <button mat-icon-button
                              (click)="viewPermissions(element)"
                              [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_COMPANY_EMPLOYEES)"
                              [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_COMPANY_EMPLOYEES) ? util.NoAccessCursor: ''}"
                              [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_COMPANY_EMPLOYEES) ? util.NoAccessMsg : 'Employee Permissions'"
                              matTooltipPosition="left"
                      >
                        <mat-icon>settings</mat-icon>
                      </button>
                      <button mat-icon-button
                              color="warn"
                              (click)="delete(element)"
                              [disabled]="!util.hasPermission(util.Permission.CAN_DELETE_COMPANY_EMPLOYEES)"
                              [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_DELETE_COMPANY_EMPLOYEES) ? util.NoAccessCursor: ''}"
                              [matTooltip]="!util.hasPermission(util.Permission.CAN_DELETE_COMPANY_EMPLOYEES) ? util.NoAccessMsg : ''"
                              matTooltipPosition="left"
                      >
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="['table-name','search-header']"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"></tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="6">No data matching the filter.</td>
                </tr>
              </table>
            </div>
          </div>
          <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[5, 10, 25, 100]"
                         aria-label="Select page of users"></mat-paginator>
        </div>
      </div>
    </div>

    <!--    list start-->
    <div class="row justify-content-center d-flex d-md-none" style="padding-bottom: 150px; padding-top: 15px;">
      <div class="col-12 col-md-11">
        <ul style="list-style-type: none; padding: 25px 0 0 0;">
          <li *ngFor="let element of dataSource.data"
              style="margin-bottom: 5px; display: flex; justify-content: space-between;">
            <app-user-card [firstName]="element.user?.['firstName'] "
                           [lastName]="element.user?.['lastName']"
                           [displayName]="element.user?.['email']"
                           [borderColor]="'transparent'"
                           [sideColor]="'#002150'"
                           [image]="element.user?.['imageUrl']"
            ></app-user-card>
            <div style="display: inline-flex;">
              <button color="primary"
                      mat-icon-button
                      (click)="viewInfo(element)"
                      [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_COMPANY_EMPLOYEES)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_COMPANY_EMPLOYEES) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_COMPANY_EMPLOYEES) ? util.NoAccessMsg : 'Employee Account'"
                      matTooltipPosition="left">
                <mat-icon>account_circle</mat-icon>
              </button>
              <button mat-icon-button
                      (click)="viewPermissions(element)"
                      matTooltip="Employee Permissions"
                      matTooltipPosition="right">
                <mat-icon>settings</mat-icon>
              </button>
              <button mat-icon-button
                      (click)="delete(element)"
                      [disabled]="!util.hasPermission(util.Permission.CAN_DELETE_COMPANY_EMPLOYEES)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_DELETE_COMPANY_EMPLOYEES) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_DELETE_COMPANY_EMPLOYEES) ? util.NoAccessMsg : ''"
                      matTooltipPosition="left"
              >
                <mat-icon color="warn">delete</mat-icon>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
