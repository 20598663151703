import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  Config,
  getConfig,
  getEditingBusinessUnit,
  getSelectedBusinessUnit,
  initialConfigState,
  initialThemeState,
  Theme, toggleLoading
} from "../../../redux";
import {ToastrService} from "ngx-toastr";
import {Store} from "@ngrx/store";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {BusinessUnitMandatesService} from "../../services";
import {IBusinessUnit} from "../../models";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {MandateModalComponent} from "../../../mandates/components";
import {MatDialog} from "@angular/material/dialog";
import {ModalSize, UtilService} from "../../../shared/services/util/util.service";
import {iMandateResponse} from "../../../mandates/models";
import {toggleNavigating} from "../../../redux/stores/config/config.actions";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-business-unit-mandates',
  templateUrl: './business-unit-mandates.component.html',
  styleUrls: ['./business-unit-mandates.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BusinessUnitMandatesComponent implements OnInit, OnDestroy {

  private readonly DEFAULT_LOAD_TIME = environment.rondeivu_default_load_delay_ms;

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  editingBusinessUnit$: Subscription = new Subscription();

  buRequest: IBusinessUnit = {} as unknown as IBusinessUnit;

  dataSource: MatTableDataSource<iMandateResponse> = new MatTableDataSource();
  mandates: iMandateResponse[] = [];
  sortedMandates: iMandateResponse[] = [];
  config: Config = initialConfigState;
  theme: Theme = initialThemeState;
  columnsToDisplay = ['employee', 'f-aum', 'ticket', 'duration', 'created', 'status', 'edit'];

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  adminBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;
  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;

  constructor(
    private businessUnitMandatesService: BusinessUnitMandatesService,
    private toastr: ToastrService,
    private store: Store,
    private router: Router,
    private dialog: MatDialog,
    public util: UtilService
  ) {
    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.adminBusinessUnit = Object.assign({}, bu);
    });

    this.editingBusinessUnit$ = this.store.select(getEditingBusinessUnit).subscribe((sbu: IBusinessUnit) => {
      if (!!sbu && this.selectedBusinessUnit.businessUnitId != sbu.businessUnitId) {
        this.selectedBusinessUnit = Object.assign({}, sbu);
        if (!!this.adminBusinessUnit && !!this.adminBusinessUnit.businessUnitId &&
          !!this.selectedBusinessUnit && !!this.selectedBusinessUnit.businessUnitId &&
          this.adminBusinessUnit.businessUnitType == 'ADMIN') {
          this.subscribeToDatasource();
        }
      }
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.editingBusinessUnit$.unsubscribe();
  }

  subscribeToDatasource(): void {
    console.log("Fetching Business Unit Mandates...");
    this.getMandates();
  }

  getMandates() {
    this.businessUnitMandatesService.getMandates().subscribe({
      next: (mandates: iMandateResponse[]) => {
        this.mandates = mandates;
        this.dataSource = new MatTableDataSource(this.mandates);
        this.initDataSource();
      }, error: (err: any) => {
        this.toastr.error("Unable to get mandates!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  initDataSource() {
    this.dataSource.filterPredicate = (data: iMandateResponse, filter: string) => {
      const lc_filter = filter.toLowerCase();
      const EMP = (data.createdBy.firstName + ' ' + data.createdBy.lastName).toLowerCase().includes(lc_filter);
      const EMAIL = data.createdBy.email.toLowerCase().includes(lc_filter);
      return EMP || EMAIL;
    };
    if (!!this.paginator) {
      this.paginator.pageSize = 10;
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  viewMandate(m: any) {
    this.store.dispatch(toggleNavigating({navigating: true}));
    setTimeout(() => {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/mandates/' + m.id]).then(() => {
        this.store.dispatch(toggleNavigating({navigating: false}));
      });
    }, this.DEFAULT_LOAD_TIME);
  }

  /**
   * Mandate modal
   */
  openMandateModal() {
    const mandateModalRef = this.dialog.open(MandateModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.MEDIUM),
      disableClose: true
    });

    mandateModalRef.afterClosed().subscribe((result: any) => {
      // console.log(`Dialog result: ${result}`);
      if (!!result) {
        console.log("Creating mandate...");
        this.store.dispatch(toggleLoading({loading: true}));
        this.businessUnitMandatesService.addMandate(result).subscribe({
          next: (res: any) => {
            this.subscribeToDatasource();
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.success("Mandate Created!", $localize`:@@companyName:Rondeivu`);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to create mandate!");
          }
        });
      }
    });
  }

  //sort
  sortData(sort: Sort) {
    const data = this.mandates.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedMandates = data;
      return;
    }

    this.sortedMandates = data.sort((a: iMandateResponse, b: iMandateResponse) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'employee':
          const n1 = a.createdBy.firstName + ' ' + a.createdBy.lastName;
          const n2 = b.createdBy.firstName + ' ' + b.createdBy.lastName;
          return compare(n1, n2, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'created':
          return compare(a.created, b.created, isAsc);
        default:
          return 0;
      }
    });

    this.dataSource = new MatTableDataSource(this.sortedMandates);
    this.initDataSource();
  }

}


function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
