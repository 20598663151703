
<!-- <div [@fadeIn]="animationState" (@fadeIn.start)="animStart($event)" > -->
<div>
  <!-- <div [@slideOutLeft]="animateExit" (@slideOutLeft.done)="exitEnd($event)"> -->
  <div>
<!--    <mat-toolbar id="toolbar" dense [ngStyle]="{'background-color':theme.subheader_bg, 'color':theme.subheader_fg}">-->
<!--      <button  mat-button (click)="goBack()">-->
    <!--        <mat-icon>list</mat-icon>-->
    <!--      </button>-->
<!--      &nbsp;-->
<!--      <span id="deal-header">-->
<!--&lt;!&ndash;        {{selectedUser?.family_name + ' ' + selectedUser?.given_name +" || " + selectedUser?.email}}&ndash;&gt;-->
<!--      </span>-->
<!--    </mat-toolbar>-->
    <nav id="deal-nav" dense
         mat-tab-nav-bar
         [tabPanel]="tabPanel"
         style="width: 100%"
         [ngStyle]="{'background-color': theme.subheader_bg}"
    >
      <a
        mat-tab-link
        *ngFor="let link of links"
        (click)="activeLink = link; navigate(link);"
        [active]="activeLink.link == link.link"
        [disabled]="!link.enabled"
        [hidden]="link.hidden"
        [ngStyle]="{'background-color':theme.content_bg, 'color':theme.content_fg}"
      >
        {{ link.title }}
      </a>
    </nav>
    <mat-tab-nav-panel #tabPanel>
      <div [@appFade]="animationState">
        <router-outlet></router-outlet>
      </div>
    </mat-tab-nav-panel>
  </div>
</div>

