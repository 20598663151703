import { Injectable } from '@angular/core';
import {Store} from "@ngrx/store";
import {RondeivuHttpClient} from "../../../../handlers";
import {UuidService} from "../../../../services";
import {Config, getConfig, initialConfigState} from "../../../redux";
import {environment} from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  private config: Config = initialConfigState;
  private readonly BASE_URL = environment.rondeivu_api;
  private readonly FRAGMENT = '/subscription';
  private readonly SUBSCRIBE = '/subscribe';
  private readonly LOCK = '/change-lock-status';

  constructor(private store: Store, private http: RondeivuHttpClient, private uuid: UuidService) {
    this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
  }

  /**
   * Get the subscription data
   */
  getSubscription(){
    return this.http.get(this.BASE_URL + this.FRAGMENT + '/' + this.config.selected_deal_id);
  }

  /**
   * When the investor indicates interest on evergreen deals
   */
  subscribe(subscription:{id:string, subscriptionAmount:number, notes:string}){
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.SUBSCRIBE + '/' + this.config.selected_deal_id, subscription);
  }

  /**
   * Remove a subscription
   */
  deleteSubscription(subscription:any){
    return this.http.delete(this.BASE_URL + this.FRAGMENT + '/' + subscription.id);
  }

  /**
   * Update a subscription
   */
  updateSubscription(subscription:any){
    return this.http.put(this.BASE_URL + this.FRAGMENT  + '/' + subscription.id, subscription);
  }

  /**
   * Change the subscription lock status
   */
  changeLockStatus(lock:any){
    return this.http.put(this.BASE_URL + this.FRAGMENT + this.LOCK + '/' + this.config.selected_deal_id, lock);
  }
}
