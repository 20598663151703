import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  Config,
  fetchTasks,
  getConfig,
  getSelectedBusinessUnit,
  initialConfigState,
  toggleLoading
} from "../../../redux";
import {IDealCard} from "../../../shared/models";
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {MockDealCardService} from "../../services";
import {DealService} from "../../../deals/services";
import {IBusinessUnit} from "../../../business-units/models";
import {Subscription} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {DealModalComponent} from "../../../deals/components";
import {MandateModalComponent} from "../../../mandates/components";
import {MandateService} from "../../../mandates/services";
import {ToastrService} from "ngx-toastr";
import {UtilService} from "../../../shared/services";
import {ModalSize} from "../../../shared/services/util/util.service";

@Component({
  selector: 'app-live-deals',
  templateUrl: './live-deals.component.html',
  styleUrls: ['./live-deals.component.scss']
})
export class LiveDealsComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();

  readonly types: string[] = ['live', 'syndication', 'allocation'];
  config: Config = initialConfigState;
  deals: IDealCard[] = [];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private store: Store,
              private dealCardService: MockDealCardService,
              private dealService: DealService,
              private mandateService: MandateService,
              private dialog: MatDialog,
              private toastr: ToastrService,
              public util: UtilService
  ) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      if (!!bu && !!bu.businessUnitId) {
        this.loadDeals();
      }
    });
  }


  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
  }

  getDate() {
    return new Date().toLocaleDateString('en-US');
  }

  loadDeals() {
    console.log("Fetching live deals...");
    this.dealService.getDeals(this.types.join(',')).subscribe((deals: IDealCard[]) => {
      // console.log(JSON.stringify(deals));
      this.deals = Object.assign([], deals);
    });
  }

  /**
   * Deal Modal
   */
  openDealModal() {
    const dealModalRef = this.dialog.open(DealModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.SMALL),
      disableClose: true
    });

    dealModalRef.afterClosed().subscribe((result: any) => {
      // console.log(`Dialog result: ${result}`);
      if (!!result) {
        console.log("Creating deal...");
        this.store.dispatch(toggleLoading({loading: true}));
        this.dealService.addDeal(result).subscribe({
          next: (res: IDealCard) => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.store.dispatch({type: fetchTasks});
            this.toastr.success("Deal Created!", $localize`:@@companyName:Rondeivu`);
            this.router.navigate(['/' + this.config.selected_business_unit.slug + '/deals']);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to create deal!", $localize`:@@companyName:Rondeivu`);
          }
        });
      }
    });
  }

  /**
   * Mandate modal
   */
  openMandateModal() {
    const mandateModalRef = this.dialog.open(MandateModalComponent, {
      height: 'auto',
      width: this.util.getModalWidth(ModalSize.MEDIUM),
      disableClose: true
    });

    mandateModalRef.afterClosed().subscribe((result: any) => {
      // console.log(`Dialog result: ${result}`);
      if (!!result) {
        console.log("Creating mandate...");
        this.store.dispatch(toggleLoading({loading: true}));
        this.mandateService.addMandate(result).subscribe({
          next: (res: any) => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.store.dispatch({type: fetchTasks});
            this.toastr.success("Mandate Created!", $localize`:@@companyName:Rondeivu`);
            this.router.navigate(['/' + this.config.selected_business_unit.slug + '/mandates']);
          }, error: err => {
            this.store.dispatch(toggleLoading({loading: false}));
            this.toastr.error("Unable to create mandate!");
          }
        });
      }
    });
  }

}
