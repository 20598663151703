import {Injectable, OnDestroy} from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot, Router
} from '@angular/router';
import {from, Observable, Subscription} from 'rxjs';
import {Location} from "@angular/common";
import {ToastrService} from "ngx-toastr";
import {AppConfigService} from "../services";
import {Store} from "@ngrx/store";
import {DealService, DealTeamMembersService} from "../modules/deals/services";
import {
  Config, getConfig,
  initialConfigState,
  updateDeal, updateDealId, updateDealRole,
  updateDealTabs,

} from "../modules/redux";
import {IDealCard} from "../modules/shared/models";
import {INavItem} from "../models";
import {IBusinessUnitRole} from "../modules/business-units/models";

@Injectable({
  providedIn: 'root'
})
export class DealResolver implements Resolve<boolean>, OnDestroy {
  private readonly LOGIN_PATH = '/auth/login';
  config$: Subscription = new Subscription();
  config: Config = initialConfigState;
  dealId = '';

  constructor(
    private router: Router,
    private location: Location,
    private toastr: ToastrService,
    private dealService: DealService,
    private dealTeamMemberService: DealTeamMembersService,
    private appConfig: AppConfigService,
    private store: Store) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    console.log("Running deal resolver!");
    this.dealId = route.paramMap.get('id') || '';
    this.store.dispatch(updateDealId({dealId: this.dealId}));
    return from(this.fetchAndDispatchDeal(route, state));
  }

  private fetchAndDispatchDeal(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      //deal already exists in the store
      if (!!this.config.selected_deal) {
        console.log("Deal resolver found existing deal...");
        resolve(true);
      } else {
        console.log("Deal resolver fetching deal...");
        this.dealService.getDeal(this.dealId).subscribe({
          next: (deal: IDealCard) => {
            this.store.dispatch(updateDeal({deal: deal}));
            this.fetchAndDispatchDealTabs(route, state).then((success: boolean) => {
              if (success) {
                this.fetchAndDispatchDealRole(route, state).then((allow: boolean) => {
                  if (allow) {
                    resolve(true);
                  } else {
                    reject(false);
                  }
                });
              } else {
                reject(false);
              }
            });
          }, error: (error: any) => {
            reject(false);
          }
        });
      }
    });
  }

  private fetchAndDispatchDealRole(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      //role already exists in the store
      if (!!this.config.selected_deal_role) {
        resolve(true);
      } else {
        console.log("Deal resolver fetching deal role...");
        this.dealTeamMemberService.getDealTeamMemberPermissions(this.dealId).subscribe({
          next: (role: IBusinessUnitRole) => {
            this.store.dispatch(updateDealRole({dealRole: role}));
            resolve(true);
          }, error: err => {
            resolve(false);
          }
        });
      }
    });
  }

  private fetchAndDispatchDealTabs(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      //tabs already exist in the store
      // if (!!this.config.deal_tabs && (this.config.deal_tabs.length > 0)) {
      //   resolve(true);
      // } else {
        console.log("Deal resolver fetching deal tabs...");
        this.appConfig.getDealTabsByDealId(this.dealId).subscribe({
          next: (tabs: INavItem[]) => {
            this.store.dispatch(updateDealTabs({tabs: tabs}));
            resolve(true);
          }, error: (error: any) => {
            resolve(false);
          }
        });
      // }
    });
  }
}
