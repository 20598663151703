import { Injectable } from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {Observable} from "rxjs";
import {RondeivuHttpClient} from "../../../../handlers";
import {
  CognitoUser,
  Config,
  getCognitoUser,
  getConfig,
  initialCognitoUserState,
  initialConfigState
} from "../../../redux";
import {Store} from "@ngrx/store";
import {HttpHeaders} from "@angular/common/http";
import {IAppUserRequest, IUsersRequest} from "../../models/i-users";

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private readonly BASE_URL = environment.rondeivu_api;
  private readonly FRAGMENT = '/users';
  private readonly BU_FOR_USERS = '/businessunitsforusers';
  private readonly FORCE_PR = '/force-password-reset';
  private readonly USER_PR = '/user-password-reset';
  private readonly UPLOAD = '/upload-image';
  private readonly RESET = '/reset-image';
  private readonly ACTIVITY = '/useractivity';
  private readonly EMAIL_NOTIFICATION = '/email-notification';
  private readonly SET_2FA = '/set-2fa';
  private readonly RESET_2FA = '/reset-2fa';


  private config: Config = initialConfigState;
  private cognito: CognitoUser = initialCognitoUserState;

  constructor(private http: RondeivuHttpClient, private store: Store) {
    this.store.select(getConfig).subscribe(c => {
      this.config = c;
    });
    this.store.select(getCognitoUser).subscribe(cu => {
      this.cognito = cu;
    });
  }

  /**
   * Get a list of all users
   */
  getUsers():Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT);
  }

  /**
   * Get the user by stored id
   */
  getUser():Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + '/' + this.config.selected_user_id);
  }

  /**
   * Get a user by id
   */
  getUserById(id:string):Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + '/' + id);
  }

  /**
   * Add a new user
   * @param user
   */
  addUser(user: IUsersRequest): Observable<any> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.BASE_URL + this.FRAGMENT, user, {headers: httpHeaders});
  }

  /**
   * Update an app user
   * @param user
   */
  updateUser(user: IAppUserRequest): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + '/' + user.id, user);
  }

  /**
   * Get the business units for an app user
   * @param appUserId
   */
  getBusinessUnitsForUser(appUserId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.BU_FOR_USERS + '/' + appUserId);
  }

  /**
   * Force password reset for app user
   * @param appUserId
   */
  forcePasswordReset(appUserId: string) {
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.FORCE_PR + '/' + appUserId, '');
  }

  /**
   * User password reset for app user
   * @param appUserId
   */
  userPasswordReset(appUserId: string) {
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.USER_PR + '/' + appUserId, '');
  }

  /**
   * Upload the user image
   */
  uploadUserImage(file: any, appUserId: string): Observable<any> {
    let formData = new FormData();
    formData.append("formFile", file);
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.UPLOAD + '/' + appUserId, formData);
  }

  /**
   * Clears the user image
   * @param appUserId
   */
  clearUserImage(appUserId: string) {
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.RESET + '/' + appUserId, {});
  }

  /**
   * Get the user app activity
   * @param appUserId
   */
  getUserActivity(appUserId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.ACTIVITY + '/' + appUserId);
  }

  /**
   * Get the user email notifications
   * @param appUserId
   */
  getUserEmailNotifications(appUserId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.EMAIL_NOTIFICATION + '/' + appUserId);
  }

  set2Fa(appUserId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.SET_2FA + '/' + appUserId);
  }

  reset2Fa(appUserId: string): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + this.RESET_2FA + '/' + appUserId, {});
  }


}
