import {animate, AnimationTriggerMetadata, state, style, transition, trigger} from "@angular/animations";

export function AppFade(): AnimationTriggerMetadata {
  return trigger('appFade', [
    // ...
    state('true', style({
      backgroundColor: '#fff',
      opacity: 1,
    })),
    state('false', style({
      backgroundColor: '#fff',
      opacity: 0,
    })),
    transition('false => true', [
      animate('0.8s')
    ]),
    transition('true => false', [
      animate('0.5s')
    ]),
  ]);
}
