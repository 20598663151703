import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MandatesRoutingModule } from './mandates-routing.module';
import { MandatesListComponent } from './components/';
import {MockMandatesService} from "./services/mock-mandates/mock-mandates.service";
import {HttpClientModule} from "@angular/common/http";
import { MandatesNavigationComponent } from './components';
import { MandateInfoComponent } from './components';
import {NgMaterialModule} from "../ng-material/ng-material.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "../shared/shared.module";

@NgModule({
  declarations: [
    MandatesListComponent,
    MandatesNavigationComponent,
    MandateInfoComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MandatesRoutingModule,
        HttpClientModule,
        NgMaterialModule,
        SharedModule
    ],
  providers: [MockMandatesService],
})
export class MandatesModule { }
