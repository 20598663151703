<div class="rm-header">
  <span>Rondeivu</span>
</div>
<mat-dialog-content>
  <h1 mat-dialog-title>Sign Attestation</h1>
  <div style="width: 100%;">
    <div class="row justify-content-center">
      <div style="padding-bottom: 25px; padding-left: 25px; padding-right: 25px;">
        <span style="font-size: medium; font-weight: lighter;">{{data.attestationDisplayText}}</span>
      </div>

      <div class="row justify-content-center" *ngFor="let d of data.attestationHtmls"
           style="border-radius:12px; margin:5px; padding: 20px; background-color: rgba(128,128,128,0.1);">
        <div class="col-1">
          <mat-checkbox color="primary"></mat-checkbox>
        </div>
        <div class="col-10">
          <span [innerHTML]="d"></span>
        </div>
      </div>

    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions style="display: flex; justify-content: space-between;">
  <button mat-raised-button (click)="onNoClick()">Logout</button>
  <button mat-raised-button color="primary" (click)="action(data)" cdkFocusInitial [disabled]="!canAction()">Submit
    Attestation
  </button>
</mat-dialog-actions>
