export enum AppStatus {
  PENDING = 'PENDING',
  ONBOARDING = 'ONBOARDING',
  DECLINED = 'DECLINED',
  APPROVED = 'APPROVED',
  LIVE = 'LIVE',
  CLOSED = 'CLOSED',
  COMPLETED = 'COMPLETED',
  SENT = 'SENT',
  NOT_REQUIRED = 'NOT_REQUIRED',
  IN_REVIEW = 'IN_REVIEW',
  CONFIRMED = 'CONFIRMED',
  NOT_REGISTERED = 'NOT_REGISTERED',
  UNCONFIRMED = 'UNCONFIRMED',
  FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',
  ACCEPTED = 'ACCEPTED',
  AUTO_ACCEPTED = 'AUTO_ACCEPTED',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED'
}
