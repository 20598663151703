import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {createReducer, on} from "@ngrx/store";
import * as MandateActions from './mandate.actions';
import {iMandateResponse} from "../../../mandates/models";


export interface MandateState extends EntityState<iMandateResponse> {
  selectedMandateId: string | null;
}

export const adapter: EntityAdapter<iMandateResponse> = createEntityAdapter<iMandateResponse>({
  selectId: selectMandateId,
  sortComparer: sortByName,
});

export const initialMandateState: MandateState = adapter.getInitialState({
  // additional entity state properties
  selectedMandateId: null,
});

export const mandateReducer = createReducer(initialMandateState,
  on(MandateActions.addMandate, (state, {mandate}) => {
    return adapter.addOne(mandate, state)
  }),
  on(MandateActions.setMandate, (state, {mandate}) => {
    return adapter.setOne(mandate, state)
  }),
  on(MandateActions.upsertMandate, (state, {mandate}) => {
    return adapter.upsertOne(mandate, state);
  }),
  on(MandateActions.addMandates, (state, {mandates}) => {
    return adapter.addMany(mandates, state);
  }),
  on(MandateActions.upsertMandates, (state, {mandates}) => {
    return adapter.upsertMany(mandates, state);
  }),
  on(MandateActions.updateMandate, (state, {update}) => {
    return adapter.updateOne(update, state);
  }),
  on(MandateActions.updateMandates, (state, {updates}) => {
    return adapter.updateMany(updates, state);
  }),
  on(MandateActions.mapMandate, (state, {entityMap}) => {
    return adapter.mapOne(entityMap, state);
  }),
  on(MandateActions.mapMandates, (state, {entityMap}) => {
    return adapter.map(entityMap, state);
  }),
  on(MandateActions.deleteMandate, (state, {id}) => {
    return adapter.removeOne(id, state);
  }),
  on(MandateActions.deleteMandates, (state, {ids}) => {
    return adapter.removeMany(ids, state);
  }),
  on(MandateActions.deleteMandatesByPredicate, (state, {predicate}) => {
    return adapter.removeMany(predicate, state);
  }),
  on(MandateActions.loadMandates, (state, {mandates}) => {
    return adapter.setAll(mandates, state);
  }),
  on(MandateActions.setMandates, (state, {mandates}) => {
    return adapter.setMany(mandates, state);
  }),
  on(MandateActions.clearMandates, state => {
    return adapter.removeAll({...state, selectedMandateId: null});
  })
);

export const getSelectedMandateId = (state: MandateState) => state.selectedMandateId;

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of mandate ids
export const selectMandateIds = selectIds;

// select the dictionary of mandate entities
export const selectMandateEntities = selectEntities;

// select the array of mandates
export const selectAllMandates = selectAll;

// select the total mandate count
export const selectMandateTotal = selectTotal;

export function selectMandateId(a: iMandateResponse): string {
  //In this case this would be optional since primary key is id
  return a.id;
}

export function sortByName(a: iMandateResponse, b: iMandateResponse): number {
  if (!a.created || !b.created) {
    return 0;
  }
  return a.created.localeCompare(b.created);
}


