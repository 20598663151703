import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {DealAdminService, DealService} from "../../services";
import {
  Config,
  getConfig,
  getLookups,
  getSelectedBusinessUnit, getSelectedDeal,
  initialConfigState,
  initialLookupsState,
  Lookups,
  toggleLoading, updateDeal, updateDealId
} from "../../../redux";
import {IBusinessUnit} from "../../../business-units/models";
import {IDealAdminSettingsRequest, IDealSettingsRequest, IDealStatusRequest} from "../../models";
import {Subscription} from "rxjs";
import {IDealCard} from "../../../shared/models";
import {UtilService} from "../../../shared/services";

@Component({
  selector: 'app-deal-settings',
  templateUrl: './deal-settings.component.html',
  styleUrls: ['./deal-settings.component.scss']
})
export class DealSettingsComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  dealId$: Subscription = new Subscription();
  selectedDeal$: Subscription = new Subscription();

  dealSetting$: Subscription = new Subscription();
  admin$: Subscription = new Subscription();

  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;
  bu: IBusinessUnit = {} as unknown as IBusinessUnit;
  selectedDeal: IDealCard = {} as unknown as IDealCard;
  dealSettings: IDealSettingsRequest = {} as unknown as IDealSettingsRequest;
  adminSettings: IDealAdminSettingsRequest = {} as unknown as IDealAdminSettingsRequest;

  userForm = new FormGroup({
    id: new FormControl(),
    dealNdaRequirementType: new FormControl('', []),
    dealCloseType: new FormControl('', []),
    secDealType: new FormControl('', []),
    dealInterestType: new FormControl({value: '', disabled: true}, []),
    dealIsMnpi: new FormControl(false, []),
    syndicationDate: new FormControl(),
    closeDate: new FormControl(),
    numberOfIssuerSignatories: new FormControl(1),
    subDocsDefaultTemplateId: new FormControl(),
    subDocsTemplateId: new FormControl()
  });

  adminForm = new FormGroup({
    id: new FormControl(),
    dealWorkflow: new FormControl('', []),
    issuerNdaStatus: new FormControl('', []),
    issuerEngagementAgreementStatus: new FormControl('', []),
    issuerCanEditDealDataroom: new FormControl(false, []),
    investorCanDownloadDataroom: new FormControl(false, []),
    issuerCanEditDealCardInformation: new FormControl(false, [])
  });

  workflowForm = new FormGroup({
    id: new FormControl(),
    dealWorkflow: new FormControl('', [])
  });

  constructor(
    private store: Store,
    private toastr: ToastrService,
    private dealService: DealService,
    private adminService: DealAdminService,
    public util: UtilService
  ) {
    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });

    this.lookup$ = this.store.select(getLookups).subscribe(lookups => {
      this.lookups = lookups;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.bu = Object.assign({}, bu);
    });

    this.selectedDeal$ = this.store.select(getSelectedDeal).subscribe((deal: IDealCard) => {
      this.selectedDeal = deal;
      this.subscribeToDatasource();
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.dealId$.unsubscribe();
    this.selectedDeal$.unsubscribe();
    this.admin$.unsubscribe();
    this.dealSetting$.unsubscribe();
  }

  subscribeToDatasource(): void {
    console.log("Fetching Deal Settings...");
    this.dealSetting$ = this.dealService.getDealSettings().subscribe({
      next: (ds: any) => {
        this.dealSettings = Object.assign({}, ds);
        this.userForm.patchValue(this.dealSettings);
      }, error: (error: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to get deal settings!", $localize`:@@companyName:Rondeivu`);
      }
    });

    //admin settings
    if (this.util.isType('admin')) {
      this.admin$ = this.adminService.getDealAdminSettings().subscribe({
        next: (as: any) => {
          this.adminSettings = Object.assign({}, as);
          this.adminForm.patchValue(this.adminSettings);
          this.workflowForm.patchValue(this.adminSettings);
        }, error: (error: any) => {
          this.store.dispatch(toggleLoading({loading: false}));
          this.toastr.error("Unable to get admin settings!", $localize`:@@companyName:Rondeivu`);
        }
      });
    }
  }

  save() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.dealService.updateDealSettings(this.userForm.getRawValue() as unknown as IDealSettingsRequest).subscribe({
      next: (ds: IDealSettingsRequest) => {
        this.dealSettings = Object.assign({}, ds);
        this.userForm.patchValue(this.dealSettings);
        // reload the store deal
        this.fetchDealAndDispatch();
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Settings Updated!", $localize`:@@companyName:Rondeivu`);
      }, error: (error: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to update settings!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  adminSave() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.adminService.updateDealAdminSettings(this.adminForm.getRawValue() as unknown as IDealAdminSettingsRequest).subscribe({
      next: (as: IDealAdminSettingsRequest) => {
        this.adminSettings = Object.assign({}, as);
        // reload the store deal
        this.fetchDealAndDispatch();
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Admin Settings Updated!", $localize`:@@companyName:Rondeivu`);
      }, error: (error: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to update admin settings!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  workFlowSave() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.adminService.updateDealStatus(this.workflowForm.getRawValue() as unknown as IDealStatusRequest).subscribe({
      next: (status: any) => {
        this.fetchDealAndDispatch();
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Deal Status Updated!", $localize`:@@companyName:Rondeivu`);
      }, error: (error: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to update deal status!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  /**
   * Get the deal and update the store
   */
  private fetchDealAndDispatch() {
    if (!!this.selectedDeal.id) {
      this.store.dispatch(toggleLoading({loading: true}));
      this.dealService.getDeal(this.selectedDeal.id).subscribe({
        next: (deal: IDealCard) => {
          //this will determine the notes load
          this.toastr.success("Deal Reloaded!", $localize`:@@companyName:Rondeivu`);
          this.store.dispatch(updateDeal({deal: deal}));
          this.store.dispatch(updateDealId({dealId: deal.id}));
          this.store.dispatch(toggleLoading({loading: false}));
        }, error: (error: any) => {
          this.store.dispatch(toggleLoading({loading: false}));
          this.toastr.error("Unable to get deal!", $localize`:@@companyName:Rondeivu`);
        }
      });
    }
  }

}
