import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IDealCard} from "../../../shared/models";
import {
  CognitoUser,
  Config,
  getCognitoUser,
  getConfig,
  getSelectedBusinessUnit,
  initialCognitoUserState,
  initialConfigState,
  showDealModal,
  showMandateModal
} from "../../../redux";
import {Store} from "@ngrx/store";
import {MockDealCardService} from "../../services";
import {DealService} from "../../../deals/services";
import {ActivatedRoute, Router} from "@angular/router";
import {IBusinessUnit} from "../../../business-units/models";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-common-dashboard',
  templateUrl: './common-dashboard.component.html',
  styleUrls: ['./common-dashboard.component.scss']
})
export class CommonDashboardComponent implements OnInit, OnDestroy {
  @Input() type: 'live' | 'closed' | 'pending' | undefined;
  config$: Subscription = new Subscription();
  cognito$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();

  config: Config = initialConfigState;
  deals: IDealCard[] = [];
  showDealNotification = false;
  showMandateNotification = true;
  cognito: CognitoUser = initialCognitoUserState;

  constructor(private router: Router, private route: ActivatedRoute,
              private store: Store, private dealCardService: MockDealCardService,
              private dealService: DealService) {

    this.cognito$ = this.store.select(getCognitoUser).subscribe((cognito: any) => {
      this.cognito = cognito;
    });

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
      // this.showDealNotification = config.selected_user_view.toLowerCase() == 'issuer';
      // this.showMandateNotification = config.selected_user_view.toLowerCase() == 'investor';
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      if (!!bu && !!bu.businessUnitId) {
        this.loadDeals();
      }
    });
  }


  ngOnInit(): void {
    // console.log("init type: " + this.type);
    // this.loadDeals();
  }

  ngOnDestroy() {
    this.cognito$.unsubscribe();
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
  }

  getName() {
    let n = '';
    if (!!this.cognito && !!this.cognito.attributes) {
      n = this.cognito.attributes['given_name'] + ' ' + this.cognito.attributes['family_name'];
    }
    return n;
  }

  getDate() {
    return new Date().toLocaleDateString('en-US');
  }

  openModal() {
    if (!!this.config && !!this.config.selected_business_unit && this.config.selected_business_unit.businessUnitType.toLowerCase() === 'investor') {
      this.openMandateModal();
    } else {
      this.openDealModal();
    }
  }

  openMandateModal() {
    this.store.dispatch({type: showMandateModal});
  }

  openDealModal() {
    this.store.dispatch({type: showDealModal});
  }

  loadDeals() {
    this.dealService.getDeals(this.type).subscribe((deals: IDealCard[]) => {
      this.deals = deals;
    });
  }
}
