<div style="overflow-y: auto; height: calc(100vh - 176px);">
  <div class="container-fluid">
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-12 col-md-11">
        <span class="rm-title rm-title-3">Deal Matching</span><br/>
        <span
          class="rm-title rm-title-6 rm-lighter">This is where you can match this deal to one or many mandates.</span>
      </div>
    </div>
    <!-- row start-->
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-12 col-md-11">
        <!--notes-->
        <mat-label style="font-size: medium;">Exclusion List</mat-label>
        <br/>
        <mat-form-field class="form-field" appearance="outline">
                <textarea matInput id="exclusions" name="exclusions" placeholder="Type here..."
                          [(ngModel)]="exclusions"></textarea>
        </mat-form-field>
      </div>
    </div>
    <!--    row start-->
    <div class="row justify-content-center">
      <div class="col-11 text-end">
        <button
          mat-raised-button
          color="primary"
          (click)="saveExclusions(exclusions)"
          [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_INVESTOR_MATCH_EXCLUSION_LIST)"
          [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_INVESTOR_MATCH_EXCLUSION_LIST) ? util.NoAccessCursor: ''}"
          [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_INVESTOR_MATCH_EXCLUSION_LIST) ? util.NoAccessMsg : ''"
          matTooltipPosition="left"
        >
          SAVE CHANGES
        </button>
      </div>
    </div>
    <!--    table start-->
    <div class="row justify-content-center d-none d-md-flex" style="padding-bottom: 150px; padding-top: 15px;">
      <div class="col-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <!--MATCH TABLE-->
              <table style="width: 100%;" mat-table [dataSource]="dataSource" [multiTemplateDataRows]="true" matSort>
                <ng-container matColumnDef="table-name">
                  <th colspan="5" mat-header-cell *matHeaderCellDef>
                    <span style="font-size: large;">Investor Matches</span>
                  </th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="search-header">
                  <th colspan="5" mat-header-cell *matHeaderCellDef>
                    <form>
                      <mat-form-field style="width: 100%;">
                        <mat-label>Search</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" #input/>
                        <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                    </form>
                  </th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef> Id</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.id }}
                  </td>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="businessUnit">
                  <th mat-header-cell *matHeaderCellDef> Investor</th>
                  <td mat-cell *matCellDef="let element" style="padding: 0 5px;">
                    <app-user-card [firstName]="element.businessUnitMiniModel?.['displayName']"
                                   [displayName]="element.businessUnitMiniModel?.['profile']"
                                   [borderColor]="'transparent'"
                                   [sideColor]="'#ff0000'"
                                   [image]="element.businessUnitMiniModel?.['imageUrl']"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="viewBusinessUnit">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-icon-button (click)="viewBusinessUnit(element.businessUnitMiniModel?.id)"
                            matTooltip="View Business Unit" matTooltipPosition="left">
                      <mat-icon>east</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="mandate">
                  <th mat-header-cell *matHeaderCellDef> Mandate</th>
                  <td mat-cell *matCellDef="let element">
                    <div style="width: 100%; text-align: center;">
                      {{ element.mandate?.title }}
                    </div>
                  </td>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="viewMandate">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-icon-button (click)="viewMandate(element.mandate?.mandateId)" matTooltip="View Mandate"
                            matTooltipPosition="left">
                      <mat-icon>east</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="compatability">
                  <th mat-header-cell *matHeaderCellDef> Compatability Score</th>
                  <td mat-cell *matCellDef="let element">
                    <div style="width: 100%; text-align: center;">
                      <span [matTooltip]="element.compatabilityText" matTooltipPosition="right">
                        {{ element.compatabilityScore }}
                      </span>
                    </div>
                  </td>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="internalScore">
                  <th mat-header-cell *matHeaderCellDef> Internal Score</th>
                  <td mat-cell *matCellDef="let element" style="text-align: center;">
                    <mat-select [(ngModel)]="element.internalScore"
                                (ngModelChange)="setInternalScore(element)"
                                [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_INVESTOR_MATCH_INTERNAL_SCORE )"
                                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_INVESTOR_MATCH_INTERNAL_SCORE ) ? util.NoAccessCursor: ''}"
                                [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_INVESTOR_MATCH_INTERNAL_SCORE ) ? util.NoAccessMsg : ''"
                                matTooltipPosition="left"
                    >
                      <mat-option *ngFor="let n of numbers;" [value]="n">{{ n }}</mat-option>
                    </mat-select>
                  </td>
                </ng-container>
                <!-- Anchor Interests Column -->
                <ng-container matColumnDef="anchorInterests">
                  <th mat-header-cell *matHeaderCellDef> Interests</th>
                  <td mat-cell
                      *matCellDef="let element">
                    <ul>
                      <li style="font-size: x-small;" *ngFor="let a of element.mandateAnchorInterests">
                        {{ a }}
                      </li>
                    </ul>
                  </td>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="dateMatched">
                  <th mat-header-cell *matHeaderCellDef> Date Matched</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.dateMatched | date }}
                  </td>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef> Matching</th>
                  <td mat-cell *matCellDef="let element">
                    <div style="display: flex;">
                      <button *ngIf="!element.isMatched"
                              mat-raised-button
                              color="primary"
                              (click)="match(element)"
                              style="width: 100px;"
                              [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_INVESTOR_MATCH )"
                              [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_INVESTOR_MATCH ) ? util.NoAccessCursor: ''}"
                              [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_INVESTOR_MATCH ) ? util.NoAccessMsg : ''"
                              matTooltipPosition="left"
                      >
                        MATCH
                      </button>
                      <button *ngIf="element.isMatched"
                              mat-raised-button
                              (click)="unmatch(element)"
                              style="width: 100px;"
                              [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_INVESTOR_MATCH )"
                              [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_INVESTOR_MATCH ) ? util.NoAccessCursor: ''}"
                              [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_INVESTOR_MATCH ) ? util.NoAccessMsg : ''"
                              matTooltipPosition="left"
                      >
                        UNMATCH
                      </button>
                      <button mat-icon-button (click)="viewHistory(element)" matTooltip="View History"
                              matTooltipPosition="right">
                        <mat-icon>history</mat-icon>
                      </button>
                    </div>
                  </td>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="notes">
                  <th mat-header-cell *matHeaderCellDef> Notes</th>
                  <td mat-cell *matCellDef="let element">
                    <span style="font-size: x-small">{{ element.comments }}</span>
                  </td>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="matchDetails">
                  <th mat-header-cell *matHeaderCellDef> Match Details</th>
                  <td mat-cell *matCellDef="let element">
                    <ul>
                      <li style="font-size: x-small;"
                          *ngIf="!!element.matchedBy">{{ element.matchedBy.firstName + ' ' + element.matchedBy.lastName }}
                      </li>
                      <li style="font-size: x-small;" *ngIf="!!element.matchedBy">{{ element.matchedBy.email }}</li>
                      <li style="font-size: x-small;"
                          *ngIf="!!element.dateMatched">{{ element.dateMatched | date }}
                      </li>
                    </ul>
                  </td>
                </ng-container>
                <!-- Search Header -->
                <tr mat-header-row *matHeaderRowDef="['table-name','search-header'];"></tr>
                <tr mat-header-row *matHeaderRowDef="matchesColsToDisplay; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: matchesColsToDisplay;"></tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    list start-->
    <div class="row justify-content-center d-flex d-md-none" style="padding-bottom: 150px; padding-top: 15px;">
      <div class="col-12">
        <ul style="list-style-type: none; padding: 25px 0 0 0;">
          <li *ngFor="let element of dataSource.data"
              style="margin-bottom: 5px; display: flex; justify-content: space-between;">
            <app-user-card [firstName]="element.businessUnitMiniModel?.['displayName']"
                           [displayName]="element.mandate?.title"
                           [borderColor]="'transparent'"
                           [sideColor]="'#ff0000'"
                           [image]="element.businessUnitMiniModel?.['imageUrl']"
            ></app-user-card>
            <div style="display: flex;">
              <button *ngIf="!element.isMatched" mat-raised-button color="primary" (click)="match(element)"
                      style="width: 100px;">
                MATCH
              </button>
              <button *ngIf="element.isMatched" mat-raised-button (click)="unmatch(element)"
                      style="width: 100px;">
                UNMATCH
              </button>
              <button mat-icon-button (click)="viewHistory(element)" matTooltip="View History"
                      matTooltipPosition="right">
                <mat-icon>history</mat-icon>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>



