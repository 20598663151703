import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ISubDocField, SubDocFieldType} from "../../../models/syndication";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {MatRadioChange} from "@angular/material/radio";

@Component({
  selector: 'app-deal-subscription-subdoc-form-field',
  templateUrl: './deal-subscription-subdoc-form-field.component.html',
  styleUrls: ['./deal-subscription-subdoc-form-field.component.scss']
})
export class DealSubscriptionSubdocFormFieldComponent implements OnInit, OnChanges {

  @Input() field: string | null = '';
  @Input() fieldList: ISubDocField[] = [];
  @Output() onInputChange = new EventEmitter;
  @Output() onCheckChange = new EventEmitter;
  @Output() onRadioChange = new EventEmitter;
  @Output() onSelectChange = new EventEmitter;
  protected readonly SubDocFieldType = SubDocFieldType;

  currentField: ISubDocField = {} as unknown as ISubDocField;

  constructor() {
  }

  ngOnInit(): void {
    this.getCurrentField();
  }

  private getCurrentField() {
    if (!!this.field) {
      for (let i = 0; i <= this.fieldList.length - 1; i++) {
        if (this.field == this.fieldList[i].uuid) {
          this.currentField = Object.assign(this.fieldList[i]);
          break;
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes && changes['fieldList']) {
      this.fieldList = changes['fieldList'].currentValue;
      this.getCurrentField();
    }
  }

  getField() {
    return this.currentField;
  }

  getInput() {
    return !!this.currentField.values ? this.currentField.values[0] : '';
  }

  changeInput(event: any) {
    let answer: IQuestionAnswer = {
      uuid: this.field,
      value: event.srcElement.value
    } as unknown as IQuestionAnswer;
    this.onInputChange.emit({answer: answer});
  }

  isOptionChecked(optId: string) {
    return this.currentField.values.includes(optId);
  }

  changeCheckbox(opt: any, event: MatCheckboxChange) {
    let answer: IQuestionAnswer = {
      uuid: this.field,
      value: opt.key
    } as unknown as IQuestionAnswer;
    this.onCheckChange.emit({answer: answer});
  }

  getRadio() {
    return !!this.currentField.values ? this.currentField.values[0] : null;
  }

  changeRadio(event: MatRadioChange) {
    let answer: IQuestionAnswer = {
      uuid: this.field,
      value: event.value
    } as unknown as IQuestionAnswer;
    this.onRadioChange.emit({answer: answer});
  }

  changeSelect(event: any) {
    let answer: IQuestionAnswer = {
      uuid: this.field,
      value: event.value
    } as unknown as IQuestionAnswer;
    this.onSelectChange.emit({answer: answer});
  }

}

interface IQuestionAnswer {
  uuid: string;
  values: string;
}
