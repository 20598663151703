import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup} from "@angular/forms";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-phone-verify-modal',
  templateUrl: './phone-verify-modal.component.html',
  styleUrls: ['./phone-verify-modal.component.scss']
})
export class PhoneVerifyModalComponent implements OnInit {

  loading = false;
  //reactive form
  phoneForm = new FormGroup({
    id: new FormControl(),
    countryCode: new FormControl('+1'),
    mobile: new FormControl(),
  });

  constructor(private toastrService: ToastrService,
              public dialogRef: MatDialogRef<PhoneVerifyModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.phoneForm.patchValue(this.data);
  }

  public confirm(code: string) {
    this.dialogRef.close(
      {
        id: this.phoneForm.get('id')?.value,
        code: code
      }
    );
  }

  public pasteError(errPrefix: string, event: string) {
    this.toastrService.error(errPrefix + event);
  }

  public requestCode() {

  }

}
