import {createReducer, on} from '@ngrx/store';
import * as CognitoUserActions from './cognito-user.actions';
import {initialCognitoUserState} from "./cognito-user.init";

// the key feature
export const cognitoUserFeatureKey = 'CognitoUser';

// the update reducer
export const cognitoUserReducer = createReducer(
  initialCognitoUserState,
  on(CognitoUserActions.updateCognitoUser, (state, {cognito}) => ({
      id: cognito.id,
      username:  cognito.username,
      attributes: cognito.attributes
      }
    )
  )
);

