import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {StoreModule} from "@ngrx/store";
import {reducers, ThemeEffects} from "./index";
import {EffectsModule} from "@ngrx/effects";
import {ConfigEffects} from "./stores/config/config.effects";
import {LookupsEffects} from "./stores/lookups/lookups.effects";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('app', reducers),
    EffectsModule.forFeature([ConfigEffects, ThemeEffects, LookupsEffects])
  ],
  providers: [

  ]
})
export class ReduxModule { }
