import {createAction, props} from '@ngrx/store';
import {Config} from "./config.model";
import {INavItem, ITask} from "../../../../models";
import {IBusinessUnit, IBusinessUnitRole} from "../../../business-units/models";
import {IDealCard} from "../../../shared/models";
import {iMandateResponse} from "../../../mandates/models";
import {IDealSubscription, IDealSyndication} from "../../../deals/models";
import {IBusinessUnitEntity} from "../../../entities/models/entity";

export const updateConfig = createAction(
  '[Update Config] Config',
  props<{ config: Config }>()
);

//modals
export const showDealModal = '[Show Deal Modal] Config';
export const showMandateModal = '[Show Mandate Modal] Config';
export const toggleDarkModeConfig = '[Toggle Dark Mode] Config';

//screen height
export const updateScrenHeight = createAction(
  '[Update Screen Height] Config',
  props<{ height: number }>()
);
//screen width
export const updateScreenWidth = createAction(
  '[Update Screen Width] Config',
  props<{ width: number }>()
);
export const toggleDarkMode = createAction(
  '[Toggle Dark Mode] Config',
  props<{ mode: boolean }>()
);
//loading
export const toggleLoading = createAction(
  '[Toggle Loading] Config',
  props<{ loading: boolean }>()
);
//navigating
export const toggleNavigating = createAction(
  '[Toggle Navigating] Config',
  props<{ navigating: boolean }>()
);
//drawer state
export const updateDrawerState = createAction(
  '[Update Drawer State] Config',
  props<{ open: boolean }>()
);
//drawer tab idx
export const updateDrawerTabIdx = createAction(
  '[Update Drawer Tab Idx] Config',
  props<{ idx: number }>()
);
//user groups
export const updateUserGroups = createAction(
  '[Update User Groups] Config',
  props<{ groups: string[] }>()
);
//nav items
export const updateNavItems = createAction(
  '[Update Nav Items] Config',
  props<{
    items: INavItem[]
  }>()
)
//selected user view
export const updateSelectedUserView = createAction(
  '[Update Selected User View] Config',
  props<{ view: string }>()
);

//show navigation menu
export const showNavigationMenu = createAction(
  '[Update Show Navigation Menu] Config',
  props<{ show: boolean }>()
);
//show navigation toolbar
export const showNavigationToolbar = createAction(
  '[Update Show Navigation Toolbar] Config',
  props<{ show: boolean }>()
);
//show notes tab
export const showNotesTab = createAction(
  '[Update Show Notes Tab] Config',
  props<{ show: boolean }>()
);
//update the selected deal id
export const updateDealId = createAction(
  '[Update Selected Deal Id] Config',
  props<{ dealId: string }>()
);
//update the selected deal
export const updateDeal = createAction(
  '[Update Selected Deal] Config',
  props<{ deal: IDealCard }>()
);
//deal tabs
export const updateDealTabs = createAction(
  '[Update Deal Tabs] Config',
  props<{
    tabs: INavItem[]
  }>()
)
//update the selected deal role
export const updateDealRole = createAction(
  '[Update Selected Deal Role] Config',
  props<{ dealRole: IBusinessUnitRole }>()
);
//update the selected mandate id
export const updateMandateId = createAction(
  '[Update Selected Mandate Id] Config',
  props<{ mandateId: string }>()
);
//update the selected mandate
export const updateMandate = createAction(
  '[Update Selected Mandate] Config',
  props<{ mandate: iMandateResponse }>()
);
//update the selected user id
export const updateUserId = createAction(
  '[Update Selected User Id] Config',
  props<{ userId: string }>()
);

//update the selected user
export const updateUser = createAction(
  '[Update Selected User] Config',
  props<{ user: any }>()
);

//update the selected legal id
export const updateLegalId = createAction(
  '[Update Selected Legal Id] Config',
  props<{ legalId: string }>()
);

//update the selected legal
export const updateLegal = createAction(
  '[Update Selected Legal] Config',
  props<{ legal: any }>()
);

//update the selected kyc id
export const updateKycId = createAction(
  '[Update Selected KYC Id] Config',
  props<{ kycId: string }>()
);

//update the selected kyc
export const updateKyc = createAction(
  '[Update Selected KYC] Config',
  props<{ kyc: any }>()
);

//update the selected deal team id
export const updateSelectedDealTeamId = createAction(
  '[Update Selected Deal Team Id] Config',
  props<{ selectedDealTeamId: string }>()
);
export const updateDealDocumentRootFolders = createAction(
  '[Update DealDocumentRootFolders] Config',
  props<{ rootFolders: any[] }>()
);
//business units
export const updateBusinessUnits = createAction(
  '[Update BusinessUnits] Config',
  props<{ businessUnits: IBusinessUnit[] }>()
);
//selected user business unit
export const updateSelectedBusinessUnit = createAction(
  '[Update SelectedBusinessUnit] Config',
  props<{ businessUnit: IBusinessUnit }>()
);
//selected user business unit role
export const updateSelectedBusinessUnitRole = createAction(
  '[Update SelectedBusinessUnitRole] Config',
  props<{ businessUnitRole: IBusinessUnitRole }>()
);
//selected user business unit entity id
export const updateSelectedBusinessUnitEntityId = createAction(
  '[Update SelectedBusinessUnitEntityId] Config',
  props<{ businessUnitEntityId: string }>()
);
//selected user business unit entity
export const updateSelectedBusinessUnitEntity = createAction(
  '[Update SelectedBusinessUnitEntity] Config',
  props<{ businessUnitEntity: IBusinessUnitEntity }>()
);
//editing business unit
export const updateEditingBusinessUnit = createAction(
  '[Update EditingBusinessUnit] Config',
  props<{ businessUnit: IBusinessUnit }>()
);

//editing business unit employee
export const updateEditingBusinessUnitEmployee = createAction(
  '[Update EditingBusinessUnitEmployee] Config',
  props<{ businessUnitEmployee: any }>()
);

//update deal subscription
export const updateDealSubscription = createAction(
  '[Update Deal Subscription] Config',
  props<{ dealSubscription: IDealSubscription }>()
);

//update deal syndication
export const updateDealSyndication = createAction(
  '[Update Deal Syndication] Config',
  props<{ dealSyndication: IDealSyndication }>()
);

//update tasks
export const updateTasks = createAction(
  '[Update Tasks] Config',
  props<{ tasks: ITask[] }>()
);

//update the selected employee id
export const updateEmployeeId = createAction(
  '[Update Selected Employee Id] Config',
  props<{ employeeId: string }>()
);
//update the selected employee
export const updateEmployee = createAction(
  '[Update Selected Employee] Config',
  props<{ employee: any }>()
);

//update the selected deal team member
export const updateDealTeamMember = createAction(
  '[Update Selected Deal Team Member] Config',
  props<{ dealTeamMember: any }>()
);

//update the selected entity
export const updateSelectedEntity = createAction(
  '[Update Selected Entity] Config',
  props<{ entity: any }>()
);
//update the selected entity id
export const updateSelectedEntityId = createAction(
  '[Update Selected Entity Id] Config',
  props<{ entityId: string }>()
);
//update the selected entity kyc
export const updateSelectedEntityKyc = createAction(
  '[Update Selected Entity Kyc] Config',
  props<{ entityKyc: any }>()
);
//update the selected entity kyc id
export const updateSelectedEntityKycId = createAction(
  '[Update Selected Entity Kyc Id] Config',
  props<{ kycId: string }>()
);

//fetch tasks
export const fetchTasks = '[Fetch Tasks] Config';



