<div class="rm-header">
  <span>Indicate Interest in {{ getSelectedDealName() }}</span>
</div>
<div class="row justify-content-center" style="padding-bottom: 25px; padding-top: 40px;">
  <div class="col-11">
    <span style="font-size: medium;">
      <p>
        <b>To express your interest in {{ getSelectedDealName() }}, please complete the form below. We will inform you of your final allocation once it has been confirmed.</b>
      </p>
      <p style="font-size: small;">
        <b>Please note: </b><i><u>Submitting this Indication of Interest (IOI) is non-binding and does not constitute a commitment to invest.
        Completing this form does not guarantee that you will receive an allocation.</u></i>
      </p>
    </span>
  </div>
</div>
<form>
  <div class="row justify-content-center" style="padding-bottom: 50px;">
    <div class="col-6" style="padding-right: 50px;">
      <span style="font-weight: bold;">Allocation Type</span>
      <mat-form-field class="form-field" appearance="outline">
        <mat-select id="all" name="all" [(ngModel)]="data.restrictionType" [required]="true"
                    placeholder="Choose one...">
          <mat-option *ngFor="let r of lookups.indicationOnInterestRestrictionTypes" [value]="r.key">
            {{ r.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-5">
      <span style="font-weight: bold;">Amount (USD)</span>
      <mat-form-field class="form-field" *ngIf="editingAmount">
        <input id="amt"
               name="amt"
               type="number"
               [min]="0"
               [(ngModel)]="data.amount"
               matInput
               [required]="true"
               (change)="editingAmount = false"
        >
        <mat-icon color="warn"
                  style="cursor: pointer;" matSuffix
                  *ngIf="!!data.amount"
                  (click)="editingAmount = false;">cancel
        </mat-icon>
      </mat-form-field>
      <div *ngIf="!editingAmount" style="display: flex;">
        <div style="padding-top: 13px;">
          <span
            style="font-size:x-large; font-weight: bold; color: #0a53be">{{ data.amount | currency: 'USD':true:'2.0' }}</span>
        </div>
        <div>
          <button type="button" mat-icon-button (click)="editingAmount = true;">
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </div>

    </div>
    <div class="col-11">
      <span style="font-weight: bold;">Additional Notes</span><br/>
      <em>Please provide any additional notes regarding your indication of interest here, such as contingencies or
        ongoing due diligence processes.
        These notes will be visible to Rondeivu and the Issuer.</em><br/><br/>
      <mat-form-field class="form-field" floatLabel="never">
        <textarea id="notes"
                  name="notes"
                  [(ngModel)]="data.notes"
                  matInput
                  placeholder="type here..."
        ></textarea>
      </mat-form-field>
    </div>
  </div>
</form>
<div class="row justify-content-center">
  <div class="col-6">
    <button mat-raised-button (click)="dialogRef.close()">CANCEL</button>
  </div>
  <div class="col-6 text-end">
    <button mat-raised-button color="primary" (click)="dialogRef.close(data)"
            [disabled]="!data.amount || data.amount < 0 || !data.restrictionType">SUBMIT
    </button>
  </div>
</div>
