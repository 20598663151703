import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from 'src/app/components';
import {
  DealDataRoomComponent,
  DealListComponent,
  DealMatchingComponent,
  DealMetricsComponent,
  DealNavigationComponent,
  DealSettingsComponent,
  DocumentsComponent,
  DealHighlightsComponent,
  MediaComponent,
  DealTeamComponent,
  SubscriptionComponent,
  SyndicationComponent,
  InvestorTestComponent,
  CommunicationComponent,
  DealNdaComponent,
  DealTeamNavigationComponent,
  DealTeamInnerNavigationComponent,
  DealTeamMemberInfoComponent,
  DealTeamMemberPermissionsComponent,
  DealEditComponent,
  DealViewComponent, DealSubscriptionComponent
} from './components';
import {DealTeamMemberResolver} from "../../resolvers/deal-team-member.resolver";
import {DealGuard} from "../../guards/deal.guard";
import {DealResolver} from "../../resolvers/deal.resolver";

const routes: Routes = [
  {path: '', component: DealListComponent},
  {
    path: ':id', component: DealNavigationComponent,
    runGuardsAndResolvers: "always",
    canActivate: [DealGuard],
    resolve: {
      DealResolver
    },
    children: [
      {path: 'highlights', component: DealHighlightsComponent},
      {path: 'deal-information', component: DealViewComponent},
      {path: 'deal-edit', component: DealEditComponent},
      {path: 'deal-metrics', component: DealMetricsComponent},
      {path: 'nda', component: DealNdaComponent},
      {path: 'deal-settings', component: DealSettingsComponent},
      {
        path: 'deal-team', component: DealTeamNavigationComponent,
        children: [
          {path: '', component: DealTeamComponent},
          {
            path: ':id', component: DealTeamInnerNavigationComponent,
            resolve: {
              businessUnit: DealTeamMemberResolver
            },
            children: [
              {path: 'account', component: DealTeamMemberInfoComponent},
              {path: 'permissions', component: DealTeamMemberPermissionsComponent},
              {path: '', redirectTo: 'account', pathMatch: 'full'}
            ]
          }
        ]
      },
      {path: 'data-room', component: DealDataRoomComponent},
      {path: 'deal-analytics', component: PageNotFoundComponent},
      {path: 'communication', component: CommunicationComponent},
      {path: 'documents', component: DocumentsComponent},
      {path: 'media', component: MediaComponent},
      {path: 'syndication', component: SyndicationComponent},
      {path: 'subscription', component: SubscriptionComponent},
      {path: 'deal-matching', component: DealMatchingComponent},
      {path: 'investor-test', component: InvestorTestComponent},
      {path: '', redirectTo: 'deal-information', pathMatch: 'full'},
      {path: '**', redirectTo: 'deal-information'},
    ]
  },
  {path: '', redirectTo: '', pathMatch: 'full'},
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DealsRoutingModule {
}
