<app-data-room
  pageTitle="Documents"
  pageDesc="These are user specific documents pertaining to this deal."
  [documentTypes]="docTypes"
  [objectId]="objectId"
  [showEditButton]="util.hasPermission(util.Permission.CAN_EDIT_DEAL_DOCUMENTS)"
  [showDeleteButton]="util.hasPermission(util.Permission.CAN_EDIT_DEAL_DOCUMENTS)"
  [showAddFileButton]="util.hasPermission(util.Permission.CAN_EDIT_DEAL_DOCUMENTS)"
  [showNewFolderButton]="util.hasPermission(util.Permission.CAN_EDIT_DEAL_DOCUMENTS)"
  [canDropFiles]="util.hasPermission(util.Permission.CAN_EDIT_DEAL_DOCUMENTS)"
  [downloadAllEnabled]="util.hasPermission(util.Permission.CAN_ACCESS_DEAL_DOCUMENTS_DOWNLOAD_ALL)"
></app-data-room>
