import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from 'src/app/components';
import {MandateInfoComponent, MandatesListComponent, MandatesNavigationComponent} from "./components/";
import {MandateResolver} from "../../resolvers/mandate.resolver";

const routes: Routes = [
  { path: '', component: MandatesListComponent },
  {
    path: ':id', component: MandatesNavigationComponent,
    resolve: {
      MandateResolver
    },
    children: [
      {path: 'information', component: MandateInfoComponent},
      {path: '', redirectTo: 'information', pathMatch: 'full'},
      {path: '**', redirectTo: 'information'},
    ]
  },
  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MandatesRoutingModule {}
