<mat-toolbar id="toolbar" class="employee-header" dense>
  <div style="display: flex; justify-content: space-between; width: 100%;">
    <div style="display: flex;">
      <button type="button" mat-icon-button (click)="back()">
        <mat-icon>west</mat-icon>
      </button>
      <app-user-card
        [firstName]="selectedEntityKyc?.kycName"
        [displayName]="selectedEntityKyc?.kycRondeivuStatus"
        [icon]="'picture_as_pdf'"
        [size]="UserCardSize.SM"
      >
      </app-user-card>
    </div>
    <div>
      <form [formGroup]="form">
        <!--      <mat-form-field class="form-field" *ngIf="util.isType('admin')">-->
        <!--        <mat-label>KYC Status</mat-label>-->
        <!--        <mat-select id="kycStatus" formControlName="kycRondeivuStatus" (selectionChange)="changeStatus()">-->
        <!--          <mat-option *ngFor="let s of lookups.kycRondeivuStatus" [value]="s.key">-->
        <!--            {{ s['value'] }}-->
        <!--          </mat-option>-->
        <!--        </mat-select>-->
        <!--      </mat-form-field>&nbsp;-->
        <button type="button" mat-raised-button color="primary" (click)="togglePdfView()">
          <mat-icon>{{ viewingPDF ? 'edit' : 'picture_as_pdf' }}</mat-icon>&nbsp;{{ viewingPDF ? 'Edit KYC' : 'View PDF' }}
        </button>
      </form>
    </div>
  </div>
</mat-toolbar>
<div *ngIf="!viewingPDF">
  <router-outlet></router-outlet>
</div>
<div *ngIf="viewingPDF">
  <ngx-extended-pdf-viewer
    [src]="base64"
    [useBrowserLocale]="true"
    [textLayer]="true"
    [showHandToolButton]="true"
    [showPresentationModeButton]="false"
    [showDownloadButton]="true"
    [showPrintButton]="true"
    [showOpenFileButton]="false"
    [showPropertiesButton]="false"
    [showSidebarButton]="false"
    backgroundColor="#333333"
  ></ngx-extended-pdf-viewer>
</div>
