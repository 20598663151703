import {Injectable} from '@angular/core';
import {Config, getConfig, initialConfigState} from "../../../redux";
import {environment} from "../../../../../environments/environment";
import {Store} from "@ngrx/store";
import {RondeivuHttpClient} from "../../../../handlers";
import {IMediaRoom} from "../../models";
import {HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  private config: Config = initialConfigState;
  private readonly BASE_URL = environment.rondeivu_api;
  private readonly FRAGMENT = '/mediaroom';
  private readonly IMAGE_FRAGMENT = '/upload-image';
  private readonly REORDER = '/reorder';

  constructor(private store: Store, private http: RondeivuHttpClient) {
    this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
  }

  /**
   * Create media room item for deal
   * @param mediaRoom
   */
  addMediaRoom(mediaRoom: IMediaRoom): Observable<any> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const req = JSON.stringify(mediaRoom);
    // console.log(req);
    return this.http.post(this.BASE_URL + this.FRAGMENT + '/' + this.config.selected_deal_id, mediaRoom, {headers: httpHeaders});
  }

  /**
   * Upload image into the media room and returns the public url for use as media room add or update
   * @param file
   */
  addMediaRoomImage(file: any): Observable<any> {
    let formData = new FormData();
    formData.append("formFile", file);
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.IMAGE_FRAGMENT + '/' + this.config.selected_deal_id, formData);
  }

  /**
   * Get the media rooms specific to the deal
   */
  getMediaRooms(): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + '/' + this.config.selected_deal_id);
  }

  /**
   * Update the media room
   * @param mediaRoom
   */
  updateMediaRoom(mediaRoom: IMediaRoom): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + '/' + mediaRoom.id, mediaRoom);
  }

  /**
   * Delete the media room
   * @param mediaRoom
   */
  removeMediaRoom(mediaRoom: IMediaRoom): Observable<any> {
    return this.http.delete(this.BASE_URL + this.FRAGMENT + '/' + mediaRoom.id);
  }

  /**
   * Reorder the media room list
   * @param mediaRooms the media room list
   * @param dealId the deal id
   */
  reorder(mediaRooms: IMediaRoom[], dealId: string): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + this.REORDER + '/' + dealId, mediaRooms);
  }

}
