import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {CognitoUser, Config, getCognitoUser, getConfig, initialCognitoUserState, initialConfigState} from "../../redux";
import {RondeivuHttpClient} from "../../../handlers";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {IKycLegalEntity} from "../models/i-kyc-legal-entity";

@Injectable({
  providedIn: 'root'
})
export class EntityKycBusinessService {
  readonly BASE_URL = environment.rondeivu_api;
  readonly FRAGMENT = '/businessunitentitykyclegalentity';
  readonly KYC_FRAGMENT = '/kyc';
  readonly UPLOAD_DOC = '/upload-proof-of-document';
  readonly DELETE_DOC = '/remove-proof-of-documentation';
  readonly VIEW_DOC = '/view-proof-of-document';
  readonly RONDEIVU_STATUS = '/change-rondeivu-status';
  readonly IMPORT = '/import';
  private config: Config = initialConfigState;
  private cognito: CognitoUser = initialCognitoUserState;

  constructor(private http: RondeivuHttpClient, private store: Store) {
    this.store.select(getConfig).subscribe(c => {
      this.config = c;
    });
    this.store.select(getCognitoUser).subscribe(cu => {
      this.cognito = cu;
    });
  }

  /**
   * Get the legal entities associated to the user's business unit
   */
  getKycLegalEntities(): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT);
  }

  /**
   * Add a new legal entity to the user's business unit
   */
  addKycLegalEntity(kycLegalEntity: IKycLegalEntity): Observable<any> {
    let formData = new FormData();
    this.sanitize(formData, "businessUnitId", kycLegalEntity.businessUnitId);
    this.sanitize(formData, "businessUnitEntityKycId", kycLegalEntity.businessUnitEntityKycId);
    this.sanitize(formData, "kycLegalEntityType", kycLegalEntity.kycLegalEntityType);
    this.sanitize(formData, "legalName", kycLegalEntity.legalName);
    this.sanitize(formData, "streetAddress", kycLegalEntity.streetAddress);
    this.sanitize(formData, "city", kycLegalEntity.city);
    this.sanitize(formData, "stateOrProvince", kycLegalEntity.stateOrProvince);
    this.sanitize(formData, "countryId", kycLegalEntity.countryId);
    this.sanitize(formData, "zipPostalCode", kycLegalEntity.zipPostalCode);
    this.sanitize(formData, "legalDomicile", kycLegalEntity.legalDomicile);
    this.sanitize(formData, "taxIdType", kycLegalEntity.taxIdType);
    this.sanitize(formData, "taxIdNumber", kycLegalEntity.taxIdNumber);
    this.sanitize(formData, "legalEntityProofofDocumentType", kycLegalEntity.legalEntityProofofDocumentType);
    this.sanitize(formData, "legalEntityProofofDocumentOtherName", kycLegalEntity.legalEntityProofofDocumentOtherName);
    this.sanitize(formData, "natureoftheBusiness", kycLegalEntity.natureoftheBusiness);
    this.sanitize(formData, "businessSourceofFund", kycLegalEntity.businessSourceofFund);

    if (!!kycLegalEntity.kycLegalEntityProofofDocuments) {
      for (let i = 0; i <= kycLegalEntity.kycLegalEntityProofofDocuments.length - 1; i++) {
        formData.append("kycLegalEntityProofofDocuments", kycLegalEntity.kycLegalEntityProofofDocuments[i]);
      }
    }

    return this.http.post(this.BASE_URL + this.FRAGMENT, formData);
  }

  private sanitize(formData: FormData, field: string, value: any) {
    if (!!value && (value != '') && (value != undefined)) {
      formData.append(field, value);
    }
  }

  /**
   * Get a legal entity by the current kyc id
   * @param kycId the kyc being viewed
   */
  getKycLegalEntityByKycId(kycId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.KYC_FRAGMENT + '/' + kycId);
  }

  /**
   * Get a legal entity by id
   * @param kycLegalEntityId
   */
  getKycLegalEntityById(kycLegalEntityId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + '/' + kycLegalEntityId);
  }

  /**
   * Update a legal entity
   * @param kycLegalEntity
   */
  updateKycLegalEntity(kycLegalEntity: any): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + '/' + kycLegalEntity.id, kycLegalEntity);
  }

  /**
   * Delete a legal entity by id
   * @param kycLegalEntityId
   * @param entityKycId
   */
  deleteKycLegalEntity(entityKycId: string, kycLegalEntityId: string): Observable<any> {
    return this.http.delete(this.BASE_URL + this.FRAGMENT + '/' + entityKycId + '/' + kycLegalEntityId);
  }

  /**
   * Upload proof of document
   * @param kycLegalEntityId
   * @param form
   */
  uploadProofOfDocument(kycLegalEntityId: string, form: any): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + this.UPLOAD_DOC + '/' + kycLegalEntityId, form);
  }

  /**
   * Delete proof of document
   * @param documentId
   */
  deleteProofOfDocument(documentId: string): Observable<any> {
    return this.http.delete(this.BASE_URL + this.FRAGMENT + this.DELETE_DOC + '/' + documentId);
  }

  /**
   * View proof of document
   * @param documentId
   */
  getProofOfDocument(documentId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.VIEW_DOC + '/' + documentId, {responseType: 'blob'});
  }

  /**
   * ADMIN ONLY: update a person status
   * @param kycLegalEntityId
   * @param payload
   */
  updateLegalEntityStatus(kycLegalEntityId: string, payload: {
    kycLegalEntityId: string,
    kycLegalEntityRondeivuStatus: string
  }): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + this.RONDEIVU_STATUS + '/' + kycLegalEntityId, payload);
  }

  getKycLegalEntityToImport(kycId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.IMPORT + '/' + kycId);
  }
}
