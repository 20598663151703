import {Injectable} from '@angular/core';
import {RondeivuHttpClient} from "../../handlers";
import {environment} from "../../../environments/environment";
import {ToastrService} from "ngx-toastr";
import {Observable} from "rxjs";
import {
  CognitoUser,
  Config,
  getCognitoUser,
  getConfig,
  initialCognitoUserState,
  initialConfigState
} from "../../modules/redux";
import {Store} from "@ngrx/store";
import {IBusinessUnit} from "../../modules/business-units/models";

/**
 * TESTING ONLY
 */


@Injectable({
  providedIn: 'root'
})
export class AdminService {

  readonly BASE_URL = environment.rondeivu_api;
  readonly ADMIN_URL = this.BASE_URL + '/admin';
  readonly USERS_FRAGMENT = '/test/appusers/complete-user-list';

  private config: Config = initialConfigState;
  private cognito: CognitoUser = initialCognitoUserState;

  constructor(private http: RondeivuHttpClient, private toastr: ToastrService, private store: Store) {
    this.store.select(getConfig).subscribe(c => {
      this.config = c;
    });
    this.store.select(getCognitoUser).subscribe(cu => {
      this.cognito = cu;
    });
  }

  /**
   * Gets a list of business units for admin users
   */
  getBusinessUnits(): Observable<any> {
    return this.http.get(this.BASE_URL + '/businessunits');
  }

  /**
   * Adds a new business unit
   * @param businessUnit
   */
  addBusinessUnit(businessUnit: IBusinessUnit) {
    return this.http.post(this.BASE_URL + '/businessunits', businessUnit);
  }

  /**
   * Deletes a business unit by id
   * @param businessUnit
   */
  deleteBusinessUnit(businessUnit:IBusinessUnit){
    return this.http.delete(this.BASE_URL + '/businessunits/' + businessUnit.businessUnitId)
  }

  /**
   * Used by business unit employee modal to get a list of all users
   */
  getAppUsers(): Observable<any> {
    return this.http.get(this.BASE_URL + '/test/appusers/user-list');
  }

  /**
   * Used by business unit employee modal to add a new bu employee
   */
  applyBusinessUnitToUser(businessUnit: IBusinessUnit, appUserId: string, role:string):Observable<any> {
    let payload = {
      businessUnitId: businessUnit.businessUnitId,
      appUserId: appUserId,
      title: businessUnit.displayName,
      role: role
    }
    return this.http.post(this.BASE_URL + '/businessunitemployees', payload);
  }

  /**
   *
   *
   *
   *
   *      TESTING
   *
   *
   *
   *
   *
   *
   */


  /**
   * Admin ONLY
   */
  getAdminBusinessUnits(): Observable<any> {
    return this.http.get(this.ADMIN_URL + '/businessunits');
  }



  /**
   * Admin ONLY
   */
  addUserToBusinessUnit(businessUnitId: string, appUserId: string, title: string, role:string):Observable<any> {
    let payload = {
      businessUnitId: businessUnitId,
      appUserId: appUserId,
      title: title,
      role: role
    }
    return this.http.post(this.ADMIN_URL + '/businessunitemployees', payload);
  }

  /**
   * Admin ONLY
   */
  adminAddBusinessUnitByType(type: string) {
    let val = Math.floor(1000 + Math.random() * 9000);
    let bu = {
      slug: val.toString(),
      originalName: 'Rondeivu-' + val.toString(),
      displayName: 'Rondeivu-' + val.toString(),
      internalName: 'Rondeivu-' + val.toString(),
      businessUnitType: type,
      subType: "",
      domicile: "US",
      organizationId: "",
      isKYCRequired: true
    } as unknown as IBusinessUnit;
    this.http.post(this.ADMIN_URL + '/businessunits', bu).subscribe(res => {
      this.toastr.success('Business Unit Added!', $localize`:@@companyName:Rondeivu`);
    });
  }



  dealMatch(dealId: string, mandateId: string) {
    let payload = {
      MandateId: mandateId,
      dealId: dealId
    };
    return this.http.post(this.ADMIN_URL + '/investordealmatch', payload);
  }

}
