import {Directive, EventEmitter, HostBinding, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[appDnd]'
})
export class DndDirective {
  @Output() filesDropped: EventEmitter<any> = new EventEmitter<any>;
  @Output() filesHovered: EventEmitter<any> = new EventEmitter<any>;
  @HostBinding('class.fileover') fileOver:boolean = false;

  constructor() { }

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt:any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
    this.filesHovered.emit(this.fileOver);
    // console.log('Drag Over');
  }
  // Dragleave listener
  @HostListener('dragleave', ['$event']) onDragLeave(evt:any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    this.filesHovered.emit(this.fileOver);
    // console.log('Drag Leave');
  }
  // Drop listener
  @HostListener('drop', ['$event']) ondrop(evt:any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    const files = evt.dataTransfer.files;
    if(files.length > 0){
      // console.log(`You dropped ${files.length} files.`);
      this.filesDropped.emit(files);
    }
  }

}
