import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ThemePalette} from "@angular/material/core";

import {Subscription} from "rxjs";
import {IBusinessUnit} from "../../../business-units/models";
import {
  Config,
  getConfig,
  getSelectedBusinessUnit,
  getThemeState,
  initialConfigState,
  initialThemeState,
  Theme, toggleLoading, updateUserId
} from "../../../redux";
import {UsersService} from "../../../users/services/users/users.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {toggleNavigating} from "../../../redux/stores/config/config.actions";
import {AppFade} from "../../../../animations/animations";
import {IAppUser} from "../../../../models";

@Component({
  selector: 'app-approvals-navigation',
  templateUrl: './approvals-navigation.component.html',
  styleUrls: ['./approvals-navigation.component.scss'],
  animations: [AppFade()],
})
export class ApprovalsNavigationComponent implements OnInit, OnDestroy, AfterViewInit {
  loading = false;
  animationState = false;
  links = [
    {title: 'Issuer', link: '/issuer', enabled: true, hidden: false},
    {title: 'Investor', link: '/investor', enabled: true, hidden: false},
  ];
  activeLink = {};
  background: ThemePalette = undefined;

  userId: string | null = '';
  selectedUser: IAppUser = {} as unknown as IAppUser;

  config$: Subscription = new Subscription();
  theme$: Subscription = new Subscription();
  route$: Subscription = new Subscription();

  selectedBusinessUnit$: Subscription = new Subscription();
  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;

  config: Config = initialConfigState;
  theme: Theme = initialThemeState;

  constructor(
    private usersService: UsersService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private toastrService: ToastrService
  ) {
    this.route$ = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.loading = true;
        this.store.dispatch(toggleNavigating({navigating: true}));
        setTimeout(() => {
          this.store.dispatch(toggleNavigating({navigating: false}));
          this.loading = false;
        }, 1000);
      }
    });

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.theme$ = this.store.select(getThemeState).subscribe((theme: any) => {
      this.theme = theme;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.selectedBusinessUnit = bu;
      if (!!bu && !!bu.businessUnitId) {
        this.getUserIdFromRoute();
        this.getUser();
        this.setActiveLink();
      }
    });
  }

  ngOnInit(): void {
    this.animationState = true;
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.theme$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.route$.unsubscribe();
  }

  ngAfterViewInit() {

  }

  getUser() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 1000);

    if (!!this.userId) {
      this.store.dispatch(toggleLoading({loading: true}));
      this.usersService.getUserById(this.userId).subscribe({
        next: (user: any) => {
          this.selectedUser = user;
          this.toastrService.success("User Loaded!", $localize`:@@companyName:Rondeivu`);
          this.store.dispatch(toggleLoading({loading: false}));
        }, error: (error: any) => {
          this.store.dispatch(toggleLoading({loading: false}));
          this.toastrService.error("Unable to get user!", $localize`:@@companyName:Rondeivu`);
        }
      });
    }
  }

  getUserIdFromRoute() {
    this.userId = this.route.snapshot.paramMap.get('id');
    if (!!this.userId) {
      this.store.dispatch(updateUserId({userId: this.userId}));
    }
  }

  setActiveLink() {
    const fragments = this.router.url.split('/');
    let link = '/' + fragments[fragments.length - 1];
    this.links.forEach(l => {
      if (l.link == link) {
        this.activeLink = l;
      }
    });
  }

  navigate(obj: { title: string; link: string; enabled: boolean }) {
    this.store.dispatch(toggleNavigating({navigating: true}));
    this.animationState = false;
    setTimeout(() => {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/approvals/' + obj.link]).then(() => {
        this.animationState = true;
        this.store.dispatch(toggleNavigating({navigating: false}));
      });
    }, 300);
  }

  goBack() {
    this.store.dispatch(toggleNavigating({navigating: true}));
    this.animationState = false;
    setTimeout(() => {
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/users']).then(() => {
        this.animationState = true;
        this.store.dispatch(toggleNavigating({navigating: false}));
      });
    }, 300);
  }

}
