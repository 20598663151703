<div style="overflow-y: auto; height: calc(100vh - 175px);">
  <div class="container-fluid">
    <div style="overflow-x: hidden;">
      <br/><br/>
      <div class="row justify-content-center">
        <div class="col-11" style="display: flex;">
          <div style="padding-top: 10px;">
            <span style="font-size: x-large; color: #002150;">KYC Information</span>&nbsp;
          </div>
          <button type="button"
                  mat-icon-button
                  *ngIf="util.isType('admin')"
                  (click)="viewHistory()"
                  matTooltip="View History"
                  matTooltipPosition="right">
            <mat-icon>history</mat-icon>
          </button>
        </div>
        <div class="col-11" style="padding-top: 15px;">
          <span style="font-size: small;">
            Streamline your KYC experience with our user-friendly <b>KYC Process Management Module</b>.<br/><br/>
            This feature empowers you to effortlessly download all relevant files needed for verification and then seamlessly
            upload the required documents with confidence. We understand the importance of a straightforward KYC process,
            and this module is designed to provide you with a secure, efficient, and convenient space for managing all
            aspects of your KYC journey. Trust in our commitment to maintaining the highest standards of security as
            you navigate through this enhanced verification process.
          </span>
        </div>

      </div>
      <!--    WELL START-->
      <div class="row justify-content-center" style="padding-top: 25px; padding-bottom: 150px;">
        <div class="col-11">
          <form [formGroup]="userForm"
                (ngSubmit)="save()">
            <div class="row">
              <span style="font-size: x-large;">Settings</span>
            </div>
            <br/>
            <!--          row start-->
            <div class="row" style="padding-bottom: 20px;">
              <div class="col-6">
                <mat-label style="font-size: medium;">Created By</mat-label>
                <br/><br/>
                <app-user-card [firstName]="selectedKyc.createdBy?.firstName"
                               [lastName]="selectedKyc.createdBy?.lastName"
                               [displayName]="selectedKyc.createdBy?.email"
                               [borderColor]="'transparent'"
                               [sideColor]="'#345'"
                               [image]="selectedKyc.createdBy?.imageUrl">
                </app-user-card>

              </div>
              <div class="col-12 col-md-6">
                <mat-label>Status</mat-label>
                <div *ngIf="!util.isType('admin')">
                  <br/>
                  <span [appStatus]="userForm.get('status')?.value">{{ userForm.get('status')?.value }}</span>
                </div>
                <mat-form-field class="form-field" appearance="outline" *ngIf="util.isType('admin')">
                  <mat-select formControlName="status"
                              [required]="true"
                              (selectionChange)="updateStatus($event.value)"
                              [disabled]="!util.hasPermission(util.Permission.CAN_CHANGE_KYC_STATUS)"
                              [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_CHANGE_KYC_STATUS) ? util.NoAccessCursor: ''}"
                              [matTooltip]="!util.hasPermission(util.Permission.CAN_CHANGE_KYC_STATUS) ? util.NoAccessMsg : ''"
                              matTooltipPosition="left"
                  >
                    <mat-option *ngFor="let r of lookups.kycStatusTypes" [value]="r.key">
                      {{ r.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <!--          row start-->
            <div class="row" style="padding-bottom: 20px;">
              <div class="col-12 col-md-6">
                <mat-label style="font-size: medium;">Entity Name</mat-label>
                <mat-form-field class="form-field" appearance="outline">
                  <input class="form-input" matInput formControlName="entityName" [required]="true"/>
                </mat-form-field>
              </div>
              <!--      investor types-->
              <div class="col-12 col-md-6" *ngIf="shouldShowTypesFor('investor')">
                <mat-label>Type</mat-label>
                <mat-form-field class="form-field" appearance="outline">
                  <mat-select formControlName="kycProfileSubType" [required]="true"
                              (selectionChange)="getKycDocuments($event.value)">
                    <mat-option *ngFor="let r of lookups.kycInvestorTypes" [value]="r.key">
                      {{ r.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <!--      issuer types-->
              <div class="col-12 col-md-6" *ngIf="shouldShowTypesFor('issuer')">
                <mat-label>Type</mat-label>
                <mat-form-field class="form-field" appearance="outline">
                  <mat-select formControlName="kycProfileSubType" [required]="true"
                              (selectionChange)="getKycDocuments($event.value)">
                    <mat-option *ngFor="let r of lookups.kycIssuerTypes" [value]="r.key">
                      {{ r.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <!--          row start-->
            <div class="row" style="padding-bottom: 20px;">
              <div class="col-12 col-md-6">
                <mat-label style="font-size: medium;">Date Submitted</mat-label>
                <br/>
                <span>{{ selectedKyc.created | date }}</span>
              </div>
            </div>
            <!--      row-->
            <div class="row" style="padding-bottom: 20px;">
              <div class="col-12">
                <span>
                  <b>Instructions:</b><br/><br/>
                  <ol style="font-weight: lighter;">
                    <li>Click the link(s) below to download the fillable PDF document(s).</li>
                    <li>Fill out the forms electronically.</li>
                    <li>Navigate to the "Upload Documents" tab and upload the completed forms along with any additional documents requested in the KYC form(s).</li>
                    <li>Once all required documents are uploaded, click the "Submit for Review" button to complete the process.</li>
                  </ol>
                </span>
                <span><b>Documents to Download:</b></span><br/><br/>
                <span style="font-weight: lighter;" *ngIf="!userForm.get('kycProfileSubType')?.value">Please select a KYC type to view required documents.</span>
                <span style="font-weight: lighter;" *ngIf="!!userForm.get('kycProfileSubType')?.value">Please ensure all necessary documents are submitted for a successful KYC review.
                  If you encounter any issues or have questions, feel free to reach out to our support team at Rondeivu.
                </span>
              </div>
            </div>
            <!-- row-->
            <div class="row" style="padding-bottom: 15px;">
              <div class="col-12" *ngIf="loading == false">
                <ul>
                  <li *ngFor="let d of docs"><a [href]="d.url" target="_blank">{{ d.name }}</a></li>
                </ul>
              </div>
              <div class="col-12 col-md-6" *ngIf="loading">
                <mat-spinner [diameter]="36" color="accent"></mat-spinner>
              </div>
            </div>
            <!-- row-->
            <div class="row">
              <div class="col-12 text-end">
                <button mat-raised-button
                        color="primary"
                        [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_KYC)"
                        [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_KYC) ? util.NoAccessCursor: ''}"
                        [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_KYC) ? util.NoAccessMsg : ''"
                        matTooltipPosition="left"
                        type="submit">SAVE CHANGES
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

