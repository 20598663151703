<div style="overflow-y: auto; height: calc(100vh - 176px);">
  <div class="container-fluid">
    <form [formGroup]="userForm"
          (ngSubmit)="save()">
      <div class="row justify-content-center" style="padding-top: 25px;">
        <div class="col-12 col-md-11">
          <span class="rm-title rm-title-3">Deal Metrics</span><br/>
          <span class="rm-title rm-title-6 rm-lighter">This is where you can edit the metrics of the deal.</span>
        </div>
      </div>
      <div class="row justify-content-center" style="padding-bottom: 150px; padding-top: 25px;">
        <div class="col-12 col-md-11">
          <div class="row">
            <span style="font-size: large; font-weight: bold;">Issuer Overview</span>
          </div>
          <br/>
          <!--          row start-->
          <div class="row">
            <div class="col-12 col-md-6">
              <mat-label style="font-size: medium;">Entity Name</mat-label>
              <mat-form-field class="form-field" appearance="outline" [style.padding-top]="0">
                <input class="form-input" matInput formControlName="issuerEntityName" [required]="true"
                       placeholder="Rondeivu"/>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <mat-label style="font-size: medium;">Country</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <mat-select id="country" formControlName="countryId" [required]="true" placeholder="Canada">
                  <mat-option *ngFor="let c of lookups.countries" [value]="c.key">
                    {{c.value}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="userForm.invalid">Please choose one.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <br/>
          <!--          row start-->
          <div class="row">
            <div class="col-12 col-md-6">
              <!--              state-->
              <div *ngIf="(userForm.get('countryId')?.value =='US') || (userForm.get('countryId')?.value == '')">
                <mat-label style="font-size: medium;">State</mat-label>
                <mat-form-field class="form-field" appearance="outline">
                  <mat-select id="state" [required]="userForm.get('countryId')?.value =='US'"
                              formControlName="stateorProvinceId" placeholder="Alaska">
                    <mat-option *ngFor="let s of lookups.stateTypes" [value]="s.key">
                      {{s.value}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="userForm.invalid">Please choose one.</mat-error>
                </mat-form-field>
              </div>

              <!--              province-->
              <div *ngIf="userForm.get('countryId')?.value === 'CA'">
                <mat-label style="font-size: medium;">Province</mat-label>
                <mat-form-field class="form-field" appearance="outline">
                  <mat-select id="province"
                              [required]="userForm.get('countryId')?.value === 'CA'"
                              formControlName="stateorProvinceId">
                    <!--                <mat-option>Placeholder</mat-option>-->
                    <mat-option *ngFor="let p of lookups.provinceTypes" [value]="p.key">
                      {{p.value}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="userForm.invalid">Please choose one.</mat-error>
                </mat-form-field>
              </div>

            </div>
            <div class="col-12 col-md-6">
              <!-- City -->
              <mat-label style="font-size: medium;">City</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input class="form-input" matInput placeholder="i.e. Toronto" formControlName="city" [required]="true"/>
              </mat-form-field>
            </div>
          </div>
          <br/>
          <!--        HEADER-->
          <br/>
          <!--          row start-->
          <div class="row" style="padding-top: 50px;">
            <span style="font-size: large; font-weight: bold;">Deal Overview</span>
          </div>
          <br/>
          <!--          row start-->
          <div class="row">
            <div class="col-12 col-md-6">
              <!--              deal type-->
              <div class="radio-group">
                <mat-label>Deal Type<sup>*</sup></mat-label>
                <mat-error *ngIf="userForm.invalid && (selectedDealIdx.length ==0)">Please select at least one deal
                  type.
                </mat-error>
                <div *ngFor="let dealType of lookups.dealTypes; index as i;">
                  <mat-checkbox [ngModelOptions]="{standalone: true}" color="primary"
                                [id]="'dt'+ i" [name]="'dt'+i" [(ngModel)]="selectedDealIdx[i]"
                                (change)="toggleDealType(dealType)">{{ dealType.value }}
                  </mat-checkbox>
                  <br/>
                </div>
              </div>
            </div>

            <div class="col-12 d-flex d-md-none">
              &nbsp;
            </div>

            <div class="col-12 col-md-6">
              <div class="radio-group">
                <mat-label>Asset Class<sup>*</sup></mat-label>
                <mat-error *ngIf="userForm.invalid && (selectedAssetIdx.length ==0)">Please select at least one asset
                  class.
                </mat-error>
                <div *ngFor="let asset of lookups.assetTypes;index as i;">
                  <mat-checkbox [ngModelOptions]="{standalone: true}" color="primary"
                                [id]="'ac'+ i" [name]="'ac'+i" [(ngModel)]="selectedAssetIdx[i]"
                                (change)="toggleAssetClass(asset)">{{ asset.value }}
                  </mat-checkbox>
                  <br/>
                </div>
              </div>
            </div>
          </div>
          <br/>
          <!--          row start-->
          <div class="row">
            <div class="col-12">
              <mat-label style="font-size: medium;">Keywords</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <mat-chip-list #chipList aria-label="Theme selection">
                  <mat-chip
                    *ngFor="let st of selectedThemes"
                    (removed)="removeTheme(st)">
                    {{st}}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                  <input
                    [placeholder]="selectedThemes.length==0? 'i.e. sustainable, litigationfinance, cashflowpositive': ''"
                    #themeInput
                    [formControl]="themeCtrl"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="addTheme($event)"/>
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTheme($event)">
                  <mat-option *ngFor="let ft of filteredThemes | async" [value]="ft">
                    {{ft}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
          <!--        HEADER-->
          <br/>
          <!--          row start-->
          <div class="row" style="padding-top: 50px;">
            <span style="font-size: large; font-weight: bold;">Size and Timing</span>
          </div>
          <br/>
          <!--          row start-->
          <div class="row">
            <div class="col-12 col-md-6">
              <!--             total fund size-->
              <mat-label style="font-size: medium;">Total Fund Size (Million USD)</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input type="number" matInput id="totSz" formControlName="fundraiseSize" [required]="true"
                       placeholder="50"/>
                <mat-error *ngIf="userForm.invalid">Please enter a number.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <!--              timeline-->
              <mat-label style="font-size: medium;">Timeline to Close</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <mat-select id="tltc" formControlName="timeLineTypeId" [required]="true" placeholder="1-4 weeks">
                  <mat-option *ngFor="let tl of lookups.timelineTypes" [value]="tl.key">
                    {{tl.value}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="userForm.invalid">Please choose one.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <br/>
          <!--          row start-->
          <div class="row">
            <div class="col-12 col-md-6">
              <!--              capacity to raise-->
              <mat-label style="font-size: medium;">Capacity to Raise on Rondeivu (Million USD)</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input type="number" matInput id="cr" formControlName="rondeivuFundraiseSize" [required]="true"
                       placeholder="20"/>
                <mat-error *ngIf="userForm.invalid">Please enter a number.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-3">
              <!-- has deal closed-->
              <mat-label style="font-size: medium;">Has first close occurred?</mat-label>
              <br/><br/>
              <mat-radio-group formControlName="firstCloseOccured">
                <mat-radio-button *ngFor="let obj of [{key:'Yes',value:true},{key:'No',value:false}]" color="primary"
                                  [value]="obj.value">
                  {{obj.key}}&nbsp;
                </mat-radio-button>
              </mat-radio-group>
              <mat-error *ngIf="userForm.invalid">Please choose one.</mat-error>
            </div>
            <div class="col-12 col-md-3">
              <!--  anchor seeking-->
              <mat-label style="font-size: medium;">Are you seeking an anchor/lead?</mat-label>
              <br/><br/>
              <mat-radio-group formControlName="anchorSeeking">
                <mat-radio-button *ngFor="let obj of [{key:'Yes',value:true},{key:'No',value:false}]" color="primary"
                                  [value]="obj.value">
                  {{obj.key}}&nbsp;
                </mat-radio-button>
              </mat-radio-group>
              <mat-error *ngIf="userForm.invalid">Please choose one.</mat-error>
            </div>
          </div>
          <!--        HEADER-->
          <br/>
          <div class="row" style="padding-top: 50px;">
            <span style="font-size: large; font-weight: bold;">Target and Return</span>
          </div>
          <br/>
          <!--          row start-->
          <div class="row">
            <div class="col-12 col-md-6">
              <!-- return bear case-->
              <mat-label style="font-size: medium;">Target Return - Bear Case (MOIC Multiple)</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input type="number" matInput id="bearc" formControlName="targetMoicBear" [required]="true"
                       placeholder="50">
                <mat-error *ngIf="userForm.invalid">Please enter a number.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <!--min duration-->
              <mat-label style="font-size: medium;">Investment Timeline - Minimum Duration (years)</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input type="number" matInput id="mind" formControlName="minimumDuration" required
                       placeholder="10">
                <mat-error *ngIf="userForm.invalid">Please enter a number.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <br/>
          <!-- row start-->
          <div class="row">
            <div class="col-12 col-md-6">
              <!-- base case-->
              <mat-label style="font-size: medium;">Target Return - Base Case (MOIC Multiple)</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input type="number" matInput id="basec" formControlName="targetMoicBase" required
                       placeholder="20">
                <mat-error *ngIf="userForm.invalid">Please enter a number.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <!--              expected duration-->
              <mat-label style="font-size: medium;">Investment Timeline - Expected Duration (years)</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input type="number" matInput id="expd" formControlName="expectedDuration" required
                       placeholder="25">
                <mat-error *ngIf="userForm.invalid">Please enter a number.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <br/>
          <!--          row start-->
          <div class="row">
            <div class="col-12 col-md-6">
              <!--              best case-->
              <mat-label style="font-size: medium;">Target Return - Best Case (MOIC Multiple)</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input type="number" matInput id="bestc" formControlName="targetMoicBest" required
                       placeholder="110">
                <mat-error *ngIf="userForm.invalid">Please enter a number.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <!--max duration-->
              <mat-label style="font-size: medium;">Investment Timeline - Maximum Duration (years)</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <input type="number" matInput id="maxD" formControlName="maximumDuration" required
                       placeholder="30">
                <mat-error *ngIf="userForm.invalid">Please enter a number.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <br/>
          <div class="row" style="padding-top: 50px;">
            <span style="font-size: large; font-weight: bold;">Additional Notes</span>
          </div>
          <br/>
          <br/>
          <!-- row start-->
          <div class="row">
            <div class="col-12">
              <!--notes-->
              <mat-label style="font-size: medium;">Private Notes</mat-label>
              <mat-form-field class="form-field" appearance="outline">
                <textarea matInput id="mints" placeholder="Example notes..." formControlName="notes"></textarea>
              </mat-form-field>
            </div>
          </div>
          <!-- BUTTONS-->
          <div class="row" style="padding-top: 50px;">
            <div class="col-12 text-end">
              <button mat-raised-button
                      color="primary"
                      type="submit"
                      [disabled]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_METRICS)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_EDIT_DEAL_METRICS) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_EDIT_DEAL_METRICS) ? util.NoAccessMsg : ''"
                      matTooltipPosition="left"
              >SAVE CHANGES
              </button>
            </div>
          </div>
        </div>
      </div>
      <br/>
    </form>
  </div>
</div>

