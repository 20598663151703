import {Injectable} from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {IApprovalRequest} from "../../models";

@Injectable({
  providedIn: 'root'
})
export class ApprovalsService {

  private readonly BASE_URL = environment.rondeivu_api
  private readonly FRAGMENT = '/approvals';

  private readonly BUSINESS_UNIT = '/businessunit';
  private readonly MANDATE = '/mandate';
  private readonly DEAL = '/deal';

  constructor(private http: HttpClient) {
  }

  getApprovals(type: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + '/' + type);
  }

  /**
   * Sends the approval based on type
   * @param approval
   */
  addApproval(approval: IApprovalRequest): Observable<any> {
    switch (approval.type) {
      case 'deal':
        return this.addDealApproval(approval);
      case 'mandate':
        return this.addMandateApproval(approval);
      case 'businessunit':
        return this.addBusinessUnitApproval(approval);
    }
  }

  private addDealApproval(approval: IApprovalRequest): Observable<any> {
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.DEAL, approval);
  }

  private addMandateApproval(approval: IApprovalRequest): Observable<any> {
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.MANDATE, approval);
  }

  private addBusinessUnitApproval(approval: IApprovalRequest): Observable<any> {
    return this.http.post(this.BASE_URL + this.FRAGMENT + this.BUSINESS_UNIT, approval);
  }
}
