export {NgMaterialModule} from './ng-material/ng-material.module';
export {ReduxModule} from './redux/redux.module';
export {SharedModule} from './shared/shared.module';
//rondeivu modules - lazy loaded
export {DashboardModule} from './dashboard/dashboard.module';
export {DealsModule} from './deals/deals.module';
export {ApprovalsModule} from './approvals/approvals.module';
export {MandatesModule} from './mandates/mandates.module';
export {UsersModule} from './users/users.module';
export {AdminModule} from './admin/admin.module';
export {SettingsModule} from './settings/settings.module';
export {BusinessUnitsModule} from './business-units/business-units.module';
export {LegalModule} from './legal/legal.module';
export {EntitiesModule} from './entities/entities.module';
export {KycModule} from './kyc/kyc.module';
export {CompanyModule} from './company/company.module';
