import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CloudwatchService} from "../../services";
import {Subscription} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {
  Config,
  getConfig,
  getSelectedBusinessUnit,
  initialConfigState,
  initialThemeState,
  Theme,
  toggleLoading
} from "../../../redux";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {IBusinessUnit} from "../../../business-units/models";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {MatDialog} from "@angular/material/dialog";
import {ISnsBounceLog} from "../../models";

@Component({
  selector: 'app-cloudwatch-sns-bounce-log',
  templateUrl: './cloudwatch-sns-bounce-log.component.html',
  styleUrls: ['./cloudwatch-sns-bounce-log.component.scss']
})
export class CloudwatchSnsBounceLogComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();

  dataSource: MatTableDataSource<ISnsBounceLog> = new MatTableDataSource();
  config: Config = initialConfigState;
  theme: Theme = initialThemeState;
  columnsToDisplay = ['time', 'to', 'from', 'subject'];

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  selectedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;

  dataList: ISnsBounceLog[] = [];
  sortedDataList: ISnsBounceLog[] = [];

  constructor(private cloudwatch: CloudwatchService,
              private toastr: ToastrService,
              private router: Router,
              private store: Store,
              private dialog: MatDialog) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.selectedBusinessUnit = Object.assign({}, bu);
      if (!!this.selectedBusinessUnit && !!this.selectedBusinessUnit.businessUnitId) {
        this.subscribeToDatasource();
      }
    });

  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
  }

  subscribeToDatasource(): void {
    console.log("Fetching SNS Bounce Log...");
    this.getSnsBounceLog();
  }

  getSnsBounceLog() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.cloudwatch.getSnsBounceLog().subscribe({
      next: (res: ISnsBounceLog[]) => {
        this.dataList = res;
        this.dataSource = new MatTableDataSource(res);
        this.initDataSource();
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Something went wrong!");
      }
    });
  }

  initDataSource() {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const lc_filter = filter.toLowerCase();
      const ts = data.mail.timestamp.toLowerCase().includes(lc_filter);
      // const dest = data.destination[0].toLowerCase().includes(lc_filter);
      const to = this.getHeader(data, 'To').includes(lc_filter);
      const frm = this.getHeader(data, 'From').toLowerCase().includes(lc_filter);
      const sub = this.getHeader(data, 'Subject').toLowerCase().includes(lc_filter);

      return ts || to || frm || sub;
    };
    if (!!this.paginator) {
      this.paginator.pageSize = 100;
      this.dataSource.paginator = this.paginator;
    }
    if (!!this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getHeader(element: ISnsBounceLog, header: string) {
    return element.mail.headers.filter(h => {
      return h.name == header;
    })[0].value;
  }

  //sort
  sortData(sort: Sort) {
    const data = this.dataList.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedDataList = data;
      return;
    }

    this.sortedDataList = data.sort((a: ISnsBounceLog, b: ISnsBounceLog) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'time':
          return compare(a.mail.timestamp || '', b.mail.timestamp || '', isAsc);
        case 'to':
          return compare(this.getHeader(a, 'To') || '', this.getHeader(b, 'To') || '', isAsc);
        case 'from':
          return compare(this.getHeader(a, 'From') || '', this.getHeader(b, 'From') || '', isAsc);
        case 'subject':
          return compare(this.getHeader(a, 'Subject') || '', this.getHeader(b, 'Subject') || '', isAsc);
        default:
          return 0;
      }
    });

    this.dataSource = new MatTableDataSource(this.sortedDataList);
    this.initDataSource();
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
