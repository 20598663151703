<!--        user menu button-->
<div [class]="'social-card ' + (!highlightOnHover? '' : 'social-card-hover')">
  <div class="social-card-inner" [ngStyle]="{'border':getBorder(), 'border-left': getSide()}">
    <!--    CIRCLE START-->
    <div *ngIf="!image"
         [class]="'number number-' + userCardSize[size].toString().toLowerCase() + (imageShape =='square'? '' : ' circle')"
         [ngStyle]="{'background':background}">
      <div *ngIf="!icon">
          <span class="first-name-letter">{{ firstName?.substring(0, 1)?.toUpperCase() || 'U' }}</span>
          <span class="last-name-letter">{{ lastName?.substring(0, 1)?.toUpperCase() }}</span>
      </div>
      <mat-icon *ngIf="!!icon" [class]="'icon-'+ userCardSize[size].toString().toLowerCase()"
                [ngStyle]="{'background':background}">{{ icon }}
      </mat-icon>
    </div>
    <!--CIRCLE END-->
    <!--IMAGE START-->
    <img *ngIf="!!image" [src]="image"
         [class]="'img-'+ userCardSize[size].toString().toLowerCase() + (imageShape =='square'? ' img-square' : ' img')"
         [ngStyle]="{'background':background}">
    <!--    IMAGE END-->
    <!--  text area -->
    <div [class]="'text-container text-container-' + userCardSize[size].toString().toLowerCase()">
      <span>{{ (firstName || 'Unknown') + ' ' + lastName }}</span><br/>
      <span [class]="'user-select user-select-' + userCardSize[size].toString().toLowerCase()"
            *ngIf="!!displayName">{{ displayName }}</span>
    </div>
  </div>
</div>
