import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanDeactivate,
  CanLoad,
  Route, Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {IBusinessUnit} from "../modules/business-units/models";
import {BusinessUnitService} from "../modules/business-units/services";
import {UtilService} from "../modules/shared/services";
import {AuthService} from "../services";
import {updateSelectedBusinessUnit} from "../modules/redux";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {
  private readonly LOGIN_PATH = '/auth/login';

  constructor(
    private util: UtilService,
    private auth: AuthService,
    private store: Store,
    private router: Router,
    private toastr: ToastrService,
    private businessUnitService: BusinessUnitService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log("Running auth guard!");
    return this.canAccess(route, state);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canDeactivate(component: unknown, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState ?: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canLoad(route: Route, segments: UrlSegment[]):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  async canAccess(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.auth.isValidCognitoSession().then((validCognito: boolean) => {
        this.isValidBusinessUnit(route, state).then((validBusinessUnit: boolean) => {
          resolve(true);
        }).catch(businessUnitError => {
          reject(false);
          this.util.logOut().then(() => {
            this.toastr.error("Access denied!", $localize`:@@companyName:Rondeivu`);
          });
        });
      }).catch(cognitoError => {
        this.util.setRedirectPath(state.url);
        this.router.navigate([this.LOGIN_PATH]).then(() => {
          this.toastr.error("Unable to determine access!", $localize`:@@companyName:Rondeivu`);
          reject(false);
        });
      })
    });
  }

  /**
   * Fetches the user business units and determines if the requested slug belongs to the users business units
   * @param route
   * @param state
   * @private
   */
  private isValidBusinessUnit(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let verified = false;
      let businessUnitId = route.paramMap.get('id') || '';
      this.businessUnitService.getUserBusinessUnits().subscribe({
        next: (businessUnits: IBusinessUnit[]) => {
          // verify by slug
          if (businessUnits.length == 0) {
            this.util.logOut().then(() => {
              this.toastr.warning('Sorry, you have no available business unit access. Please try again later!', $localize`:@@companyName:Rondeivu`);
              resolve(true);
            });
          } else {
            let verifiedBusinessUnit: IBusinessUnit = {} as unknown as IBusinessUnit;
            businessUnits.forEach(businessUnit => {
              if (businessUnit.slug == businessUnitId) {
                verifiedBusinessUnit = businessUnit;
                verified = true;
              }
            });
            if (verified) {
              this.store.dispatch(updateSelectedBusinessUnit({businessUnit: verifiedBusinessUnit}));
              resolve(true);
            } else {
              if (this.hasAdminBusinessUnits(businessUnits)) {
                const specials = /[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g;
                if (!specials.test(businessUnits[0].slug)) {
                  // there are business units but the user does not have permission to the requested business unit
                  let path: string = '/' + businessUnits[0].slug + '/deals';
                  const message: string = 'You do not have employee access to the requested business unit, use your Admin account to access this route!';
                  // user has an admin business unit so override requested business unit to reach the route
                  const adminBusinessUnit = this.getFirstAdminBusinessUnit(businessUnits);
                  if (!!adminBusinessUnit) {
                    path = state.url.replace(businessUnitId, adminBusinessUnit.slug);
                    this.store.dispatch(updateSelectedBusinessUnit({businessUnit: adminBusinessUnit}));
                    this.router.navigate([path]).then(() => {
                      this.toastr.warning(message, $localize`:@@companyName:Rondeivu`);
                      resolve(true);
                    });
                  }
                } else {
                  this.toastr.warning('Invalid Business Unit Slug!', $localize`:@@companyName:Rondeivu`);
                  resolve(true);
                }
              } else {
                this.toastr.warning('You do not have access to the requested business unit!', $localize`:@@companyName:Rondeivu`);
                reject(false);
              }
            }
          }
        }, error: (err: any) => {
          // error subscribing to business units
          this.toastr.error("Access denied by auth guard!", $localize`:@@companyName:Rondeivu`);
          reject(false);
        }
      });
    });
  }

  private hasAdminBusinessUnits(businessUnits: IBusinessUnit[]): boolean {
    let has = false;
    businessUnits.forEach(businessUnit => {
      if (businessUnit.businessUnitType?.toLowerCase() == 'admin') {
        has = true;
      }
    });
    return has;
  }

  private getFirstAdminBusinessUnit(businessUnits: IBusinessUnit[]): IBusinessUnit | null {
    for (let i = 0; i <= businessUnits.length - 1; i++) {
      if (businessUnits[i].businessUnitType?.toLowerCase() == 'admin') {
        return businessUnits[i];
      }
    }
    return null;
  }
}
