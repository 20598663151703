import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-negotiate-modal',
  templateUrl: './negotiate-modal.component.html',
  styleUrls: ['./negotiate-modal.component.scss']
})
export class NegotiateModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<NegotiateModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { ndaType: string, proceed: boolean }) {
  }

  ngOnInit(): void {
  }

}
