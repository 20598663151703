import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  Config, getAssetTypes,
  getConfig, getDealTypes,
  getLookups, getSelectedBusinessUnit, getSelectedDeal, getSelectedDealId,
  initialConfigState,
  initialLookupsState,
  Lookups,
  toggleLoading
} from "../../../redux";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {DealService} from "../../services";
import {IBusinessUnit} from "../../../business-units/models";
import {FormControl, FormGroup} from "@angular/forms";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {Observable, of, startWith, Subscription} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {ILookup} from "../../../../models";
import {IDealRequest} from "../../models";
import {HttpErrorResponse} from "@angular/common/http";
import {map} from "rxjs/operators";
import {UtilService} from "../../../shared/services";
import {IDealCard} from "../../../shared/models";

@Component({
  selector: 'app-deal-metrics',
  templateUrl: './deal-metrics.component.html',
  styleUrls: ['./deal-metrics.component.scss']
})
export class DealMetricsComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();

  private config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;
  deal: any = {};

  //reactive form
  userForm = new FormGroup({
    id: new FormControl(),
    issuerEntityName: new FormControl('', []),
    countryId: new FormControl('', []),
    stateorProvinceId: new FormControl('', []),
    city: new FormControl('', []),
    issuerDeals: new FormControl([''], []),
    issuerDealAssets: new FormControl([''], []),
    issuerDealAreasOfFocus: new FormControl([''], []),
    fundraiseSize: new FormControl(),
    timeLineTypeId: new FormControl(),
    rondeivuFundraiseSize: new FormControl('', []),
    firstCloseOccured: new FormControl(),
    anchorSeeking: new FormControl(),
    targetMoicBear: new FormControl(),
    minimumDuration: new FormControl(),
    targetMoicBase: new FormControl(),
    expectedDuration: new FormControl(),
    targetMoicBest: new FormControl(),
    maximumDuration: new FormControl(),
    notes: new FormControl(),
  });

  // deal type and asset class selection
  selectedIssuerDealAssets: string[] = [];
  selectedAssetIdx: boolean[] = [];
  selectedIssuerDeals: string[] = [];
  selectedDealIdx: boolean[] = [];

  //theme input
  @ViewChild('themeInput') themeInput: any;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  themeCtrl = new FormControl('');
  filteredThemes: Observable<string[]>;
  selectedThemes: string[] = [];

  bu: IBusinessUnit = {} as unknown as IBusinessUnit;
  selectedDeal$: Subscription = new Subscription();
  selectedDeal: IDealCard = {} as unknown as IDealCard;

  constructor(
    private store: Store,
    private router: Router,
    private toastr: ToastrService,
    private dealService: DealService,
    public util: UtilService
  ) {
    this.config$ = this.store.select(getConfig).subscribe(config => {
      this.config = config;
    });

    this.lookup$ = this.store.select(getLookups).subscribe(lookups => {
      this.lookups = lookups;
    });

    this.lookup$.add(this.store.select(getDealTypes).subscribe(dealTypes => {
      this.patchFormControls();
    }));

    this.lookup$.add(this.store.select(getAssetTypes).subscribe(assetTypes => {
      this.patchFormControls();
    }));

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      this.bu = Object.assign({}, bu);
    });

    this.selectedDeal$ = this.store.select(getSelectedDeal).subscribe((deal: IDealCard) => {
      if (this.selectedDeal.id != deal.id) {
        this.selectedDeal = deal;
        this.subscribeToDatasource();
      } else {
        this.selectedDeal = deal;
      }
    });

    //add the possible themes
    this.filteredThemes = this.themeCtrl.valueChanges.pipe(
      startWith(null),
      map((theme: string | null) => (theme ? this._filter(theme) : this.lookups.areasOfFocus.map(l => {
        return l.value
      }).slice())),
    );
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.selectedDeal$.unsubscribe();
  }

  subscribeToDatasource(): void {
    console.log("Fetching Deal Metrics...");
    this.dealService.getDealMetrics().subscribe({
      next: (ds: any) => {
        this.deal = ds;
        // set the selected assets
        this.patchFormControls();
        try {
          this.userForm.patchValue(this.deal);
        } catch (err: any) {
          this.toastr.error(err.message(), $localize`:@@companyName:Rondeivu`);
        }
      }, error: error => {
        this.toastr.error("Unable to get deal metrics!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  patchFormControls() {
    if (!!this.deal) {
      this.applyAssetTypes();
      this.applyDealTypes();
      this.applyKeywords();
    }
  }

  applyDealTypes() {
    //set the selected deals
    if (!!this.deal?.issuerDeals) {
      this.selectedIssuerDeals = this.deal.issuerDeals;
      for (let p = 0; p <= this.deal.issuerDeals.length - 1; p++) {
        for (let q = 0; q <= this.lookups.dealTypes.length - 1; q++) {
          if (this.deal.issuerDeals[p].key === this.lookups.dealTypes[q].key) {
            this.selectedDealIdx[q] = true;
          }
        }
      }
    }
  }

  applyAssetTypes() {
    if (!!this.deal?.issuerDealAssets) {
      this.selectedIssuerDealAssets = this.deal.issuerDealAssets;
      for (let i = 0; i <= this.deal.issuerDealAssets.length - 1; i++) {
        for (let x = 0; x <= this.lookups.assetTypes.length - 1; x++) {
          if (this.deal.issuerDealAssets[i].key === this.lookups.assetTypes[x].key) {
            this.selectedAssetIdx[x] = true;
          }
        }
      }
    }
  }

  applyKeywords() {
    //map to array of string
    if (!!this.deal.issuerDealAreasOfFocus) {
      this.selectedThemes = this.deal.issuerDealAreasOfFocus.map((theme: any) => {
        return theme.key;
      });
    }
  }


  /**
   * Action for adding a new file
   * @param event
   */
  addDocuments(event: any) {
    // console.log(event);
  }

  save() {
    let payload = this.userForm.getRawValue() as unknown as IDealRequest;
    //standalone form obj
    payload.issuerDealAssets = this.deal.issuerDealAssets.map((x: any) => {
      return {key: x.key};
    });
    //standalone form obj
    payload.issuerDeals = this.deal.issuerDeals.map((y: any) => {
      return {key: y.key};
    });
    //areas of focus has to be mapped to obj
    payload.issuerDealAreasOfFocus = Object.assign(this.selectedThemes.map(t => {
      return {"key": t};
    }));

    let notes = this.userForm.get('notes')?.value || '';
    payload.notes = notes.toString();

    this.store.dispatch(toggleLoading({loading: true}));
    this.dealService.updateDealMetrics(payload).subscribe((ds: any) => {
      // console.log(JSON.stringify(ds));
      this.userForm.patchValue(ds);
      this.store.dispatch(toggleLoading({loading: false}));
      this.toastr.success("Deal Metrics Updated!", $localize`:@@companyName:Rondeivu`);
    }, (error: HttpErrorResponse) => {
      this.store.dispatch(toggleLoading({loading: false}));
      this.toastr.error("Unable to update deal metrics!", $localize`:@@companyName:Rondeivu`);
    });
  }

  isCountry(id?: string | null, country?: string) {
    let found = false;
    if (!!id && !!country) {
      for (let i = 0; i <= this.lookups.countries.length - 1; i++) {
        if (this.lookups.countries[i].key == id) {
          if (this.lookups.countries[i].value == country) {
            found = true;
            break;
          }
        }
      }
    }
    return found;
  }

  /**
   * Form action for selecting deal type
   * @param deal
   */
  toggleDealType(deal: ILookup) {
    this.toggleLookup(this.deal.issuerDeals, deal);
  }

  /**
   * Form action for selecting asset class
   * @param asset
   */
  toggleAssetClass(asset: ILookup) {
    this.toggleLookup(this.deal.issuerDealAssets, asset);
  }

  /**
   * This is a core function for all component pages that use the multicheckbox for the lookup selection
   * @param list the list to modify
   * @param lookup the lookup to toggle
   * @private
   */
  private toggleLookup(list: { key: string }[], lookup: ILookup) {
    if (!!list) {
      //search for the key of the lookup
      let found = false;
      let foundIdx = -1;
      for (let i = 0; i <= list.length - 1; i++) {
        if (list[i].key === lookup.key) {
          found = true;
          foundIdx = i;
          break;
        }
      }
      //only add if not found
      if (!found) {
        list.push(lookup);
      } else {
        if (foundIdx >= 0) {
          list.splice(foundIdx, 1);
        }
      }
      // console.log(list);
    }
  }

  /**
   * Add theme/keyword to the list
   */
  addTheme(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add our theme
    // console.log(value);
    if (value && !this.selectedThemes.includes(value)) {
      this.selectedThemes.push(value);
    }
    // Clear the input value
    event.chipInput!.clear();
    this.themeCtrl.setValue(null);
  }

  /**
   * Remove theme/keyword form the list
   * @param theme
   */
  removeTheme(theme: string): void {
    const index = this.selectedThemes.indexOf(theme);

    if (index >= 0) {
      this.selectedThemes.splice(index, 1);
    }
  }

  /**
   * Form action for selecting or typing a theme/keyword
   * @param event the selected event
   */
  selectedTheme(event: MatAutocompleteSelectedEvent): void {
    if (!this.selectedThemes.includes(event.option.viewValue)) {
      this.selectedThemes.push(event.option.viewValue);
    }
    if (!!this.themeInput) {
      this.themeInput.nativeElement.value = '';
    }
    this.themeCtrl.setValue(null);
  }

  /*
  Filters the autocomplete list
   */
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    // console.log(this.lookups.areasOfFocus.map(l=>{return l.value}));
    return this.lookups.areasOfFocus.map(l => {
      return l.value
    }).filter(theme => theme.toLowerCase().includes(filterValue));
  }
}
