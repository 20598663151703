import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {fadeInAnimation, slideInLeftAnimation, slideOutLeftAnimation} from "angular-animations";
import {ThemePalette} from "@angular/material/core";
import {
  Config,
  getConfig, getSelectedBusinessUnit,
  getThemeState,
  initialConfigState,
  initialThemeState,
  Theme,
  toggleLoading, updateUserId
} from "../../../redux";
import {UsersService} from "../../../users/services/users/users.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {IAppUserRequest} from "../../../users/models/i-users";
import {Subscription} from "rxjs";
import {IBusinessUnit} from "../../../business-units/models";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-legal-navigation',
  templateUrl: './legal-navigation.component.html',
  styleUrls: ['./legal-navigation.component.scss'],
  animations: [slideInLeftAnimation(), fadeInAnimation(), slideOutLeftAnimation()],
})
export class LegalNavigationComponent implements OnInit, OnDestroy, AfterViewInit {
  loading = false;
  hidden = true;
  animationState = false;
  animateSlide = false;
  animateExit = false;
  links = [
    {title: 'Information', link: '/information', enabled: true, hidden: false}
  ];
  activeLink = {};
  background: ThemePalette = undefined;

  legalId: string | null = '';
  selectedUser: IAppUserRequest = {} as unknown as IAppUserRequest;

  config$: Subscription = new Subscription();
  theme$: Subscription = new Subscription();
  selectedBusinessUnit$: Subscription = new Subscription();
  route$: Subscription = new Subscription();

  config: Config = initialConfigState;
  theme: Theme = initialThemeState;

  constructor(
    private usersService: UsersService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private toastrService: ToastrService
  ) {
    this.route$ = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.loading = true;
        this.store.dispatch(toggleLoading({loading: true}));
        setTimeout(() => {
          this.store.dispatch(toggleLoading({loading: false}));
          this.loading = false;
        }, 1000);
      }
    });

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.theme$ = this.store.select(getThemeState).subscribe((theme: any) => {
      this.theme = theme;
    });

    this.selectedBusinessUnit$ = this.store.select(getSelectedBusinessUnit).subscribe((bu: IBusinessUnit) => {
      if (!!bu && !!bu.businessUnitId) {
      }
    });
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.theme$.unsubscribe();
    this.selectedBusinessUnit$.unsubscribe();
    this.route$.unsubscribe();
  }

  ngAfterViewInit() {
    this.animateIn();
  }

  getLegal() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 1000);

    if (!!this.legalId) {
    }
  }

  animateIn() {
    this.hidden = false;
    this.store.dispatch(toggleLoading({loading: true}));
    this.animationState = false;
    setTimeout(() => {
      this.animationState = true;
      this.store.dispatch(toggleLoading({loading: false}));
    }, 1000);
  }

  getUserIdFromRoute() {
    this.legalId = this.route.snapshot.paramMap.get('id');
    if (!!this.legalId) {
      this.store.dispatch(updateUserId({userId: this.legalId}));
    }
  }

  setActiveLink() {
    const fragments = this.router.url.split('/');
    let link = '/' + fragments[fragments.length - 1];
    this.links.forEach(l => {
      if (l.link == link) {
        this.activeLink = l;
      }
    });
  }

  animStart(event: any) {
    // console.log('Animation Started', event);
  }

  exitEnd(event: any) {
    // console.log('Animation Ended', event);
  }

  toggleBackground() {
    this.background = this.background ? undefined : 'primary';
  }

  addLink() {
    let l = `Link ${this.links.length + 1}`;
    this.links.push({title: l, link: l, enabled: true, hidden: false});
  }

  navigate(obj: { title: string; link: string; enabled: boolean }) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.animateSlide = false;
    setTimeout(() => {
      this.animateSlide = true;
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/legal/' + this.legalId + obj.link]);
    }, 300);
  }

  goBack() {
    this.store.dispatch(toggleLoading({loading: true}));
    this.animateExit = false;
    setTimeout(() => {
      this.animateExit = true;
      this.store.dispatch(toggleLoading({loading: false}));
      this.router.navigate(['/' + this.config.selected_business_unit.slug + '/legal']);
    }, 2000);
  }

}
