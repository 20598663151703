<div style="height:calc(100vh - 176px); overflow-y: auto;">
  <div class="container-fluid">
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-12 col-md-6">
        <span class="rm-title rm-title-3">KYC Verification</span><br/>
        <span
          class="rm-title rm-title-6 rm-lighter">These are of the kyc processes for the selected business unit:</span>
      </div>
      <div class="col-12 col-md-3 text-end">
        <mat-form-field class="form-field" appearance="outline" *ngIf="!!dataSource && !!dataSource.data[0]">
          <mat-label>Status</mat-label>
          <mat-select [(ngModel)]="dataSource.data[0].status"
                      (selectionChange)="updateStatus($event)"
                      [disabled]="!util.hasPermission(util.Permission.CAN_CHANGE_KYC_STATUS)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_CHANGE_KYC_STATUS) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_CHANGE_KYC_STATUS) ? util.NoAccessMsg : ''"
                      matTooltipPosition="left"
          >
            <mat-option *ngFor="let s of lookups.kycStatusTypes" [value]="s.key">
              <span>{{s.value}}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-2 text-end" style="padding-top: 10px;">
        <button mat-raised-button color="primary"
                (click)="addKyc(dataSource.data[0])"
                [disabled]="!util.hasPermission(util.Permission.CAN_ADD_KYC)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_KYC) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_ADD_KYC) ? util.NoAccessMsg : ''"
                matTooltipPosition="left"
        >
          <mat-icon>note_add</mat-icon>&nbsp;INITIATE
          KYC
        </button>
      </div>
    </div>
    <div class="row justify-content-center" style="padding-top: 25px;"
         *ngIf="!!dataSource.data && dataSource.data.length >= 1">
      <div class="col-12 col-md-11">
        <app-user-card [firstName]="dataSource.data[0]?.businessUnit.displayName || undefined"
                       [displayName]="dataSource.data[0]?.businessUnit.profile || undefined"
                       [borderColor]="'transparent'"
                       [sideColor]="'#ff0000'"
                       [size]="userCardSize.XL"
                       [image]="dataSource.data[0]?.businessUnit.imageUrl || undefined"
        ></app-user-card>
      </div>
    </div>
    <!--      STANDARD STATE -->
    <div class="row justify-content-center" *ngIf="!!dataSource.data && dataSource.data.length >= 1"
         style="padding-top: 25px;padding-bottom: 150px;">
      <div class="col-12 col-md-11">
        <div>
          <table *ngIf="!!dataSource.data[0]?.kycProcessItems"
                 mat-table [dataSource]="dataSource.data[0]?.kycProcessItems"
                 class="inner-table">
            <!--                    header-->
            <ng-container matColumnDef="status">
              <th colspan="1" mat-header-cell *matHeaderCellDef class="inner-header">Status</th>
              <td mat-cell *matCellDef="let element">
                <span [appStatus]="element.status">{{element.status}}</span>
              </td>
            </ng-container>
            <!--                    header-->
            <ng-container matColumnDef="name">
              <th colspan="1" mat-header-cell *matHeaderCellDef class="inner-header">Display Name</th>
              <td mat-cell *matCellDef="let element"> {{element.entityName}} </td>
            </ng-container>
            <!--                    header-->
            <ng-container matColumnDef="created">
              <th mat-header-cell *matHeaderCellDef class="inner-header"> Created</th>
              <td mat-cell *matCellDef="let element"> {{ element.created | date }}</td>
            </ng-container>
            <!--                    header-->
            <ng-container matColumnDef="view">
              <th mat-header-cell *matHeaderCellDef class="inner-header"> Actions</th>
              <td mat-cell *matCellDef="let element">
                <div class="d-flex">
                  <button mat-icon-button
                          color="warn"
                          (click)="delete(element)"
                          [disabled]="!util.hasPermission(util.Permission.CAN_DELETE_KYC)"
                          [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_DELETE_KYC) ? util.NoAccessCursor: ''}"
                          [matTooltip]="!util.hasPermission(util.Permission.CAN_DELETE_KYC) ? util.NoAccessMsg : 'Delete KYC'"
                          matTooltipPosition="left"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                  <button mat-icon-button (click)="viewKycProcess(element)" matTooltip="View KYC"
                          matTooltipPosition="right">
                    <mat-icon>east</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="kycProcessCols; sticky:true;"></tr>
            <tr mat-row *matRowDef="let element; columns: kycProcessCols;">
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
