// the store model
import {IBusinessUnit, IBusinessUnitRole} from "../../../business-units/models";
import {IDealCard} from "../../../shared/models";
import {iMandateResponse} from "../../../mandates/models";
import {IDealSubscription, IDealSyndication} from "../../../deals/models";
import {INavItem, ITask} from "../../../../models";
import {IBusinessUnitEntity} from "../../../entities/models/entity";

export class Config {
  screenHeight = 0;
  screenWidth = 0;
  is_loading: boolean = false;
  is_navigating: boolean = false;
  show_navigation_menu: boolean = true;
  show_navigation_toolbar: boolean = true;
  show_notes_tab: boolean = false;
  right_drawer_open: boolean = false;
  right_drawer_tab_idx: number = 0;
  rondeivu_app_title: string = '';
  rondeivu_header_title: string = '';
  rondeivu_address_1: string = '';
  rondeivu_address_2: string = '';
  rondeivu_address_3: string = '';
  rondeivu_email: string = '';
  rondeivu_phone: string = '';
  rondeivu_api: string = '';
  rondeivu_api_base_uri: string = '';
  rondeivu_theme_dark: boolean = false;
  user_groups: string[] = [];
  nav_items: INavItem[] = [];
  selected_user_view: string = '';
  selected_deal_id: string = '';
  selected_deal: IDealCard = {} as unknown as IDealCard;
  deal_tabs: INavItem[] = [];
  selected_deal_role: IBusinessUnitRole = {} as unknown as IBusinessUnitRole;
  selected_deal_root_folders: any[] = [];
  selected_business_unit: IBusinessUnit = {} as unknown as IBusinessUnit;
  selected_business_unit_role: IBusinessUnitRole = {} as unknown as IBusinessUnitRole;
  selected_business_unit_entity_id: string = '';
  selected_business_unit_entity: IBusinessUnitEntity = {} as unknown as IBusinessUnitEntity;
  business_units: IBusinessUnit[] = [];
  editing_business_unit: IBusinessUnit = {} as unknown as IBusinessUnit;
  editing_business_unit_employee: any = {};
  selected_mandate_id: string = '';
  selected_mandate: iMandateResponse = {} as unknown as iMandateResponse;
  selected_user_id: string = '';
  selected_user: any = {};
  selected_legal_id: string = '';
  selected_legal: any = {};
  selected_kyc_id: string = '';
  selected_kyc: any = {};
  selected_deal_team_member: any = {};

  selected_entity: any = {};
  selected_entity_id: string = '';
  selected_entity_kyc: any = {};
  selected_entity_kyc_id: string = '';


  //todo needs employee model
  selected_employee_id: string = '';
  selected_employee: any = {} as unknown as any;

  selected_deal_team_id: string = '';
  selected_investor_deal_match_id: string = '';

  deal_subscription: IDealSubscription = {} as unknown as IDealSubscription;
  deal_syndication: IDealSyndication = {} as unknown as IDealSyndication;

  tasks: ITask[] = [];

  constructor(data: any) {
    Object.assign(this, data);
  }
}



