import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from 'src/app/components';
import {
  ApprovalsNavigationComponent,
  InvestorApprovalsComponent,
  IssuerApprovalsComponent
} from "./components/";


const routes: Routes = [
  {
    path: '', component: ApprovalsNavigationComponent, children:[
      {path:'issuer', component: IssuerApprovalsComponent},
      {path:'investor', component: InvestorApprovalsComponent},
      {path: '', redirectTo: 'issuer', pathMatch: 'full'},
      {path: '**', redirectTo: 'issuer'}
    ]
  },
  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ApprovalsRoutingModule {}
