import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Subscription} from "rxjs";
import {Config, getConfig, getLookups, initialConfigState, initialLookupsState, Lookups} from "../../../redux";
import {FormControl, FormGroup} from "@angular/forms";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {KycService, UtilService} from "../../services";

@Component({
  selector: 'app-kyc-modal',
  templateUrl: './kyc-modal.component.html',
  styleUrls: ['./kyc-modal.component.scss']
})
export class KycModalComponent implements OnInit, OnDestroy {
  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;

  userForm = new FormGroup({
    id: new FormControl(),
    entityName: new FormControl(),
    kycProfileSubType: new FormControl(),
  });

  loading: boolean = false;
  docs: { name: string, url: string }[] = [];

  constructor(public dialogRef: MatDialogRef<KycModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { isEditing: boolean, kyc: any, profile: string },
              private store: Store,
              private toastr: ToastrService,
              private kycService: KycService,
              public util:UtilService) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });
  }

  ngOnInit(): void {
    if (!!this.data && this.data.isEditing) {
      console.log("Editing KYC");
      this.userForm.patchValue({
        id: this.data.kyc.id,
        entityName: this.data.kyc.entityName,
        kycProfileSubType: this.data.kyc.kycProfileSubType
      });
    }
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
  }

  submit() {
    this.dialogRef.close(this.userForm.getRawValue());
  }

  /**
   * Issuers and investors show their kyc types but for admin it is based on the
   * incoming business unit profile type to the modal
   * @param type the type of user this control should show for
   */
  shouldShowTypesFor(type: string) {
    if (this.util.isType(type)) {
      return true;
    } else if (this.util.isType('admin')) {
      if (!!this.data && !!this.data?.profile && this.data.profile?.toLowerCase() === type) {
        return true;
      }
    }
    return false;
  }

  /**
   * Fetched the kyc documents when the kyc type changes
   * @param event the form control event
   */
  getKycDocuments(event: any) {
    // console.log(event.value);
    this.loading = true;
    this.kycService.getKycDetails(event.value).subscribe({
      next: (res: { name: string, url: string }[]) => {
        this.docs = Object.assign([], res);
        this.loading = false;
      }, error: (err: any) => {
        this.loading = false;
        this.toastr.error("Unable to get kyc details!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

}
