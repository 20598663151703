import {Injectable} from '@angular/core';
import {HttpHeaders} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {RondeivuHttpClient} from "../../../../handlers";
import {iMandateRequest} from "../../models";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MandateService {

  private readonly MOCK_URL = environment.rondeivu_api_mock + '/mandates';

  private readonly BASE_URL = environment.rondeivu_api;
  private readonly FRAGMENT = '/mandates';

  constructor(
    private http: RondeivuHttpClient
  ) {

  }

  addMandate(mandate: iMandateRequest) {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const req = JSON.stringify(mandate);
    // console.log(req);
    return this.http.post(this.BASE_URL + this.FRAGMENT, mandate, {headers: httpHeaders});
  }

  getMandates(): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT);
  }

  getMandate(id: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + '/' + id);
  }

  updateMandate(mandate:any): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + '/' + mandate.id, mandate);
  }

  deleteMandate(id: string): Observable<any> {
    return this.http.delete(this.BASE_URL + this.FRAGMENT + '/' + id);
  }
}
