import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Config, getConfig, getLookups, initialConfigState, initialLookupsState, Lookups} from "../../../../redux";
import {Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import {IDealTeam, IDealTeamMemberRequest} from "../../../models";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-deal-team-modal',
  templateUrl: './deal-team-modal.component.html',
  styleUrls: ['./deal-team-modal.component.scss']
})
export class DealTeamModalComponent implements OnInit, OnDestroy {

  lookup$: Subscription = new Subscription();
  lookups: Lookups = initialLookupsState;

  config$: Subscription = new Subscription();
  config: Config = initialConfigState;

  selectedDealTeam: IDealTeam = {} as unknown as IDealTeam;
  cache: IDealTeamMemberRequest = {} as unknown as IDealTeamMemberRequest;

  userForm = new FormGroup({
    role: new FormControl(),
    canUploadDataroom: new FormControl(),
    dealNotePermission: new FormControl(),
    rootFolders: new FormControl(),
  });

  constructor(private store: Store, public dialogRef: MatDialogRef<DealTeamModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { selectedDealTeam: IDealTeam, member: IDealTeamMemberRequest }) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });
  }

  ngOnInit(): void {
    if (!!this.data && !!this.data.member) {
      this.userForm.get('role')?.patchValue(this.data.member.role);
      this.userForm.get('canUploadDataroom')?.patchValue(this.data.member.permissions.canUploadDataroom);
      this.userForm.get('dealNotePermission')?.patchValue(this.data.member.permissions.dealNotePermission);
      this.userForm.get('rootFolders')?.patchValue(this.data.member.permissions.rootFolders);
    }
  }

  submit() {
    if (!!this.data && !!this.data.member) {
      this.data.member.role = this.userForm.get('role')?.value;
      this.data.member.permissions.canUploadDataroom = this.userForm.get('canUploadDataroom')?.value;
      this.data.member.permissions.dealNotePermission = this.userForm.get('dealNotePermission')?.value;
      this.data.member.permissions.rootFolders = this.userForm.get('rootFolders')?.value;
      this.dialogRef.close(this.data.member);
    }
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
  }

  isSelectedDealTeamType(type: string) {
    if (!!this.data.selectedDealTeam && !!this.data.selectedDealTeam.businessUnitProfile) {
      return this.data.selectedDealTeam.businessUnitProfile.toLowerCase() === type;
    }
    return false;
  }

  //this is a custom comparator for the root folder selection
  rootCompare(r1: { id: string, name: string, active: boolean }, r2: { id: string, name: string, active: boolean }): boolean {
    return r1 && r2 ? r1.id === r2.id : r1 === r2;
  }
}
