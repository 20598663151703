<div style="padding-bottom: 50px;">
  <app-data-room
    pageTitle="Data Room"
    pageDesc="These are the documents associated with this deal, shared between the deal counterparties."
    [documentTypes]="docTypes"
    [objectId]="objectId"
    [showEditButton]="util.hasPermission(util.Permission.CAN_EDIT_DEAL_DATAROOM)"
    [showDeleteButton]="util.hasPermission(util.Permission.CAN_EDIT_DEAL_DATAROOM)"
    [showAddFileButton]="util.hasPermission(util.Permission.CAN_EDIT_DEAL_DATAROOM)"
    [showNewFolderButton]="util.hasPermission(util.Permission.CAN_EDIT_DEAL_DATAROOM)"
    [canDropFiles]="util.hasPermission(util.Permission.CAN_EDIT_DEAL_DATAROOM)"
    [downloadAllEnabled]="util.hasPermission(util.Permission.CAN_ACCESS_DEAL_DATAROOM_DOWNLOAD_ALL)"
  ></app-data-room>
</div>

