import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {Store} from "@ngrx/store";
import {
  Config,
  getConfig, getLookups,
  initialConfigState,
  initialLookupsState,
  Lookups
} from "../../../redux";
import {IBusinessUnit} from "../../models";
import {FormControl, FormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BusinessUnitService} from "../../services";

@Component({
  selector: 'app-business-unit-modal',
  templateUrl: './business-unit-modal.component.html',
  styleUrls: ['./business-unit-modal.component.scss']
})
export class BusinessUnitModalComponent implements OnInit, OnDestroy {

  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();

  lookups: Lookups = initialLookupsState;
  config: Config = initialConfigState;
  employees: any[] = [];

  userForm = new FormGroup({
    id: new FormControl(),
    slug: new FormControl('', []),
    displayName: new FormControl('', []),
    internalName: new FormControl('', []),
    businessUnitType: new FormControl(),
    subType: new FormControl(),
    domicile: new FormControl(),
    intakeDescription: new FormControl(),
  });

  constructor(public dialogRef: MatDialogRef<BusinessUnitModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private businessUnitService: BusinessUnitService,
              private toastr: ToastrService, private store: Store
  ) {
    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });

    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.config$.unsubscribe();
    this.lookup$.unsubscribe();
  }

  submit() {
    this.dialogRef.close(this.userForm.getRawValue() as IBusinessUnit);
  }
}
