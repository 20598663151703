<div style="height:calc(100vh - 64px); overflow-y: auto;">
  <div class="container-fluid">
    <br/><br/>
    <div class="row justify-content-center">
      <div class="col-6 col-md-5">
        <div style="display: flex;">
          <mat-icon color="primary" class="rm-header-icon">policy</mat-icon>&nbsp;
          <span class="rm-title rm-title-3">KYC</span>
        </div>
      </div>
      <div class="col-6 text-end">
        <button mat-raised-button
                color="primary"
                style="height: auto;width: auto;"
                (click)="addKyc()"
                *ngIf="!util.isType('admin')"
                [disabled]="!util.hasPermission(util.Permission.CAN_ADD_KYC)"
                [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ADD_KYC) ? util.NoAccessCursor: ''}"
                [matTooltip]="!util.hasPermission(util.Permission.CAN_ADD_KYC) ? util.NoAccessMsg : ''"
                matTooltipPosition="left"
        >
          <mat-icon>assignment</mat-icon>&nbsp;INITIATE KYC
        </button>
      </div>

    </div>
    <!-- info pane start-->
    <div class="row justify-content-center" *ngIf="!util.isType('admin')">
      <div class="col-12 col-md-11" style=" margin-top: 15px; max-height: 300px; overflow-y:scroll;">
        <span>
          As part of our commitment to maintaining a secure and compliant platform,
          we require all users to complete the <b>Know Your Customer (KYC)</b> verification process.
          <br/> <br/>

          To complete the KYC process, kindly follow the steps below:<br/> <br/>

          <b>Step 1: Initiate KYC</b><br/>

          Click the Initiate KYC button, and submit a new KYC process by selecting the KYC type and providing an entity name.<br/> <br/>

          <b>Step 2: Access the KYC Information</b><br/>

          Once KYC is initiated, you will be redirected to the KYC information page. This is where you'll download all related KYC documents and upload official documents.<br/> <br/>

          <b>Step 3: Provide Personal Information</b><br/>

          Fill in the required personal information accurately. This includes your full name, date of birth, address, and any other details requested. Ensure the information matches the details on your official documents.<br/> <br/>

          <b>Step 4: Document Upload</b><br/>

          Upload clear, legible copies of the following documents:<br/><br/>

          Government-issued photo ID: Passport, driver's license, or national ID card.
          Proof of address: Utility bill, bank statement, or any official document with your current address.
          Please ensure that the documents are valid, unexpired, and show all relevant information clearly. Ensure that the images are in color and free from glare or shadows.<br/> <br/>

          <b>Step 5: Review and Submit</b><br/>

          Carefully review all the information you've provided and the documents you've uploaded. Once satisfied, submit your KYC information for verification.<br/> <br/>

          Below you’ll find a list of KYC processes initiated by your company:<br/> <br/>
        </span>
      </div>
    </div>
    <!--    info pane end-->
    <div class="row justify-content-center" style="padding-top:25px; padding-bottom: 50px;"
         *ngIf="!util.isType('admin')">
      <div class="col-12 col-md-11" *ngIf="!!dataSource.data[0]">
      <span style="font-weight: lighter; font-size: x-large;"
            [appStatus]="dataSource.data[0]?.status || ''">{{ dataSource.data[0]?.status }}</span><br/><br/>
        <app-user-card [firstName]="dataSource.data[0]?.businessUnit?.displayName || ''"
                       [displayName]="dataSource.data[0]?.businessUnit?.profile || ''"
                       [sideColor]="'#ff0000'"
                       [background]="'#FFF'"
                       [image]="dataSource.data[0]?.businessUnit?.imageUrl || ''"
                       [size]="userCardSize.LG"
        ></app-user-card>
      </div>
    </div>
    <!--      NON ADMIN VIEW-->
    <div class="row justify-content-center" *ngIf="!util.isType('admin')" style="padding-bottom: 150px;">
      <div class="col-12 col-md-11">
        <div *ngIf="!!dataSource.data[0]?.kycProcessItems">
          <table
            mat-table [dataSource]="dataSource.data[0]?.kycProcessItems || []"
            class="inner-table">
            <!--                    header-->
            <ng-container matColumnDef="status">
              <th colspan="1" mat-header-cell *matHeaderCellDef class="inner-header">Status</th>
              <td mat-cell *matCellDef="let element">
                <span [appStatus]="element.status">{{ element?.status }}</span>
              </td>
            </ng-container>
            <!--                    header-->
            <ng-container matColumnDef="name">
              <th colspan="1" mat-header-cell *matHeaderCellDef class="inner-header">Entity Name</th>
              <td mat-cell *matCellDef="let element"> {{ element?.entityName }}</td>
            </ng-container>
            <!--                    header-->
            <ng-container matColumnDef="created">
              <th mat-header-cell *matHeaderCellDef class="inner-header"> Created</th>
              <td mat-cell *matCellDef="let element"> {{ element?.created | date }}</td>
            </ng-container>
            <!--                    header-->
            <ng-container matColumnDef="view">
              <th mat-header-cell *matHeaderCellDef class="inner-header"> View</th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button
                        (click)="viewKycProcess(element.id)"
                        [disabled]="!util.hasPermission(util.Permission.CAN_ACCESS_KYC)"
                        [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ACCESS_KYC) ? util.NoAccessCursor: ''}"
                        [matTooltip]="!util.hasPermission(util.Permission.CAN_ACCESS_KYC) ? util.NoAccessMsg : 'View KYC'"
                        matTooltipPosition="left">
                  <mat-icon>east</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="kycProcessCols; sticky:true;"></tr>
            <tr mat-row *matRowDef="let element; columns: kycProcessCols;">
            </tr>
          </table>
        </div>
      </div>
    </div>
    <!--    NON ADMIN VIEW END-->

    <!--    -->
    <!--      ADMIN VIEW-->
    <!--    -->
    <!--    table start-->
    <div class="row justify-content-center d-none d-md-flex" *ngIf="util.isType('admin')" style="padding-top:25px;">
      <div class="col-12 col-md-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <table mat-table [dataSource]="dataSource"
                     [multiTemplateDataRows]="true"
                     matSort
                     (matSortChange)="sortData($event)"
              >
                <!-- Search Header -->
                <ng-container matColumnDef="table-name">
                  <th colspan="3" mat-header-cell *matHeaderCellDef>
                    <span style="font-size: large;">KYC</span>
                  </th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="search-header">
                  <th colspan="3" mat-header-cell *matHeaderCellDef>
                    <form>
                      <mat-form-field style="width: 100%;">
                        <mat-label>Search</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" #input>
                        <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                    </form>
                  </th>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="User">
                  <th mat-header-cell *matHeaderCellDef> Business Unit</th>
                  <td mat-cell
                      *matCellDef="let element" style="padding: 5px;">
                    <app-user-card [firstName]="element.businessUnit?.displayName"
                                   [displayName]="element.businessUnit?.profile"
                                   [borderColor]="'transparent'"
                                   [sideColor]="'#ff0000'"
                                   [image]="element.businessUnit?.['imageUrl']"
                                   (click)="util.isType('admin')? util.viewBusinessUnit(element.businessUnit?.id, 'kyc') : null"
                                   [ngStyle]="{'cursor': util.isType('admin')? 'pointer':''}"
                                   [highlightOnHover]="util.isType('admin')"
                    ></app-user-card>
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="profile">
                  <th mat-header-cell *matHeaderCellDef> Business Unit Profile</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ element?.businessUnit?.profile || '' }}
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef>BU KYC Status</th>
                  <td mat-cell *matCellDef="let element">
                    <span [appStatus]="element.status">{{ element.status }} </span>
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="kycProcessCount">
                  <th mat-header-cell *matHeaderCellDef> # KYC Processes</th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element?.kycProcessesCount }} </span>
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="pendingKycProcessesCount">
                  <th mat-header-cell *matHeaderCellDef> # Pending/Review KYC</th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element?.pendingKycProcessesCount }} </span>
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="declinedKycProcessesCount">
                  <th mat-header-cell *matHeaderCellDef> # Declined KYC</th>
                  <td mat-cell *matCellDef="let element">
                    <span>{{ element?.declinedKycProcessesCount }} </span>
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="Date">
                  <th mat-header-cell *matHeaderCellDef> Business Unit Created On</th>
                  <td mat-cell *matCellDef="let element"> {{ element?.created|date }}</td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef class="inner-header"> View</th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-icon-button
                            (click)="viewBusinessUnit(element?.businessUnit?.id)"
                            [disabled]="!util.hasPermission(util.Permission.CAN_ACCESS_BUSINESS_UNITS)"
                            [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ACCESS_BUSINESS_UNITS) ? util.NoAccessCursor: ''}"
                            [matTooltip]="!util.hasPermission(util.Permission.CAN_ACCESS_BUSINESS_UNITS) ? util.NoAccessMsg : 'View Business Unit'"
                            matTooltipPosition="left"
                    >
                      <mat-icon>east</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <ng-container matColumnDef="expand">
                  <th mat-header-cell *matHeaderCellDef aria-label="row actions" style="width: 50px;">&nbsp;</th>
                  <td mat-cell *matCellDef="let element" style="width: 50px;">
                    <button mat-icon-button aria-label="expand row"
                            [hidden]="!element.kycProcessItems || element.kycProcessItems?.length == 0"
                            (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                      <mat-icon *ngIf="expandedElement !== element" [matBadge]="element.kycProcessItems?.length"
                                matBadgeColor="accent">keyboard_arrow_down
                      </mat-icon>
                      <mat-icon *ngIf="expandedElement === element" [matBadge]="element.kycProcessItems?.length"
                                matBadgeColor="accent">keyboard_arrow_up
                      </mat-icon>
                    </button>
                  </td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                  <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length"
                      style="padding: 0;">
                    <div class="example-element-detail"
                         [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                      <div class="approval-row detail-row">
                        <table style="background: white;"
                               *ngIf="!!element.kycProcessItems && element.kycProcessItems.length > 0">
                          <thead>
                          <th>&nbsp;Display Name</th>
                          <th>Status</th>
                          <th>Created</th>
                          <th>View</th>
                          </thead>
                          <tbody>
                          <tr *ngFor="let kyc of element.kycProcessItems">
                            <td>&nbsp;{{ kyc.entityName }}</td>
                            <td><span [appStatus]="kyc.status">{{ kyc.status }}</span></td>
                            <td>{{ kyc.created | date }}</td>
                            <td>
                              <button mat-icon-button
                                      (click)="viewKycProcess(kyc.id)"
                                      [disabled]="!util.hasPermission(util.Permission.CAN_ACCESS_KYC)"
                                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ACCESS_KYC) ? util.NoAccessCursor: ''}"
                                      [matTooltip]="!util.hasPermission(util.Permission.CAN_ACCESS_KYC) ? util.NoAccessMsg : 'View KYC'"
                                      matTooltipPosition="left">
                                <mat-icon>east</mat-icon>
                              </button>
                            </td>
                          </tr>
                          </tbody>
                        </table>

                        <!--                        <table *ngIf="!!element.kycProcessItems && element.kycProcessItems.length > 0"-->
                        <!--                               mat-table [dataSource]="element.kycProcessItems"-->
                        <!--                               class="inner-table">-->
                        <!--                          &lt;!&ndash;                    header&ndash;&gt;-->
                        <!--                          <ng-container matColumnDef="status">-->
                        <!--                            <th colspan="1" mat-header-cell *matHeaderCellDef class="inner-header">Status</th>-->
                        <!--                            <td mat-cell *matCellDef="let element">-->
                        <!--                              <span [appStatus]="element.status">{{element.status}}</span>-->
                        <!--                            </td>-->
                        <!--                          </ng-container>-->
                        <!--                          &lt;!&ndash;                    header&ndash;&gt;-->
                        <!--                          <ng-container matColumnDef="name">-->
                        <!--                            <th colspan="1" mat-header-cell *matHeaderCellDef class="inner-header">Display Name</th>-->
                        <!--                            <td mat-cell *matCellDef="let element"> {{element.entityName}} </td>-->
                        <!--                          </ng-container>-->
                        <!--                          &lt;!&ndash;                    header&ndash;&gt;-->
                        <!--                          <ng-container matColumnDef="created">-->
                        <!--                            <th mat-header-cell *matHeaderCellDef class="inner-header"> Created</th>-->
                        <!--                            <td mat-cell *matCellDef="let element"> {{element.created | date}} </td>-->
                        <!--                          </ng-container>-->
                        <!--                          &lt;!&ndash;                    header&ndash;&gt;-->
                        <!--                          <ng-container matColumnDef="view">-->
                        <!--                            <th mat-header-cell *matHeaderCellDef class="inner-header"> View</th>-->
                        <!--                            <td mat-cell *matCellDef="let element">-->
                        <!--                              <button mat-icon-button (click)="viewKycProcess(element.id)" matTooltip="View KYC"-->
                        <!--                                      matTooltipPosition="left">-->
                        <!--                                <mat-icon>east</mat-icon>-->
                        <!--                              </button>-->
                        <!--                            </td>-->
                        <!--                          </ng-container>-->
                        <!--                          <tr mat-header-row *matHeaderRowDef="kycProcessCols; sticky:true;"></tr>-->
                        <!--                          <tr mat-row *matRowDef="let element; columns: kycProcessCols;">-->
                        <!--                          </tr>-->
                        <!--                        </table>-->
                      </div>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="['table-name','search-header'];"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
                    class="example-element-row"
                    [class.example-expanded-row]="expandedElement === element">
                  <!--              (click)="expandedElement = expandedElement === element ? null : element">-->
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter.</td>
                </tr>
              </table>
            </div>
          </div>
          <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[25, 50, 100]"
                         [pageSize]="100"
                         aria-label="Select page of users"></mat-paginator>
        </div>
      </div>
    </div>
    <!--    list start-->
    <div class="row justify-content-center d-flex d-md-none" style="padding-bottom: 150px; padding-top: 15px;"
         *ngIf="util.isType('admin')">
      <div class="col-12">
        <ul style="list-style-type: none; padding: 25px 0 0 0;">
          <li *ngFor="let element of dataSource.data"
              style="margin-bottom: 5px; display: flex; justify-content: space-between;">
            <app-user-card [firstName]="element.businessUnit?.displayName"
                           [displayName]="element.businessUnit?.profile"
                           [borderColor]="'transparent'"
                           [sideColor]="'#345'"
                           [image]="element.businessUnit?.['imageUrl']"
                           [imageShape]="'square'"
            ></app-user-card>
            <div style="display: flex;">
              <span [appStatus]="element.status"
                    style="line-height: 42px; font-size: xx-small;">{{ element.status }}</span>
              <button mat-icon-button
                      (click)="viewBusinessUnit(element?.businessUnit?.id || '')"
                      [disabled]="!util.hasPermission(util.Permission.CAN_ACCESS_BUSINESS_UNITS)"
                      [ngStyle]="{'cursor':!util.hasPermission(util.Permission.CAN_ACCESS_BUSINESS_UNITS) ? util.NoAccessCursor: ''}"
                      [matTooltip]="!util.hasPermission(util.Permission.CAN_ACCESS_BUSINESS_UNITS) ? util.NoAccessMsg : 'View Business Unit'"
                      matTooltipPosition="left"
              >
                <mat-icon>east</mat-icon>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
