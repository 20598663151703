<div style="height:calc(100vh - 176px); overflow-y: auto;">
  <div class="container-fluid">
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-11">
        <span class="rm-title rm-title-3">Activity</span><br/>
        <span class="rm-title rm-title-6 rm-lighter">This is the user's current activity:</span>
      </div>
    </div>
    <div class="row justify-content-center" style="padding-top: 25px;">
      <div class="col-11">
        <div class="table-container">
          <div class="row table-inner-container">
            <div class="table-inner-inner-container">
              <table mat-table [dataSource]="dataSource"
                     [multiTemplateDataRows]="true"
                     matSort
                     (matSortChange)="sortData($event)"
              >
                <!-- Search Header -->
                <ng-container matColumnDef="table-name">
                  <th colspan="4" mat-header-cell *matHeaderCellDef>
                    <span style="font-size: large;">User Activity</span>
                  </th>
                </ng-container>
                <!-- Search Header -->
                <ng-container matColumnDef="search-header">
                  <th colspan="4" mat-header-cell *matHeaderCellDef>
                    <form>
                      <mat-form-field style="width: 100%;">
                        <mat-label>Search</mat-label>
                        <input type="text" matInput (keyup)="applyFilter($event)" #input>
                        <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                    </form>
                  </th>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="ip">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="ip"> IP Address</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ element.eventContextData.ipAddress }}
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="device">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="device"> Device Name</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ element.eventContextData.deviceName }}
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="country">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="country"> Country</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ element.eventContextData.country }}
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="city">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="city"> City</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ element.eventContextData.city }}
                  </td>
                </ng-container>
                <!-- COLUMN -->
                <ng-container matColumnDef="creation">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="date"> Creation Date</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ element.creationDate | date:'medium' }}
                  </td>
                </ng-container>

                <!-- COLUMN -->
                <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="type"> Event Type</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ element?.eventType?.['value'] }}
                  </td>
                </ng-container>

                <!-- COLUMN -->
                <ng-container matColumnDef="risk">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="risk"> Event Risk</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ element?.eventRisk?.riskDecision?.value }}
                  </td>
                </ng-container>

                <!-- COLUMN -->
                <ng-container matColumnDef="level">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="level"> Risk Level</th>
                  <td mat-cell
                      *matCellDef="let element">
                    {{ element?.eventRisk?.riskLevel?.value }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="['table-name','search-header']"></tr>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true;"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                    class="example-element-row"
                    [class.example-expanded-row]="expandedElement === element">
                  <!--              (click)="expandedElement = expandedElement === element ? null : element">-->
                </tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter.</td>
                </tr>
              </table>
            </div>
          </div>
          <mat-paginator style="background-color: white; z-index: 99;" [pageSizeOptions]="[5, 10, 25, 100]"
                         aria-label="Select page of users"></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

