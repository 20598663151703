import {Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Subscription} from "rxjs";
import {
  Config,
  getConfig,
  getLookups,
  initialConfigState,
  initialLookupsState,
  Lookups,
  toggleLoading
} from "../../../redux";
import {FormControl, FormGroup} from "@angular/forms";
import {UtilService} from "../../../shared/services";
import {Store} from "@ngrx/store";
import {UuidService} from "../../../../services";
import {ToastrService} from "ngx-toastr";
import {SubdocService} from "../../services/subdoc/subdoc.service";
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ISubDocField, ISubDocFieldsResponse, ISyndicationSubdoc} from "../../models/syndication";
import {DomSanitizer} from "@angular/platform-browser";
import {isNumber} from "@swimlane/ngx-charts/lib/utils/types";

@Component({
  selector: 'app-deal-subscription-subdoc-form-modal',
  templateUrl: './deal-subscription-subdoc-form-modal.component.html',
  styleUrls: ['./deal-subscription-subdoc-form-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DealSubscriptionSubdocFormModalComponent implements OnInit {
  config$: Subscription = new Subscription();
  lookup$: Subscription = new Subscription();
  config: Config = initialConfigState;
  lookups: Lookups = initialLookupsState;

  protected readonly FORM_ANSWERS = 'answers';

  form = new FormGroup({
    answers: new FormControl(),
  });

  fieldResponse: ISubDocFieldsResponse = {} as unknown as ISubDocFieldsResponse;
  fieldsList: ISubDocField[] = [];

  fieldHtml: string = '';
  splitFieldHtml: string[] = [];
  matches: string[] = [];

  @ViewChild('fileUpload') private fileInput: ElementRef | undefined;

  constructor(
    public util: UtilService,
    private store: Store,
    private uuid: UuidService,
    private toastr: ToastrService,
    private subDocService: SubdocService,
    private router: Router,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<DealSubscriptionSubdocFormModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { subdoc: ISyndicationSubdoc }) {

    this.config$ = this.store.select(getConfig).subscribe((config: Config) => {
      this.config = config;
    });
    this.lookup$ = this.store.select(getLookups).subscribe((lookups: Lookups) => {
      this.lookups = lookups;
    });
  }


  ngOnInit(): void {
    if (!!this.data) {
      // this.getSubdoc();
      this.getFields();
    }
  }

  getSubdoc() {
    console.log("Fetching SubDoc...");
    this.store.dispatch(toggleLoading({loading: true}));
    this.subDocService.getSubdocById(this.data.subdoc.id).subscribe({
      next: (subdoc: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: err => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to get subdocs!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  getFields() {
    console.log("Fetching Fields...");
    this.store.dispatch(toggleLoading({loading: true}));
    this.subDocService.getDocumentFields(this.data.subdoc.id).subscribe({
      next: (fields: ISubDocFieldsResponse) => {
        this.fieldResponse = fields;
        this.fieldsList = fields.fieldsList;
        // add the current answers to the form
        let answers: { uuid: string, values: string[] }[] = [];
        for (let x = 0; x <= this.fieldsList.length - 1; x++) {
          let uuid = this.fieldsList[x].uuid || '';
          let values = this.fieldsList[x].values || [];
          answers.push({uuid: uuid, values: values});
        }
        this.form.get(this.FORM_ANSWERS)?.setValue(answers);
        // parse the html
        this.processContent(this.fieldResponse.content);
        this.store.dispatch(toggleLoading({loading: false}));
      }, error: err => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to get fields!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  saveFields(approveForSig: boolean) {
    console.log("Updating Fields...");
    this.store.dispatch(toggleLoading({loading: true}));
    let payload: any = {};
    payload.fieldsList = this.form.get(this.FORM_ANSWERS)?.value || [];
    payload.approveForInvestorSignatures = approveForSig;
    this.subDocService.updateDocumentFields(this.data.subdoc.id, payload).subscribe({
      next: (res: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.success("Sub Doc Fields Updated!", $localize`:@@companyName:Rondeivu`);
      }, error: err => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to get fields!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  private processContent(content: string) {
    const prefix = '<x-concord-field data-field-uuid="';
    const suffix = '"></x-concord-field>';
    const separator = '<span class="field-marker">**********</span>';
    const regex: RegExp = new RegExp(prefix + '.{36}' + suffix, 'g');
    const matches = content.match(regex);
    if (!!matches) {
      for (let i = 0; i <= matches.length - 1; i++) {
        this.matches?.push(matches[i].replace(prefix, '').replace(suffix, ''));
      }
    }

    const replacedContent = content.replace(regex, separator);
    const splitContent = replacedContent.split(separator);
    this.fieldHtml = replacedContent;
    this.splitFieldHtml = splitContent;
  }

  downloadPdf() {
    this.downloadSubdoc(this.data.subdoc);
  }

  downloadSubdoc(element: ISyndicationSubdoc) {
    this.store.dispatch(toggleLoading({loading: true}));
    this.subDocService.getSubdocPdf(element.id).subscribe({
      next: (res: Blob) => {
        let filename = 'download.pdf';
        let blob: Blob = res as Blob;
        let a = document.createElement('a');
        a.download = filename;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        this.store.dispatch(toggleLoading({loading: false}));
      },
      error: (err: any) => {
        this.store.dispatch(toggleLoading({loading: false}));
        this.toastr.error("Unable to download file!", $localize`:@@companyName:Rondeivu`);
      }
    });
  }

  submit(): void {
    // this.dialogRef.close(this.form.getRawValue());
  }

  getSafeHtml(content: string) {
    // Bypassing Angular HTML sanitizer
    return this.sanitizer
      .bypassSecurityTrustHtml(content);
  }

  inputChange(event: any) {
    this.updateFormAnswer(event.answer, FormAnswerType.INPUT);
  }

  checkChange(event: any) {
    this.updateFormAnswer(event.answer, FormAnswerType.CHECKBOX);
  }

  radioChange(event: any) {
    this.updateFormAnswer(event.answer, FormAnswerType.RADIO);
  }

  selectChange(event: any) {
    this.updateFormAnswer(event.answer, FormAnswerType.SELECT);
  }

  private updateFormAnswer(formAnswer: IFormAnswer, type: FormAnswerType) {
    const answer: IFormAnswer = Object.assign(formAnswer);
    this.updateCollectionQuestion(this.FORM_ANSWERS, answer, type);
  }

  private updateCollectionQuestion(collection: string, formAnswer: IFormAnswer, type: FormAnswerType) {
    // console.log(formAnswer);
    let answers = Object.assign(this.form.get(collection)?.value) || [];
    // check if answer exists already
    let attrFound = false;
    // look answers
    for (let i = 0; i <= answers.length - 1; i++) {
      let answerAtIdx = answers[i];
      if (answerAtIdx.uuid == formAnswer.uuid) {
        attrFound = true;
        // check for existing value
        let valueFoundIdx;
        let existingValues = Object.assign(answerAtIdx.values) || [];
        // loop the values
        for (let x = 0; x <= existingValues.length - 1; x++) {
          if (existingValues[x] == formAnswer.value) {
            valueFoundIdx = x;
            break;
          }
        }
        // handle removing the value based on input type
        if (type == FormAnswerType.CHECKBOX) {
          // console.log(valueFoundIdx);
          if (typeof valueFoundIdx === 'number') {
            answerAtIdx.values.splice(valueFoundIdx, 1);
          } else {
            answerAtIdx.values.push(formAnswer.value);
          }
        } else {
          let values: string[] = [];
          values.push(formAnswer.value);
          answerAtIdx.values = values;
        }
        break;
      }
    }
    // answer doesn't exist
    if (!attrFound) {
      let values = [];
      values.push(formAnswer.value);
      answers.push({
        uuid: formAnswer.uuid,
        values: values
      });
    }

    this.form.get(collection)?.setValue(answers);
    // console.log(JSON.stringify(this.form.get(collection)?.value));
  }

}

interface IFormAnswer {
  uuid: string;
  value: string;
}

enum FormAnswerType {
  INPUT = 'INPUT',
  CHECKBOX = 'CHECKBOX',
  RADIO = 'RADIO',
  SELECT = 'SELECT'
}
