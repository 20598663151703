<div style="overflow-y: auto; height: calc(100vh - 112px);">
  <div class="container-fluid">
    <div style="overflow-x: hidden;">
      <div class="row justify-content-center" style="padding-top: 25px;">
        <div class="col-12 col-md-11">
          <span class="rm-title rm-title-3">User Settings</span><br/><br/>
          <span class="rm-title rm-title-6">

            Access and tailor your user preferences with our <b>User Settings</b> page, emphasizing a balance between personalization and security.<br/><br/>
            Customize your display information for a personalized platform experience and fortify your account security seamlessly by setting up <b>Two-Factor Authentication</b>. <br/><br/>
            Your online presence, personalized and secure.

          </span>
        </div>
      </div>
      <div class="row justify-content-center" style="margin: 50px 0;">
        <div class="col-12 col-md-11">
          <div class="row">
            <app-user-card [firstName]="userForm.get('firstName')?.value || undefined"
                           [lastName]="userForm.get('lastName')?.value || undefined"
                           [displayName]="userForm.get('email')?.value || undefined"
                           [borderColor]="'transparent'"
                           [background]="'#F1EDE4'"
                           [sideColor]="'#002150'"
                           [size]="userCardSize.XL"
                           [image]="userForm.get('imageUrl')?.value || undefined"
            ></app-user-card>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 col-md-11 text-end">
              <button type="button" color="primary" mat-raised-button (click)="openImageUploadModal()">UPLOAD
                IMAGE
              </button>&nbsp;
              <button type="button" color="warn" mat-raised-button (click)="clearImage()">CLEAR IMAGE</button>
            </div>
          </div>
        </div>
      </div>
      <!--    WELL START-->
      <div class="row justify-content-center">
        <div class="col-12 col-md-11">
          <form [formGroup]="userForm"
                (ngSubmit)="save()">
            <div class="row">
              <span style="font-size: large; font-weight: bold;">Account Data</span>
            </div>
            <br/>
            <div class="row">
              <div class="col-12 col-md-8">
                <!--          row start-->
                <div class="row">
                  <div class="col-12">
                    <mat-label style="font-size: medium;">First Name</mat-label>
                    <mat-form-field class="form-field" appearance="outline">
                      <input class="form-input" matInput formControlName="firstName" placeholder="i.e. John"/>
                    </mat-form-field>
                  </div>
                </div>
                <!--          row start-->
                <div class="row">
                  <div class="col-12">
                    <mat-label style="font-size: medium;">Last Name</mat-label>
                    <mat-form-field class="form-field" appearance="outline">
                      <input class="form-input" matInput formControlName="lastName" placeholder="i.e. Smith"/>
                    </mat-form-field>
                  </div>
                </div>
                <!--          row start-->
                <div class="row" style="padding-bottom: 20px;">
                  <div class="col-12">
                    <mat-label style="font-size: medium;">Email</mat-label>
                    <br/>
                    <mat-label style="font-size: small; font-weight: lighter;">Please contact Rondeivu at
                      info@rondeivu.com
                      to change your email address.
                    </mat-label>
                    <mat-form-field class="form-field" appearance="outline">
                      <input class="form-input" matInput formControlName="email"
                             placeholder="i.e. example@rondeivu.com"/>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <!--          row start-->
            <div class="row" style="padding-top: 20px;">
              <div class="col-12 text-end">
                <button type="submit" mat-raised-button color="primary">SAVE CHANGES</button>
              </div>
            </div>
            <!--          rows end-->
          </form>
        </div>
      </div>
      <br/><br/>
      <!--    WELL START-->
      <div class="row justify-content-center">
        <div class="col-12 col-md-11">
          <hr/>
          <br/>
          <form [formGroup]="phoneForm"
                (ngSubmit)="savePhone()">
            <div class="row">
              <span style="font-size: large; font-weight: bold;">Phone & 2FA Verification</span>
            </div>
            <br/>
            <div class="row">
              <div class="col-12 col-md-8">
                <!--          row start-->
                <div class="row">
                  <div class="col-12 col-md-4">
                    <mat-label style="font-size: medium;">Country Code</mat-label>
                    <mat-form-field class="form-field" appearance="outline">
                      <mat-select id="cc" formControlName="countryCode">
                        <mat-option *ngFor="let cc of lookups.countryCodes" [value]="cc.key">
                          {{cc.value}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-8">
                    <mat-label style="font-size: medium;">Phone Number</mat-label>
                    <mat-form-field class="form-field" appearance="outline">
                      <input class="form-input" matInput formControlName="mobile" placeholder="555-555-5555"/>
                    </mat-form-field>
                  </div>
                </div>

                <!--            row start-->
                <div class="row" *ngIf="!userForm.get('phoneNumberVerified')?.value">
                  <div class="col-12 text-center">
                    <div class="notify">
                      <mat-icon id="notify-icon">warning</mat-icon>
                      <span style="line-height: 24px;height:24px;">Your phone number requires verification</span>&nbsp;
                      <!--                      <button mat-raised-button class="notify-button" type="button" (click)="openPhoneVerifyModal()">-->
                      <!--                        Verify-->
                      <!--                        Now-->
                      <!--                      </button>-->
                    </div>
                  </div>
                </div>
                <!--            row start-->
                <div class="row" *ngIf="userForm.get('phoneNumberVerified')?.value">
                  <div class="col-12 text-center">
                    <div class="notify-good ">
                      <span style="font-size: 0.88rem;">Your phone number is verified</span>&nbsp;
                      <mat-icon id="notify-good-icon">done</mat-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--          row start-->
            <div class="row" style="padding-top: 20px;">
              <div class="col-12 text-end">
                <button type="submit" mat-raised-button color="primary">SAVE & VERIFY 2FA</button>
              </div>
            </div>
            <!--          rows end-->
          </form>
        </div>
      </div>
      <br/><br/>
      <!--WELL START-->
      <div class="row justify-content-center" style="padding-bottom: 150px;">
        <div class="col-12 col-md-11">
          <hr/>
          <br/>
          <form [formGroup]="adminForm"
                (ngSubmit)="changePassword()">
            <!-- This hidden input prevents the autofill of the password fields. -->
            <input autocomplete="false" name="hidden" type="text" hidden>
            <div class="row">
              <span style="font-size: large; font-weight: bold;">Account Password</span>
            </div>
            <br/>
            <!--          row start-->
            <div class="row">
              <div class="col-12 col-md-6">
                <mat-label style="font-size: medium;">Old Password</mat-label>
                <mat-form-field class="form-field" appearance="outline">
                  <input class="form-input" matInput formControlName="oldPassword" autocomplete="new-password"
                         aria-autocomplete="none" type="password"/>
                  <mat-error *ngIf="adminForm.get('oldPassword')?.invalid && adminForm.get('oldPassword')?.dirty">
                    Password must be a combination of lower-case,
                    upper-case, numbers, special characters, and at
                    least 8 characters long.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-12 col-md-6">
                <mat-label style="font-size: medium;">New Password</mat-label>
                <mat-form-field class="form-field" appearance="outline">
                  <input class="form-input" matInput formControlName="newPassword" autocomplete="new-password"
                         aria-autocomplete="none" type="password"/>
                  <mat-error *ngIf="adminForm.get('newPassword')?.invalid && adminForm.get('newPassword')?.dirty">
                    Password must be a combination of lower-case,
                    upper-case, numbers, special characters, and at
                    least 8 characters long.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-end">
                <button type="submit" mat-raised-button color="primary"
                        [disabled]="adminForm.invalid">
                  UPDATE PASSWORD
                </button>
              </div>
            </div>
            <!--          rows end-->
          </form>
        </div>
      </div>
      <!--      WELL END-->
    </div>
  </div>
</div>

