import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {CognitoUser, Config, getCognitoUser, getConfig, initialCognitoUserState, initialConfigState} from "../../redux";
import {RondeivuHttpClient} from "../../../handlers";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {IKycPerson} from "../models/i-kyc-person";

@Injectable({
  providedIn: 'root'
})
export class EntityKycPersonService {
  readonly BASE_URL = environment.rondeivu_api;
  readonly FRAGMENT = '/businessunitentitykycperson';
  readonly KYC_FRAGMENT = '/kyc';
  readonly UPLOAD_ID = '/upload-proof-of-identification';
  readonly UPLOAD_ADDRESS = '/upload-proof-of-address';
  readonly DELETE_ID = '/remove-proof-of-identification';
  readonly DELETE_ADDRESS = '/remove-proof-of-address';
  readonly VIEW_ID = '/view-proof-of-identification';
  readonly VIEW_ADDRESS = '/view-proof-of-address';
  readonly RONDEIVU_STATUS = '/change-rondeivu-status';
  readonly IMPORT = '/import';
  private config: Config = initialConfigState;
  private cognito: CognitoUser = initialCognitoUserState;

  constructor(private http: RondeivuHttpClient, private store: Store) {
    this.store.select(getConfig).subscribe(c => {
      this.config = c;
    });
    this.store.select(getCognitoUser).subscribe(cu => {
      this.cognito = cu;
    });
  }

  /**
   * Get the people associated to the user's business unit
   */
  getKycPeople(): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT);
  }

  /**
   * Add a new person to the user's business unit
   */
  addKycPerson(kycPerson: IKycPerson): Observable<any> {
    let formData = new FormData();
    this.sanitize(formData, "businessUnitId", kycPerson.businessUnitId);
    this.sanitize(formData, "businessUnitEntityKycId", kycPerson.businessUnitEntityKycId);
    this.sanitize(formData, "firstName", kycPerson.firstName);
    this.sanitize(formData, "lastName", kycPerson.lastName);
    this.sanitize(formData, "middleName", kycPerson.middleName);
    this.sanitize(formData, "suffix", kycPerson.suffix);
    this.sanitize(formData, "countryId", kycPerson.countryId);
    this.sanitize(formData, "city", kycPerson.city);
    this.sanitize(formData, "jobTitle", kycPerson.jobTitle);
    this.sanitize(formData, "kycPersonType", kycPerson.kycPersonType);
    this.sanitize(formData, "principalOccupation", kycPerson.principalOccupation);
    this.sanitize(formData, "searchResult", kycPerson.searchResult);
    this.sanitize(formData, "stateOrProvince", kycPerson.stateOrProvince);
    this.sanitize(formData, "streetAddress", kycPerson.streetAddress);
    this.sanitize(formData, "suffix", kycPerson.suffix);
    this.sanitize(formData, "updated", kycPerson.updated);
    this.sanitize(formData, "yearofBirth", kycPerson.yearofBirth.toString());
    this.sanitize(formData, "zipPostalCode", kycPerson.zipPostalCode);
    this.sanitize(formData, "personGovernmentIssuedIdNumber", kycPerson.personGovernmentIssuedIdNumber);
    this.sanitize(formData, "personGovernmentIssuedIdType", kycPerson.personGovernmentIssuedIdType);

    if (!!kycPerson.personGovernmentIssuedIdExpiryDate) {
      formData.append("personGovernmentIssuedIdExpiryDate", new Date(kycPerson.personGovernmentIssuedIdExpiryDate).toLocaleDateString('en-US'));
    }

    if (!!kycPerson.personProofofAddressDocuments) {
      for (let i = 0; i <= kycPerson.personProofofAddressDocuments.length - 1; i++) {
        formData.append("personProofofAddressDocuments", kycPerson.personProofofAddressDocuments[i]);
      }
    }

    this.sanitize(formData, "personProofofAddressDocumentType", kycPerson.personProofofAddressDocumentType);
    this.sanitize(formData, "personProofofAddressDocumentOtherName", kycPerson.personProofofAddressDocumentOtherName);

    if (!!kycPerson.personProofofAddressIssuedDate) {
      formData.append("personProofofAddressIssuedDate", new Date(kycPerson.personProofofAddressIssuedDate).toLocaleDateString('en-US'));
    }

    if (!!kycPerson.personProofofIdentificationDocuments) {
      for (let i = 0; i <= kycPerson.personProofofIdentificationDocuments.length - 1; i++) {
        formData.append("personProofofIdentificationDocuments", kycPerson.personProofofIdentificationDocuments[i]);
      }
    }

    this.sanitize(formData, "personProofofIdentificationIdType", kycPerson.personProofofIdentificationIdType);
    this.sanitize(formData, "personProofofIdentificationOtherName", kycPerson.personProofofIdentificationOtherName);

    if (!!kycPerson.personProofofIdentificationIdExpiryDate) {
      formData.append("personProofofIdentificationIdExpiryDate", new Date(kycPerson.personProofofIdentificationIdExpiryDate).toLocaleDateString('en-US'));
    }

    return this.http.post(this.BASE_URL + this.FRAGMENT, formData);
  }

  private sanitize(formData: FormData, field: string, value: any) {
    if (!!value && (value != '') && (value != undefined)) {
      formData.append(field, value);
    }
  }

  /**
   * Get a person by the current kyc id
   * @param kycId the kyc being viewed
   */
  getKycPeopleByKycId(kycId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.KYC_FRAGMENT + '/' + kycId);
  }

  /**
   * Get a person by id
   * @param kycPersonId
   */
  getKycPersonById(kycPersonId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + '/' + kycPersonId);
  }

  /**
   * Update a person
   * @param kycPerson
   */
  updateKycPerson(kycPerson: any): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + '/' + kycPerson.id, kycPerson);
  }

  /**
   * Delete a person by id
   * @param kycPersonId
   * @param entityKycId
   */
  deleteKycPerson(entityKycId: string, kycPersonId: string): Observable<any> {
    return this.http.delete(this.BASE_URL + this.FRAGMENT + '/' + entityKycId + '/' + kycPersonId);
  }

  /**
   * Upload proof of identification
   * @param kycPersonId
   * @param form
   */
  uploadProofOfIdentification(kycPersonId: string, form: any): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + this.UPLOAD_ID + '/' + kycPersonId, form);
  }

  /**
   * Upload proof of address
   * @param kycPersonId
   * @param form
   */
  uploadProofOfAddress(kycPersonId: string, form: any): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + this.UPLOAD_ADDRESS + '/' + kycPersonId, form);
  }

  /**
   * Delete proof of identification
   * @param documentId
   */
  deleteProofOfIdentification(documentId: string): Observable<any> {
    return this.http.delete(this.BASE_URL + this.FRAGMENT + this.DELETE_ID + '/' + documentId);
  }

  /**
   * Delete proof of address
   * @param documentId
   */
  deleteProofOfAddress(documentId: string): Observable<any> {
    return this.http.delete(this.BASE_URL + this.FRAGMENT + this.DELETE_ADDRESS + '/' + documentId);
  }

  /**
   * View proof of identification
   * @param documentId
   */
  getProofOfIdentification(documentId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.VIEW_ID + '/' + documentId, {responseType: 'blob'});
  }

  /**
   * View proof of address
   * @param documentId
   */
  getProofOfAddress(documentId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.VIEW_ADDRESS + '/' + documentId, {responseType: 'blob'});
  }

  /**
   * ADMIN ONLY: update a person status
   * @param kycPersonId
   * @param payload
   */
  updatePersonStatus(kycPersonId: string, payload: {
    kycPersonId: string,
    kycPersonRondeivuStatus: string
  }): Observable<any> {
    return this.http.put(this.BASE_URL + this.FRAGMENT + this.RONDEIVU_STATUS + '/' + kycPersonId, payload);
  }

  getKycPeopleToImport(kycId: string): Observable<any> {
    return this.http.get(this.BASE_URL + this.FRAGMENT + this.IMPORT + '/' + kycId);
  }

}
